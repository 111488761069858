import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChange
} from '@angular/core';


declare let jQuery: any;
declare let moment: any;
declare let _: any;

@Component({
  selector: 'app-shared-trialline_navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class TriallineNavigationComponent implements AfterViewInit, OnChanges
{

  @Input() timeline: any;
  @Input() minDate: number;
  @Input() maxDate: number;
  @Output() onRangeChanged = new EventEmitter<any>();
  @Output() onSelectFlag = new EventEmitter<any>();

  cursor: any = {
    left: 0,
    width: null
  };

  flags: any[] = [];
  oldMinDate: number = 0;
  line: any = jQuery('.trial-line-navigation-line');

  firstTime: boolean = true;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef
  ) {

    this.line = jQuery('.trial-line-navigation-line');
  }

  // ngOnInit() {
  //
  //   console.log('onInit', this.minDate, this.maxDate);
  // }

  ngOnChanges(changes: {[propKey: string]: SimpleChange})
  {
    if (changes['timeline']) {

      this.timeline = changes['timeline'].currentValue;
    }
  }

  ngAfterViewInit() {

    let self = this;
    this.timeline.on('rangechanged', function(pos) {
      self.rangechanged(pos);
    });

    // this.rangechanged(this.timeline.getWindow());
  }

  ngOnDestroy(){
    this._changeDetectorRef.detach();
  }

  rangechanged(pos, anyway:boolean = false) {

    this.onRangeChanged.emit(pos);

    let self = this;
    let drawLine = function(pos) {

      self.calculateFlags();

      self.cursor = {
        left: self.dateToPx(moment(pos.start).valueOf() - self.minDate),
        width: self.dateToPx(moment(pos.end).valueOf() - moment(pos.start).valueOf())
      };
      jQuery('.trial-line-navigation').find('[data-toggle="tooltip"]').tooltip({
        container: 'body',
        animated : 'fade'
      });

      let dragable = function(e, ui, pos) {
        let min = self.minDate;
        let max = self.maxDate;
        let time_width = (max - min);
        let time_left = min + ((time_width * ui.offset.left) / jQuery('.trial-line-navigation-line').width());
        let time_right = time_left + (moment(pos.end).valueOf() - moment(pos.start).valueOf());
        if (self.timeline) {
          self.timeline.setWindow(time_left, time_right);
        }
      };

      jQuery('.trial-line-navigation-line-cursor')
        .draggable({
          axis: "x",
          cursor: '-moz-grabbing',
          containment: "parent",
          scrollSensitivity: 100,
          drag: function(e, ui){
            dragable(e, ui, pos);
          }
        });
    };

    if ((pos && pos.byUser) || self.firstTime || anyway) {
      this.firstTime = false;
      drawLine(pos);
      this._changeDetectorRef.detectChanges();
    }
  };

  calculateFlags() {
    let self = this;
    let reduceFn = function(mem, item) {
      if(!Array.isArray(mem)){
        if(Math.abs(mem.left - item.left) < 20) {
          item.top = mem.top + 5;
          if(item.top > 20) {
            item.top = 2;
            item.left = item.left + 5;
          }
        }
        mem = [mem, item];
      }else{
        if(Math.abs(mem[mem.length-1].left - item.left) < 20) {
          item.top = mem[mem.length-1].top + 5;
          if(item.top > 20) {
            item.top = 2;
            item.left = item.left + 5;
          }
        }
        mem.push(item);
      }
      return mem;
    };
    let mapFn = function(item) {
      item.top = 0;
      item.left = self.dateToPx(moment(item.start).valueOf() - self.minDate);
      return item;
    };

    if (this.timeline.itemsData.length != 1) {

      this.flags = _.chain(this.timeline.itemsData._data).map(mapFn).sortBy('left').reduce(reduceFn).value();
    } else {

      this.flags = [];
    }
  }

  calculateFlagsList() {
    let self = this;
  if (this.oldMinDate != this.minDate){
    this.oldMinDate = this.minDate
    let reduceFn = function(mem, item) {
      if (!Array.isArray(mem)) {
        if(Math.abs(mem.left - item.left) < 20) {
          item.top = mem.top + 5;
          if(item.top > 20) {
            item.top = 2;
            item.left = item.left + 5;
          }
        }
        mem = [mem, item];
      } else {
        if (Math.abs(mem[mem.length-1].left - item.left) < 20) {
          item.top = mem[mem.length-1].top + 5;
          if(item.top > 20) {
            item.top = 2;
            item.left = item.left + 5;
          }
        }
        mem.push(item);
      }
      return mem;
    };
    let mapFn = function(item) {
      item.top = 0;
      item.left = self.dateToPx(moment(item.start).valueOf() - self.minDate);
      return item;
    };

      setTimeout(() => {
        if (this.timeline.itemsData !== undefined) {
            if ( this.timeline.itemsData.length !== 1) {
                this.flags = _.chain(this.timeline.itemsData._data).map(mapFn).sortBy('left').reduce(reduceFn).value();
              } else {
              this.flags = [];
                }
          } else {
          this.flags = [];
          }
      }, 300)
    }
  }


  dateToPx(dateUnix) {
    let min = this.minDate;
    let max = this.maxDate;
    let time_width = (max - min);
    return (dateUnix * jQuery('.trial-line-navigation-line').width()) / time_width
  };

  select(id: number) {
    this.timeline.setSelection(id, {focus: true});
    let self = this;
    this.onSelectFlag.emit(id);
    this.timeline.on('rangechanged', function onSelect() {
      self.firstTime = true;
      self.rangechanged(self.timeline.getWindow());
      self.timeline.off('rangechanged', onSelect);
    });
  }

resizeWindow(){
  window.setTimeout(function(){
    window.dispatchEvent(new Event('resize'));
      },1500);
    }
}
