
import {map} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {RestService} from "../../rest/shared/rest.service";
import {Event} from "../../main/shared/event.model";
import {ActivatedRoute} from "@angular/router";
import {Trialline} from "../../main/shared/trialline.model";


@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: [
    './test.component.css'
  ],
})
export class TestComponent implements OnInit {

  items: Event[] = [];

  trialline: Trialline;

  constructor(private http: RestService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {

    this.route.data.subscribe((data: {trialline: Trialline}) => {

      this.trialline = data.trialline;
    });
    this.loadEvents();
  }

  loadEvents() {

    return this.http.get('trialline/59/events?with=category').pipe(map((response: any) => {
      let body = response.body;

      return body.events;
    })).toPromise().then((events: any) => {
      this.items = events;
    }).catch((e: any) => console.log(e));
  }
}
