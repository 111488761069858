<div id="youtube-preview-modal" class="modal fade bs-modal-lg in" tabindex="-1" role="dialog"
     style="z-index: 9999999; overflow: visible">
  <div class="modal-dialog modal-md modal-preview">
    <div class="modal-content">
      <div class="modal-header ">
        <button (click)="closePreviewer()" type="button" class="close"><span
          aria-hidden="true">&times;</span></button>
        <h4 class="modal-title"><span class="file-title">{{ video?.title }}</span></h4>
      </div>
      <div class="modal-body">
          <iframe class="center-block" width="560" height="315" [attr.src]="videoUrl" frameborder="0" allowfullscreen></iframe>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>
