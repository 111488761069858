import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  encapsulation: ViewEncapsulation.Emulated,
  selector: 'app-403',
  templateUrl: './forbidden.component.html',
  styleUrls: [
    './forbidden.component.css'
  ]
})
export class ForbiddenComponent
{
}
