<style>
  .container{
    width:90%;
  }
</style>
<div class="page-container page-wrap">
  <div class="page-content-wrapper">
    <div class="page-head">
      <div class="container  head-container">
        <!-- BEGIN Search -->
        <form action="/dashboard">
          <div class="page-title">
            <div class="inputs">
              <div class="portlet-input input-inline input-xlarge ">
                <div class="input-icon right">
                  <i *ngIf="activeTab =='dashboard' || activeTab == 'matters'" class="fa fa-search"></i>
                  <input type="text" name="search" (input)="onSearchChange($event)" [(ngModel)]="searchKey"
                         class="form-control form-control-solid"
                         placeholder="Search All TrialLines by Title or Teammate" value=""
                         *ngIf="activeTab =='dashboard'"
                         >
                  <input type="text" name="searchMat" (input)="onMatSearch($event)" [(ngModel)]="searchMat"
                         class="form-control form-control-solid"
                         placeholder="Search Clio Matters to Import into TrialLine" value=""
                         *ngIf="activeTab == 'matters'"
                         >
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- END Search -->
        <!-- BEGIN btn block -->
        <div class="page-title pull-right">
          <!-- && (user?.team_access_level != 1 && user?.team_access_level != 5) -->
          <button *ngIf="user && ! forbidden && user?.team_access_level != 5" (click)="newTrialline()"
                  class="btn btn-primary btn-circle btn-md pull-right"> Create a New TrialLine
          </button>
        </div>
      </div>
      <!-- END btn block -->
    </div>
  </div>
  <div class="page-content">
    <div class="container" >
      <ng-container *ngIf="newTrialUser">
        <label id="freeTiralDiscountPopup" class="freeTiralDiscountPopup" style="display: none;">showpopup</label>
      </ng-container>
      <!--BEGIN Breadcrumb-->
      <ul class="nav nav-tabs" role="tablist">
        <li role="presentation" [class.active]="activeTab == 'dashboard' || !activeTab">
          <a (click)="activeTab = 'dashboard';update()" aria-controls="info" role="tab" data-toggle="tab"
             aria-expanded="true">
            My Dashboard
          </a>
        </li>
        <li role="presentation" [class.active]="activeTab == 'matters'" *ngIf="user?.clio_access_token">
          <a (click)="activeTab = 'matters'" aria-controls="matters" role="tab" data-toggle="tab"
             aria-expanded="false">
           Clio Matters
          </a>
        </li>
        <li role="presentation" [class.active]="activeTab == 'archived' || !activeTab">
          <a (click)="activeTab = 'archived'" aria-controls="archived" role="tab" data-toggle="tab"
             aria-expanded="false">
            Archived TrialLines
          </a>
        </li>
      </ul>
      <div class="tab-content">
        <div role="tabpanel" class="tab-pane" [class.active]="activeTab == 'dashboard'" id="dashboard">
          <div class="portlet light ">
            <div class="portlet-body">
              <div *ngIf="forbidden">
                <div class="alert alert-warning" *ngIf="! user?.subscription_past_due">
                  Your subscription is not active, please <a [routerLink]="['/profile/subscription']">choose a subscription plan</a> to continue.
                </div>
                <div class="alert alert-warning" *ngIf="user?.subscription_past_due">
                  Your subscription is not active, please <a [routerLink]="['/profile/subscription']">choose a subscription plan</a> to continue.
                </div>
              </div>
              <ng-container *ngIf="! forbidden">
                <div class="my-dashboard-list-item __header">
                  <div class="row">
                    <div class="col-md-3 col-sm-3">
                      <div class="title" (click)="reorder('name')" style="cursor: pointer">Title (Click Arrow to See Previous Updates)
                        <span *ngIf="columnname=='name'" class="e2e-inner-html-bound" [style.opacity]="order == 'name' ? '1' : '.3'"
                        [innerHTML]="getSortIcon('name')"></span>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-2">
                      <div class="title" (click)="reorder('first_name')" style="cursor: pointer">Created By
                        <span *ngIf="columnname=='first_name'" class="e2e-inner-html-bound" [style.opacity]="order == 'first_name' ? '1' : '.3'"
                        [innerHTML]="getSortIcon('first_name')"></span>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-2">
                      <div class="title" (click)="reorder('created_at')" style="cursor: pointer">Create Date
                        <span *ngIf="columnname=='created_at'" class="e2e-inner-html-bound" [style.opacity]="order == 'created_at' ? '1' : '.3'"
                        [innerHTML]="getSortIcon('created_at')"></span></div>
                    </div>
                    <div class="col-md-2 col-sm-2">
                      <div class="title" (click)="reorder('updated_at')" style="cursor: pointer">Last Update
                        <span *ngIf="columnname=='updated_at'" class="e2e-inner-html-bound" [style.opacity]="order == 'updated_at' ? '1' : '.3'"
                        [innerHTML]="getSortIcon('updated_at')"></span></div>
                    </div>

                    <!--<div class="col-md-2 col-sm-2">-->
                      <!--<div class="title">Cancellation date</div>-->
                    <!--</div>-->
                    <!--<div class="col-md-1 col-sm-1">-->
                    <!--<div class="title">Status</div>-->
                    <!--</div>-->
                    <div class="col-md-3 col-sm-3">
                      <div class="title">Actions</div>
                    </div>
                  </div>
                </div>
                <div class="my-dashboard-list-body">
                  <!-- <div *ngFor="let trialline of  triallines | search:'name':searchKey" class="my-dashboard-list-item"> -->

                     <div *ngFor="let trialline of  triallines   | paginate: { id: 'foo',
                                                                  itemsPerPage: perPage,
                                                                  currentPage: page,
                                                                  totalItems: total};let i = index;"
                                                                 class="my-dashboard-list-item">
                    <!--WITH ACCORDION-->
                    <div class="row accord">
                      <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true" style="margin-bottom: 0 !important;">
                        <div class="panel panel-default">
                          <div class="panel-heading" role="tab" id="{{ 'heading' + trialline.id }}">
                            <div class="col-xs-12 full">
                              <div class="col-md-2 col-sm-2 __title full" style=" margin-bottom: 0 !important;">
                                <h4 class="panel-title">
                                  <a [routerLink]="['/trialline/' + trialline.id]" (click)='triallinetest(trialline.id);'>
                                    {{ trialline.name }}
                                  </a>
                                </h4>
                              </div>
                              <div class="col-md-1 col-sm-1 __title full" style=" margin-bottom: 0 !important;">
                                <span *ngIf="trialline.free == true" class="label label-success_new label-sm pull-right">free</span>
                                <span *ngIf="trialline.status == 'Archive'" class="label label-info label-sm pull-right">archived</span>
                              </div>
                              <div  *ngIf='trialline.userdetail!=null' class="col-md-2 col-sm-2 __title" >

                                {{trialline.userdetail.first_name}} {{trialline.userdetail.last_name}}
                              </div>
                              <div  *ngIf='trialline.userdetail==null' class="col-md-2 col-sm-2 __title" >

                              </div>
                               <div class="col-md-2 col-sm-2 __title">
                                {{ triallineCustomDateFormat(trialline.trialline_date_format, trialline.created_at) }}
                              </div>
                              <div class="col-md-2 col-sm-2 __title">
                                {{ triallineCustomDateFormat(trialline.trialline_date_format, trialline.updated_at) }}
                              </div>
                              <!--<div class="col-md-2 col-sm-2 __title" style="">-->
                                <!--{{ trialline.deleted_at }}-->
                              <!--</div>-->
                              <!--<div class="col-md-1 col-sm-1 __status" style="">-->
                              <!--<p>{{ trialline.status }}</p>-->
                              <!--</div>-->
                              <div class="col-md-3 col-sm-3__date list-action">
                                <a [routerLink]="['/trialline/' + trialline.id]" *ngIf="trialline.status != 'Archive'"
                                   class="btn blue btn-sm btn-circle" (click)='triallinetest(trialline.id);'>View</a>
                                <!--<a (click)="onClick()" class="btn blue btn-sm btn-circle">View</a>-->
                                <a [routerLink]="['/trialline/' + trialline.id + '/edit']" *ngIf="trialline.status != 'Archive'"
                                   class="btn blue btn-outline btn-sm btn-circle">Edit</a>
                                <a  (click)="copyTrialline(trialline)" *ngIf="trialline.status != 'Archive' && user?.team_access_level != 5"
                                   class="btn blue btn-outline btn-sm btn-circle copy-trl"
                                   data-confirm="You are about to duplicate this TrialLine, do you wish to continue?">
                                  Copy
                                </a>
                                <a  (click)="archiveTrialline(trialline)" *ngIf="trialline.status != 'Archive' && trialline.free != true && user?.team_access_level != 5"
                                   class="btn red btn-outline btn-sm btn-circle"
                                   data-confirm="TrialLine will be archived. While it's not active, no one will have an access to view it. The month fee is 0.99$. Do you wish to proceed?">
                                  Archive
                                </a>
                                <a (click)="openTrialline(trialline)" *ngIf="trialline.status == 'Archive'"
                                   class="btn blue btn-sm btn-circle"
                                   data-confirm="TrialLine will be opened. Are you sure you want to open?">
                                  Open
                                </a>

                                <!-- <a (click)="deleteTrialline(trialline)" 
                                  *ngIf="user && user?.team_access_level != 1 &&(user && user?.id ==trialline.user_id ||user && user?.user_type==0) "
                                  class="btn red btn-outline btn-sm btn-circle"
                                  data-confirm="Warning! If you delete this TrialLine you will not be able to retrieve it. You may also archive this TrialLine for $0.99 per month. Are you sure you want to delete this TrialLine?"
                                >
                                  Delete
                                </a> -->
                                <a (click)="deleteTrialline(trialline)" 
                                  *ngIf="user && user?.team_access_level != 1 &&(user && user?.id ==trialline.user_id ||user && user?.user_type==0)"
                                  class="btn red btn-outline btn-sm btn-circle"
                                >
                                  Delete
                                </a>
                                <h4 class="panel-title icon" style="display: inline-block">
                                  <a class="collapsed" data-toggle="collapse" data-parent="#accordion" [attr.href]="'#collapse' + trialline.id" aria-expanded="false" [attr.aria-controls]="'collapse' + trialline.id">
                                  </a>
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div [id]="'collapse' + trialline.id" class="panel-collapse collapse" role="tabpanel" [attr.aria-labelledby]="'heading' + trialline.id" aria-expanded="false" style="height: 0px;">
                            <div class="panel-body" style="padding: 30px 15px 0 15px!important;">
                              <span *ngIf="! trialline.childs || trialline.childs.length == 0">There are no versions of this TrialLine yet</span>
                              <ng-container *ngIf="trialline.childs && trialline.childs.length > 0">
                                <div id="item" *ngFor="let sub of trialline.childs">
                                  <div class="col-xs-12 nop" style="margin-top: 5px;">
                                    <div class="col-md-6 col-sm-6 __title">
                                      <p>{{ sub.name }}</p>
                                    </div>
                                    <div class="col-md-3 col-sm-3 __title">
                                      <p>{{ sub.created_at }}</p>
                                    </div>
                                    <!--<div class="col-md-1 col-sm-1 __status">-->
                                    <!--<p>{{ sub.status }}</p>-->
                                    <!--</div>-->
                                    <div class="col-md-3 col-sm-3 __date">
                                      <a [routerLink]="['/trialline/' + sub.id]"
                                         class="btn blue btn-sm btn-circle" >View</a>
                                      <!--<a (click)="onClick()" class="btn blue btn-sm btn-circle">View</a>-->
                                      <!--<a [routerLink]="['/trialline/' + sub.id + '/edit']"-->
                                      <!--class="btn blue btn-outline btn-sm btn-circle">Edit-->
                                      <!--</a>-->
                                      <a (click)="deleteTrialline(sub)" *ngIf="user && user?.user_type != 3"
                                         class="btn red btn-outline btn-sm btn-circle"
                                         data-confirm="Warning! If you delete this TrialLine you will not be able to retrieve it. You may also archive this TrialLine for $0.99 per month. Are you sure you want to delete this TrialLine?">
                                        Delete
                                      </a>
                                      <a (click)="useAsPrimary(sub)"
                                         class="btn blue btn-sm btn-outline btn-circle">
                                        Use
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="padding-top: 10px" >
                    <label *ngIf="triallines==''">Triallines Not Found </label>
                    <select *ngIf="triallines!=''" (change)="changePages($event)"  style="float: right; margin-left: 10px">
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                      <div *ngIf="triallines!=''" style="float: right">
                        <pagination-controls  id="foo"
                                              (pageChange)="pageChanged($event)"
                                              maxSize="9"
                                              responsive="true"
                                              previousLabel="Previous"
                                              nextLabel="Next"
                                              screenReaderPaginationLabel="Pagination"
                                              screenReaderPageLabel="page"
                                              screenReaderCurrentLabel="You're on page">
                        </pagination-controls>
                      </div>
                    </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div role="tabpanel" class="tab-pane" [class.active]="activeTab == 'matters'" id="matters">
          <app-dashboard-matters [activeTab]="activeTab" [forbidden]="forbidden" [user]="user"></app-dashboard-matters>
        </div>
        <div role="tabpanel" class="tab-pane" [class.active]="activeTab == 'archived'" id="archived">
          <app-dashboard-archived [activeTab]="activeTab" [forbidden]="forbidden" [triallines]="triallines"></app-dashboard-archived>
        </div>
      </div>
      <!--END Breadcrumb-->
    </div>
  </div>
</div>

<app-first-time [user]="user" (onClosed)="openSubscriptionFormModal($event)"></app-first-time>
<app-subscription *ngIf="user" [user]="user" (onSubscribed)="reloadTriallines($event)"></app-subscription>
<app-create_trialline *ngIf="showCreateForm && user" [user]="user" (onCreated)="addTrialline($event)"></app-create_trialline>
<span defaultOverlayTarget></span>
