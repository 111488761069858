import {NgModule} from '@angular/core';
import {HttpClientModule} from "@angular/common/http";
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {ProfileResourceService} from "./shared/profile-resoruce.service";
import {ProfileResolver} from "./shared/profile.resolver";
import {ProfileSidebarComponent} from "./shared/sidebar/sidebar.component";
import {ProfileSettingsComponent} from "./settings/settings.component";
import {ProfileBillingComponent} from "./billing/billing.component";
import {ValidatorModule} from "../../form-validator/validator.module";
import {ProfileSubscriptionComponent} from "./subscription/subscription.component";
import {AddCardComponent} from "./billing/add-card/add-cart.component";
import {CountsResolver} from "./shared/counts.resolver";
import {CardsResolver} from "./shared/cards.resolver";
import {ModalModule} from "ngx-modialog-7";
import { ProfileClioComponent } from "./clio/clio.component";
import { KeysPipe } from "../../keys.pipe";
import { DocumentsClioComponent } from "./clio/documents/documents.component";
import { MainModule } from '../main.module';
import { FeedCardComponent } from './clio/feed-card/feed-card.component';
import { FeedServiceService } from './clio/feed-service.service';
import {CouponResourceService} from "./shared/coupon-resoruce.service";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    ValidatorModule,
    ModalModule.forRoot(),
  ],
  declarations: [
    ProfileSettingsComponent,
    ProfileSidebarComponent,
    ProfileBillingComponent,
    ProfileSubscriptionComponent,
    AddCardComponent,
    ProfileClioComponent,
    DocumentsClioComponent,
    KeysPipe,
    FeedCardComponent

  ],
  providers: [
    ProfileResourceService,
    CouponResourceService,
    ProfileResolver,
    CountsResolver,
    CardsResolver,
    FeedServiceService
  ],
  exports: [
    AddCardComponent,
    FeedCardComponent
  ]
})
export class ProfileModule {}
