import {map} from 'rxjs/operators';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Title} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {Event} from "../../../shared/event.model";
import {EventsResourceService} from "../shared/events-resoruce.service";
import {SharedService} from "../../../../shared/shared.service";
import {NgForm} from "@angular/forms";
import {Category} from "../../../shared/category.model";
import {TriallineResourceService} from "../../shared/trialline-resoruce.service";
import {FileModel} from "../../../file-library/shared/file.model";
import {FileLibraryResourceService} from "../../../file-library/shared/file-library-resoruce.service";
import {FileItem, FileUploader} from "ng2-file-upload";
import {env} from "../../../../../../.env";
import {AuthService} from "../../../../auth/shared/auth.service";
import {Modal} from "ngx-modialog-7/plugins/bootstrap";
import {Video} from "../../../shared/video.model";
import {Trialline} from "../../../shared/trialline.model";
import {TriallinesResourceService} from "../../../triallines/shared/triallines-resoruce.service";
import {RestService} from "../../../../rest/shared/rest.service";
import {EventEditCopyModalComponent} from "./edit-copy.component";
import Cropper from "cropperjs";
import { Console } from 'console';

declare var jQuery: any;
declare var moment: any;
declare var _: any;
declare var $: any;

@Component({
  selector: 'app-trialline-event-modal',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EventEditModalComponent implements OnInit, OnDestroy, AfterViewInit {

  config: any;
  isCollapsed: boolean;
  description: any;
  @ViewChild("ckeditor", { static: true }) ckeditor: any;
  @ViewChild("ckeditor1", { static: true }) ckeditor1: any;
  @Input() event: Event;
  @Input() customField: any; 
  @Output() onClose = new EventEmitter<Event>();
  @Output() onUpdated = new EventEmitter<Event>();
  @Output() onCreated = new EventEmitter<Event>();
  @Output() onDelete  = new EventEmitter<Event>();
  @Output() onCopy    = new EventEmitter<Event>();
  @ViewChild('sharedFiles') sharedFiles;

  testTime = new Date();
  moreFiles: boolean = true;
  apiPath = env.apiUrl;
  public uploader: FileUploader;
  public privateUploader: FileUploader;
  public billCostUploader: FileUploader;
  private modalId = '#event-edit-modal';
  fromLibraryModalId = '#add-from-library-modal';
  fromLibraryModal: boolean = false;
  processing: boolean = false;
  categories: Category[];
  triallineId: number;
  filesIds: number[] = [];
  privateFilesIds: number[] = [];
  billCostFiles: any = [];
  videosIds: number[] = [];
  sub: any;
  defaultThumbnail: string = '/images/start-event/trialline-event-thumb.jpg';
  publicFolderUrl: string = env.publicFolderUrl;
  publicImages = env.publicImages;
  errors: any = {};
  valid: boolean = true;
  categoryValid: boolean = true;
  exceptFileIds: number[] = [];
  saveAvailable: boolean = true;
  status: boolean = false;
  triallines: Trialline[];
  cursor_position: any;
  top: number;
  prev_pos: any;
  status1: boolean = false;
  alltr: any;
  visibility: boolean;
  thumbnailfilesarray = [];
  rgeneral: any;
  thumbnailstatus: boolean;
  encrypt_id: any;
  thumbnail_encryptid: any;
  ttfilesi = [];
  ttfolder = [];
  thumbnailpath: any;
  thumbnailbackfolder: any;
  thumbnailfiles: any;
  eventtagslist = [];
  eventdata = [];
  searchtag = [];
  contactsEventId = [];
  firsttime: boolean;
  showIcon: boolean = false;
  copyEvent: boolean = false;
  EventEditCopyModalComponent1: EventEditCopyModalComponent;
  crop_image: HTMLImageElement;
  cropProcessing: boolean = false;
  blobImg: any = false;
  isCropped: boolean = false;
  cropper: any;
  cropped_image_src: string;
  lesserEndDate:boolean;
  date_regex = /^\d{2}\/\d{2}\/\d{4}$/ ; // /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
  displayDateFormat: any;
  selectedDateFormat: string = 'MDY';
  progressBarUploder: boolean = false;

  constructor(protected toasts: ToastrService,
              protected title: Title,
              protected route: ActivatedRoute, public router: Router,
              protected sharedService: SharedService,
              private eventsResourceService: EventsResourceService,
              private _changeDetectionRef: ChangeDetectorRef,
              private triallineResoureService: TriallineResourceService,
              private fileLibraryResourceService: FileLibraryResourceService,
              private triallinesResourceService: TriallinesResourceService,
              private authService: AuthService,
              private modal: Modal,
              private http: RestService) {
    this.firsttime = false
    this.sharedFiles = [];
    // refactor to component
    this.sub = this.route.parent.params.subscribe(params => {
      this.triallineId = +params["trialline_id"];
    });
    this.uploader = new FileUploader({
      url: env.apiUrl + 'file/thumbnail?attach_to=trialline&attach_to_id=' + this.triallineId,
      authToken: 'Bearer ' + authService.token(),
      autoUpload: true,
      maxFileSize: 1024 * 1024 * 2
    });
    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      if (
        item.file.type == "image/png" ||
        item.file.type == "image/jpg" ||
        item.file.type == "image/jpeg" ||
        item.file.type == "image/webp"
      ) {
        this.progressBarUploder = true;
      } else {
        this.progressBarUploder = false;
        return toasts.error("Invalid file format. Please select an image.");
      }
      let body = JSON.parse(response).body;
      if (body.path) {
        let encrypt_id = body.encrypt_id ? body.encrypt_id : "";
        let file_id = body.file_id ? body.file_id : null;
        this.thumbnailUploaded(body.path, encrypt_id, file_id);
        this.showCropImage();
      } else {
        toasts.error("Something went wrong, please try again.", "Sorry");
      }
    };
    this.privateUploader = new FileUploader({
      url: env.apiUrl + 'file/private',
      authToken: 'Bearer ' + authService.token(),
    });
    this.privateUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let body = JSON.parse(response).body;
      if (status == 200 && body.file.id) {
        this.privateFilesIds.push(body.file.id);
      } else {
        if (status == 400) {
          let errorResponse = JSON.parse(response);
          toasts.error(errorResponse.error.message);
        } else {
          toasts.error('Something went wrong, please try again.', 'Sorry')
        }
      }
    };
    this.billCostUploader = new FileUploader({
      url: env.apiUrl + 'file/private',
      authToken: 'Bearer ' + authService.token(),
    });
    this.billCostUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let body = JSON.parse(response).body;
      item.file = body.file;
      let patch = true;
      if (this.billCostFiles.length > 0) {
        this.billCostFiles.forEach((fileElement) => {
          if (fileElement.file.file.id === item.file.file.id) {
            patch = false;
          }
        })
      }
      if (status == 200 && body.file.id) {
        if (patch) {
          this.billCostFiles.push(item);
        }
      } else {
        if (status == 400) {
          let errorResponse = JSON.parse(response);
          toasts.error(errorResponse.error.message);
        } else {
          toasts.error('Something went wrong, please try again.', 'Sorry')
        }
      }
    };
    this.uploader.onWhenAddingFileFailed = (item: any, filter: any, options: any) => {
      switch (filter.name) {
        case 'fileSize':
          toasts.error(`Maximum upload size exceeded (less than 2 Mb files allowed)`);
          break;
        default:
          toasts.error(`Unknown error (filter is ${filter.name})`);
          break;
      }
    };
    this.EventEditCopyModalComponent1 = new EventEditCopyModalComponent(this.toasts,this.title,this.route,this.router,
      this.sharedService, this.eventsResourceService,this. _changeDetectionRef, this.triallineResoureService,
      this.fileLibraryResourceService, this.triallinesResourceService,this.authService,this.modal,this.http)
  }


  upload(item, attachText, isPublic = false) {
    if (!attachText) {
      this.toasts.error('Attach text field is required')
    } else {
      item.options.additionalParameter =
        {
          attachText: attachText,
          is_private: 1,
        };
      item.upload();
    }
  }

  uploadBillCost(item, attachText, amount, bill_cost, isPublic = false) {
    if (!amount || !attachText) {
      this.toasts.error('Attach text and amount fields is required');
    } else {
      item.options.additionalParameter =
        {
          attachText: attachText,
          is_private: 1,
          amount,
          bill_cost,
          trialline_id: this.triallineId,
          type: 'bill_cost'
        };
      item.upload();
    }
  }

  handleDropzoneQueue(count: number) {
    this.saveAvailable = count < 1;
    this._changeDetectionRef.detectChanges();
  }

  dynamicFormat(dFormat) {
    return dFormat;
  }

  calendarDateFormatSelection(dateFormat){
    
    switch(dateFormat) {
      case 'YMD':
        return {
          'halfDateFormat' : 'yyyy/mm',
          'fullDateFormat' : 'yyyy/mm/dd',
        };
      break;
      case 'DMY':
        return {
          'halfDateFormat' : 'mm/yyyy',
          'fullDateFormat' : 'dd/mm/yyyy',
        };
      break;
      case 'MDY':
        return {
          'halfDateFormat' : 'mm/yyyy',
          'fullDateFormat' : 'mm/dd/yyyy',
        };
      break;
      default:
        return {
          'halfDateFormat' : 'mm/yyyy',
          'fullDateFormat' : 'mm/dd/yyyy',
        };
      break;
    }

  }

  ngOnInit() {
    
    this.initialiseChanges()
  }
  initialiseChanges() {
    
    if(typeof this.event.date_start != 'object') {
      this.event.date_start = new Date(this.event.start_date)
    } else {      
      this.event.date_start = this.event.start_date
    }

    if(this.event.date_end) {      
      if(typeof this.event.date_end != 'object') {        
        this.event.date_end = new Date(this.event.end_date)
      } else {        
        this.event.date_end = this.event.end_date
      }
    }
    
    this.config = { toolbar:'Full', uiColor : '#f8f8f8'}
    this.thumbnailfilesarray = [];
    this.eventtagslist = [];
    jQuery('#general').collapse('show');
    jQuery('#provider-details').collapse('hide');
    jQuery('#add-media').collapse('hide');
    jQuery('#add-tag').collapse('hide');
    jQuery('#notes').collapse('hide');
    jQuery('#crop_block').hide();
    
    this.triallinesResourceService.getTriallinesByPage({
      page: 1,
      sort: {field: "created_at", order: "ASC"},
      name: null,
      limit: 1000
    }).subscribe((response) => {
      this.triallines = response['triallines'];
      this.triallines.forEach( (element) => {
          if(element.id == this.triallineId) {
            this.displayDateFormat = this.calendarDateFormatSelection(element.trialline_date_format);
            this.selectedDateFormat = (element.trialline_date_format) ? element.trialline_date_format : 'MDY';
          }    
      });
      
      

      });
    if (!this.event) {
      this.event = new Event();
      this.event.thumbnail = this.defaultThumbnail;
      this.event.thumbnail_attr = this.defaultThumbnail;
      this.event.date_start = null;
    } else {
      this.contactsEventId = _.pluck(this.event.contacts, 'id');
      this.exceptFileIds = _.pluck(this.event.files, 'id');
    }
    if (!this.event.updated_at){
      this.event.updated_at = 'null';
    }
    this.eventtagslist = this.event.tag_name;
    this.sharedService.tagarray = this.eventtagslist;
    this.sub = this.route.parent.params.subscribe(params => {
      this.triallineId = +params["trialline_id"];
    });
    this.loadCategories();
    this.thumbnailbackfolder = [];
    // @ts-ignore
    this.http.get('file/fromLibrary' + (this.triallineId ? '?trialline_id=' + this.triallineId : ''),
      {}, false).pipe(map(r => r.body)).subscribe((r: any) => {
      this.thumbnailfiles = r
      for (let i = 0; i < r.general.length; i++) {
        if (((r.general[i].original_path).split("/", 100)).length < 4) {
          if (r.general[i].type == 'image') {
            if (this.thumbnailfilesarray.includes(r.general[i]) == false) {
              this.thumbnailfilesarray.push(r.general[i])
            }
          }
        } else {
          if (r.general[i].type == 'image') {
            let splittext = (r.general[i].original_path).split("/", 100)[2]
            if (this.ttfolder.includes(splittext) == false) {
              this.ttfolder.push((r.general[i].original_path).split("/", 100)[2]);
            }
          }
        }
      }
      for (let i = 0; i < r.trialline.length; i++) {
        if (((r.trialline[i].original_path).split("/", 100)).length < 4) {
          if (r.trialline[i].type == 'image') {
            this.thumbnailfilesarray.push(r.trialline[i])
          }
        } else {
          if (r.trialline[i].type == 'image') {
            let splittext = (r.trialline[i].original_path).split("/", 100)[2]
            if (this.ttfolder.includes(splittext) == false) {
              this.ttfolder.push((r.trialline[i].original_path).split("/", 100)[2]);
            }
          }
        }
      }
    })
    this.sharedService.encriptedpaththumbnail = ''

  }

  loadCategories() {
    this.triallineResoureService.getCategories(this.triallineId).toPromise()
      .then((categories: Category[]) => {
        this.categories = categories;
      })
      .catch((error) => {
        this.toasts.error(error);
      });
  }

  ngOnDestroy() {
    if(this.sub != undefined){
      this.sub.unsubscribe();
    }
    this._changeDetectionRef.detach();
  }

  showCropImage() {
    this.cropped_image_src = this.publicFolderUrl +'index.php/v1/filesystem/'+(this.event.thumbnail_encryptid)+'?preview=1';
    var self = this;
    this.cropProcessing = true;
    this.crop_image = jQuery('#crop_img')[0] as HTMLImageElement;
    this.crop_image.src = this.cropped_image_src ;
    this._changeDetectionRef.detectChanges();
    this.crop_image.addEventListener('load', function(event) {
      var cropper = new Cropper(self.crop_image, {
        aspectRatio: 1 / 1,
        highlight: false,
        zoomable: false,
        responsive: false,
        minContainerWidth: 300,
        minContainerHeight: 300,
        crop(event) {
          self.cropper = cropper;
        }
      });
    });
    this.crop_image.addEventListener( 'ready', function (event) {
      self.cropProcessing = false;
      self.isCropped = true;
      self._changeDetectionRef.detectChanges();
      jQuery('#crop_block').show();
    })
  }

  cropApply() {
    this.cropProcessing = true;
    this.isCropped = false;
    this.cropper.getCroppedCanvas({
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
    }).toBlob((blob) => {
      const formData = new FormData();
      jQuery('#crop_block').hide();
      let filename = this.event.thumbnail_attr.substring(this.event.thumbnail_attr.lastIndexOf('/')+1);
      filename = filename.replace(/\.[^/.]+$/, "")
      let finalFilname = filename+".jpeg"
      formData.append('file', blob,finalFilname);
      this.uploadThumbnail(formData);
    },'image/jpeg',1);
  }

  cropCancel() {
    jQuery('#crop_block').hide();
    this.cropper.destroy();
  }

  uploadThumbnail(formData) {
    var self = this;
    jQuery.ajax(env.apiUrl + 'file/thumbnail', {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + this.authService.token()},
      data: formData,
      processData: false,
      contentType: false,
      success(response) {
        jQuery('#processing').hide();
        if (response.body.path) {
          self.event.thumbnail = response.body.path;
        }
        if (response.body.encrypt_id) {
          self.event.thumbnail_encryptid = response.body.encrypt_id;
        }
        if (response.body.message) {
          self.toasts.success(response.body.message);
        }
        self.cropProcessing = false;
        if (response.body.file_id) {
          var file_id =  response.body.file_id ? response.body.file_id : null;
        }
        self.thumbnailUploaded(self.event.thumbnail, self.event.thumbnail_encryptid, file_id);
        self._changeDetectionRef.detectChanges();
        self.cropper.destroy();
        jQuery('#crop_block').hide();
      },
      error() {
        self.cropProcessing = false;
        jQuery('#processing').hide();
        self.toasts.error('Something went wrong', 'Sorry');
      },
    });
  }

  ngAfterViewInit() {
    this.initialiseChanges();

    jQuery(this.modalId)
      .velocity("transition.slideRightBigIn");
    let self = this;
    jQuery(this.modalId).on('hidden.bs.modal', function (e) {
      if (!jQuery(this).hasClass('in')) {
        self.onClose.emit(self.event);
      } else {
        jQuery('body').addClass('modal-open');
      }
    });
    this.showIcon = this.event.thumbnail_attr !== null ;
  }

  switchHasTimes() {
    console.log('this.event',this.event)
    if (this.event) {
      this.event.has_times = !this.event.has_times;
    }
    
  }

  switchHasTimesEnd() {
    // if(jQuery('.event-form .end_date').val() == 'undefined' || jQuery('.event-form .end_date').val() == '') {
    //   this.toasts.error('Please fill the End Date first');
    //   return;
    // } else {
      if (this.event) {
        this.event.has_times_end = !this.event.has_times_end;
      }
    //}
  }

  switchEndDate() {    
    if (this.event) {
      this.event.end_date = this.event.end_date == null ? new Date() : null;
    }    
    //this.switchHasTimesEnd();
    this.event.has_times_end = false;
  }

  checkNumber(value: any) {
    return Number.isInteger(value);
  }

  changeBillCostFilesAttached(event, file) {
    let index = this.event.bill_cost_files.indexOf(file);
    this.event.bill_cost_files[index].attach_text = event.target.value;
  }

  changeBillCostFilesAmount(event, file) {
    let index = this.event.bill_cost_files.indexOf(file);
    this.event.bill_cost_files[index].amount = event.target.value;
  }

  changeBillCostFilesType(event, file) {
    let index = this.event.bill_cost_files.indexOf(file);
    this.event.bill_cost_files[index].bill_cost = event.target.value;
  }

  changeBillCostFilesAttached2(event, file) {
    let index =this.billCostFiles.indexOf(file);
    this.billCostFiles[index].file.attach_text = event.target.value;
  }

  changeBillCostFilesAmount2(event, file) {
    let index = this.billCostFiles.indexOf(file);
    this.billCostFiles[index].file.amount = event.target.value;
  }

  changeBillCostFilesType2(event, file) {
    let index = this.billCostFiles.indexOf(file);
    this.billCostFiles[index].file.bill_cost = event.target.value;
  }

  convertForDatabase(currentData, dateFormat, checkedDays) { // request should be mm/dd/yyyy
    
    let splitDate = currentData.split('/');    

    let dateformatForDB = '';
    switch(dateFormat) { // "11/30/2022"
      case 'YMD':  
        if(checkedDays) { //yyyy/dd/mm
          dateformatForDB = splitDate[2]+'/'+splitDate[1]+'/'+splitDate[0];   
        } else {  //yyyy/dd/mm
          dateformatForDB = splitDate[1]+'/'+splitDate[2]+'/'+splitDate[0];        
        }
      break;
      case 'DMY': //    dd/mm/yyyy
        if(checkedDays) {
          dateformatForDB = splitDate[0]+'/'+splitDate[1]+'/'+splitDate[2];        
        } else {
          dateformatForDB = splitDate[1]+'/'+splitDate[0]+'/'+splitDate[2];        
        }
      break;
      case 'MDY':        
        if(checkedDays) {  // mm/dd/yyyy
          dateformatForDB = splitDate[0]+'/'+splitDate[1]+'/'+splitDate[2];
        } else {
          dateformatForDB = splitDate[0]+'/'+splitDate[1]+'/'+splitDate[2];        
        }
          
      break;
    }

    return dateformatForDB;
  }

  saveEvent(form: NgForm) {    
    console.log('form.value:',form.value)
    console.log('this.event:',this.event)
    this.errors = {};
    
    this.valid = form.valid;
    
    if (!Number.isInteger(form.controls.category_id.value * 1) && this.valid) {
      this.valid = false;
      this.categoryValid = false;
    }
    if(form.value.end_date == null && form.value.has_times_end){
      this.toasts.error("Please Select End Date");
      return;
    }

    if(form.value.category_id === '0'){
      this.toasts.error("Please Select a valid Category");
      return;
    }
    // if(form.value.start_date) {
    //   console.log('convertForDatabase')
    //     let ifStartChecked = (form.value.if_start_days) ? true: false;
    //     let test ='';
    //     console.log('Go for convertion', form.value.start_date, form.value.selecteddateformat)
    //     test = this.convertForDatabase(form.value.start_date, form.value.selecteddateformat, ifStartChecked)
    // }

    // if(form.value.end_date) {
    //   let ifChecked = (form.value.if_end_days) ? true: false;
    //   form.value.end_date = this.convertForDatabase(form.value.end_date, form.value.selecteddateformat, ifChecked)
    // }

    if(!this.date_regex.test(form.value.start_date) && form.value.start_date){
      this.toasts.error('Start Date should be in Format MM/DD/YYYY');
      return;
    }

    if(!this.date_regex.test(form.value.end_date) && form.value.end_date){
      this.toasts.error('End Date should be in Format MM/DD/YYYY');
      return;
    }
    
    if(form.value.has_times && (!form.value.start_time || form.value.start_time == 'Invalid date')){
      console.log('I am in has_time undefined')
      form.value.has_times = undefined;
      this.event.has_times = false;
      //delete();
      //this.toasts.error('Set time should not be empty');      
    }

    if(form.value.has_times_end && (!form.value.end_time || form.value.end_time == 'Invalid date') ){
      console.log('I am in has_times_end undefined')
      form.value.has_times_end = undefined;
      this.event.has_times_end = false;
      //delete();
      //this.toasts.error('Set time should not be empty');      
    }

    // if(form.value.start_time == 'Invalid date') {
     
    //   form.value.start_time = this.event.start_date;
    // } else {
    //   form.value.start_time = form.value.start_time;
    // }

    // if(form.value.end_time == 'Invalid date' || form.value.end_time == 'undefined') {
      
    //   form.value.end_time = this.event.end_date;
      
    // } else {
    //   form.value.end_time = form.value.end_time;
    // }
    
    if (this.valid) {
      
      let data: any = form.value;
      
      if(data.start_date && data.end_date){
        
        let endDate = new Date(data.end_date);
        let startDate = new Date(data.start_date);

        if(startDate.getTime() > endDate.getTime()){
          this.toasts.error('End Date should be greater than Start Date!');
          this.lesserEndDate = true;
          return;
        }
      }
      if (this.filesIds && this.filesIds.length > 0) {
        data.files = this.filesIds;
      }
      if (this.videosIds && this.videosIds.length > 0) {
        data.videos = this.videosIds;
      }
      if (this.privateFilesIds && this.privateFilesIds.length > 0) {
        data.private_attachments = this.privateFilesIds;
      }
      data.bill_cost_attachments = [];
      if (this.billCostFiles && this.billCostFiles.length > 0) {
        this.billCostFiles.forEach((item) => {
          data.bill_cost_attachments.push({
            id: item.file.id,
            amount: item.file.amount,
            attach_text: item.file.attach_text,
            bill_cost: item.file.bill_cost,
            file_id: item.file.file.id
          });
        })
      }
      if (this.event.bill_cost_files && this.event.bill_cost_files.length > 0) {
        this.event.bill_cost_files.forEach((item) => {
          data.bill_cost_attachments.push({
            id: item.id,
            amount: item.amount,
            attach_text: item.attach_text,
            bill_cost: item.bill_cost,
            file_id: item.file.id
          });
        })
      }
      if (this.contactsEventId && this.contactsEventId.length > 0) {
        data.contacts_id = this.contactsEventId;
      }
      data.thumbnail = this.event.thumbnail_attr;
      //data.start_date = data.start_date ? (data.start_date, data.has_times ? data.start_time : null): null;
      // data.end_date = data.end_date ?   (data.end_date, (data.has_times_end ? data.end_time : null)) : null;

      data.start_date = data.start_date;
      data.has_times = this.event.has_times;
      data.has_times_end = this.event.has_times_end;
      data.end_date = data.end_date? data.end_date : null;

      if ((this.sharedService.encriptedpaththumbnail == '') || (this.sharedService.encriptedpaththumbnail == null)) {
        this.thumbnailstatus = false;
        this.thumbnail_encryptid = '';
      } else {
        this.thumbnailstatus = true;
        this.thumbnail_encryptid = this.sharedService.encriptedpaththumbnail;
      }
      data.thumbnailstatus = this.thumbnailstatus;
      data.thumbnail_encryptid = this.thumbnail_encryptid ? this.thumbnail_encryptid : this.event.thumbnail_encryptid;
      // data.thumbnail_encryptid = this.thumbnail_encryptid;
      data.thumbnail_id = this.event.thumbnail_id ? this.event.thumbnail_id : null;
      this.eventtagslist = this.sharedService.tagarray;
      if (this.eventtagslist.length > 0) {
        data.tag = this.eventtagslist;
      } else {
        data.tag = [];
      }
      if (this.event.id) {
        this.updateEvent(data);
        this.filesIds = []
        this.billCostFiles = []
        this.event.files = []
        this.eventtagslist = [];
        //form.reset();
      } else {
        //not copying the value of custom fields, and copying just the fields.
        if(this.customField){
          this.customField.map(x=>{x.value=""});
        }
        
        data.custom_field = this.customField
        
        this.createEvent(data);
        
        this.event.title = '';
        this.event.date_start = null;
        this.event.if_start_days = false;
        this.event.has_times = false;
        this.event.has_times_end = false;
        this.event.date_end = null;
        this.event.if_end_days = false;
        this.event.bates_number;
        this.event.exhibit_number = '';
        this.event.description = '';
        this.event.provider_name = '';
        this.event.provider_phone = '';
        this.event.provider_email = '';
        this.event.facility_name = '';
        this.event.address = '';
        this.event.office_number = 0;
        this.event.city = '';
        this.event.state = '';
        this.event.postal_code = '';
        this.event.files = [];
        this.event.notes = ''
        this.filesIds = [];
        this.eventtagslist = [];
        this.billCostFiles = [];
      }

      this._changeDetectionRef.detectChanges();
    } else {
      this.toasts.error('Please, fill the required fields!');
      if (!Number.isInteger(form.controls.category_id.value * 1)) {
        this.categoryValid = false;
      }
    }

  }
   parseDateNew(date) {
    var myarr = date.split("T");
    var dateArr = myarr[0].split('-')
    let finaldate = dateArr[1]+"/"+dateArr[2]+"/"+dateArr[0].slice(-2);
    let myarrTime = myarr[1].split('+')[0]

     let checkingTime = myarrTime.split(':')
     if(checkingTime[0] != '00' || checkingTime[1] != '00'){
       finaldate = finaldate +" "+ this.tConvert(myarrTime);
     }
   return finaldate;
  }

   tConvert (time) {
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice (1);  // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join (''); // return adjusted time or original string
  }


  parseDate(date, time=null) {
    //console.log("date---------",date);
    // console.log("time",time);
    // let outDate = '';
    // if(time){
    //   outDate =  moment(time);
    // } else {
    //   console.log("time not set");
    //   time = "00:00:00"
    //   outDate =  moment(date).startOf(time);;
    // }

        // let outDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
        // time ? time.getUTCHours() : 0,
        // time ? time.getUTCMinutes() : 0,
        // time ? time.getUTCSeconds() : 0
        // )
    // let parsed = new Date(date);
    // let offset = parsed.getTimezoneOffset() * 60000;
    // // console.log(" new Date(parsed.getTime()-offset);",  new Date(parsed.getTime()-offset));

    // return new Date(parsed.getTime()-offset);

      //console.log("outDate",date,);
    return date;
  }

  updateEvent(data) {
    //console.log('update date', data);
    this.eventsResourceService.updateEvent(this.triallineId, this.event.id, data).subscribe((data) => {
      let event = data.body.event
        this.onUpdated.emit(event);
        this.toasts.success('Successfully updated');
        jQuery(this.modalId).modal('hide');
        if (event.tags_count > 0) {
          for (let i = 0; i < event.tag_name.length; i++) {
            this.sharedService.tagsid.push(event.tag_name[i].id)
          }
        }
      },(error) => {
        if (error.status == 422) {
          let errorData: any = error.json();
          this.errors = errorData.data;
        } else {
          this.toasts.error(error);
        }
      });
  }

  createEvent(data) {
    this.eventsResourceService.createEvent(this.triallineId, data).toPromise()
      .then((event) => {
        // @ts-ignore
        if (event.tags_count > 0) {
          // @ts-ignore
          for (let i = 0; i < event.tag_name.length; i++) {
            // @ts-ignore
            this.sharedService.tagsid.push(event.tag_name[i].id)
          }
        }
        // @ts-ignore
        this.onCreated.emit(event);
        this.toasts.success('Successfully created');
        jQuery(this.modalId).modal('hide');
        this.ngOnInit()
        jQuery("#general").css('collapse')
        this.event.title = '';
        this.event.date_start = '';
        this.event.if_start_days = false;
        this.event.has_times = false;
        this.event.has_times_end = false;
        this.event.date_end = '';
        this.event.if_end_days = false;
        this.event.bates_number = 0;
        this.event.exhibit_number = '';
        this.event.description = '';
        this.event.provider_name = '';
        this.event.provider_phone = '';
        this.event.provider_email = '';
        this.event.facility_name = '';
        this.event.address = '';
        this.event.office_number = 0;
        this.event.city = '';
        this.event.state = '';
        this.event.postal_code = '';
        this.event.files = [];
        this.event.notes = '';
        this.event.thumbnail_id = data.thumbnail_id;
        this.filesIds = [];
        this.ckeditor.instance.setData('<p></p>');
        this.ckeditor.instance.updateElement();
        this.ckeditor1.instance.setData('<p></p>');
        this.ckeditor1.instance.updateElement();

      })
      .catch((error) => {
        if (error.status == 422) {
          let errorData: any = error.error;
          this.errors = errorData.data;
        } else {
          this.toasts.error(error);
        }
      });
  }

  addCategory(category: Category) {
    this.categories.push(category);
    this.event.category_id = category.id;
    this.event.category = category;
    this.categoryValid = true;
  }

  updateFiles(files: FileModel[]) {
    this.event.files = files;
    this._changeDetectionRef.detectChanges();
  }

  addFile(file: FileModel) {
    if (!this.event.files) this.event.files = [];
    this.event.files.push(file);
    this.filesIds.push(file.id);
    this.exceptFileIds.push(file.id);
    this.sharedFiles.onFileSubmit(file);
    this._changeDetectionRef.detectChanges();
  }

  addContact(contact_id) {

    if (contact_id !== undefined){
      if(this.contactsEventId.indexOf(contact_id) == -1) {
        this.contactsEventId.push(contact_id);
        this._changeDetectionRef.detectChanges();
      } else {
        this.toasts.info('Contact has already been added.');
      }
    }else{
      this.toasts.info('Please select contact.');
    }
  }

  changeContacts(contacts) {
    for(let i = 0; i < contacts.length; i++) {
      if(this.contactsEventId.indexOf(contacts[i]) == -1) {
        this.contactsEventId.push(contacts[i]);
      }
    }
  }

  removeContact(contact) {
    let index = this.contactsEventId.indexOf(contact.id);
    this.contactsEventId.splice(index, 1);
    this._changeDetectionRef.detectChanges();
  }

  removeFile(file: FileModel) {
    this.fileLibraryResourceService.deleteFileFromEventNew(file, this.event).subscribe((response: any) => {
      this.toasts.success('Successfully deleted');
      let index = this.event.files?.indexOf(file);
      this.event.files?.splice(index, 1);
      let indexPrivate = this.event.private_files?.indexOf(file);
      this.event.private_files?.splice(indexPrivate, 1);
      this.event.bill_cost_files = this.event.bill_cost_files?.filter(item => {
        return item.file_id !== file.id
      })
      this.billCostFiles = this.billCostFiles.filter(item => item.file.id !== file.id)
      this.privateFilesIds = this.privateFilesIds.filter(item => item !== file.id);
      this.filesIds = this.filesIds.filter(item => item !== file.id);
      this._changeDetectionRef.detectChanges();
    }, (error) => {
      this.toasts.error(error);
    });
  }

  cloudFile(file: FileModel) {
    this.fileLibraryResourceService.cloudFile(file).subscribe((status: string) => {
        this.toasts.success('Successfully ' + status);
        let index = this.event.files.indexOf(file);
        this.event.files[index].in_library = !this.event.files[index].in_library;
      }, (error) => {
      this.toasts.error(error);
    });
  }

  openFromLibraryModal() {
    this.fromLibraryModal = true;
    this._changeDetectionRef.detectChanges();
    jQuery(this.fromLibraryModalId).modal('show');
  }

  thumbnailUploaded(path: string, encryptid = '', file_id) {
    this.event.thumbnail_attr = path;
    this.event.thumbnail_encryptid = encryptid;
    this.event.thumbnail_id = file_id;
    this.sharedService.encriptedpaththumbnail = '';
    this._changeDetectionRef.detectChanges();
  }

  deleteThumbnail(path) {
    if (decodeURI(path).indexOf(this.event.category.thumbnail) === -1) {
      this.event.thumbnail_attr = this.event.category.thumbnail;
      this.event.thumbnail_encryptid = '';
      this.sharedService.encriptedpaththumbnail = '';
      this._changeDetectionRef.detectChanges();
    }
  }

  showDeleteIcon(path) {
    if (this.event && this.event.category) {
      return decodeURI(path).indexOf(this.event.category.thumbnail) === -1 && decodeURI(path)
        .indexOf(this.defaultThumbnail) === -1;
    }
  }

  deleteEvent() {
    jQuery(this.modalId).modal('hide');
    this.onDelete.emit(this.event);
  }

  categoryChanged(id) {
    if (id) {
      let category = this.categories.find(e => e.id == id);
      // let previousCategory = this.event.category;
      // if (this.event.id) {
      //   if (
      //     (previousCategory &&
      //       this.event.thumbnail_attr.indexOf(previousCategory.thumbnail) !== -1) ||
      //     this.event.thumbnail_attr.indexOf(this.defaultThumbnail) !== -1) {
      //     this.event.thumbnail_attr = category.thumbnail;
      //     this.event.thumbnail_encryptid = category.thumbnail_encryptid;
      //   }
      // } else {
      //   if (!this.event.thumbnail_attr ||
      //     (this.event.thumbnail_attr && this.event.thumbnail_attr.indexOf(this.defaultThumbnail) !== -1) ||
      //     (previousCategory && this.event.thumbnail_attr.indexOf(previousCategory.thumbnail) !== -1)) {
      //     if (category) {
      //       this.event.thumbnail_attr = category.thumbnail;
      //       this.event.thumbnail_encryptid = category.thumbnail_encryptid;
      //     }
      //   }
      // }

      this.categoryValid = !!category;
      this.event.category = category;
      this._changeDetectionRef.detectChanges();
    }
  }

  uploadVideo(video_url) {

    this.eventsResourceService.uploadVideo(this.triallineId, video_url).toPromise()
      .then((video) => {
        this.event.videos = this.event.videos ? this.event.videos : [];
        // @ts-ignore
        this.event.videos.push(video);
        // @ts-ignore
        this.videosIds.push(video.id);
      })
      .catch((e: any) => {
        console.log(e)
      })
  }

  deleteVideo(video: Video) {
    this.eventsResourceService.deleteVideo(this.triallineId, this.event.id, video.id).toPromise()
      .then((video: any) => {
        let index = this.event.videos.indexOf(video);
        this.event.videos.splice(index, 1);
      })
      .catch((e: any) => {
        console.log(e)
      })
  }

  closeAddFilesModal() {
    jQuery(this.fromLibraryModalId).modal('hide');
    this.eventtagslist = [];
  }

  closeModal() {
    this.onClose.emit(this.event);
  }

  onCKChange(description: any) {
    let TextGrab = description.editor.getData();
    TextGrab = $(TextGrab).text(); // html to text
    TextGrab = TextGrab.replace(/\r?\n|\r/gm, " "); // remove line breaks
    TextGrab = TextGrab.replace(/\s\s+/g, " ").trim();
    let res1 = TextGrab.substr(TextGrab.length - 1)
    if (res1 == "#") {
      this.status1 = true;
      jQuery("#triallinemodal").modal('show');
      jQuery($('#triallinemodal').css('top:70%'));
    } else {
      this.status1 = false;
      jQuery("#triallinemodal").modal('hide');
    }
  }


  getTriallineLink(trlID, trlName) {
    this.prev_pos = this.cursor_position + trlName.length;
    this.status1 = false;
    if (this.event.description == null) {
      this.event.description = '';
    }
    this.alltr += trlName;
    this.event.description = this.event.description.replace('#</p>', '');
    this.event.description +=(' <a target="_blank" title=\x22' + trlName
      + '\x22 href=\x22https://trialline.net/trialline/' + trlID + '\x22>' + trlName + '</a>');

  }

  showaccordian(id) {
    const $general = jQuery('#general');
    const $providerDetails = jQuery('#provider-details');
    const $addContact = jQuery('#add-contact');
    const $addMedia = jQuery('#add-media');
    const $notes = jQuery('#notes');
    const $addTag = jQuery('#add-tag');
    const $billCost = jQuery('#add-bill-cost');
    if (id == 1) {
      $general.collapse('show');
      $providerDetails.collapse('hide');
      $addMedia.collapse('hide');
      $notes.collapse('hide');
      $addContact.collapse('hide');
      $addTag.collapse('hide');
      $billCost.collapse('hide');
    } else if (id == 2) {
      $general.collapse('hide');
      $providerDetails.collapse('show');
      $addMedia.collapse('hide');
      $addContact.collapse('hide');
      $notes.collapse('hide');
      $addTag.collapse('hide');
      $billCost.collapse('hide');
    } else if (id == 3) {
      $general.collapse('hide');
      $providerDetails.collapse('hide');
      $addMedia.collapse('hide');
      $addContact.collapse('show');
      $notes.collapse('hide');
      $addTag.collapse('hide');
      $billCost.collapse('hide');
    } else if (id == 4) {
      $general.collapse('hide');
      $providerDetails.collapse('hide');
      $addMedia.collapse('show');
      $notes.collapse('hide');
      $addTag.collapse('hide');
      $addContact.collapse('hide');
      $billCost.collapse('hide');
    } else if (id == 6) {
      $general.collapse('hide');
      $providerDetails.collapse('hide');
      $addMedia.collapse('hide');
      $notes.collapse('show');
      $addTag.collapse('hide');
      $addContact.collapse('hide');
      $billCost.collapse('hide');
    } else if (id == 5) {
      $general.collapse('hide');
      $providerDetails.collapse('hide');
      $addMedia.collapse('hide');
      $notes.collapse('hide');
      $addTag.collapse('show');
      $addContact.collapse('hide');
      $billCost.collapse('hide');
      this.eventtagslist = this.sharedService.tagarray
    } else if (id == 7) {
      $general.collapse('hide');
      $providerDetails.collapse('hide');
      $addMedia.collapse('hide');
      $notes.collapse('hide');
      $addTag.collapse('hide');
      $addContact.collapse('hide');
      $billCost.collapse('show');
    }
  }

  openthumbnailFromLibraryModal(e) {
    if (this.firsttime == false) {
      jQuery("#add-thumbnail-from-library-modal").modal('hide');
    } else {
      jQuery("#add-thumbnail-from-library-modal").modal('show');
    }
    this._changeDetectionRef.detectChanges();
  }

  getFileName(name: string) {
    if (name) {
      return name.replace(/\d{9,}_/, '');
    }
    return name;
  }

  selectFileeventthumbnail(file) {

    this.thumbnailstatus = true;
    this.encrypt_id = file.encrypt_id
    this.sharedService.encriptedpaththumbnail = file.encrypt_id
    this.event.thumbnail_encryptid = file.encrypt_id
    //console.log("caseeeee",this.sharedService.encriptedpaththumbnail)
    jQuery("#add-thumbnail-from-library-modal").modal('hide');
  }

  closePreviewer1() {
    this.sharedService.encriptedpaththumbnail = ''
    jQuery("#add-thumbnail-from-library-modal").modal('hide');
  }

  OpenTrlFolderthumbnail(Fname) {
    if (this.thumbnailpath == undefined || this.thumbnailpath == '') {
      this.thumbnailpath = Fname;
    } else {
      this.thumbnailpath = (this.thumbnailpath).concat('/' + Fname);
    }
    this.thumbnailbackfolder = this.thumbnailpath.split("/", 100);
    this.thumbnailfilesarray = [];
    this.ttfolder = [];
    for (let i = 0; i < this.thumbnailfiles.general.length; i++) {
      let tte = this.thumbnailpath;
      let patharray1 = (this.thumbnailfiles.general[i].original_path.split('/'));
      let joinedArray1 = patharray1.slice(2, tte.split("/", 100).length + 2).join("/");
      if (this.thumbnailfiles.general[i].type == 'image') {
        if (joinedArray1 == this.thumbnailpath) {
          if ((this.thumbnailfiles.general[i].original_path.indexOf(this.thumbnailpath) !== -1)) {
            let indexid = (this.thumbnailpath.split("/", 100).length) + 2;
            if (((this.thumbnailfiles.general[i].original_path)
              .split("/", 100)).length - 3 == (this.thumbnailpath.split("/", 100).length)) {
              this.thumbnailfilesarray.push(this.thumbnailfiles.general[i])
            } else {
              let splittext = (this.thumbnailfiles.general[i].original_path).split("/", 100)[indexid]
              if (this.ttfolder.includes(splittext) == false) {
                this.ttfolder.push((this.thumbnailfiles.general[i].original_path).split("/", 100)[indexid]);
              }
            }
          }
        }
      }
    }

    for (let j = 0; j < this.thumbnailfiles.trialline.length; j++) {
      let ttet = this.thumbnailpath;
      let patharray = (this.thumbnailfiles.trialline[j].original_path.split('/'));
      let joinedArray = patharray.slice(2, ttet.split("/", 100).length + 2).join("/");
      if (this.thumbnailfiles.trialline[j].type == 'image') {
        if (joinedArray == this.thumbnailpath) {
          if ((this.thumbnailfiles.trialline[j].original_path.indexOf(this.thumbnailpath) !== -1)) {
            let indexid = (this.thumbnailpath.split("/", 100).length) + 2;
            if (((this.thumbnailfiles.trialline[j].original_path)
              .split("/", 100)).length - 3 == (this.thumbnailpath.split("/", 100).length)) {
              this.thumbnailfilesarray.push(this.thumbnailfiles.trialline[j])
            } else {
              let splittext = (this.thumbnailfiles.trialline[j].original_path).split("/", 100)[indexid]
              if (this.ttfolder.includes(splittext) == false) {
                this.ttfolder.push((this.thumbnailfiles.trialline[j].original_path).split("/", 100)[indexid]);
              }
            }
          }
        }
      }
    }
  }

  thumbnaillevelup() {
    this.thumbnailpath = this.thumbnailpath.split('/');
    this.thumbnailpath.pop();
    this.thumbnailpath = this.thumbnailpath.join('/');
    this.thumbnailfilesarray = [];
    this.ttfolder = [];
    if (this.thumbnailpath / length < 1) {
      this.ngOnInit();
    } else {
      for (let i = 0; i < this.thumbnailfiles.general.length; i++) {
        let tte = this.thumbnailpath;
        let patharray1 = (this.thumbnailfiles.general[i].original_path.split('/'));
        let joinedArray1 = patharray1.slice(2, tte.split("/", 100).length + 2).join("/");
        if (this.thumbnailfiles.general[i].type == 'image') {
          if (joinedArray1 == this.thumbnailpath) {
            if ((this.thumbnailfiles.general[i].original_path.indexOf(this.thumbnailpath) !== -1)) {
              let indexid = (this.thumbnailpath.split("/", 100).length) + 2;
              if (((this.thumbnailfiles.general[i].original_path)
                .split("/", 100)).length - 3 == (this.thumbnailpath.split("/", 100).length)) {
                this.thumbnailfilesarray.push(this.thumbnailfiles.general[i])
              } else {
                let splittext = (this.thumbnailfiles.general[i].original_path).split("/", 100)[indexid]
                if (this.ttfolder.includes(splittext) == false) {
                  this.ttfolder.push((this.thumbnailfiles.general[i].original_path)
                    .split("/", 100)[indexid]);
                }
              }
            }
          }
        }
      }
      for (let j = 0; j < this.thumbnailfiles.trialline.length; j++) {
        let ttet = this.thumbnailpath;
        let patharray = (this.thumbnailfiles.trialline[j].original_path.split('/'));
        let joinedArray = patharray.slice(2, ttet.split("/", 100).length + 2).join("/");
        if (this.thumbnailfiles.trialline[j].type == 'image') {
          if (joinedArray == this.thumbnailpath) {
            if ((this.thumbnailfiles.trialline[j].original_path.indexOf(this.thumbnailpath) !== -1)) {
              let indexid = (this.thumbnailpath.split("/", 100).length) + 2;
              if (((this.thumbnailfiles.trialline[j].original_path)
                .split("/", 100)).length - 3 == (this.thumbnailpath.split("/", 100).length)) {
                this.thumbnailfilesarray.push(this.thumbnailfiles.trialline[j])
              } else {
                let splittext = (this.thumbnailfiles.trialline[j].original_path).split("/", 100)[indexid]
                if (this.ttfolder.includes(splittext) == false) {
                  this.ttfolder.push((this.thumbnailfiles.trialline[j].original_path).split("/", 100)[indexid]);
                }
              }
            }
          }
        }
      }
    }
  }

  eventtags(tag = null) {
    if (tag === null) {
      const tag = jQuery('#tag').val();
    }
    if ((tag.trim() != '') || (tag.trim() != null)) {
      if ((this.eventtagslist.some(e => e.tag_name === tag.trim().toUpperCase())) == true) {
        this.toasts.error('Sorry,Tag is Already Exists.');
      } else {
        this.eventtagslist.push({'tag_name': tag.trim().toUpperCase()});
        this.sharedService.newaddtag.push(tag.trim())
        $('#tag').val('');
      }
    }
  }

  removetag(tag) {
    this.eventtagslist = this.eventtagslist.filter(item => item !== tag);
    this.sharedService.tagarray = this.eventtagslist;
    let data = [];
    if (tag.id != undefined) {
      data.push({'tag_id': tag.id, 'event_id': tag.event_id})
      this.eventsResourceService.deletetag(this.triallineId, data)
    }
  }

  getparticulartag(event) {
    if (event.keyCode == 13){
      this.eventtags();
    }else{
      let data = [];
      const $tag = jQuery('#tag');
      this.searchtag = [];
      if (($tag.val().trim() == '') || ($tag.val().trim() == null)) {
        this.searchtag = [];
      } else {
        data.push({"search_tag": $tag.val().trim()})
        this.eventsResourceService.searchtag(this.triallineId, data).toPromise()
          .then((response) => {
            this.searchtag = response !== null ? response : [];
          }).catch((error) => {
            console.log(error)
          })
      }
    }
  }

  rename(file_id, attachText, amount, bill_cost ) {
    if (!amount || !attachText) {
      this.toasts.error('Attach text and amount fields is required');
    } else {
      this.triallineResoureService.renameFiles({
        file_id,
        attachText,
        amount,
        bill_cost,
        type: 'bill_cost'
      })
        .toPromise()
        .then((response) => {
          this.toasts.success('Attach text field edited');
        })
    }
  }

  fileSelected(event) {
    this.billCostUploader.queue.forEach((item: FileItem) => {
      // @ts-ignore
      if (!item.file.id) {
        // @ts-ignore
        item.options.additionalParameter = {
          attachText: item.file.name,
          is_private: 1,
          trialline_id: this.triallineId,
          type: 'bill_cost',
        };
        item.upload();
      }
    })
  }

  addtag(tag) {
    this.eventtags(tag);
    this.searchtag = [];
  }

  Test() {
    this.firsttime = true
  }

  mouseleavefunction() {
    this.firsttime = false
  }

  getkeyvalue(event) {
    $(":focus").each(function () {
      if ($(this).attr("id") == 'tag') {
      }
    });
  }

  hidemodal() {
    this.status1 = false;
    jQuery("#triallinemodal").modal('hide');
  }

  eventCopy() {
    jQuery(this.modalId).modal('hide');
    jQuery("#triallinemodal").modal('hide');
    this.onCopy.emit(this.event);
  }

  //compare startdate and endDate
  // To show error if EndDate is smaller than startDate

  compareEndDate(){
    //console.log('function hit');
  }


}
