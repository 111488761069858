import {NgModule} from '@angular/core';
import {HttpClientModule} from "@angular/common/http";
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {ColorPickerModule} from "ngx-color-picker";
import {TriallineRoutingModule} from "./trialline-routing.module";
import {TriallineEditComponent} from "./edit/edit.component";
import {TriallineResolver} from "./shared/trialline.resolver";
import {TriallineResourceService} from "./shared/trialline-resoruce.service";
import {CKEditorModule} from 'ckeditor4-angular';
import {SharedComponentsModule} from "../../shared-components/shared-components.module";
import {EventsModule} from "./events/events.module";
import {FileUploadModule} from "ng2-file-upload";
import {TriallineEditCategoriesComponent} from "./edit/categories/categories.component";
import {TriallineEditCategoriesEditComponent} from "./edit/categories/edit/edit.component";
import {TriallineContactsComponent} from "./contacts/contacts.component";
import {TriallineContactsEditComponent} from "./contacts/edit/edit.component";
import {ContactEditModalCategoriesComponent} from "./contacts/edit/categories/categories.component";
import {ModalModule} from "ngx-modialog-7";
import {BootstrapModalModule} from "ngx-modialog-7/plugins/bootstrap";
import {TriallineManageComponent} from "./manage/manage.component";
import {ManageResolver} from "./manage/shared/manage.resolver";
import {LayoutsModule} from "../../layouts/layouts.module";
import {TriallineManageCommentsComponent} from "./manage/comments/comments.component";
import {TriallineViewsComponent} from "./views/views.component";
import {ViewsResolver} from "./views/shared/views.resolver";
import {ViewsResourceService} from "./views/shared/views-resoruce.service";
import {ConfirmationPopoverModule} from "angular-confirmation-popover";
import {ClipboardModule} from "ngx-clipboard";
import {TriallineManageSaveViewComponent} from "./manage/save-view/save-view.component";
import {TriallineEditTeamComponent} from "./edit/team/team.component";
import {TriallineCreateAccessComponent} from "./edit/team/create-access-team.component";
import {BackgroundsResolver} from "./edit/shared/backgrounds.resolver";
import {VisibleEventsPipe} from "./manage/shared/visible-events.pipe";
import {VisibleCategoriesPipe} from "./manage/shared/visible-categories.pipe";
import {OrderByNamePipe} from "./manage/shared/order-by-name.pipe";
import { FileEditCaseComponent } from './file-edit-case/file-edit-case.component';
import { TagsComponent } from './edit/tags/tags.component';
import {NgxPaginationModule} from "ngx-pagination";
import {ValidatorModule} from "../../form-validator/validator.module";
import {AutocompleteLibModule} from "angular-ng-autocomplete";
import { NgxCaptureModule } from 'ngx-capture';
import {NgSelectModule} from "@ng-select/ng-select";
import { TooltipModule } from 'ngx-bootstrap/tooltip';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    TriallineRoutingModule,
    CKEditorModule,
    SharedComponentsModule,
    EventsModule,
    FileUploadModule,
    ColorPickerModule,
    ModalModule.forRoot(),
    BootstrapModalModule,
    LayoutsModule,
    ConfirmationPopoverModule.forRoot(),
    ClipboardModule,
    NgxPaginationModule,
    ValidatorModule,
    AutocompleteLibModule,
    NgxCaptureModule,
    NgSelectModule,
    TooltipModule,
  ],
  declarations: [
    TriallineEditComponent,
    TriallineEditCategoriesComponent,
    TriallineEditCategoriesEditComponent,
    TriallineContactsComponent,
    TriallineContactsEditComponent,
    TriallineManageCommentsComponent,
    TriallineManageComponent,
    TriallineViewsComponent,
    TriallineManageSaveViewComponent,
    TriallineEditTeamComponent,
    TriallineCreateAccessComponent,
    VisibleEventsPipe,
    VisibleCategoriesPipe,
    OrderByNamePipe,
    FileEditCaseComponent,
    TagsComponent,
    ContactEditModalCategoriesComponent
  ],
  providers: [
    TriallineResolver,
    TriallineResourceService,
    ManageResolver,
    ViewsResolver,
    BackgroundsResolver,
    ViewsResourceService
  ]
})
export class TriallineModule {}
