import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Event} from "../../../shared/event.model";
import {PresentResourceService} from "../../../../present/shared/present-resoruce.service";
import {NgForm} from "@angular/forms";
import {ToastrService} from 'ngx-toastr';
import {env} from "../../../../../../.env";
import {Modal} from 'ngx-modialog-7/plugins/bootstrap';
import {EventsResourceService} from "../shared/events-resoruce.service";
import {TriallineEventsComponent} from "../events.component";
import * as screenfull from 'screenfull';
import {TriallinePyramidComponent} from "../../../../shared-components/trialline-pyramid/trialline-pyramid.component";
import {any} from "codelyzer/util/function";


declare var jQuery: any;
declare var moment: any;


@Component({
  selector: 'app-trialline-event-more-modal',
  templateUrl: './more.component.html',
  styleUrls: ['./more.component.css']
})

export class EventMoreModalComponent implements  OnInit, AfterViewInit {
  @Input() isView: boolean;
  @Input() viewPassword: string;
  @Input() viewUuid: string;
  @Input() event: Event;
  @Input() eventsIdsOrder: number[];
  @Input() eventsIdsOrderTracks: number[];
  @Input() view: number;
  @Input() comments: boolean;
  @Input() answer_comment: boolean;
  @Input() triallineId: number;
  @Input() activeTab: string;
  @Input() currentCustomColumn: any;

  @Output() onClose = new EventEmitter<Event>();
  @Output() onComment = new EventEmitter<any>();
  viewId: number = 0;
  moreFiles: boolean = true;
  modalId = '#event-more-modal';
  publicFolderUrl: string = env.publicFolderUrl;
  publicImages = env.publicImages;
  processing: boolean = false;
  defaultThumbnail: string = '/images/start-event/trialline-event-thumb.jpg';
  fullscreen: boolean = false;
  fullscreenButtonVisible = true;
  showtwodate: boolean = true;
  monthNames: string[] = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "June", "July", "Aug.", "Sept.", "Oct.", "Nov.", "Dec."];
  customColumns: any = [];
  fullIpad: any;


  constructor(
     public presentResourceService: PresentResourceService,
     public eventsResourceService: EventsResourceService,
     public _changeDetectionRef: ChangeDetectorRef,
     public modal: Modal,
     public toasts: ToastrService
  ) {}

  ngOnDestroy() {
    this._changeDetectionRef.detach();
  }

  fullscreenToggle() {
    if (screenfull && typeof screenfull.on !== "undefined") {
          screenfull.toggle();
        } else if(screenfull === false && navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null && !this.fullIpad) {
          this.fullIpad = true;
          this.fullscreen = true;
          setTimeout( ()=>{
            jQuery('#preview-modal').find('.zoom_active').css({'position': 'fixed',
              'display': 'inline-grid', 'left': '-35px', 'top': '-100px', 'width': '110%', 'max-height': '160%',
              'min-height': '102%'});
            jQuery('#preview-modal').find('.zoom_deactivate').css({'position': 'fixed',
              'display': 'inline-grid', 'left': '-35px', 'top': '-100px', 'width': '110%', 'max-height': '160%',
              'min-height': '102%'});
            let height = jQuery(window).height();
            if (height < 750) {
              height = height - 50;
            } else if (height >= 750 && height < 900) {
              height = height - 200;
            } else {
              height = height - 170;
            }
            jQuery('#preview-modal').find('.zoom_active').find('.modal-content').css({ 'height': height + 'px'});
          }, 200)
        } else if(screenfull === false && navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null && this.fullIpad) {
          this.fullIpad = false;
          this.fullscreen = false;
          jQuery('#preview-modal').find('.modal-preview').removeAttr( "style" );
          jQuery('#preview-modal').find('.zoom_deactivate').attr("style","");
          jQuery('#preview-modal').find('.modal-preview').find('.modal-content').removeAttr( "style" );
        }
  }

  ngOnInit() {
    console.log(this.event)
    if (this.event.end_date !== null) {
      if ((this.event.if_start_days) && (this.event.if_end_days)) {
        if ((this.event.end_date.split(' ')[0]).split('-')[1] == (this.event.start_date.split(' ')[0]).split('-')[1]) {
          this.showtwodate = false
        } else {
          this.showtwodate = true;
        }

      }
    }
    if (!this.activeTab) {
      this.activeTab = 'notes'
    }
    this.event.thumbnailImage = this.setThumbnailImage(this.event.thumbnail_attr, this.event.thumbnail_visible);
    this.customColumns = JSON.parse(this.event.custom_field);
  }
  closePopup(){
    this.onClose.emit(this.event);
  }

  setViewId (viewId) {
    this.viewId = viewId;
  }

  parseYear(date: any) {
    return new Date(date).getUTCFullYear()
  }

  parseMonth(date: any) {
    return this.monthNames[new Date(date).getUTCMonth()]
  }

  parseDay(date: any) {
    return parseInt(new Date(date).toString().substring(0, 10).split(' ')[2])
  }

  parseTime(time: any = null) {
    const arr = time.split(/[- :]/);
    const dt = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    return dt.toLocaleString([], {hour: 'numeric', minute: 'numeric', hour12: true})
  }

  storeComment(form: NgForm) {
    let comment = form.value.comment;
    const data = {
      comment: form.value.comment,
      id: this.event.id,
      viewId: this.viewId
    };
    if (!comment) {
      this.toasts.error('Comment can not be empty')
    } else {
      this.onComment.emit(data);
      form.reset();
      this.viewId = 0
    }
  }

  ngAfterViewInit() {
    let self = this;

    if (screenfull && typeof screenfull.on !== "undefined") {
      this.fullscreenButtonVisible = true;
      screenfull.on('change', () => {
        this.fullscreen = screenfull.isFullscreen;
      });
    }

    jQuery(this.modalId).on('hidden.bs.modal', function (e) {
      if (!jQuery(this).hasClass('in')) {
        self.onClose.emit(self.event);
        if (screenfull && typeof screenfull.on !== "undefined" && screenfull.isFullscreen) {
          screenfull.toggle()
        }
      } else {
        jQuery('body').addClass('modal-open');
      }
    })
  }

  toggleClassFadeIn() {
    jQuery('#event-more-modal .modal-dialog').toggleClass('slide-in-fwd-center');
  }

  toggleClassFadeOut() {
    jQuery('#event-more-modal .modal-dialog').toggleClass('slide-in-fwd-center-reverse');
  }

  nextEvent() {
    this.toggleClassFadeIn();
    this.toggleClassFadeOut();
    this.activeTab = 'description';
    jQuery('#preview-modal').modal('hide');
    jQuery('#files').removeAttr( "style");
    jQuery('.tab-content').removeAttr( "style");
    this.processing = true;
    let index = this.eventsIdsOrder.indexOf(this.event.id);
    let id = index + 1 == this.eventsIdsOrder.length ? this.eventsIdsOrder[0] : this.eventsIdsOrder[index + 1];
    if (this.view == 3) {
      index = this.eventsIdsOrderTracks.indexOf(this.event.id);
      id = index + 1 == this.eventsIdsOrderTracks.length ? this.eventsIdsOrderTracks[0]
        : this.eventsIdsOrderTracks[index + 1];
    }
    this.loadEvent(id);
  }

  prevEvent() {
    this.toggleClassFadeIn();
    this.toggleClassFadeOut();
    this.activeTab = 'description';
    jQuery('#preview-modal').modal('hide');
    jQuery('#files').removeAttr( "style");
    jQuery('.tab-content').removeAttr( "style");
    this.processing = true;
    let index = this.eventsIdsOrder.indexOf(this.event.id);
    let id = index == 0 ? this.eventsIdsOrder[this.eventsIdsOrder.length - 1] : this.eventsIdsOrder[index - 1];
    if (this.view == 3) {
      index = this.eventsIdsOrderTracks.indexOf(this.event.id);
      id = index == 0 ? this.eventsIdsOrderTracks[this.eventsIdsOrderTracks.length - 1]
        : this.eventsIdsOrderTracks[index - 1];
    }
    this.loadEvent(id);
  }

  loadEvent(id: number) {
    if (id !== undefined) {
      this.showtwodate = true
      setTimeout (() => {
        if (this.isView) {
          this.presentResourceService.getEvent(this.viewUuid, id, this.viewPassword).toPromise()
            .then((event: Event) => {
              this.processing = false;
              this.event = event;
              if (this.event.end_date !== null) {
                if ((this.event.if_start_days) && (this.event.if_end_days)) {
                  this.showtwodate = (this.event.end_date.split(' ')[0]).split('-')[1] != (this.event.start_date.split(' ')[0]).split('-')[1];
                }
              }
              this.event.date_start = TriallineEventsComponent.parseDate(this.event.date_start);
              if (this.event.date_end) {
                this.event.date_end = TriallineEventsComponent.parseDate(this.event.date_end);
              }
              this.event.thumbnailImage = this.setThumbnailImage(event.thumbnail_attr, event.thumbnail_visible);
              this._changeDetectionRef.detectChanges();
              this.activeTab = 'description';
              this.toggleClassFadeOut();
              this.toggleClassFadeIn();
              setTimeout (() => {
                if (!jQuery('#event-flag-'+id).length) {
                  jQuery('.event-flag-'+id).trigger('click');
                } else {
                  jQuery('#event-flag-'+id).trigger('click');
                }
                if (!jQuery('#event-flag-'+ (id * 1 + 100000000)).length) {
                  jQuery('.event-flag-'+(id * 1 +100000000)).trigger('click');
                } else {
                  jQuery('#event-flag-'+(id * 1 + 100000000)).trigger('click');
                }
                if (!jQuery('#event-flag-'+(id * 1 + 200000000)).length) {
                  jQuery('.event-flag-'+(id * 1 + 200000000)).trigger('click');
                } else {
                  jQuery('#event-flag-'+(id * 1 + 200000000)).trigger('click');
                }
                if (!jQuery('#event-flag-'+(id * 1 + 300000000)).length) {
                  jQuery('.event-flag-'+(id * 1 + 300000000)).trigger('click');
                } else {
                  jQuery('#event-flag-'+(id * 1 + 300000000)).trigger('click');
                }
              }, 500);
            })
            .catch((error) => {
              this.toggleClassFadeOut();
              this.toggleClassFadeIn();
              setTimeout (() => {
                if (!jQuery('#event-flag-'+id).length) {
                  jQuery('.event-flag-'+id).trigger('click');
                } else {
                  jQuery('#event-flag-'+id).trigger('click');
                }
                if (!jQuery('#event-flag-'+ (id * 1 + 100000000)).length) {
                  jQuery('.event-flag-'+(id * 1 +100000000)).trigger('click');
                } else {
                  jQuery('#event-flag-'+(id * 1 + 100000000)).trigger('click');
                }
                if (!jQuery('#event-flag-'+(id * 1 + 200000000)).length) {
                  jQuery('.event-flag-'+(id * 1 + 200000000)).trigger('click');
                } else {
                  jQuery('#event-flag-'+(id * 1 + 200000000)).trigger('click');
                }
                if (!jQuery('#event-flag-'+(id * 1 + 300000000)).length) {
                  jQuery('.event-flag-'+(id * 1 + 300000000)).trigger('click');
                } else {
                  jQuery('#event-flag-'+(id * 1 + 300000000)).trigger('click');
                }
              }, 500);
              this.processing = false;
              this.toasts.error(error);
            });
        } else {
          this.eventsResourceService.getEvent(this.triallineId, id, 'category,files,files.billcosts,files.contacts.categories,videos,comments,contacts,contacts.categories,contacts.privateFiles', false)
            .subscribe(
              (res: any) => {
                let event = res.body.event;
                this.processing = false;
                this.event = event;

                if (this.event.end_date !== null) {
                  if ((this.event.if_start_days) && (this.event.if_end_days)) {
                    this.showtwodate = (this.event.end_date.split(' ')[0]).split('-')[1] != (this.event.start_date.split(' ')[0]).split('-')[1];
                  }
                }
                /* END */
                this.event.thumbnailImage = this.setThumbnailImage(event.thumbnail_attr, event.thumbnail_visible);

                this.event.date_start = TriallineEventsComponent.parseDate(this.event.date_start);
                if (this.event.date_end) {
                  this.event.date_end = TriallineEventsComponent.parseDate(this.event.date_end)
                }
                this._changeDetectionRef.detectChanges();
                this.activeTab = 'description';
                this.toggleClassFadeOut();
                this.toggleClassFadeIn();
                setTimeout (() => {
                  if (!jQuery('#event-flag-'+id).length) {
                    jQuery('.event-flag-'+id).trigger('click');
                  } else {
                    jQuery('#event-flag-'+id).trigger('click');
                  }
                  if (!jQuery('#event-flag-'+ (id * 1 + 100000000)).length) {
                    jQuery('.event-flag-'+(id * 1 +100000000)).trigger('click');
                  } else {
                    jQuery('#event-flag-'+(id * 1 + 100000000)).trigger('click');
                  }
                  if (!jQuery('#event-flag-'+(id * 1 + 200000000)).length) {
                    jQuery('.event-flag-'+(id * 1 + 200000000)).trigger('click');
                  } else {
                    jQuery('#event-flag-'+(id * 1 + 200000000)).trigger('click');
                  }
                  if (!jQuery('#event-flag-'+(id * 1 + 300000000)).length) {
                    jQuery('.event-flag-'+(id * 1 + 300000000)).trigger('click');
                  } else {
                    jQuery('#event-flag-'+(id * 1 + 300000000)).trigger('click');
                  }
                }, 500);
              },
              (error: any) => {
                this.toggleClassFadeOut();
                this.toggleClassFadeIn();
                setTimeout (() => {
                  if (!jQuery('#event-flag-'+id).length) {
                    jQuery('.event-flag-'+id).trigger('click');
                  } else {
                    jQuery('#event-flag-'+id).trigger('click');
                  }
                  if (!jQuery('#event-flag-'+ (id * 1 + 100000000)).length) {
                    jQuery('.event-flag-'+(id * 1 +100000000)).trigger('click');
                  } else {
                    jQuery('#event-flag-'+(id * 1 + 100000000)).trigger('click');
                  }
                  if (!jQuery('#event-flag-'+(id * 1 + 200000000)).length) {
                    jQuery('.event-flag-'+(id * 1 + 200000000)).trigger('click');
                  } else {
                    jQuery('#event-flag-'+(id * 1 + 200000000)).trigger('click');
                  }
                  if (!jQuery('#event-flag-'+(id * 1 + 300000000)).length) {
                    jQuery('.event-flag-'+(id * 1 + 300000000)).trigger('click');
                  } else {
                    jQuery('#event-flag-'+(id * 1 + 300000000)).trigger('click');
                  }
                }, 500);
                console.log('error' + error);
                this.processing = false;
                this.toasts.error(error);
              }
            );
        }
      }, 600);

      this.showtwodate = true

    } else {
      this.processing = false;
      this.toasts.error('error');
    }
  }

  setThumbnailImage(path: string, visible: boolean) {
    let obj = {};
    if (visible) {
      obj = {
        'background': 'url(\'' + this.publicImages +
          (path ? path.substring(1).replace("'", "\\'") : this.defaultThumbnail.substring(1)) + '\') center center no-repeat'
      }
    }
    return obj;
  }
  currentCustomColumnValue(column){
    console.log(column);
    this.currentCustomColumn = column;
  }
}
