import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter, HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {FileModel} from "../../main/file-library/shared/file.model";
import {env} from "../../../../.env";
import {DomSanitizer} from "@angular/platform-browser";
import * as screenfull from "screenfull";
import {Screenfull} from "screenfull";

declare var jQuery:any;

const enum Status {
  OFF = 0,
  RESIZE = 1,
  MOVE = 2
}

@Component({
  selector: 'app-shared-previewer',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements AfterViewInit, OnInit{

  @Input() file: FileModel;
  @Input() showDownload: any;
  @Output() onClosePreviewer = new EventEmitter<any>();
  apiPath = env.apiUrl;
  fullscreen: boolean = false;
  pdfpath: any;
  width: number;
  height: number;
  left: number = 0;
  top: number = 0;
  private boxPosition: { left: number, top: number };
  private containerPos: { left: number, top: number, right: number, bottom: number };
  public mouse: {x: number, y: number}
  public status: Status = Status.OFF;
  private mouseClick: {x: number, y: number, left: number, top: number}
  @ViewChild("previewModal2")  public previewModal2: ElementRef;
  screenW: any = false;
  pdfpathIpad: any;
  constructor(
    private _changeDetectionRef: ChangeDetectorRef,
    private _sanitizer: DomSanitizer
  ) {  }

  ngOnDestroy() {
    this._changeDetectionRef.detach();
  }

  closePreviewer() {
    this.width = 630;
    this.height = 560;
    this.left = 0;
    this.top = 0;
    jQuery('#preview-modal-shared').modal('hide');
    this.onClosePreviewer.emit(true);
    if ( this.file !== undefined) {
      if ( this.file.type == 'video') {
        let file = this.file;
        this.file = new FileModel();
        this._changeDetectionRef.detectChanges();
        this.file = file;
      }
    }
  }

  // zoomPreviewToggle() {
  //   if (screenfull && typeof screenfull.on !== "undefined") {
  //         screenfull.toggle();
  //     }
  // }

  ngViewInit() {
  }

  ngOnInit() {
    if (navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null) {
      this.screenW = true;
      this.pdfpathIpad = this.getSafeResourceUrlPdf();
      document.getElementById("files").setAttribute("style", "display:initial;");
    } else {
      this.screenW = false;
      this.pdfpath = this._sanitizer.bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/' + this.file.encrypt_id);
    }
  }

  ngAfterViewInit()
  {
    if(this.showDownload == undefined) {
      this.showDownload = false;
    }
    let self = this;
    jQuery('#preview-modal-shared').on('hidden.bs.modal', function (e) {

      self.closePreviewer();
    });
    if (screenfull && typeof screenfull.on !== "undefined") {
        screenfull.on('change', () => {
            this.fullscreen = screenfull.isFullscreen;
        });
    }
    if (navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null) {
      this.screenW = true;
      this.pdfpathIpad = this.getSafeResourceUrlPdf();
      document.getElementById("files").setAttribute("style", "display:initial;");
    } else {
      this.screenW = false;
      this.pdfpath = this._sanitizer.bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/' + this.file.encrypt_id);
    }
    this.width = 630;
    this.height = 560;
  }

  getSafeResourceUrl() {
    return this._sanitizer.bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/' + this.file.encrypt_id);
  }

  getSafeResourceUrlPdf() {
    return this._sanitizer.bypassSecurityTrustResourceUrl('https://drive.google.com/viewerng/viewer?embedded=true&url=' + this.apiPath + 'filesystem/' + this.file.encrypt_id);
  }

  private loadContainer(){
    const left = this.boxPosition.left - this.left;
    const top = this.boxPosition.top - this.top;
    const right = left + 630;
    const bottom = top + 560;
    this.containerPos = { left, top, right, bottom };
  }

  setStatus(event: MouseEvent, status: number){
    if(status === 1) event.stopPropagation();
    else if(status === 2) this.mouseClick = { x: event.clientX, y: event.clientY, left: this.left, top: this.top };
    else this.loadBox();
    this.status = status;
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent){
    this.mouse = { x: event.clientX, y: event.clientY };
    if(this.status === Status.RESIZE) {
      this.resize()
    } else if (this.status === Status.MOVE){
      this.move();
    }
  }

  private resize(){
    this.width = Number(this.mouse.x > this.boxPosition.left) ? this.mouse.x - this.boxPosition.left : 0;
    this.height = Number(this.mouse.y > this.boxPosition.top) ? this.mouse.y - this.boxPosition.top : 0;
  }

  private move(){
    this.left = this.mouseClick.left + (this.mouse.x - this.mouseClick.x);
    this.top = this.mouseClick.top + (this.mouse.y - this.mouseClick.y);
  }

  private loadBox(){
    const {left, top} = this.previewModal2.nativeElement.getBoundingClientRect();
    this.boxPosition = {left, top};
  }
}
