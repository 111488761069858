import {Component, OnInit} from '@angular/core';
import {NgForm} from "@angular/forms";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ToastrService} from 'ngx-toastr';
import {AuthResourceService} from "../shared/auth-resoruce.service";
import {AuthService} from "../shared/auth.service";

@Component({
  selector: 'app-auth-confirm-invitation-access',
  templateUrl: './confirm-invitation-access.component.html'
})
export class ConfirmInvitationAccessComponent implements OnInit
{
  token: string;

  constructor(
      protected authResourceService: AuthResourceService,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {

      this.token = params['token'];
      if (!this.token) {
        this.router.navigateByUrl('/login')
      } else {
        this.authResourceService.confirmInvitationAccess(this.token).subscribe((response: any) => this.handleSuccess(response), (error: any) =>  this.handleError(error))
      }
    });
  }

  confirm(form: NgForm) {
    this.authResourceService.confirmInvitationAccess(this.token).subscribe(
      response => this.handleSuccess(response),
        e => this.handleError(e))
  }

  handleSuccess(response: Response)
  {
    this.toastr.success('Successfully confirmed');
    this.router.navigateByUrl('/dashboard')
  }

  handleError(error: Response)
  {
    this.toastr.error('Token does not exists or has expired');
    this.router.navigateByUrl('/login')
  }
}
