<div id="contacts-show-more-tab" class="" >
  <div class="portlet-body">
    <div class="table-scrollable table-scrollable-borderless">
      <table class="table table-hover editable">
        <thead>
        <tr class="uppercase">
          <th></th>
          <th>Name</th>
          <th>Category</th>
          <th>Files</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let contact of contacts">
          <td>
            <div class="category-view-thumbnail">
              <img [attr.src]=" getSafeThumbnailUrl(contact)" alt="{{ contact.slug }}"
                   class="img-thumbnail" style="height: 100px;" data-toggle="tooltip"
                   title="{{getSafeUrl(contact)}}">
            </div>
          </td>
          <td>
            <span class="category-view-name">{{ contact.name }}</span>
          </td>
          <td>
            <span class="category-view-slug" *ngIf="contact">
              <span *ngFor="let category of contact.categories; last as isLast">
                {{ category.name }}{{!isLast ? ', ' : ''}}
              </span>
            </span>
          </td>
          <td>
              <span *ngFor="let file of contact?.private_files">
               <div>
                 <p>
                   <a class="" (click)="ShowInPreviewer(file.file)"
                       data-toggle="tooltip" title="{{getSafeFileUrl(file)}}">{{file.file.title}}</a>
                 </p>
               </div>
            </span>
          </td>
          <td>
             <a class="btn blue btn-outline btn-w120" (click)="show(contact)">Show Contact Details</a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div>
    <app-shared-contacts-show [contact]="contactShow" [elementStyle]="false"  (onOpenEdit)="openEdit($event)" [showEditButton]="true"></app-shared-contacts-show>
  </div>
  <div class="file-prev">
     <app-shared-previewer [file]="filePreviewer" [showDownload]="true" (onClosePreviewer)="closePreviewerFile()"
                           *ngIf="preview"></app-shared-previewer>
  </div>
  <div>
    <app-shared-contacts-edit
      *ngIf="showEdit"
      [editContact]="editContact"
      (onUpdated)="updateContact($event)"
      (onClose)="closeModal($event)"
      (onDelete)="deleteContact($event)"
    ></app-shared-contacts-edit>
  </div>
</div><!-- /.modal-content -->

