import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output, SimpleChange, HostListener, ElementRef, ViewChild
} from '@angular/core';
import {Contacts} from "../../main/shared/contacts.model";
import * as screenfull from 'screenfull';
import {TriallineResourceService} from "../../main/trialline/shared/trialline-resoruce.service";
import {AuthService} from "../../auth/shared/auth.service";
import {DomSanitizer} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {env} from "../../../../.env";
import {Modal} from "ngx-modialog-7/plugins/bootstrap";
import {FileModel} from "../../main/file-library/shared/file.model";
import {ToastrService} from "ngx-toastr";
import {FileLibraryResourceService} from "../../main/file-library/shared/file-library-resoruce.service";

declare var jQuery:any;

const enum Status {
  OFF = 0,
  RESIZE = 1,
  MOVE = 2
}

@Component({
  selector: 'app-shared-bill-cost-more',
  templateUrl: './bill-cost-more.component.html',
  styleUrls: ['./bill-cost-more.component.scss']
})

export class BillCostMoreComponent implements OnInit {
  @Input() bill_costs: any;

  publicFolderUrl = env.publicFolderUrl;
  apiPath = env.apiUrl;
  contactShow: any;
  fullscreen: boolean = false;
  file: FileModel;
  billCost: any;
  pdfpath: any;
  width: number;
  height: number;
  left: number = 0;
  top: number = 0;
  oldLeft: number = 0;
  oldTop: number = 0;
  private boxPosition: { left: number, top: number };
  private containerPos: { left: number, top: number, right: number, bottom: number };
  public mouse: {x: number, y: number}
  public status: Status = Status.OFF;
  private mouseClick: {x: number, y: number, left: number, top: number}
  @ViewChild("previewModal2")  public previewModal2: ElementRef;
  screenW: any = false;
  moreModalId = '#event-more-modal';
  fullIpad: boolean = false;
  pdfpathIpad: any;
  previewodalId: string = '#preview-modal2';
  preview:boolean = false;
  showModal:boolean = false;
  showEdit:boolean = false;
  editContact:Contacts;

  constructor(
    private authService: AuthService,
    protected toasts: ToastrService,
    private _changeDetectorRef: ChangeDetectorRef,
    public modal: Modal,
    protected ref: ChangeDetectorRef,
    protected resourceService: FileLibraryResourceService,
    public triallineResourceService: TriallineResourceService,
    private _sanitizer: DomSanitizer,
    protected route: ActivatedRoute, public router: Router,
  ) {}

  ngOnDestroy() {
    this._changeDetectorRef.detach();
  }

  ngOnInit() {
    if (typeof this.preview === 'undefined') this.preview = false;
  }

  ngAfterViewInit() {
    if (screenfull && typeof screenfull.on !== "undefined") {
      screenfull.on('change', () => {
        this.fullscreen = screenfull.isFullscreen;
      });
    }
    jQuery('[data-toggle="tooltip"]').tooltip({
      animated: 'fade',
      placement: 'right',
      html: true
    });
    this.width = 630;
    this.height = 560;
    let self = this;
    jQuery(this.moreModalId).on('hidden.bs.modal', function () {
      jQuery('.modal-backdrop.fade.in').remove();
      self.width = 630;
      self.height = 560;
      self.left = 0;
      self.top = 0;
    })
  }

  private loadContainer(){
    const left = this.boxPosition.left - this.left;
    const top = this.boxPosition.top - this.top;
    const right = left + 630;
    const bottom = top + 560;
    this.containerPos = { left, top, right, bottom };
  }

  setStatus(event: MouseEvent, status: number){
    if(status === 1) event.stopPropagation();
    else if(status === 2) this.mouseClick = { x: event.clientX, y: event.clientY, left: this.left, top: this.top };
    else this.loadBox();
    this.status = status;
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent){
    this.mouse = { x: event.clientX, y: event.clientY };
    if(this.status === Status.RESIZE) {
      this.resize()
    } else if (this.status === Status.MOVE){
      this.move();
    }

  }

  private resize(){
    this.loadBox();
    this.width = Number(this.mouse.x > this.boxPosition.left) ? this.mouse.x - this.boxPosition.left : 0;
    this.height = Number(this.mouse.y > this.boxPosition.top) ? this.mouse.y - this.boxPosition.top : 0;
  }

  private move(){
    this.left = this.mouseClick.left + (this.mouse.x - this.mouseClick.x);
    this.top = this.mouseClick.top + (this.mouse.y - this.mouseClick.y);
  }

  private loadBox(){
    const {left, top} = this.previewModal2.nativeElement.getBoundingClientRect();
    this.boxPosition = {left, top};
  }

  selectFile(billCost) {
    this.file = billCost.file;
    this.billCost = billCost;
    this.resourceService.logFile(billCost.file).toPromise()
      .then((response: any) => {
        // setTimeout(()=>{
        //   $("#frameId").contents().find("img").attr("style","width:100%");
        //   $("#frameId").contents().find("embed").attr("style","width:100%");
        // }, 500)
        this.ref.detectChanges();
        this.pdfpath = this._sanitizer.bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/'
          + this.file.encrypt_id);
        jQuery(this.previewodalId).modal('show');
      })
      .catch((error) => {
        console.log(error);
      });
    this.screenW = navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null;
    if (billCost.file.type === 'pdf' && this.screenW) {
      this.pdfpathIpad = this.getSafeResourceUrlPdf2();
    }
    this.loadBox();
    this.loadContainer();
  }

  getSafeResourceUrlPdf2() {
    return this._sanitizer
      .bypassSecurityTrustResourceUrl('https://drive.google.com/viewerng/viewer?embedded=true&url='
        + this.apiPath + 'filesystem/' + this.file.encrypt_id);
  }

  getSafeThumbnailUrl(bill_cost) {
    if (bill_cost.file.type === 'pdf') {
      return 'assets/pdfimages/pdf1.png'
    }
    return this.publicFolderUrl + 'index.php/v1/filesystem/' +
        bill_cost.file.encrypt_id + '?preview=1'
  }

  getSafeResourceUrlPdf(bill_cost) {
    return this._sanitizer
      .bypassSecurityTrustResourceUrl('https://drive.google.com/viewerng/viewer?embedded=true&url='
        + this.apiPath + 'filesystem/' + bill_cost.file.encrypt_id);
  }

  getSafeUrl(bill_cost) {
    let obj = this.getSafeThumbnailUrl(bill_cost);
    if (bill_cost.file.type === 'pdf') {
      obj = 'assets/pdfimages/pdf1.png'
    }
    return "<img src='" + obj + "' style='height: 150px;'/>";
  }
  
  imageIcons(fExtension: string) {
    switch(fExtension) { 
      case 'pdf': 
      { 
        return 'assets/images/icon-file-type/pdf.png';
        break; 
      } 
      case 'audio':
      { 
        return 'assets/images/icon-file-type/mp3.png'; 
        break; 
      }
      case 'table':
      { 
        return 'assets/images/icon-file-type/xls.png'; 
        break; 
      }
      case 'text':
      { 
        return 'assets/images/icon-file-type/doc.png';  
        break; 
      } 
      default: 
      { 
        return 'assets/images/custom-column-images/paperwork.png'; 
        break; 
      } 
    }
  }

  show(contact){
    this.showModal = true;
    if(contact !== undefined){
      this.contactShow = contact;
      jQuery('#contacts-shared-show-modal').modal('show');
    }
  }

  getSafeResourceUrl() {
    if(this.file?.type=='image') {
      return this._sanitizer.bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/' + this.file.encrypt_id);
    } else {
      return this._sanitizer.bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/' + this.file?.encrypt_id);
    }
  }

  getFileName(name: string) {
    if(name != undefined) {
      return name.replace(/\d{9,}_/, '');
    }
    return '';
  }

  getSafeFileUrl(file) {
    if (file.file.type=='pdf') {
      return "<img src='assets/pdfimages/pdf1.png' style='height: 430px;'/>";
    }
    return "<img src='" + this.apiPath + 'filesystem/' + file.file.encrypt_id + "?preview=1' style='height: 430px;'/>";
  }

  closePreviewer() {
    jQuery('#preview-modal2').modal('hide');
    this.width = 630;
    this.height = 560;
    this.left = 0;
    this.top = 0;
    if (this.file != undefined) {
      if (this.file.type == 'video') {
        let file = this.file;
        this.file = new FileModel();
        this.ref.detectChanges();
        this.file = file;
      }
    }
  }

  // zoomPreviewToggle() {
  //   if (screenfull && typeof screenfull.on !== "undefined") {
  //     screenfull.toggle();
  //   } else if(screenfull === false && navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null && !this.fullIpad) {
  //     this.fullIpad = true;
  //     this.fullscreen = true;
  //     setTimeout( ()=>{
  //       jQuery('#preview-modal').find('.zoom_active').css({'position': 'fixed',
  //         'display': 'inline-grid', 'left': '-35px', 'top': '-100px', 'width': '110%', 'max-height': '160%',
  //         'min-height': '102%'});
  //       jQuery('#preview-modal').find('.zoom_deactivate').css({'position': 'fixed',
  //         'display': 'inline-grid', 'left': '-35px', 'top': '-100px', 'width': '110%', 'max-height': '160%',
  //         'min-height': '102%'});
  //       let height = jQuery(window).height();
  //       if (height < 750) {
  //         height = height - 50;
  //       } else if (height >= 750 && height < 900) {
  //         height = height - 200;
  //       } else {
  //         height = height - 170;
  //       }
  //       jQuery('#preview-modal').find('.zoom_active').find('.modal-content').css({ 'height': height + 'px'});
  //     }, 200)
  //   } else if(screenfull === false && navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null && this.fullIpad) {
  //     this.fullIpad = false;
  //     this.fullscreen = false;
  //     jQuery('#preview-modal').find('.modal-preview').removeAttr( "style" );
  //     jQuery('#preview-modal').find('.zoom_deactivate').attr("style","");
  //     jQuery('#preview-modal').find('.modal-preview').find('.modal-content').removeAttr( "style" );
  //   }
  // }

  closePreviewerFile() {
    this.preview = false;
    jQuery('#preview-modal-shared').modal('hide');
    this.width = 630;
    this.height = 560;
  }

  updateContact(bill_cost: any) {
    let index = this.bill_costs.indexOf(this.bill_costs.find(e => e.id == bill_cost.id));
    this.bill_costs[index] = bill_cost;
    this._changeDetectorRef.detectChanges();
    this.ngAfterViewInit();
  }

  closeModal(Contact: Contacts) {
    this.showEdit = false;
  }


  deleteContact(contact: Contacts) {

    const dialogRef = this.modal.confirm()
      .size('lg')
      .isBlocking(true)
      .showClose(true)
      .keyboard(27)
      .title('Confirmation')
      .body('You are about to delete this contact. Deleting this contact will remove them from the case and they ' +
        'will no longer be associated with any of your case facts. Proceed?')
      .open()
    dialogRef.result
      .catch((err: any) => {
        this.modelClose()
        console.log('ERROR: ' + err)})
      .then((dialog: any) => {
        this.modelClose()
        if(dialog){
          this.confirmedDeleteContact(contact)
        }
      });
    $('bs-modal-container').addClass('show-midal-element')
    this._changeDetectorRef.detectChanges();
  }

  confirmedDeleteContact(bill_cost: any) {
    //
    // this.triallineResourceService.deleteContact(contact.trialline_id, contact.id).subscribe((response: any)=> {
    //   let index = this.bill_costs.indexOf(this.bill_costs.find(e => e.id == bill_cost.id));
    //   this.bill_costs.splice(index, 1);
    //   this.toasts.success('Successfully deleted contact: ', contact.name);
    // }, (error) => {
    //   if (error.status == 403) {
    //     let errorData: any = error.error;
    //     if (errorData.error.message) { this.toasts.error(errorData.error.message); }
    //   } else {
    //     this.toasts.error(error);
    //   }
    // })
  }

  modelClose() {
    $('body').removeClass('modal-open');
  }

  
}
