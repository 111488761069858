<div id="file-edit-modal" class="modal fade bs-modal-lg in" tabindex="-1" role="dialog"
     style="z-index: 9999999; overflow: auto; height: 100%">
  <div class="modal-dialog modal-md modal-preview">
    <div class="modal-content">
      <div class="modal-header ">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">Edit file: <span class="file-title" style=" line-break: anywhere;">{{ file?.title }}</span></h4>
      </div>
      <div class="modal-body">
        <!-- BEGIN PORTLET -->
        <div class="portlet light ">
          <div class="portlet-body">
            <!--BEGIN File Details-->
            <form id="file-view-inf">
              <div class="form-body" *ngIf="! file">
                <p>Select a file to edit.</p>
              </div>
              <div class="form-body" *ngIf="file">
                <div class="form-group">
                  <div class="upload-box-details">
                    <p class="help-block">
                      <span class="label label-sm label-danger">Note:&nbsp;</span>
                      File will be overwritten without saving changes
                    </p>
                    <div class="progress" style="height: 3px; width: 200px; margin: 0">
                      <div class="progress-bar" role="progressbar" [style.width]="uploader.progress + '%'"></div>
                    </div>
                    <label for="file-input">
                      <div data-preview data-preview_path data-type-file="image"
                           class="col-md-6 image-background upload-box-details-file-img pull-left"
                           [style.background-image]="'url(' + apiPath + 'filesystem/' + file.encrypt_id + '?preview=1)'"
                           data-title="{{ file.title }}">
                      </div>
                      <input id="file-input" type="file" ng2FileSelect [uploader]="uploader" style="display: none;"/>
                    </label>
                  </div>
                  <div class="col-md-6 overflow-h word-break">
                    <div data-name class="">{{ file.title }}</div>
                    <div data-date class="text-muted">{{ file.created_at | date }}</div>
                    <p>
                      <a class="btn blue download-file"
                         href="{{ apiPath + 'filesystem/' + file.encrypt_id + '/download' }}">
                        Download file
                      </a>
                    </p>
                  </div>
                </div>
                <div class="clearfix"></div>
                <form #fileForm="ngForm" (submit)="save(fileForm)">
                  <div class="form-group">
                    <label>Title</label>
                    <input type="text" name="title" [ngModel]="file.title" class="form-control">
                  </div>
                  <div class="form-group">
                    <label>Description</label>
                    <textarea [ngModel]="file.description" name="description" class="form-control autosizeme" rows="3"
                              placeholder="Description...">{{ file.description }}</textarea>
                  </div>
                  <div class="form-actions">
                    <button type="submit" class="btn blue">Save Change</button>
                  </div>
                </form>
              </div>
            </form>
            <!--END file details-->
          </div>
        </div>
        <!-- END PORTLET -->
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>
