import {Trialline} from "./trialline.model";
import {UserProfile} from "../profile/shared/user.model";
import {Event} from "./event.model";
import {ContactsCategory} from "./contactsCategory.model";
import {FileModel} from "../file-library/shared/file.model";

export class Contacts
{
  id: number;
  name: string;
  slug: string;
  trialline_id: number;
  user_id: number;
  background: string;
  thumbnail: string;
  default: boolean;
  phone: string;
  email: string;
  website: string;
  facility_name: string;
  address: string;
  office_number: number;
  city: string;
  state: string;
  postal_code: string;
  bates_number: number;
  exhibit_number?: string;
  notes: string;
  private_files: any;
  created_at?: string;
  updated_at?: string;
  thumbnail_visible?: boolean = true;
  events_count?: number;
  thumbnail_encryptid?: any;
  category_id?: any;
  status?: boolean = true;
  statusSelect?: boolean = true;
  trialline?: Trialline;
  categories?: ContactsCategory[];
  user?: UserProfile;
  events?: Event[];
  files?: FileModel[];
  category?: ContactsCategory;
  dshow?: boolean
}
