<div id="event-more-modal" class="222 modal bs-modal-lg in show-style" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg slide-in-fwd-center" [class.fullscreen]="fullscreen">
    <div class="prev-next-arrows">
      <button type="button" class="prev" style="z-index: -99999;" (click)="prevEvent()"><i class="fa fa-angle-left"></i></button>
      <button type="button" class="next" style="z-index: -99999;" (click)="nextEvent()"><i class="fa fa-angle-right"></i></button>
    </div>
    <div class="modal-content" style="display: flex;">
      <div class="mobile-category" *ngIf="event.category_name != 'No Category'" [ngStyle]="{'background-color': event.category != null
      ? this.setBackgroundColor(event.category.background): '#000000'}">
        <span class="title-main">{{event.category_name}}</span>
        <i (click)="modalClosed()"  class="fa fa-close"></i>
      </div>
      <img src="assets/squares.gif" class="img spinners center-block" alt="spinner" *ngIf="processing">
      <!-- <button type="button" class="close close1" data-dismiss="modal" aria-label="Close" (click)="modalClosed()">
        <span aria-hidden="true"></span>
      </button>
      <button (click)="fullscreenToggle()" type="button" id="fullscreen" *ngIf="fullscreenButtonVisible">
        <span aria-hidden="true"><i class="fa fa-search-{{ fullscreen ? 'minus' : 'plus' }}"
            aria-hidden="true"></i></span>
      </button> -->
      <div class="main-content" [ngStyle]="{'background-color': event.category != null
      ? this.setBackgroundColor(event.category.background) : '#000000'}">
        <div class="modal-header">
          <div class="category" *ngIf="event.category_name != 'No Category'" [ngStyle]="{'color': event.category != null
          ? this.setBackgroundColor(event.category.background): '#000000'}">
            <div class="category-inner-block" title= {{event.category_name}}>
              {{event.category_name}}
            </div>
          </div>
          <div class="modal-right-content">
            <div class="header-wrapper">
              <div class='date-calendar' [ngStyle]="{'background-color': event.category_name != 'No Category'
              ? this.setBackgroundColor(event.category.background) + '0D' : '#0E69A5', 'border': '2px solid ' + (event.category_name != 'No Category'
              ? this.setBackgroundColor(event.category.background): '#0E69A5')}">
                <i class="fa fa-calendar-o" [ngStyle]="{'background-color': event.category_name != 'No Category'
                ? this.setBackgroundColor(event.category.background) + '0D' : '#0E69A5', 'color': event.category != null
                ? this.setBackgroundColor(event.category.background) : '#000000'}"></i>
              </div>
            <div class="event-date" style= "margin-right: 15px;">
              <div class="event-date-year">
                <span *ngIf="event.date_start">{{transformDate(event.start_date, !!event.if_start_days)}}</span>
              </div>
              <div class="event-date-time" >
                <span *ngIf="(event.date_start) || parseTime(event.start_date) !== '12:00 AM'">
                  <span *ngIf="event.has_times">{{parseTime(event.start_date)}}</span>
                </span>
              </div>
            </div>
            <div class="arrow-icon" [ngClass]="[ event.end_date ? 'show-block' : 'hide-block']">
              <i class="fa fa-angle-right"></i>
            </div>
            <div class='date-calendar' [ngClass]="[ event.end_date ? 'show-block' : 'hide-block']"  [ngStyle]="{'background-color': event.category_name != 'No Category'
            ? this.setBackgroundColor(event.category.background) + '0D' : '#0E69A5', 'border': '2px solid ' + (event.category_name != 'No Category'
            ? this.setBackgroundColor(event.category.background): '#0E69A5')}">
              <i class="fa fa-calendar-o" [ngStyle]="{'background-color': event.category != null
              ? this.setBackgroundColor(event.category.background) + '0D' : '#0E69A5', 'color': event.category != null
              ? this.setBackgroundColor(event.category.background) : '#000000'}"></i>
            </div>

            <div class="event-date" style="margin-right: 30px;" [ngClass]="[ event.end_date ? 'show-block' : 'hide-block']">
              <div class="event-date-year">
                <span *ngIf="event.date_end">{{transformDate(event.end_date, !! event.if_end_days)}}</span>
              </div>
              <div class="event-date-time">
                <span *ngIf="(event.start_date && event.date_end) || parseTime(event.start_date) !== '12:00 AM'">
                  <span *ngIf="event.start_date && event.has_times_end">{{parseTime(event.end_date)}}</span>
                </span>
              </div>
            </div>
            </div>
            <div class="modal-buttons">
              <button class="close-btn" (click)="modalClosed()">
                <i class="fa fa-times-thin fa-2x" aria-hidden="true"></i>
              </button>
              <button class="close-btn" (click)="fullscreenToggle()">
                <i class="fa fa-expand" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="tab-content" [class.fullheight]="fullscreen">
          <div role="tabpanel" class="tab-pane" [class.active]="activeTab == 'description'" id="description">
            <div class="event-image" *ngIf="event?.thumbnail_visible">
              <div class=" 99 image" *ngIf="event.thumbnail_encryptid == null || event.thumbnail_encryptid == false "
                [ngStyle]="event.thumbnailImage">
              </div>
              <div class="image" *ngIf="event.thumbnail_encryptid !== null || event.thumbnail_encryptid !== false "
                [ngStyle]="{'background':'url(\'' + publicFolderUrl + 'index.php/v1/filesystem/' +
                event.thumbnail_encryptid + '?preview=1' + '\') center center no-repeat'}">
              </div>
            </div>
            <div class="modal-header-block">
              <div class="category" *ngIf="event.category_name != 'No Category'" [ngStyle]="{'color': event.category != null
              ? this.setBackgroundColor(event.category.background): '#000000'}">
                {{event.category_name}}
              </div>
              <div class="header-wrapper">
                <div class="event-date" style="margin-right: 30px;">
                  <div class="event-date-year">
                    <span *ngIf="event.date_start">{{transformDate(event.start_date, !!event.if_start_days)}}</span>
                  </div>
                  <div class="event-date-time" >
                    <span *ngIf="(event.date_start) || parseTime(event.start_date) !== '12:00 AM'">
                      <span *ngIf="event.has_times">{{parseTime(event.start_date)}}</span>
                    </span>
                  </div>
                </div>
                <div class="arrow-icon">
                  <i class="fa fa-angle-right"></i>
                </div>    
                <div class="event-date" style="margin-right: 30px;">
                  <div class="event-date-year">
                    <span *ngIf="event.date_end">{{transformDate(event.end_date, !!event.if_end_days)}}</span>
                  </div>
                  <div class="event-date-time">
                    <span *ngIf="(event.start_date && event.date_end) || parseTime(event.start_date) !== '12:00 AM'">
                      <span *ngIf="event.start_date && event.has_times_end">{{parseTime(event.end_date)}}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="main-text-wrap" [ngClass]="event?.thumbnail_visible ? '': 'no-thumbnail'">
              <div class="event-title event-title-ellipsis" title="{{event.title}}">
                {{event.title}}
              </div>
              <div class="event-text"  [innerHTML]="event?.description | safe: 'html'"></div>
              <!-- <div class="tags" style="margin-top: -12px;">
                <h2 *ngIf="event?.tag_name && event.tag_name.length > 0">Tags</h2>
                <div class="tags-wrapper">
                  <span *ngFor="let tag of event?.tag_name">
                    <div class="tag-text"  [ngStyle]="{'background-color': event.category != null
                    ? this.setBackgroundColor(event.category.background) + '0D' : '#000000', 'border': '2px solid ' + (event.category != null
                    ? this.setBackgroundColor(event.category.background): '#000000')}" style="margin: 5px; display: flex;">
                      {{tag.tag_name}}
                    </div>
                  </span>
                </div>
              </div> -->
            </div>
          </div>
          <div role="tabpanel" class="tab-pane modal-scroll-box  " [class.active]="activeTab == 'files'" id="files"
            *ngIf="event?.files?.length">
            <app-shared-files [moreFiles]='moreFiles' [files]="event?.files" [cloudFunc]="false" [removeFunc]="false"
              [preview]="true"></app-shared-files>
          </div>
          <div role="tabpanel" class="tab-pane modal-scroll-box" [class.active]="activeTab == 'media'" id="media"
            *ngIf="event?.videos?.length">
            <app-shared-videos [videos]="event?.videos" [preview]="true" [removeFunc]="false"></app-shared-videos>
          </div>
          <div role="tabpanel" class="tab-pane modal-scroll-box  " [class.active]="activeTab == 'comments'" id="comments"
            *ngIf="event?.comments?.length || comments">
            <ul class="view-panel-box no_list_style">
              <li class="views">
                <div class="comments">
                  <ul class="comments-box">
                    <li *ngFor="let comment of event?.comments">
                      <div class="comments-box-avatar" [attr.data-letters]="comment.avatar"></div>
                      <div class="comments-box-body">
                        <div class="comments-box-body-title">
                          <span class="name">{{ comment.author }}</span>
                          <span class="date">{{ comment.created_at|date:'short' }}</span>
                        </div>
                        <div class="comments-box-body-message">{{ comment.comment }}</div>
                        <div class="reply_comment" *ngIf="!this.isView" (click)="setViewId(comment.view_id)">Reply</div>
                      </div>
                    </li>
                    <li class="comments-form" *ngIf="this.isView || this.viewId">
                      <form #commentForm="ngForm" (submit)="storeComment(commentForm)"
                        class="form-inline no-validate-comment-form">
                        <div class="col-md-10 comments-form-text">
                          <div class="form-group comments-form-group">
                            <textarea [ngModel] name="comment" class="form-control" placeholder="Your comments"
                              maxlength="200"></textarea>
                          </div>
                        </div>
                        <div class="col-md-1 comments-form-button">
                          <button type="submit" class="btn blue btn-circle btn-sm pull-right">Post
                          </button>
                        </div>
                      </form>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div role="tabpanel" class="tab-pane modal-scroll-box" [class.active]="activeTab == 'tags'" id="tags"
            *ngIf="event?.tag_name?.length">
            <div class="row" style="margin-left:2px;">
              <span *ngFor="let tag of event?.tag_name">
                <a class="btn btn-default btn-outline btn-event" style="margin-top: 7px;">
                  {{tag.tag_name}} </a>
              </span>
            </div>
          </div>

          <div role="tabpanel" class="tab-pane" [class.active]="activeTab == 'custom_column'" id="custom_columns"
            *ngIf="customColumns?.length">
            <div class="main-text-wrap">
              <div *ngIf="currentCustomColumn">
                <div class="event-title">
                  {{currentCustomColumn['name']}}
                </div>
                <div class="event-text" [innerHTML]="currentCustomColumn['value'] | safe: 'html'"></div>
              </div>
            </div>
          </div>
          <div role="tabpanel" class="tab-pane modal-scroll-box" [class.active]="activeTab == 'notes'" id="notes">
            <div class="form-body form-date" [innerHTML]="event?.notes | safe: 'html'"></div>
          </div>
          <div role="tabpanel" class="tab-pane modal-scroll-box" [class.active]="activeTab == 'contacts'" id="contacts"
            *ngIf="event?.contacts?.length">
            <app-shared-contacts-more [contacts]="event?.contacts"></app-shared-contacts-more>
          </div>
          <div role="tabpanel" class="tab-pane modal-scroll-box" [class.active]="activeTab == 'bill_cost'" id="bill_cost"
            *ngIf="event?.bill_costs_count">
            <app-shared-bill-cost-more [bill_costs]="event?.bill_costs"></app-shared-bill-cost-more>
          </div>
        </div>
      </div>
      <div class="modal-footer" [ngStyle]="{'background': event.category_name != 'No Category'
      ? this.setBackgroundColor(event.category.background): '#0E69A5'}">
        <div class="action-buttons">
          <div class="button-inner-block">
            <a (click)="activeTab = 'description'" aria-controls="info" role="tab" data-toggle="tab" aria-expanded="true"
              class="action-buttons-list" [class.active]="activeTab == 'description' || !activeTab" title="Event">
              <i class="fa fa-info-circle"></i>
            </a>
            
            <ng-container *ngIf="event?.comments?.length > 0 || isView == true">
              <a (click)="activeTab = 'comments'" [class.active]="activeTab == 'comments'"
                aria-controls="comments" role="tab" class="action-buttons-list" data-toggle="tab" title="Comments">
                <i class="fa fa-comments" aria-hidden="true"></i>
                <span class="calc" [ngStyle]="{'background': event.category_name != 'No Category'
                      ? this.setBackgroundColor(event.category.background): '#0E69A5'}">{{event.comments ?
                  event.comments.length : 0}}</span>
              </a>
            </ng-container>
            <a (click)="activeTab = 'tags'" aria-controls="tags" role="tab" data-toggle="tab" class="action-buttons-list"
              [class.active]="activeTab == 'tags'" *ngIf="event?.tag_name?.length > 0" title="Tags">
              <i class="fa fa-tags"></i>
              <span class="calc" [ngStyle]="{'background': event.category_name != 'No Category'
                    ? this.setBackgroundColor(event.category.background): '#0E69A5'}">{{event?.tag_name?.length}}</span>
            </a>
            <a (click)="activeTab = 'notes'" aria-controls="notes" role="tab" data-toggle="tab"
              class="action-buttons-list" [class.active]="activeTab == 'notes'"
              *ngIf="event?.notes?.length > 0" title="Notes">
              <i class="fa fa-pencil"></i>
              <!-- <span class="calc" [ngStyle]="{'background': event.category != null
                    ? this.setBackgroundColor(event.category.background): '#000000'}">{{event.notes ?
                event.comments.length : 0}}</span> -->
            </a>
            <a (click)="activeTab = 'media'" aria-controls="media" role="tab" data-toggle="tab"
              class="action-buttons-list" [class.active]="activeTab == 'media'" *ngIf="event?.videos?.length > 0" title="Video">
              <i class="fa fa-play-circle"></i>
              <span class="calc" [ngStyle]="{'background': event.category_name != 'No Category'
                    ? this.setBackgroundColor(event.category.background): '#0E69A5'}">{{event.videos?.length }}</span>
            </a>
            <a (click)="activeTab = 'files'" aria-controls="files" role="tab" data-toggle="tab"
              class="action-buttons-list" [class.active]="activeTab == 'files'" *ngIf="event?.files?.length > 0" title="Attachments">
              <i class="fa fa-paperclip"></i>
              <span class="calc" [ngStyle]="{'background': event.category_name != 'No Category'
                    ? this.setBackgroundColor(event.category.background): '#0E69A5'}">{{event?.files?.length}}</span>
            </a>
            <a (click)="activeTab = 'contacts'" aria-controls="contacts" role="tab" data-toggle="tab"
              class="action-buttons-list" [class.active]="activeTab == 'contacts'" *ngIf="event?.contacts?.length > 0" title="Contacts">
              <i class="fa fa-user"></i>
              <span class="calc" [ngStyle]="{'background': event.category_name != 'No Category'
                    ? this.setBackgroundColor(event.category.background): '#0E69A5'}">{{event?.contacts?.length}}</span>
            </a>
            <a (click)="activeTab = 'bill_cost'" aria-controls="bill_cost" role="tab" data-toggle="tab"
              class="action-buttons-list" [class.active]="activeTab == 'bill_cost'" *ngIf="event?.bill_costs_count" title="Bill_cost">
              <i class="fa fa-usd"></i>
              <span class="calc" [ngStyle]="{'background': event.category_name != 'No Category'
                    ? this.setBackgroundColor(event.category.background): '#0E69A5'}">{{event?.bill_costs_count}}</span>
            </a>
          </div>
          <!-- <ng-container *ngFor="let customColumn of customColumns">
            <a *ngIf="customColumn.value != null"
              (click)="activeTab = 'custom_column'; currentCustomColumnValue(customColumn)"
              aria-controls="custom_column" role="tab" data-toggle="tab" class="action-buttons-list"
              [class.active]="activeTab == 'custom_column'">
              <img src="{{customColumn.image_url}}" style="width: 14px">
            </a>
          </ng-container> -->
        </div>
        <div class="prev-next-arrows-fullscreen" *ngIf="fullscreen || checkWindowWidth()">
          <button type="button" class="prev more-style" (click)="prevEvent()"><i class="fa fa-angle-left"></i></button>
          <button type="button" class="next more-style" (click)="nextEvent()"><i class="fa fa-angle-right"></i></button>
        </div>
      </div>

    </div>
  </div>
</div>