import {Pipe, PipeTransform} from "@angular/core";

declare var moment:any;

@Pipe({ name: 'moment' })

export class MomentPipe implements PipeTransform{

  transform(value: string, format: string): string {
    if (! value) return null;

    let momentDate = moment(this.parse(value));
    if (momentDate.isValid())
      return momentDate.format(format);
    else
      return null;
  }

  parse(date) {

    let parsed = new Date(date);

    let offset = parsed.getTimezoneOffset() * 60000;

    return new Date(parsed.getTime() + offset);
  }
}
