
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

import {RestService} from "../../../rest/shared/rest.service";
import {Trialline} from "../../shared/trialline.model";

@Injectable()
export class TriallinesResourceService
{
  constructor(public http: RestService) {}

  getTriallines(): Observable<any>
  {
    return this.http.get('trialline?limit=25&orderByColumnName=created_at').pipe(map(
      r => r.body
    ));
  }

  getArchivedTriallines(): Observable<any>
  {
    return this.http.get('trialline?limit=100&orderByColumnName=created_at&archived=true').pipe(map(
      r => r.body
    ));
  }

  //Pagination
  getTriallinesByPage(data): Observable<any>
  {
    return this.http.get('trialline?orderByColumnName='+data.sort.field +'&order='+ data.sort.order+'&page='+data.page +'&name='+data.name +'&limit='+data.limit).pipe(map(
      r => r.body
    ));

  }

  getLastCreatedTrialline(id): Observable<any>
  {
    return this.http.get('trialline/last-created/'+id).pipe(map(
      r => r.body
    ));
  }

  createTrialline(data): Observable<any>
  {

    return this.http.post('trialline', data).pipe(map((response: any) => {
      let body = response.body;

      return body.trialline;
    }));

  }

  

  archiveTrialline(id): Observable<any>
  {

    return this.http.post('trialline/' + id + '/archive', []).pipe(map((response: any) => {
      let body = response.body;

      return body.trialline;
    }));
  }

  archiveDeletedTrialline(id, tag:any): Observable<any>
  {
    console.log('I am in:',tag)
    return this.http.post('trialline/' + id + '/archive', {tag:tag}).pipe(map((response: any) => {
      let body = response.body;

      return body.trialline;
    }));
  }

  archiveTriallines(): Observable<any>
  {
    return ;
  }

  copyTrialline(id): Observable<any>
  {
    return this.http.post('trialline/' + id + '/copy', []).pipe(map((response: any) => {
      let body = response.body;

      return body.trialline;
    }));
  }

  openTrialline(id): Observable<any>
  {
    
    return this.http.post('trialline/' + id + '/open', []).pipe(map((response: any) => {
      let body = response.body;

      return body.trialline;
    }));

  }

  deleteTrialline(id): Observable<any>
  {
    return this.http.delete('trialline/' + id).pipe(map(
      r => r.body
    ));
  }

  repayTrialline(id): Observable<any>
  {

    return this.http.post('trialline/' + id + '/repay', []).pipe(map((response: any) => {
      let body = response.body;

      return body.trialline;
    }));

  }

  useAsPrimary(id): Observable<any>
  {

    return this.http.post('trialline/' + id + '/make-primary', []).pipe(map((response: any) => {
      let body = response.body;

      return body.trialline;
    }));

  }

  createSubscription(planId)
  {
    return this.http.post('billing/subscription', {plan: planId}).pipe(map(r => r.body));
  }

  switchFirstTime(stat)
  {
    return this.http.post('first', [], {}, false).pipe(map(r => r.body));
  }

  switchTrialEnd(stat) {
    return this.http.post('trial', [], {}, false).pipe(map(r => r.body));
  }

  getUserInfo() {

    return this.http.get('first', '', false).pipe(map((response: any) => {
      let body = response.body;

      return body.user;
    }));


  }

  createFolder(data): Observable<any> {

    return this.http.post('trialline/createfolder',data).pipe(map(r => r.body));
  }

  retriveFolder(data) {
    return this.http.post('trialline/retrivefolder',data).pipe(map(r => r.body));

  }

  retrivesubFolder(data): Observable<any> {

    return this.http.post('trialline/subfolder',data).pipe(map(r => r.body));

  }
}
