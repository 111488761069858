import {Component, Input, OnChanges} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ProfileResourceService} from "../../shared/profile-resoruce.service";
import {User} from "../../../../auth/shared/user.model";
import {FeedServiceService} from '../feed-service.service';
import {DomSanitizer} from '@angular/platform-browser';
import {NewsRss} from "../../../support/news-rss";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import * as xml2js from "xml2js";
import {RestService} from "../../../../rest/shared/rest.service";

declare var require: any

@Component({
  selector: 'app-dashboard-matters',
  templateUrl: './matters.component.html',
  styleUrls: ['./matters.component.scss']
})
export class MattersComponent implements OnChanges
{
  $index=1;
  errors: any = {};
  matters: any = {};
  loading: boolean;
  parseError: string;
  longPopupState: boolean = false;
  @Input() user: User;
  @Input() activeTab: string;
  @Input() forbidden: boolean=false;
  // @Input() SearchKey:string;

  //Pagination
  pageNumber: number = 1;
  orderCol: string = 'created_at';
  orderVal: string='ASC';
  limit:number=25;
  total_count: number;
  reverse: boolean = true;

  public feeds: any;
  private feed: any;
  private feedUrl: string = 'https://blog.trialline.net/feed/'
  sagarfeeds=[];
  matterall=[];

  constructor(
    protected toasts: ToastrService,
    protected profileResourceService: ProfileResourceService,
    public feedService: FeedServiceService,
    private _sanitizer: DomSanitizer,
    public http: RestService
   // private http: HttpClient
  ) {}


  ngOnChanges()
  {
    this.refreshFeed();
    if (this.activeTab === 'matters')
    {
      if (!this.user.clio_access_token) {
        this.loading = false;
        this.parseError = 'Please connect Clio';
      } else
      {
         this.profileResourceService.getClioMatters(this.user.id,{limit:this.limit,
        page:this.pageNumber,order:this.orderVal}).toPromise()
          .then((data: any) =>
        {
            this.matters=data.data;
            this.total_count = data['total'];
            this.pageNumber = data['page'];
            this.loading = false;
            this.forbidden=false;
          }).catch((error) => {
          this.loading = false;
          this.parseError = error.error.error.message;
          console.log(error.error);
        });
      }
    }

  }

  togglePopup(state: boolean, e?: any) {
    if (e === 'func' || e.target.hasAttribute('data-clicker')) {
      this.longPopupState = state;
    }
  }

  create(id: number) {
    this.loading = true;
    this.togglePopup(true, 'func');
    this.profileResourceService.createTriallineFromMatter(id, this.user.id).toPromise()
      .then((data: any) => {
        this.toasts.success('Trialline created');
        this.loading = false;
        this.profileResourceService.getClioMatters(this.user.id,{limit:this.limit,
          page:this.pageNumber,order:this.orderVal}).toPromise()
          .then((data: any) => {
            this.matters = data.data;
            this.total_count = data['total'];
            this.pageNumber = data['page'];
            this.loading = false;
          }).catch((error) => {
          this.loading = false;
          this.parseError = error.error.error.message;
          console.log(error.error);
        });
      }).catch((error) => {
      console.log(error.error);
      this.loading = false;
    });
 //   console.log(id);
  }

  update(id: number) {
    this.loading = true;
    this.togglePopup(true, 'func');
    this.profileResourceService.updateTriallineMatter(id, this.user.id).toPromise()
      .then((data: any) => {
        this.toasts.success('Trialline updated');
        this.loading = false;
      }).catch((error) => {
      console.log(error.error);
      this.loading = false;
    });
  }
  async refreshFeed()
  {
    localStorage.setItem('support','dashboard');

    /* New Changes */

    let self = this;
    this.http.get( 'support-feed').subscribe(
      (data: any) => {
        let rss = data.body.rss;
        let parseString = xml2js.parseString;
        parseString(rss, (err, result: NewsRss) => {
          if (result.rss != undefined) {
            self.feeds = self.ClioFeeds(result.rss.channel[0].item)
          }
        });
      },
      (error: any) => {
        console.log(error);
      }
    );

   }

   ClioFeeds(feeds:any)
   {
    return feeds.filter(function(feeds)
      {
        if(feeds.category != undefined){
          if((feeds.category.indexOf("TrialLine Clio Tutorials") > -1) || (feeds.category.indexOf("Clio") > -1)){
            return true;
        }
        } else{
          return false;
        }
    });

   }

   pageChanged(data: any){

    this.profileResourceService.getClioMatters(this.user.id,{limit:this.limit,
      page:data,order:this.orderVal})
        .subscribe((response) => {
          this.matters = response.data;
          this.loading = false;
          this.forbidden=false;
          this.total_count = response['total'];
          this.pageNumber = response['page'];
    });
  }

  changePages(data: any){
    this.limit = data.target.value;
    this.profileResourceService.getClioMatters(this.user.id,{limit:this.limit,
      page:this.pageNumber,order:this.orderVal})
      .subscribe((response) => {
        this.matters = response.data;
        this.loading = false;
        this.forbidden=false;
      this.total_count = response['total'];
      this.pageNumber = response['page'];
      });
  }
  reorder(column: string) {
    if (column == this.orderCol) {
      this.orderVal = this.orderVal === 'DESC' ? 'ASC' : 'DESC';
      this.reverse = ! this.reverse;
     this.profileResourceService.getClioMatters(this.user.id,{limit:this.limit,
      page:this.pageNumber,order:this.orderVal})
      .subscribe((response) => {
        this.matters = response.data;
        this.loading = false;
        this.forbidden=false;
      this.total_count = response['total'];
      this.pageNumber = response['page'];
      });
    }

  }
  getSortIcon(column: string) {

    return this._sanitizer.bypassSecurityTrustHtml('<i class="fa fa-sort' +
      (column == this.orderCol ? '-' + (this.reverse ? 'asc' : 'desc') : '')  + '"' +
      ' aria-hidden="true"></i>');
  }
  onMatterSearch(searchValue): void {
      this.profileResourceService.getClioMatters(this.user.id,{limit:this.limit,
        page:1,order:this.orderVal,search:searchValue})
        .subscribe((response) => {
          this.matters = response.data;
          this.loading = false;
          this.forbidden=false;
        this.total_count = response['total'];
        this.pageNumber = response['page'];
        });
   };

}
