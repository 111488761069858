import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Title} from "@angular/platform-browser";
import {Trialline} from "../shared/trialline.model";
import {ActivatedRoute, Router} from "@angular/router";
import {env, permissions} from "../../../../.env";
import {Modal} from 'ngx-modialog-7/plugins/bootstrap';
import {AuthService} from "../../auth/shared/auth.service";
import {User} from "../../auth/shared/user.model";

@Component({
  selector: 'app-desktop-application',
  templateUrl: './application.component.html'
})
export class ApplicationComponent implements  OnInit
{
  triallines: Trialline[];

  os: string;
  list: boolean = false;

  env = env;

  permissions = permissions;

  user: User;

  unknown: boolean = false;

  modalOpened: boolean = false;
  downloaded: boolean = false;

  listOfLinks: any = [
    {system: 'MacOS', url: '#'},
    {system: 'Windows', url: '#'}
  ];

  constructor(
    protected toasts: ToastrService,
    protected title: Title,
    private modal: Modal,
    protected route: ActivatedRoute, public router: Router,
    private auth: AuthService
  ) {
    title.setTitle('Trialline | Desktop Application')

    auth.user().subscribe(
      (user: User) => {
        this.user = user;
        this.openModalOrDownload();
      }
    );
  }

  ngOnInit() {

    let os="Unknown OS";
    if (navigator.appVersion.indexOf("Win")!=-1) os="Windows";
    if (navigator.appVersion.indexOf("Mac")!=-1) os="MacOS";
    if (navigator.appVersion.indexOf("X11")!=-1) os="UNIX";
    if (navigator.appVersion.indexOf("Linux")!=-1) os="Linux";

    this.os = os;
  }

  openModalOrDownload() {
    if (this.user && this.user.isFree==0 && (this.user.subscription_plan == 'basic' || ! this.user.subscription_plan)) {

      if (! this.modalOpened) {
        this.modalOpened = true;
        const dialogRef = this.modal.confirm()
          .size('lg')
          .isBlocking(true)
          .okBtn('Upgrade')
          .cancelBtn('Cancel')
          .showClose(true)
          .keyboard(27)
          .title('We\'re sorry, but your current plan does not include the offline version.')
          .body('Your current TrialLine subscription does not include the use of the offline version. To use the offline version, please upgrade your plan through the "My Profile" tab in your Dashboard')
          .open()
        dialogRef.result
          .catch((err: any) => {
            this.modelClose()
            console.log('ERROR: ' + err)
          })
          .then((dialog: any) => {
            if(dialog) {
              this.modelClose()
              this.router.navigateByUrl('/profile/subscription').then(r => {})
            }
          })
          .catch((err: any) => { this.router.navigateByUrl('/dashboard').then(r => {}) });
        $('bs-modal-container').addClass('show-midal-element')
      }
    } else {

      if (! this.downloaded) {

        if (['MacOS', 'Windows'].indexOf(this.os) > -1) {
          // this.download();
        } else {
          this.unknown = true;
          this.list = true;
        }
      }
    }
  }

  download(os = this.os) {

    window.open(env.apiUrl + 'desktop/' + os + '?token=' + this.auth.token(), '_blank');
  }

  toggleList() {
    this.list = ! this.list;
  }

  modelClose() {
    $('body').removeClass('modal-open');
  }
}
