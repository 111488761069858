<div id="preview-modal-shared" class="modal fade bs-modal-lg in" tabindex="-1" role="dialog"
     style="z-index: 9999999; overflow: visible; display: block;" >
  <div class="modal-dialog modal-lg modal-preview" ngClass="{{ fullscreen ?
   'zoom_active' : 'zoom_deactivate' }}">
    <div class="modal-content draggable" #previewModal2>
      <div class="modal-header">
        <button (click)="closePreviewer()" type="button" class="close"><span
          aria-hidden="true">&times;</span></button>
        <!-- <button (click)="zoomPreviewToggle()" type="button" class="close close1 fullscrean">
              <span aria-hidden="true">
                <i class="fa fa-search-{{ fullscreen ? 'minus' : 'plus' }}" aria-hidden="true"></i>
              </span>
        </button> -->
        <h4 class="modal-title">{{file?.title.length > 50 ? (file?.title.substr(0, 50) + '...') : file?.title}}</h4>
      </div>
      <div class="modal-body {{file?.type === 'image' ? 'body-image' : ''}} -{{file?.mime}}-"
           [ngSwitch]="file?.type || file?.mime">

        <img *ngSwitchCase="'image'" class="img-responsive" [attr.src]="getSafeResourceUrl()" width="100%">
        <video *ngSwitchCase="'video'" width="100%" controls>
          <source [attr.src]="getSafeResourceUrl()" type="video/mp4">
          Your browser does not support HTML5 video.
        </video>

        <audio    *ngSwitchCase="'audio/mp3'" controls>
          <source [attr.src]="getSafeResourceUrl()" type="audio/mpeg">
          Your browser does not support the audio element.
        </audio>

        <audio *ngSwitchCase="'audio/wav'" controls>
          <source [attr.src]="getSafeResourceUrl()" type="audio/wav">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/flac'" controls>
          <source [attr.src]="getSafeResourceUrl()" type="audio/flac">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/x-aiff'" controls>
          <source [attr.src]="getSafeResourceUrl()" type="audio/x-aiff">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/aac'" controls>
          <source [attr.src]="getSafeResourceUrl()" type="audio/aac">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/ac3'" controls>
          <source [attr.src]="getSafeResourceUrl()" type="audio/ac3">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/x-ms-wma'" controls>
          <source [attr.src]="getSafeResourceUrl()" type="audio/x-ms-wma">
          Your browser does not support the audio element.
        </audio>
        <div class="pdf" *ngSwitchCase="'pdf'">
          <iframe *ngIf="screenW"  scrolling="auto"  class="mobile-pdf" [src]="pdfpathIpad" width="100%"
                  height="{{ fullscreen ? '100%' : '500' }}"  type='application/pdf'>
          </iframe>
          <iframe *ngIf="!screenW"  scrolling="auto"  class="mobile-pdf" [src]="pdfpath" width="100%"
                  height="{{ fullscreen ? '100%' : '280' }}"  type='application/pdf'>
          </iframe>
<!--          <embed  *ngIf="!screenW"  [attr.src]='pdfpath'  id='frameId' width="100%"-->
<!--                  height="{{ fullscreen ? '100%' : '500px' }}"-->
<!--                  pluginspage="http://www.adobe.com/products/acrobat/readstep2.html"/>-->
        </div>
         <div *ngSwitchDefault>
          <div class="download-icon">
            <img src="../../../assets/images/filenotfound.png" alt="Download file">
          </div>
             <p class="download-text">It looks like  this file doesn't have  a <br>
             Preview  we can show you</p>
        </div>
        <div *ngIf="showDownload">
          <a [href]="apiPath + 'filesystem/' + file?.encrypt_id + '/download'" class="btn btn-sm btn-primary download-btn"><i class="fa fa-download" style="margin-right: 15px;" aria-hidden="true"></i> Download attachment </a>
        </div>
      </div>
      <div class="resize-action" (mousedown)="setStatus($event, 1)"></div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>
