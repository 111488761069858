import {Injectable, Inject, PLATFORM_ID} from '@angular/core';
@Injectable({
  providedIn: 'root'
})

export class HelpersService {

  constructor(
  ) {
  }

  compareNameAb( a, b ) {
    if (a.name.split(' ').length > 1) {
      let arr = a.name.split(' ');
      arr.splice(0, 1);
      a.nameSort = arr.join(" ").toUpperCase();
    } else {
      a.nameSort = a.name.toUpperCase();
    }
    if (b.name.split(' ').length > 1) {
      let brr = b.name.split(' ');
      brr.splice(0, 1);
      b.nameSort = brr.join(" ").toUpperCase();
    } else {
      b.nameSort = b.name.toUpperCase();
    }
    if ( a.nameSort < b.nameSort ){
      return -1;
    }
    if ( a.nameSort > b.nameSort ){
      return 1;
    }
    return 0;
  }

  compareCategoryAb( a, b ) {
    if (a.category.name.split(' ').length > 1) {
      let arr = a.category.name.split(' ');
      arr.splice(0, 1);
      a.categorySort = arr.join(" ").toUpperCase();
    } else {
      a.categorySort = a.category.name.toUpperCase();
    }
    if (b.category.name.split(' ').length > 1) {
      let brr = b.category.name.split(' ');
      brr.splice(0, 1);
      b.categorySort = brr.join(" ").toUpperCase();
    } else {
      b.categorySort = b.category.name.toUpperCase();
    }
    if ( a.categorySort < b.categorySort ){
      return -1;
    }
    if ( a.categorySort > b.categorySort ){
      return 1;
    }
    return 0;
  }

  compareCategoryBa( a, b ) {
    a.categorySort = a.category.name.toUpperCase();
    b.categorySort = b.category.name.toUpperCase();
    if ( a.categorySort > b.categorySort ){
      return -1;
    }
    if ( a.categorySort < b.categorySort ){
      return 1;
    }
    return 0;
  }

  compareNameBa( a, b ) {
    a.categorySort = a.name.toUpperCase();
    b.categorySort = b.name.toUpperCase();
    if ( a.nameSort > b.nameSort ){
      return -1;
    }
    if ( a.nameSort < b.nameSort ){
      return 1;
    }
    return 0;
  }

  capitalizeFirstLetter(str: string): string {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str;
  }

  capitalizeAllFirstLetters(str: string): string {
    if (str) {
      return str.split(' ')
        .map((s: string) => s.toLowerCase())
        .map((s: string) => this.capitalizeFirstLetter(s))
        .join(' ');
    }
    return str;
  }
}
