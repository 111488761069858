<table class="table table-bordered table-striped table-condensed flip-content table-sortable table-vertical-align-center">
  <thead>
  <tr>
    <th *ngFor="let column of columns" [class]="selectedClass(column.variable)" (click)="changeSorting(column.variable)">
      {{column.display}}
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let object of data | orderBy : convertSorting()">
    <td *ngFor="let column of columns">
      {{object[column.variable] | format : column.filter}}
    </td>
  </tr>
  </tbody>
</table>
