import {AfterViewInit, Component, Input, OnChanges, SimpleChange} from '@angular/core';
import {Video} from "../../main/shared/video.model";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

declare var jQuery:any;

@Component({
  selector: 'app-shared-youtube-previewer',
  templateUrl: './preview-youtube.component.html'
})
export class PreviewYoutubeComponent implements OnChanges, AfterViewInit
{

  @Input() video: Video;

  videoUrl: SafeResourceUrl;

  constructor(private _sanitizer: DomSanitizer) {}

  ngAfterViewInit() {

    let self = this;
    jQuery('#youtube-preview-modal').on('hidden.bs.modal', function (e) {

      self.closePreviewer();
    })
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange})
  {
    if (changes['video'] && changes['video'].currentValue) {

      this.setVideoUrl(changes['video'].currentValue);
    }
  }

  setVideoUrl(video: Video) {

    this.videoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + video.youtube_id);
  }

  closePreviewer()
  {
    this.videoUrl = null;
    this.setVideoUrl(this.video);
    jQuery('#youtube-preview-modal').modal('hide');
  }
}
