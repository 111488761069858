import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {FileLibraryResourceService} from "../../../file-library/shared/file-library-resoruce.service";
import {FileModel} from "../../../file-library/shared/file.model";

@Injectable()
export class BackgroundsResolver implements Resolve<FileModel[]>
{
  constructor(protected flResourceService: FileLibraryResourceService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<FileModel[]>
  {
    return this.flResourceService.backgrounds()
      .toPromise()
      .then((backgrounds: FileModel[]) => {return backgrounds;})
    //  .catch((error) => {});
  }
}
