<div class="form-inline">
  <div class="input-group date col-md-5" [hubValidator]="errors?.name" transformer="inline">
    <input type="text" [(ngModel)]="category.name" class="form-control category-edit-name col-sm-12 col-xs-5"
           placeholder="Create a new category">
  </div>
  <div class="input-group date col-md-3" [hubValidator]="errors?.background" transformer="inline">
    <input [(ngModel)]="category.background" class="form-control" [(colorPicker)]="color" [cpPosition]="'left'" [cpPositionRelativeToArrow]="true"
           [style.background]="color" [value]="color">
  </div>

  <div class="input-group date col-md-2">
    <a (click)="create()" class="btn blue btn-outline btn-w80">Create</a>
  </div>

  <img src="assets/squares.gif" class="img spinners" alt="spinner" width="30px" *ngIf="processing">
</div>
