import {
  AfterViewInit, ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter, HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange, ViewChild
} from '@angular/core';
import {env} from "../../../../.env";
import {Trialline} from "../../main/shared/trialline.model";
import {DOCUMENT} from "@angular/common";
import {Event} from "../../main/shared/event.model";
import {TriallineNavigationComponent} from "../trialline/navigation/navigation.component";
import {SharedService} from "../../shared/shared.service";
import {FilterToTemplatePipe} from '../shared/filterToTemplate.pipe';
import * as $ from "jquery";
import jsPDF from 'jspdf';

declare var vis: any;
declare var jQuery: any;
declare var html2canvas: any;
declare var download: any;
declare var moment: any;

@Component({
  selector: 'app-shared-trialline-mobile',
  templateUrl: './trialline-mobile.component.html',
  styleUrls: ['./trialline-mobile.component.scss']
})
export class TriallineMobileComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() events: Event[];
  @Input() trialline: Trialline;
  @Input() editButton: boolean;
  @Input() commentsButton: boolean;
  @Output() onEdit = new EventEmitter<number>();
  @Output() onMore = new EventEmitter<any>();
  @Input() offsetHeight: number;

  @ViewChild('visn', { static: true }) el: ElementRef;
  @ViewChild(TriallineNavigationComponent,  { static: true }) navigation: TriallineNavigationComponent;

  scrollWidth: number = 0;
  initialTouch: number = 0;

  public publicPath = env.proxyUrl + env.publicFolderUrl;
  public apiPath = env.proxyUrl + env.apiUrl;
  public publicPathUrl = env.apiUrl;
  timeline: any;
  timebar: any = [];
  listNumberEvents: any = [];
  minDate: any = 0;
  maxDate: any = 0;
  cursor: any = {
    left: 0,
    width: null
  };
  flags: any[] = [];
  currentMonth: string;
  firstTime: boolean = true;
  $pyramid: any;
  eventWidth: number = 480;
  countItems: number = 0;
  positionList: number = 0;
  maxWidth: number = 1200;
  zoomMin: number = 7780;
  triallineOffset: number = 0;
  cofHeight: number = 2;
  windowWidth: number;
  elemOnTopOfPyramid: number = 0;
  eventsRef: any;
  password: any;
  macScroll: boolean = true;
  actions: boolean = true;

  public idVisualisation: string;
  timelineNew: any;
  defaultThumbnail: string = '/images/start-event/trialline-event-thumb.jpg';
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _changeDetectorRef: ChangeDetectorRef,
    private filterToTemplate: FilterToTemplatePipe,
    protected sharedService: SharedService) {
  }

  print() {
    this.checkWindowWidth();
  }

  transformTitle(str: string) {
    return !str || str.length < 40
      ? str
      : str.slice(0, 40).concat('...');
  }

  checkWindowWidth() {
    const max = 41000;
    const step = 8191;
    let width = jQuery('#pyramid-view-wrapper').get()[0].scrollWidth;
    let scrollX = 0;
    if (width * 4 < max) {
      return this.printCanvas(0, width, 0, this.trialline.name).then(() => {
        console.log('single downloaded')
      })
    }
    let promised = [];
    let i = 1;
    while (width > 0) {
      let imageWidth = step > width ? width : step;
      promised.push(this.printCanvas(scrollX, imageWidth, i, this.trialline.name));
      width -= imageWidth;
      scrollX += imageWidth;
      i++;
    }
    Promise.all(promised).then(() => {
      console.log('multiple downloaded')
    }).catch((e) => {
      console.log('Multiple error', e);
    })
  }

  printCanvas(scrollX, width, index, name = 'screenshot') {
    let positiopn  = this.triallineOffset
    this.triallineOffset = 0
    jQuery('#pyramid-view-wrapper').css({'transform': 'translateX(-50px)'});
    let self = this;
    return new Promise((resolve, reject) => {
      jQuery("#timeline").scrollLeft(0);
      setTimeout(()=>{ 
        html2canvas(document.getElementById('pyramid-view-wrapper'), {
          width: width,
          scale: 2,
          imageTimeout: 100,
          useCORS:true,
          logging:true,
          allowTaint:true,
          taintTest: false,
          //removeContainer: true,
          x: scrollX,
          removeContainer: true
        }).then(function (canvas) {
            let newName = name.replace(/[^A-Z0-9]+/ig, "_");
            let contentDataURL = canvas.toDataURL('png');
            let fileName = index ? newName + '-part-' + index : newName;
          if ((navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null)
            || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) {
              const imgWidth = 430;
              const pageHeight = 800;
              let imgHeight = canvas.height * imgWidth / canvas.width;
          let pageWidth = imgHeight * 3;
          let heightLeft = imgHeight;
          let height = width/4 < 800 ? 1400 : width/4 ;
          let pdf = new jsPDF('l', 'mm', [pageWidth, height], true); // A4 size page of PDF
              let position = 0;
              pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight, undefined,'FAST');
              heightLeft -= pageHeight;
              while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight, undefined,'FAST')
                heightLeft -= pageHeight;
              }
              pdf.save(fileName + '.pdf'); // Generated PDF
            if(screen.width < 1024) {
                document.getElementById("viewport").setAttribute("content",
                  "width=device-width, initial-scale=1");
              }
          } else {
            download(contentDataURL, fileName, 'image/png');
         }
          self.triallineOffset = positiopn
            resolve(true);
          })
          .catch((e) => {
            self.triallineOffset = positiopn
            console.log(e);
          })
        }, 3000);
    });
  }

  refreshScrollWidth() {
    this.scrollWidth = jQuery('#timeline').get()[0].scrollWidth - jQuery('#timeline').width();
  }

  @HostListener('document:keydown', ['$event'])
  keyNavigation(e: any) {
    const self = this;
    if (((e.which === 37) || (e.which === 39)) && window.innerWidth >= 767) {
      if (e.which === 37) {
          let value = 200;
        if (self.actions) {
          self.scrollEvents({wheelDelta: value} )
        }
      }
      if (e.which === 39) {
          let value = -200;
        if (self.actions) {
          self.scrollEvents({wheelDelta: value} )
        }
      }
    }
  }

  ngAfterViewInit() {
   this.refreshScrollWidth();
    let self = this;
    if (window.innerWidth > 767) {
      jQuery("#visn_1").swipe({
        swipeStatus: function (event, phase, direction, distance, duration, fingers, fingerData, currentDirection) {
          if (phase == 'move') {
            if(distance > 700) {
              distance = 700;
            }
            let val = (direction == 'left' ? (+distance) : (distance * (-1)));
            if (self.actions) {
              self.scrollEvents({wheelDelta: val} )
            }
          } else {
            if(distance > 5) {
              if (distance > 100) {
                distance = 600;
              }
              let val = (direction == 'left' ? (+distance) : (distance * (-1)));
              if (self.actions) {
                self.scrollEvents({wheelDelta: val})
              }
            }
          }
        },
        allowPageScroll: "horizontal"
      });
    }

    setTimeout(() => {
      jQuery('.vis-timeline').css({"height":"52px"})
      jQuery('.vis-timeline').find('.vis-bottom').css({"top":"0px"})
      jQuery('.trial-line-navigation-line-cursor').css({"width": 700 + 'px'});
    }, 100);
    this.timelineNew.on('changed', function (properties) {
      if((navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null) || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) {
        jQuery('.trial-line-navigation').css({"bottom":"-31px"})
      }
      jQuery('.vis-timeline').css({"height":"52px"})
      jQuery('.vis-timeline').find('.vis-bottom').css({"top":"0px"})
    });
    jQuery(window).resize(function (e) {
      let height = jQuery(window).height();
      if (height < 550) {
        self.cofHeight = 1;
      } else {
        self.cofHeight = 2;
      }
    });
    this.events.forEach( (element, index) => {
      this.listNumberEvents[element.id] = index;
    });
    this.countItems = this.timelineNew.itemsData.length
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    this.events = changes['events'].currentValue;
    this.renderAndSetItems();
    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i].category_name == "No Category") {
        if (this.events[i].category) {
          this.events[i].category.background = '#808080	'
        }
      }
    }
  }

  ngOnInit() {
    this.events = this.events.sort(this.sortFunction);
    if (this.password) {
      this.idVisualisation = this.password;
    } else {
      this.idVisualisation = '1'
    }
    let items = new vis.DataSet([]);
    this.timelineNew = new vis.Timeline(this.el.nativeElement, items,
      {
        zoomable: false,
        autoResize: false,
        height: "100%",
        stack: true,
        align: 'left',
        dataAttributes: ['id'],
        template: function () {
          return ''
        }
      });
    this.timelineNew.redraw();
    this.renderAndSetItems();
    this._changeDetectorRef.detectChanges();
  }

  sortFunction(a,b){
    let dateA = new Date(a.date_start).getTime();
    let dateB = new Date(b.date_start).getTime();
    return dateA > dateB ? 1 : -1;
  }

  formatDate(date) {
    return date ? this.getMomentDate(date).format('D') : '';
  }

  formatTime(date) {
    return date ? this.getMomentDate(date).format('hh:mma') : '';
  }

  formatMonth(date) {
    return date ? this.getMomentDate(date).format('MMMM') : '';
  }

  formatYear(date) {
    return date ? this.getMomentDate(date).format('YYYY') : '';
  }

  getMomentDate(date) {
    return moment.utc(date);
  }

  renderAndSetItems(events: any = this.events, saveCursor = false) {
    this.maxDate = 0;
    this.minDate = 0;
    let self = this;
    this.eventsRef = [];
    if (this.events && this.events.length != 0) {
      this.eventsRef = this.filterToTemplate.transformEvents(this.events);
    }
    if(this.eventsRef && this.eventsRef.length !== 0 ){
      events = this.eventsRef;
    }
    if (events.length > 0) {
      this.minDate = moment(events[0].start_date).valueOf();
    }
    let items = new vis.DataSet(events ? events.map(function (item, key) {
      item.start = item.start_date;
      if (moment(item.start_date).valueOf() < self.minDate) {
        self.minDate = moment(item.start_date);
      }
      if (moment(item.start_date).valueOf() > self.maxDate) {
        self.maxDate = moment(item.start_date);
      }
      return item;
    }) : []);
    if (this.timelineNew) {
      this.timelineNew.setItems(items);
    }
    this.changeLimits(saveCursor, this.getStartDate(events));
    this.navigation.calculateFlagsList();
  }

  swipeEvent(e?: any) {
    e.preventDefault();
  }

  @HostListener('mousewheel', ['$event'])
  scrollEvt(e?: any) {
    e.preventDefault();
    const fastScroll = e.wheelDelta < 50 && e.wheelDelta > -50
    if (!fastScroll && this.macScroll && this.actions) {
      this.scrollEvents(e);
    }
  }

  scrollEvents(e?: any, scrollToPosition?: number) {
    this.actions = false;
    if (e.target) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!this.windowWidth) {
      this.windowWidth = window.innerWidth;
    }
    if (!this.triallineOffset) {
      this.triallineOffset = 0;
    }
    const scroll  = e.wheelDelta;
    this.maxWidth = (this.countItems * this.eventWidth) +  this.eventWidth
    let scrollVal = Math.abs(scroll) > 200 ? Math.abs(scroll) : 300;
    if ( this.positionList < 800 && this.positionList < this.maxWidth / this.cofHeight) {
      this.positionList = this.positionList + (scroll > 0 ? scrollVal : -scrollVal)
    } else if (this.positionList < 0 && scroll > 0 ) {
        this.positionList = this.positionList + scrollVal;
    }else if (this.positionList  > 800 && scroll < 0 ) {
        this.positionList = this.positionList - scrollVal;
    }
    let cof  = this.maxWidth / this.windowWidth
    let newPost = (- this.positionList / cof) + 250;
    this.triallineOffset = this.positionList
    jQuery('.trial-line-navigation-line-cursor').animate({"left": newPost + 'px'}, 300, 'linear');
    setTimeout(() => this.actions = true, 200);
  }


  changeLimits(saveCursor: boolean = false, startDate, minDate = this.minDate, maxDate = this.maxDate) {
    if (startDate) {
      let date = new Date(startDate);
      date.setDate(date.getDate() - 3);
      let endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 4);
    }
    if (!minDate) {
      minDate = new Date();
    }
    if (this.timelineNew) {
      let diffTime = (moment(this.maxDate).valueOf() - moment(this.minDate).valueOf()) * 0.4;
      this.minDate = moment(minDate).valueOf() - diffTime;
      this.maxDate = moment(maxDate).valueOf() + diffTime;
      if (!saveCursor) {
        // this.timeline.setWindow(this.minDate, this.maxDate);
        let
          start = this.minDate,
          end = this.maxDate,
          interval = (end - start) * -0.2,
          pos = {
              start: start - interval,
              end: end + interval
            };
        this.timelineNew.setWindow(pos);
      }
      this.timelineNew.redraw();
    }
  }

  selectFlag(id = null) {
    if (id !== null ) {
      let number = this.listNumberEvents[id];
      this.positionList = ( - number * this.eventWidth) / this.cofHeight +  this.eventWidth;
      let cof = this.maxWidth / this.windowWidth;
      let newPost = (-this.positionList / cof) + 300;
      this.triallineOffset = this.positionList
      jQuery('.trial-line-navigation-line-cursor').animate({"left": newPost + 'px'}, 500);
    }
  };

  getStartDate(events) {
    let dates = [];
    events.forEach((order: any) => {
      dates.push(order.start_date);
    });
    let date_sort_asc = function (date1, date2) {
      if (date1 > date2) return 1;
      if (date1 < date2) return -1;
      return 0;
    };
    dates.sort(date_sort_asc);
    return (dates[0]);
  }

  eventMore(id: number, activeTab?) {
    this.onMore.emit({id: id, activeTab: (activeTab ? activeTab : 'description')});
  }
}
