import {Pipe, PipeTransform} from '@angular/core';
import {Category} from "../../../shared/category.model";

@Pipe({
  name: 'visibleCategories',
  pure: false
})

export class VisibleCategoriesPipe implements PipeTransform {
  transform(categories: Category[], args?: any[]): any {
    return categories.filter((category: Category) => {
       return (category.events_count > 0 || category.name === "No Category");
    }).sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }));
  }
}
