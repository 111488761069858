<div *ngIf="zipProcessing" style="position: fixed;top: 45%;left: 40%;background: #eff3f8;padding: 10px;border: 1px solid #044e89;border-radius: 10px;z-index: 9999999999;">
  <div style="text-align: center;">
    <img src="assets/squares.gif" class="img spinners" alt="spinner" style="opacity: .8;" />
  </div>
  <div style="font-weight: bold;">
    <div class="totat_zip_size" style="">      
    </div>
    <div style="">
      We are creating the zip! Please wait...
    </div>
  </div>
</div>
<style>
/* start */
#preview-modal .zoom_deactivate {
  width: 500px;
  height: 400px;
  /* overflow: hidden; */
}
/* #preview-modal .zoom_deactivate .modal-content{
  height: 100% !important;
} */
.download-text{
  margin: 30px 0px;
  font-size: 20px;
  font-weight: 700;
  color: #000;
}
.download-icon{
  width: 120px;
  height: 130px;
  margin: 10px auto;
}
.download-icon img{
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-width: 100%;
  object-fit: contain;
}
.zoom_active {
  width: 100%;
  height: 100%;
}
.zoom_active .modal-content {
  height: 100%;
}
.zoom_active .modal-body {
  height: 90%;
}
.modal-dialog.zoom_active{
  margin: 0 auto;
}
.zoom_deactivate .modal-body.body-image{
  overflow: auto;
  max-height: 500px;
}
/* End */

  #divmid1{margin-left: 50px;}
#divmid2{margin-left: 100px;}
#divmid3{margin-left: 150px;}
#divmid4{margin-left: 200px;}
#divmid5{margin-left: 250px;}
#divmid6{margin-left: 300px;}
#divmid7{margin-left: 350px;}
#divmid8{margin-left: 400px;}
#divmid9{margin-left: 450px;}
#divmid10{margin-left: 500px;}
#divmid11{margin-left: 550px;}
#divmid12{margin-left: 600px;}
#divmid13{margin-left: 650px;}
#divmid14{margin-left: 700px;}
#divmid15{margin-left: 750px;}
#divmid16{margin-left: 800px;}
#divmid17{margin-left: 850px;}
#divmid18{margin-left: 900px;}
#divmid19{margin-left: 950px;}
#divmid20{margin-left: 1000px;}
  a{
    text-decoration: none !important;
 }
  #folderbtn {
    padding: 7px 24px;
    margin-bottom: 40px;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    color: #fff;
    background-color: #337ab7;
    font-family: inherit;
    border-radius: 4px;
}
  .folderbtn {
    padding: 7px 24px;
    margin-bottom: 40px;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    color: #fff;
    background-color: #337ab7;
    font-family: inherit;
    border-radius: 4px;
}

  .input-text{
    outline: 0 !important;
    margin-top: -14px !important;
    border-width: 0 0 2px;
    border-color: lightblue;
}
.multiselect > label{
  display: inline;
  font-size: large;
}
.multiselect  .selectall{
  display: inline;
}
.cloud ,input[type=checkbox]{
  margin-right: 8px;
}
.upload-files .img-scroll{
    overflow-y: auto;
    display: block;
    width: auto;
    padding: 0;
    margin-top: 20px;
    /* max-height:  301px; */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
}

.form-inline .form-group{
    margin-right: 10px;
  
}
</style>
 <app-shared-dropzone *ngIf='onlyevent' [isLibrary]="true" (onSuccess)="addFile($event)" [path]="fullpath">
 </app-shared-dropzone>
  
 <div class="sub-header" *ngIf="displayPortion">
  <h4>
      Uploaded Files <small *ngIf="user">(Used: {{ user.filled_filesize }}mb out of {{ user.max_filesize }}mb)</small>
  </h4>
</div>
<div id="upload-filter-form" [ngClass]="displayPortion ? '' : 'modale-opened' " class="form-inline no-validate upload-files-outer">
  <div class="upload-files-section">
    <div class="form-group">
      <select (change)="selectFileType($event)" #filterType name="type" class="form-control">
        <option value="" selected>All Types</option>
        <option value="image">Image</option>
        <option value="text">Text</option>
        <option value="table">Excel</option>
        <option value="pdf">PDF</option>
        <option value="audio">Audio</option>
        <option value="video">Video</option>
      </select>
    </div>
    <div class="form-group">
          <label class="sr-only" for="idSearch">Search</label>
          <input #filterName type="text" name="search" class="form-control" id="idSearch"
                placeholder="Search"
                value="" (keyup)="filterFileName($event)">
    </div>
    <div class="pull-right" *ngIf='onlyevent'>
          <button class="btn btn-sm btn-primary" (click)="generatezip()" ><!-- [disabled]="callid.length==0" -->
            Download all Case Documents to my Computer(ZIP)
          </button>
          <button class="btn btn-sm btn-primary" style="margin-left: 10px;" data-toggle="modal" data-target="#moveModal" (click)="clearallchek()">
            Move Files
          </button>
          <button class="btn btn-sm btn-primary" style="margin-left: 10px;" (click)="createnewfolderincompanyfileslibray();" >
            Create a New Folder
          </button>
          <!-- End -->
      </div>
  </div>
</div>
<div class="row" *ngIf='onlyevent'>
  <div class="col-md-12">
    <!-- PAth And Button -->
     <hr style="height:1px;border:none;color:#868383;background-color:#a9a7a7;margin-top: -25px;"/>
        <div class="row">
          <div class="col-sm-10 col-md-10">
            <i  *ngIf='structuree==false' style="font-size: 16px;color: #0075bb;cursor:pointer;" class="fa fa-plus-circle" aria-hidden="true" (click)="assigne()"
                title="Show folder structure "></i>
             <i *ngIf='structuree==true' style="font-size: 16px;color: #0075bb;cursor:pointer;" class="fa fa-minus-circle" aria-hidden="true" (click)="hide()"
                title="Hide folder structure "></i>
            <span (click)="backtofolder(1)" style="font-size: 15px;color: #0075bb;cursor: pointer;text-decoration: none;font-family: GibsonSemiBold,Geneva,Tahoma,Verdana,sans-serif;line-height: 1.5em;font-weight:bold;font-style: normal;" title="Home Directory"> You are in folder
              <i class="fa fa-arrow-right" aria-hidden="true">&nbsp; </i>Home</span>
            <span *ngIf='structuree==false' >
              <span  *ngFor="let sa of backfolder;let i = index ">
                <a (click)="backtofolder(sa)" style="font-size: 15px;color:#0075bb;cursor: pointer;text-decoration: none;font-family: GibsonSemiBold,Geneva,Tahoma,Verdana,sans-serif;line-height: 1.5em;font-weight:bold;font-style: normal;" title={{sa}}>
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>
                 {{sa}}
                </a>
              </span>
            </span>
            
            <span *ngIf='structuree==true' >
              <span *ngFor="let sa of backfolder;let i = index "><a (click)="backtofolder(sa)" style="font-size: 15px;color:#0075bb;cursor: pointer;text-decoration: none;font-family: GibsonSemiBold,Geneva,Tahoma,Verdana,sans-serif;line-height: 1.5em;font-weight:bold;font-style: normal;" title={{sa}}>
               <div id="divmid{{i+1}}"><span style='font-size:27px;'>&#8627;</span>{{sa}}</div>
             </a>
             </span>
           </span>

          </div>          
          <div class="col-sm-2 col-md-2" *ngIf='backfolder.length>0'>
            <span  id="folderbtn-modal">
              <span data-toggle="modal" data-target="#submyModal">Create a Sub Folder</span>
           </span>
          </div>
        </div>
      <hr *ngIf='onlyevent' style="height:1px;border:none;color:#868383;background-color:#a9a7a7;margin-top:7px"/>
      <!-- End -->
       <!-- Temp-1 -->
        <div  *ngIf='backfolder.length>0' class="col-md-4" style="margin-top:7px;" title="click here to go up">
          <a (click)="levelup()"> <i class="fa fa-folder-open"  style="font-size: 24px;color: #034375;" ></i>&nbsp;&nbsp;
            <span style='font-size:22px;'>&#8226;</span><span style='font-size:22px;'>&#8226;</span>
          </a>
       </div>
        <div *ngFor="let nefo of TempFolder">
             <div class="col-md-4" style="margin-top: 1pc;">
              <div class="row">
                <div class="col-sm-12 col-md-12">
                  <a (click)="OpenTrlFolder('',nefo)">  <i class="fa fa-folder-open"  style="font-size: 24px;color: #034375;"></i>
                    <span style="font-size: 16px;font-family: sans-serif;font-weight: bold;">
                      {{nefo}}
                    </span>
                  </a>
                  <a  (click)="renamefoldermodal(nefo)"><i class="fa fa-pencil-square-o" aria-hidden="true" style="margin-left:0pc;font-size:14px;font-weight: initial;color: #337ab7; padding: 5px 4px 4px 4px;vertical-align: -0.1rem;" title="Rename Folder"
                   ></i>
                  </a>

                  <a (click)="deletefoldermodal(nefo)"> <i class="fa fa-trash" aria-hidden="true"
                     style="margin-left:0px;font-size: 14px;font-weight: initial;color: #337ab7;"
                     title="Delete Folder" ></i>
                    </a>
                 
                 
                </div>
              </div>
            </div>
        </div>
        <!-- list view or icon view button -->
        <button *ngIf='onlyevent' style="display: inline-block;padding: 8px 8px;font-size: 8pt;" (click)="preventDefault(listView); listView = ! listView"
              class="btn btn-sm  pull-right"
              [class.btn-default]="listView ? true : null"
              [class.btn-primary]="! listView ? true : null">
              <ng-container *ngIf="listView">
                <i class="fa fa-list"></i> Switch to List View                
              </ng-container>
              <ng-container *ngIf="!listView">
                <i class="fa fa-th-large"></i> Switch to Icon View 
              </ng-container>
              <!-- {{ listView ? "Switch to List View" : "Switch to Icon View" }} -->
        </button>
  </div>
</div>
<hr  *ngIf='onlyevent' style="height:1px;border:none;color:#868383;background-color:#a9a7a7;margin-top:0"/>
<div  infiniteScroll  [infiniteScrollDistance]="2" [infiniteScrollThrottle]="1000"
      (scrolled)="onScrollDown()"
>
<!-- start -->
<!-- For Event File And More Files from Dashboard view -->
<div class="123 tiles upload-files no-preview" [ngClass]="displayPortion ? '' : 'modale-opened' " *ngIf="!list && onlyevent==false">
  <ul class="img-scroll">
    <ng-container *ngIf="allFiles" onScrollDown(true) >
      <li *ngFor="let file of (allFiles | filterFiles:filterType.value:filterName.value)" class="tile image"
          (click)="selectFile(file)" >
        <div class="tile-body">
          <div *ngIf="cloudFunc" class="cloud" (click)="$event.stopPropagation(); toggleCloud(file)"
               [class.cloud-active]="file.in_library ? true : null">
              <i class="fa fa-cloud"></i>
          </div>
          <div *ngIf="cloudFunc" class="cloud" style="left: 35px;" (click)="$event.stopPropagation(); editFile(file)">
              <i class="fa fa-gear"></i>
          </div> <div *ngIf="cloudFunc" class="cloud" style="left: 70px;" (click)="$event.stopPropagation(); connectContact(file)">
              <i class="fa fa-user"></i>
          </div>
          <div *ngIf="removeFunc" class="remove" (click)="$event.stopPropagation(); remove(file)">&times;</div>
          <div *ngIf="file.type=='image'" class="upload-file-img"
               title="{{ getFileName(file.title) }}"
               [style.background-image]="'url(' + apiPath + 'filesystem/' + file.encrypt_id + '?preview=1)'"
          >
          </div>
          <div *ngIf="file.type!='image'" class="upload-file-img" title="{{ getFileName(file.title) }}">
            <img src="{{ imageIcons(file.type) }}" style="width: inherit;" alt="{{ getFileName(file.title) }}">
          </div>
          <div class="tile-object">
            <div class="name" style="width: 127px;overflow: hidden; line-break: anywhere;">{{ getFileName(file.title) }}</div>
          </div>
        </div>
      </li>
    </ng-container>
  </ul>
</div>
<!--  End-->
<!-- Icon View -->
<div class="tiles upload-files no-preview" *ngIf="list && onlyevent==true"> <!-- *ngIf="!list && onlyevent==true" -->
  <ul>
    <ng-container *ngIf="allFiles" onScrollDown(true)>
      <li *ngFor="let file of (companyFiles | filterFiles:filterType.value:filterName.value )| paginate: { id: 'foo',
                                                                  itemsPerPage: perPage,
                                                                  currentPage: page,
                                                                  totalItems: total}; let i = index;"  class="tile image"
          (click)="selectFile(file)" >
        <div class="tile-body">
          <div *ngIf="cloudFunc" class="cloud" (click)="$event.stopPropagation(); toggleCloud(file)"
               [class.cloud-active]="file.in_library ? true : null">
              <i class="fa fa-cloud"></i>
          </div>
          <div *ngIf="cloudFunc" class="cloud" style="left: 35px;" (click)="$event.stopPropagation(); editFile(file)">
              <i class="fa fa-gear"></i>
          </div>
          <div *ngIf="removeFunc" class="remove" (click)="$event.stopPropagation(); remove(file)">&times;</div>
          <div *ngIf="file.type=='image'" class="upload-file-img"
               title="{{ getFileName(file.title) }}"
               [style.background-image]="'url(' + apiPath + 'filesystem/' + file.encrypt_id + '?preview=1)'"
          >
          </div>
          <div *ngIf="file.type!='image'" class="upload-file-img" title="{{ getFileName(file.title) }}">
            <img src="{{ imageIcons(file.type) }}" style="width: inherit;" alt="{{ getFileName(file.title) }}">
          </div>
          <div class="tile-object">
            <div class="name" style="width: 127px;overflow: hidden; line-break: anywhere;">
             
              {{ getFileName(file.title) }}</div>
          </div>
        </div>
      </li>
    </ng-container>
  </ul>
  <div style="padding-top: 10px" >
    <select (change)="changePages($event)"  style="float: right; margin-left: 10px">
      <option value="25">25</option>
      <option value="50">50</option>
      <option value="100">100</option>
    </select>
    <div style="float: right">
      <pagination-controls  id="foo"
                            (pageChange)="pageChanged($event)"
                            maxSize="9"
                            responsive="true"
                            previousLabel="Previous"
                            nextLabel="Next"
                            screenReaderPaginationLabel="Pagination"
                            screenReaderPageLabel="page"
                            screenReaderCurrentLabel="You're on page">
      </pagination-controls>
    </div>
  </div>
</div>
<div *ngIf="!list && companyFiles.length>0" class="conteiner">
        <div class="row">
            <div class="col-xs-12 col-sm-1 bor-file nop col-thumb">
                <div class="col-xs-5 col-sm-12 tit-file-main  tbm title-thumb">Preview</div>
            </div>
            <div class="col-xs-12 col-sm-3 bor-file nop col-file-title">
                <div class="col-xs-5 col-sm-12 tit-file-main tbm title-file-title filter-by"
                     (click)="reorder('title')">Title
                  <span *ngIf="currentStatus=='title'" class="e2e-inner-html-bound"
                        [style.opacity]="order == 'title' ? '1' : '.3'"
                        [innerHTML]="getSortIcon('title')"></span>
                </div>
            </div>
            <div class="col-xs-12 col-sm-1 col-md-1 bor-file nop col-file-tipe">
                <div class="col-xs-5 col-sm-12 tit-file-main tbm title-file-tipe filter-by"
                     (click)="reorder('type')">Type
                  <span *ngIf="currentStatus=='type'"  class="e2e-inner-html-bound"
                        [style.opacity]="order == 'type' ? '1' : '.3'" [innerHTML]="getSortIcon('type')">
                  </span>
            </div>
            </div>
            <!-- <div class="col-xs-12 col-sm-3 bor-file nop col-atta">
                <div class="col-xs-5 col-sm-12 tit-file-main tbm title-atta filter-by"
                     (click)="reorder('attached')" >Attached to
                  <span *ngIf="currentStatus=='attached'"  class="e2e-inner-html-bound"
                        [style.opacity]="order == 'attached' ? '1' : '.3'"
                        [innerHTML]="getSortIcon('attached')"></span></div>
            </div> -->
            <div class="col-xs-12 col-sm-2 col-md-2 bor-file nop col-desc" (click)="reorder('description')">
                <div class="col-xs-12 col-sm-12 tit-file-main tbm title-desc filter-by">Description
                  <span *ngIf="currentStatus=='description'"  class="e2e-inner-html-bound"
                        [style.opacity]="order == 'description' ? '1' : '.3'"
                        [innerHTML]="getSortIcon('description')"></span></div>
            </div>
            <div class="col-xs-12 col-sm-2 col-md-2 bor-file nop col-file-act">
                <div class="col-xs-5 col-sm-12 tit-file-main tbm title-file-act">Action</div>
            </div>
         </div>
          <div *ngFor="let file of (companyFiles | filterFiles:filterType.value:filterName.value);index as i"
               class="row row-files">
            <div class="col-xs-12 col-sm-1 bor-file nop col-thumb">
                <div class="col-xs-5 col-sm-12 tit-file  tbm title-thumb">Preview</div>
                <div class="col-xs-7 col-sm-12 inf-file  tbm info-thumb" (click)="selectFile(file)">
                    <img *ngIf="file.type!='pdf'" [attr.src]="apiPath + 'filesystem/' + file.encrypt_id + '?preview=1'"
                         alt="{{ getFileName(file.title) }}" class="img-thumbnail"
                         style="height: 25px; max-width: 75px;">
                    <img  *ngIf="file.type=='pdf'" src="assets/pdfimages/pdf1.png" class="img-thumbnail" style="height: 25px; max-width: 75px;"
                      alt="{{ getFileName(file.title) }}">
                </div>
            </div>
            <div class="col-xs-12 col-sm-3 bor-file nop col-file-title">
                <div class="col-xs-5 col-sm-12 tit-file tbm title-file-title">Title</div>
                <div class="col-xs-7 col-sm-12 inf-file tbm info-file-title" style=" line-break: anywhere;">{{ getFileName(file.title) }}</div>
            </div>
            <div class="col-xs-12 col-sm-1 col-md-1 bor-file nop col-file-tipe">
                <div class="col-xs-5 col-sm-12 tit-file tbm title-file-tipe">Type</div>
                <div class="col-xs-7 col-sm-12 inf-file tbm info-file-tipe">{{ file.type }}</div>
            </div>
            <!-- <div class="col-xs-12 col-sm-3 bor-file nop col-atta">
                <div class="col-xs-5 col-sm-12 tit-file tbm title-atta">Attached to</div>
                <div class="col-xs-7 col-sm-12 inf-file tbm info-atta">
                    <div class="atta" *ngFor="let item of file.list">
                        {{ item }}
                    </div>
                </div>
            </div> -->
            <div class="col-xs-12 col-sm-2 col-md-2 bor-file nop col-desc">
                <div class="col-xs-12 col-sm-12 tit-file tbm title-desc">Description</div>
                <div id='descdiv_{{i}}' class="col-xs-12 col-sm-12 inf-file tbm info-desc" title={{file.description}}
                     (click)='showdescription(i)'>
                    {{ file.description }}
                </div>
                <div id='descdivclose_{{i}}' style="display:none;" class="col-xs-12 col-sm-12 inf-file tbm info-desc"
                     title="{{file.description}}" (click)='closedescription(i,file.description)'>
                  {{ file.description }}
              </div>
            </div>
            <div class="col-xs-12 col-sm-2 col-md-2 bor-file nop col-file-act">
                <div class="col-xs-5 col-sm-12 tit-file tbm title-file-act">Action</div>
                <div class="col-xs-12 col-sm-12 inf-file tbm info-file-act">
                  <button (click)="editFile(file)" class="btn btn-sm btn-info" title="Edit">
                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
                    <button (click)="$event.stopPropagation(); connectContact(file)" class="btn btn-sm btn-info"
                            title="Connect image to a Contact" style="margin-left: 5px;">
                      <i class="fa fa-user" aria-hidden="true"></i>
                    </button>
                    <button (click)="$event.stopPropagation(); remove(file)" class="btn btn-sm btn-danger"
                            title="Delete from everywhere" style="margin-left: 5px;"><i class="fa fa-times" aria-hidden="true"></i></button>
                    <!-- <button (click)="$event.stopPropagation(); toggleCloud(file)" class="btn btn-sm"
                            title="Hide from file library"><i class="fa fa-lock" aria-hidden="true"></i>
                    </button> -->
                </div>
            </div>
            <div class="col-xs-12 col-sm-2 col-md-2 bor-file nop col-desc"></div>
            <div class="col-xs-12 col-sm-7 col-md-7 bor-file nop col-desc">
                 <textarea id='desc_{{i}}' rows="1" cols="40"   value="{{ file.description }}" class="form-control"
                           style="display: none;" readonly (focusout)="hidedescription(i)" (click)="$event.stopPropagation(); editFile(file)"
                           title="click to Edit">
                 </textarea>
            </div>
        </div>
    </div>
    <div *ngIf="companyFiles.length==0 && onlyevent">
      <label  style="font-weight: bold;color: #337ab7;">*Looks like you haven't added any documents to
        <span style="color: black;">{{foldernametoshow}}</span> yet.</label>
  </div>
</div>
<app-file-edit *ngIf="cloudFunc" [file]="file" (onUpdated)="updated($event)"></app-file-edit>
<span defaultOverlayTarget></span>
<!-- Create New Folder Model -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal"  aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header" style="border-bottom:none">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
        <h5 class="modal-title" id="myModalLabel">New Folder</h5>
      </div>
      <div class="modal-body" style="height: 36px;">
       <input class="form-control input-text"  id="input-text-folder" type="text" [(ngModel)]="cfolderNamemain"
              placeholder="New Folder" required/>
      </div>
      <div class="modal-footer" style="border-top:none">
        <button type="button" class="btn btn-sm btn-default" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-sm btn-primary" (click)="createFolderonmaindirectory()">Create</button>
      </div>
    </div>
  </div>
</div>
<!-- Create sub Folder Model -->
<div class="modal fade" id="submyModal" tabindex="-1" role="dialog" aria-labelledby="myModal"  aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header" style="border-bottom:none">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
        <h5 class="modal-title" id="myModalLabelNew">New Sub Folder</h5>
      </div>
      <div class="modal-body" style="height: 36px;">
       <input class="form-control input-text"  id="input-text" type="text" [(ngModel)]="folderName"
              placeholder="New Sub Folder" required/>
      </div>
      <div class="modal-footer" style="border-top:none">
        <button type="button" class="btn btn-sm btn-default" data-dismiss="modal">Cancel</button>
         <button type="button" class="btn btn-sm btn-primary" (click)="createFolder()">Create</button>
      </div>
    </div>
  </div>
</div>
<!-- Delete Modal -->
<div id="deletemodal" class="modal fade" role="dialog" data-backdrop="static">
  <div class="modal-dialog " style="width:70pc;">
  <div class="modal-content">
      <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h4 class="modal-title">Confirmation</h4>
        </div>
      <div class="modal-body">
          <label>Are you sure you want to DELETE this folder from this Comapany File Library ? To
            avoid losing the files in this folder, move them to a new folder first, THEN delete this folder. Are you
            sure you want to delete this folder?</label>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary"  (click)='confirmedDeletefolder()'>Yes, delete folder. </button>
        <button type="button" class="btn btn-default" data-dismiss="modal">No, nevermind.</button>
      </div>
    </div>
</div>
</div>
<!-- Rename folder modal -->
<div class="modal fade" id="renameModal" tabindex="-1" role="dialog" aria-labelledby="myModal"  aria-hidden="true"
     data-backdrop="static">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header" style="border-bottom:none">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
        <h5 class="modal-title" id="myModalLabelRename">Rename Folder</h5>
      </div>
      <div class="modal-body" style="height: 36px;">
       <input class="form-control input-text"  id="rename-text" type="text" [(ngModel)]="renamefolderName"
              placeholder="Rename Folder" required/>
      </div>
      <div class="modal-footer" style="border-top:none">
        <button type="button" class="btn btn-sm btn-default" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-sm btn-primary" (click)="renamefolder()">Rename</button>
      </div>
    </div>
  </div>
</div>
<!-- Move Files Modal  -->
<div id="moveModal" class="modal fade" role="dialog" data-backdrop="static">
  <div class="modal-dialog " style="width:75pc;height: 49pc;">
  <div class="modal-content">
      <div class="modal-header" style="height:5pc;">
        <div class="row">
          <div class="col-sm-2" >
            <h4 class="modal-title" style="margin-left:-3pc;text-align: center;font-size: larger;">Move Files</h4>
          </div >
           <div class="col-sm-10">
             <h4 class="modal-title" style="margin-left: -13pc;text-align: center;font-size: larger;">Select Files/Folders That You Would Like To Move</h4>  </div>
        </div>
        <div class="row">
            <div class="col-sm-10">
                <div class="col-sm-12">
                  <span (click)="backtofolder(1)" style="font-size: 15px;color: #0075bb;cursor: pointer;text-decoration: none;font-family: GibsonSemiBold,Geneva,Tahoma,Verdana,sans-serif;line-height: 1.5em;font-weight:bold;font-style: normal;" title="Home Directory"> You are in folder
                    <i class="fa fa-arrow-right" aria-hidden="true">&nbsp; </i>Home</span>
                  <span *ngFor="let sa of backfolder"><a (click)="backtofolder(sa)" style="font-size: 15px;color:#0075bb;cursor: pointer;text-decoration: none;font-family: GibsonSemiBold,Geneva,Tahoma,Verdana,sans-serif;line-height: 1.5em;font-weight:bold;font-style: normal;" title={{sa}}>
                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                  {{sa}}</a>
                  </span>
                </div>
            </div>
            <div class="col-sm-1">
              <button id="folderbtn" type="button"  data-toggle="modal" data-target="#movestomodal"
                      class="btn btn-sm btn-primary" (click)="movesto()"
                      [disabled]="moveid.length==0 && checkboxstore.length==0"
              style="padding: 7px 20px;">Move to...</button>
             </div>
            <div class="col-sm-1">
                <button class="btn " style="background-color: #337ab7;color: white;"  data-dismiss="modal" title="click to close modal">
                  <i class="fa fa-close"></i></button>
            </div>
            <!-- MOves Popup Work  -->
          <div>
            <div class="modal fade" id="movestomodal" role="dialog" >
                <div class="modal-dialog" style="width:48pc;margin-top: -16pc;margin-left: 16pc;overflow: auto;height: 21pc;">
                 <div class="modal-content">
                    <div class="modal-header">
                      <button type="button" class="close" (click)='hidemovetomnodal()'>&times;</button>
                      <h5 class="modal-title">Move ({{countfile}}/{{foldercount}}) files/folder here ...</h5>
                    </div>
                    <div class="modal-body">
                        <span *ngIf="'Home' != foldernametoshow"  (click)="moveshere('')" style="font-size: 15px;color: #0075bb;cursor: pointer;text-decoration: none;font-family: GibsonSemiBold,Geneva,  Tahoma,Verdana,sans-serif;line-height: 1.5em;font-weight:bold;font-style: normal;" title="Home Directory">Home Directory</span>
                        <hr style="margin-top:-2px;height:1px;border:none;color:#868383;background-color:#a9a7a7;" />
                       <span  *ngFor="let mainitem of arr_name;let j = index " >
                              <i *ngIf='structure1==true && showid!=j && mainitem != foldernametoshow' style="font-size: 16px;color: #0075bb;cursor:pointer;" class="fa fa-plus-circle"
                                 aria-hidden="true" (click)="moveassigne(j)" title="Show folder structure "></i>
                              <i *ngIf='structure2==false && showid==j && mainitem != foldernametoshow ' style="font-size: 16px;color: #0075bb;cursor:pointer;" class="fa fa-minus-circle"
                                 aria-hidden="true" (click)="movehides(j)" title="Hide folder structure "></i>
                            <span  *ngIf="mainitem != foldernametoshow" (click)="moveshere(mainitem)" style="font-size: 15px;color: #0075bb;cursor: pointer;text-decoration: none;font-family: GibsonSemiBold,Geneva,  Tahoma,Verdana,sans-serif;line-height: 1.5em;font-weight:bold;font-style: normal;" title="Home Directory">Home</span>
                             <span  *ngFor="let subitem of mainitem;let i = index ">
                                <span *ngIf='structure1==true && showid!=j && mainitem != foldernametoshow' (click)="moveshere(mainitem)">
                                 <a  style="font-size: 15px;color:#0075bb;cursor: pointer;text-decoration: none;font-family: GibsonSemiBold,Geneva,Tahoma,Verdana,sans-serif;line-height: 1.5em;font-weight:bold;font-style: normal;" >
                                  <i class="fa fa-arrow-right" aria-hidden="true"></i>{{subitem}}
                                   </a>
                                </span>
                                <span  *ngIf='structure2==false && showid==j ' (click)="moveshere(mainitem)">
                                  <a  style="font-size: 15px;color:#0075bb;cursor: pointer;text-decoration: none;font-family: GibsonSemiBold,Geneva,Tahoma,Verdana,sans-serif;line-height: 1.5em;font-weight:bold;font-style: normal;" >
                                    <div id="divmIdMo{{i+1}}"><span style='font-size:27px;'>&#8627;</span>{{subitem}}</div>
                                   </a>
                                 </span>
                             </span>
                            <hr style="margin-top:-2px;height:1px;border:none;color:#868383;background-color:#a9a7a7;" />
                           </span>
                      </div>
                  </div>
                </div>
            </div>
          </div>
        <!-- End -->
        </div>
      </div>
      <div class="modal-body">
        <!-- Display Folders -->
        <div class="row">
            <div class="col-md-12">
               <!-- Temp-1 -->
              <div *ngFor="let nefo of TempFolder;let i = index " >
                 <div class="col-md-4" style="margin-top:1pc;">
                  <div class="row">
                      <div class="col-sm-12 col-md-12">
                            <span  class="cloud" >
                              <input type="checkbox"  id="{{htmlgeneration(nefo)}}"  name="foldercheck"
                                     (change)="checkValue($event,nefo)">
                           </span>
                          <a   (click)="OpenTrlFolder('',nefo)">
                            <i class="fa fa-folder-open"  style="font-size: 24px;color:#034375;"></i>&nbsp;&nbsp;<span style="font-size: 16px;font-family: sans-serif;font-weight: bold;">
                              {{nefo}}
                            </span></a>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <!-- End -->
        <!-- Display Files -->
        <div class="row">
            <div class="col-md-12">
               <hr *ngIf='TempFolder.length>0 && companyFiles.length>0' style="height:1px;border:none;color:#868383;background-color:#a9a7a7;" />
              <div class="tiles upload-files no-preview" >
                <div class="multiselect" *ngIf='companyFiles.length>0'>
                  <label>Select All Files </label>
                  <input class="selectall" type="checkbox"  [checked]="isMultiSelect"  (click)="moveAllFiletofolder($event)" value="selectall">
                </div>  
                <ul>
                    
                    <ng-container onScrollDown(true)>
                      <li *ngFor="let file of companyFiles"  class="tile image" >
                        <div class="tile-body">
                          <!-- <div>
                              <input type="checkbox" (click)="moveFiletofolder(file)"  [checked]="file.created_at==true">
                          </div> -->
                          <div  class="cloud" (click)="moveFiletofolder(file)"
                                              [class.cloud-active]="file.created_at ? true : null">
                                              <input type="checkbox"  [checked]="file.created_at==true">
                          </div>
                          <div *ngIf="file.type=='image'" class="upload-file-img"
                              title="{{ getFileName(file.title) }}"
                              [style.background-image]="'url(' + apiPath + 'filesystem/' + file.encrypt_id + '?preview=1)'"
                          >
                          </div>                          
                          <div *ngIf="file.type!='image'" class="upload-file-img" title="{{ getFileName(file.title) }}">
                            <img src="{{ imageIcons(file.type) }}" style="width: inherit;" alt="{{ getFileName(file.title) }}">
                          </div>
                          <div class="tile-object">
                            <div class="name" style="width: 127px;overflow: hidden; line-break: anywhere;">{{ getFileName(file.title)}}</div>
                          </div>
                        </div>
                      </li>
                    </ng-container>
                  </ul>
              </div>
            </div>
        </div>
        <!-- End -->
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div> -->
    </div>
  </div>
</div>
<!-- Previewm  modal -->
<div id="preview-modal" class="modal fade bs-modal-lg in" tabindex="-1" role="dialog" data-backdrop="static"
     style="z-index: 9999999; overflow: visible">
  <div class="modal-dialog modal-lg modal-preview" ngClass="{{ fullscreen ?
   'zoom_active' : 'zoom_deactivate' }}" tabindex="-1">
   <div class="modal-content draggable" #previewModal3
       [style.width]="fullscreen ? '100%' : width+'px'"
       [style.height]="fullscreen ? '100%' : height+'px'"
       [style.transform]="'translate3d('+ left + 'px,' + top + 'px,' + '0px)'"
       [class.active]="status === 1 || status === 2"
       (mousedown)="setStatus($event, 2)"
       (touchstart)="setStatus($event, 2)"
       (window:mouseup)="setStatus($event, 0)"
       (touchend)="setStatus($event, 0)">
      <div class="modal-header">
        <button (click)="closePreviewer()" id="closePreviewer" type="button" class="close"><span
          aria-hidden="true">&times;</span></button>
        <!-- <button (click)="zoomPreviewToggle()" id="zoomPreviewToggle" type="button" class="close close1 fullscrean">
              <span aria-hidden="true">
                <i class="fa fa-search-{{ fullscreen ? 'minus' : 'plus' }}" aria-hidden="true"></i>
              </span>
        </button> -->
        <h4 class="modal-title">{{file?.title.length > 50 ? (file?.title.substr(0, 50) + '...') : file?.title}}</h4>
      </div>
      <div class="modal-body {{file?.type === 'image' ? 'body-image' : ''}} -{{file?.mime}}-
            {{file?.type === 'pdf' ? 'body-pdf' : ''}}"
           [ngSwitch]="file?.type || file?.mime">
        <img *ngSwitchCase="'image'" class="img-responsive" [attr.src]="getSafeResourceUrl()"
             alt="{{ getFileName(file.title) }}">
        <video *ngSwitchCase="'video'" width="100%" controls>
          <source [attr.src]="getSafeResourceUrl(file)" type="video/mp4">
          Your browser does not support HTML5 video.
        </video>
        <audio    *ngSwitchCase="'audio/mp3'" controls>
          <source [attr.src]="getSafeResourceUrl()" type="audio/mpeg">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/wav'" controls>
          <source [attr.src]="getSafeResourceUrl()" type="audio/wav">
          Your browser does not support the audio element.
        </audio>
        <!-- Start -->
        <audio *ngSwitchCase="'audio'" controls>
          <source [attr.src]="getSafeResourceUrl()" type="audio/mp3">
          Your browser does not support the audio element.
        </audio>
        <!-- End -->
        <audio *ngSwitchCase="'audio/flac'" controls>
          <source [attr.src]="getSafeResourceUrl()" type="audio/flac">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/x-aiff'" controls>
          <source [attr.src]="getSafeResourceUrl()" type="audio/x-aiff">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/aac'" controls>
          <source [attr.src]="getSafeResourceUrl()" type="audio/aac">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/ac3'" controls>
          <source [attr.src]="getSafeResourceUrl()" type="audio/ac3">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/x-ms-wma'" controls>
          <source [attr.src]="getSafeResourceUrl()" type="audio/x-ms-wma">
          Your browser does not support the audio element.
        </audio>
          <div class="pdf" *ngSwitchCase="'pdf'" style="height: 100% ">
            <iframe *ngIf="screenW"  scrolling="auto"  class="mobile-pdf" [src]="pdfpathIpad" width="100%"
                    height="100%"  type='application/pdf'>
            </iframe>
            <iframe  *ngIf="!screenW"  scrolling="auto"  class="desktop-pdf"  type="application/pdf" [src]='pdfpath'
                     id='frameId' width="100%"
                     height="100%"  ></iframe>
          </div>
         <div *ngSwitchDefault style="text-align: center;">
          <div class="download-icon">
            <img src="../../../assets/images/filenotfound.png" alt="Download file">
          </div>
             <p class="download-text">It looks like  this file doesn't have  a <br>
             Preview  we can show you</p>
           <a [href]="apiPath + 'filesystem/' + file?.encrypt_id + '/download'" class="btn btn-sm btn-primary download-btn"> <i class="fa fa-download" style="margin-right: 15px;" aria-hidden="true"></i>Download attachment</a>
        </div>
      </div>
      <div class="resize-action" (touchstart)="setStatus($event, 1)"  (mousedown)="setStatus($event, 1)"></div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>
<div class="modal-contact">
  <app-modal-contacts
                      [file]="fileContact"
                      [triallinesId]=""
                      (onAddContact)="addContactsToFile($event)"
  >
  </app-modal-contacts>
</div>