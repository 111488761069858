<div id="contacts-show-more-tab" class="" >
  <div class="portlet-body">
    <div class="table-scrollable table-scrollable-borderless">
      <table class="table table-hover editable">
        <thead>
        <tr class="uppercase">
          <th></th>
          <th>Bill/Cost</th>
          <th>Amount</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let bill_cost of bill_costs">
          <td (click)="selectFile(bill_cost)">
            <div class="category-view-thumbnail">
             
              <ng-container *ngIf="bill_cost.file.type=='image'">
                <img [attr.src]="getSafeThumbnailUrl(bill_cost)" alt="{{ bill_cost.attach_text }}"
                    class="img-thumbnail" style="height: 150px;"
                    title="{{getSafeUrl(bill_cost)}}">                
              </ng-container>
              <ng-container *ngIf="bill_cost.file.type!='image'">
                <img src="{{ imageIcons(bill_cost.file.type) }}" alt="{{ getFileName(bill_cost.file.title) }}"
                                 title="{{getSafeUrl(bill_cost)}}">
              </ng-container>
              
              <div class="name">{{ bill_cost.file.title }}</div>
            </div>
          </td>
          
         

          <td>
            <span class="category-view-name">{{ bill_cost.bill_cost.toUpperCase() }}</span>
          </td>
          <td>
            <span class="category-view-slug">${{ bill_cost.amount }}</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div id="preview-modal2" class="modal fade bs-modal-lg in" tabindex="2" role="dialog" data-backdrop="static"
     style="z-index: 9999999; overflow: visible">
  <div class="modal-dialog modal-lg modal-preview" ngClass="{{ fullscreen ?
   'zoom_active' : 'zoom_deactivate' }}" style="{{ fullscreen ? '' : 'top:-30px;'}} " tabindex="-1" >
    <div class="modal-content draggable" #previewModal2
         [style.width]="fullscreen ? '100%' : width+'px' "
         [style.height]="fullscreen ? '100%' : height+'px'"
         [style.transform]="'translate3d('+ left + 'px,' + top + 'px,' + '0px)'"
         [class.active]="status === 1 || status === 2"
         (mousedown)="setStatus($event, 2)"
         (window:mouseup)="setStatus($event, 0)">
      <div class="modal-header">
        <button (click)="closePreviewer()" id="closePreviewer" type="button" class="close"><span
          aria-hidden="true">&times;</span></button>
        <!-- <button (click)="zoomPreviewToggle()" id="zoomPreviewToggle" type="button" class="close close1 fullscrean">
              <span aria-hidden="true">
                <i class="fa fa-search-{{ fullscreen ? 'minus' : 'plus' }}" aria-hidden="true"></i>
              </span>
        </button> -->
        <h4 class="modal-title">{{billCost?.attach_text}}</h4>
      </div>
      <div class="modal-body {{file?.type === 'image' ? 'body-image' : ''}} -{{file?.mime}}- {{file?.type === 'pdf' ? 'body-pdf' : ''}}"
           [ngSwitch]="file?.type || file?.mime">
        <img *ngSwitchCase="'image'" class="img-responsive" [attr.src]="getSafeResourceUrl()"
             alt="{{ getFileName(file.title) }}">
        <video *ngSwitchCase="'video'" width="100%" controls>
          <source [attr.src]="getSafeResourceUrl()" type="video/mp4">
          Your browser does not support HTML5 video.
        </video>
        <audio    *ngSwitchCase="'audio/mp3'" controls>
          <source [attr.src]="getSafeResourceUrl()" type="audio/mpeg">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/wav'" controls>
          <source [attr.src]="getSafeResourceUrl()" type="audio/wav">
          Your browser does not support the audio element.
        </audio>
        <!-- Start -->
        <audio *ngSwitchCase="'audio'" controls>
          <source [attr.src]="getSafeResourceUrl()" type="audio/mp3">
          Your browser does not support the audio element.
        </audio>
        <!-- End -->
        <audio *ngSwitchCase="'audio/flac'" controls>
          <source [attr.src]="getSafeResourceUrl()" type="audio/flac">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/x-aiff'" controls>
          <source [attr.src]="getSafeResourceUrl()" type="audio/x-aiff">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/aac'" controls>
          <source [attr.src]="getSafeResourceUrl()" type="audio/aac">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/ac3'" controls>
          <source [attr.src]="getSafeResourceUrl()" type="audio/ac3">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/x-ms-wma'" controls>
          <source [attr.src]="getSafeResourceUrl()" type="audio/x-ms-wma">
          Your browser does not support the audio element.
        </audio>
        <div class="pdf  ghgh" *ngSwitchCase="'pdf'" style="height: 100% ">
          <iframe *ngIf="screenW"  scrolling="auto"  class="mobile-pdf" [src]="pdfpathIpad" width="100%"
                  height="100%"  type='application/pdf'>
          </iframe>
          <iframe  *ngIf="!screenW"  scrolling="auto"  class="desktop-pdf"  type="application/pdf" [src]='pdfpath'
                   id='frameId' width="100%"
                   height="100%"  ></iframe>
        </div>
        <div *ngSwitchDefault style="text-align: center;">
          <div class="download-icon">
            <img src="../../../assets/images/filenotfound.png" alt="Download file">
          </div>
             <p class="download-text">It looks like  this file doesn't have  a <br>
             Preview  we can show you</p>
          <!-- This file format can't be previewed, you can -->
          <a [href]="getSafeResourceUrl()" class="btn btn-sm btn-primary download-btn"><i class="fa fa-download" style="margin-right: 15px;" aria-hidden="true"></i>download this file</a>
        </div>
      </div>
      <div class="resize-action" (mousedown)="setStatus($event, 1)"></div>
    </div><!-- /.modal-content -->
  </div>
</div>
