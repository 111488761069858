import {NgModule} from '@angular/core';
import {HttpClientModule} from "@angular/common/http";
import {FormsModule, FormGroup} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {TriallinesComponent} from "./triallines.component";
import {TriallinesResourceService} from "./shared/triallines-resoruce.service";
import {TriallinesResolver} from "./shared/triallines.resolver";
import {CreateTriallineComponent} from "./create-trialline/create-trialline.component";
import {ValidatorModule} from "../../form-validator/validator.module";
import {ModalModule} from 'ngx-modialog-7';
import {BootstrapModalModule} from 'ngx-modialog-7/plugins/bootstrap';
import {SubscriptionComponent} from "./subscription/subscription.component";
import {FirstTimeModalComponent} from "./fisrt-time/first-time.component";
import {ProfileModule} from "../profile/profile.module";
import { MattersComponent } from "../profile/clio/matters/matters.component";
import {ArchivedTriallinesComponent} from './archived-triallines/archived-triallines.component';
import { FileUploadModule } from 'ng2-file-upload';
import { SearchPipe } from 'app/search.pipe';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    ValidatorModule,
    ModalModule.forRoot(),
    BootstrapModalModule,
    ProfileModule,
    FileUploadModule,
    NgxPaginationModule
  ],
  declarations: [
    TriallinesComponent,
    CreateTriallineComponent,
    SubscriptionComponent,
    FirstTimeModalComponent,
    MattersComponent,
    ArchivedTriallinesComponent,
    SearchPipe
  ],
  providers: [
    TriallinesResourceService,
    TriallinesResolver
  ]
})
export class TriallinesModule {}
