<div id="add-card-modal-new" class="modal fade bs-modal-md in prise-modal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header ">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">Please add your credit card information. Remember, you will not be charged until your FREE trial expires.</h4>
      </div>
      <div class="modal-body portlet-body form">
        <div style="margin: 15px;">
          <form #addCardForm="ngForm" (submit)="addCard(addCardForm)">
            <label>
              <span>Name</span>
              <input name="name" [ngModel]="user?.first_name + ' ' + user?.last_name" type="text" class="field" />
            </label>
            <label>
              <span>Phone</span>
              <input name="phone" [ngModel]="user?.phone" class="field" type="tel" />
            </label>
            <label>
              <span>Card</span>
              <div id="card-element-new" class="field"></div>
            </label>
            <label>
              <span>
                <span>I agree to the</span>
                <a href="/privacy-policy" target="_blank">Privacy Policy </a>
              </span>
              <input type="checkbox" name="privacyAgree" [(ngModel)]="privacyAgree" class="field" />
            </label>
            <button [disabled]="processing || !privacyAgree" type="submit" class="btn blue submit pull-right">Submit</button>
            <div class="clearfix"></div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

