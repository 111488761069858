
<div class="card-feed-entry" *ngFor="let feed of feeds ;let i = index" >
  
  <!-- <div *ngIf='myItem=="dashboard"'>
      {{i+1}}.<a [href]="feed.link[0]" target="_blank" >{{feed.title[0]}}</a>
  </div>
   <div *ngIf='myItem=="support"'>
    {{i+1}}.<a [href]="feed.link" target="_blank" >{{feed.title}}</a>
</div><br>   -->
  <div>
  {{i+1}}.<a [href]="feed.link" target="_blank" >{{feed.title}}</a>
  </div><br>
  
   <!-- <li style="padding: 5px;" > <a [href]="feed.link" target="_blank" >{{feed.title}}</a></li>  -->
 </div>