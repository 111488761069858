import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from 'ngx-toastr';

import {AuthResourceService} from "../../shared/auth-resoruce.service";
declare var jQuery:any;
@Component({
  selector: 'app-auth-send_email',
  templateUrl: './send.component.html',
})
export class SendComponent
{
  errors: any = {};
  processing: boolean = false;

  constructor(
    protected authResourceService: AuthResourceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {}

  send(form: any)
 {

    this.processing = true;
    this.authResourceService.sendResetPasswordEmail(form.value).subscribe(
      (response: any)  =>  this.handleSuccess(response),
      (error: any)    =>  this.handleError(error)
    );
  }

  handleSuccess(response: Response)
  {

    this.processing = false;
    this.errors = {};
    this.toastr.success('We have e-mailed your password reset link!');
  }

  handleError(error: any)
  {

    this.processing = false;
    if (error.status == 422)
    {
      jQuery("#invalidmailModal").modal('show')
      let errorData: any = error.error;
      this.errors = errorData.data;
    }
  }
}
