import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {Trialline} from "../../../shared/trialline.model";
import {TriallineResourceService} from "../../shared/trialline-resoruce.service";

@Injectable()
export class TlResolver implements Resolve<Trialline>
{
  constructor(protected triallineResource: TriallineResourceService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Trialline>
  {
    return this.triallineResource.getTrialline(route.parent.params['trialline_id'])
      .toPromise()
      .then((trialline: Trialline) => {return trialline;})
    //  .catch((error) => { this.router.navigateByUrl(error.status == 403 ? '/403' : '/404') });
  }
}
