<div class="container page-content-inner page-wrap">
    <div class="row">
        <div class="col-md-12">
            <app-profile-sidebar [user]="user"></app-profile-sidebar>
            <div class="profile-content">
                <div class="row">
                    <div class="col-md-12">
                        <div class="portlet light ">
                            <div class="portlet-body">
                                <div class="row tabs">
                                    <div class="col-sm-12 pull-right tabs-button">
                                        <!-- Nav tabs -->
                                        <div class="card">
                                            <ul class="nav nav-tabs" role="tablist">
                                                <li role="presentation" [class.active]="activeTab == 'description' || !activeTab">
                                                    <a (click)="activeTab = 'description'" aria-controls="info" role="tab" data-toggle="tab"
                                                       aria-expanded="true">
                                                        Clio
                                                    </a>
                                                </li>
<!--                                                <li role="presentation" [class.active]="activeTab == 'files'">-->
<!--                                                    <a (click)="activeTab = 'files'" aria-controls="files" role="tab" data-toggle="tab"-->
<!--                                                       aria-expanded="false">-->
<!--                                                        Files-->
<!--                                                    </a>-->
<!--                                                </li>-->
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-content">
                                    <div role="tabpanel" class="tab-pane" [class.active]="activeTab == 'description'" id="info">
                                        <form #clioForm="ngForm" (submit)="update(clioForm)"
                                              class="form-horizontal margin-bottom" novalidate>
<!--                                            <div class="form-body">-->
<!--                                                <div class="form-group" [hubValidator]="errors?.app_key_clio"-->
<!--                                                     transformer="inline">-->
<!--                                                    <label class="col-md-3 control-label">App key</label>-->
<!--                                                    <div class="col-md-9">-->
<!--                                                        <input name="app_key_clio" required type="text" class="form-control"-->
<!--                                                               [ngModel]="user?.app_key_clio"-->
<!--                                                               #appKeyClio="ngModel"-->
<!--                                                               maxlength="50">-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                                <div class="form-group" [hubValidator]="errors?.app_secret_clio"-->
<!--                                                     transformer="inline">-->
<!--                                                    <label class="col-md-3 control-label">App secret</label>-->
<!--                                                    <div class="col-md-9">-->
<!--                                                        <input name="app_secret_clio" required type="text" class="form-control"-->
<!--                                                               [ngModel]="user?.app_secret_clio"-->
<!--                                                               #appSecretClio="ngModel"-->
<!--                                                               maxlength="50">-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </div>-->
                                            <div class="form-actions">
                                                <div class="row">
                                                    <div class="col-md-offset-3 col-md-9">
                                                        <button type="submit" class="btn blue submit"
                                                                value="Submit Payment">
                                                            Connect
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
<!--                                    <div role="tabpanel" class="tab-pane" [class.active]="activeTab == 'files'" id="files">-->
<!--                                        <app-profile-documents [activeTab]="activeTab" [user]="user"></app-profile-documents>-->
<!--                                    </div>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
