<div id="save-view-modal" class="modal fade bs-modal-lg in" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header ">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">{{ share ? 'Share' : 'Save' }} view</h4>
      </div>
      <form #createViewForm="ngForm" id="createViewFormId" (submit)="createView(createViewForm)" role="form-horizontal" class="no-validate"
            *ngIf="!shareId">
        <input name="file_id" id="file_id" type="hidden" value="" />
        <div class="modal-body portlet-body form">
          <div class="form-body">
            <div class="form-group">
              <label>Name</label>
              <input type="text" [ngModel] name="name" class="form-control" placeholder="Please name your view">
              <span class="help-block" *ngIf="errors?.name" style="color: red;">{{ errors?.name[0] }}</span>
            </div>
            <div class="form-group">
              <label>Description</label>
              <textarea [ngModel] name="description" class="form-control autosizeme" rows="3" placeholder="Add a description"></textarea>
            </div>
            <div class="form-group">
              <div class="mt-checkbox-inline">
                <label class="mt-checkbox">
                  <input [ngModel] name="comment" type="checkbox" id="inlineCheckbox1">
                    Make Comments Available
                  <span></span>
                </label>
              </div>
            </div>
            <div class="form-group" *ngIf="share">
              <label>Enter an email address to securely send this view to</label>
              <input [ngModel] type="email" class="form-control" name="email">
            </div>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary">{{ share ? 'Share' : 'Create' }}</button>
        </div>
      </form>
      <form #createViewForm="ngForm" (submit)="sendView(createViewForm)" role="form-horizontal" class="no-validate"
            *ngIf="shareId">
        <div class="modal-body portlet-body forms">
          <div class="form-body">
            <div class="form-group">
              <label>Enter an email address to securely share this View</label>
              <input [ngModel] type="email" class="form-control" name="email" required>
            </div>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary">Share</button>
        </div>
      </form>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>
