<div class="container support page-wrap ">
<div class="row col-md-12 ">
  <div class="col-md-7 rowsize" style="float: none;  display: table-cell;  vertical-align: top;">
  <h3>TECHNICAL SUPPORT</h3>
  <blockquote>
    <p style="margin-top: 0px; font-size: 14px;">Please create a support ticket here and a customer care representative will respond to you just as soon as they can.</p>
  </blockquote>
  <div class="row">
    <div class="col-md-8">
      <form #supportForm="ngForm" (submit)="submitTicket(supportForm)">
        <div class="form-group">
          <label>Email</label>
          <input [ngModel] type="email" name="email" class="form-control" placeholder="Email address">
          <span style='color:red'> {{email_error}}</span>
        </div>

        <div class="form-group">
          <label>Subject</label>
          <input [ngModel] type="text" name="title" class="form-control" placeholder="Subject">
          <span style='color:red'> {{subject_error}}</span>
        </div>
        <div class="form-group" *ngIf="! forbidden">
          <label>TrialLine</label>
          <select [ngModel] name="trialline" class="form-control">
            <option disabled selected>- Select TrialLine -</option>
            <option *ngFor="let trialline of triallines" value="{{ trialline.id }}">{{ trialline.name }}</option>
          </select>
          <span style='color:red'> {{trl_error}}</span>
        </div>
        <div class="form-group">
          <label>Description</label>
          <textarea [ngModel] class="form-control autosizeme" name="description" cols="30" rows="10"
                    placeholder="Your comment..."></textarea>
          <span style='color:red'> {{desc_error}}</span>
        </div>
        <div class="form-actions">
          <button [disabled]="processing" type="submit" class="btn blue submit" value="send">Send</button>
          <button type="button" class="btn default" (click)='clear(supportForm);'>Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="col-md-5" style="left: 20px;  float: none;  display: table-cell;  vertical-align: top;">
  <h3>TUTORIALS:</h3>
  <div class="portlet-body">
      <app-feed-card  [feeds]="feeds" ></app-feed-card>
  </div>
</div>
</div>
</div>
