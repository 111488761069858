import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Title} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {UserProfile} from "../shared/user.model";
import {NgForm} from "@angular/forms";
import {ProfileResourceService} from "../shared/profile-resoruce.service";
import {AuthService} from "../../../auth/shared/auth.service";

@Component({
  selector: 'app-profile-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class ProfileSettingsComponent implements  OnInit
{
  errors: any = {};
  passwordErrors: any = {};
  user: UserProfile;
  
  constructor(
    protected toasts: ToastrService,
    protected title: Title,
    protected route: ActivatedRoute, public router: Router,
    protected profileResourceService: ProfileResourceService,
    protected authService: AuthService
  ) { title.setTitle('Trialline | Profile') }

  ngOnInit() {

    this.route.data.subscribe((data: {user: UserProfile}) => {

      this.user = data.user;
    });
  }

  update(form: NgForm) {
    console.log('check the form',form);
    var re = /\S+@\S+\.\S+/;
    if(!form.value.company){
    this.toasts.error("Company is required");
    return;
    }
    if(!form.value.email){
      this.toasts.error("Email is required");
      return;

    }
    if(!form.value.first_name){
      this.toasts.error("First Name is required");
      return;
    }
    if(!form.value.last_name){
      this.toasts.error("Last Name is required");
      return;
    }
    if(!form.value.phone){
      this.toasts.error("Phone Number is required");
      return;
    }
    if(!re.test(form.value.email)){
      this.toasts.error("Please enter a valid email address");
    }
   else{
    this.profileResourceService.updateProfile(form.value).toPromise()
    .then((user: UserProfile) => {
      this.errors = {};
      this.toasts.success('Profile successfully updated');
      this.authService.emit();
    })
    .catch((error) => {

      if (error.status == 422) {
        let errorData = error.error;
        this.errors = errorData.data.message;
        
        this.toasts.error(this.errors);
      } else {
        this.toasts.error(error);
      }
    });
   }
  }

  // onSecureAccountChange() {
  //   console.log('Secure Account with OTP:', this.isSecureAccountEnabled);
  //   localStorage.setItem('secureAccountEnabled', this.isSecureAccountEnabled ? 'true' : 'false');
  // }

  updatePassword(form: NgForm) {
    
    this.profileResourceService.updatePassword(form.value).toPromise()
      .then((user: UserProfile) => {

        this.passwordErrors = {};
        form.reset();
        this.toasts.success('Password successfully updated');
      })
      .catch((error) => {

        if (error.status == 422) {
          console.log(error);
          if(error.error.new_pass){
            this.toasts.error(...error.error.new_pass);
          }
          if(error.error.password){
            this.toasts.error(...error.error.password);
          }
          let errorData = error.error;
          this.passwordErrors = errorData.data;
          
        } else if (error.status == 422) { 
          this.toasts.error(error.statusText);
        }
        else {

          this.toasts.error(error);
        }
      });
  }
}