import {Component} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Router} from "@angular/router";
import {AuthResourceService} from "../../../auth/shared/auth-resoruce.service";
import {User} from "../../../auth/shared/user.model";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {HttpClient} from '@angular/common/http';
import {env} from "../../../../../.env";
import * as $ from 'jquery';

declare var jQuery: any;

@Component({
  selector: 'app-popup-form',
  templateUrl: './popup-form.component.html',
  styleUrls: [
    './popup-form.component.css'
  ]
})
export class PopupFormComponent {
  errors: any = {};
  processing: boolean = false;
  user: any;

  captcha: boolean = false;

  popupForm: UntypedFormGroup;

  constructor(protected authResourceService: AuthResourceService,
              private fb: UntypedFormBuilder,
              protected router: Router,
              protected toasts: ToastrService,
              private http: HttpClient) {

    this.popupForm = this.fb.group({
      popup_first_name: [null, Validators.required],
      popup_email: [null, Validators.required],
      popup_phone: [null, Validators.required],
      popup_message: [null, Validators.required]
    });
  }

  resolved(captchaResponse: string) {

    this.captcha = true;
  }

  submit() {
    this.errors = {};
    if (!this.captcha) {

      return this.toasts.info('reCAPTCHA required')
    }

    this.http.post(env.apiUrl + 'contact-form',  this.popupForm.value).subscribe(
      (response: any) => {

        this.captcha = false;
        this.toasts.success('Successfully submitted');
        jQuery('#popap_form').modal('hide');
      },
      (error: any) => {
        this.toasts.error('Something went wrong');
      }
    );
  }
}
