<div class="login" style="display: grid; min-height: 100%;">
  <div class="logo">
    <a [routerLink]="['/']">
      <img src="assets/images/logo.png" style="width: 250px !important;" alt="">
    </a>
  </div>

  <router-outlet></router-outlet>

</div>
