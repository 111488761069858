import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {View} from "../../main/shared/view.model";
import {PresentResourceService} from "./present-resoruce.service";

@Injectable()
export class PresentResolver implements Resolve<View> {
  constructor(protected presentResource: PresentResourceService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<View> {
    return this.presentResource.getView(route.params['uuid'], route.queryParams['pass'], route.queryParams['token'])
      .toPromise()
      .then((view: View) => {
        return view;
      })
      // .catch((error) => {
      //   this.router.navigateByUrl(error.status == 403 ? '/403' : '/404')
      // });
  }
}
