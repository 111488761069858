<div class="form-group">
  <label  class="col-sm-3 control-label">Create a new contact category</label>
  <div class="col-sm-9">
     <input type="text" [(ngModel)]="category.name" class="form-control category-edit-name col-sm-12 col-xs-5"  (keyup)="onChangeNewCategory(category.name)"
             placeholder="Create a new contact category">
    <input  #background [(ngModel)]="category.background" class="form-control" [(colorPicker)]="color" [cpPosition]="'left'" [cpPositionRelativeToArrow]="true"
           [style.background]="color" [value]="color" (colorPickerChange)="onChangeBackground(background.value)">
  </div>
  <div class="col-sm-12" style="display: flex; justify-content: flex-end;">
    <div class="input-group date col-md-2" style="margin-top: 10px;">
      <a (click)="create()" class="btn blue btn-outline btn-w115 {{disable ? 'disable' : ''}} ">Save Category</a>
    </div>
  <img src="assets/squares.gif" class="img spinners" alt="spinner" width="30px" *ngIf="processing">
  </div>
</div>
