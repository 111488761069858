import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Title} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {FileModel} from "./shared/file.model";
import {FileLibraryResourceService} from "./shared/file-library-resoruce.service";

declare var jQuery: any;
declare var _: any;

@Component({
  selector: 'app-file_library',
  templateUrl: './file-library.component.html'
})
export class FileLibraryComponent implements OnInit {
  files: FileModel[];
  uploadedFiles: FileModel[] = [];
  listView: boolean = true;

  fileModalId: string = '#file-edit-modal';
  @ViewChild('sharedFiles', { static: true }) sharedFiles;
  file: FileModel;
  fullpath: string;


  constructor(
    protected toasts: ToastrService,
    protected title: Title,
    protected route: ActivatedRoute,
    public router: Router,
    protected ref: ChangeDetectorRef,
    protected resourceService: FileLibraryResourceService
  ) {
    title.setTitle('Trialline | File library')
  }

  ngOnInit() {
    this.route.data.subscribe((data: { files: FileModel[] }) => {
      this.files = data.files;
    });
  }

  ngOnDestroy() {
    this.ref.detach();
  }


  fileDetails(file: FileModel) {
    jQuery(this.fileModalId).modal('show');
    this.file = file;
  }

  addFile(file: FileModel) {
    this.files.push(file);
    console.log(this.files);
    this.sharedFiles.onFileSubmit(file);
    this.ref.detectChanges();
  }

  removeFile(file: FileModel) {
    this.resourceService.deleteFile(file).toPromise()
      .then((response: any) => {
        this.toasts.success('Successfully deleted');
        let index = this.files.indexOf(file);
        this.files.splice(index, 1);
        this.ref.detectChanges();
      })
      .catch((error) => {
        this.toasts.error(error);
      });
  }

  cloudFile(file: FileModel) {
    this.resourceService.cloudFile(file).toPromise()
      .then((status: string) => {
        this.toasts.success('Successfully ' + status);
        let index = this.files.indexOf(file);
        this.files[index].users = [];
        this.files.splice(index, 1);
        this.ref.detectChanges();
      })
      .catch((error) => {

        this.toasts.error(error);
      });
  }

  updateFile(file: FileModel)
  {
    let index = this.files.indexOf(this.files.find(e => e.id == file.id));

    _.extend(this.files[index], file);
  }

}
