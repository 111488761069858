import {Trialline} from "./trialline.model";
import {UserProfile} from "../profile/shared/user.model";
import {Event} from "./event.model";

export class ContactsCategory
{
  id: number;
  name: string;
  slug: string;
  trialline_id: number;
  user_id: number;
  background: string;
  thumbnail: string;
  default: boolean;
  pivot: any;
  created_at?: string;
  updated_at?: string;

  state?: boolean = true;
  status?: boolean = true;

  contacts_count?: number;
  thumbnail_encryptid?: any;

  trialline?: Trialline;
  user?: UserProfile;

}
