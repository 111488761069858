import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ToastrService} from 'ngx-toastr';


import {ContactsCategory} from "../../../../../../shared/contactsCategory.model";
import {TriallineResourceService} from "../../../../../shared/trialline-resoruce.service";

declare var jQuery:any;

@Component({
  selector: 'app-trialline-event-contact-modal-categories',
  templateUrl: './categories.component.html'
})
export class TrillionEventContactsModalCategoriesComponent
{
  @Input() triallineId: number;
  @Input() color: string;
  @Output() onCreated = new EventEmitter<ContactsCategory>();
  @Output() onBackground = new EventEmitter<ContactsCategory>();


  errors: any = {};
  category: ContactsCategory = new ContactsCategory();
 // color: string = '#'+ Math.floor(Math.random()*16777215).toString(16);
  processing: boolean = false;
  disable: boolean = true;

  constructor(
    protected toasts: ToastrService,
    private triallineResoureService: TriallineResourceService
  ) {
  }

  ngOnInit(){
    if(this.color === null || this.color.length < 7 ){

      this.color = '#'+ Math.floor(Math.random()*16777215).toString(16);
    }
  }
  create() {

    this.processing = true;
    this.category.background = this.color;
    this.category['trialline_id'] = this.triallineId ;
    this.triallineResoureService.createContactCategory(this.triallineId, this.category).toPromise()
      .then((category: any) => {
        this.onCreated.emit(category);
        this.category = new ContactsCategory();
        this.errors = {};
        this.processing = false;
        this.toasts.success('Successfully created new category')
        this.disable = true;
      })
      .catch((error) => {
        if (error.status == 422) {
          let errorData: any = error.error;
          console.log(errorData);
          this.errors = errorData.data;
        } else {
          this.toasts.error(error);
        }

        this.processing = false;
      });

  }

  onChangeNewCategory(value) {
    if(value.length > 1){
      this.disable = false;
    }else{
      this.disable = true;
    }
  }

  onChangeBackground(value) {
    // console.log('value');
    // console.log(value);
    // console.log(this.category);
    // console.log(this.color);

  }
}
