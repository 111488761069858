import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

import {ToastrService} from 'ngx-toastr';
import {AuthResourceService} from "../../shared/auth-resoruce.service";

@Component({
  selector: 'app-auth-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']

})
export class ResetComponent
{
  errors: any = {};
  processing: boolean = false;
  user: any = {};

  constructor(
    protected authResourceService: AuthResourceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {

    if (! activatedRoute.queryParams['value']['token']) {

      toastr.error('This password reset token is invalid.');
      router.navigateByUrl('/reset/password');
    }
  }

  reset(form: any)
  {
    this.processing = true;
    let params = form.value;
    params.token =  this.activatedRoute.queryParams['value']['token'];
    this.authResourceService.resetPassword(params).toPromise().then(
      (response: any) =>  this.handleSuccess(response.body),
      (error: any)    =>  this.handleError(error)
    );
  }

  handleSuccess(response: Response)
  {
    this.processing = false;
    this.errors = {};
    this.toastr.success('Your password has been reset!');
    this.router.navigateByUrl('/login');
  }

  handleError(error: any)
  {
    this.processing = false;
    if (error.status == 422) {
      let errorData: any = error.error;
      this.errors = errorData.data;

      if (error.error) {
        if (error.error.email) {
          this.toastr.error(error.error.email[0]);
        }
        if (error.error.password) {
          this.toastr.error(error.error.password[0]);
        }
      }

      if (error.error.token) {
        this.toastr.error(error.error.token[0]);
        this.router.navigateByUrl('/reset/password')
      }
    }
  }
}
