<div class="tiles upload-videos">
  <ul>
    <li class="tile image" *ngFor="let video of videos" (click)="selectFile(video)">
      <div class="tile-body">
        <div class="upload-file-img"
             [style.background-image]="getThumbnail(video)">
          <i class="fa fa-play-circle youtube_icon"></i>
        </div>
        <div *ngIf="removeFunc" (click)="remove(video); $event.preventDefault(); $event.stopPropagation();" class="remove-video">&times;</div>
        <div *ngIf="video.title" class="tile-object"><div class="name">{{video.title}}</div></div>
      </div>
    </li>
  </ul>
  <div class="clearfix"></div>
</div>
<app-shared-youtube-previewer [video]="video"></app-shared-youtube-previewer>
<span defaultOverlayTarget></span>
