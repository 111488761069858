import {NgModule} from '@angular/core';
import {HttpClientModule} from "@angular/common/http";
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {MainComponent} from "./main/main.component";
import {StarterComponent} from "./starter/starter.component";
import {AuthComponent} from "./auth/auth.component";
//import { SmoothScrollToDirective, SmoothScrollDirective } from "ng2-smooth-scroll";
import { AddCardComponent } from "./main/add-card/add-cart.component";
import {SubscriptionComponent } from "./main/subscription/subscription.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
  ],
  declarations: [
    MainComponent,
    StarterComponent,
    AuthComponent,
    AddCardComponent,
    SubscriptionComponent
  ],
  providers: [
  ]
})
export class LayoutsModule {}
