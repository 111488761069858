import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Title} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";
import {Event} from "../../shared/event.model";
import {Modal} from 'ngx-modialog-7/plugins/bootstrap';
import {Trialline} from "../../shared/trialline.model";
import {EventsResourceService} from "../events/shared/events-resoruce.service";
import {TriallineComponent} from "../../../shared-components/trialline/trialline.component";
import {env} from "../../../../../.env";
import {TriallineResourceService} from "../shared/trialline-resoruce.service";
import {AuthService} from "../../../auth/shared/auth.service";
import {TriallineEventsComponent} from "../events/events.component";
import { CookieService } from 'ngx-cookie';
import {TriallineMobileComponent} from "../../../shared-components/trialline-mobile/trialline-mobile.component";
import {SharedService} from 'app/shared/shared.service';
import {TriallinePyramidComponent} from "../../../shared-components/trialline-pyramid/trialline-pyramid.component";
import * as $ from "jquery";
import {ContactsCategory} from "../../shared/contactsCategory.model";
import {Contacts} from "../../shared/contacts.model";
import {TriallineTrackComponent} from "../../../shared-components/trialline-track/trialline-track.component";
import {PresentResourceService} from "../../../present/shared/present-resoruce.service";
import { NgxCaptureService } from 'ngx-capture';

declare var jQuery: any;
declare var _: any;

@Component({
  selector: 'app-trialline-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss']
})
export class TriallineManageComponent implements OnInit, OnDestroy { 

  isvalid: boolean;
  showallcategoriesandtag: boolean = false;
  // my changes
  checkedList: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
  allchecked: boolean = true;
  headers: any;
  urltext: any;
  filterParallel: boolean = false;
  events: Event[] = [];
  eventsTracks: Event[] = [];
  copyEventData: Event;
  eventsIdsOrder: number[] = [];
  eventsIdsOrderTracks: number[] = [];
  event: Event;
  screenView: any;
  trialline: Trialline;
  triallineId: number;
  editModalId = '#event-edit-modal';
  moreModalId = '#event-more-modal';
  saveViewModalId = '#save-view-modal';
  apiUrl = env.apiUrl;
  disabled: boolean = false;
  simpleView: number = 0;
  showAll = true;
  viewSocialImage: any;
  
  @ViewChild(TriallineComponent, { static: false }) tl: TriallineComponent;
  @ViewChild(TriallinePyramidComponent, { static: false }) trlPyr: TriallinePyramidComponent;
  @ViewChild(TriallineMobileComponent, { static: false }) tlMobile: TriallineMobileComponent;
  @ViewChild(TriallineTrackComponent, { static: false }) tlTrack: TriallineTrackComponent;
  @ViewChild('screen', { static: true }) screen: any;

  sub: any;
  processing: boolean = false;
  editModal: boolean = false;
  copyEvent: boolean = false;
  moreModal: boolean = false;
  categories: boolean = false;
  contactsSelect: boolean = false;
  contactCategories: boolean = false;
  contactsShow: boolean = false;
  rangeFilterFrom: string = '';
  rangeFilterTo: string = '';
  rangeFilterFromModel: string = '';
  rangeFilterToModel: string = '';
  rangeFilterShow: boolean = false;
  rangeFilterApply: boolean = false;
  tags: boolean = false;
  contactsCategory: ContactsCategory[] = [];
  contacts: Contacts[];
  saveViewModal: boolean = false;
  shareView: boolean = false;
  selectedCategories: any;
  selectedContactsCategory: any;
  selectedContacts: any;
  selectedContactsId: any;
  IsExportHidden = true;
  activeTab = 'description';
  x = 1324;
  displayDateFormat: any;
  tracks = [
    {
      name: 'Track 1',
      id: 'track1',
      filterCategory: '',
      filterValue: ''
    },
    {
      name: 'Track 2',
      id: 'track2',
      filterCategory: '',
      filterValue: ''
    },
    {
      name: 'Track 3',
      id: 'track3',
      filterCategory: '',
      filterValue: ''
    }
  ]
  tracksCategory = [
    {
      name: 'Categories',
      id: 'category_id'
    },
    {
      name: 'Contact Categories',
      id: 'contacts_category_id'
    },
    {
      name: 'Tags',
      id: 'tag_id'
    },
    {
      name: 'Contact Names',
      id: 'contacts_id'
    },
  ]
  cookieName = 'manage';
  categoriesid: any[];
  contactsCategoryid = [];
  contactsid = [];
  allidsrow = [];
  tagsdata = [];
  contactCategoriesData = [];
  tagsid = [];
  categoriesdata: any;
  statusdispaly: boolean;
  tagscount: any;
  alldata = [];
  storeevnets: Event[];
  hiddentag: boolean = false;
  rememberallcheckedtags: boolean;
  orientation: string | number;
  criteriaFilterTooltip = 'The ‘Criteria Filter’ works differently than the traditional filter option. Checking this box will only display events that match each of the specific criteria you select from the filter menu. For example, if you select one category, one tag, and one contact, with Criteria Filter checked you will only see events that share all three of those criteria exactly. To learn more about the Criteria Filter, click here: https://blog.trialline.net/the-criteria-filter/';

  constructor(
    private route: ActivatedRoute,
    private eventsResourceService: EventsResourceService,
    private triallineResourceService: TriallineResourceService,
    private title: Title,
    private _changeDetectionRef: ChangeDetectorRef,
    private toasts: ToastrService,
    public modal: Modal,
    public auth: AuthService,
    public cookieService: CookieService,
    protected sharedService: SharedService,
    private presentResourceService: PresentResourceService,
    private captureService:NgxCaptureService
  ) {
    title.setTitle('Trialline')
  }

  calendarDateFormatSelection(dateFormat){
    
    switch(dateFormat) {
      case 'YMD':
        return {
          'halfDateFormat' : 'yyyy/mm',
          'fullDateFormat' : 'yyyy/mm/dd',
        };
      break;
      case 'DMY':
        return {
          'halfDateFormat' : 'mm/yyyy',
          'fullDateFormat' : 'dd/mm/yyyy',
        };
      break;
      case 'MDY':
        return {
          'halfDateFormat' : 'mm/yyyy',
          'fullDateFormat' : 'mm/dd/yyyy',
        };
      break;
      default:
        return {
          'halfDateFormat' : 'mm/yyyy',
          'fullDateFormat' : 'mm/dd/yyyy',
        };
      break;
    }

  }

  changedView(id?: number) {
    this.disabled = true;
    
    if(id !== null && id !== undefined){
      this.simpleView = id;
      //console.log('this.simpleView:',this.simpleView);
      //console.log('this.trialline:',this.trialline);
      if (this.simpleView == 3) {
        
        if (this.trialline.track1 == null && this.trialline.track2 == null && this.trialline.track3 == null) {
          jQuery("#assignModal").modal('show');
        }
       //this.deselect();
       this.loadTrackEvents();
      }else{
        //this.deselect();
        this.loadTrackEvents();
        // if (this.trialline.track1 == null && this.trialline.track2 == null && this.trialline.track3 == null) {
        //   jQuery("#assignModal").modal('show');
        // }
      }
    }
    setTimeout(() => {
      this.disabled = false;
    }, 2000);
  }

  screenshot() {
    this.tlMobile.print();
  }

  window() {
    return window;
  }

  changedCategories() {
    this.sharedService.filterarray.push({'triallineId': this.trialline.id, 'categoryid': this.getSelectedCategories()});
    this.categoriesid = this.getSelectedCategories().join()
    this.showallcategoriesandtag = false;
    this.rememberallcheckedtags = false;
    localStorage.setItem('triallineIdCategories_' + this.triallineId, JSON.stringify(this.categoriesid));
    if (this.simpleView != 3) {
      this.loadEvents(this.getSelectedCategories().join())
    }
    this.isShowShowAll();
  }

  changedCategoriesSecond() {
    this.sharedService.filterarray.push({'triallineId': this.trialline.id, 'categoryid': this.getSelectedCategories()});
    this.categoriesid = this.getSelectedCategories().join()
    this.showallcategoriesandtag = false;
    this.rememberallcheckedtags = false;
    localStorage.setItem('triallineIdCategories_' + this.triallineId, JSON.stringify(this.categoriesid));
  }

  assignTrack() {
    this.processing = true;
    this.eventsResourceService.updateTracks(this.triallineId, this.tracks)
      .toPromise()
      .then((trialline) => {
        this.trialline.track1 = trialline.track1;
        this.trialline.track2 = trialline.track2;
        this.trialline.track3 = trialline.track3;
        if (this.trialline.track1 || this.trialline.track2 || this.trialline.track3) {
          this.loadTrackEvents();
        } else {
          this.eventsTracks = [];
          this.processing = false;
        }
      }).catch((e: any) => {
        console.log(e);
        this.processing = false;
      });
    jQuery("#assignModal").modal('hide');
    this._changeDetectionRef.detectChanges();
  }

  changeTracksCategories(value, trackId) {
    this.tracks.map(item => {
      if (item.id == trackId) {
        item.filterCategory = value;
      }
      return item;
    })
  }

  changeTracksCategoriesValue(value, trackId) {
    this.tracks.map(item => {
      if (item.id == trackId) {
        item.filterValue = value;
      }
      return item;
    })
  }
  getUniqueCategoryNames() {
    const uniqueNames = new Set<string>();
    const uniqueCategories: ContactsCategory[] = [];
    this.contactsCategory.forEach((category) => {
      if (!uniqueNames.has(category.name)) {
        uniqueNames.add(category.name);
        uniqueCategories.push(category);
      }
    });
    return uniqueCategories;
  }

  changedCContacts() {
    this.sharedService.filterarray
      .push({'triallineId': this.trialline.id, 'contactsCategotyid': this.getSelectedContactsCategory()});
    this.contactsCategoryid = this.getSelectedContactsCategory().join();
    localStorage.setItem('triallineIdContactsCategory_' + this.triallineId, JSON.stringify(this.contactsCategoryid));
    //console.log('this.simpleView category contacts:',this.simpleView)
    if (this.simpleView != 3) {
      this.loadEvents(this.getSelectedContactsCategory().join())
    }
    this.isShowShowAll();
  }

  changedCContactsSecond() {
    this.sharedService.filterarray
      .push({'triallineId': this.trialline.id, 'contactsCategotyid': this.getSelectedContactsCategory()});
    this.contactsCategoryid = this.getSelectedContactsCategory().join();
    localStorage.setItem('triallineIdContactsCategory_' + this.triallineId, JSON.stringify(this.contactsCategoryid));
  }

  changedContacts() {
    this.sharedService.filterarray.push({'triallineId': this.trialline.id, 'contactsid': this.getSelectedContacts()});
    this.contactsid = this.getSelectedContacts().join();
    localStorage.setItem('triallineIdContacts_' + this.triallineId, JSON.stringify(this.contactsid));
    
    if (this.simpleView != 3) {
      this.loadEvents(this.getSelectedContacts().join())
    }
    this.isShowShowAll();
  }

  rangeChange(e){
    if (e.target.value && e.target.value.length < 10){
      this.toasts.error('Range date must be in MM/DD/YYYY format');
    }

  }

  changeRange(form) {
    let data: any = form.value;
    //console.log("dates data", data)
    this.rangeFilterToModel =  data.end_date;
    this.rangeFilterFromModel =  data.start_date;
    this.rangeFilterTo = null;
    this.rangeFilterFrom = null;
    
    if (!this.rangeFilterFromModel || !this.rangeFilterToModel){
      this.toasts.error('Please select the start and end date');
      $( 'input[type="checkbox"]' ).prop('checked', false);
    }
    else if (Date.parse(this.rangeFilterFromModel) > Date.parse(this.rangeFilterToModel)) {
      this.toasts.error('End Date should be greater than Start Date!');
      $( 'input[type="checkbox"]' ).prop('checked', false);
    }
    else {
      if (data.range_apply) {
        this.rangeFilterTo = data.end_date ? this.parseNewDate(data.end_date) : null;
        this.rangeFilterFrom = data.start_date ? this.parseNewDate(data.start_date) : null;
      }

      
      if (this.simpleView != 3) {
        this.loadEvents(this.getSelectedCategories().join())
      }
  }
  }

  parseDate(date: Date) {
    let month = date.getMonth() + 1;
    return date.getFullYear() + '/' + month + '/' + date.getDate();
  }
  parseNewDate(date) {
    var strArr = date.split('/');
    return strArr[2] + '/' + strArr[0] + '/' + strArr[1];
  }

  changedContactsSecond() {
    this.sharedService.filterarray.push({'triallineId': this.trialline.id, 'contactsid': this.getSelectedContacts()});
    this.contactsid = this.getSelectedContacts().join();
    localStorage.setItem('triallineIdContacts_' + this.triallineId, JSON.stringify(this.contactsid));
  }

  getSelectedCategories() {
    if (!this.trialline) {
      return [];
    }
    this.selectedCategories = this.cookieService.get(this.cookieName + this.trialline.id)
      ? this.cookieService.get(this.cookieName + this.trialline.id).split(',')
      : [];
    return _.pluck(this.trialline.categories.filter((obj) => {return obj.state}), 'id')
  }

  getSelectedContactsCategory() {
    if (!this.trialline) {
      return [];
    }
    this.selectedContactsCategory = this.cookieService.get(this.cookieName + this.trialline.id)
      ? this.cookieService.get(this.cookieName + this.trialline.id).split(',')
      : [];
    return _.pluck(this.contactsCategory.filter((obj) => {return obj.status}), 'id')
  }

  getSelectedContacts() {
    if (!this.trialline) {
      return [];
    }
    this.selectedContacts = this.cookieService.get(this.cookieName + this.trialline.id)
      ? this.cookieService.get(this.cookieName + this.trialline.id).split(',')
      : [];
    return _.pluck(this.trialline.contacts.filter((obj) => {return obj.status}), 'id')
  }

  getSelectedTags(){
    return Array.from(new Set(this.sharedService.tagsid))
  }

  all(ev) {
    this.trialline.categories.forEach(x => x.state = ev.target.checked)
    this.changedCategories();
    this.isShowShowAll();
  }

  switchFilter() {
    this.filterParallel = !this.filterParallel;
    if (this.filterParallel) {
      this.trialline.categories.forEach(x => x.state = false)
      this.contactsCategory.forEach(x => x.status = false)
      this.trialline.contacts.forEach(x => x.status = false)
      this.tagsdata.forEach(x => x.status = false)
      this.tagsid = []
      this.sharedService.tagsid = this.tagsid
      localStorage.setItem('triallineIdTags_' + this.triallineId, JSON.stringify(this.tagsid));
      this.changedCContactsSecond();
      this.changedContactsSecond();
    }
    this.changedCategories();
  }

  deselect() {
    this.trialline.categories.forEach(x => x.state = false)
    this.contactsCategory.forEach(x => x.status = false)
    this.trialline.contacts.forEach(x => x.status = false)
    this.tagsdata.forEach(x => x.status = false)
    this.tagsid = []
    this.sharedService.tagsid = this.tagsid
    localStorage.setItem('triallineIdTags_' + this.triallineId, JSON.stringify(this.tagsid));
    this.changedCContactsSecond();
    this.changedContactsSecond();
    this.changedCategories();
  }

  selectAllFilters() {
    this.trialline.categories.forEach(x => x.state = true)
    this.contactsCategory.forEach(x => x.status = true)
    this.trialline.contacts.forEach(x => x.status = true)
    this.tagsdata.forEach(x => x.status = true)
    for (let i = 0; i < this.tagsdata.length; i++) {
      if (this.tagsdata[i].status) {
        this.tagsid.push(this.tagsdata[i].id)
      } else {
        this.tagsid = []
      }
    }
    this.sharedService.tagsid = this.tagsid
    this.changedCContactsSecond();
    this.changedContactsSecond();
    this.changedCategories();
  }

  allContactCategoty(ev) {
    this.contactsCategory.forEach(x => x.status = ev.target.checked)
    this.changedCContacts();
    this.isShowShowAll();
  }

  allContact(ev) {
    this.trialline.contacts.forEach(x => x.status = ev.target.checked)
    this.changedContacts();
    this.isShowShowAll();
  }

  color(id) {
    if (id == 'catcolor') {
      $("#catcolor").css("background", "#ded4d4");
      $("#tagcolor").css("background", "white");
      $("#contactcolor").css("background", "white");
      $("#contactCategorycolor").css("background", "white");
    } else if (id == 'tagcolor') {
      $("#catcolor").css("background", "white");
      $("#contactcolor").css("background", "white");
      $("#tagcolor").css("background", "#ded4d4");
      $("#contactCategorycolor").css("background", "white");
    } else if (id == 'contactcolor') {
      $("#catcolor").css("background", "white");
      $("#tagcolor").css("background", "white");
      $("#contactcolor").css("background", "#ded4d4");
      $("#contactCategorycolor").css("background", "white");
    } else {
      $("#catcolor").css("background", "white");
      $("#tagcolor").css("background", "white");
      $("#contactCategorycolor").css("background", "#ded4d4");
      $("#contactcolor").css("background", "white");
    }
  }

  isAllCheckedForAsterisk() {
    this.sharedService.tagfilterid = 1;
    
    let queryRun : any;
    queryRun = this.trialline.categories.filter(c => c && c.events_count > 0).find(c => c && c.state);
    
    if(!queryRun) {
      return 1;
    } else { 
      
      if(this.trialline.categories.filter(c => c && c.events_count > 0).every(c => c && c.state)) {
        return 0;
      }      
      return this.trialline.categories.filter(c => c && c.events_count > 0).find(c => c && c.state);

    }
  }

  isAllChecked() {
    this.sharedService.tagfilterid = 1;
    return this.trialline
      .categories
      .filter(c => c && c.events_count > 0)
      .every(c => c && c.state);
  }

  isAllContactsCategoryCheckedForAsterisk() {
    if (this.trialline !== undefined) {
      let queryRun : any;
      queryRun = this.contactsCategory.find(c => c && c.status);
      
      if(!queryRun) {
        return 1;
      } else {
        if(this.contactsCategory.every(c => c && typeof c.status !== 'undefined' && c.status === true)) {
          return 0;
        }
        if(this.contactsCategory.find(c => c && typeof c.status !== 'undefined' && c.status === true)) {
          return 1;
        } else {
          return 0;
        }
      }
    }
    return  0;
  }

  isAllContactsCategoryChecked() {
    if (this.trialline !== undefined) {
      if (this.contactsCategory == undefined) return true;
      let count = this.contactsCategory.length;
      if (count === 0) {
        return count;
      }
      return count == this.getSelectedContactsCategory().length
    }
    return  true;    
  }

  isAllContactsCheckedForAsterisk() {
   
    if(this.trialline.contacts.length <= 0 ) {
      return 0;
    }

    let count = 0;
    for (let i = 0; i < this.trialline.contacts.length; i++) {
      if (this.trialline.contacts[i].status == true) {
        count++;
      }
    }

    if(this.trialline.contacts.length == count) {
      return 0;
    }

    return 1;

  }

  isAllContactsChecked() {
    if(this.trialline !== undefined){
      let count = this.trialline.contacts.length
      if (count === 0) {
        return count;
      }
      return count == this.getSelectedContacts().length
    }
    return  true;
  }

  toggleCategories() {
    this.categories = !this.categories;
    this.tags = false;
    this.contactCategories = false;
    this.contactsShow = false;
    this.contactsSelect = false;
  }

  toggletag() {
    this.tags = !this.tags;
    this.categories = false;
    this.contactCategories = false;
    this.contactsShow = false;
    this.contactsSelect = false;
  }

  toggleContactsCategory() {
    this.tags = false;
    this.categories = false;
    this.contactsShow = false;
    this.contactsSelect = true;
    this.contactCategories = !this.contactCategories;
  }

  toggleContacts() {
    this.tags = false;
    this.categories = false;
    this.contactCategories = false;
    this.contactsSelect = true;
    this.contactsShow = !this.contactsShow;
  }

  toggleContactsSelects() {
    this.tags = false;
    this.categories = false;
    this.contactCategories = false;
    this.contactsShow = false;
    this.contactsSelect = !this.contactsSelect;
  }

  togglediv(idElement = "#allMenu") {
    this.closeAssign();
    this.statusdispaly = true;
    $(idElement).show();
    if (idElement === "#allMenu") {
      $('#allMenuContact').hide();
      this.contactCategories = false;
      this.contactsShow = false;
    } else {
      $('#allMenu').hide();
      this.tags = false;
      this.categories = false;
    }
  }

  showAssign(idElement = "#assign") {
    this.closeMenu();
    this.statusdispaly = true;
    $(idElement).show();
  }

  closeAssign(idElement = "#assign") {
    this.statusdispaly = false;
    $(idElement).hide();
  }

  ngOnInit() {
    // this.disableApplyDateRange = true;
    // if(data.end_date != '' && data.start_date != '') {
               
    //   this.isDisabled = false;
    // } else {
    //   this.isDisabled = true;
    // }
    this.headers = [
      {id: 1, name: 'Event Date', checked: true},
      {id: 2, name: 'Title', checked: true},
      {id: 3, name: 'Category', checked: true},
      {id: 4, name: 'Provider ', checked: true},
      {id: 5, name: 'Description ', checked: true},
      {id: 6, name: 'Bates Page Number', checked: true},
      {id: 7, name: 'Exhibit Number', checked: true},
      {id: 8, name: 'Notes', checked: true},
      {id: 9, name: 'Comments', checked: true},
      {id: 10, name: 'Attached Files', checked: true},
      {id: 11, name: 'Tags', checked: true},
      {id: 12, name: 'Contacts', checked: true}
    ];

    if (this.window().innerWidth < 756) {
      this.simpleView = 1;
    }

    this.sub = this.route.parent.params.subscribe(params => {
      this.triallineId = +params["trialline_id"];
    });
    this.sharedService.triallineidcheck = this.triallineId;
    this.categoriesid = []
    this.contactsCategoryid = []
    this.route.data.subscribe((data: { trialline: Trialline }) => {
      this.displayDateFormat = this.calendarDateFormatSelection(data.trialline.trialline_date_format);
      localStorage.setItem("date_format_trialline", data.trialline.trialline_date_format);
      localStorage.setItem("for_future_timelines", data.trialline.for_future_timelines);
      this.trialline = data.trialline;
      this.trialline.categories.sort((a, b) => a.name.localeCompare(b.name));
      if (this.sharedService.filterarray.length > 0) {
        if (this.sharedService.filterarray.map(value => value.triallineId).includes(this.trialline.id)) {
          for (let row in this.sharedService.filterarray) {
            if (this.sharedService.filterarray[row].triallineId == this.trialline.id) {
              this.allidsrow.push(this.sharedService.filterarray[row])
            }
          }
          if (this.allidsrow[this.allidsrow.length - 1].categoryid !== undefined) {
            for (let j = 0; j < this.allidsrow[this.allidsrow.length - 1].categoryid.length; j++) {
              this.categoriesid.push(this.allidsrow[this.allidsrow.length - 1].categoryid[j]);
            }
          }
          for (let category in this.trialline.categories) {
            if (this.categoriesid.includes(this.trialline.categories[category].id)) {
              this.trialline.categories[category].state = true;
            }
            this.isAllChecked();
          }
          for (let categoryContact in this.contactsCategory) {
            if (this.contactsCategoryid.includes(this.contactsCategory[categoryContact].id)) {
              this.contactsCategory[categoryContact].status = true;
            }
            this.isAllContactsCategoryChecked();
          }
        } else {
          this.checkedTrialline();
        }
      } else {
        this.checkedTrialline();
      }
      let triallineIdCategories = JSON.parse(localStorage.getItem('triallineIdCategories_' + this.triallineId));
      if (triallineIdCategories != null) {
        let uniqueItems = triallineIdCategories.split(',');
        let categoryNew = [];
        if (uniqueItems.length > 0 ) {
          for (let item in uniqueItems) {
            categoryNew.push(parseInt(uniqueItems[item]));
          }
          this.categoriesid = categoryNew;
          for (let category in this.trialline.categories) {
            this.trialline.categories[category].state =  categoryNew.includes(this.trialline.categories[category].id);
          }
        }
      }
      this.isAllChecked();
      this.checkedContacts();
      this.checkedContactsCategory();
      this.getalltags();
      this.isShowShowAll();
      this.loadEvents(this.categoriesid);
      // this.loadTrackEvents();
      this._changeDetectionRef.detectChanges();
    });
  }

  ngAfterViewInit() {
    if(navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null) {
      //console.log('iPad');
    }
    let self = this
    jQuery(window).resize(function () {
      self.orientation = window.orientation;
      document.documentElement.style.height = `initial`;
      setTimeout(() => {
        document.documentElement.style.height = `100vh`;
        $('body').css({"min-height": "-webkit-fill-available"})
        $('html').css({"height": "-webkit-fill-available"})
        setTimeout(() => {
          // this line prevents the content
          // from hiding behind the address bar
          window.scrollTo(0, 1);
        }, 300);
      }, 300);
    });
    JSON.parse(localStorage.getItem('triallineIdContacts_' + this.triallineId));
  }

  checkedTrialline() {
    for (let category in this.trialline.categories) {
      this.categoriesid.push(this.trialline.categories[category].id);
      this.trialline.categories[category].state = true;
      this.isAllChecked();
    }
    for (let category in this.contactsCategory) {
      this.contactsCategoryid.push(this.contactsCategory[category].id);
      this.contactsCategory[category].status = true;
      this.isAllContactsCategoryChecked();
    }
    for (let contact in this.trialline.contacts) {
      this.contactsid.push(this.trialline.contacts[contact].id);
      this.trialline.contacts[contact].status = true;
      this.isAllContactsChecked();
    }
  }

  exportShow() {
    this.IsExportHidden = !this.IsExportHidden;
  }

  ngOnDestroy() {
    this._changeDetectionRef.detach();
    this.sub.unsubscribe();
  }

  loadEvents(where?) {
    let param = ((typeof where === "object") && !(where instanceof Array)) ? '' : where;
    let categoriesId: any;
    let uniqueItems: any;
    let uniqueContact: any = [];
    let uniqueContactsCategory: any = [];
    if (param instanceof Array) {
      categoriesId = param
    } else {
      categoriesId = param.split(",");
    }
    this.processing = true;
    uniqueItems = Array.from(new Set(this.sharedService.tagsid))
    if (this.sharedService.tagfilterid == 0) {
      uniqueItems = [];
    }

    if (this.trialline.categories.length == categoriesId.length && this.tagscount == uniqueItems.length) {
      uniqueItems = [];
    }
    if (where == '') {
      this.cookieService.put(this.cookieName + this.triallineId, '');
      this.processing = false;
    }
    if (!this.filterParallel) {
      if (this.isAllChecked()){
        categoriesId = 'all';
      }
      if (this.isAlltagChecked() && this.tagsdata.length > 0){
        uniqueItems = 'all';
      }
      if (this.isAllContactsCategoryChecked()){
        uniqueContactsCategory = 'all';
      }else{
        uniqueContactsCategory = this.getSelectedContactsCategory().join();
      }
      if (this.isAllContactsChecked()){
        uniqueContact = 'all';
      }else{
        uniqueContact = this.getSelectedContacts().join();
      }
    } else {
      uniqueContactsCategory = this.getSelectedContactsCategory().join();
      uniqueContact = this.getSelectedContacts().join();
    }
    return this.eventsResourceService
      .getEvents(this.triallineId, 'category,views,views.comments,contacts', categoriesId, uniqueItems,
        uniqueContactsCategory, uniqueContact, this.filterParallel, this.rangeFilterFrom, this.rangeFilterTo).toPromise()
      .then((events: Event[]) => {
        this.loadEventsThen(events)
      }).catch((e: any) => {
        console.log(e);
        this.processing = false;
      });
  }

  addComment(data) {
    if (this.simpleView != 3) {
      this.loadEvents(this.getSelectedCategories().join())
    } else {
      this.loadTrackEvents()
    }
    this.presentResourceService.storeCommentTrialline(this.triallineId, data.viewId, data.id, data.comment).toPromise()
      .then((comment: any) => {
        this.event.comments.push(comment);
        this.loadEvent(data.id, this.moreModalId,
          'category,files,files.contacts,comments,files.contacts.categories,videos,privateFiles,contacts,contacts.categories,contacts.privateFiles');
        this._changeDetectionRef.detectChanges();
      })
      .catch((e: any) => console.log(e))
  }

  loadEventsThen(events) {
    this.cookieService.put(this.cookieName + this.triallineId,
      (_.uniq(_.pluck(events, 'category_id'))).join(), { expires:'0',  path: '/'}
    );
    this.events = [];
    this.events = events;
    this.storeevnets = events;
    this.sharedService.tagcategoriesid = 0;
    this.alldata = [];
    let eventsCount = this.storeevnets.length;
    for (let k = 0; k < eventsCount; k++) {
      if (this.storeevnets[k].tags_count > 0) {
        for (let j = 0; j < this.storeevnets[k].tag_name.length; j++) {
          if (this.tagsid.includes(this.storeevnets[k].tag_name[j].id) == true) {
            if (this.alldata.includes(this.storeevnets[k]) == false) {
              this.alldata.push(this.storeevnets[k])
            }
          }
        }
      } else {
        this.alldata.push(this.storeevnets[k])
      }
    }
    this.events = this.alldata;
    this.sharedService.eventdata = this.events
    this.idsList();
    this.changedView();
    this.onlytagdatawithcategorydataonemore();
    this.processing = false;
    this._changeDetectionRef.detectChanges();
  }

  loadTrackEvents() {
    //console.log('this.tracks', this.tracks);
    this.tracks.map(item => {
      let track = [];
      if (item.id == 'track1' && this.trialline.track1) {
        track = this.trialline.track1.split('=');
        if (track.length > 0) {
          item.filterCategory = track[0];
          item.filterValue = track[1];
        }
      }
      if (item.id == 'track2' && this.trialline.track2) {
        track = this.trialline.track2.split('=');
        if (track.length > 0) {
          item.filterCategory = track[0];
          item.filterValue = track[1];
        }
      }
      if (item.id == 'track3' && this.trialline.track3) {
        track = this.trialline.track3.split('=');
        if (track.length > 0) {
          item.filterCategory = track[0];
          item.filterValue = track[1];
        }
      }
    })
    return this.eventsResourceService
      .getTrackEvents(this.triallineId).toPromise()
      .then((events: Event[]) => {
        this.eventsTracks = events;
        this.idsList();
        this.changedView();
        this.processing = false;
        this.isShowShowAll();
        this._changeDetectionRef.detectChanges();
      }).catch((e: any) => {
        console.log(e);
        this.processing = false;
      });
  }

  idsList() {
    this.eventsIdsOrder = _.pluck(_.sortBy(this.events, function (item) {
      return new Date(item.date_start)
    }), 'id');
    this.eventsIdsOrderTracks = _.pluck(_.sortBy(this.eventsTracks, function (item) {
      return new Date(item.date_start)
    }), 'id');
  }

  commentPanel() {
    $('.collaps-panel').toggleClass('in');
  }

  edit(id: number) {
    this.processing = true;
    this.loadEvent(id, this.editModalId,
      'category,files,files.contacts,files.billcosts,files.contacts.categories,videos,privateFiles,contacts,contacts.categories,contacts.privateFiles,billCostFiles');
  }

  more(info) {
    this.activeTab = info.activeTab;
    this.processing = true;
    this.loadEvent(info.id, this.moreModalId,
      'category,files,files.contacts,files.billcosts,files.contacts.categories,videos,comments,contacts,contacts.categories,contacts.privateFiles,billCostFiles');
  }

  create() {
    this.event = new Event();
    this.editModal = true;
    this._changeDetectionRef.detectChanges();
    jQuery(this.editModalId).modal('show');
    this.sharedService.status = true;
  }

  loadEvent(id: number, modal: string, withParams: string = null) {
    this.eventsResourceService.getEvent(this.triallineId, id, withParams)
      .subscribe(
      (res: any) => {
        let event = res.body.event;
        jQuery(modal).modal('show').addClass('in');
        this.processing = false;
        this.event = event;
        this.event.date_start = TriallineEventsComponent.parseDate(this.event.date_start);
        if (this.event.date_end) {
          this.event.date_end = TriallineEventsComponent.parseDate(this.event.date_end)
        }
        if (modal == this.editModalId) {
          this.editModal = true;
        } else {
          this.moreModal = true;
        }

        this._changeDetectionRef.detectChanges();
        jQuery(modal).modal('show').addClass('in').addClass('show-style');
        if( !jQuery(modal).hasClass( "in" )){
          jQuery(this.editModalId).addClass('in');
        }
      },
      (error: any) => {
        this.editModal = false;
        this.moreModal = false;
        this.processing = false;
        this.toasts.error(error);
      }
    )
  }

  modalClosed(event: Event) {
    if (this.editModal) {
      this.editModal = false;
      this.copyEvent = false;
      jQuery('.modal-backdrop').remove();

    }
  }
  addCopyEvent(e) {
    this.copyEventData = e;
    this.copyEvent = true;
    this.editModal = false;
    this._changeDetectionRef.detectChanges();
    jQuery(this.editModalId).modal('hide');
    jQuery('#event-edit-copy-modal').modal('show');
    jQuery('#general_copy').collapse('show');
    jQuery('#provider-details_copy').collapse('hide');
    jQuery('#add-media_copy').collapse('hide');
    jQuery('#add-tag_copy').collapse('hide');
    jQuery('#notes_copy').collapse('hide');
    localStorage.removeItem('folderpath');
    $("#thumbnail_visible").prop("checked", true);
  }

  moreModalClosed(event: Event) {
    if (this.moreModal) {
      this.moreModal = false;
    }
  }

  updateEvent(event: Event) {
    //console.log(event);
    if (this.simpleView === 3) {
      this.loadTrackEvents();
    } else {
      let index = this.events.indexOf(this.events.find(e => e.id == event.id));
      if (this.events[index].category_id != event.category_id) {
        let indexCat = this.trialline.categories.indexOf(this.trialline.categories
          .find(c => c.id == this.events[index].category_id));
        if( this.trialline.categories[indexCat]){
          this.trialline.categories[indexCat].events_count--;
        }
        let indexCat1 = this.trialline.categories.indexOf(this.trialline.categories.find(c => c.id == event.category_id));
        if(this.trialline.categories[indexCat1]){
          this.trialline.categories[indexCat1].events_count++;
        }
      }
      _.extend(this.events[index], event);
      if(this.simpleView === 0){
        this.tl.renderAndSetItems(this.events, true);
        this.tl.timeline.setSelection(event.id);
      }else if(this.simpleView === 1){
        this.tlMobile.renderAndSetItems(this.events, true);
      }else if(this.simpleView === 2){
        this.trlPyr.renderAndSetItems(this.events, true);
      }
      if (event.tags_count > 0) {
        for (let i = 0; i < event.tag_name.length; i++) {
          this.sharedService.tagsid.push(event.tag_name[i].id)
          this.tagsid.push(event.tag_name[i].id);
        }
      }
    }
    this.ngOnInit();
    this.getalltags();
    this._changeDetectionRef.detectChanges();
  }

  confirmedDeleteEvent(event: Event) {
    this.eventsResourceService.deleteEvent(this.triallineId, event.id).toPromise()
      .then((response: any) => {
        let index = this.events.indexOf(this.events.find(e => e.id == event.id));
        this.events.splice(index, 1);
        this._changeDetectionRef.detectChanges();
        if(this.simpleView === 0){
          this.tl.renderAndSetItems(this.events, true);
        }else if(this.simpleView === 1){
          this.tlMobile.renderAndSetItems(this.events, true);
        }else if(this.simpleView === 2){
          this.trlPyr.renderAndSetItems(this.events, true);
        }
        this.toasts.success('Successfully deleted event');
        let indexCat = this.trialline.categories.indexOf(this.trialline.categories.find(c => c.id == event.category_id));
        this.trialline.categories[indexCat].events_count--;
      })
      .catch((error) => {
        this.toasts.error(error);
      });
  }

  addEvent(event: Event) {
    this.events.push(event);
    this.idsList();
    let indexCat = this.trialline.categories.indexOf(this.trialline.categories.find(c => c.id == event.category_id));
    if (this.trialline.categories[indexCat]) {
      this.trialline.categories[indexCat].events_count++;
    } else {
      event.category.events_count = 1;
      event.category.state = true;
      this.trialline.categories.push(event.category);
      this.trialline.categories.sort((a, b) => a.name.localeCompare(b.name));
    }
    if(this.simpleView === 0){
      this.tl.renderAndSetItems(this.events, true);
    }else if(this.simpleView === 2){
      this.trlPyr.renderAndSetItems(this.events, true);
    }else if(this.simpleView === 1){
      this.tlMobile.renderAndSetItems(this.events, true);
    }
    else if(this.simpleView === 1){
      this.tlMobile.renderAndSetItems(this.events, true);
    }
    if (event.tags_count > 0) {
      for (let i = 0; i < event.tag_name.length; i++) {
        this.sharedService.tagsid.push(event.tag_name[i].id)
        this.tagsid.push(event.tag_name[i].id);
      }
    }
    this.tl.moveToEvent(event.start_date)
    this.getalltags();
  }
  saveView() {
    jQuery("#createViewFormId")[0].reset();
    this.saveViewModal = true;
    this.shareView = false;
    jQuery(this.saveViewModalId).modal('show')
    const dimensions = this.screen.nativeElement;
    
    this.captureService.getImage(this.screen.nativeElement, true, {
        width: dimensions.width,
        height: dimensions.height,
        useCORS: true,
      }).then(img=>{
        
      this.screenView = this.DataURIToBlob(img)
      
      const formData = new FormData()
      var milliseconds = new Date().getTime();
      let finalFilname = milliseconds+"view-image.png"
      formData.append('file', this.screenView,finalFilname);
      this.uploadThumbnail(formData)
    }) 
  }

  uploadThumbnail(formData) {
    var self = this;
    jQuery.ajax(env.apiUrl + 'file', {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + this.auth.token()},
      data: formData,
      processData: false,
      contentType: false,
      success(response) {
        
        jQuery("#file_id").val(response.body.file.id);
      },
      error() {
        self.toasts.error('Something went wrong', 'Sorry');
      },
    });
  }

  sharingView() {
    jQuery("#createViewFormId")[0].reset();
    this.saveViewModal = true;
    this.shareView = true;
    jQuery(this.saveViewModalId).modal('show')
    //console.log("case2",this.screen);
    // this.captureService.getImage(this.screen.nativeElement, true).then(img=>{
    //   this.screenView = this.DataURIToBlob(img)
    // })
  }

  DataURIToBlob(dataURI: string) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: 'image/png'});
}
  // DataURIToBlob(dataURI: string) {
  //   const splitDataURI = dataURI.split(',')
  //   const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
  //   const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

  //   const ia = new Uint8Array(byteString.length)
  //   for (let i = 0; i < byteString.length; i++)
  //     ia[i] = byteString.charCodeAt(i)

  //   return new Blob([ia], { type: mimeString })
  // }

  selectEventOnTimeline(id) {
    if(this.simpleView === 0) {
      this.tl.select(id);
    }
  }

  // My Changes

  Applyexportfilter(id) {
    jQuery("#exportfiltermodal").modal('show');
    this.urltext = id
    this.allchecked = true;
    this.headers = [
      {id: 1, name: 'Event Date', checked: true},
      {id: 2, name: 'Title', checked: true},
      {id: 3, name: 'Category', checked: true},
      {id: 4, name: 'Provider ', checked: true},
      {id: 5, name: 'Description ', checked: true},
      {id: 6, name: 'Bates Page Number', checked: true},
      {id: 7, name: 'Exhibit Number', checked: true},
      {id: 8, name: 'Notes', checked: true},
      {id: 9, name: 'Comments', checked: true},
      {id: 10, name: 'Attached Files', checked: true},
      {id: 11, name: 'Tags', checked: true},
      {id: 12, name: 'Contacts', checked: true},
    ];
    this.checkedList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

  }

  assignShow() {
    jQuery("#assignModal").modal('show');
  }

  checkall(event: any) {
    if (event.target.checked) {
      this.checkedList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
    } else {
      this.checkedList = [];
    }
    this.headers.forEach(headers => headers.checked = event.target.checked)

  }

  onCheckboxChange(option, event) {
    if (event.target.checked) {
      this.checkedList.push(option);
      this.allchecked = this.checkedList.length >= 13;
    } else {
      for (var i = 0; i < this.checkedList.length; i++) {
        if (this.checkedList[i] == option) {
          this.checkedList.splice(i, 1);
        }
      }
      this.allchecked = this.checkedList.length >= 13;
    }
  }

  download() {
    setTimeout(jQuery('#exportfiltermodal').modal('hide'), 1000);
    this.IsExportHidden = !this.IsExportHidden;
  }

  /* Tags Changes */
  tagcheckall(data) {
    if (this.rememberallcheckedtags == true) {
      jQuery('#allcattag').prop('checked', true);
      this.showallcategoriesandtag = true
    } else {
      this.showallcategoriesandtag = false
    }
    if (this.showallcategoriesandtag == false) {
      this.tagsid = []
      this.tagsdata.forEach(x => x.status = data.target.checked)
      for (let i = 0; i < this.tagsdata.length; i++) {
        this.tagsdata[i].status = data.target.checked;
        if (data.target.checked) {
          this.tagsid.push(this.tagsdata[i].id)
        } else {
          this.tagsid = []
        }
      }
      this.sharedService.tagsid = this.tagsid
      this.sharedService.checkid = 1
      this.sharedService.tagfilterid = 1
      this.alldata = [];
      this.events = [];
      if (this.categoriesid.length > 0) {
        for (let k = 0; k < this.storeevnets.length; k++) {
          this.alldata.push(this.storeevnets[k])
        }
      }
      this.onlytagdatawithcategorydataonemore();
      this.events = this.alldata;
    } else {
      this.tagsid = []
      for (let i = 0; i < this.tagsdata.length; i++) {
        this.tagsdata[i].status = data.target.checked;
        if (data.target.checked == true) {
          this.tagsid.push(this.tagsdata[i].id)
        } else {
          this.tagsid = []
        }
      }
      this.onlytagdatawithcategorydataonemore();
    }
    this.isShowShowAll();
  }

  changedTags(ev, id) {
    if (this.rememberallcheckedtags == true) {
      jQuery('#allcattag').prop('checked', true);
      this.showallcategoriesandtag = true;
    } else {
      this.showallcategoriesandtag = false;
    }
    if (this.showallcategoriesandtag == false) {
      $('#allMenu').show();
      this.tagsid = [];
      for (let i = 0; i < this.tagsdata.length; i++) {
        if (this.tagsdata[i].id == id) {
          this.tagsdata[i].status = ev.target.checked;
        }
        if (this.tagsdata[i].status == true) {
          this.tagsid.push(this.tagsdata[i].id);
        }
      }
      this.sharedService.tagsid = this.tagsid;
      this.sharedService.checkid = 1;
      this.sharedService.tagfilterid = 1;
      this.isAlltagChecked();
      this.alldata = [];
      this.events = [];
      if (this.categoriesid.length > 0) {
        for (let k = 0; k < this.storeevnets.length; k++) {
          this.alldata.push(this.storeevnets[k])
        }
      }
      localStorage.setItem('triallineIdTags_' + this.triallineId, JSON.stringify(this.tagsid));
      this.events = this.alldata;
      if (this.simpleView != 3) {
        this.onlytagdatawithcategorydataonemore();
      }
    } else {
      this.tagsid = [];
      for (let i = 0; i < this.tagsdata.length; i++) {
        if (this.tagsdata[i].id == id) {
          this.tagsdata[i].status = ev.target.checked;
        }
        if (this.tagsdata[i].status == true) {
          this.tagsid.push(this.tagsdata[i].id)
        }
      }
      localStorage.setItem('triallineIdTags_' + this.triallineId, JSON.stringify(this.tagsid));
      if (this.simpleView != 3) {
        this.onlytagdatawithcategorydataonemore();
      }
    }
    this.isShowShowAll();
  }

  isAlltagCheckedForAsterisk() {
    
    if(this.tagsdata.length <= 0 ) {
      return 0;
    }

    let count = 0;
    for (let i = 0; i < this.tagsdata.length; i++) {
      if (this.tagsdata[i].status == true) {
        count++;
      }
    }
    
    if(this.tagsdata.length == count) {
      return 0;
    }
    
    return 1;

  }

  isAlltagChecked() {
    let count = 0;
    for (let i = 0; i < this.tagsdata.length; i++) {
      if (this.tagsdata[i].status == true) {
        count++
      }
    }
    if (count === 0) {
      return count
    }
    return count == this.tagsdata.length;
  }

  loadtagevents(tagsid) {
    this.processing = true;
    if (tagsid == '') {
      this.cookieService.put(this.cookieName + this.triallineId, '');
      this.processing = false;
      return this.events = [];
    } else if (!(tagsid[0] > 0) ) {
      tagsid = ''
    }
    return this.eventsResourceService.gettagEvents(this.triallineId, 'category,views,views.comments',
      tagsid).toPromise()
      .then((events: Event[]) => {
        this.processing = false;
        this.events = events;
        this.sharedService.eventdata = this.events;
        this.isAlltagChecked();
      }).catch((e: any) => {
        console.log(e);
        this.processing = false;
      });
  }

  getalltags() {
    this.tagsdata = [];
    let triallineIdTags = JSON.parse(localStorage.getItem('triallineIdTags_' + this.triallineId));
    let triallineIdCCategory = JSON.parse(localStorage.getItem('triallineIdCategories_' + this.triallineId));
    this.triallineResourceService.getContacts(this.triallineId).toPromise()
      .then((response) => {
        this.trialline.contacts = response;
      })
    this.contactsCategory = [];
    this.eventsResourceService.getTags(this.triallineId).toPromise()
      .then((response) => {
        this.tagscount = response.length;
        this.tagsdata = [];
        if (response.length > 0) {
          for (let i = 0; i < response.length; i++) {
            let storeStatus = triallineIdTags != null ? triallineIdTags.includes(response[i].id) == true : null;
            if (this.sharedService.tagsid.length > 0) {
              if (this.sharedService.tagfilterid == 0 && (storeStatus === null  || storeStatus === true) ) {
                this.tagsdata.push({'id': response[i].id, 'tag_name': response[i].tag_name, 'status': true});
                this.tagsdata.sort((a, b) => a.tag_name.localeCompare(b.tag_name));
                this.tagsid.push(response[i].id)
              } else {
                if (this.sharedService.tagsid.includes(response[i].id) == true  && (storeStatus === null
                  || storeStatus === true) ) {
                  this.tagsdata.push({'id': response[i].id, 'tag_name': response[i].tag_name, 'status': true});
                  this.tagsdata.sort((a, b) => a.tag_name.localeCompare(b.tag_name));
                  this.tagsid.push(response[i].id)
                } else {
                  this.tagsdata.push({'id': response[i].id, 'tag_name': response[i].tag_name, 'status': false});
                }
              }
            } else {
              if (this.sharedService.checkid == 1) {
                this.tagsdata.push({'id': response[i].id, 'tag_name': response[i].tag_name, 'status': false});
                this.tagsdata.sort((a, b) => a.tag_name.localeCompare(b.tag_name));
              } else {
                this.tagsdata.push({'id': response[i].id, 'tag_name': response[i].tag_name,
                  'status': (storeStatus === null  || storeStatus === true)});
                this.tagsdata.sort((a, b) => a.tag_name.localeCompare(b.tag_name));
                this.tagsid.push(response[i].id)
              }
            }
          }
        }
        if (triallineIdTags != null) {
          this.tagsid = triallineIdTags;
        }
        this.sharedService.tagsid = this.tagsid;
        this.isAlltagChecked()
        this.eventsResourceService.getCcategories(this.triallineId).toPromise()
          .then((response) => {
            for (let i = 0; i < response.length; i++) {
              let storeStatus = triallineIdCCategory != null ? triallineIdCCategory.includes(response[i].id) == true : null;
              if (storeStatus === true) {
                response[i].status = true;
              } else {
                response[i].status = false;
              }
              this.contactsCategory.push(response[i])
            }
            for (let i = 0; i < this.tracksCategory.length; i++) {
              if (this.tracksCategory[i].id == 'contacts_category_id' && !this.contactsCategory.length) {
                this.tracksCategory.splice(i, 1)
              }
              if (this.tracksCategory[i].id == 'tag_id' && !this.tagsdata.length) {
                this.tracksCategory.splice(i, 1)
              }
              if (this.tracksCategory[i].id == 'contacts_id' && !this.trialline?.contacts.length) {
                this.tracksCategory.splice(i, 1)
              }
            }
          })
      });
  }

  checkedContacts() {
    let triallineIdContacts = JSON.parse(localStorage.getItem('triallineIdContacts_' + this.triallineId));
    if (triallineIdContacts != null ) {
      let uniqueItems = triallineIdContacts;
      if (!Array.isArray(triallineIdContacts)) {
         uniqueItems = triallineIdContacts.split(',');
      }
      let contactsNew = [];
      if (uniqueItems.length > 0 ) {
        for (let item in uniqueItems) {
          contactsNew.push(parseInt(uniqueItems[item]));
        }
        this.contactsid = contactsNew;
        for (let contacts in this.trialline.contacts) {
          this.trialline.contacts[contacts].status =  contactsNew.includes(this.trialline.contacts[contacts].id);
          this.isAllContactsChecked();
        }
      }
    }
  }

  checkedContactsCategory() {
    let triallineIdContactsCategory = JSON.parse(localStorage.getItem('triallineIdContactsCategory_' + this.triallineId));
    if (triallineIdContactsCategory != null) {
      let uniqueItems = triallineIdContactsCategory.split(',');
      let contactsCategoryNew = [];
      if (uniqueItems.length > 0 ) {
        for (let item in uniqueItems) {
          contactsCategoryNew.push(parseInt(uniqueItems[item]));
        }
        this.contactsCategoryid = contactsCategoryNew;
        for (let contactsCategory in this.contactsCategory) {
          this.contactsCategory[contactsCategory].status = contactsCategoryNew
            .includes(this.contactsCategory[contactsCategory].id);
          this.isAllContactsCategoryChecked();
        }
      }
    }
  }

  closeMenu() {
    this.tags = false;
    this.categories = false;
    this.contactCategories = false;
    this.contactsShow = false;
    $('#allMenu').hide()
    $('#allMenuContact').hide()
    $('#assign').hide();
    $("#catcolor").css("background", "white");
    $("#tagcolor").css("background", "white");
    $("#contactcolor").css("background", "white");
    $("#contactCategorycolor").css("background", "white");
  }

  onlytagdatawithcategorydata(event) {
    this.rememberallcheckedtags = event.target.checked
    this.showallcategoriesandtag = event.target.checked
    if (event.target.checked == true) {
      this.events = [];
      let uniqueContactsCategory;
      let uniqueContact;
      let uniqueItems;
      let categoriesId;
      if (this.isAllContactsCategoryChecked()) {
        uniqueContactsCategory = 'all';
      } else {
        uniqueContactsCategory = this.getSelectedContactsCategory().join();
      }
      if (this.isAllContactsChecked()) {
        uniqueContact = 'all';
      }else{
        uniqueContact = this.getSelectedContacts().join();
      }
      if (this.isAlltagChecked()) {
        uniqueItems = 'all'
      } else {
        uniqueItems = Array.from(new Set(this.sharedService.tagsid))
      }
      if (this.tagscount == uniqueItems.length) {
        uniqueItems = [];
      }
      if (this.isAllChecked()){
        categoriesId = 'all';
      } else {
        categoriesId = this.categoriesid;
      }
      if ((this.tagsid.length > 0) || (this.categoriesid.length > 0)
        || (uniqueContactsCategory && uniqueContactsCategory.length > 0) || (uniqueContact && uniqueContact.length > 0)
        || this.rangeFilterFrom || this.rangeFilterTo) {
        return this.eventsResourceService
          .gettagandcategoriesEvents(this.triallineId, categoriesId, uniqueItems, uniqueContactsCategory, uniqueContact)
          .toPromise()
          .then((events: Event[]) => {
            this.events = events;
            this.sharedService.eventdata = this.events;
            this.sharedService.tagcategoriesid = 1
          }).catch((e: any) => {
            console.log(e);
          });
      } else {
        this.events = [];
      }
    } else {
      this.loadEvents(this.categoriesid);
    }
    this._changeDetectionRef.detectChanges();
  }

  onlytagdatawithcategorydataonemore() {
    if(this.categoriesid.length > 0 && isNaN(this.categoriesid[0])) {
      this.categoriesid = [];
    }
    let uniqueContactsCategory;
    let uniqueContact;
    let uniqueItems;
    let categoriesId;
    if (!this.filterParallel) {
      if (this.isAllContactsCategoryChecked()) {
        uniqueContactsCategory = 'all';
      } else {
        uniqueContactsCategory = this.getSelectedContactsCategory().join();
      }
      if (this.isAllContactsChecked()) {
        uniqueContact = 'all';
      }else{
        uniqueContact = this.getSelectedContacts().join();
      }
      if (this.isAlltagChecked()) {
        uniqueItems = 'all'
      } else {
        uniqueItems = Array.from(new Set(this.sharedService.tagsid))
      }
      if (this.isAllChecked()){
        categoriesId = 'all';
      } else {
        categoriesId = this.categoriesid;
      }
    } else {
      uniqueContactsCategory = this.getSelectedContactsCategory().join();
      uniqueContact = this.getSelectedContacts().join();
      uniqueItems = Array.from(new Set(this.sharedService.tagsid))
      categoriesId = this.categoriesid;
    }
    this.contactsCategoryid = this.getSelectedContactsCategory().join();
    if ((this.tagsid && this.tagsid.length > 0) || (this.categoriesid && this.categoriesid.length > 0)
      || (uniqueContactsCategory && uniqueContactsCategory.length > 0) || (uniqueContact && uniqueContact.length > 0)
      || this.rangeFilterTo || this.rangeFilterFrom) {
      return this.eventsResourceService.gettagandcategoriesEvents(this.triallineId, categoriesId, uniqueItems,
        uniqueContactsCategory, uniqueContact, this.filterParallel, this.rangeFilterFrom, this.rangeFilterTo).toPromise()
        .then((events: Event[]) => {
          let count = 0;
          for (let i = 0; i < events.length; i++) {
            if (this.categoriesid.includes(events[i].category_id) == false) {
              count++;
            }
          }
          this.events = events;
          this.idsList();
          if (this.showallcategoriesandtag == false) {
            this.storeevnets = events;
            this.alldata = [];
            this.events = [];
            if (this.categoriesid.length > 0) {
              for (let k = 0; k < this.storeevnets.length; k++) {
                this.alldata.push(this.storeevnets[k])
              }
              this.events = this.alldata;
            } else {
              this.events = events;
            }
          } else {
            this.events = events;
            this.sharedService.eventdata = this.events
            this.sharedService.tagcategoriesid = 1
          }
          this.sharedService.eventdata = this.events

        }).catch((e: any) => {
          console.log(e);
          this.processing = false;
        });
    } else {
      this.events = []
    }
    this._changeDetectionRef.detectChanges();
  }
  isShowRedShow(){
    let tag = this.isAlltagChecked();
    if (tag === 0) {
      tag = true;
    }
    let contact = this.isAllContactsChecked();
    if (contact === 0) { 
      contact = 1;
    }
    let ccontact = this.isAllContactsCategoryChecked();
    if (ccontact === 0) {
      ccontact = 1;
    }
    return (this.isAllChecked()
      && tag
      && ccontact
      && contact)
  }


  isShowRedShowNew() {
    //console.log('--------- I am in isShowRedShowNew function ---------')
    let showAsteriskTags: number = 1;
    let showAsteriskContacts: number = 1;
    let showAsteriskContactsCategories: number = 1;
    let showAsteriskCategories: number = 1;


    /************* FOR TAGS *************/
    if(this.tagsdata.length) {
      let count = 0;
      for (let i = 0; i < this.tagsdata.length; i++) {        
        if (this.tagsdata[i].status == true) {
          count++
        }
      }
      
      if (count === 0) { // If all empty
        showAsteriskTags = 0;
      }

      if(count == this.tagsdata.length) { // If all selected
        showAsteriskTags = 0;
      }

    }
    //console.log('showAsterisk FOR TAGS:', showAsteriskTags);
    /************* FOR Contacts *************/
    if(this.trialline !== undefined && this.trialline.contacts.length > 0){
      
      let count = 0;
      for (let i = 0; i < this.trialline.contacts.length; i++) {
        if (this.trialline.contacts[i].status == true) {
          count++;
        }
      }
  
      if (count === 0) { // If all empty
        showAsteriskContacts = 0;
      }

      if(count == this.trialline.contacts.length) { // If all selected
        showAsteriskContacts = 0;
      }
      
    }
    //console.log('showAsterisk FOR Contacts:', showAsteriskContacts);
    /************* FOR Contacts Categories *************/
    if (this.trialline !== undefined && this.contactsCategory != undefined) {
      let queryRun : any;
      queryRun = this.contactsCategory.find(c => c && c.status);
      
      if(!queryRun) { // If all empty
        showAsteriskContactsCategories = 0;
      }
        
      if(this.contactsCategory.every(c => c && typeof c.status !== 'undefined' && c.status === true)) { // If all selected
        showAsteriskContactsCategories = 0;
      }
      
    }
    //console.log('showAsterisk FOR Contacts Categories:', showAsteriskContactsCategories);
    /************* FOR Categories *************/    
    if(this.trialline.categories) {
      let queryRun : any;
      queryRun = this.trialline.categories.filter(c => c && c.events_count > 0).find(c => c && c.state);
      
      if(!queryRun) { // if all empty
        showAsteriskCategories = 0;
      }
        
      if(this.trialline.categories.filter(c => c && c.events_count > 0).every(c => c && c.state)) { // If all selected
        showAsteriskCategories = 0;
      }

    }
    //console.log('showAsterisk FOR Categories:', showAsteriskCategories);

    if(showAsteriskCategories || showAsteriskContactsCategories || showAsteriskContacts || showAsteriskTags) {
      return 1;
    }
    
  }

  isShowShowAll(){
    let uniqueContactsCategory;
    let uniqueContact;
    if (this.isAllContactsCategoryChecked()) {
      uniqueContactsCategory = 'all';
    } else {
      uniqueContactsCategory = this.getSelectedContactsCategory().join();
    }
    if (this.isAllContactsChecked()) {
      uniqueContact = 'all';
    }else{
      uniqueContact = this.getSelectedContacts().join();
    }
    this.showAll = (this.tagsid && this.tagsid.length > 0) || (this.categoriesid && this.categoriesid.length > 0)
      || (uniqueContactsCategory && uniqueContactsCategory.length > 0) || (uniqueContact && uniqueContact.length > 0);
    this._changeDetectionRef.detectChanges();
  }

  isShowRedShowContact(){
    return !(this.isAllContactsCategoryChecked() || this.isAllContactsChecked())
  }
}
