import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter, HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  ViewChild
} from '@angular/core';
import {Event} from '../../main/shared/event.model'
import {TriallineNavigationComponent} from "../trialline/navigation/navigation.component";
import {env} from '../../../../.env';
import {Trialline} from '../../main/shared/trialline.model';
import * as $ from 'jquery';
import {SharedService} from 'app/shared/shared.service';
import {FilterToTemplatePipe} from '../shared/filterToTemplate.pipe';


declare var vis: any;
declare var jQuery: any;
declare var moment: any;

@Component({
  selector: 'app-track-trialline',
  templateUrl: './trialline-track.component.html',
  styleUrls: ['./trialline-track.component.scss'],
})
export class TriallineTrackComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() events: Event[];
  @Input() trialline: Trialline;
  @Input() tracks: [];
  @Input() password: string;
  @Input() offsetHeight: number;
  @Input() editButton: boolean;
  @Input() commentsButton: boolean;
  @Output() onEdit = new EventEmitter<number>();
  @Output() onMore = new EventEmitter<any>();

  @ViewChild('visualizations', { static: true }) el: ElementRef;
  @ViewChild(TriallineNavigationComponent, { static: true }) navigation: TriallineNavigationComponent;

  public publicPath = env.publicFolderUrl;
  public apiPath = env.apiUrl;
  public idVisualisation: string;
  defaultThumbnail: string = '/images/start-event/trialline-event-thumb.jpg';
  defaultThumbnail1: string = '';
  trackClass: string = '';
  hyphenDash: string = '';
  timeline: any;
  status: any;
  groups: any;
  minDate: any = 0;
  maxDate: any = 0;
  eventsRef: any = [];
  zoomMin: number = 7100;
  constructor(
    private element: ElementRef,
    private _changeDetectorRef: ChangeDetectorRef,
    protected sharedService: SharedService,
    private filterToTemplate: FilterToTemplatePipe,
  ) {

  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    let events = changes['events'] ? changes['events'].currentValue : [];
    if (this.timeline) {
      this.minDate = 0;
      this.maxDate = 0;
      this.renderAndSetItems(events);
    }

  }

  @HostListener('document:keydown', ['$event'])
  arrowNav(e: any) {
    if ([37, 38, 39, 40].indexOf(e.keyCode) > -1 && (!(document.getElementsByTagName("ckeditor").length > 0))) {
      e.preventDefault();
      switch (e.which) {
        case 37: // left
          this.moveWindow(-0.3);
          break;
        case 39: // right
          this.moveWindow(0.3);
          break;
        case 38: // up
          this.zoomPercent(0.3);
          break;
        case 40: // down
          this.zoomPercent(-0.3);
          break;
        default:
          return; // exit this handler for other keys
      }
    }
  }

  ngOnInit() {
    if (this.password) {
      this.idVisualisation = this.password;
    } else {
      this.idVisualisation = '1'
    }
    if (typeof this.editButton === 'undefined') this.editButton = true;
    if (typeof this.commentsButton === 'undefined') this.commentsButton = true;
    let items = new vis.DataSet([]);
    let self = this;
    console.log(this.events);
    this.timeline = new vis.Timeline(this.el.nativeElement, items,
      {
        zoomable: true,
        autoResize: true,
        height: "100%",
        stack: true,
        align: 'left',
        dataAttributes: ['id'],
        template: function (item, element, data, visualisation) {

          let margin = '';
          let timepanel = '';
          let timeStart = '';
          let timeEnd = '';
          let onlydate = '';
          let datepanel = '';
          let date;
          let divbindnonecategory;
          let backgroundColor;

          if(item.has_times == true || item.has_times_end == true) {
            margin = '-58px';
          }else{
            margin = '-29px';
          }
          if (item.end_date == false){
            item.times_end = '<div style="display: none;"></div>'
          }
          if(item.title.length > 22) {
            item.title = item.title.substring(0, 22)+'...';
          } else {
            item.title = item.title;
          }
          if(item.end_date != null){
            datepanel = '<div  class="ticket-header-label " style="margin-top:' + margin + ';background-color:'
              + item.background + ';height: 26px;font-size: 14px;padding:4px;">' +
              item.formatStartDate + '-' + date + '</div>';
          }else{
            onlydate = '<div class="ticket-header-date" style="font-size: 13px; color:#000; font-weight: bold;">' + item.formatStartDate + '</div>';
          }
          if(item.category_name == 'No Category') {
            divbindnonecategory = '';
            backgroundColor = '<div class="ticket" style=" background-color: #0E69A5 ">';
          }else {
            divbindnonecategory = '<div class="ticket-header-label" style=" color: ' + item.background + '">' +
              item.category_name + '</div>';
            backgroundColor = '<div class="ticket" style=" background-color: ' + item.background + '">';
          }
          if (item.show_times_end2) {
            self.hyphenDash = '<i class="fa fa-arrow-right fa-lg icon-gray" aria-hidden="true"></i>';
          } else {
            self.hyphenDash = '';
          }
          if(item.times_start) {
            item.times_end = item.times_end;
          } else {
            item.times_end = '';
          }

          let template = (item.start && item.end) && (item.start !== item.end) ? '<div class="back_strip"' +
            ' style="height: 30px; background:'+item.background+';position: absolute; width: 100%">' +
            '</div>' : '';
          return '<div>'  + backgroundColor +
            '<div class="w-100">' +
              '<div class="ticket-header"><div class="content-card-heading" title="' + item.category_name + '">' + divbindnonecategory + '</div>'+
              '<div class="ticket-header-date " style="display: flex; align-items: flex-start; font-size: 18px; color:#000; font-weight: bold; padding-top: 3px; right: 10px;">'+
              '<div class="time-text">'+
              '<div class="fs-lg">'
               + item.show_times_start2 
               +'</div>'
               + item.times_start  + '</div>' + self.hyphenDash
               +
              '<div class="time-text">' +
              '<div class="fs-lg">'
              + item.show_times_end2 
              +'</div>'
              + item.times_end + 
              '</div>' +'</div></div>' +
            '<div class="ticket-body clearfix">' +
            (item.thumbnail_visible ? '<div class="ticket-body-img img_lazy" style=" background:' + item.thumbnail.background +
              '; background-size: cover;"></div>' : '') +
            '<div class="ticket-body-text" style="display: grid">' +
            '<h4>' + item.title + '</h4>' +
            '<div class="text text-item-description"><p>'
            + item.description +
            '</p></div>'+
            '<div class="control" data-event-id="' + item.id_second + '">'+
            (self.editButton && !self.trialline.parent_trialline_id ? '<a id="edit-button_' + self.idVisualisation
              + '" class="btn btn-default btn-xs" title="Edit"><i class="fa fa-pencil" aria-hidden="true"></i><span class="text_hover">Edit</span></a>' : '') +
            '<a id="more-button_' + self.idVisualisation + '" class="btn btn-default btn-xs" title="More"><i class="fa fa-ellipsis-h"></i></a>' +
            '</div>'+
            '</div>' +
            '</div>' +
            '</div>' +
            '<div class="ticket-body-control"' + backgroundColor  +
            '<div class="control" data-event-id="' + item.id_second + '">' +
            (item.files_count ?
            '<a class="btn btn-default btn-xs attached-btn" id="attachments-button_' + self.idVisualisation + '">' +
            '<i class="fa fa-paperclip" aria-hidden="true" title="Attachmnets"></i>' +
            '<span class="badge">' + item.files_count + '</span>' +
            '</a>': '') +
            /* tag */
            (item.tags_count ?
            '<a class="btn btn-default btn-xs attached-btn" id="tags-button_' + self.idVisualisation + '">' +
            '<i class="fa fa-tags" aria-hidden="true" title="Tags"></i>' +
            '<span class="badge">' + item.tags_count + '</span>' +
            '</a>': '') +
            (item.comments_count ?
              '<a class="btn btn-default btn-xs comment-btn" id="comments-button_' + self.idVisualisation + '">' +
              '<i class="fa fa-comments" aria-hidden="true" title="Comments"></i>' +
              '<span class="badge">' + item.comments_count + '</span>' +
              '</a>' : '') +
            (item.bill_cost_count ?
              '<a class="btn btn-default btn-xs attached-btn" id="bill_cost-button_' + self.idVisualisation + '">' +
              '<i class="fa fa-usd" aria-hidden="true" title="BillCosts"></i>' +
              '<span class="badge">' + item.bill_cost_count + '</span>' +
              '</a>' : '') +
            /* End */
            '</div>' +
            '</div>' +
            '</div>' + template
        }
      });
    this.timeline.on('rangechanged', function (s) {
      self.sharedService.StartTime = s.start;
      self.sharedService.EndTime = s.end;
    });
    this.timeline.redraw();
    this.renderAndSetItems();
    this._changeDetectorRef.detectChanges();
  }

  ngAfterViewInit() {
    let self = this;
    let $visTimeline = jQuery('.vis-timeline');
    let container = jQuery('#visualizations_' + this.idVisualisation)[0];
    let setContainerHeight = function (c) {
      let offset = self.offsetHeight || 0;
      let $zoomWidget = jQuery('.widget-zoom');
      let zoomWidgetHeight = $zoomWidget.height();
      let windowHeight = jQuery(window).height();
      c.style.height = (windowHeight - offset) + 'px';
      $zoomWidget.css('top', ((windowHeight - 100) / 2) - (zoomWidgetHeight / 2));
      if (self.timeline) {
        self.timeline.redraw();
      }
    };

    setContainerHeight(container);
    jQuery(window).resize(function (e) {
      let height = $(this).height();
      let topHeight = height - 36 ;
      if(navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null) {
        $('.trial-line-navigation').css({'top': topHeight + 'px'})
        $('body').css({'height': height + 'px'})
      }
      self.timeline.setOptions({height:height});
      setContainerHeight(container);
    });
    $visTimeline.on('click', '#edit-button_' + this.idVisualisation, function () {
      self.eventEdit($(this).parent().data('event-id'));
      self.sharedService.status = true;
    });
    $visTimeline.on('click', '#more-button_' + this.idVisualisation, function () {
      self.eventMore($(this).parent().data('event-id'), 'description');
    });
    $visTimeline.on('click', '#attachments-button_' + this.idVisualisation, function () {
      self.eventMore($(this).parent().data('event-id'), 'files');
    });
    $visTimeline.on('click', '#bill_cost-button_' + this.idVisualisation, function () {
      self.eventMore($(this).parent().data('event-id'), 'bill_cost');
    });
    $visTimeline.on('click', '#comments-button_' + this.idVisualisation, function () {
      self.eventMore($(this).parent().data('event-id'), 'comments');
    })
    $visTimeline.on('click', '#tags-button_' + this.idVisualisation, function () {
      self.eventMore($(this).parent().data('event-id'), 'tags');
    });
  }

  ngOnDestroy() {
    this._changeDetectorRef.detach();
  }

  eventEdit(id: number) {
    this.onEdit.emit(id);
  }

  eventMore(id: number, activeTab?) {
    if (!jQuery('#event-flag-'+id).length) {
      jQuery('.event-flag-'+id).trigger('click');
    } else {
      jQuery('#event-flag-'+id).trigger('click');
    }
    if (!jQuery('#event-flag-'+ (id * 1 + 100000000)).length) {
      jQuery('.event-flag-'+(id * 1 +100000000)).trigger('click');
    } else {
      jQuery('#event-flag-'+(id * 1 + 100000000)).trigger('click');
    }
    if (!jQuery('#event-flag-'+(id * 1 + 200000000)).length) {
      jQuery('.event-flag-'+(id * 1 + 200000000)).trigger('click');
    } else {
      jQuery('#event-flag-'+(id * 1 + 200000000)).trigger('click');
    }
    if (!jQuery('#event-flag-'+(id * 1 + 300000000)).length) {
      jQuery('.event-flag-'+(id * 1 + 300000000)).trigger('click');
    } else {
      jQuery('#event-flag-'+(id * 1 + 300000000)).trigger('click');
    }
    this.onMore.emit({id: id, activeTab: (activeTab ? activeTab : 'description')});
  }

  renderAndSetItems(events: any = this.events, saveCursor = false) {
    if (this.events && this.events.length != 0) {
      this.eventsRef = this.filterToTemplate.transformEventsTrack(this.events);
    }
    this.maxDate = 0;
    this.minDate = 0;
    let self = this;
    if(this.eventsRef && this.eventsRef.length !== 0 && events.length !== 0){
      events = this.eventsRef;
    }
    if (events.length > 0) {
      this.minDate = moment(events[0].start_date).valueOf();
    }
    let items = new vis.DataSet(events ? events.map(function (item, key) {
      item.start = item.start_date;
      if (moment(item.start_date).valueOf() < self.minDate) {
        self.minDate = moment(item.start_date);
      }
      if (moment(item.start_date).valueOf() > self.maxDate) {
        self.maxDate = moment(item.start_date);
      }
      if (item.date_end && moment(item.date_end).valueOf() > self.maxDate) {
        self.maxDate = moment(item.date_end);
      }
      if (item.if_start_days) {
        item.formatStartDate = moment(item.start_date).format('MMM YYYY');
      } else {
        item.formatStartDate = moment(item.start_date).format('MMM DD, YYYY');
      }
      item.monthYear = moment(item.start_date).format('MMMM YYYY');
      if (!item.description) item.description = '';
      return item;
    }) : []);
    this.groups = new vis.DataSet();
    let count = 0;
    this.tracks.map((item: any) => {
      item.show = false;
    })
    this.tracks.map((item: any) => {
      this.events.forEach((itemEvent: Event) => {
        if (item.show == false && itemEvent.track == item.id) {
          item.show = true;
          count++;
          this.groups.add({id: item.id, content: item.name});
        }
      });
      return item;
    })
    this.trackClass = 'trackFirst';
    if (count == 2) {
      this.trackClass = 'trackSecond';
    } else if (count == 3) {
      this.trackClass = 'trackThird';
    }
    $('.vis-panel.vis-bottom').removeClass('trackSecond');
    $('.vis-panel.vis-bottom').removeClass('trackThird');
    $('.vis-panel.vis-left').removeClass('trackSecond');
    $('.vis-panel.vis-left').removeClass('trackThird');
    $('.trial-line-navigation').removeClass('trackSecond');
    $('.trial-line-navigation').removeClass('trackThird');
    $('.vis-panel.vis-center').removeClass('trackSecond');
    $('.vis-panel.vis-center').removeClass('trackThird');
    $('.vis-panel.vis-bottom').removeClass('trackFirst');
    $('.vis-panel.vis-left').removeClass('trackFirst');
    $('.trial-line-navigation').removeClass('trackFirst');
    $('.vis-panel.vis-center').removeClass('trackFirst');
    $('.navigation_track').removeClass('trackFirst');
    $('.navigation_track').removeClass('trackSecond');
    $('.navigation_track').removeClass('trackThird');
    $('.vis-panel.vis-center').addClass(this.trackClass);
    $('.trial-line-navigation').addClass(this.trackClass);
    $('.vis-panel.vis-bottom').addClass(this.trackClass);
    $('.vis-panel.vis-left').addClass(this.trackClass);
    $('.navigation_track').addClass(this.trackClass);
    this.timeline.setItems(items);
    this.timeline.setGroups(this.groups);
    this.changeLimits(saveCursor, this.getStartDate(events));
    this.showMonthYear();
    this.navigation.calculateFlags();
  }

  getStartDate(events) {
    let dates = [];
    events.forEach((order: any) => {
      dates.push(order.start_date);
    });
    let date_sort_asc = function (date1, date2) {
      if (date1 > date2) return 1;
      if (date1 < date2) return -1;
      return 0;
    };
    dates.sort(date_sort_asc);
    return (dates[0]);
  }

  changeLimits(saveCursor: boolean = false, startDate, minDate = this.minDate, maxDate = this.maxDate) {
    if (startDate) {
      let date = new Date(startDate);
      date.setDate(date.getDate() - 3);
      let endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 4);
    }
    if (!minDate) {
      minDate = new Date();
    }
    if (!maxDate) {
      maxDate = new Date();
    }
    if (this.timeline) {
      this.timeline.setOptions({
        maxHeight: "100%",
        zoomMin: this.zoomMin,
        groupOrder: 'content',
        margin: {
          item: {
            horizontal: -10
          },
          axis: 200
        },
        min: new Date(moment(minDate).subtract(30, 'd').year(), moment(minDate).subtract(30, 'd').month(), moment(minDate).subtract(30, 'd').date()),
        max: new Date(moment(maxDate).add(10, 'y').year(), moment(maxDate).add(10, 'y').month(), moment(maxDate).add(10, 'y').date())
      });
      if (!saveCursor) {
        let start = this.minDate,
          end = this.maxDate,
          interval = (end - start) * -0.2,
          pos = {
            start: start - interval,
            end: end + interval
          };
        this.timeline.setWindow(pos);
      }
      this.timeline.redraw();
      this._changeDetectorRef.detectChanges();
    }
  }

  showMonthYear(pos = null) {
    if (!pos) {
      pos = this.timeline.getWindow()
    }
    let middleDate = pos.start.valueOf() + ((pos.end.valueOf() - pos.start.valueOf()) / 2);
    jQuery('#nameMonth .month').text(moment(middleDate).format('MMMM YYYY'));
  };

  zoomPercent(percentage) {
    let range = this.timeline.getWindow(),
      start = range.start.valueOf(),
      end = range.end.valueOf(),
      interval = (range.end.valueOf() - range.start.valueOf()) * percentage,
      pos = {
        start: start - interval,
        end: end + interval
      };
    this.navigation.firstTime = true;
    this.timeline.setWindow(pos);
  }

  moveWindow(percentage) {
    let range = this.timeline.getWindow(),
      start = range.start.valueOf(),
      end = range.end.valueOf(),
      interval = (range.end.valueOf() - range.start.valueOf()) * percentage,
      pos = {
        start: start + interval,
        end: end + interval
      };
    this.navigation.firstTime = true;
    this.timeline.setWindow(pos);
  };

  select(id: number) {
    this.navigation.select(id);
  }
}
