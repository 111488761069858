import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {TriallineResourceService} from "../../shared/trialline-resoruce.service";
import {View} from "../../../shared/view.model";

@Injectable()
export class ViewsResolver implements Resolve<View[]>
{
  constructor(protected triallineResource: TriallineResourceService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<View[]>
  {
    return this.triallineResource.getViews(route.parent.params['trialline_id'], 'trialline')
      .toPromise()
      .then((views: View[]) => views)
    //  .catch((error) => { this.router.navigateByUrl(error.status == 403 ? '/403' : '/404') });
  }
}
