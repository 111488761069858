<div class="container support page-wrap">
  <h3>DESKTOP APPLICATION</h3>
  <img src="assets/squares.gif" class="img spinners" alt="spinner" *ngIf="! user" style="opacity: .6;position: relative;top: 10px;left:50%;z-index: 9999999;">
  <div class="row" *ngIf="user && user.subscription_plan"><!-- Rheal Change Add && user.subscription_plan for free user   -->
    <div class="col-md-12" *ngIf="(user.subscription_plan)">
      <div class="jumbotron" style="text-align: center">
        <ng-container *ngIf="! unknown">
          <h1>Application for {{ os }}</h1>
          <p>
            The offline version of TrialLine is meant for display purposes only, for use in situations where reliable
            internet access is not available. You can make edits to your TrialLines in the offline version, but you
            cannot upload offline changes to your online TrialLines. Before displaying your TrialLines with the offline
            version, be sure you have downloaded your most recent changes.
          </p>
          <p>
            <a class="btn btn-primary btn-lg" (click)="download()" role="button">Download</a><br>
            <a (click)="toggleList()"><small>Click here to download TrialLine for {{ os == 'MacOS' ? 'Windows' : 'MacOS' }}.</small></a>
          </p>
        </ng-container>
        <ng-container *ngIf="unknown">
          <h3>Please select an application from list below</h3>
        </ng-container>
        <ul [ngStyle]="{display: (list ? 'block' : 'none')}" style="list-style: none; text-align: left">
          <li *ngFor="let item of listOfLinks">
            <a *ngIf="item.system != os" (click)="download(item.system)">{{ item.system }} application download</a>
          </li>
          <li>Didn't find the suitable version? <a [routerLink]="['/support']">Please contact our support</a>, and we will try to help you</li>
        </ul>
      </div>
    </div>
    <div class="alert alert-warning" *ngIf="! (user.subscription_plan)">
      <strong>Warning!</strong> Warning! Your current subscription plan does not support the offline version of TrialLine. To update your subscription, please login to https://TrialLine.net and select the "Subscriptions" tab on your "Profile" page.
    </div>
  </div>

  <!-- Free User  -->
  <div class="row" *ngIf="user && !user.subscription_plan && user.isFree==1"><!-- Rheal Change Add && user.subscription_plan for free user   -->
    <div class="col-md-12">
      <div class="jumbotron" style="text-align: center">
        <ng-container *ngIf="! unknown">
          <h1>Application for {{ os }}</h1>
          <p>
            <a class="btn btn-primary btn-lg" (click)="download()" role="button">Download</a><br>
            <a (click)="toggleList()"><small>Click here to download TrialLine for {{ os == 'MacOS' ? 'Windows' : 'MacOS' }}.</small></a>
          </p>
        </ng-container>
        <ng-container *ngIf="unknown">
          <h3>Please select an application from list below</h3>
        </ng-container>
        <ul [ngStyle]="{display: (list ? 'block' : 'none')}" style="list-style: none; text-align: left">
          <li *ngFor="let item of listOfLinks">
            <a *ngIf="item.system != os" (click)="download(item.system)">{{ item.system }} application download</a>
          </li>
          <li>Didn't find the suitable version? <a [routerLink]="['/support']">Please contact our support</a>, and we will try to help you</li>
        </ul>
      </div>
    </div>
  </div>

</div>
<span defaultOverlayTarget></span>
