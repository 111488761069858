<!--<img src="assets/squares.gif" class="img spinners" alt="spinner" *ngIf="processing">-->
<div class="navbar navbar-default " id="custom-bootstrap-menu" role="navigation">
  <div class="container-fluid">
    <div class="navbar-header">
      <a [routerLink]="['/dashboard']" class="logoimg">
        <img alt="" src="assets/images/logo.png">
      </a>
      <button class="navbar-toggle" data-target=".navbar-menubuilder" data-toggle="collapse" type="button">
        <span class="sr-only">Toggle navigation</span><span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
    </div>
    <div class="collapse navbar-collapse navbar-menubuilder">
      <ul class="nav navbar-nav navbar-left widthfull">
        <li><a [routerLink]="['../']">Back to TrialLine</a>
        </li>
        <li class="bigright"><a [routerLink]="['/support']">Technical Support</a>
        </li>
        <li class="bigright"><a [routerLink]="['/dashboard']">Dashboard</a>
        </li>
        <li class="bigright"><a [routerLink]="['../edit']">Edit TrialLine</a>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="page-container">
  <div class="conteiner">
    <div class="row nom responsive-table-by-me not-mob">
      <div class="col-xs-2 col-md-1 bor nop col-he">
        <div class="col-xs-12 tit-views nop tbm title-he">#</div>
      </div>
      <div class="col-xs-10 col-sm-6 col-md-2 bor nop col-view">
        <div class="col-xs-12 tit-views tbm title-view">View Name</div>
      </div>
      <div class="col-xs-6 col-sm-4 col-md-1 bor nop col-date">
        <div class="col-xs-12 tit-views tbm title-date">Date Created</div>
      </div>
      <div class="col-xs-6 col-md-2 bor nop col-share-link">
        <div class="col-xs-12 tit-views tbm title-share">Share Link</div>
      </div>
      <div class="col-xs-6 col-md-2 bor nop col-link">
        <div class="col-xs-12 tit-views tbm title-link">Link Password</div>
      </div>
      <div class="col-xs-6 col-md-1 bor nop col-set">
        <div class="col-xs-12 tit-views tbm title-set">Set iFrame Dimensions (px)</div>
      </div>
      <div class="col-xs-6 col-md-2 bor nop col-frame">
        <div class="col-xs-12 tit-views tbm title-frame">iFrame Embed Code</div>
      </div>
      <div class="col-xs-6 col-sm-12 col-md-1 bor nop col-share">
        <div class="col-xs-12 tit-views tbm title-remove">Share</div>
      </div>
      <div class="col-xs-6 col-sm-12 col-md-1 bor nop col-remove">
        <div class="col-xs-12 tit-views tbm title-remove">Remove</div>
      </div>
    </div>
    <div *ngFor="let view of views" class="row nom responsive-table-by-me mob" >
      <div [ngClass]="{'live_url': view.viewsort == 1}">
      <div class="col-xs-2 col-md-1 bor nop col-he item-tab">
        <div class="col-xs-12 tit-views nop tbm title-he">#</div>
        <div class="col-xs-12 inf nop tbm info-he">{{ view.id }}</div>
      </div>
      <div class="col-xs-10 col-sm-6 col-md-2 bor nop col-view item-tab">
        <div class="col-xs-12 tit-views tbm title-view">View Name</div>
        <div class="col-xs-12 inf tbm info-view">
          <a target="_blank"
             [attr.href]="appUrl + 'view/' + view.uuid + '?pass=' + view.password + '&token=' + auth.token()">{{ view.name }}</a>


          <div *ngIf="view.viewsort == 1" class="view-select" d="public_{{view.id}}-view">

            <input type="checkbox" id="public_{{view.id}}-view" class="btn btn-primary pull-left"
            mwlConfirmationPopover
            [title]="'Are You Sure?'"
            [popoverMessage]=" view.view == 0 ? 'You are choosing Pyramid View.':'You are choosing Relative View.'"
            [confirmText] ="'Yes'"
            [cancelText] ="'No'"
            placement="right"
            (confirm)="changeView(view)"
            (click)="publicAccess()"
            [checked]="view.view"
            >
            <label *ngIf="view.viewsort == 1" for="public_{{view.id}}-view"> Check to share in Pyramid View</label>
          </div>
        </div>
      </div>
      <div class="col-xs-6 col-sm-4 col-md-1 bor nop col-date item-tab">
        <div class="col-xs-12 tit-views tbm title-date">Date Created</div>
        <div class="col-xs-12 inf tbm info-date">{{ view.created_at|date:'MM/dd/yy' }}</div>
      </div>
      <div class="col-xs-6 col-md-2 bor nop col-share-link item-tab">
        <div class="col-xs-12 tit-views tbm title-share">Share Link</div>
        <div class="col-xs-12 inf tbm info-share">
          <input type="checkbox" id="public_{{view.id}}" class="btn btn-primary pull-left"
                 mwlConfirmationPopover
                 [title]="'Are You Sure?'"
                 [popoverMessage]=" view.public ? 'You are about to choose private access. The password will be required for anyone who has the link to make this TrialLine view visible. Do you wish to continue?':'You are about to make this TrialLine view visible to anyone who has the link. Do you want to continue?'"
                 [confirmText] ="'Yes'"
                 [cancelText] ="'No'"
                 placement="left"
                 (confirm)="accessLine(view)"
                 [checked]="view.public"
                 [(ngModel)]="view.public"
                 (click)="publicAccess();"
                 >
          <label for="public_{{view.id}}">{{view.public ? 'Disable public access?' : 'Enable public visibility?' }}</label>
          <div class="input-group input-group-sm">
            <input type="text" name="text" class="form-control"
                   [attr.value]="appUrl + 'view/' + view.uuid + '?share=true'"  readonly>
            <span class="input-group-btn">
                <button class="btn blue" type="button" (click)="copyValue(view, 'url')">Copy</button>
            </span>
          </div>

        </div>
      </div>
      <div class="col-xs-6 col-md-2 bor nop col-link item-tab">
        <div class="col-xs-12 tit-views tbm title-link">Link Password</div>
        <div class="col-xs-12 inf tbm info-link">
          <div class="input-group input-group-sm ">
            <input type="text" name="password" class="form-control password"
                   [attr.value]="view.password">
            <span class="input-group-btn">
                          <button (click)="copyValue(view, 'password')" class="btn blue" type="button">Copy</button>
                      </span>
            <span class="input-group-btn">
                          <button (click)="generatePassword(view)" class="btn blue" type="button">New Pass</button>
                      </span>
          </div>
        </div>
      </div>
      <div class="col-xs-6 col-md-1 bor nop col-set item-tab">
        <div class="col-xs-12 tit-views tbm title-set">Set iFrame Dimensions (px)</div>
        <div class="col-xs-12 inf tbm info-set">
          <div class="input-group input-group-sm">
            <div class="form-inline">
              <div class="form-group col-xs-12 col-sm-6 inf-inp">
                <label for="height" class="text-muted col-xs-12" style="font-size: 10px">Height</label>
                <input #height (change)="toasts.info('iFrame embed code height changed')"
                       id="height" type="number" class="form-control input-sm col-xs-6"
                       placeholder="height" value="500">
              </div>
              <div class="form-group col-xs-12 col-sm-6 inf-inp">
                <label for="width" class="text-muted col-xs-12" style="font-size: 10px">Width</label>
                <input #width (change)="toasts.info('iFrame embed code width changed')"
                       id="width" type="number" class="form-control input-sm col-xs-6"
                       placeholder="width">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-6 col-md-2 bor nop col-frame item-tab">
        <div class="col-xs-12 tit-views tbm title-frame">iFrame Embed Code</div>
        <div class="col-xs-12 inf tbm info-frame">
          <div class="input-group input-group-sm">
            <input #embed type="text" name="frame" class="form-control"
                   [attr.value]="'<iframe src=\'' + appUrl + 'view/' + view.uuid + '?pass=' +
                                    view.password + '\' width=\'' + (! width.value ? 600 : width.value ) +
                                     'px\' height=\'' + (! height.value ? 500 : height.value )  + 'px\'></iframe>'"
            >
            <span class="input-group-btn">
                          <button (click)="toClipboard(embed.value)" class="btn blue" type="button">Copy</button>
                      </span>
          </div>
        </div>
      </div>
      <div class="col-xs-6 col-sm-12 col-md-1 bor nop col-share item-tab">
        <div (click)="sharingView(view.id)" class="col-xs-12 tit-views tbm title-remove">Share</div>
        <div class="col-xs-12 inf tbm info-remove">
          <a (click)="sharingView(view.id)" class="btn blue btn-outline btn-delete"
             type="button" name="share"
             data-toggle="tooltip" title="Share"
          >Share
          </a>
        </div>
      </div>
      <div class="col-xs-6 col-sm-12 col-md-1 bor nop col-remove item-tab">
        <div class="col-xs-12 tit-views tbm title-remove">Remove</div>
        <div class="col-xs-12 inf tbm info-remove">
          <a  *ngIf="view.viewsort != 1" class="btn red btn-outline btn-delete"
             mwlConfirmationPopover
             [title]="'Please confirm'"
             [popoverMessage]="'Do you want to proceed?'"
             placement="left"
             (confirm)="deleteView(view)">
            Delete
          </a>
        </div>
      </div>
    </div>
    </div>
  </div>
</div>
<app-trialline-manage-save-view [share]="shareView" [shareId]="shareId" [triallineId]="triallineId"
                                [categories]="selectedCategories"></app-trialline-manage-save-view>
<!--<div class="col-sm-12 time-line-content">-->
<!--<div class="table-scrollable">-->
<!--<table class="table table-striped table-bordered table-advance table-hover">-->
<!--<thead>-->
<!--<tr>-->
<!--<th>#</th>-->
<!--<th class="col-md-3">View Name</th>-->
<!--<th class="col-md-1">Date Created</th>-->
<!--<th class="col-md-2">Share Link</th>-->
<!--<th class="col-md-2">Link Password</th>-->
<!--<th class="col-md-1">Set iFrame Dimensions (px)</th>-->
<!--<th class="col-md-2">iFrame Embed Code</th>-->
<!--<th class="col-md-1">Remove</th>-->
<!--</tr>-->
<!--</thead>-->
<!--<tbody>-->
<!--<tr *ngFor="let view of views">-->
<!--<td></td>-->
<!--<td></td>-->
<!--<td></td>-->
<!--<td>-->
<!--<div class="input-group input-group-sm">-->
<!--<input type="text" name="text" class="form-control" [attr.value]="appUrl + 'view/' + view.uuid"
 readonly>-->
<!--<span class="input-group-btn">-->
<!--<button class="btn blue" type="button" (click)="copyValue(view, 'url')">Copy</button>-->
<!--</span>-->
<!--</div>-->
<!--</td>-->
<!--<td>-->
<!--<div class="input-group input-group-sm">-->
<!--<input type="text" name="password" class="form-control password" [attr.value]="view.password">-->
<!--<span class="input-group-btn">-->
<!--<button (click)="copyValue(view, 'password')" class="btn blue" type="button">Copy</button>-->
<!--</span>-->
<!--<span class="input-group-btn">-->
<!--<button (click)="generatePassword(view)" class="btn blue" type="button">New Pass</button>-->
<!--</span>-->
<!--</div>-->
<!--</td>-->
<!--<td>-->
<!--<div class="input-group input-group-sm">-->
<!--<div class="form-inline">-->
<!--<div class="form-group" style="width: 45%">-->
<!--<label for="height" class="text-muted" style="font-size: 10px">Height</label>-->
<!--<input #height (change)="toasts.info('iFrame embed code height changed')" id="height"
 type="number" class="form-control input-sm" placeholder="height" value="500">-->
<!--</div>-->
<!--<div class="form-group" style="width: 45%">-->
<!--<label for="width" class="text-muted" style="font-size: 10px">Width</label>-->
<!--<input #width (change)="toasts.info('iFrame embed code width changed')" id="width"
 type="number" class="form-control input-sm" placeholder="width">-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--</td>-->
<!--<td>-->
<!--<div class="input-group input-group-sm">-->
<!--<input #embed type="text" name="frame" class="form-control"-->
<!--[attr.value]="'<iframe src=\'' + appUrl + 'view/' + view.uuid + '?pass=' +
 view.password + '\' width=\'' + (! width.value ? 600 : width.value ) + 'px\' height=\'' +
  (! height.value ? 500 : height.value )  + 'px\'></iframe>'"-->
<!--&gt;-->
<!--<span class="input-group-btn">-->
<!--<button (click)="toClipboard(embed.value)" class="btn blue" type="button">Copy</button>-->
<!--</span>-->
<!--</div>-->
<!--</td>-->

<!--<td>-->

<!--</td>-->
<!--</tr>-->
<!--</tbody>-->
<!--</table>-->
<!--</div>-->
<!--</div>-->

