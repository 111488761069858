import {
  AfterViewInit,
  Component,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ITimepickerEvent } from "./it-timepicker.interface";

declare var moment: any;
const CUSTOM_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CustomDatetime),
  multi: true
};

@Component({
  selector: 'custom-datetime',
  providers: [CUSTOM_ACCESSOR],
  template: `
        <div class="form-inline ng2-datetime">
            <div [ngClass]="{ 'form-group': true, 'input-group': !datepickerOptions.hideIcon, 'date': true }">
                <input id="{{idDatePicker}}" type="text" class="form-control {{name}}"
                       [attr.readonly]="readonly"
                       [attr.required]="required"
                       [attr.placeholder]="datepickerOptions.placeholder || 'Choose date'"
                       [attr.tabindex]="tabindex"
                       [(ngModel)]="dateModel"
                       (blur)="onTouched()"
                       [textMask]="{mask: dateMask, guide: false}"
                       (change)="onDateModelChange($event)"
                       (keyup)="checkEmptyValue($event)"
                       />
                <div [hidden]="datepickerOptions.hideIcon || datepickerOptions === false"
                     (click)="showDatepicker()"
                     class="input-group-addon">
                    <span [ngClass]="datepickerOptions.icon || 'glyphicon glyphicon-th'"></span>
                </div>
            </div>
            <div [ngClass]="{ 'form-group': true, 'input-group': !timepickerOptions.hideIcon, 'bootstrap-timepicker timepicker': true }">
                <input id="{{idTimePicker}}" type="text" class="form-control input-small"
                       [attr.readonly]="readonly"
                       [attr.required]="required"
                       [attr.placeholder]="timepickerOptions.placeholder || 'Set time'"
                       [attr.tabindex]="tabindex"
                       [(ngModel)]="timeModel"
                       (focus)="showTimepicker()"
                       (blur)="onTouched()"
                       (change)="onDateModelChange($event)"
                       (keyup)="checkEmptyValue($event)">
                <span [hidden]="timepickerOptions.hideIcon || false" class="input-group-addon">
                    <i [ngClass]="timepickerOptions.icon || 'glyphicon glyphicon-time'"></i>
                </span>
            </div>
            <button *ngIf="hasClearButton" type="button" (click)="clearModels()">Clear</button>
        </div>
    `,
  styles: [
    '.ng2-datetime *[hidden] { display: none; }'
  ]
})

export class CustomDatetime implements ControlValueAccessor, AfterViewInit, OnDestroy, OnChanges {
  @Input('timepicker') timepickerOptions: any = {};
  @Input('datepicker') datepickerOptions: any = {};
  @Input('name') name: string;
  @Input('hasClearButton') hasClearButton: boolean;
  @Input() readonly: boolean;
  @Input() required: boolean;
  @Input() tabindex: string;

  date: Date; // ngModel
  dateModel: string;
  timeModel: string;
  dateTimeValue: Date;
  dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

  // instances  
  datepicker: any;
  timepicker: any;

  idDatePicker: string = uniqueId('q-datepicker_');
  idTimePicker: string = uniqueId('q-timepicker_');

  onChange = (_: any) => {
  }

  @HostListener('blur')
  onTouched = () => {
  }

  @HostBinding('attr.tabindex')
  get tabindexAttr(): string | undefined {
    return this.tabindex === undefined ? '-1' : undefined;
  }

  ngAfterViewInit() {
    this.init();
  }

  ngOnDestroy() {
    if (this.datepicker) {
      this.datepicker.datepicker('destroy');
    }
    if (this.timepicker) {
      this.timepicker.timepicker('remove');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes['datepickerOptions'] && this.datepicker) {
        this.datepicker.datepicker('destroy');

        if (changes['datepickerOptions'].currentValue) {
          this.datepicker = null;
          this.init();
        } else if (changes['datepickerOptions'].currentValue === false) {
          this.datepicker.remove();
        }
      }
      if (changes['timepickerOptions'] && this.timepicker) {
        this.timepicker.timepicker('remove');

        if (changes['timepickerOptions'].currentValue) {
          this.timepicker = null;
          this.init();
        } else if (changes['timepickerOptions'].currentValue === false) {
          this.timepicker.parent().remove();
        }
      }
    }
  }

  writeValue(value: any) {
    this.date = value;
    this.dateTimeValue = value;
    if (isDate(this.date)) {
      setTimeout(() => {
        this.updateModel(this.date);
      }, 0);
    } else {
      this.clearModels();
    }
  }

  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  checkEmptyValue(e: any) {
    const value = e.target.value;
    if (value === '' && (
      this.timepickerOptions === false ||
      this.datepickerOptions === false ||
      (this.timeModel === '' && this.dateModel === '')
    )) {
      this.onChange(undefined);
    }
  }

  clearModels() {
    this.onChange(undefined);
    if (this.timepicker) {
      this.timepicker.timepicker('setTime', null);
    }
    this.updateDatepicker(null);
  }

  showTimepicker() {
    this.timepicker.timepicker('showWidget');
  }

  showDatepicker() {
    this.datepicker.datepicker('show');
  }

  onDateModelChange(value: any) {
    //console.log('I am in onDateModelChange', this.datepickerOptions)
    const d = parseInputDate(value,this.datepickerOptions);
    if (!d) {
      return;
    }

    //console.log('print d object', d)


    //this.dateModel = `${d.month}/${d.day}/${d.year}`;
    //this.updateModel(new Date(d.year, d.month, d.day));
    this.updateDatepicker(`${d.month}/${d.day}/${d.year}`);
    const newDate = new Date(d.year, d.month - 1, d.day);
    if (this.date && isDate(this.date)) {
      newDate.setHours(this.date.getHours());
      newDate.setMinutes(this.date.getMinutes());
      newDate.setSeconds(this.date.getSeconds());
    }

    this.date = newDate;
    this.onChange(this.date);

  }


  //////////////////////////////////
  convertForDatabasePicker(currentData, dateFormat, checkedDays) { // request should be mm/dd/yyyy
    let caseCondition = dateFormat;
    //console.log('form date in function call: ', currentData);



    let splitDate = currentData.split('/');
    //console.log('format request currentData: ', currentData)
    let dateformatForDB = '';
    switch (dateFormat) { // "11/30/2022"
      case 'yyyy/mm/dd':
      case 'yyyy/mm':
        if (checkedDays) { 
          //yyyy/mm
          dateformatForDB = splitDate[1] + '/' + splitDate[0];
        } else {  //yyyy/dd/mm
          dateformatForDB = splitDate[1] + '/' + splitDate[2] + '/' + splitDate[0];
        }
        break;
      case 'dd/mm/yyyy':
      case 'mm/yyyy':
        if (checkedDays) {
          dateformatForDB = splitDate[0] + '/' + splitDate[1];
        } else {
          dateformatForDB = splitDate[1] + '/' + splitDate[0] + '/' + splitDate[2];
        }
        break;
      case 'mm/dd/yyyy':
      case 'mm/yyyy':
        if (checkedDays) {  // mm/dd/yyyy
          dateformatForDB = splitDate[0] + '/' + splitDate[1];
        } else {
          dateformatForDB = splitDate[0] + '/' + splitDate[1] + '/' + splitDate[2];
        }

        break;
    }

    return dateformatForDB;
  }

  private updateDataMask(dformat) {
    
    if(dformat == 'yyyy/mm/dd') {
      this.dateMask = [/\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/];
    } else if(dformat == 'yyyy/mm') {
      this.dateMask = [/\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/];
    } else if(dformat == 'mm/yyyy') {
      this.dateMask = [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    } else {
      this.dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    }

  }

  private init(): void { 
    
    if (this.datepickerOptions.minViewMode) {      
      this.dateMask = [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    }

    if (!this.datepicker && this.datepickerOptions !== false) {
      
      //console.log('datepicker options:',this.datepickerOptions);
      
      let options = jQuery.extend({ enableOnReadonly: !this.readonly }, this.datepickerOptions);
      //console.log('datepicker id:', '#' + this.idDatePicker)
      
      this.datepicker = (<any>$('#' + this.idDatePicker)).datepicker(options)
      
      this.updateDataMask(options.format);
      
      //console.log('datepicker id:', '#' + this.idDatePicker)

      this.datepicker.on('change', (e: any) => {
    
        if (e.target.value) {
          //console.log('e.target.value',e.target.value)
          //let newDate: Date = checkDate(this.convertForDatabasePicker(e.target.value, this.datepickerOptions.format, this.datepickerOptions.halfformat));
          let newDate: Date = checkDate(this.convertForDatabasePicker(e.target.value, this.datepickerOptions.format, this.datepickerOptions.halfformat));
          //console.log('newDate SetLocal Variable:',newDate)
          if (isDate(this.date) && isDate(newDate)) {

            // get hours/minutes
            newDate.setHours(this.date.getHours());
            newDate.setMinutes(this.date.getMinutes());
            newDate.setSeconds(this.date.getSeconds());
          }
          localStorage.setItem("datePickerdate", newDate.toString())
          if (newDate) {
            this.date = newDate;
            this.dateTimeValue = newDate;
            this.onChange(newDate);
          }
        }

      });
    } else if (this.datepickerOptions === false) {
      (<any>$('#' + this.idDatePicker)).remove();
    }
    
    if (!this.timepicker && this.timepickerOptions !== false) {

      let options = jQuery.extend({ defaultTime: false }, this.timepickerOptions);
      this.timepicker = (<any>$('#' + this.idTimePicker)).timepicker(options);

      this.timepicker.on('change', (event: any) => {

        const [hours, minutes, seconds] = event.target.value.split(':');
        
        if (!isDate(this.date)) {

          this.date = new Date();
          this.updateDatepicker(this.date);
        }

        let newDate;
        
        let datepickerName = <any>this.name.split('_');
        if (datepickerName[0] == 'start') {
          datepickerName = 'start_date';
        } else {
          datepickerName = 'end_date';
        }

        //console.log('datepickername', datepickerName)
        if(localStorage.getItem("datePickerdate") !== null) {
        //if (jQuery('.event-form .' + datepickerName).val() != '') {
          newDate = localStorage.getItem("datePickerdate") +" "+ hours +":"+minutes +":"+seconds
          //newDate = jQuery('.event-form .' + datepickerName).val() + " " + hours + ":" + minutes + ":" + seconds
        } else {
          newDate = this.date;
        }

        //console.log('newDate', newDate)
        
        let dateTime1 = moment(newDate).format("MM/DD/YYYY HH:mm:ss");
        this.date = dateTime1

        //console.log('final date: this.date, dateTime1', this.date, dateTime1)
        
        this.onChange(this.date);
      });

    } else if (this.timepickerOptions === false) {
      (<any>$('#' + this.idTimePicker)).parent().remove();
    }

    this.updateModel(this.date);
  }

  private updateModel(date: Date): void {

    //console.log('updateModel date:',date)
    this.updateDatepicker(date);
    //console.log("date in update model",date);

    // update timepicker
    if (this.timepicker !== undefined && isDate(date)) {
      let hours = date.getHours();
      if (this.timepickerOptions.showMeridian) {
        // Convert 24 to 12 hour system
        hours = (hours === 0 || hours === 12) ? 12 : hours % 12;
      }
      const meridian = date.getHours() >= 12 ? ' PM' : ' AM';
      var dtetime = $('#end_date_time').val();
      const offset = date.getTimezoneOffset() / -60;
      //console.log("offset", offset);

      // var minutes= 0
      // if(Math.sign(offset) == -1) {
      //   minutes = +1
      // } else if(Math.sign(offset) == 0) {
      //   minutes = 0
      // }

      const time =
        this.pad(hours) + ':' +
        this.pad(this.date.getMinutes()) + ':' +
        this.pad(this.date.getSeconds()) +
        (this.timepickerOptions.showMeridian || this.timepickerOptions.showMeridian === undefined
          ? meridian : '');
      this.timepicker.timepicker('setTime', time);
      this.timeModel = time; // fix initial empty timeModel bug
    }
  }

  private updateDatepicker(date?: any) {
    if (this.datepicker !== undefined) {
      this.datepicker.datepicker('update', date);
    }
  }

  private pad(value: any): string {
    return value.toString().length < 2 ? '0' + value : value.toString();
  }
}

let id = 0;
function uniqueId(prefix: string): string {
  return prefix + ++id;
}

function isDate(obj: any) {
  return Object.prototype.toString.call(obj) === '[object Date]';
}

function parseInputDate(value, datepickerOptions) {
  //console.log('datepickeroption in parseInputDate', datepickerOptions)
  
  //console.log('parseInputDate Value',value)
  const parsed = value.toString().replace(/[^0-9.]/g, "");
  //console.log('parsed', parsed)
  //console.log('parsed.length', parsed.length)

  const length = 8; // mmddyyyy
  if (parsed.length !== length) {
    return false;
  }
  //console.log('datepickerOptions.format', datepickerOptions.format)
  // let month = parsed.toString().substr(0, 2);
  // let day = parsed.toString().substr(2, 2);
  // let year = parsed.toString().substr(4, 4);

  let month;
  let day; 
  let year;
 if (datepickerOptions.format == 'mm/dd/yyyy'){// mmddyyyy
   month = parsed.toString().substr(0, 2);
   day = parsed.toString().substr(2, 2);
  year = parsed.toString().substr(4, 4);
 }

 if (datepickerOptions.format == 'dd/mm/yyyy'){//ddmmyyyy
  day = parsed.toString().substr(0, 2);
  month = parsed.toString().substr(2, 2);
  year = parsed.toString().substr(4, 4);
 }
  
 if(datepickerOptions.format == 'yyyy/mm/dd'){//yyyymmdd
  //console.log('yyyy/mm/dd')
  year = parsed.toString().substr(0, 4);
  month = parsed.toString().substr(4, 2);
  day = parsed.toString().substr(6, 2);
 }
 

  month = parseInt(month) > 12 ? 12 : month;
  const lastDayOfMonth = getLastDayOfMonth(day, month, year);
  day = parseInt(day) > lastDayOfMonth ? lastDayOfMonth : day;
  return { day, month, year };
}

function getLastDayOfMonth(day, month, year) {
  const date = new Date(year, month, 0);
  return date.getDate();
}
function checkDate(date) {
  var dateLen = date.split("/")
  //console.log("dateLen", dateLen)
  if (dateLen.length == 2) {
    return dateLen[0] + "/01/" + dateLen[1]
  }
  return date
}


