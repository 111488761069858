                    <div class="form-body">
                      <div class="portlet-body">
                        <div class="table-scrollable table-scrollable-borderless">
                          <table class="table table-hover editable"  *ngIf="triallinetagLength">
                            <thead>
                            <tr class="uppercase">
                              <th></th>
                              <th (click)="reorder('tag_name')"  class="filter-by">
                                Name <span *ngIf="currentStatus=='tag_name'" class="e2e-inner-html-bound" [style.opacity]="order == 'tag_name' ? '1' : '.3'"
                                           [innerHTML]="getSortIcon('tag_name')"></span></th>
                              <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let tag of triallinetag">
                              <td></td>
                              <td>
                                <span id="tag_nameonly_{{tag.id}}" >{{ tag.tag_name }}</span>
                                <input type="text" id="tag_name_{{tag.id}}" name="tagname" value="{{tag.tag_name}}" class="form-control" style='display: none;' (keyup.enter)="updatetag(tag,tag.id)" ><br>
                              </td>
                              <td>
                                <a id="btn_edit_{{tag.id}}" class="btn blue btn-outline btn-w80" (click)="edittag(tag,tag.id)">Edit</a>

                                <a id="btn_delete_{{tag.id}}" class="btn red btn-outline btn-w80" (click)="deletetag(tag.id)">Delete</a>

                                <a id="btn_update_{{tag.id}}" class="btn green btn-outline btn-w80" style='display: none;margin-top:7px;'
                                (click)="updatetag(tag,tag.id)">Update</a>
                                <a id="btn_cancel_{{tag.id}}" class="btn blue btn-outline btn-w80" style='display:none;margin-top: 7px;'  (click)="canceledittag(tag.id)" >Cancel</a>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                          <div *ngIf="!triallinetagLength">
                            <span>At the moment the trialline has no tags</span>
                          </div>
                        </div>
                      </div>
                    </div>
