
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {env} from '../../../../.env';
import {Router} from "@angular/router";
import {EventEmitter, Output, Directive } from "@angular/core";
import {HttpEvent, HttpFinishEvent, HttpStartEvent} from "./http.events";
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from "../../auth/shared/auth.service";





@Directive()
export abstract class Rest
{
  @Output() events: EventEmitter<HttpEvent> = new EventEmitter;

  protected apiUrl: string;

  constructor (protected http: HttpClient, protected auth: AuthService, protected router: Router) {
    this.setApiUrl();
  }


  protected intercept(method: string, url: string, body?: any, options?: any, events: boolean = true) : Observable<HttpEvent>
  {
    if (events) {

      let eventStart = new HttpStartEvent(url);
      this.events.next(eventStart);
    }

    return this.createHttpEntity(method, url, body, options).pipe(map((response: any): any => {

      if (events) {

        let eventFinish = new HttpFinishEvent(url);
        this.events.next(eventFinish);
      }
      return response;
    }),catchError((error: Response) => {

      if (events) {
        let eventFinish = new HttpFinishEvent(url);
        this.events.next(eventFinish);
      }

      if (error.status == 401) {
        this.auth.logout();
        this.router.navigate(['/']);
      }

      if (error.status == 503) {
        this.auth.logout();
        this.router.navigate(['/maintenance']);
      }

      return observableThrowError(error);
    }),);
  }


  private createHttpEntity(method: string, url: string, body?: any, options?: any ): Observable<Response>
  {
    let requestOptions = this.getDefaultRequestOptions(options);

    if (url.startsWith('/')) {
      url = url.substr(1);
    }

    if (method == 'get' || method == 'delete') {
      return eval('this.http.' + method + '(this.apiUrl + url, requestOptions)');
    }

    return eval('this.http.' + method + '(this.apiUrl + url, body, requestOptions)')
  }



  protected getDefaultRequestOptions(options?: any)
  {
    if (! options) {
      options = {};
    }
    if (! options.headers) {

      if (this.auth.check()) {
        options.headers = new HttpHeaders({'Accept': 'application/json', 'Authorization': 'Bearer ' + this.auth.token()})
      } else {
        options.headers = new HttpHeaders({'Accept': 'application/json'})
      }
    }

    return options;
  }

  private setApiUrl(): void
  {
    this.apiUrl = env.apiUrl;
  }
}
