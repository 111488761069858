import {Router} from "@angular/router";
import {HttpResponse} from "@angular/common/http";
import {Component, OnInit} from '@angular/core';
import {User} from "../shared/user.model";
import {AuthResourceService} from "../shared/auth-resoruce.service";
import {ToastrService} from 'ngx-toastr';


declare var jQuery: any;

@Component({
  selector: 'app-auth-register',
  templateUrl: './register.component.html'
})
export class RegisterComponent {
  errors: any = {};
  processing: boolean = false;
  user: any;
  practice_areas: any [];
  how_founds: any [];

  captcha: boolean = false;
  formdata: any;
  firm: string;
  captcherror: string;

  constructor(
    protected authResourceService: AuthResourceService,
    protected router: Router,
    protected toasts: ToastrService
  ) {
    this.practice_areas = [
      'Choose Your Practice Area',
      'Antitrust Litigation',
      'Appellate',
      'Bankruptcy',
      'Business Litigation',
      'Civil Rights',
      'Class Action/Mass Torts',
      'Construction Litigation',
      'Criminal Defense',
      'Employment & Labor',
      'Environmental Litigation',
      'Estate, Trusts & Probate',
      'Family Law',
      'Immigration',
      'Insurance Defense',
      'Intellectual Property',
      'Personal Injury - General',
      'Personal Injury - Medical Malpractice',
      'Personal Injury – Products Liability',
      'Professional Liability',
      'Real Estate',
      'Securities Law',
      'Social Security Disability',
      'Workers\' Compensation',
      'I am not a Lawyer',
      'Other (Please explain)'
    ];
    this.how_founds = [
      'How did you find out about TrialLine?',
      'ABA Publication',
      'Attorney Referral',
      'CLE',
      'Clio',
      'Email Invitation',
      'Exhibitor Booth',
      'Facebook',
      'Google Search',
      'Instagram',
      'LinkedIn',
      'ListServe',
      'Twitter',
      'Other (Please explain)'
    ];
  }

  resolved(captchaResponse: string) {
    this.captcha = true;
  }

  register(form: any) {
    this.formdata = form.value;
    this.processing = true;

    this.authResourceService.register(form.value).subscribe(
      (user: User) => this.handleRegister(user, form),
      (error: Response) => this.handleError(error)
    );
  }

  handleRegister(user: any, form: any) {
    form.reset();
    this.processing = false;
    this.user = user;
    jQuery('#congratulations-modal').modal('show');
  }

  handleError(error: any) {
    this.processing = false;
    if (error.status == 422) {
      this.errors = error.error;

      if (this.formdata.firm == '' || this.formdata.firm == null) {
        this.firm = 'The company field is required.';
      } else {
        this.firm = '';
      }
      if((this.formdata.firm=='')||(this.formdata.firm==null))
      {
        this.firm='The company field is required.';
      }else{this.firm='';}
      if(!this.formdata.rules)
      {
        this.errors.rules='You should be agree with Privacy Policy.';
      }else{this.errors.rules='';
    }
      if(!this.captcha)
      {
        this.captcherror='Please complete the security reCAPTCHA.'
      }else
      {
        this.captcherror=''
      }
      try {
        for (let key in this.errors) {
          let value = this.errors[key];
          this.toasts.error(value.join(' '), key);
        }
      } catch (e) {
        console.log(e);
      }
    } else if(error.status == 401) {
      this.toasts.error(error.error.error.statusText);
    } else {
      this.toasts.error(error.statusText);
    }
  }

  prepareValue(value) {
    switch (value) {
      case 'Other (Please explain)':
        value = '';
        break;
      case 'How did you find out about TrialLine?':
        value = '';
        break;
      case 'Choose Your Practice Area':
        value = '';
        break;
    }
    return value;
  }
}
