import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";

import {ProfileResourceService} from "app/main/profile/shared/profile-resoruce.service";
import {UserProfile} from "./user.model";

@Injectable()
export class ProfileResolver implements Resolve<UserProfile>
{
  constructor(public profileResource: ProfileResourceService) {}

  resolve(route: ActivatedRouteSnapshot): Promise<UserProfile>
  {
      return this.profileResource.getUser().toPromise().then((user: UserProfile) => {
        return user;});
       // .catch((error) => {});
  }
}
