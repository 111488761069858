import { Component, OnInit, Input } from '@angular/core';
import {EventsResourceService} from "../../events/shared/events-resoruce.service";
import {ToastrService} from 'ngx-toastr';
import {Modal} from 'ngx-modialog-7/plugins/bootstrap';
import {DomSanitizer} from "@angular/platform-browser";

declare var jQuery:any;
@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit
{
  @Input() triallineid ;
  triallinetag: any;
  triallineId: any;
  triallinetagLength: boolean = false;
  data=[];

  orderVal: string;
  reverse: boolean = true;
  order:string = 'name';
  currentStatus:string = '';

  constructor(public eventsResourceService:EventsResourceService,
              public toasts:ToastrService,
              public modal: Modal,
              private _sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.triallineId=this.triallineid
     this.eventsResourceService.getTags(this.triallineid).subscribe(response => {
       this.triallinetag = response;
       this.triallinetagLength = this.triallinetag.length > 0
    })
  }

  edittag(data,id) {
    $("#tag_nameonly_"+id).css("display","none");
    $("#tag_name_"+id).css("display","");
    $("#btn_edit_"+id).css("display","none");
    $("#btn_delete_"+id).css("display","none");
    $("#btn_update_"+id).css("display","");
    $("#btn_cancel_"+id).css("display","");
  }

  canceledittag(id) {
   $("#tag_nameonly_"+id).css("display","");
   $("#tag_name_"+id).css("display","none");
   $("#btn_edit_"+id).css("display","");
   $("#btn_delete_"+id).css("display","");
   $("#btn_update_"+id).css("display","none");
   $("#btn_cancel_"+id).css("display","none");
  }

  updatetag(value,id) {
    this.data=[];
    let tagNameId = jQuery("#tag_name_"+id).val().trim()
    if(tagNameId == '') {
      this.toasts.error('Please enter tag name')
    }else {
      let count = 0
      for(let i=0; i<this.triallinetag.length; i++) {
        if((this.triallinetag[i].tag_name == tagNameId ) && (id!=this.triallinetag[i].id)) {
          count++;
        }
      }
        if(count > 0) {
          this.toasts.error('This tag already available')
        }else {
            this.data.push({"tag_id":value.id, "tag_name":tagNameId, "trialline_id": this.triallineId})

          this.eventsResourceService.updatetag(this.triallineId,this.data)
            .subscribe(response => {

              let data = response.body.tagDatas
              this.toasts.success('Tag updated succesfully')
              this.triallinetag = data
              $("#tag_nameonly_"+id).css("display","");
              $("#tag_name_"+id).css("display","none");
              $("#btn_edit_"+id).css("display","");
              $("#btn_delete_"+id).css("display","");
              $("#btn_update_"+id).css("display","none");
              $("#btn_cancel_"+id).css("display","none");
            });
        }
    }
  }

  deletetag(id) {
    const dialogRef = this.modal.confirm()
    .size('lg')
    .isBlocking(true)
    .showClose(true)
    .keyboard(27)
    .title('Confirmation')
    .body('Are you sure you want to delete this tag?')
    .open()
    dialogRef.result
    .catch((err: any) => {
      this.modelClose();
      console.log('ERROR: ' + err)
    })
    .then((dialog: any) => {
      this.modelClose()
      if(dialog){
        let data=[];
        data.push({"tag_id":id,"trialline_id": this.triallineId})
        this.eventsResourceService.tagdelete(this.triallineId,data).subscribe(response => {
            this.toasts.success('Tag deleted succesfully')
            let data = response.body.tagDatas
            this.triallinetag = data
          });
      }
    });
    this.modelAction();
  }

  modelAction() {
    $('bs-modal-container').addClass('show-midal-element');
  }

  modelClose() {
    $('body').removeClass('modal-open');
  }

  reorder(column: string) {
    this.orderVal = this.orderVal === 'DESC' ? 'ASC' : 'DESC';
    this.reverse = ! this.reverse;
    this.orderVal === 'DESC' ? this.triallinetag.sort(this.dynamicSort('tag_name')): this.triallinetag.sort(this.dynamicSort('-tag_name'));
    this.currentStatus = column;
  }

  getSortIcon(column: string) {
    return this._sanitizer.bypassSecurityTrustHtml('<i class="fa fa-sort' +
      ( '-' + (this.reverse ? 'asc' : 'desc') )  + '"' +
      ' aria-hidden="true"></i>');
  }

  /**
   * Function to sort alphabetically an array of objects by some specific key.
   *
   * @param {String} property Key of the object to sort.
   */
  dynamicSort(property) {
    let sortOrder = 1;

    if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a,b) {
      if(sortOrder == -1){
        return b[property].localeCompare(a[property]);
      }else{
        return a[property].localeCompare(b[property]);
      }
    }
  }
}
