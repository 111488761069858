<img src="assets/squares.gif" class="img spinners" alt="spinner" *ngIf="processing" style="opacity: .8;
  position: absolute;
  top: 10px;
  left:50%;">

<div class="content">
  <form #resetPassword="ngForm" class="forget-form" (submit)="send(resetPassword)" novalidate="novalidate" style="display: block;">
    <h3 style="font-family: 'Open Sans', sans-serif !important;">Reset Password.</h3>
    <p style="font-family: 'Open Sans', sans-serif !important; font-size: 14px;"> Enter your e-mail address below to reset your password. </p>
    <div class="form-group" [hubValidator]="errors?.email" transformer="inline">
      <div class="input-icon">
        <i class="fa fa-envelope"></i>
        <input class="form-control" type="text" placeholder="Email"  name="email"> </div>
    </div>
    <div class="form-actions">
      <a [routerLink]="['/login']">
        <button type="button" class="btn grey-salsa btn-outline"> Back to sign in </button>
      </a>
      <button type="submit" class="btn blue pull-right"> Submit </button>
    </div>
    <div class="clearfix"></div>
  </form>
</div>
<div id="invalidmailModal" class="modal fade" role="dialog">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content">
      <!-- <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Modal Header</h4>
      </div> -->
      <div class="modal-body">
        <h4>No active account is associated with that email address.</h4>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>

  </div>
</div>
