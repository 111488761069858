<div class="container page-content-inner page-wrap">
  <div class="row">
    <div class="col-md-12">
      <app-profile-sidebar [user]="user"></app-profile-sidebar>
      <!-- BEGIN PROFILE CONTENT -->
      <div class="profile-content">
        <div class="row">
          <div class="col-md-12">
            <!-- BEGIN PORTLET -->
            <div class="portlet light ">
              <div class="portlet-title tabbable-line">
                <ul class="nav nav-tabs">
                  <li class="active">
                    <a href="#general" data-toggle="tab"> General Info </a>
                  </li>
                  <li>
                    <a href="#billing" data-toggle="tab"> Billing Report </a>
                  </li>
                </ul>
              </div>
              <div class="portlet-body">
                <!--BEGIN TABS-->
                <div class="tab-content">
                  <div class="tab-pane active" id="general">
                    <div class="row" *ngIf="cards && cards.length > 0">
                      <div *ngFor="let card of cards" class="col-md-{{ 12 / cards.length }}">
                        <div class="thumbnail" style="max-width: 300px;">
                          <div class="caption">
                            <div class='col-lg-12'>
                              <span class="glyphicon glyphicon-credit-card"></span>
                              <a (click)="deleteCard(card)" *ngIf="card.last4 != user?.card_last_four"
                                 class="pull-right text-danger" title="Delete this payment method?"><i class="fa fa-trash" aria-hidden="true"></i></a>
                              <span *ngIf="card.last4 == user?.card_last_four" class="pull-right text-info"
                                    style="margin-right: 5px"><i class="fa fa-check" aria-hidden="true"></i>(primary)</span>
                              <a (click)="makePrimary(card)" title="Make this card primary"
                                 *ngIf="card.last4 != user?.card_last_four" class="pull-right text-info" style="margin-right: 5px">
                                <i class="fa fa-check" aria-hidden="true"></i>
                              </a>
                            </div>
                            <div class='col-lg-12'>
                              <div class="">
                                <h4 class="">XXXX XXXX XXXX {{ card.last4 }}</h4>
                              </div>
                              <p class="text-muted">Exp: {{ card.exp_month + '/' + card.exp_year }}</p>
                              <p class="text-muted card-name text-uppercase">{{ card.name }}</p>
                              <h2 class="pull-right card-brand">
                                <i class="fa fa-{{ getBrandShort(card.brand) }}" aria-hidden="true"></i>
                              </h2>
                            </div>
                            <div class="clearfix"></div>
                          </div>
                          <div class="clearfix"></div>
                        </div>
                      </div>
                    </div>
                    <button *ngIf="cards.length < 3" class="btn btn-primary" (click)="openModal()">Add Card</button>

                  </div>
                  <div class="tab-pane" id="billing">
                    <div class="form-group">
                      <select #trialline_id (change)="billingChange(trialline_id.value)" class="form-control">
                        <option value="0">General Billing Report</option>
                        <option *ngFor="let trialline of user?.team?.triallines" value="{{ trialline.id }}">{{ trialline.name }}</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <select #trialline_id_data (change)="billingDateChange(trialline_id_data.value)" class="form-control">
                        <option value="0">All time</option>
                        <option value="3">Last 3 months</option>
                        <option value="6">Last 6 months</option>
                        <option value="12">Last 1 year</option>

                      </select>
                    </div>

                    <!--<a href="#" disabled> Invoices </a>-->

                    <div class="form-group">
                      <a target="_blank" href="{{url}}" class="btn btn-primary">Download</a>
                      <!--<p>You dont have payment</p>-->
                      <table class="table ">
                        <thead>
                        <tr>
                          <th>Date</th>
                          <th>Description</th>
                          <th>Card</th>
                          <th>Amount</th>
                        </tr>
                        </thead>
                        <tbody id="billing-table">

                          <tr *ngFor="let payment of payments">
                            <td>{{ payment.payment_date | date }}</td>
                            <td>
                              {{ getDescription(payment) }}
                              <span *ngIf="payment.trialline && payment.trialline.deleted_at" class="text-muted small">
                                (deleted at: {{ payment.trialline.deleted_at | date:'short' }})
                              </span>
                            </td>
                            <td>{{ payment.card_info }}</td>
                            <td>-${{ payment.amount }}</td>
                          </tr>
                        </tbody>

                      </table>
                    </div>
                  </div>
                </div>
                <!--END TABS-->
              </div>
            </div>
            <!-- END PORTLET -->
          </div>
        </div>
      </div>
      <!-- END PROFILE CONTENT -->
    </div>
  </div>
</div>
<app-add-card [user]="user" (onAdded)="cardAdded($event)" [id_form]="2"></app-add-card>
