import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, OnDestroy} from '@angular/core';
//import { ToastrManager } from 'ng6-toastr-notifications';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from "@angular/router";
import {User} from "../../../auth/shared/user.model";
import {Title} from "@angular/platform-browser";
import {NgForm} from "@angular/forms";
import {env} from "../../../../../.env";
import {ProfileResourceService} from "../../../main/profile/shared/profile-resoruce.service";
import {AuthService} from "../../../auth/shared/auth.service";

declare var jQuery: any;
declare var Stripe:any;

@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-cart.component.scss']
})
export class AddCardComponent implements OnInit, OnDestroy
{
  @Input() user: User;
  @Input() id_form: number;
  @Output() onAdded = new EventEmitter<any>();

  private stripe: any;
  private elements: any;
  private card: any;

  processing: boolean = false;
  privacyAgree = true;

  modalId = '#add-card-modal-new';

  constructor(
    protected toasts: ToastrService,
    protected title: Title,
    protected route: ActivatedRoute,
    protected router: Router,
    protected ref: ChangeDetectorRef,
    protected profileResource: ProfileResourceService,
    protected auth: AuthService
  ) {}

  ngOnInit() {
    this.setupStripe();
  }

  ngOnDestroy() {
    this.card.destroy();
    this.ref.detach();
  }

  setupStripe() {
    this.stripe = Stripe(env.stripeToken);
    if (this.stripe === undefined ){
      return null;
    }
    this.elements = this.stripe.elements();

    this.card = this.elements.create('card', {
      style: {
        base: {
          iconColor: '#666EE8',
          color: '#31325F',
          lineHeight: '40px',
          fontWeight: 300,
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSize: '15px',

          '::placeholder': {
            color: '#CFD7E0',
          },
        },
      }
    });
    this.card.mount('#card-element-new');
  }

  updateCard(r)
  {
    this.profileResource.addCard({token: r.token.id, last4: r.token.card.last4}).subscribe((response: any) => {
      let date = response.body;
      this.processing = false;
        this.toasts.success('Card successfully updated');
        this.ref.detectChanges();
        this.onAdded.emit(date.card);
      }, (error) => {
        console.log(error);
        this.processing = false;
        try{
          if (error.error.error && error.error.error.message) {
            this.toasts.error(error.error.error.message);
          }
        }catch (er) {
          if (error.error && error.error.message) {
            this.toasts.error(error.error.message);
          }
        }
      });
  }

  addCard(form: NgForm) {
    this.processing = true;
    let extra = form.value;
    this.stripe.createToken(this.card, extra).subscribe((result: any) => {
      if (result.token) {
        this.updateCard(result);

      } else if (result.error) {
        this.processing = false;
        this.toasts.error('Error', result.error.message);
      }
    }, (error) => {
      console.log(error);
      this.processing = false;
      try{
        if (error.error.error && error.error.error.message) {
          this.toasts.error(error.error.error.message);
        }
      }catch (er) {
        if (error.error && error.error.message) {
          this.toasts.error(error.error.message);
        }
      }
    });
  }
}
