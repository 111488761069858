import {Component, Input} from '@angular/core';
import {FileModel} from "../../main/file-library/shared/file.model";

@Component({
  selector: 'app-shared-files_present',
  templateUrl: './files-present.component.html'
})
export class FilesPresentComponent {

  @Input() files: FileModel[];
}
