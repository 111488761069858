import {Pipe} from '@angular/core';
import {FileModel} from "../../main/file-library/shared/file.model";

@Pipe({
  name: 'filterFiles',
  pure: false
})
export class FilesPipe {

  transform(files: FileModel[], filterType, filterName) {
    let out: FileModel[];

    out = filterType != '' ? files.filter(file => { return file.type == filterType }) :
      files;
    // out = filterType != '' ? files.filter(file => { return file.type == filterType && file.destination !== 'background'}) :
    //   files.filter(file => { return file.destination !== 'background'});

    out = filterName != '' ? out.filter(file => new RegExp(filterName.toLowerCase()).test(file.title.toLowerCase())) : out;

    return out;
  }
}
