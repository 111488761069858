import {Trialline} from "./trialline.model";
import {UserProfile} from "../profile/shared/user.model";
import {FileModel} from "../file-library/shared/file.model";
import {Category} from "./category.model";
import {Video} from "./video.model";
import {Contacts} from "./contacts.model";

export class Event
{
  id: number;

  title: string;
  description: string;
  backsideimage: string;
  thumbnail: string;
  bill_cost_files: any;
  id_second: number;
  category_id: number;
  start_date: any;
  end_date: any;
  if_start_days: boolean;
  if_end_days: boolean;
  date_start?: any;
  date_end?: any;
  date_created_at?: any;
  trialline_id: number;
  user_id: number;
  provider_name: string;
  provider_phone: string;
  provider_email: string;
  facility_name: string;
  address: string;
  office_number: number;
  city: string;
  state: string;
  postal_code: string;
  bates_number: number;
  exhibit_number?: string;
  notes: string;
  track: string;
  thumbnail_visible: boolean = true;
  notes_visibility: boolean;
  comments_visibility: boolean;
  is_hidden: boolean;
  has_times: boolean;
  has_times_end: boolean;
  thumbnail_attr: string;
  thumbnail_id?:any;
  bill_costs_count?:number;
  bill_costs?: any;
  comments_count?: number;
  category_name?: string;
  videos?: Video[];
  comments?: any;
  views?: any;
  user?: UserProfile;
  files?: FileModel[];
  files_count:number;
  trialline?: Trialline;
  category?: Category;
  contacts?: Contacts[];
  private_files?: any;
  created_at: any;
  updated_at: any;
  thumbnail_encryptid :any;  
  tag_name=[];
  ccontact_name=[];
  tags_count:number;
  view?: any;
  concattagdata?:any;
  dateStartDisplay: any;
  dateEndDisplay: any;
  exhibitNumberDisplay: any;
  dateCreatedDisplay: any;
  thumbnailImage?: any;
  custom_field?:any;
  showHideFile: boolean;
  dateKeyFirstTime:boolean = false;
  real_start_date?: string;
  real_end_date?: string;
  showHideTagFile: boolean;
}
