<div id="new-teammate" class="modal fade bs-modal-md in" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header ">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">Create New Teammate</h4>
      </div>
      <form #createTeammateForm="ngForm" (submit)="createTeammate(createTeammateForm)" role="form-horizontal">
        <div class="modal-body portlet-body form">
          <div class="form-body">
            <div class="form-group">
              <label>First Name</label>
              <input type="text" name="first_name" class="form-control" [ngModel]>
              <span class="help-block" *ngIf="errors?.first_name" style="color: red;">{{ errors?.first_name[0] }}</span>
            </div>
            <div class="form-group">
              <label>Last Name</label>
              <input type="text" name="last_name" class="form-control" [ngModel]>
              <span class="help-block" *ngIf="errors?.last_name" style="color: red;">{{ errors?.last_name[0] }}</span>
            </div>
            <div class="form-group">
              <label>Email</label>
              <input type="email" name="email" class="form-control" [ngModel]>
              <span class="help-block" *ngIf="errors?.email" style="color: red;">{{ errors?.email[0] }}</span>
            </div>
            <div class="form-group">
              <label>Access</label>
              <select  (change)="selectdRole($event.target.value)" name="team_access_level" class="form-control" [ngModel]>
                <!--<option value="3">Owner</option>-->
                <option value="2">Manager</option>
                <option value="1">Staff</option>
                <option value="5" *ngIf='this.team.owner.subscription_plan=="annual" || this.team.owner.subscription_plan=="month" || this.team.owner.subscription_plan=="annual_499_per_year" || this.team.owner.subscription_plan=="month_59_per_month" || this.team.owner.subscription_plan=="trial"'  >Guest User</option>
              </select>
              <span class="help-block" *ngIf="errors?.team_access_level" style="color: red;">{{ errors?.team_access_level[0] }}</span>
            </div>
            <ng-container *ngIf='(this.team.owner.subscription_plan=="annual" || this.team.owner.subscription_plan=="month")|| this.team.owner.isFree==1; else nonFreeUsers'>
              <div class="form-group">
              <label>Subscription Plan</label>
              <select  (change)="selectdPlan($event.target.value)" name="subscription_plan" class="form-control" [ngModel] id='ddlViewBy'>
                <option  *ngIf='selectedlevel'>Subscription is free</option>
                <option value="1" *ngIf='!selectedlevel'>Month-to-Month</option>
                <option value="2" *ngIf='this.team.owner.subscription_plan=="annual" &&!selectedlevel'>Annual</option>
              </select>
              <span class="help-block" *ngIf='suberror' style="color: red;">Select user subscription plan.</span>
            </div>
            </ng-container>
            <ng-template #nonFreeUsers>
            <div class="form-group">
              <label>Subscription Plan</label>
              <select (change)="selectdPlan($event.target.value); teamCoupon = ''; showSaveMessage = false;" name="subscription_plan" class="form-control" [ngModel]="selectedSubscriptionplan" id='ddlViewBy'>
                <option *ngIf='selectedlevel'>Subscription is free</option>
                <option value="3" *ngIf='!selectedlevel && (this.team.owner.subscription_plan=="annual_499_per_year" || this.team.owner.subscription_plan=="month_59_per_month")'>Month-to-Month</option>
                <option value="4" *ngIf='!selectedlevel && (this.team.owner.subscription_plan=="annual_499_per_year")'>Annual</option>
              </select>
              <span class="help-block" *ngIf='suberror' style="color: red;">Select user subscription plan.</span>
            </div>
            </ng-template>
            <div class="form-group">
              <label>Coupon Code</label>
              <div style="display: flex;">
                <input type="text" name="team_coupon" class="form-control" style="width: 40%;" [(ngModel)]="teamCoupon">
                <span class="help-block" *ngIf="errors?.team_coupon" style="color: red;">{{ errors?.team_coupon[0] }}</span>
                <button type="button" style="margin: 0px 5px;" class="btn btn-success" (click)="checkCoupon(createTeammateForm)" [disabled]="isInputEmpty()">
                  <i class="fa fa-check-square" style="margin: 0 !important;"></i>
                </button>
                <label *ngIf="showSaveMessage" style="position: relative; display: block; margin-top: 6px;">
                  (<strong style="color: #cb1111;">{{this.couponObj.percentageDiscount}}%</strong> OFF applied)
                  You save <b style="color: #cb1111;">${{this.couponObj.discountedAmount}}</b> on your subscription plan.
                </label>
              </div>              
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-primary">Create</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div *ngIf='!(this.team.owner.subscription_plan=="annual_499_per_year" || this.team.owner.subscription_plan=="month_59_per_month")' id="subteammate" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h5 class="modal-title">Please, confirm your subscription</h5>
      </div>
      <div class="modal-body">
        <h5 class="modal-title">Please confirm your subscription. You will be subscribed to the <b>{{subscription_plan}}</b>  plan and will be billed <b>{{subscription_amount }}</b>. </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal"> Confirm</button>

      </div>
    </div>
  </div>
</div>

<div *ngIf='(this.team.owner.subscription_plan=="annual_499_per_year" || this.team.owner.subscription_plan=="month_59_per_month") || this.team.owner.isFree==1' class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h5 class="modal-title">Please, confirm your subscription</h5>
      </div>
      <div class="modal-body">
        <h5 class="modal-title">Please confirm your subscription. You will be subscribed to the <b>{{subscription_plan}}</b>  plan and will be billed <b>{{subscription_amount }}</b>. </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal"> Confirm</button>

      </div>
    </div>
  </div>
</div>
