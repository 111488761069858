import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-errors-block',
  templateUrl: './errors-block.component.html'
})
export class ErrorsBlockComponent implements OnChanges
{
  @Input() errors: Object = {};
  @Input() except: Object[] = [];

  public publicErrors: Object[] = [];

  public constructor() {}

  ngOnChanges(changes: SimpleChanges)
  {
    this.publicErrors = [];

    for (let error in this.errors) {

      if (this.except.indexOf(error) == -1) {
        this.publicErrors.push(this.errors[error]);
      }
    }
  }
}
