import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";

import {ProfileResourceService} from "app/main/profile/shared/profile-resoruce.service";

@Injectable()
export class CardsResolver implements Resolve<any>
{
  constructor(protected profileResource: ProfileResourceService) {}

  resolve(route: ActivatedRouteSnapshot): Promise<any>
  {
      return this.profileResource.getCards()
        .toPromise()
        .then((cards: any) => {return cards;})
        .catch((error) => {});
  }
}
