<div class="page-container">
  <div class="time-line-content">
    <a (click)="toggleCategories()" class="btn btn-default btn-icon" style="position:absolute;top:0px;left:0px;z-index:1;">
      Filters
      <span id="notification" style="color: red;" [style.display]="isAllChecked() ? 'none' : 'inline-block'">*</span>
      <i class="fa fa-angle-down"></i><br />
    </a>
    <div class="btn-group-vertical view-by-login">
      <div class="btn btn-default btn-icon hidden-xs">
        <img src="assets/squares.gif" alt="spinner" *ngIf="disabled" style="max-height: 25px; max-width: 34px; opacity: .6;">
        <label *ngIf="! disabled" class="switch">
            <input [disabled]="disabled" type="checkbox" [(ngModel)]="simpleView" (change)="changedView()">
            <span class="slider round"></span>
          </label>
        <div class="btn-text">Carousel <br> view</div>
      </div>
    </div>
    <div [class.open]="categories" class="menu-dropdown filters">
      <ul id="filter-dropdown" class="dropdown-menu" style="margin: 0" [style.height]="'660px'">
        <div class="close-filters">
          <a (click)="toggleCategories()" data-toggle-custom="#filter-dropdown">
            <i class="fa fa-times" aria-hidden="true"></i><br />
          </a>
        </div>
        <li>
          <label class="mt-checkbox mt-checkbox-outline">
            <input (change)="all($event)" type="checkbox" [checked]="isAllChecked()"
                   class="filter-select-all" value="all">
            Show All Categories
            <span></span>
          </label>
        </li>
        <li class="divider"></li>
        <li *ngFor="let category of categoriesArray">
          <label class="mt-checkbox mt-checkbox-outline">
            <input (change)="changedCategories()" type="checkbox" [(ngModel)]="category.state"
                   [attr.value]="category.id" class="filter-select"> {{ category.name }}
            <span></span>
          </label>
        </li>
      </ul>
    </div>
    <app-shared-trialline *ngIf="!simpleView" (onMore)="more($event)" [events]="shownEvents"
                          [trialline]="trialline" [password]="password"
                          [commentsButton]="false" [editButton]="false"></app-shared-trialline>
    <app-shared-trialline-mobile *ngIf="simpleView" [events]="events" [trialline]="trialline"
                                 (onMore)="more($event)" [commentsButton]="false" [editButton]="false">
    </app-shared-trialline-mobile>
    <app-trialline-event-more-modal [isView]="true" [viewUuid]="view?.uuid" [viewPassword]="password"
                                                                   [eventsIdsOrder]="eventsIdsOrder" [activeTab]="activeTab" [comments]="false"
                                                                   [event]="event" *ngIf="moreModal"
                                                                   (onClose)="moreModalClosed($event)">
  </app-trialline-event-more-modal>
  </div>
</div>

