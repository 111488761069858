<div id="event-edit-copy-modal" class="modal" role="dialog" style="z-index: 1650;overflow-y: auto;"
     (keyup)='getkeyvalue($event)'>
  <div class="modal-dialog modal-lg" id="ss_copy">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" id='close_copy' data-dismiss="modal" aria-label="Close"
                (click)="closeAddFilesModal()" (close)="closeAddFilesModal()" (click)="hideModelEvent()"><span
          aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">
          {{ (event.id ? '' : 'Create new event ') + (event.title ? event.title : '') }}
        </h4>
      </div>
      <form #eventForm="ngForm" role="form-horizontal" class="event-form" (submit)="saveEventCopy(eventForm);">
        <div class="modal-body portlet-body">
          <div class="panel-group" id="accordion_copy">
            <div class="panel panel-default">
              <div class="panel-heading" data-toggle="collapse" data-parent="#accordion_copy"
                   data-target="#general_copy"
                   (click)='showaccordian_copy(1)'>
                <h4 class="panel-title">
                  <a class="accordion-toggle">
                    GENERAL</a>
                </h4>
              </div>
              <div id="general_copy" class="panel-collapse collapse in">
                <div class="panel-body">
                  <div class="form-body form-date ">
                    <div class="form-group">
                      <label>Title</label>
                      <input type="text" name="title" [ngModel]="event.title" class="form-control"
                             #title="ngModel" placeholder="Enter title here .." autocomplete="off">
                    </div>
                    <div class="row margin-reset">
                      <div class="col-md-8 tab-content-left-col">
                        <div class="form-group">
                          <label>Start Date*</label>
                          <div class="form-inline">
                            <div class="input-group date col-md-4 date-picker-date-from">
                              <!-- <custom-datetime [timepicker]="false" [datepicker]="{maxViewMode: 2, minViewMode: 1,
                               format: 'mm/yyyy', autoclose:true}" *ngIf="if_start_days.checked"
                                               [ngModel]="event.date_start" name="start_date" required>
                              </custom-datetime>
                              <custom-datetime [timepicker]="false" [datepicker]="{maxViewMode: 2,
                               format: 'mm/dd/yyyy', autoclose:true}" [ngModel]="event.date_start" name="start_date"
                                               *ngIf="!if_start_days.checked" required>
                              </custom-datetime> -->
                              <custom-datetime [timepicker]="false"
                                               [datepicker]="{maxViewMode: 2, minViewMode: 1, format:'mm/yyyy', autoclose:true, halfformat:true}"
                                               *ngIf="if_start_days.checked"
                                               [ngModel]="event.date_start"
                                               name="start_date"
                                               required>
                              </custom-datetime>
                              <custom-datetime [timepicker]="false"
                                               [datepicker]="{maxViewMode: 2, format: 'mm/dd/yyyy', autoclose:true, halfformat:false}"
                                               [ngModel]="event.date_start"
                                               name="start_date"
                                               *ngIf="!if_start_days.checked"
                                               required>
                              </custom-datetime>
                            </div>
                            <div class="input-group">
                              <!-- <custom-datetime [timepicker]="{showSeconds: true, showMeridian: true}"
                                               [datepicker]="false" [ngModel]="event.date_start"
                                               name="start_time" *ngIf="event.has_times"></custom-datetime> -->
                                        <custom-datetime [timepicker]="{showSeconds: true, showMeridian: true}"
                                               [datepicker]="false"
                                               [ngModel]="event.date_start"
                                               name="start_time" 
                                               *ngIf="event.has_times">
                              </custom-datetime>
                            </div>
                            <div *ngIf="!valid">
                                <span class="help-block" *ngIf="!eventForm.form.controls.start_date.valid"
                                      style="color: red;">
                                  Start Date is required
                                </span>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="mt-checkbox-inline">
                            <label class="mt-checkbox">
                              <input name="if_start_days" [ngModel]="event.if_start_days"
                                     #if_start_days type="checkbox">
                              Show only year and month
                              <span></span>
                            </label>
                          </div>
                          <a class="btn btn-default btn-sm" id="btnTimes"
                             (click)="$event.preventDefault(); switchHasTimes()">
                            {{ event.has_times ? 'Remove' : 'Add' }} Start Time
                          </a>
                        </div>
                        <input type="hidden" [ngModel]="event.has_times" name="has_times">
                        <input type="hidden" [ngModel]="event.has_times_end" name="has_times_end">
                        <input type="hidden" [ngModel]="event.custom_field" name="custom_field">
                        <div *ngIf="event?.end_date != null">
                          <div class="form-group">
                            <label>End Date</label>
                            <div class="form-inline">
                              <div class="input-group date col-md-4 date-picker-date-from">
                                <!-- <custom-datetime [timepicker]="false" [ngModel]="event.date_end"
                                                 [datepicker]="{maxViewMode: 2, minViewMode: 1,
                                                  format: 'mm/yyyy', autoclose:true}"
                                                 *ngIf="if_end_days.checked" name="end_date" required>
                                </custom-datetime>
                                <custom-datetime [timepicker]="false" [ngModel]="event.date_end"
                                                 [datepicker]="{maxViewMode: 2, format: 'mm/dd/yyyy', autoclose:true}"
                                                 *ngIf="!if_end_days.checked" name="end_date" required>
                                </custom-datetime> -->
                                <custom-datetime  [timepicker]="false"
                                                  [ngModel]="event.date_end"
                                                  [datepicker]="{maxViewMode: 2, minViewMode: 1, format: 'mm/yyyy', autoclose:true, halfformat:true}"
                                                  *ngIf="if_end_days.checked"
                                                  name="end_date">
                                </custom-datetime>
                                <custom-datetime  [timepicker]="false"
                                                  [ngModel]="event.date_end"
                                                  [datepicker]="{maxViewMode: 2, format: 'mm/dd/yyyy', autoclose:true, halfformat:false}"
                                                  *ngIf="!if_end_days.checked"
                                                  name="end_date">
                                </custom-datetime>
                              </div>
                              <div class="input-group">
                                <!-- <custom-datetime [timepicker]="{showSeconds: true, showMeridian: true}"
                                                 [datepicker]="false" [ngModel]="event.date_end" name="end_time"
                                                 *ngIf="event.has_times"></custom-datetime> -->
                                                 <custom-datetime [timepicker]="{showSeconds: true, showMeridian: true}"
                                                 [datepicker]="false"
                                                 [ngModel]="event.date_end"
                                                 name="end_time"
                                                 *ngIf="event.has_times_end">
                                                </custom-datetime>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="mt-checkbox-inline">
                              <label class="mt-checkbox">
                                <input name="if_end_days" [ngModel]="event.if_end_days"
                                       #if_end_days type="checkbox">
                                Show only year and month
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="input-group date">
                          <a id="btnAdditional" class="btn btn-default btn-sm" style="margin-right: 3px"
                             (click)="$event.preventDefault(); switchEndDate()">
                            {{ event.end_date ? 'Remove' : 'Add' }} End Date
                          </a>
                          <a class="btn btn-default btn-sm" id="btnTimes_end" *ngIf="event?.end_date != null"
                             (click)="$event.preventDefault(); switchHasTimesEnd()">
                            {{ event.has_times_end ? 'Remove' : 'Add' }} End Time
                          </a>
                        </div>
                        <br>
                        <div>
                          <div class="form-group">
                            <label>Category*</label>
                            <div class="form-inline">
                              <div class="input-group date col-md-5">
                                <select #category_copy (change)="categoryChanged(category_copy.value)"
                                        [ngModel]="event?.category_id" name="category_id" class="form-control"
                                        required>
                                  <option [attr.value]="0">Choose category</option>
                                  <option *ngFor="let category of categories" [attr.value]="category.id"
                                          [attr.selected]="event.category_id == category.id ? true : null">
                                    {{ category.name }}
                                  </option>
                                </select>
                              </div>
                              <div *ngIf="!valid">
                                <span class="help-block" *ngIf="!categoryValid" style="color: red;">
                                  Category is required
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <app-trialline-event-modal-categories [triallineId]="triallineId"
                                                                  (onCreated)="addCategory($event)">
                            </app-trialline-event-modal-categories>
                          </div>
                          <h5>Reference</h5>
                          <div class="row">
                            <div class="col-sm-3">
                              <div class="form-group">
                                <input type="text" name="bates_number" [ngModel]="event.bates_number"
                                       class="form-control" id="bates_number"
                                       placeholder="Bates Page Number">
                              </div>
                            </div>
                            <div class="col-sm-3">
                              <div class="form-group">
                                <input type="text" name="exhibit_number" [ngModel]="event.exhibit_number"
                                       class="form-control" id="exhibit_number"
                                       placeholder="Exhibit Number">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group" *ngIf="visibility.checked">
                          <div class="note-small mb-1">Click on image to change the thumbnail</div>
                          <label for="thumbnail_input_copy">
                            <input type="file" ng2FileSelect [uploader]="uploader" id="thumbnail_input_copy"
                                   style="display: none;">
                            <div class="fileinput fileinput-new upload-box">
                              <div class="fileinput-preview thumbnail tile upload-files"
                                   style="width: 210px; position: relative;">
                                <div class="tile-body" *ngIf="thumbnail_Image_src">
                                  <img  #thumbnail
                                       [attr.src]="thumbnail_Image_src"
                                       class="img-responsive" width="200px" alt="thumbnail">
                                  <a *ngIf="showDeleteIcon(thumbnail.src)" (click)="deleteThumbnail(thumbnail.src);
                                   $event.preventDefault()" id="delete-background" class="remove-background">&times;</a>
                                </div>
                              </div>
                            </div>
                          </label>
                          <div class="progress" style="height: 3px; width: 200px; margin: 0">
                            <div class="progress-bar" *ngIf="progressBarUploder" role="progressbar" [style.width]="uploader.progress + '%'"></div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="mt-checkbox-inline">
                            <label class="mt-checkbox">
                              <input name="thumbnail_visible" id='thumbnail_visible_copy'
                                     [ngModel]="event.thumbnail_visible"
                                     #visibility type="checkbox">
                              Show thumbnail
                              <span></span>
                            </label>
                          </div>
                          <div class="upload-container" *ngIf="visibility.checked">
                            <div>
                              <button (click)="$event.preventDefault(); openthumbnailFromLibraryModal(); false"
                                      (mouseenter)='Test();' (mouseleave)='mouseleavefunction()'
                                      class="btn btn-primary" style="margin-top:-8px;">Add Thumbnail From Existing
                                TrialLine Files
                              </button>
                            </div>
                          </div>
                          <div style="width: 30%; margin-top: 10px" *ngIf="!isCropped">
                            <div style="display: flex; flex-direction: row;">
                              <button type="button" class="btn btn-success" *ngIf="event.thumbnail_encryptid"
                                      (click)="showCropImage()">Crop Image</button>
                              <img src="assets/squares.gif" class="img spinners" alt="spinner" width="30px"
                                   id="processing" *ngIf="cropProcessing">
                            </div>
                          </div>
                          <div id="crop_block_1" style="margin-top: 10px">
                            <div style="display: flex; flex-direction: row; margin-bottom: 10px">
                              <button style="display: flex;" type="button" (click)="cropApply()" class="btn btn-success">
                                Apply
                              </button>
                              <button style="display: flex; margin-left: 2rem" type="button" (click)="cropCancel()"
                                      class="btn btn-danger">
                                Cancel
                              </button>
                            </div>
                            <img class="mr-auto p-2" [attr.src]="cropped_image_src" style="display: block; max-width: 100%;" alt="" id="crop_img">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group" style="z-index: 1;">
                      <label>Description</label>
                      <ckeditor (change)="onCKChange($event)" [(ngModel)]="event.description" name="description"
                                id="editor1" debounce="500" [config]="config" #ckeditor_copy>
                      </ckeditor>
                      <div id="triallinemodal_copy" class="modal fade " role="dialog"
                           style="display: block;position: absolute;left: 50%;top:70%;height:100%;transform: translate(-50%, -50%);"
                           [style.visibility]="status1 ? 'visible' : 'hidden'">
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <button type="button" class="close" (click)="hidemodal()" style="font-size:xx-large;">
                                &times;
                              </button>
                              <h4 class="modal-title" style="text-align: center;">Select TrialLine To Link To</h4>
                            </div>
                            <div class="modal-body" style="max-height: 20pc;overflow-y: auto;overflow-x: hidden;">
                              <div class="container">
                                <ul class="menu" style="list-style: none">
                                  <li *ngFor="let trialline of triallines;let i = index" value="{{ trialline.id }}">
                                    <!--  {{i+1}}) -->
                                    <a (click)="getTriallineLink(trialline.id, trialline.name)"
                                       title="Click here to link that TrialLine">{{ trialline.name }}</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="add-thumbnail-from-library-modal_copy" class="modal fade_ bs-modal-lg in_ overflowed_ in"
                   role="dialog"
                   style="z-index:1680;" data-modal-parent="#event-edit-copy-modal">
                <div class="modal-dialog" style="width: 90%;">
                  <div class="modal-content">
                    <div class="modal-header ">
                      <div class="row">
                        <div class="col-sm-4">
                          <h4 class="modal-title">Add Thumbnail From File Library</h4>
                        </div>
                        <div class="col-sm-7">
                        </div>
                        <div class="col-sm-1">
                          <button (click)="closePreviewer1()" type="button" class="close"><span
                            aria-hidden="true">&times;</span></button>
                        </div>
                      </div>
                    </div>
                    <img src="assets/squares.gif" class="img spinners center-block" alt="spinner" *ngIf="processing">
                    <div class="modal-body" style="max-height: calc(100vh - 100px);overflow-y: auto;">
                      <div class="row">
                        <div *ngFor="let nefo of ttfolder">
                          <div class="col-md-4" style="margin-top: 1pc;">
                            <div class="row">
                              <div class="col-sm-12 col-md-12">
                                <a (click)="OpenTrlFolderthumbnail(nefo)">
                                  <i class="fa fa-folder-open" style="font-size: 24px;color: #034375;"></i>
                                  <span style="font-size: 16px;font-family: sans-serif;font-weight: bold;">
                                    {{nefo}}
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <hr *ngIf='ttfolder.length > 0 && thumbnailfilesarray.length > 0'
                            style="height:1px;border:none;color:#868383;background-color:#a9a7a7;"/>
                      </div>
                      <div class="row">
                        <div class="tiles upload-files no-preview">
                          <ul>
                            <li *ngFor="let file of thumbnailfilesarray" class="tile image"
                                (click)="selectFileeventthumbnail(file)"
                                style="margin-left: 4px;border: 4px solid;color: #f3f3f3;">
                              <div class="tile-body">
                                <div class="upload-file-img"
                                     title="{{ getFileName(file.title) }}"
                                     [style.background-image]="'url(' + apiPath + 'filesystem/' + file.encrypt_id + '?preview=1)'"
                                >
                                </div>
                                <div class="tile-object">
                                  <div class="name"
                                       style="width: 127px;overflow: hidden;  line-break: anywhere;">{{getFileName(file.title)}}</div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="panel-heading" data-toggle="collapse" data-parent="#accordion_copy"
                   data-target="#provider-details_copy"
                   (click)='showaccordian_copy(2)'>
                <h4 class="panel-title">
                  <a class="accordion-toggle">
                    PROVIDER DETAILS
                  </a>
                </h4>
              </div>
              <div id="provider-details_copy" class="panel-collapse collapse">
                <div class="panel-body">
                  <div class="form-body">
                    <div class="form-horizontal">
                      <h5>Provider Details</h5>
                      <div class="form-group">
                        <label for="providerName_copy" class="col-sm-3 control-label">Provider Name</label>
                        <div class="col-sm-9">
                          <input type="text" name="provider_name" [ngModel]="event.provider_name"
                                 class="form-control"
                                 id="providerName_copy" placeholder="Name">
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="providerPhone_copy" class="col-sm-3 control-label">Provider Phone</label>
                        <div class="col-sm-9">
                          <input type='text' name="provider_phone" [ngModel]="event.provider_phone"
                                 class="form-control" id="providerPhone_copy" placeholder="Phone">
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="providerEmail_copy" class="col-sm-3 control-label">Provider Email</label>
                        <div class="col-sm-9">
                          <input type="email" name="provider_email" class="form-control"
                                 id="providerEmail_copy"
                                 [ngModel]="event.provider_email"
                                 placeholder="Email">
                        </div>
                      </div>
                      <h5>Facility Location Details</h5>
                      <div class="form-group">
                        <label for="facilityName_copy" class="col-sm-3 control-label">Facility Name</label>
                        <div class="col-sm-9">
                          <input type="text" name="facility_name" [ngModel]="event.facility_name"
                                 class="form-control" id="facilityName_copy" placeholder="Facility Name">
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="facilityAddress_copy" class="col-sm-3 control-label">Address</label>
                        <div class="col-sm-9">
                          <input type="text" name="address" [ngModel]="event.address" class="form-control"
                                 id="facilityAddress_copy" placeholder="Address">
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="facilityOfficeNumber_copy" class="col-sm-3 control-label">Office number</label>
                        <div class="col-sm-9">
                          <input type="number" name="office_number" [ngModel]="event.office_number"
                                 class="form-control"
                                 id="facilityOfficeNumber_copy" placeholder="Office number">
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="facilityCity_copy" class="col-sm-3 control-label">City</label>
                        <div class="col-sm-9">
                          <input type="text" name="city" [ngModel]="event.city" class="form-control"
                                 id="facilityCity_copy"
                                 placeholder="City">
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="facilityState_copy" class="col-sm-3 control-label">State</label>
                        <div class="col-sm-9">
                          <input type="text" name="state" [ngModel]="event.state" class="form-control"
                                 id="facilityState_copy" placeholder="State">
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="facilityPostalCode_copy" class="col-sm-3 control-label">Postal code</label>
                        <div class="col-sm-9">
                          <input name="postal_code" [ngModel]="event.postal_code" class="form-control"
                                 id="facilityPostalCode_copy"
                                 type="text"
                                 placeholder="Postal code">
                        </div>
                      </div>
                      <h5>Attach</h5>
                      <div class="attach-wrap">
                        <input type="file" ng2FileSelect [uploader]="privateUploader" multiple/><br/>
                        <table class="table">
                          <thead>
                          <tr>
                            <th>Attach</th>
                            <th>Progress</th>
                            <th>Actions</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngFor="let file of event?.private_files">
                            <td>
                              <input class="form-control input-sm" type="text" disabled
                                     [attr.value]="file.file.title" placeholder="Attach Text">
                            </td>
                            <td>
                              <span><i class="glyphicon glyphicon-ok"></i></span>
                            </td>
                            <td nowrap>
                              <a [attr.href]="apiPath + 'filesystem/' + file.file.encrypt_id + '/download'"
                                 class="btn btn-success btn-xs">
                                <span class="glyphicon glyphicon-download"></span> Download
                              </a>
                              <a (click)="removeFile(file.file)"
                                 class="btn btn-danger btn-xs">
                                <span class="glyphicon glyphicon-remove"></span> Delete
                              </a>
                            </td>
                          </tr>
                          <tr *ngFor="let item of privateUploader.queue">
                            <td>
                              <input class="form-control input-sm" type="text" #attachText
                                     [disabled]="item.isReady || item.isUploading || item.isSuccess">
                            </td>
                            <td>
                              <div class="progress" style="margin-bottom: 0;">
                                <div class="progress-bar" role="progressbar"
                                     [ngStyle]="{ 'width': item.progress + '%' }"></div>
                              </div>
                              <span *ngIf="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
                            </td>
                            <td nowrap>
                              <button type="button" class="btn btn-success btn-xs"
                                      (click)="upload(item, attachText.value)"
                                      [disabled]="item.isReady || item.isUploading || item.isSuccess">
                                <span class="glyphicon glyphicon-upload"></span> Upload
                              </button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" data-target="#add-contact"
                   (click)='showaccordian_copy(6)'>
                <h4 class="panel-title">
                  <a class="accordion-toggle">ADD CONTACT</a>
                </h4>
              </div>
              <div id="add-contact" class="panel-collapse collapse">
                <div class="panel-body">
                 <app-trialline-event-modal-contacts [contactEvent]="event"
                                                     [contacts]="event?.contacts"
                                                     [triallinesId]="triallineId"
                                                     (onAddContact)="addContact($event)"
                                                     (onAddTag)="addtag($event)"
                                                     (onRemoveContact)="removeContact($event)">
                 </app-trialline-event-modal-contacts>
                </div>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" data-target="#add-bill-cost"
                   (click)='showaccordian_copy(7)'>
                <h4 class="panel-title">
                  <a class="accordion-toggle">BILLS & COSTS</a>
                </h4>
              </div>

              <div id="add-bill-cost" class="panel-collapse collapse">
                <div class="panel-body">
                  <input type="file" class="8888" ng2FileSelect [uploader]="billCostUploader"
                         (onFileSelected)="fileSelected($event)" multiple/><br/>
                  <table class="table">
                    <thead>
                    <tr>
                      <th>File name</th>
                      <th>Amount</th>
                      <th>Bill/Cost</th>
                      <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of billCostFiles">
                      <td>
                        <input class="form-control input-sm" type="text"
                               (change)="changeBillCostFilesAttached2($event, item)"
                               value="{{item.some.name}}">
                      </td>
                      <td>
                        <input class="form-control input-sm" type="text" value="{{item.amount}}"
                               (change)="changeBillCostFilesAmount2($event, item)" placeholder="Amount">
                      </td>
                      <td>
                        <select class="form-control input-sm" (change)="changeBillCostFilesType2($event, item)">
                          <option value="bill" [selected]="item.bill_cost == 'bill'">Bill</option>
                          <option value="cost" [selected]="item.bill_cost == 'cost'">Cost</option>
                        </select>
                      </td>
                      <td nowrap>
                        <a [attr.href]="apiPath + 'filesystem/' + item.file.file.encrypt_id + '/download'"
                           class="btn btn-success btn-xs">
                          <span class="glyphicon glyphicon-download"></span> Download
                        </a>
                        <a (click)="removeFile(item.file)"
                           class="btn btn-danger btn-xs">
                          <span class="glyphicon glyphicon-remove"></span> Delete
                        </a>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="panel-heading" data-toggle="collapse" data-parent="#accordion_copy"
                   data-target="#add-media_copy"
                   (click)='showaccordian_copy(3)'>
                <h4 class="panel-title">
                  <a class="accordion-toggle">
                    ADD MEDIA
                  </a>
                </h4>
              </div>
              <div id="add-media_copy" class="panel-collapse collapse">
                <div class="panel-body">
                  <div class="upload-box">
                    <app-shared-files [moreFiles]="moreFiles" #sharedFilesCopy [files]="event.files"
                                      (onRemove)="removeFile($event)"
                                      (onCloud)="cloudFile($event)" [preview]="true"></app-shared-files>
                    <div class="">
                      <div class="col-md-8">
                        <app-shared-dropzone (onSuccess)="addFile($event)"
                                             (onQueueChanged)="handleDropzoneQueue($event)"></app-shared-dropzone>
                      </div>
                      <div class="col-md-4">
                        <div class="upload-container" style="padding:9px;">
                          <div style="height: 141px;">
                            <button (click)="$event.preventDefault(); openFromLibraryModal()"
                                    class="btn btn-lg btn-primary" style="margin-top: 45px;">Add From Existing TrialLine
                              Files
                            </button>
                            <app-shared-from_library *ngIf="fromLibraryModal" [triallineId]="triallineId"
                                                     [existFileIds]="exceptFileIds" (onSelected)="addFile($event)">
                            </app-shared-from_library>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="upload-video-box">
                    <app-shared-videos [videos]="event.videos" [preview]="true"
                                       (onRemove)="deleteVideo($event)"></app-shared-videos>
                  </div>
                  <div class="input-group">
                    <input #videoUrl type="text" class="form-control" placeholder="YouTube video url...">
                    <span class="input-group-btn">
                    <button (click)="uploadVideo(videoUrl.value); videoUrl.value = ''" class="btn btn-default"
                            type="button">Add</button>
                  </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="panel-heading" data-toggle="collapse" data-parent="#accordion_copy"
                   data-target="#add-tag_copy"
                   (click)='showaccordian_copy(5)'>
                <h4 class="panel-title">
                  <a class="accordion-toggle">
                    ADD TAGS
                  </a>
                </h4>
              </div>
              <div id="add-tag_copy" class="panel-collapse collapse">
                <div class="panel-body">
                  <div class="row">
                    <div class="col-sm-4">
                      <input list="filteredArray" (keyup)="getparticulartag($event)" type="text" class="form-control"
                             id='tag_copy' autocomplete="off" placeholder="Type tags here...">
                      <datalist id="filteredArray_copy" *ngIf='searchtag.length >= 1'>
                        <option (keyup.enter)='addtag(tag.tag_name)' (click)='addtag(tag.tag_name)'
                                *ngFor="let item of searchtag">{{item.tag_name|uppercase}}</option>
                      </datalist>
                    </div>
                    <div class="col-sm-1">
                      <div style="height:11px;margin-left:-12px;">
                        <button (click)="$event.preventDefault();eventtags_copy()"
                                class="btn btn-primary">Add Tag
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-left:2px;margin-top: 10px;">
                      <span *ngFor="let tag of eventtagslist">
                        <a class="btn btn-default btn-outline btn-event" style="margin-top: 7px;">
                          {{tag.tag_name|uppercase }}
                          &nbsp;<i class="fa fa-remove" style="color: #7e878e;" (click)='removetag_copy(tag)'></i>
                        </a>
                      </span>

                  </div>
                </div>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="panel-heading" data-toggle="collapse" data-parent="#accordion_copy" data-target="#notes_copy"
                   (click)='showaccordian_copy(4)'>
                <h4 class="panel-title">
                  <a class="accordion-toggle">
                    NOTES
                  </a>
                </h4>
              </div>
              <div id="notes_copy" class="panel-collapse collapse">
                <div class="panel-body">
                  <div class="form-body form-date ">
                    <div class="form-group">
                      <label>Notes</label>
                      <ckeditor
                        #ckeditor1_copy
                        [ngModel]="event.notes"
                        name="notes"
                        id="editor2_copy"
                        debounce="500">
                      </ckeditor>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal" (click)="hideModelEvent()">Close</button>
          <button [disabled]="! saveAvailable" type="submit" class="btn btn-primary">Save changes</button>
        </div>
      </form>
    </div>
  </div>
</div>
