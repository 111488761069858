import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";

import {AuthService} from "./auth.service";

@Injectable()
export class NotLowCanActivate implements CanActivate
{
  constructor(protected authService: AuthService, protected router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : boolean
  {
    return true;
    // if (this.authService.userModel) {
    //
    //   return this.checkAndRedirectOnFail(this.authService.userModel);
    // } else {
    //
    //   this.authService.loadUser().then((user: User) => {
    //
    //     return this.checkAndRedirectOnFail(user);
    //   })
    // }
  }

  // private checkAndRedirectOnFail(user: User): boolean {
  //
  //   let plan = user.subscription_plan;
  //
  //   if (! plan || ! (plan == 'medium' || plan == 'high')) {
  //     this.router.navigate(['/dashboard']);
  //   }
  //
  //   return plan == 'medium' || plan == 'high';
  // }
}
