import {ChangeDetectorRef, Component, ViewEncapsulation} from '@angular/core';
import {env} from "../../../../.env";
// import {DomSanitizer} from "@angular/platform-browser";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-sample-trallines',
  templateUrl: './sample-triallines.component.html',
  styleUrls: [
      '../welcome/welcome.component_normalize.css',
      '../welcome/welcome.component_slick.css',
      '../welcome/welcome.component_main.css',
      '../welcome/welcome.component_main_responsive.css',
      './sample-triallines.styles.css'
  ]
})
export class SampleTriallinesComponent
{
  views: any[] = [
    // {
    //   title: 'Ludwig v. Westland Insurance',
    //   uuid: 'c1ccee70-de75-11e6-a855-bf6a923965aa',
    //   passwordPhrase: 'HIcT7'
    // },
    {
      title: 'Ludwig v. Westland Insurance',
      uuid: '153be580-1b5f-11e9-a4f5-455c31f22436',
      passwordPhrase: 'owTRk'
    },
    {
      uuid: '66a1ebd0-fac1-11e6-bff6-bd847e87ddcb',
      title: 'Mass Tort Timeline - Talc Related Health Issues',
      passwordPhrase: 'LOnfn'
    },
    {
      title: 'Patent Infringement - Apple v. Samsung',
      uuid: '262fcb00-fac2-11e6-ad4c-91bc1df2d9dc',
      passwordPhrase: 'cVDCu'
    },
  ];
  
  // appPath = env.applicationUrl;
  // views: any[] = [
  //   {
  //     title: 'Ludwig v. Westland Insurance',
  //     iframe: this.appPath + 'view/734548b0-5798-11e8-9a48-b3750cf3ff55?pass=8loxc'
  //   },
  //   {
  //     title: 'Mass Tort Timeline - Talc Related Health Issues',
  //     iframe: this.appPath + 'view/66a1ebd0-fac1-11e6-bff6-bd847e87ddcb?pass=LOnfn'
  //   },
  //   {
  //     title: 'Patent Infringement - Apple v. Samsung',
  //     iframe: this.appPath + 'view/262fcb00-fac2-11e6-ad4c-91bc1df2d9dc?pass=cVDCu'
  //   },
  // ];
  //
  // constructor(private _sanitizer: DomSanitizer) {}
  //
  // getSafeUrl(url) {
  //   return this._sanitizer.bypassSecurityTrustResourceUrl (url);
  // }
}
