<div id="new-access-team" class="modal fade bs-modal-md in" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header ">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">Create New Access to Team</h4>
      </div>
      <form #createAccessTeamForm="ngForm" (submit)="createAccessTeam(createAccessTeamForm)" role="form-horizontal">
        <div class="modal-body portlet-body form">
          <div class="form-body" style="margin: 0px 30px;">
            <input type="hidden" name="team_id" class="form-control" value="{{ triallineId }}" [ngModel]="triallineId" >
            <div class="form-group">
              <label>First Name</label>
              <input type="text" name="first_name" class="form-control" [ngModel]>
              <span class="help-block" *ngIf="errors?.first_name" style="color: red;">{{ errors?.first_name[0] }}</span>
            </div>
            <div class="form-group">
              <label>Last Name</label>
              <input type="text" name="last_name" class="form-control" [ngModel]>
              <span class="help-block" *ngIf="errors?.last_name" style="color: red;">{{ errors?.last_name[0] }}</span>
            </div>
            <div class="form-group">
              <label>Email</label>
              <input type="email" name="email" class="form-control" [ngModel]>
              <span class="help-block" *ngIf="errors?.email" style="color: red;">{{ errors?.email[0] }}</span>
            </div>
            <div class="form-group">
              <label>Access</label>
              <select name="team_access_level" class="form-control" [ngModel]>
                <option value="2">Manager</option>
              </select>
              <span class="help-block" *ngIf="errors?.team_access_level" style="color: red;">{{ errors?.team_access_level[0] }}</span>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            <button id="submitAccessFormButton" type="submit" class="btn btn-primary create-access-team">Create</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div> 


<div id="subteam-access" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h5 class="modal-title">Are you sure?</h5>
      </div>
      <div class="modal-body">
        <h5 class="modal-title">You are about to share this TrialLine with an outside user. That user will have 'Manager' permissions for this TrialLine. Do you want to continue?</h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal" (click)="closeSubmitAccess()">Close</button>
        <button type="button" (click)="confirmsubscribe()" class="btn btn-info btn-sm pull-right">
          Confirm
        </button>
      </div>
    </div>
  </div>
</div>
