import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";

import {ErrorsBlockComponent} from './errors-block/errors-block.component';
import {HubValidatorDirective} from "./validator.directive";

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    HubValidatorDirective,
    ErrorsBlockComponent
  ],
  exports: [
    HubValidatorDirective,
    ErrorsBlockComponent
  ]
})
export class ValidatorModule {}
