import {AfterViewInit, ChangeDetectorRef, Component, Input} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Category} from "../../../shared/category.model";
import {TriallineResourceService} from "app/main/trialline/shared/trialline-resoruce.service";
import {env} from "../../../../../../.env";
import {FileUploader} from "ng2-file-upload";
import {AuthService} from "../../../../auth/shared/auth.service";
import {Modal} from 'ngx-modialog-7/plugins/bootstrap';
import {DomSanitizer} from "@angular/platform-browser";

declare var jQuery:any;

@Component({
  selector: 'app-trialline-edit-categories',
  templateUrl: './categories.component.html'
})
export class TriallineEditCategoriesComponent implements AfterViewInit
{
  @Input() categories: Category[];
  @Input() categoryEvents: Event[];

  publicFolderUrl = env.publicFolderUrl;
  publicImages = env.publicImages;
  apiPath = env.apiUrl;
  private modalId = '#category-edit-modal';
  errors: any = {};
  category: Category = new Category();
  color: string = '#'+(Math.random()*0xFFFFFF<<0).toString(16);
  orderVal: string;
  reverse: boolean = true;
  orderCol: string;
  currentStatus:string = '';
  order:string = 'name';

  showEdit: boolean = false;
  editCategory: Category;

  uploader: FileUploader;

  constructor(
    protected toasts: ToastrService,
    private triallineResoureService: TriallineResourceService,
    private authService: AuthService,
    private _changeDetectorRef: ChangeDetectorRef,
    public modal: Modal,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnDestroy() {
    this._changeDetectorRef.detach();
  }

  ngAfterViewInit() {

    jQuery('[data-toggle="tooltip"]').tooltip({
      animated: 'fade',
      placement: 'right',
      html: true
    });
  }

  getSafeThumbnailUrl(category) {

    let obj;
    if( category.thumbnail_encryptid != null && category.thumbnail_encryptid != false ) {
      obj =  this.publicFolderUrl + 'index.php/v1/filesystem/' +
        category.thumbnail_encryptid + '?preview=1'
    } else {
      obj =  this.publicImages +
        (category.thumbnail ? category.thumbnail.substring(1).replace("'", "\\'") : '')
    }

    return obj;
  }

  getSafeUrl(category) {

    let obj = this.getSafeThumbnailUrl(category);
    return "<img src='" + obj + "' style='height: 130px;'/>";
  }

  closeModal(category: Category) {

    this.showEdit = false;
  }

  updateCategory(category: Category) {

    let index = this.categories.indexOf(this.categories.find(e => e.id == category.id));

    this.categories[index] = category;

    this._changeDetectorRef.detectChanges();
    this.ngAfterViewInit();
  }

  createCategory(category: Category) {

    this.categories.push(category);
  }

  edit(category: Category) {

    this.editCategory = category;
    this.showEdit = true;
    this._changeDetectorRef.detectChanges();
    jQuery(this.modalId).modal('show');
  }

  create() {

    this.editCategory = new Category();
    this.showEdit = true;
    this._changeDetectorRef.detectChanges();
    jQuery(this.modalId).modal('show');
  }

  deleteCategory(category: Category) {

    const dialogRef = this.modal.confirm()
      .size('lg')
      .isBlocking(true)
      .showClose(true)
      .keyboard(27)
      .title('Confirmation')
      .body('Are you sure you want to delete this category?')
      .open()
    dialogRef.result
      .catch((err: any) => {
        this.modelClose()
        console.log('ERROR: ' + err)})
      .then((dialog: any) => {
        this.modelClose()
        if(dialog){
          this.confirmedDeleteCategory(category)
        }
      });
    $('bs-modal-container').addClass('show-midal-element')
  }

  confirmedDeleteCategory(category: Category) {

    this.triallineResoureService.deleteCategory(category.trialline_id, category.id).subscribe((response: any)=> {
        let index = this.categories.indexOf(this.categories.find(e => e.id == category.id));
        this.categories.splice(index, 1);
        this.toasts.success('Successfully deleted category: ', category.name);
      }, (error) => {
      if (error.status == 403) {

        let errorData: any = error.error;
        if (errorData.error.message) { this.toasts.error(errorData.error.message); }
      } else {
        this.toasts.error(error);
      }
    })
  }

  modelClose() {
    $('body').removeClass('modal-open');
  }

  reorder(column: string) {
      this.orderVal = this.orderVal === 'DESC' ? 'ASC' : 'DESC';
      this.reverse = ! this.reverse;
      this.orderVal === 'DESC' ? this.categories.sort( this.dynamicSort(column)) : this.categories.sort( this.dynamicSort('-'+column));
      this.currentStatus = column;
  }

  getSortIcon(column: string) {
    return this._sanitizer.bypassSecurityTrustHtml('<i class="fa fa-sort' +
      ( '-' + (this.reverse ? 'asc' : 'desc') )  + '"' +
      ' aria-hidden="true"></i>');
  }

  /**
   * Function to sort alphabetically an array of objects by some specific key.
   *
   * @param {String} property Key of the object to sort.
   */
  dynamicSort(property) {
    let sortOrder = 1;

    if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a,b) {
      if(sortOrder == -1){
        return b[property].localeCompare(a[property]);
      }else{
        return a[property].localeCompare(b[property]);
      }
    }
  }
}
