import {Pipe, PipeTransform} from '@angular/core';
import {Event} from "../../../shared/event.model";

@Pipe({
  name: 'visibleEvents',
  pure: false
})

export class VisibleEventsPipe implements PipeTransform {
  transform(events: Event[], args: any[]): any 
  {

    let visible = events.filter((event: Event) => {

      return event.notes
        || (event.view && event.view.reduce((s, a) => s + (a.comments.filter(c => c.event_id == event.id).length), 0) > 0);
    });

    return visible.sort(function(a, b) {
      return new Date(a.date_created_at).getTime() - new Date(b.date_created_at).getTime()
    });
  }
}
