import {NgModule} from '@angular/core';
import {HttpClientModule} from "@angular/common/http";
import {FormsModule} from '@angular/forms';
import {CommonModule, DatePipe} from '@angular/common';
import {RouterModule} from "@angular/router";
import {FilesComponent} from "./file/files.component";
import {ContactComponent} from "./contact/contacts.component";
import {ContactSharedShowComponent} from "./contact-show/contact-show.component";
import {ContacSharedtEditModalCategoriesComponent} from "./contact-edit/categories/categories.component";
import {ContactsSharedEditComponent} from "./contact-edit/contact-edit.component";
import {ContactMoreComponent} from "./contact-more/contact-more.component";
import {BillCostMoreComponent} from "./bill-cost-more/bill-cost-more.component";
import {DropzoneModule} from "ngx-dropzone-wrapper";
import {DropzoneComponent} from "./dropzone/dropzone.component";
import {FilesPipe} from "./shared/files.pipe";
import {PreviewComponent} from "./preview/preview.component";
import {FormatPipe} from "./table-sortable/shared/format.pipe";
import {OrderByPipe} from "./table-sortable/shared/order-by.pipe";
import {TableSortableComponent} from "./table-sortable/table-sortable.component";
import {ShortenPipe} from "./shared/shorten.pipe";
import {FilesPresentComponent} from "./files-present/files-present.component";
import {ModalModule} from "ngx-modialog-7";
import {BootstrapModalModule} from "ngx-modialog-7/plugins/bootstrap";
import {TriallineComponent} from "./trialline/trialline.component";
import {TriallineNavigationComponent} from "./trialline/navigation/navigation.component";
import {TriallinePyramidComponent} from "./trialline-pyramid/trialline-pyramid.component";
import {PreviewYoutubeComponent} from "./youtube-preview/preview-youtube.component";
import {VideosComponent} from "./videos/videos.component";
import {FromLibraryComponent} from "./from-library/from-library.component";
import {TriallineMobileComponent} from "./trialline-mobile/trialline-mobile.component";
import {SortByDatePipe} from "./shared/sortByDate.pipe";
import {FilterToTemplatePipe} from "./shared/filterToTemplate.pipe";
import {FileEditComponent} from "./file-edit/file-edit.component";
import {FileUploadModule} from "ng2-file-upload";
import {SortById} from "./shared/sort-by-id.pipe";
import {CustomDatetime} from "./datetime-picker/datetime-picker.components";
import {TextMaskModule} from "angular2-text-mask";
import {SafeNewPipe} from '../safenew.pipe';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import 'hammerjs';
import {NgxPaginationModule} from "ngx-pagination";
import {AutocompleteLibModule} from "angular-ng-autocomplete";
import {CKEditorModule} from "ckeditor4-angular";
import {ColorPickerModule} from "ngx-color-picker";
import {TriallineTrackComponent} from "./trialline-track/trialline-track.component";
import {NgSelectModule} from "@ng-select/ng-select";
//import {SafePipe} from "../safe.pipe";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    DropzoneModule,
    ModalModule.forRoot(),
    BootstrapModalModule,
    FileUploadModule,
    TextMaskModule,
    InfiniteScrollModule,
    NgxPaginationModule,
    AutocompleteLibModule,
    CKEditorModule,
    ColorPickerModule,
    NgSelectModule
  ],
  declarations: [
    FilesComponent,
    VideosComponent,
    DropzoneComponent,
    PreviewComponent,
    PreviewYoutubeComponent,
    TableSortableComponent,
    FilesPresentComponent,
    TriallineComponent,
    TriallinePyramidComponent,
    TriallineTrackComponent,
    TriallineMobileComponent,
    TriallineNavigationComponent,
    FromLibraryComponent,
    FilesPipe,
    FormatPipe,
    OrderByPipe,
    ShortenPipe,
    SortByDatePipe,
    FilterToTemplatePipe,
    SortById,
    FileEditComponent,
    CustomDatetime,
    SafeNewPipe,
    ContactComponent,
    ContactMoreComponent,
    BillCostMoreComponent,
    ContactSharedShowComponent,
    ContacSharedtEditModalCategoriesComponent,
    ContactsSharedEditComponent
 //   SafePipe
  ],
  providers: [
    SortByDatePipe,
    FilterToTemplatePipe,
    DatePipe
  ],
    exports: [
        FilesComponent,
        VideosComponent,
        DropzoneComponent,
        TableSortableComponent,
        TriallineComponent,
        TriallinePyramidComponent,
        TriallineTrackComponent,
        TriallineMobileComponent,
        TriallineNavigationComponent,
        //  EventMoreModalComponent,
        FromLibraryComponent,
        ShortenPipe,
        SortById,
        CustomDatetime,
        ContactComponent,
        ContactMoreComponent,
        BillCostMoreComponent,
        ContactSharedShowComponent,
        PreviewComponent
    ]
})
export class SharedComponentsModule {}
