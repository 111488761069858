import {ChangeDetectorRef, Component, Inject, Injectable} from '@angular/core';
import {EventMoreModalComponent} from "../more/more.component";
import {PresentResourceService} from "../../../../present/shared/present-resoruce.service";
import {EventsResourceService} from "../shared/events-resoruce.service";
import {ToastrService} from "ngx-toastr";
import {Modal} from 'ngx-modialog-7/plugins/bootstrap';
import * as screenfull from 'screenfull';
declare var jQuery: any;

@Component({
  selector: 'app-trialline-event-more-pyramid-modal',
  templateUrl: './morePyramid.component.html',
  styleUrls: ['./morePyramid.component.scss']
})
export class EventMoreModalPyramidComponent extends EventMoreModalComponent {

  moreModalId = '#event-more-modal';

  constructor( public presentResourceService: PresentResourceService,
               public eventsResourceService: EventsResourceService,
               public _changeDetectionRef: ChangeDetectorRef,
               public modal: Modal,
               public toasts: ToastrService) {
    super(presentResourceService, eventsResourceService, _changeDetectionRef, modal, toasts);
  }

  transformDate(date: any, showOnlyMonthAndYear?: boolean) {
    if (!date) {
      return '';
    }
    const arr = date.split(/[- :]/);
    const dt = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    const dateIntArr = dt.toString().substring(0, 15).split(' ')
    return showOnlyMonthAndYear
      ? dateIntArr[1] + ' ' + dateIntArr[3]
      : dateIntArr[1] + ' ' + parseInt(dateIntArr[2]) + ', ' + dateIntArr[3];

  }

  setBackgroundColor(color: string) {
    return color !== '#0E69A5' ? color : '#000000'
  }

  checkWindowWidth() {
    return window.innerWidth < 769;
  }
  ngAfterViewInit() {
    let self = this;

    if (screenfull && typeof screenfull.on !== "undefined") {
      this.fullscreenButtonVisible = true;
      screenfull.on('change', () => {
        this.fullscreen = screenfull.isFullscreen;
      });
    }

    jQuery(this.modalId).on('hidden.bs.modal', function (e) {
      jQuery('.modal-backdrop.fade.in').remove();
      if (!jQuery(this).hasClass('in')) {
        self.onClose.emit(self.event);
        if (screenfull && typeof screenfull.on !== "undefined" && screenfull.isFullscreen) {
          screenfull.toggle()
        }
      } else {
        jQuery('body').addClass('modal-open');
      }
    })
  }

  modalClosed(){
    console.log('close the modal');
    jQuery('#event-more-modal').modal('hide').removeClass('in').removeClass('show-style');
    setTimeout( ()=> {
      if(jQuery('#event-more-modal').find('.modal-dialog .modal-lg').length === 0){
        console.log('remove the modal-backdrop class as well.')
        jQuery('.modal-backdrop').remove();
      }
    } , 1000);
  }
}
