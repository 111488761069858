import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {TeamResourceService} from "./team-resoruce.service";
import {Team} from "./team.model";

@Injectable()
export class TeamManageResolver implements Resolve<Team>
{
  constructor(protected teamResourceService: TeamResourceService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Team>
  {
    let res =  this.teamResourceService.getTeam().toPromise().then((team: any) => {return team;}).catch((error) => { this.router.navigateByUrl(error.status == 403 ? '/403' : '/404') });

    return this.teamResourceService.getTeam().toPromise().then((team: any) => {return team;});
  }
}
