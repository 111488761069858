import {NgModule} from '@angular/core';
import {HttpClientModule} from "@angular/common/http";
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {EventsResolver} from "./shared/events.resolver";
import {EventsResourceService} from "./shared/events-resoruce.service";
import {TriallineEventsComponent} from "./events.component";
import {OrderModule} from 'ngx-order-pipe';
import {SharedComponentsModule} from "../../../shared-components/shared-components.module";
import {EventEditModalComponent} from "./edit/edit.component";
import {EventEditCopyModalComponent} from "./edit/edit-copy.component";
import {CKEditorModule} from 'ckeditor4-angular';
import {ColorPickerModule} from "ngx-color-picker";
import {EventEditModalCategoriesComponent} from "./edit/categories/categories.component";
import {ValidatorModule} from "../../../form-validator/validator.module";
import {FileUploadModule} from "ng2-file-upload";
import {ModalModule} from "ngx-modialog-7";
import {BootstrapModalModule} from "ngx-modialog-7/plugins/bootstrap";
import {EventMoreModalComponent} from "./more/more.component";
import {ConfirmationPopoverModule} from 'angular-confirmation-popover';
import {MomentPipe} from "../../shared/moment.pipe";
import {TlResolver} from "./shared/tl.resolver";
import {SafePipe} from "../../../safe.pipe";
import {NgxPaginationModule} from 'ngx-pagination';
import {EventMoreModalPyramidComponent} from "./moreForPyramid/morePyramid.component";
import {PresentResourceService} from "../../../present/shared/present-resoruce.service";
import {EventEditModalContactsComponent} from "./edit/contacts/contacts.component";
import {TrillionEventContactsEditComponent} from "./edit/contacts/edit/edit.component";
import {TrillionEventContactsModalCategoriesComponent} from "./edit/contacts/edit/categories/categories.component";
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {NgSelectModule} from "@ng-select/ng-select";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {TimepickerModule} from "ngx-bootstrap/timepicker";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {DropzoneModule} from "ngx-dropzone-wrapper";
import {NgxDropzoneModule} from "ngx-dropzone";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    OrderModule,
    CKEditorModule,
    SharedComponentsModule,
    ColorPickerModule,
    ValidatorModule,
    FileUploadModule,
    ModalModule.forRoot(),
    BootstrapModalModule,
    ConfirmationPopoverModule.forRoot(),
    NgxPaginationModule,
    AutocompleteLibModule,
    NgSelectModule,
    BsDatepickerModule,
    TimepickerModule,
    TooltipModule,
    NgbModule,
    DropzoneModule,
    NgxDropzoneModule,
  ],
  declarations: [
    TriallineEventsComponent,
    EventEditModalComponent,
    EventEditCopyModalComponent,
    EventMoreModalComponent,
    EventMoreModalPyramidComponent,
    EventEditModalCategoriesComponent,
    EventEditModalContactsComponent,
    TrillionEventContactsEditComponent,
    TrillionEventContactsModalCategoriesComponent,
    MomentPipe,
    SafePipe
  ],
  providers: [
    EventsResolver,
    TlResolver,
    PresentResourceService,
    EventsResourceService
  ],
  exports: [
    EventEditModalComponent,
    EventMoreModalComponent,
    EventMoreModalPyramidComponent,
    EventEditCopyModalComponent
  ]
})
export class EventsModule {}
