<div id="first-time-modal" class="modal fade bs-modal-lg in" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <!--<img src="assets/squares.gif" class="img spinners center-block" alt="spinner" *ngIf="! user">-->
      <div class="modal-header ">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
        <h3 class="modal-title">Welcome!</h3>
      </div>
      <div class="modal-body">
        <p>Hello! Welcome to Trialline {{ user?.first_name }} {{ user?.last_name }}!</p>
        <ng-container *ngIf="user?.user_type == 3">
          <p *ngIf="user?.team_access_level == 3">
            You have been assigned as an Owner of your organization's TrialLine account. This will allow you to view and edit all TrialLines that are were created in your company. Moreover, you will be able to make new TrialLines, assign people to work with them and invite new team members.<br>
            You're ready to go. Please visit the FAQs section of TrialLine.net to get the most out of your TrialLine account.
          </p>
          <p *ngIf="user?.team_access_level == 2">
            You have been assigned as a Manager of your organization's TrialLine account. This will allow you to create, view, edit and share TrialLines in your company account. You're ready to go. Please visit the FAQs section of TrialLine.net to get the most out of your TrialLine account.
          </p>
          <p *ngIf="user?.team_access_level == 1">
            You have been assigned as a User of your organization's TrialLine account. This will allow you to view and edit TrialLines that have been shared with you. However, you will not be able to make new TrialLines. Please talk to your TrialLine administrator to add cases to your dashboard or to update your status. You're ready to go.<br>
            Please visit the FAQs section of TrialLine.net to get the most out of your TrialLine account.
          </p>
        </ng-container>
      </div>
    </div>
  </div>
</div>


