import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TriallineResourceService} from "app/main/trialline/shared/trialline-resoruce.service";
import {AuthService} from "../../../../auth/shared/auth.service";
import {Modal} from 'ngx-modialog-7/plugins/bootstrap';
import {Team} from "../../../team/shared/team.model";
import {TeamResourceService} from "../../../team/shared/team-resoruce.service";
import {UserProfile} from "../../../profile/shared/user.model";
import {User} from "../../../../auth/shared/user.model";

declare var jQuery:any;

@Component({
  selector: 'app-trialline-edit-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TriallineEditTeamComponent implements OnInit
{
  @Input() teamIds: number[];
  @Input() triallineId: number;
  // @Output() onCreated = new EventEmitter<Category>();

  processing: boolean = false;
  notOwner: boolean = true;
  showCreateTeammateForm: boolean = false;
  team: Team;
  userProfile: UserProfile;
  user: User;

  constructor(
    protected toasts: ToastrService,
    private triallineResoureService: TriallineResourceService,
    private teamResourceService: TeamResourceService,
    private authService: AuthService,
    private _changeDetectorRef: ChangeDetectorRef,
    public modal: Modal,
  ) {
    authService.user().subscribe(
      (userResponse: User) => {
        console.log('userResponse', userResponse);
       this.user = userResponse;
      }
    );

  }


  

  ngOnInit() {
      // this.team = new Team();
      
      
    this.teamResourceService.getTeam().toPromise()
      .then((team: any) => {
        this.team = team;
        const user_email = localStorage.getItem("user_email");
        if(this.team.owner.email == user_email){
            this.notOwner = false;
        }
          this.teamResourceService.getTeamAccess(this.triallineId).toPromise()
              .then((teamAccess) => {
                  let shareNew = [];
                  let owner = null;

                  // @ts-ignore
                teamAccess.forEach((share, index) =>
                      {
                          if (share.trialline_id === this.triallineId) {
                              owner = share.owner;
                              share.user.status_access = share.status;
                              share.user.token = share.token === null;
                              shareNew.push( share.user);
                          }
                      }
                  )
                  // if(owner !== null && this.team.user_id !== owner.id) {
                  //     // this.notOwner = true;
                  //     this.team.owner = owner;
                  // }
                  this.team.share = shareNew;

                  
              })
              .catch(e => {
                  console.log(e);
                  if (e.json().message) {
                      this.toasts.error(e.json().message)
                  }
              })
      })
      .catch(e => {
        console.log(e);
        if (e.json().message) {

          this.toasts.error(e.json().message)
        }
      })

  }

  createnewaccessinteem() {
    this.showCreateTeammateForm = true;
    jQuery("#new-access-team").modal("show");
  }

  toggle(user: UserProfile) {

    if (this.teamIds.indexOf(user.id) > -1) {

      this.deleteTeamember(user.id);
    } else {

      this.addTeammember(user.id);
    }
  }

  addTeammember(member_id) {
    this.triallineResoureService.addTeammember(this.triallineId, member_id).toPromise()
      .then(r => {
        this.teamIds.push(member_id);
        this._changeDetectorRef.detectChanges();
      })
      .catch(e => {

        let error = e.json();
        if (error.error.message) {

          this.toasts.error(error.error.message);
        }
      })
  }

  deleteTeamember(member_id) {

    this.triallineResoureService.deleteTeammember(this.triallineId, member_id).toPromise()
      .then(r => {
        let index = this.teamIds.indexOf(member_id);
        this.teamIds.splice(index, 1);
        this._changeDetectorRef.detectChanges();
      })
      .catch(e => {
        let error = e.json();
        if (error.error.message) {
          this.toasts.error(error.error.message);
        }
      })
  }
  invite()
  {
    var status;var status1;var status2;
    if(($('#fname').val()==null)||($('#fname').val()==''))
    {
      $("#fname_error").val('Please enter first name.')
    }else
    {
      $("#fname_error").val('');
    }

    var data=[];
    data.push({"fname":$('#fname').val(),"lname":$('#lname').val(),"email":$('#email').val(),"trlid":this.triallineId})
      this.triallineResoureService.addGuestUser(data).toPromise()
    .then(r => {
      console.log(r)
    })

  }

  toggleAccess(user) {
      this.deleteAccess(user);
  }

  deleteAccess(user) {

    const dialogRef = this.modal.confirm()
        .size('lg')
        .isBlocking(true)
        .showClose(true)
        .keyboard(27)
        .title('Are you sure?')
        .body('You are about to remove access to this TrialLine for the selected user. Do you wish to continue?')
        .okBtn('Confirm')
        .cancelBtn('Cancel')
        .open()
    dialogRef.result
        .catch((err: any) => console.log('ERROR: ' + err))
        .then((dialog: any) => { return dialog.result })
        .then((result: any) => { this.revokeAccess(user); jQuery("modal-overlay").modal("hide");  jQuery("modal-overlay").hide();})
        .catch((err: any) => { jQuery("modal-overlay").modal("hide");  jQuery("modal-overlay").hide(); console.log(err); });
  }

  revokeAccess(user) {
    this.teamResourceService.accessTeam({ 'email': user.email, 'team_id': this.triallineId }).toPromise()
        .then(() => {
          this.toasts.success('Access successfully revoked.');
          if (this.team.share.length > 0) {
            this.team.share.forEach( (elem, index) => {
              if(elem.id === user.id) {
                this.team.share.splice(index, 1);
              }

            })
          }
        })
        .catch((error) => {
          if (error.status == 422) {
            let errorData = error.error;

            try {
              for (let key in errorData) {
                let value = errorData[key];
                this.toasts.error(value.join(" "), key);
              }
            } catch (e) {
              console.log(e)
            }
          } else if (error.status == 403) {
            this.toasts.error(error.error.error.message)
          } else {
            this.toasts.error(error);
          }
        });
  }

  addAccess(user) {
    user.status_access = false;
    this.team.share.push(user);
    this._changeDetectorRef.detectChanges();
  }
}
