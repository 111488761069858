import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {Trialline} from "../../shared/trialline.model";
import {TriallineResourceService} from "./trialline-resoruce.service";

@Injectable()
export class TriallineResolver implements Resolve<Trialline>
{
  constructor(protected triallineResource: TriallineResourceService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Trialline>
  {
      return this.triallineResource.getTrialline(route.parent.params['trialline_id'],
        'categories,files,files.billcosts,files.contacts,events,events.files,events.contacts,contacts,contacts.privateFiles,contacts.categories')
        .toPromise()
        .then((trialline: Trialline) => {return trialline;})
       // .catch((error) => { this.router.navigateByUrl(error.status == 403 ? '/403' : '/404') });
  }
}
