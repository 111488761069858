<div class="sub-header">
  <h4>Uploaded Files</h4>
</div>
<div id="upload-filter-form" class="form-inline no-validate">
  <div class="form-group">
    <select name="type" class="form-control">
      <option value="all" selected>All Types</option>
      <option value="image">Image</option>
      <option value="text">Text</option>
      <option value="table">Excel</option>
      <option value="pdf">PDF</option>
    </select>
  </div>
  <div class="form-group">
    <label class="sr-only" for="idSearch">Search</label>
    <input type="text" name="search" class="form-control" id="idSearch"
           placeholder="Search"
           value="">
  </div>
</div>
<div class="tiles upload-files">
  <ul>
    <ng-container *ngIf="files">
      <li *ngFor="let file of files" class="tile image">
        <div class="tile-body">
          <div class="upload-file-img"
               [style.background-image]="'url(' + file.encrypt_id + '/preview=1)'">
          </div>
          <div class="tile-object">
            <div class="name" style=" line-break: anywhere;">{{ file.title }}</div>
          </div>
        </div>
      </li>
    </ng-container>
  </ul>
  <div class="clearfix"></div>
</div>
