<div class="page-container page-wrap">
  <div class="page-content-wrapper">
    <div class="page-head">
      <div class="container">
        <!-- BEGIN Search -->
        <div class="page-title">
          <h1>File Library</h1>
        </div>
        <!-- END Search -->
      </div>
    </div>
    <div class="page-content">
      <div class="container">
        <div class="col-md-12 upload-box">
          <div class="clearfix"></div>
          <app-shared-files #sharedFiles [list]="listView" [files]="files" (onClick)="fileDetails($event)"
                            (onRemove)="removeFile($event)" (onCloud)="cloudFile($event)" [preview]="true"
                            [onlyevent]='true' [inLibrary]="true"></app-shared-files>
        </div>
      </div>
    </div>
  </div>
</div>
<app-file_library-details [file]="file" (onUpdated)="updateFile($event)"></app-file_library-details>

