import {Injectable} from "@angular/core";





import {RestService} from "../../../rest/shared/rest.service";

@Injectable()
export class SupportResourceService
{
  constructor(public http: RestService) {}
}
