import {Pipe} from '@angular/core';
import {Event} from "../../main/shared/event.model";

@Pipe({
  name: 'sortByDate',
  pure: false
})
export class SortByDatePipe {

  transform(events: Event[]) {

    return events.sort(function(a, b) {
      let firstDate = new Date(a.date_start).getTime();
      let secondDate = new Date(b.date_start).getTime();

      if (firstDate == secondDate) {

        return new Date(a.date_created_at).getTime() - new Date(b.date_created_at).getTime()
      }
      return firstDate - secondDate
    });
  }
}
