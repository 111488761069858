
import {map} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from "@angular/router";
import {env} from "../../../.env";
import {AuthService} from "../auth/shared/auth.service";
import {HttpHeaders , HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-admin-mode',
  template: '<img src="assets/squares.gif" class="img spinners" alt="spinner" *ngIf="processing" style="position: fixed; left: 50%; top: 10px;">'
})
export class AdminModeComponent implements OnInit {

  processing: boolean = true;
  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected _route: ActivatedRoute,
    protected authService: AuthService,
    public toasts: ToastrService
  ) {}

  ngOnInit() {

    this._route.params.subscribe(params => {

      if (! params['user_id'] || ! params['token']) {

        this.toasts.error('Something went wrong, please try again');
        this.router.navigateByUrl('/');
      } else {

        localStorage.clear();
        this.handleSuccess(params['user_id'], params['token']);
      }
    });
  }

  handleSuccess(userId: number, token: string) {

    let headers = new HttpHeaders({'Accept': 'application/json', 'Authorization': 'Bearer ' + token})



    this.http.get(env.apiUrl + 'admin/users/admin-mode/' + userId, {headers: headers}).subscribe(

    (r: any) => {
      let token = r.body.jwt_token;
      this.authService.store(token);

      this.authService.emit();

      this.processing = false;
      this.router.navigateByUrl('/dashboard');
    },
    (error: any) => {

      this.processing = false;
      this.toasts.error('Something went wrong, please try again');
      this.router.navigateByUrl('/');
    }
    );
  }
}
