import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  encapsulation: ViewEncapsulation.Emulated,
  selector: 'app-404',
  templateUrl: './page-not-found.component.html',
  styleUrls: [
    './page-not-found.component.css'
  ]
})
export class PageNotFoundComponent
{
}
