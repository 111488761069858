import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";

import {Trialline} from "../../shared/trialline.model";
import {TriallinesResourceService} from "./triallines-resoruce.service";

interface ResolvedData {
  data?: any;
  error?: any;
}

@Injectable()
export class TriallinesResolver implements Resolve<ResolvedData>
{
  constructor(protected triallinesResource: TriallinesResourceService) {}

  resolve(route: ActivatedRouteSnapshot): Promise<ResolvedData>
  {
      return this.triallinesResource.getTriallines()
        .toPromise()
        .then((triallines: any) => {return triallines;})
        .catch((err: Response) => {
            return err;
          });
  }
}
