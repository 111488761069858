import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {AuthService} from "../../auth/shared/auth.service";
import {Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from "@angular/router";
import {User} from "../../auth/shared/user.model";
import {RestService} from "../../rest/shared/rest.service";
import {HttpEvent, HttpStartEvent} from "../../rest/shared/http.events";
import {ToastrService} from 'ngx-toastr';
import {ProfileResourceService} from "../../main/profile/shared/profile-resoruce.service";
import {LOCAL_STORAGE, WebStorageService} from 'ngx-webstorage-service';


declare var jQuery: any;

@Component({
  selector: 'app-auth-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  public httpProcessing: boolean = false;
  public processing: boolean = false;
  public data: any = [];
  public showSpinners: boolean = false;
  user: User;
  private card: any;
  renewing: boolean = false;
  public cards: any = [];
  firstTimeModalId: string = '#from-old-first-time-modal';
  addCardModalId = '#add-card-modal-new';
  subscriptionFormId = '#subscription-modal-new';
  january22User: boolean;

  constructor(
    private auth: AuthService,
    protected router: Router,
    protected restService: RestService,
    protected profileResourceService: ProfileResourceService,
    public toasts: ToastrService,
    private _changeDetectionRef: ChangeDetectorRef,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) {

    restService.events.subscribe(
      (event: HttpEvent) => {
        this.httpProcessing = event instanceof HttpStartEvent;
      }
    );

    router.events.subscribe((event: Event) => {
      this.handleRouterEvent(event);
    });

    auth.user().subscribe(
      (user: User) => {
        this.user = user;
        localStorage.setItem('user_email', this.user.email);
        localStorage.setItem('user_first_name', this.user.first_name);
        let pricingUpdateDate = new Date('January 5, 2022 00:00:00');
        let userCreatedAtDate = new Date(this.user.created_at);

        if (userCreatedAtDate >=  pricingUpdateDate){
          this.january22User = true;
        }else {
          this.january22User = false;
        }
        
        if (this.user && this.user.first_time && this.user.user_type == 2)
         {
          jQuery(this.firstTimeModalId).modal('show');

          this.profileResourceService.disableFirstTime().toPromise()
            .then((r: any) => {

              this.user.first_time = false;
              this.auth.emit(this.user);
            }).catch(error => {
            let errorData = error.error;
            console.log(errorData);
          });
        }
      },
      (error: any) => {
        auth.logout();
        this.router.navigateByUrl('/');
      }
    );

    auth.userChangeEvent.subscribe(user => this.user = user);
    this.showSpinners = this.httpProcessing || this.processing;
  }

  renewSubscription() {
    if (this.user && this.user.subscription_past_due) {
      this.profileResourceService.getCards().toPromise()
        .then((r: any) => {
          this.cards = r;
          if (this.cards.length !== 0) {
            this.renewing = true;
            this.profileResourceService.renewSubscription().toPromise()
              .then((user: any) => {
                this.renewing = false;
                this.auth.emit();
                this.router.navigateByUrl('/profile/settings');
              }).catch(error => {

              this.renewing = false;
              let errorData = error.error;

              if (errorData.error.message) {
                this.toasts.error(errorData.error.message);
              } else {
                this.toasts.error(error);
              }
            })
          } else {
            this.openModal();
          }
        }).catch(error => {
        let errorData = error.error;
        console.log(errorData);
      });
    }

  }


  reloadRenew(event) {
    this.auth.emit();
    this.router.navigateByUrl('/profile/settings');
  }

  openSubscriptionFormModal() {
    jQuery(this.subscriptionFormId).modal('show');
  }

  openModal() {
    jQuery(this.addCardModalId).modal('show');
  }

  cardAdded(card) {
    jQuery(this.addCardModalId).modal('hide');
    this.cards.push(card);
    if (this.cards.length == 1) {
      this.user.card_last_four = card.last4;
      this.user.card_brand = card.brand;
    }
    this.openSubscriptionFormModal();
  }

  logout() {

    this.auth.logout();

    this.router.navigateByUrl('/');
  }

  handleRouterEvent(event: Event) {
    if (event instanceof NavigationStart) {
      this.processing = true;
    }

    if (event instanceof NavigationEnd) {
      this.processing = false;
    }

    if (event instanceof NavigationCancel) {
      this.processing = false;
    }

    if (event instanceof NavigationError) {
      this.processing = false;
    }
  }

  isActiveRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) > -1;
  }

  scrollToTop() {

    jQuery('html, body').animate({scrollTop: '0px'}, 300);
  }

  checkBrowser(): void {
    let isChrome = /chrome\//i.test(window.navigator.userAgent);
    let isIOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
    if ((!isChrome && !isIOS) && this.needRemind()) {
      jQuery("#browser-modal").modal('show');
    }
  }

  stopRemindingMe() {
    this.saveInLocal('stopRemaind', true);
  }

  needRemind() {
    return !this.getFromLocal('stopRemaind');
  }

  saveInLocal(key, val): void {
    this.storage.set(key, val);
    this.data[key] = this.storage.get(key);
  }

  getFromLocal(key) {
    this.data[key] = this.storage.get(key);
    return this.data[key];
  }

  ngOnInit(): void {
    if (this.getFromLocal('needCheckChromeNotification')) {
      this.checkBrowser();
    }
  }
}
