import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { AuthResourceService } from "../shared/auth-resoruce.service";
import { AuthService } from "../shared/auth.service";

@Component({
  selector: 'app-auth-confirm-invitation',
  templateUrl: './confirm-invitation.component.html'
})
export class ConfirmInvitationComponent implements OnInit {
  token: string;
  password: string;
  passwordConfirmation: string;
  isConfirmButtonDisabled: boolean = true;

  constructor(
    protected authResourceService: AuthResourceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    if (this.auth.check()) {
      this.auth.logout();
    }

    this.activatedRoute.params.subscribe((params: Params) => {
      this.token = params['token'];

      if (!this.token) {
        this.router.navigateByUrl('/register');
      }
    });
  }

  checkPasswordsMatch() {
    this.isConfirmButtonDisabled = this.password !== this.passwordConfirmation;
  }

  confirm(form: NgForm) {
    if (this.password !== this.passwordConfirmation) {
      this.toastr.error('Passwords do not match');
      return;
    }

    this.authResourceService.confirmInvitation(this.token, form.value).subscribe(
      r => {
        this.handleSuccess(r);
      },
      e => {
        this.handleError(e);
      }
    );
  }

  handleSuccess(response: Response) {
    this.toastr.success('Successfully confirmed');
    this.router.navigateByUrl('/login');
  }

  handleError(error: Response) {
    this.toastr.error('Token does not exist or has expired');
    this.router.navigateByUrl('/register');
  }
}
