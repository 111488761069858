import {Pipe} from '@angular/core';

@Pipe({
  name: 'sortById',
  pure: false
})
export class SortById {

  transform(items: any[]) {

    return items.sort(function(a, b) {
      return b.id - a.id
    });
  }
}
