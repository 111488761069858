import {Component, Input} from "@angular/core";

@Component({
  selector: 'table-sortable',
  templateUrl: './table-sortable.component.html',
})
export class TableSortableComponent {

  @Input() columns: any[];
  @Input() data: any[];
  @Input() sort: any;

  selectedClass(columnName): string|boolean{

    return columnName == this.sort.column ? 'sort-' + this.sort.descending : false;
  }

  changeSorting(columnName): void{

    let sort = this.sort;
    if (sort.column == columnName) {
      sort.descending = !sort.descending;
    } else {
      sort.column = columnName;
      sort.descending = false;
    }
  }

  convertSorting(): string{

    return this.sort.descending ? '-' + this.sort.column : this.sort.column;
  }
}
