import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChange, ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";
import {ToastrService} from 'ngx-toastr';
import {FileUploader} from "ng2-file-upload";
import {DomSanitizer} from "@angular/platform-browser";
import {FileModel} from "../../file-library/shared/file.model";
import {env} from"./../../../../../.env";
import {FileLibraryResourceService} from "../../file-library/shared/file-library-resoruce.service";
import {AuthService} from "../../../auth/shared/auth.service";
import {saveAs, encodeBase64} from 'file-saver';
import {Contacts} from "../../shared/contacts.model";
import {ActivatedRoute, Router} from "@angular/router";
import {TriallineResourceService} from "../shared/trialline-resoruce.service";
import {Modal} from "ngx-modialog-7/plugins/bootstrap";
declare var jQuery: any;

@Component({
  selector: 'app-file-edit-case',
  templateUrl: './file-edit-case.component.html',
  styleUrls: ['./file-edit-case.component.scss']
})
export class FileEditCaseComponent implements OnChanges
{
  @Input() file: FileModel;
  @Output() onUpdated = new EventEmitter<FileModel>();
  @Output() onRemoveContact = new EventEmitter<Contacts>();

  modalId: string = '#library-file-edit-modal';
  contacts:any [];
  listIdContacts:any = [];
  contactList:any;
  pdfpath: any;
  previewodalId: string = '#preview-modal-1';
  keyword:any = 'name';
  selectedContact:any;
  publicFolderUrl = env.publicFolderUrl;
  publicImages = env.publicImages;
  editContact: Contacts;
  triallinesId:any;
  left: number = 0;
  top: number = 0;
  public uploader: FileUploader;
  private boxPosition: { left: number, top: number };
  private containerPos: { left: number, top: number, right: number, bottom: number };
  @ViewChild("previewModal2", {read: ElementRef})  public previewModal2: ElementRef;

  apiPath = env.apiUrl;

  constructor(
    private toasts: ToastrService,
    private fileLibraryResourceService: FileLibraryResourceService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _sanitizer: DomSanitizer,
    private authService: AuthService,
    public triallineResourceService: TriallineResourceService,
    public router: Router,
    public modal: Modal,
    protected route: ActivatedRoute
  ) {}

  ngAfterViewInit() {
    if(this.file !== undefined) {
      this.contacts = this.file.contacts
    }
    let self = this;
    this.route.parent.params.subscribe(params => {
      if(params["trialline_id"] !== undefined){
        self.triallinesId = params["trialline_id"];
      }
    });
    if (this.triallinesId) {
      this.triallineResourceService.getContacts(this.triallinesId, 'categories,privateFiles').subscribe((response: any) => {
        this.contactList = response;
      }, (error: any) => { console.log(error);} );
    }
  }

  selectContact(event){
    this.selectedContact = event.id
  }

  closeEditModal() {

    jQuery('#library-file-edit-modal').modal('hide');
  }

  getSafeThumbnailUrl(category) {

    let obj;
    if( category.thumbnail_encryptid != null && category.thumbnail_encryptid != false ) {
      obj =  this.publicFolderUrl + 'index.php/v1/filesystem/' +
        category.thumbnail_encryptid + '?preview=1'
    } else {
      obj =  this.publicImages +
        (category.thumbnail ? category.thumbnail.substring(1).replace("'", "\\'") : '')
    }

    return obj;
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {

    this.file = changes['file'].currentValue;
    console.log(this.file);
    if (this.file) {
      this.uploader = new FileUploader({
        url:   env.apiUrl + 'file/' + this.file.id + '/update',
        authToken: 'Bearer ' + this.authService.token(),
        autoUpload: true
      });
      this.uploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
        let body = JSON.parse(response).body;
        this._changeDetectorRef.detectChanges();
        this.file = body.file;
        this.onUpdated.emit(this.file);
        this._changeDetectorRef.detectChanges();
      };
    }
  }
  selectFile(file: FileModel) {
    this.file = file;
    // this.fileLibraryResourceService.logFile(this.file).toPromise()
    //   .then((response: any) => {
    //     console.log(response);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // this.pdfpath = this._sanitizer.bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/' + this.file.encrypt_id);
    jQuery(this.previewodalId).modal('show');
    // this.loadBox();
    // this.loadContainer();
  }
  // private loadBox(){
  //   const {left, top} = this.previewModal2.nativeElement.getBoundingClientRect();
  //   this.boxPosition = {left, top};
  // }
  // private loadContainer(){
  //   const left = this.boxPosition.left - this.left;
  //   const top = this.boxPosition.top - this.top;
  //   const right = left + 630;
  //   const bottom = top + 560;
  //   this.containerPos = { left, top, right, bottom };
  // }

  imageIcons(fExtension: string) {
    switch(fExtension) { 
      case 'pdf': 
      { 
        return 'assets/images/icon-file-type/pdf.png';
        break; 
      } 
      case 'audio':
      { 
        return 'assets/images/icon-file-type/mp3.png'; 
        break; 
      }
      case 'table':
      { 
        return 'assets/images/icon-file-type/xls.png'; 
        break; 
      }
      case 'text':
      { 
        return 'assets/images/icon-file-type/doc.png';  
        break; 
      } 
      default: 
      { 
        return 'assets/images/custom-column-images/paperwork.png'; 
        break; 
      } 
    }
  }

  confirmedDeleteContact(contact: Contacts) {
    let index = this.contacts.indexOf(this.contacts.find(e => e.id == contact.id));
      this.contacts.splice(index, 1);
      this.onRemoveContact.emit(contact);
      this.toasts.success('Successfully deleted contact from event.');


  }

  closePreviewer() { 
    jQuery('#preview-modal').modal('hide');    
  }

  deleteContact(contact: Contacts) {
    const dialogRef = this.modal.confirm()
      .size('lg')
      .isBlocking(true)
      .showClose(true)
      .keyboard(27)
      .title('Confirmation')
      .body('Disconnecting this contact will remove the contact\'s association with this event. Proceed?')
      .open()
    dialogRef.result
      .catch((err: any) => {
        this.modelClose()
        console.log('ERROR: ' + err)})
      .then((dialog: any) => {
        this.modelClose()
        if(dialog){
          this.confirmedDeleteContact(contact);
        }
      });
    $('bs-modal-container').addClass('show-midal-element')
  }
  // imageIcons(fExtension: string) {
  //   switch(fExtension) { 
  //     case 'pdf': 
  //     { 
  //       return 'assets/images/icon-file-type/pdf.png';
  //       break; 
  //     } 
  //     case 'audio':
  //     { 
  //       return 'assets/images/icon-file-type/mp3.png'; 
  //       break; 
  //     }
  //     case 'table':
  //     { 
  //       return 'assets/images/icon-file-type/xls.png'; 
  //       break; 
  //     }
  //     case 'text':
  //     { 
  //       return 'assets/images/icon-file-type/doc.png';  
  //       break; 
  //     } 
  //     default: 
  //     { 
  //       return 'assets/images/custom-column-images/paperwork.png'; 
  //       break; 
  //     } 
  //   }
  // }

  connectToEvent() {
    this.contacts = this.file.contacts
    if (this.selectedContact !== undefined) {
      let index = this.contactList.indexOf(this.contactList.find(e => e.id == this.selectedContact));
      if(this.contacts.indexOf(this.contacts.find(e => e.id == this.selectedContact)) == -1) {
        this.listIdContacts.push(this.contactList[index].id)
        this.contacts.push(this.contactList[index])
      }else{
        this.toasts.info('Contact has already been added from event.');
      }
    }else{
      this.toasts.info('Please select contact.');
    }
  }

  getFileName(name: string) {
    if (name) {
      return name.replace(/\d{9,}_/, '');
    }
    return name;
  }

  getSafeUrl(category) {
    let obj = this.getSafeThumbnailUrl(category);
    return "<img src='" + obj + "' style='height: 130px;'/>";
  }

  modelClose() {
    $('body').removeClass('modal-open');
  }

  save(form: NgForm) {
    let data = form.value;
    let listContactsId = [];
    if (this.file.contacts.length > 0) {
      for (let i = 0; i < this.file.contacts.length; i++) {
        listContactsId.push(this.file.contacts[i].id)
      }
    }
    data['contacts_id'] = listContactsId;
    this.fileLibraryResourceService.updateFile(this.file, data).toPromise()
      .then((file: FileModel) => {
        this.toasts.success('Successfully update');
        this.onUpdated.emit(file);
        jQuery(this.modalId).modal('hide');
      })
      .catch((error) => {
        this.toasts.error(error);
      });
      this.file.description = '';
  }
  download1(encryptid,title) {
    saveAs(this.apiPath+'filesystem/'+encryptid,title);
  }
}

