import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {Event} from '../../../shared/event.model'
import {EventsResourceService} from "./events-resoruce.service";

@Injectable()
export class EventsResolver implements Resolve<Event[]>
{
  constructor(protected eventsResource: EventsResourceService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Event[]>
  {
      return this.eventsResource.getEventsAll(route.parent.params['trialline_id'], 'category')
        .toPromise()
        .then((events: Event[]) => {return events;})
       // .catch((error) => { this.router.navigateByUrl(error.status == 403 ? '/403' : '/404') });
  }
}
