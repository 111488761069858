import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-feed-card',
  templateUrl: './feed-card.component.html',
  styleUrls: ['./feed-card.component.scss']
})
export class FeedCardComponent implements OnInit {
  @Input() feeds: any;
  myItem: string;
  constructor() { }

  ngOnInit() 
  {
    this.myItem = localStorage.getItem('support');
  }

}
