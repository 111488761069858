import {Trialline} from "./trialline.model";
import {UserProfile} from "../profile/shared/user.model";
import {Event} from "./event.model";

export class Category
{
  id: number;
  name: string;
  slug: string;
  trialline_id: number;
  user_id: number;
  background: string;
  thumbnail: string;
  default: boolean;

  created_at?: string;
  updated_at?: string;

  state?: boolean = true;

  events_count?: number;
  thumbnail_encryptid?: any;

  trialline?: Trialline;
  user?: UserProfile;
  events?: Event[];
}
