import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Title} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {UserProfile} from "../shared/user.model";
import {ProfileResourceService} from "../shared/profile-resoruce.service";
import {AuthService} from "../../../auth/shared/auth.service";
import {NgForm} from "@angular/forms";
import {env} from "../../../../../.env";

@Component({
  selector: 'app-profile-settings',
  templateUrl: './clio.component.html',
  styleUrls: ['./clio.component.scss']
})
export class ProfileClioComponent implements  OnInit
{
  errors: any = {};
  user: UserProfile;
  public activeTab: string;
  constructor(
    protected toasts: ToastrService,
    protected title: Title,
    protected route: ActivatedRoute,
    protected router: Router,
    protected ref: ChangeDetectorRef,
    protected profileResourceService: ProfileResourceService,
    protected auth: AuthService
  ) { title.setTitle('Trialline | Clio') }


  ngOnInit() {
    this.route.data.subscribe((data: {user: UserProfile, cards: any}) => {
      this.user = data.user;
    });
    this.activeTab = 'description';
  }

  ngOnDestroy() {
    this.ref.detach();
  }

  update(form: NgForm) {

    this.profileResourceService.updateClio(form.value).toPromise()
      .then((data: {user: UserProfile}) => {
        this.errors = {};
        this.toasts.success('Clio data successfully updated, now opened popup,' +
          ' if no allow popup in your browser for this site');
        let clioUrl = 'https://app.clio.com/oauth/authorize?redirect_uri=' + env.clioApiUrl +
          'auth/clio/callback&response_type=code&client_id=' + env.appKeyClio + '&state=' + data.user.id;
        let windowName = 'Clio Auth';
        let attributes = "_blank,menubar=false,location=false,resizable=yes,scrollbars=yes,status=yes,width=900,height=500";

      let win=  window.open(clioUrl, windowName, attributes);
      })
      .catch((error) => {
        if (error.status == 422) {
          let errorData = error.error;
          this.errors = errorData.data;
        } else {

          this.toasts.error(error);
        }
      });
  }
}
