import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {TriallinesResourceService} from "../shared/triallines-resoruce.service";
import {User} from "../../../auth/shared/user.model";
import {AuthService} from "../../../auth/shared/auth.service";
import {ToastrService} from 'ngx-toastr';
import {Router} from "@angular/router";

declare var jQuery: any;

@Component({
  selector: 'app-first-time',
  templateUrl: './first-time.component.html',
  styleUrls: ['./first-time.component.scss']
})
export class FirstTimeModalComponent implements AfterViewInit
{
  @Input() user: User;
  @Output() onClosed = new EventEmitter<boolean>();

  modalId = '#first-time-modal';

  userInfo: User;

  roles = {
    1: 'Member',
    2: 'Admin',
    3: 'Manager'
  };

  constructor(
    public triallineResourceService: TriallinesResourceService,
    private auth: AuthService,
    public toasts: ToastrService,
    private router: Router
  ) {}

  ngAfterViewInit() {

    let self = this;
    jQuery(this.modalId).on('hidden.bs.modal', function (e) {

        self.onClosed.emit(true);
    });
  }

  getRoleName(index) {

    return this.roles[index];
  }
}
