<div class="container">
  <div class="content">
    <br><br>
    <div class="title text-center">Terms of Use and End User Agreement</div>
    <br><br>
    <p><span style="font-weight: 400;">Last updated December 15th 2023</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">These Terms of Service ("Terms") are a legally binding agreement between you and hereafter ("User," "you," or "your") and SJA Enterprises, Inc. dba TrialLine and hereafter ("Company," "we," "us," or "our"), governing your use of the Company's software as a service ("Service"). By accessing or using the Service, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use the Service.&nbsp;</span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">This Agreement may be periodically updated and the current version will be posted at <a href="https://trialline.net/terms-of-use">https://trialline.net/terms-of-use.</a> Your continued use of the Services constitutes your continued acceptance of any updated terms and conditions.&nbsp;</span></p>
    <p>&nbsp;</p>
    <p><strong>1. SUBSCRIPTION LEVELS & COSTS</strong></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">As of this <strong> Terms of Use </strong> notice and <strong> End User Agreement </strong>, there are three TrialLine.net subscription levels: Annual, Month-to-Month, and Guest. All of them will be listed here, so please make sure you understand the specifics of the plan you have purchased.</span></p>
    <p>&nbsp;</p>
    <div style="display: flex;">1.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;"><strong> Annual:</strong> The <strong>Annual</strong> plan is billed annually (one payment) and at a rate of $499 per year per user. <strong>This is a per user plan.</strong> Periodically, TrialLine may offer discounts or promotions, which are typically for one-time use unless mutually agreed upon in writing for recurring or renewable benefits. You will be enrolled in an <strong>Annual</strong> auto-renew billing program and your card will be charged at the beginning of each <strong>Annual</strong> period. You agree to and understand that your plan will auto-renew indefinitely unless you cancel it. You agree to and understand that your plan will auto-renew at the listed <strong>Annual</strong> rate (on our homepage www.trialline.net) at the time of your renewal. You agree to monitor </span><a href="http://www.trialline.net"><span style="font-weight: 400;">www.TrialLine.net</span></a><span style="font-weight: 400;"> for plan pricing changes. You agree to accept the pricing information on www.TrialLine.net at the time new charges are generated for your account. You agree to these charges without any additional outside notification. There is no discount, refund, or prorated fee for TrialLine Annual accounts canceled during the course of your subscription. Your account will remain open and usable for the duration of your subscription. These are the other features you receive in the Annual Plan: 100GB of cloud storage per user account.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">1.2. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;"><strong> Month-to-Month:</strong> The <strong>Month-to-Month</strong> plan is billed monthly and at a rate of $59 per year per user. <strong> This is a per user plan. </strong> You will be enrolled in a <strong> Monthly </strong> auto-renew billing program. You agree to and understand that your plan will auto-renew indefinitely unless you cancel it. You agree to and understand that your plan will auto-renew at the listed <strong> Month-to-Month </strong> rate (on our homepage www.trialline.net) at the time of your renewal. You agree to monitor <a href="http://www.trialline.net"><span style="font-weight: 400;">www.TrialLine.net</span></a> for plan pricing changes. You agree to accept the pricing information on <a href="http://www.trialline.net"><span style="font-weight: 400;">www.TrialLine.net</span></a> at the time new charges are generated for your account. You agree to these charges without any additional outside notification. There is no discount, refund, or prorated fee for TrialLine <strong> Month-to-Month </strong> accounts canceled during the course of your subscription. Your account will remain open and usable for the duration of your subscription. These are the other features you receive in the Month-to-Month Plan: 100GB of cloud storage per account.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">1.3. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;"><strong> Guest User:</strong> The ongoing annual software license fee is $0. <strong> A Guest User may only have access to a single TrialLine over the entire period of their subscription.</strong> Please see the Pricing Frequently Asked Questions page for further details.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><strong>2. AUTO RENEWALS</strong></p>
    <p>&nbsp;</p>
    <div style="display: flex;">2.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">All TrialLine.net accounts are set to auto-renew, either monthly or on an annual basis. You may or may not get a notification from TrialLine.net that your renewal is up and that new annual charges are about to be applied to your payment method on file. By accepting these Terms you accept automatic renewal at the current rate (posted at www.TrialLine.net) and without prior notification. Because prior notification cannot be guaranteed, it is important that you store your subscription start and end dates somewhere you can access them and that you keep track of them.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><strong>3. USER ACCOUNTS</strong></p>
    <p>&nbsp;</p>
    <div style="display: flex;">3.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">You are accountable for safeguarding the confidentiality of your account credentials. Any activities conducted under your account are your sole responsibility, and sharing your account with other users is prohibited.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">3.2. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">Should you detect any unauthorized access or breach of your account, you agree to promptly notify us. Timely reporting of such incidents allows us to take necessary actions to secure your account and prevent further unauthorized access.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">3.3. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">We retain the right to suspend or terminate your account at our discretion if we have reason to believe that there has been a violation of these Terms.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div>
      <div style="display: flex;">3.4. 
       <p style="margin-left: 20px;"><span style="font-weight: 400;">To register and create an account with TrialLine, users are required to follow these steps:&nbsp;</span></p>
       <p><br/></p>
      </div>
      <div style="margin-left: 20px;">
        <p style="margin-left: 20px;">3.4.1.&nbsp;&nbsp; <span style="font-weight: 400;"> Accessing TrialLine's Official Website: Visit trialline.net, the official website of TrialLine.&nbsp;</span></p>
        <p style="margin-left: 20px;">3.4.2.&nbsp;&nbsp; <span style="font-weight: 400;"> Completion of Signup Form: Locate and accurately fill out the provided signup form available on the homepage.</span></p>
        <p style="margin-left: 20px;">3.4.3.&nbsp;&nbsp; <span style="font-weight: 400;"> Email Submission: Provide a valid email address in the designated field on the signup form.</span></p>
        <p style="margin-left: 20px;">3.4.4.&nbsp;&nbsp; <span style="font-weight: 400;"> Submission Confirmation: Click the "Submit" button to initiate the account creation process.</span></p>
      </div>
    </div>
    <div style="display: flex;">3.5. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">Upon submission of the form, users will receive detailed instructions via email to verify their account and finalize the registration process.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><strong>4. USER ASSIGNMENTS AND PERMISSIONS</strong></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">Customers who subscribe to TrialLine have the ability to assign additional user roles and permissions in the following four ways:</span></p>
    <div style="display: flex;">4.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;"><strong>Owner:</strong> There is only one Owner per TrialLine account. The Owner is generally the person who first sets up the TrialLine subscription and provides the payment method and billing authorization. The Owner can create additional Manager, Staff, and Guest accounts from their Master Dashboard. By accepting these terms you understand and acknowledge that Managers have the authority to create new Staff users and thus new subscription charges. When the Owner assigns someone as a Manager they take full responsibility for the accounts and charges created by the Manager and agree to pay all charges for all users. Owners can create new TrialLines within their accounts. Owners can share TrialLines with outside people, via email invitations or weblinks. By accepting these terms you accept responsibility for the actions of your Owner and any possible consequences of the Owner’s ability to share TrialLines.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">4.2. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;"><strong>Manager:</strong>  Managers can create new user subscriptions as well as share new TrialLines with the Owner, Staff, and Guests to collaborate online or in the office. Because Managers can create new user subscriptions from their accounts, they can create new charges that will be billed to the payment method on file (generally added by the “Owner”). Managers can create new TrialLines within their accounts. Managers can also share TrialLines with outside people, via email invitations or weblinks. By accepting these terms you accept responsibility for the actions of your Managers (including creating new charges) and any possible consequences of the Manager’s ability to share TrialLines.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">4.3. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;"><strong>Staff:</strong> Staff cannot create new TrialLine user subscriptions and do not have the authority to create new charges. Staff can work on TrialLines shared with them by Managers or Owners. Staff can create new TrialLines within their accounts. Staff can also share TrialLines with outside people, via email invitations or weblinks. By accepting these terms you accept responsibility for the actions of your Staff users and any possible consequences of the Staff user’s ability to share TrialLines.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">4.4. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;"><strong>Guest:</strong> Guests cannot create new TrialLines subscriptions and do not have the authority to create new charges. Guests cannot create new TrialLines within their accounts. Guests can only work on a single TrialLine that has been shared with them by a Manager or Owner. Guests cannot share TrialLines with anyone. Only Owners, Managers, and Staff users can share TrialLines. Guests may only see the single TrialLine shared with them. By accepting these terms you accept responsibility for the actions of your Guests and any possible consequences of the Guest’s ability to view TrialLines, including any unauthorized duplication and/or sharing.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><strong>5. UPGRADING & DOWNGRADING PLANS</strong></p>
    <p>&nbsp;</p>
    <div style="display: flex;">5.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">All<strong> Month-to-Month Plan Subscribers</strong> may upgrade to an <strong> Annual </strong> plan at any time. We will not prorate any monthly software subscription fees. You accept surrender of any outstanding credit as the cost of upgrading your account. When you upgrade to an <strong> Annual </strong> plan, you will not experience any disruption of your service and all of your current TrialLines will be available in your updated account. Once payment is made for your upgrade, all additional features of your new plan will be immediately available.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">5.2. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;"><strong>Annual Plan</strong> subscribers may not downgrade to a Month-to-Month Subscription until their current annual contract has concluded. All <strong> Annual Plan Subscribers </strong> may only downgrade to a Month-to-Month plan at the conclusion of their annual plan. You must do this through the Subscriptions section of your user dashboard. You must also switch to a Month-to-Month plan BEFORE your annual plan auto renews. So please be aware of your subscription start and end dates. You can select the Month-to-Month plan within your user dashboard at any time, and, at the conclusion of your annual subscription, month-to-month billing will begin.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><strong>6. TECHNICAL SUPPORT</strong></p>
    <p>&nbsp;</p>
    <div style="display: flex;">6.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">We are happy to provide technical support to our current customers and potential customers taking advantage of a 7-day FREE trial period. All trial period users are invited to schedule a free live demo via the “schedule demo” button at the top of our home page: https://trialline.net/ or by contacting us directly and requesting a demo.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">6.2. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">The Technical Support form is available on your User Dashboard. For your convenience we also provide a support chat feature. We do not warrant or guarantee there will be live support at the time of your request. You may submit a support request via the technical support form on your dashboard.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">6.3. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">Current customers should keep in mind that Technical Support inquiries should be used to address general use questions and technical glitches or anomalies that may come up from time-to-time.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">6.4. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">Technical Support is not to be used to help you set-up your cases, provide one-on-one customization, or to illicit case specific strategic or design advice.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">6.5. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">If your technical support requests appear to move out of a general scope, you will be invited to take advantage of our paid TrialLine consulting services, provided through our parent company. These TrialLine consulting services are $90/hour and can be booked in 30 minute increments by <strong> contacting us at contact@trialline.com</strong> &nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><strong>7. TECHNICAL SUPPORT PERMISSIONS</strong></p>
    <p>&nbsp;</p>
    <div style="display: flex;">7.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">From time to time, you may find that you need some assistance from our technical support team. Our support team has the ability to login to your account and individual TrialLines remotely to assist you. In accepting these terms, you acknowledge that our support team may see sensitive or confidential information. Our support team will never record the information they view or download documents to private computers or machines. If you require technical support AND require complete confidentiality (meaning that you do not authorize our team to see the information contained within your TrialLine), please make that clear in the contact forms, phone calls and support form submissions you send to our company and/or to the support team. If you do not request this explicit confidentiality, and in accepting this End User Agreement and Terms of Use, you acknowledge and authorize our support team to work under the assumption they have permission to temporarily see your files for the purpose of assisting you in the development or repair of your TrialLines.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><strong>8. TRIALLINE SECURITY DETAILS</strong></p>
    <p>&nbsp;</p>
    <div style="display: flex;">8.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">TrialLine is secured with an SSL certificate, and uses JWT tokens for authorization. TrialLine uses Filesystem-level encryption, bcrypt for passwords, PHP security best practices and closed access to non-shared views.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">8.2. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">By creating an account on TrialLine.net, you agree that TrialLine.net and its parent company (SJA Enterprises dba) will not be held liable or responsible for issues that arise from inadequate security measures. Please consult with your local bar, state, or federal government to ensure that our security measures are adequate for your data’s unique state and federal security requirements.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><strong>9. INTELLECTUAL PROPERTY</strong></p>
    <p>&nbsp;</p>
    <div style="display: flex;">9.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">TrialLine retains sole and exclusive ownership of all rights, title, and interest in the TrialLine Services, including any software integrated into the Services. This includes all copyrights and other intellectual property rights associated with the Services. TrialLine's name and logo are registered trademarks of TrialLine. Any other trademarks or marks used within the Services belong to their respective owners.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">9.2. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">No part of the Services should be interpreted as granting, by implication, estoppel, or otherwise, any license or right of use of any trademark displayed or utilized within the Services without prior written permission from TrialLine or the respective third-party owners. All rights pertaining to the Services not expressly granted to users are specifically reserved by TrialLine.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">9.3. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">Subject to all of the terms and conditions of this Agreement, TrialLine shall supply the Services to you and grant you a personal, non-exclusive, non-transferable, limited and revocable license to use the Services from the Effective Date for the Subscription Period. This license is personal to you and may not be assigned or sublicensed to anyone else without the written consent of TrialLine. You must be at least eighteen years of age or older to open an account and use the Services.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><strong>10. HIPAA COMPLIANCE</strong></p>
    <p>&nbsp;</p>
    <div style="display: flex;">10.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">TrialLine’s latest security measures (last updated 01/22/2022) have been created to meet HIPAA compliant expectations for cloud software providers and data transfers. However, HIPAA compliance recommendations may change without notice to TrialLine.net or your company. You agree that TrialLine.net will not be held responsible for any fines or penalties that may arise from your use of TrialLine.net should TrialLine.net no longer fall within HIPAA compliance.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">10.2. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">For the U.S. Government’s latest HIPAA compliance recommendations, please visit:<br> https://www.hhs.gov/hipaa/for-professionals/special-topics/cloud-computing/index.html.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><strong>11. DIGITAL DOCUMENT STORAGE</strong></p>
    <p>&nbsp;</p>
    <div style="display: flex;">11.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">TrialLine does not store any of your uploaded or attached documents on TrialLine’s application server. Rather, TrialLine takes advantage of the scalability and security of Amazon Web Services (AWS). Documents are stored via AWS’s Simple Storage Service (S3). Please keep in mind that it is your responsibility to make sure that TrialLine’s security and storage methods are acceptable for your local security requirements. Through your use of TrialLine you accept that responsibility. To assist in that responsibility, we provide up-to-date programming and security information to our users via this Terms of Use document. To review AWS’s S3 security protocols, please visit: https://aws.amazon.com/s3/ &nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><strong>12. CREDIT CARD PAYMENTS</strong></p>
    <p>&nbsp;</p>
    <div style="display: flex;">12.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">Payments are securely processed through a third party payment processor (www.Stripe.com) and we do not store any complete credit card information on our application server. To understand how your credit card information is handled, please visit www.stripe.com. In accepting this Terms of Use, you agree not to hold TrialLine or its parent company ( SJA Enterprises dba) responsible for fraudulent charges or data breaches that may arise through the business practices and offerings conducted by Stripe.com. If you do have a billing issue or concern outside of charges created on behalf of TrialLine, please visit https://support.stripe.com. &nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><strong>13. FEE</strong></p>
    <p>&nbsp;</p>
    <div style="display: flex;">13.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">In consideration for the provision of the Services, you shall pay TrialLine the nonrefundable Subscription Fee.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">13.2. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">TrialLine shall issue electronic invoices with respect to the Subscription Fee and shall collect the Subscription Fee from your credit card monthly in advance. Both parties agree that no refunds shall be given in any circumstances.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">13.3. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">The Subscription Fee is exclusive of VAT or other sales tax which, if applicable to you, shall be payable by you at the then prevailing rate.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">13.4. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">In the event that the Subscription Fee, as appropriate, is not collected in accordance with the provisions herein, TrialLine may deny you access to the Services without notice.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><strong>14. TERMINATION PROCESS</strong></p>
    <p>&nbsp;</p>
    <div style="display: flex;">14.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">Termination or Cancellation: Users can terminate or cancel their TrialLine account through the user dashboard or by contacting TrialLine support. Upon termination, access to the account and associated data will cease. Any outstanding fees or payments will remain due and payable at the time of termination. TrialLine reserves the right to suspend or terminate accounts at any time for any reason, including but not limited to violation of terms or misuse of the service.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">14.2. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">The following provisions will survive the termination of this Agreement: Sections 18.2, 18.4, 9.1, 13, 15, 16, 17 and 23&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><strong>15. WARRANTIES AND LIABILITY</strong></p>
    <p>&nbsp;</p>
    <div style="display: flex;">15.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">The Services are provided to you strictly on an "as is" basis. All conditions, representations, and warranties—whether express, implied, statutory, or otherwise, including, but not limited to, any implied warranty of merchantability, fitness for a particular purpose, or non-infringement of third-party rights—are hereby disclaimed to the maximum extent permitted by applicable law.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">15.2. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">Your sole remedy concerning any claims arising from this Agreement shall be confined, in total, to the payments made by you to TrialLine within the twelve (12) month period preceding the incident giving rise to such liability.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">15.3. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">In no event shall TrialLine be liable for any special, indirect, incidental, or consequential damages, including but not limited to loss of profits, goodwill, business or business benefit, or the cost of procuring substitute products or services by you. This includes any delay or failure in providing the Services attributable to third parties such as internet service providers, data centers, server hosting companies, or telecommunications companies. TrialLine shall not be liable for credit card fraud perpetrated against you by any third-party provider of credit card services.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div>
      <div style="display: flex;">15.4. 
       <p style="margin-left: 20px;"><span style="font-weight: 400;">You agree to indemnify, defend, and hold harmless TrialLine and its affiliates, directors, officers, employees, and agents from all third-party actions. This includes claims arising from:&nbsp;</span></p>
       <p><br/></p>
      </div>
      <div style="margin-left: 20px;">
        <p style="margin-left: 20px;">15.4.1.&nbsp;&nbsp; <span style="font-weight: 400;"> Your activity and use of the Services;</span></p>
        <p style="margin-left: 20px;">15.4.2.&nbsp;&nbsp; <span style="font-weight: 400;"> Allegations asserting a violation by you of any term of this Agreement;</span></p>
        <p style="margin-left: 20px;">15.4.3.&nbsp;&nbsp; <span style="font-weight: 400; position: absolute;"> Assertions that any of Your Content transferred, stored, or uploaded by you violates any law or infringes upon any third-party right, including intellectual property or privacy rights.</span></p>
      </div>
    </div>
    <p>&nbsp;</p>
    <p><strong>16. COPYRIGHT</strong></p>
    <p>&nbsp;</p>
    <div style="display: flex;">16.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">To utilize the Services, it's imperative to uphold the intellectual property rights of others. TrialLine diligently addresses notifications concerning alleged copyright infringement and abides by the Digital Millennium Copyright Act of 1998 (DMCA). Notwithstanding any other provision herein, TrialLine reserves the right to suspend or terminate Services pertaining to repeat infringers. Additionally, TrialLine retains the authority to delete or disable any content alleged to infringe upon intellectual property rights.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">16.2. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">TrialLine's DMCA Policy is available upon request. The designated Copyright Agent for TrialLine is as follows:&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div>
      <p>Copyright Agent</p>
      <p>SJA Enterprises, Inc.</p>
      <p>2896 Crescent Avenue, Suite</p>
      <p>Eugene, OR 97408</p>
    </div>
    <p>&nbsp;</p>
    <p><strong>17. DISPUTE RESOLUTION</strong></p>
    <p>&nbsp;</p>
    <div style="display: flex;">17.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">Any action arising out of or relating to this Agreement will be subject to mandatory arbitration. Any dispute will be administered by the American Arbitration Association (“AAA”) under its Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes. The arbitration will be held in the United States county where you live or work, Eugene, Oregon (OR), or any other location agreed upon by TrialLine and you. The AAA rules will govern payment of all arbitration fees. Either you or TrialLine may assert claims, if they qualify, in small claims court in Eugene, Oregon (OR), or any United States county where you live or work. Either party may bring a lawsuit solely for injunctive relief to stop unauthorized use or abuse of the Services, or intellectual property infringement (for example, trademark, trade secret, copyright, or patent rights) without first engaging in arbitration or the informal dispute-resolution process described above. If the agreement to arbitrate is found not to apply to you or your claim, you agree to the exclusive jurisdiction of the state and federal courts in Eugene, Oregon to resolve your claim. You may only resolve disputes with TrialLine on an individual basis, and may not bring a claim as a plaintiff or a class member in a class, consolidated, or representative action. Class arbitrations, class actions, private attorney general actions, and consolidation with other arbitrations aren’t allowed.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><strong>18. USER CONDUCT</strong></p>
    <p>&nbsp;</p>
    <div style="display: flex;">18.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">Prohibited Activities: Users agree not to engage in any unlawful, abusive, or unauthorized use of the TrialLine service, including but not limited to unauthorized access, tampering, or distribution of malicious software.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div>
      <div style="display: flex;">18.2. 
        <p style="margin-left: 20px;"><span style="font-weight: 400;">You hereby agree not to use the Services in any manner (including without limitation the transfer, display, upload or storage of information and content) with respect to any information or content that is:&nbsp;</span></p>
        <p><br/></p>
      </div>
      <div style="margin-left: 20px;">
        <p style="margin-left: 20px;">18.2.1.&nbsp;&nbsp; <span style="font-weight: 400;"> Obscene, libelous, blasphemous, defamatory, inciting hatred, terrorism or any similar offense;</span></p>
        <p style="margin-left: 20px;">18.2.2.&nbsp;&nbsp; <span style="font-weight: 400;"> Unlawful or misleading;</span></p>
        <p style="margin-left: 20px;">18.2.3.&nbsp;&nbsp; <span style="font-weight: 400;"> Infringing upon any third party’s intellectual property rights (e.g., trademark, copyright, etc.), privacy rights or other similar legal rights;</span></p>
        <p style="margin-left: 20px;">18.2.4.&nbsp;&nbsp; <span style="font-weight: 400;"> Determined by TrialLine, at its sole discretion, not to be suitable for its online community.</span></p>
        <p style="margin-left: 20px;">18.2.5.&nbsp;&nbsp; <span style="font-weight: 400;"> Undertaken on behalf of third parties.</span></p>
      </div>
    </div>
    <div style="display: flex;">18.3. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">You agree to use the Service in compliance with all applicable laws and regulations.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">18.4. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">You further agree not to reproduce, redistribute, sell, create derivative works from, decompile, reverse engineer, or disassemble the Services, nor will you take any measures to interfere with or damage the Services.  Any use of the Services that is not expressly provided for herein is prohibited.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">18.5. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">TrialLine may, without providing notice, immediately suspend the Services and/or terminate this Agreement if you are in breach or, in its reasonable opinion, you are likely to become in breach of Section 18.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><strong>19. YOUR CONTENT REPRESENTATIONS</strong></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">Regarding Your Content, you affirm and assure that:</span></p>
    <div style="display: flex;">19.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;"> You possess the necessary rights to transfer, display,  store, or utilize the content and provide the licenses as outlined in the preceding sections;&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">19.2. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">TrialLine won't necessitate acquiring licenses from third parties or remitting royalties to any third party for the provision of the Services;&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">19.3. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">Your Content doesn't violate the rights of any third party, including intellectual property rights and privacy rights;&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">19.4. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">Your Content aligns with the provisions of this Agreement and adheres to all applicable laws.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><strong>20. NOTICES AND COMMUNICATIONS</strong></p>
    <p>&nbsp;</p>
    <div style="display: flex;">20.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">Official communications and notices from TrialLine will be sent via email to the user's registered email address or displayed within the TrialLine dashboard.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><strong>21. CHANGES TO TERMS</strong></p>
    <p>&nbsp;</p>
    <div style="display: flex;">21.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">We retain the right to modify or update these Terms at our discretion. Any alterations shall become effective upon their posting on TrialLine's website or through direct notification to you.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><strong>22. GOVERNING LAW</strong></p>
    <p>&nbsp;</p>
    <div style="display: flex;">22.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">This Agreement shall be governed by the laws of the State of Oregon, United States of America, without regard to principles of conflicts of law. The Uniform Commercial Code, the Uniform Computer Information Transaction Act, and the United Nations Convention of Controls for International Sale of Goods shall not apply.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><strong>23. DATA PRIVACY</strong></p>
    <p>&nbsp;</p>
    <div style="display: flex;">23.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">Privacy Policy Reference: These Terms of Use are supplemented by TrialLine's Privacy Policy, accessible here: https://trialline.net/privacy-policy, governing the collection, use, and disclosure of personal information.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">23.2. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">You authorize us to collect, store, and utilize non-personal data associated with your use of the Service. This information will be employed for analytical and optimization purposes, aiming to enhance and improve the overall functionality and performance of the Service.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><strong>24. DATA BREACHES</strong></p>
    <p>&nbsp;</p>
    <div style="display: flex;">24.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">By using TrialLine.net you agree that TrialLine.net and its parent company ( SJA Enterprises) will not be held responsible for data losses or damages as a result of data breaches. TrialLine will never publicly post your data or information contained in your TrialLines, but you agree that all websites, including TrialLine.net, remain susceptible to hacking and other data theft. To reduce these opportunities, our servers and your data are securely stored at secure off-site facilities with redundant data backups. However, that does not mean that TrialLine.net may not itself become the victim of malicious attacks. You agree that TrialLine and its parent company ( SJA Enterprises dba ) will not be held liable or responsible for losses or damages that arise from malicious or ordinary attacks. TrialLine advises you to always back-up your data and files locally, either on your own computer or company’s server.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><strong>25. DATA LIMITS</strong></p>
    <p>&nbsp;</p>
    <div style="display: flex;">25.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">Though we provide a large amount of free server space to our customers, there are some cloud-based data and file size limits you need to be aware of. These limits do not apply to the use of TrialLine’s offline version, as offline limits are set by your local devices and personal data storage solutions. However, be aware that if you attempt to sync your local TrialLines with our online version, files on your local machine may require reformatting if they exceed our online file size limits.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">25.2. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">For all users at all subscription levels, the maximum single file size for upload is 5GB. If you have a file or document in excess of 5GB, we recommend that you reformat the file to something of a smaller size, or, break the file up into multiple files (i.e. breaking up single large .pdfs of multiple medical records or multiple images into multiple (and more specific) files).&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">25.3. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">Customers who have subscribed to the <strong>Annual</strong> and <strong> Month-to-Month </strong> plans are allotted 100GB of total server storage space per user account.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">25.4. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">Your server storage space is allocated per user. Additional cloud storage is provided at the Terabyte (TB) level. Additional TBs of cloud-based storage can be purchased for $15.00 per month per TB and will be billed to the credit card on file. This purchase covers individual user accounts and IS NOT allocated company wide. By accepting these terms, you authorize TrialLine’s parent company SJA Enterprises to charge your credit card on file for additional cloud storage should you exceed your current plan limits.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">25.5. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">In most cases, we will try to provide your account owner with a warning letting your company know that you are approaching your cloud storage limits. The reason we require you to authorize the additional charges now is because most of our subscriptions allow for multiple users with differing levels of authority. Authorizing this data storage billing now allows your entire team to continue to work on their TrialLines should your company’s account Owner fail to recognize or respond to data limit overages.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><strong>26. AVAILABILITY AND SERVICES REPRESENTATION</strong></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">TrialLine endeavors to ensure continuous access to the Services, barring planned and emergency downtimes. However, TrialLine does not warrant or represent:</span></p>
    <div style="display: flex;">26.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">The Services' availability in your jurisdiction.&nbsp;</span></p>
      <p><br/></p>
    </div>
    <div style="display: flex;">26.2. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">Uninterrupted or error-free Services.&nbsp;</span></p>
      <p><br/></p>
    </div>
    <div style="display: flex;">26.3. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">Regarding any content submitted by users.&nbsp;</span></p>
      <p><br/></p>
    </div>
    <div style="display: flex;">26.4. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">Continuation of support for any specific Service feature.&nbsp;</span></p>
      <p><br/></p>
    </div>
    <div style="display: flex;">26.5. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">As to sites and resources beyond the Services, even if linked to from within the Services.&nbsp;</span></p>
      <p><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><strong>27. FORCE MAJEURE</strong></p>
    <p>&nbsp;</p>
    <div style="display: flex;">27.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">TrialLine shall not be liable or deemed to be in default for any delay or failure in performance under this Agreement resulting from acts, events, or causes beyond its reasonable control, including but not limited to acts of nature, fire, flood, earthquake, explosion, pandemic, governmental actions, wars, insurrections, strikes, labor disputes, or any other force majeure event ("Event").&nbsp;</span></p>
      <p><br/></p>
    </div>
    <div style="display: flex;">27.2. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">In the event of an Event affecting TrialLine's performance under this Agreement, TrialLine shall promptly notify the affected parties and make all reasonable efforts to mitigate the impact of such Event. The affected obligations under this Agreement shall be suspended for the duration of the force majeure event, and the time for performance shall be extended for a period equal to the duration of the Event or its impact.&nbsp;</span></p>
      <p><br/></p>
    </div>
    <div style="display: flex;">27.3. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">However, if the force majeure event continues for more than thirty (30) days, either party shall have the right to terminate this Agreement without penalty by providing written notice to the other party.&nbsp;</span></p>
      <p><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><strong>28. REACTIVATION OF EXPIRED OR CANCELED TRIALLINE ACCOUNTS</strong></p>
    <p>&nbsp;</p>
    <div style="display: flex;">28.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">Unless otherwise requested, if your TrialLine account expires, or is canceled, all of your TrialLines and associated documents will be stored for six months. If you reactivate your TrialLine account <strong> within </strong> 6 months, you will be able to retrieve all of your TrialLines and associated documents at the current TrialLine subscription rates at the time of reactivation. If you reactivate your TrialLine account <strong> after </strong> 6 months, all of your text and category data will be available, but images and attached documents will no longer be available. We remove documents after 6 months of inactivity to keep our server storage costs lower for paying TrialLine subscribers. You will need to re-upload associated documents if you wish to continue to use older TrialLines in their entirety. These rules only apply to expired or canceled TrialLine accounts and <strong> NOT </strong> to archived TrialLines. Reactivated account users will be able to make new TrialLines without issue (so long as their payment information is up-to-date). Expired or canceled TrialLine accounts that go unaccessed for 12 months will be permanently deleted from the server along with any case specific information.&nbsp;</span></p>
      <p><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><strong>29. MISCELLANEOUS PROVISIONS</strong></p>
    <p>&nbsp;</p>
    <div style="display: flex;">29.1. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">Interpretation; Severability; Waiver; Remedies. Headings are for convenience only and shall not be used to construe the terms of this Agreement. If any term of this Agreement is found invalid or unenforceable by any court of competent jurisdiction, that term will be severed from this Agreement. No failure or delay by TrialLine in exercising any right hereunder will waive any further exercise of that right. TrialLine’s rights and remedies hereunder are cumulative and not exclusive.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">29.2. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">This Agreement is binding upon and shall benefit both parties and their respective successors, heirs, executors, administrators, personal representatives, and permitted assigns. You may not assign this Agreement without prior written consent from TrialLine. No third party shall hold rights under this Agreement. You acknowledge TrialLine's capacity to transfer this Agreement to a third party at any time without prior notice.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">29.3. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">You consent to receive all communications, including notices, agreements, disclosures, or other information from TrialLine electronically. TrialLine may provide such communications via email or by posting them on the Services. Legal notices to TrialLine can be sent to:&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div>
      <p>SJA Enterprises, Inc.</p>
      <p>2896 Crescent Avenue, Suite 104</p>
      <p>Eugene, OR 97408</p>
    </div>
    <div style="display: flex;">29.4. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">This provision does not limit TrialLine's right to contest subpoenas, claims, or other demands.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">29.5. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">DMCA and GDPR policies are available upon request&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">29.6. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">This Agreement may only be modified through a revised Agreement posted by TrialLine on its website or a written amendment signed by an authorized TrialLine representative. Any revised Agreement will be effective as of its posting date on the website.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <div style="display: flex;">29.7. 
      <p style="margin-left: 20px;"><span style="font-weight: 400;">Nothing in this Agreement shall directly or indirectly confer any enforceable benefit or right of action against TrialLine to any third party. Such third parties are not entitled to enforce any term of this Agreement against TrialLine.&nbsp;</span></p>
      <p><br/><br/></p>
    </div>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">&nbsp;</span></p>
    <p><strong>CONTACT TRIALLINE</strong></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">By using the Service, you acknowledge that you have read, understood, and agreed to these Terms. If you have questions, comments, or concerns, you may reach TrialLine via phone at 541-393-9321 or via email at </span><span style="font-weight: 400;">contact@trialline.com</span><span style="font-weight: 400;">. These terms were last updated 12/15/23 TrialLine is a product and service of ( SJA Enterprises dba ), 2896 Crescent Ave #104, Eugene, OR 97408 USA.</span></p>
  </div>
</div>