import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output, SimpleChange
} from '@angular/core';
import {Contacts} from "../../main/shared/contacts.model";

import {TriallineResourceService} from "../../main/trialline/shared/trialline-resoruce.service";
import {AuthService} from "../../auth/shared/auth.service";
import {DomSanitizer} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {env} from "../../../../.env";
import {Modal} from "ngx-modialog-7/plugins/bootstrap";
import {FileModel} from "../../main/file-library/shared/file.model";

declare var jQuery:any;

@Component({
  selector: 'app-shared-contacts-show',
  templateUrl: './contact-show.component.html',
  styleUrls: ['./contact-show.component.scss']
})

export class ContactSharedShowComponent implements OnInit {
  @Input() contact: Contacts;
  @Input() elementStyle: any;
  @Input() showEditButton: boolean = false;
  @Output() onOpenEdit = new EventEmitter<Contacts>();

  publicFolderUrl = env.publicFolderUrl;
  publicImages = env.publicImages;
  apiPath = env.apiUrl;
  modalId = '#contacts-shared-show-modal';
  defaultThumbnail: string = '/images/start-event/trialline-event-thumb.jpg';
  contact_thumbnail: any;
  isModaStyle:any = 'z-index: 9999999; overflow: visible';
  editContact: Contacts;
  showEdit:boolean = false;
  modalIdEdit = '#contact-edit-modal';
  filePreviewer:FileModel;
  preview:boolean = false;

  constructor(
    private authService: AuthService,
    private _changeDetectorRef: ChangeDetectorRef,
    public modal: Modal,
    public triallineResourceService: TriallineResourceService,
    private _sanitizer: DomSanitizer,
    protected route: ActivatedRoute, public router: Router,
  ) {
    if(this.contact !== undefined) {
      this.contact_thumbnail = this.setThumbnailImageUrl(this.contact.thumbnail, true, this.contact);
    }
  }

  ngOnDestroy() {
    this._changeDetectorRef.detach();
  }

  ngOnInit() {
    this.isModaStyle = 'z-index: 9999999; overflow: visible';
  }
  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    if (this.contact !== undefined) {
      this.isModaStyle = !this.elementStyle ? 'z-index: 9999999; overflow: visible': '';
      this.contact_thumbnail = this.setThumbnailImageUrl(this.contact.thumbnail, true, this.contact);
    }
  }

  getSafeThumbnailUrl(category) {
    if (!category.thumbnail_visible) {
      return this.publicImages + '/images/start-event/contact_thumbnail.png'
    }
    let obj;
    if( category.thumbnail_encryptid != null && category.thumbnail_encryptid != false ) {
      obj =  this.publicFolderUrl + 'index.php/v1/filesystem/' +
        category.thumbnail_encryptid + '?preview=1'
    } else {
      obj =  this.publicFolderUrl +
        (category.thumbnail ? category.thumbnail.substring(1).replace("'", "\\'") : '')
    }
    return obj;
  }

  getSafeUrl(category) {
    let obj = this.getSafeThumbnailUrl(category);
    return "<img src='" + obj + "' style='height: 130px;'/>";
  }

  show(contact){
    console.log(contact);
  }


  closeContactModal(){
    jQuery(this.modalId).modal('hide');
   // jQuery('#file-contacts-edit-modal').removeClass('modal-open');
  }

  edit() {
    this.closeContactModal();
    setTimeout( () => this.onOpenEdit.emit(this.contact), 1000)
  }


  setThumbnailImageUrl(path: string, visible: boolean, contact: any) {

    let obj = {};

    if( contact.thumbnail_encryptid != null && contact.thumbnail_encryptid != false ) {
      obj =  this.publicFolderUrl + 'index.php/v1/filesystem/' +
        contact.thumbnail_encryptid + '?preview=1'
    } else {
      obj =  this.publicImages +
        (path ? path.substring(1).replace("'", "\\'") : this.defaultThumbnail.substring(1))
    }
    return obj;
  }

  ShowInPreviewer(file) {
    console.log(file);
    this.preview = true;
    this.filePreviewer = file;
    jQuery('#preview-modal-shared').modal('show');
  }

  closePreviewerFile() {
    this.preview = false;
    jQuery('#preview-modal-shared').modal('hide');
  }
}
