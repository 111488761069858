import {Component} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Router} from "@angular/router";
import {AuthResourceService} from "../../../auth/shared/auth-resoruce.service";
import {User} from "../../../auth/shared/user.model";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
//import { productFruits } from 'product-fruits';

declare var jQuery: any;

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: [
    './register-form.component.css'
  ]
})
export class RegisterFormComponent {
  errors: any = {};
  processing: boolean = false;
  user: any;
  captcha: boolean = false;
  howfindDisplay: boolean = false;
  practiceAreaDisplay: boolean = false;
  practice_areas: any [];
  how_founds: any [];
  firm_size: any [];
  registerForm: UntypedFormGroup;
  captchaerr: string;

  constructor(
    protected authResourceService: AuthResourceService,
    private fb: UntypedFormBuilder,
    protected router: Router,
    protected toasts: ToastrService
  ) {
    this.practice_areas = [
      'Choose Your Practice Area (Required)',
      'Antitrust Litigation',
      'Appellate',
      'Bankruptcy',
      'Business Litigation',
      'Civil Rights',
      'Class Action/Mass Torts',
      'Construction Litigation',
      'Criminal Defense',
      'Employment & Labor',
      'Environmental Litigation',
      'Estate, Trusts & Probate',
      'Family Law',
      'Immigration',
      'Insurance Defense',
      'Intellectual Property',
      'Medical Malpractice',
      'Nurse litigation',
      'Personal Injury - General',
      'Personal Injury - Medical Malpractice',
      'Personal Injury – Products Liability',
      'Professional Liability',
      'Private investigator',
      'Real Estate',
      'Securities Law',
      'Social Security Disability',
      'Workers\' Compensation',
      'I am not a Lawyer',
      'Other [Please explain]'
    ];
    this.how_founds = [
      'How did you find out about TrialLine? (Required)',
      'ABA Publication',
      'Attorney Referral',
      'CLE',
      'Clio',
      'Email Invitation',
      'Exhibitor Booth',
      'Facebook',
      'Google Search',
      'Instagram',
      'LinkedIn',
      'ListServe',
      'Twitter',
      'Other (Please explain)'
    ];
    this.firm_size = [
      'Choose Your Firm Size (Required)',
      '1-5',
      '5-10',
      '10-15',
      '15-20',
      '20-25',
      '25-30',
      '30-35',
      '35-40',
      '40-50',
      '50+',
      '100+',
      '200+',
      '300+',
      '400+',
      '500+',
    ];
    this.registerForm = this.fb.group({
      password: [null, Validators.required],
      password_confirmation: [null, Validators.required],
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      email: [null, Validators.required],
      phone: [null, Validators.required],
      firm: [null, Validators.required],
      firm_size: [null],
      rules: [null, Validators.required],
      how_found: [null, Validators.required],
      practice_area: [null, Validators.required],
    });
  }

  resolved(captchaResponse: string) {

    this.captcha = true;
  }

  register() {
    this.errors = {};
    /* if (!this.captcha) {
      return this.toasts.info('reCAPTCHA required')
    } */

    this.processing = true;

    this.authResourceService.register(this.registerForm.value).toPromise()
      .then(
        (user: User) => this.handleRegister(user),
        (error: Response) => this.handleError(error)
      )
  }

  handleRegister(user: User) {
    this.registerForm.reset();

    this.processing = false;
    this.user = user;

    // productFruits.init(
    //   'LPYK49qFaTWp8QTf', 
    //   'en', 
    //   { 
    //     username: this.user.email, 
    //     email: this.user.email,
    //     firstname:(this.user.first_name) ? this.user.first_name: 'NA',
    //     lastname:(this.user.last_name) ? this.user.last_name: 'NA'
    //   }
    // );

    jQuery('#congratulations-modal').modal('show');
  }

  handleError(error: any)
  {
    console.log('error coming:',error)
    this.processing = false;
    if (error.status == 422) {
      if(error.error.error) {
        this.toasts.error(error.error.error.statusText);
      } else {
        let errorData: any = error.error;
        this.errors = errorData;
        
        if(!this.registerForm.value.firm)
        {
          this.errors.firm='The company field is required.'
        }

        if(!this.registerForm.value.how_found) {
          this.errors.how_found='The how find about field is required.'
        } else {
          this.errors.how_found=''
        }

        if(!this.registerForm.value.firm_size) {
          this.errors.firm_size='The firm size field is required.'
        } else {
          this.errors.firm_size=''
        }

        if(!this.registerForm.value.rules) {
          this.errors.rules='Privacy policy agreement required'
        }else{
          this.errors.rules=''
        }

        if (!this.captcha){
          this.captchaerr='Captcha required'
        }else{
          this.captchaerr=''
        }

        try {
          for (let key in this.errors) {
            let value = this.errors[key];
            this.toasts.error(value.join(' '), key);
          }
        } catch (e) {
          console.log(e);
        }
      }
      

    } else if (error.status == 401) {
      //this.toasts.error(error.error.error.statusText, "", { timeOut: 20000 } );
      
      try {
        this.toasts.error(error.error.error.statusText);
      } catch (e) {
        console.log(e);
      }
      return false;
    } else {
      this.toasts.error(error.statusText);
    }
  }

  resendActivationEmail(email) {

    this.authResourceService.resendActivationEmail(email).toPromise().then(
      (r: any) => {
        this.toasts.info('Sent')
      },
      (error: Response) => {
        console.log(error)
      }
    );
  }

  prepareValue(value) {
    switch (value) {
      case 'Other (Please explain)':
        this.howfindDisplay = true;
        value = '';
        break;
        case 'Other [Please explain]':
          this.practiceAreaDisplay = true;
          value = '';
          break;
      case 'How did you find out about TrialLine? (Required)':
        value = '';
        break;
      case 'How did you find out about TrialLine?':
        value = '';
        break;
      case 'Choose Your Practice Area (Required)':
        value = '';
        break;
    }
    return value;
  }
}
