
import {map} from 'rxjs/operators';
import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {env} from "../../../../.env";
import {FileModel} from "../../main/file-library/shared/file.model";
import {Modal} from 'ngx-modialog-7/plugins/bootstrap';
import {RestService} from "../../rest/shared/rest.service";
import {FromLibrary} from "./shared/from-library.model";
import {FileLibraryResourceService} from "../../main/file-library/shared/file-library-resoruce.service";
import {EventEditModalComponent} from "../../main/trialline/events/edit/edit.component";
import {ToastrService} from 'ngx-toastr';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from "@angular/router";
import {SharedService} from "../../shared/shared.service";
import {EventsResourceService} from "../../main/trialline/events/shared/events-resoruce.service";
import {AuthService} from "../../auth/shared/auth.service";
import {TriallinesResourceService} from 'app/main/triallines/shared/triallines-resoruce.service';
import {TriallineResourceService} from "app/main/trialline/shared/trialline-resoruce.service";

declare var jQuery:any;

@Component({
  selector: 'app-shared-from_library',
  templateUrl: './from-library.component.html'
})
export class FromLibraryComponent implements OnInit {

  @Input() triallineId: number;
  @Input() existFileIds: number[];
  @Input() exceptFileName:string[];
  @Input() tlineLibraryList: boolean;
  @Output() onSelected = new EventEmitter<FileModel>();

  processing: boolean = false;

  public modalId = '#add-from-library-modal';

  files: FromLibrary = new FromLibrary();
  generalFiles: FileModel[] = [];
  triallineFiles: FileModel[] = [];
  apiPath = env.apiUrl;
  checkcomponent: any;
  tfilesi=[];
  tfolder=[];
  casepath: any;
  casepath1: any;
  rtrialline: any;
  ctfilesi=[];
  ctfolder=[];
  rgeneral: any;
  backfolder: any;
  backfolder1: string;
  thumbnailfilesarray =[];
  alreadyExistFiles = [];
  EventEditModalComponent1: EventEditModalComponent;

  constructor(
    protected ref: ChangeDetectorRef,
    public modal: Modal,
    public http: RestService,
    private fileLibraryResourceService: FileLibraryResourceService,
    public ToastrManager:ToastrService,
    public Title:Title,
    public ActivatedRoute:ActivatedRoute,
    public Router:Router,
    public SharedService:SharedService,
    public EventsResourceService:EventsResourceService,
    public AuthService:AuthService,
    public TriallinesResourceService:TriallinesResourceService,
    public TriallineResourceService:TriallineResourceService

  ){}

  ngOnInit() {
       this.checkcomponent=localStorage.getItem('uploadcomponent')
       this.tfilesi=[];
       this.tfolder=[];
       this.ctfilesi=[];
       this.ctfolder=[]

    if (typeof this.tlineLibraryList === 'undefined') this.tlineLibraryList = true;
    this.processing = true;
    this.http.get('file/fromLibrary' + (this.triallineId ? '?trialline_id=' + this.triallineId : ''), {}, false).pipe(map(r => r.body)).subscribe(
      (r: any) =>
      {
        this.files = r;
        let i=0;
        for(let i=0; i<r.general.length; i++) {
          if(this.existFileIds.indexOf( r.general[i].id) > -1){
            this.alreadyExistFiles.push(r.general[i])
          }
          this.rgeneral=r.general;
        }

        //console.log('r.general', r.general);
        
        for(let i=0; i<r.general.length; i++) {
          var result = r.general[i].original_path.substr(r.general[i].original_path.indexOf("/") +1);
          var result1 = result.substr(result.indexOf("/") +1);
          this.files.general[i].original_path=result1;
          console.log('((r.general[i].original_path).split("/",100)).length', ((r.general[i].original_path).split("/",100)).length)
          if(((r.general[i].original_path).split("/",100)).length<2)
          {
            this.ctfilesi.push(r.general[i])
          }else {
              var  splittext=(r.general[i].original_path).split("/",100)[0]
              if(this.ctfolder.includes(splittext)==false)
              {
                this.ctfolder.push((r.general[i].original_path).split("/",100)[0]);
              }
          }
        }
        

        if(this.tlineLibraryList !== false) {
           this.rtrialline=r.trialline;
          for(let i=0; i<r.trialline.length; i++) {

            var result = r.trialline[i].original_path.substr(r.trialline[i].original_path.indexOf("/") +1);
            var result1 = result.substr(result.indexOf("/") +1);

            this.files.trialline[i].original_path=result1;

            if(((r.trialline[i].original_path).split("/",100)).length < 2) {
              this.tfilesi.push(r.trialline[i])
            }else {
               var  splittext=(r.trialline[i].original_path).split("/",100)[0]
               if(this.tfolder.includes(splittext)==false)
               {
                 this.tfolder.push((r.trialline[i].original_path).split("/",100)[0]);

               }
            }
          }
        }
        this.processing = false;
      },
      (error: any) => {
        console.log(error);
        this.processing = false;
      }
    );

    //console.log('ngOnInit this.ctfilesi', this.ctfilesi);

  }

  closePreviewer(){
    jQuery(this.modalId).modal('hide');
  }
  closePreviewer1()
  {
    jQuery('#add-thumbnail-from-library-modal').modal('hide');
  }

  getFileName(name: string) {
    if (name) {
      return name.replace(/\d{9,}_/, '');
    }
    return name;
  }

  selectFile(file: FileModel)
  {
    if(localStorage.getItem('uploadcomponent')=='0')
    {
        if (this.existFileIds.indexOf(file.id) == -1)
       {
          this.onSelected.emit(file);
         // jQuery(this.modalId).modal('hide');
       }

    }else{
      if((localStorage.getItem("folderpath")==null)||(localStorage.getItem("folderpath")==''))
      {
        file.pivot?.user_id
        file.original_path=file.pivot?.user_id+'/123456/'+file.title;
      }else{
        file.original_path=file.pivot.user_id+'/123456/'+localStorage.getItem("folderpath")+'/'+file.title;
      }

      this.fileLibraryResourceService.insertdummy({'dummyfile':file,'trlid':this.triallineId }).toPromise().then((response: any) =>
      {
          file.id=response['Status'];
          //file.pivot.file_id=response['Status'];
          file.file_id=response['Status'];
          this.onSelected.emit(file);
      });
    }
  }

OpenTrlFolder(Fname) {
  if(this.casepath==undefined||this.casepath=='') {
      this.casepath = Fname;
  }else {
       this.casepath=(this.casepath).concat('/'+Fname);
  }
  this.backfolder1=this.casepath.split("/",100);
  this.tfilesi=[];
  this.tfolder=[];
  let i=0;
  for(let i=0; i<this.rtrialline.length; i++) {
    var gefile=this.casepath;
    var   gpatharray=(this.rtrialline[i].original_path.split('/'));
    var gjoinedArray=gpatharray.slice(0,gefile.split("/",100).length).join("/");
    if(gjoinedArray==this.casepath)
    {
      if((this.rtrialline[i].original_path.indexOf(this.casepath)!== -1))
      {
        var indexid=(this.casepath.split("/",100).length);
        if(((this.rtrialline[i].original_path).split("/",100)).length-1==(this.casepath.split("/",100).length))
        {
            this.tfilesi.push(this.rtrialline[i])
        }else
        {
          var splittext=(this.rtrialline[i].original_path).split("/",100)[indexid]
          if(this.tfolder.includes(splittext)==false)
          {
            this.tfolder.push((this.rtrialline[i].original_path).split("/",100)[indexid]);
          }
        }
      }
    }
  }
}
levelup() {
    let levelup = this.casepath.split('/');
    if(levelup.length==1) {
      this.ngOnInit();
      this.casepath='';
      this.backfolder1='';
    } else {
      var test=levelup.splice(levelup.length-2,1)

      this.casepath = this.casepath.substring(0, this.casepath.indexOf(test));
      this.casepath=(this.casepath).concat(test);
      this.backfolder1=this.casepath.split("/",100);
      this.tfilesi=[];
      this.tfolder=[];
      let i=0;
      for(i=0;i<this.rtrialline.length;i++) {
        var gefile=this.casepath;
        var   gpatharray=(this.rtrialline[i].original_path.split('/'));
        var gjoinedArray=gpatharray.slice(0,gefile.split("/",100).length).join("/");
        if(gjoinedArray==this.casepath) {
          var indexid=(this.casepath.split("/",100).length);
          if((this.rtrialline[i].original_path.indexOf(this.casepath)!== -1)) {
            if(((this.rtrialline[i].original_path).split("/",100)).length-1==(this.casepath.split("/",100).length))
            {
              this.tfilesi.push(this.rtrialline[i])
            }else
            {
              var splittext=(this.rtrialline[i].original_path).split("/",100)[indexid]
              if(this.tfolder.includes(splittext)==false)
              {
                this.tfolder.push((this.rtrialline[i].original_path).split("/",100)[indexid]);
              }
            }
          }
        }
      }
    }
 }

 backtofolder(id) {
    if(id == 1) {
      this.ngOnInit();
      this.casepath='';
      this.backfolder1='';
    } else {
          var test=id;
          this.casepath = this.casepath.substring(0, this.casepath.indexOf(test));
          this.casepath=(this.casepath).concat(test);
          this.backfolder1=this.casepath.split("/",100);
          this.tfilesi=[];
          this.tfolder=[];

          for(let i=0; i<this.rtrialline.length; i++) {
            var gefile=this.casepath;
            var gpatharray=(this.rtrialline[i].original_path.split('/'));
            var gjoinedArray=gpatharray.slice(0,gefile.split("/",100).length).join("/");
            if(gjoinedArray==this.casepath)
            {

                var indexid=(this.casepath.split("/",100).length);
                if((this.rtrialline[i].original_path.indexOf(this.casepath)!== -1))
                {
                  if(((this.rtrialline[i].original_path).split("/",100)).length-1==(this.casepath.split("/",100).length))
                  {
                      this.tfilesi.push(this.rtrialline[i])
                  }else
                  {
                    var splittext=(this.rtrialline[i].original_path).split("/",100)[indexid]
                    if(this.tfolder.includes(splittext)==false)
                    {
                      this.tfolder.push((this.rtrialline[i].original_path).split("/",100)[indexid]);
                    }
                  }
                }
            }
           }
     }
 }


 /* Company Files  */
 OpenTrlFolderc(Fname)
{
  if(this.casepath1==undefined||this.casepath1=='')
  {
      this.casepath1=Fname;
  }else
  {
       this.casepath1=(this.casepath1).concat('/'+Fname);
  }
    this.backfolder=this.casepath1.split("/",100);

      this.ctfilesi=[];
      this.ctfolder=[];
      let i=0;
      for(i=0;i<this.rgeneral.length;i++) {
        var gefile=this.casepath1;
        var   gpatharray=(this.rgeneral[i].original_path.split('/'));
        var gjoinedArray=gpatharray.slice(0,gefile.split("/",100).length).join("/");
        if(gjoinedArray==this.casepath1)
        {
            if((this.rgeneral[i].original_path.indexOf(this.casepath1)!== -1))
            {
              var indexid=(this.casepath1.split("/",100).length);
              this.ctfilesi.push(this.rgeneral[i])
              if(((this.rgeneral[i].original_path).split("/",100)).length-1==(this.casepath1.split("/",100).length))
              {
                  
              }else
              {
                var splittext=(this.rgeneral[i].original_path).split("/",100)[indexid]
                if(this.ctfolder.includes(splittext)==false)
                {
                  this.ctfolder.push((this.rgeneral[i].original_path).split("/",100)[indexid]);
                }
              }
            }
        }
      }

      console.log('OpenTrlFolderc this.ctfilesi', this.ctfilesi);
  }

levelupc()
{
    let levelup=this.casepath1.split('/');

    if(levelup.length==1)
    {
      this.ngOnInit();
      this.backfolder='';
      this.casepath1='';
    } else {
          var test=levelup.splice(levelup.length-2,1)

          this.casepath1= this.casepath1.substring(0, this.casepath1.indexOf(test));
          this.casepath1=(this.casepath1).concat(test);
          this.backfolder=this.casepath1.split("/",100);
          this.ctfilesi=[];
          this.ctfolder=[];
          let i=0;
          for(i=0;i<this.rgeneral.length;i++)
          {
            var gefile=this.casepath1;
            var   gpatharray=(this.rgeneral[i].original_path.split('/'));
            var gjoinedArray=gpatharray.slice(0,gefile.split("/",100).length).join("/");
            if(gjoinedArray==this.casepath1)
            {
              var indexid=(this.casepath1.split("/",100).length);
              if((this.rgeneral[i].original_path.indexOf(this.casepath1)!== -1))
              {
                if(((this.rgeneral[i].original_path).split("/",100)).length-1==(this.casepath1.split("/",100).length))
                {
                  this.ctfilesi.push(this.rgeneral[i])
                }else
                {
                  var splittext=(this.rgeneral[i].original_path).split("/",100)[indexid]
                  if(this.ctfolder.includes(splittext)==false)
                  {
                    this.ctfolder.push((this.rgeneral[i].original_path).split("/",100)[indexid]);
                  }
                }
              }
            }

          }
       }
    }

backtofolder1(id) {
    if(id==1) {
      this.ngOnInit();
      this.backfolder='';
      this.casepath1='';
    } else {
          let test = id;

          this.casepath1= this.casepath1.substring(0, this.casepath1.indexOf(test));
          this.casepath1=(this.casepath1).concat(test);
          this.backfolder=this.casepath1.split("/",100);
          this.ctfilesi=[];
          this.ctfolder=[];

          for(let i=0;i<this.rgeneral.length;i++) {
            let gefile = this.casepath1;
            let gpatharray = (this.rgeneral[i].original_path.split('/'));
            let gjoinedArray = gpatharray.slice(0,gefile.split("/",100).length).join("/");
            if(gjoinedArray == this.casepath1) {
              let indexid = (this.casepath1.split("/",100).length);

              if((this.rgeneral[i].original_path.indexOf(this.casepath1)!== -1)) {

                if(((this.rgeneral[i].original_path).split("/",100)).length-1==(this.casepath1.split("/",100).length)) {
                  this.ctfilesi.push(this.rgeneral[i])
                }else{
                  let splittext = (this.rgeneral[i].original_path).split("/",100)[indexid]
                  if(this.ctfolder.includes(splittext)==false) {
                    this.ctfolder.push((this.rgeneral[i].original_path).split("/",100)[indexid]);
                  }
                }
              }
            }
          }
       }
    }


  checkExistFile(file) {

    if (this.existFileIds.indexOf(file.id) > -1) {
      return  true
    } else {
      let len = this.alreadyExistFiles.length
      for(let i=0; i< len; i++) {
        if ( this.alreadyExistFiles[i].title == file.title && this.alreadyExistFiles[i].size == file.size){
          return  true
        }
      }
    }
  }
}
