import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Observable, Observer} from "rxjs";
import {RestService} from "../../../../rest/shared/rest.service";
import {env} from "../../../../../../.env";
import { any } from 'codelyzer/util/function';
import { arrayUnion } from 'ngx-modialog-7';
import { promise } from 'protractor';

@Injectable()
export class EventsResourceService
{

  constructor(public http: RestService) {}
  public apiPath = env.apiUrl;
  public backgURL = new Array(); 
  public body = new Array();
  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
            observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = err => {
          observer.error(err);
        };
      } else {
         observer.next(this.getBase64Image(img));
         observer.complete();
      }
    });
  }

   getBase64Image(img: HTMLImageElement) {
    
    const canvas: HTMLCanvasElement = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    const dataURL: string = canvas.toDataURL("image/png");
    
    return  dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }
  
  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}

async getBase64FromUrl(url: string) {

  const data = await fetch(url);

  const blob = await data.blob();



  if (data.status !== 200) {

    return null;

  }



  return new Promise((resolve) => {

    const reader = new FileReader();

    reader.readAsDataURL(blob);

    reader.onloadend = () => {

      resolve(reader.result);

    }

  });

}

  getEvents(trialline_id, withParam?, categories_id?, tags_id?, contacts_category_id?, contacts_id?,
            cross?, rangeFrom?, rangeTo?): Observable<any> {
    return this.http.get('trialline/' + trialline_id + '/events'
      + (withParam ? '?with=' + withParam : '?with=')
      + (categories_id ? '&category_id=' + categories_id : '')
      + (tags_id.length > 0 ? '&tag_id=' + tags_id : '')
      + (contacts_category_id? '&contacts_category_id=' + contacts_category_id : '')
      + (contacts_id? '&contacts_id=' + contacts_id : '')
      + (cross? '&cross=' + cross : '')
      + (rangeFrom? '&rangeFrom=' + rangeFrom : '')
      + (rangeTo? '&rangeTo=' + rangeTo : '')
      ).pipe(
       map( async (response: any) => { 

        let body = response.body;
       
        jQuery('#nameMonth .month').css({
          "opacity": '1'
        });

        return body.events;

        // Removed because of loading issue
        
        // this.body = response.body.events;

       
        // jQuery('#nameMonth .month').css({
        //   "opacity": '1'
        // });
        
        // let abcd = await Promise.all( 
        //   this.body.map(async(response:any, i) => {
            
        //     if(this.body[i].thumbnail_encryptid != 'null' && this.body[i].thumbnail_encryptid != null) {
        //       //this.apiPath+'filesystem/'+this.body[i].thumbnail_encryptid
        //       return await this.getBase64FromUrl(this.apiPath+'filesystem/'+this.body[i].thumbnail_encryptid);
        //     } else {
        //       return '/assets/images/start-event/trialline-event-thumb.jpg';
        //     }

        //   })
          
        // );

        // this.body.forEach((item, indx)=>{
        //   this.body[indx] = {...this.body[indx], backsideimage: abcd[indx]};
        // })

      
        
        // return this.body;

       


      }));
  }

  getTrackEvents(trialline_id): Observable<any> {
    return this.http.get('trialline/' + trialline_id + '/events/tracks').pipe(
      map((response: any) => {
        let body = response.body;
        return body.events;
      }));
  }

  getListEvents(trialline_id, data): Observable<any> {
    return this.http.post('trialline/' + trialline_id + '/events_all', data).pipe(
      map((response: any) => {
        let body = response.body;
        return body.data;
      }));
  }

  getEventsAll(trialline_id, withParam?, category_ids?): Observable<any>
  {
    return this.http.get('trialline/' + trialline_id + '/events?withHidden=1'
      + (withParam ? '&with=' + withParam : '?with=')
      + (category_ids ? '&category_id=' + category_ids : '')).pipe(
      map((response: any) => {
        let body = response.body;

        return body.events;
      }));
  }

  getEvent(trialline_id, id, withParam?, events = true) {
    return this.http.get('trialline/' + trialline_id + '/events/' + id + (withParam ? '?with=' + withParam : ''),
      {}, events)
  }

  updateEvent(trialline_id, id, data) {

    return this.http.post('trialline/' + trialline_id + '/events/' + id, data);
  }

  createEvent(trialline_id, data) {
    return this.http.post('trialline/' + trialline_id + '/events', data).pipe( map((response: any) => {
      let body = response.body;

      return body.event;
    }));
  }

  deleteEvent(trialline_id, event_id) {

    return this.http.delete('trialline/' + trialline_id + '/events/' + event_id).pipe(map(
      r => r.body
    ));
  }

  commentVisibility(trialline_id, event_id) {

    return this.http.put('trialline/' + trialline_id + '/events/' + event_id + '/commentsVisibility', [])
      .pipe( map((response: any) => {
      let body = response.body;
      return body.event;
    }));
  }

  toggleHidden(trialline_id, event_id) {

    return this.http.put('trialline/' + trialline_id + '/events/' + event_id + '/hidden', [])
      .pipe(map((response: any) => {
      let body = response.body;
      return body.event;
    }));
  }

  postComment(trialline_id, event_id, view_id, comment) {

    return this.http.post('trialline/' + trialline_id + '/events/' + event_id + '/comment/' + view_id,
      {comment: comment}).pipe( map((response: any) => {
      let body = response.body;

      return body.comment;
    }));
  }

  deleteComment(trialline_id, event_id, comment_id, view_id, level) {

    return this.http.delete('trialline/' + trialline_id + '/events/' + event_id + '/comment/' + view_id + '/'
      + comment_id + '?level=' + level).pipe( map((response: any) => {
      let body = response.body;

      return body.comment;
    }));

  }

  uploadVideo(trialline_id, video_url) {

    return this.http.post('trialline/' + trialline_id + '/events/videos', {video_url: video_url})
      .pipe( map((response: any) => {
      let body = response.body;

      return body.video;
    }));

  }

  deleteVideo(trialline_id, event_id, video_id) {

    return this.http.delete('trialline/' + trialline_id + '/events/' + event_id +  '/videos/' + video_id).pipe(map(
      r => r.body
    ));
  }

  importexcl(trialline_id, file) {
    //debugger
    return this.http.post('trialline/' + trialline_id + '/events/import_events',file).pipe(map(
      r => r.body
    ));
  }

  getTags(trialline_id) {

    return this.http.post('trialline/' + trialline_id + '/events/tags',[]);
  }

  getCcategories(trialline_id) {

    return this.http.post('trialline/' + trialline_id + '/events/ccategories',[]);
  }

  postCategories(trialline_id, data) {
    return this.http.post('trialline/' + trialline_id + '/categories', data);
  }

  /* Tag fillter */
  gettagEvents(trialline_id, withParam?,tag_id?): Observable<any> {
    console.log('again call to event')
    if (!(withParam[0] > 0) ) {
      withParam = ''
    }
    return this.http.get('trialline/' + trialline_id + '/events/tagfilter'
      + (withParam ? '?with=' + withParam : '?with=')
      + (tag_id ? '&tag_id=' + tag_id : '')).pipe(
      map((response: any) => {
        let body = response.body;

        

        return body.events;
      }));
  }

  deletetag(trialline_id,data) {

    return this.http.post('trialline/' + trialline_id + '/events/deletetag',data);
  }

 searchtag(trialline_id,data) {
    return this.http.post('trialline/' + trialline_id + '/events/searchtag', data);
  }

  gettagandcategoriesEvents(trialline_id, categories_id?, tags_id?, contacts_category_id?, contacts_id?,
                            cross?, rangeFrom?, rangeTo?): Observable<any> {

    return this.http.get('trialline/' + trialline_id + '/events/tagevents'
      + (categories_id ? '?category_id=' + categories_id : '?category_id=')
      + (tags_id.length > 0 ? '&tag_id=' + tags_id : '')
      + (contacts_category_id? '&contacts_category_id=' + contacts_category_id : '')
      + (contacts_id? '&contacts_id=' + contacts_id : '')
      + (cross? '&cross=' + cross : '')
      + (rangeFrom? '&rangeFrom=' + rangeFrom : '')
      + (rangeTo? '&rangeTo=' + rangeTo : '')
    ).pipe(
      map( async (response: any) => {
        let body = response.body;

        return body.events;
        // Commented blob image content
        // let bodyEvent = response.body.events; 
        
        // let abcd = await Promise.all( 
        //   bodyEvent.map(async(response:any, i) => {
            
        //     if(bodyEvent[i].thumbnail_encryptid != 'null' && bodyEvent[i].thumbnail_encryptid != null) {
        //       //this.apiPath+'filesystem/'+this.body[i].thumbnail_encryptid
        //       return await this.getBase64FromUrl(this.apiPath+'filesystem/'+bodyEvent[i].thumbnail_encryptid);
        //     } else {
        //       return '/assets/images/start-event/trialline-event-thumb.jpg';
        //     }

        //   })
          
        // );

        // bodyEvent.forEach((item, indx)=>{
        //   bodyEvent[indx] = {...bodyEvent[indx], backsideimage: abcd[indx]};
        // })

        // return bodyEvent;
      }));
  }

  updateTracks(trialline_id, tracks): Observable<any> {
    let data = {};
    tracks.forEach((item) => {
      data[item.id] = item.filterCategory + '=' + item.filterValue;
    })

    return this.http.post('trialline/' + trialline_id + '/tracks', data).pipe(
      map((response: any) => {
        let body = response.body;
        return body.trialline;
      }));
  }

  updatetag(trialline_id,data) {
    return this.http.post('trialline/' + trialline_id + '/events/edittag', data);
  }

  tagdelete(trialline_id,data) {

    return this.http.post('trialline/' + trialline_id + '/events/tagdelete', data);
  }

  addCustomColoumn(trialline_id,data) {
    return this.http.post('trialline/' + trialline_id + '/events/customcolumn', data);
  }
  getCustomColumns(trialline_id) {
    return this.http.get('trialline/' + trialline_id + '/events/customcolumns');
  }

  updateCustomColumns(trialline_id, data) {
    return this.http.post('trialline/' + trialline_id + '/events/updateCustomColumns', data);
  }

  deleteCustomColumns(trialline_id, data) {
    return this.http.post('trialline/' + trialline_id + '/events/deleteCustomColumns', data);
  }

  getSelectedColumns(trialline_id) {
    return this.http.get('trialline/' + trialline_id + '/events/getSelectedColumns');
  }

  updateSelectedColumns(trialline_id, data) {
    return this.http.post('trialline/' + trialline_id + '/events/updateSelectedColumns', data);
  }

  detachTag(trialline_id, event_id, tag_id, data) {

    return this.http.delete('trialline/' + trialline_id + '/events/' + event_id + '/tags/' + tag_id + '/detachTag',  data);
  }

  attachTag(trialline_id, event_id, tag_id, data) {

    return this.http.post('trialline/' + trialline_id + '/events/' + event_id + '/tags/' + tag_id + '/attachTag',  data);
  }

  createTagAndAttach(trialline_id, event_id, data) {

    return this.http.post('trialline/' + trialline_id + '/events/' + event_id + '/createTagAndAttach', data);
  }

  updateStartTime(trialline_id, event_id, data) {

    return this.http.post('trialline/' + trialline_id + '/events/' + event_id + '/updateStartTime',  data);
  }

  updateEndDate(trialline_id, event_id, data) {

    return this.http.post('trialline/' + trialline_id + '/events/' + event_id + '/updateEndDate',  data);
  }
  getTagList(trialline_id) {
    return this.http.get('trialline/' + trialline_id + '/events/tagList');
  }

  addFile(data) {
    return this.http.post('file', data);
  }
}
