
import {map} from 'rxjs/operators';
import {Router} from "@angular/router";
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { EventEmitter, Injectable, Output, Directive } from "@angular/core";


import {env} from "../../../../.env";
import {User} from "./user.model";

@Directive()
@Injectable()
export class AuthService
{
  @Output() userChangeEvent: EventEmitter<User|any> = new EventEmitter(true);

  userModel: User;

  protected oauthTokenName  = 'jwt';
  public authenticate: boolean;

  constructor(protected http: HttpClient, protected router: Router)
  {
    this.updateAuthenticate();
  }

  check(): boolean
  {
    return localStorage.getItem(this.oauthTokenName) != null;
  }

  addToFileSize(size) {

    let user: User = this.userModel;

    user.filled_filesize = parseFloat((user.filled_filesize + size).toFixed(1));

    this.emit(user);
  }

  emit(user?: User, nullable?: boolean)
  {
    if (nullable == true) {
      return this.userChangeEvent.emit(null);
    }

    if (user == null) {
      this.getUser();
    } else {

      this.userModel = user
    }

    this.userChangeEvent.emit(user);
  }

  user(): EventEmitter<User>
  {
    if (! this.check()) {
      return this.userChangeEvent;
    }

    this.getUser();

    return this.userChangeEvent;
  }

  token(): string
  {
    return localStorage.getItem(this.oauthTokenName);
  }

  store(token: string): void
  {
    this.user();
    localStorage.setItem(this.oauthTokenName, token);
    this.updateAuthenticate();
  }

  logout(): void
  {
    this.emit(null, true);
    localStorage.removeItem(this.oauthTokenName);
    localStorage.clear();
    this.updateAuthenticate();
  }

  updateAuthenticate(): void
  {
    this.authenticate = this.check();
  }

  loadUser() {

    return new Promise((resolve, reject) => {


      let httpOptions = {
        headers: new HttpHeaders({'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token()})
      };

      this.http.get(env.apiUrl + 'user', httpOptions).subscribe(
        (user: User) => {
          this.userModel = user;
          this.emit(user);
          resolve(user);
        },
        (error: any) => {
          reject(error);
        }
      );
    })
  }

  protected getUser(): void
  {

   let httpOptions = {
        headers: new HttpHeaders({'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token()})
      };
    this.http.get(env.apiUrl + 'user',  httpOptions).subscribe(
      (res: any) => {
       let user = res.body.user
        this.userModel = user;
        this.emit(user);
      },
      (error: any) => {
        console.log('error');
        this.logout();
        this.router.navigateByUrl('/');
      }
    );
  }
}
