<div *ngIf="zipProcessing"
  style="position: fixed;top: 45%;left: 40%;background: #eff3f8;padding: 10px;border: 1px solid #044e89;border-radius: 10px;z-index: 9999999999;">
  <div style="text-align: center;">
    <img src="assets/squares.gif" class="img spinners" alt="spinner" style="opacity: .8;" />
  </div>
  <div style="font-weight: bold;">
    <div class="totat_zip_size" style="">
    </div>
    <div style="">
      We are creating the zip! Please wait...
    </div>
  </div>
</div>
<div class="page-container page-wrap">
  <div class="page-content-wrapper">
    <div class="page-head">
      <div class="container">
        <!-- BEGIN Search -->
        <div class="page-title">
          <div class="inputs">
            <div class="portlet-input input-inline input-xlarge ">
              <div class="input-icon right">
                <i class="fa fa-search"></i>
                <input type="text" class="form-control form-control-solid input-circle"
                  placeholder="Search All TrialLines">
              </div>
            </div>
          </div>
        </div>
        <!-- END Search -->
      </div>
    </div>
    <div class="page-content">
      <div class="container">
        <!--BEGIN Breadcrumb-->
        <ul class="page-breadcrumb breadcrumb">
          <li>
            <a [routerLink]="['/dashboard']">My Dashboard</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <span>Edit Settings</span>
          </li>
        </ul>
        <!--END Breadcrumb-->
        <div class="portlet light ">
          <div class="portlet-title">
            <div class="caption">
              <i class="icon-social-dribbble font-purple-soft"></i>
              <span class="caption-subject font-purple-soft bold uppercase">{{ trialline?.name }}</span>
              <p class="left-actions" style="margin-top: 10px;">
                <a [routerLink]="['../']" class="btn blue btn-circle btn">Return to timeline view</a>
              </p>
            </div>
            <div class="actions">
              <p class="text-muted">{{ trialline?.payment_date | date:'MM/dd/yyyy' }}</p>
              <!--<a (click)="archiveTrialline()" class="btn blue btn-sm btn-circle btn-w110 btn-lg"-->
              <!--data-confirm="TrialLine will be archived. While it's not active, no one will have an access to view it. The month fee is 0.99$. Do you wish to proceed?">-->
              <!--<span class="ladda-label">Archive</span>-->
              <!--</a>-->
              <!-- <a (click)="deleteTrialline()"
                 class="btn red btn-outline btn-circle btn-w110">Delete</a> -->
            </div>
          </div>
          <form #triallineForm="ngForm" (submit)="update(triallineForm)">
            <div class="portlet-body">
              <div class="tabbable-line">
                <ul class="nav nav-tabs ">
                  <li class="active">
                    <a href="#general" data-toggle="tab" (click)="findtab(0)"> General Info </a>
                  </li>
                  <li>
                    <a href="#access" data-toggle="tab"
                      *ngIf="_layout.user && (_layout.user?.user_type != 3 || (_layout.user?.team_access_level == 3 || _layout.user?.team_access_level == 2 ))&&_layout.user?.user_type != 5"
                      (click)="findtab(0)"> Manage Team </a>
                  </li>
                  <li>
                    <a href="#contacts" data-toggle="tab" (click)="findtab(0)"> Case Contacts </a>
                  </li>
                  <li>
                    <a href="#files" data-toggle="tab" (click)="findtab(1)"> Case File Library </a>
                  </li>
                  <li>
                    <a href="#categories" data-toggle="tab" (click)="findtab(0)"> Categories </a>
                  </li>
                  <li>
                    <a href="#tags" data-toggle="tab" (click)="findtab(0)">Tags</a>
                  </li>
                  <li>
                    <a href="#background" data-toggle="tab" (click)="findtab(0)"> Background </a>
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane active" id="general">
                    <div class="form-body">
                      <div class="form-group">
                        <label>Title</label>
                        <input type="text" name="name" class="form-control" [ngModel]="trialline.name">
                        <span class="help-block" *ngIf="errors?.name" style="color: red;">{{ errors?.name[0] }}</span>
                      </div>
                      <div class="form-group">
                        <label>Description</label>
                        <ckeditor [ngModel]="trialline.description" name="description" debounce="500" [config]="{ versionCheck: false }"
                          editorUrl="https://cdn.ckeditor.com/4.5.11/full-all/ckeditor.js"></ckeditor>
                        <!--<textarea class="ckeditor form-control" name="description" rows="6"-->
                        <!--style="visibility: hidden; display: none;">{{ trialline?.description }}</textarea>-->
                      </div>
                      <div class="form-group">
                        <label>Which date format would you like to use?</label>
                        <select name="trialline_date_format" [(ngModel)]="trialline.trialline_date_format"
                          class="form-control">
                          <option *ngFor="let dateFormat of dateFormatList" [value]="dateFormat.key">
                            {{dateFormat.value}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <!-- TAGS -->
                  <div class="tab-pane" id="tags">
                    <app-tags [triallineid]="triallineId"></app-tags>
                  </div>
                  <!-- END -->
                  <div class="tab-pane" id="background">
                    <div class="portlet-body">
                      <button (click)="$event.preventDefault(); switch()" class="btn"
                        [class.btn-default]="trialline.background_source == 'own' ? true : null"
                        [class.btn-primary]="trialline.background_source != 'own' ? true : null"
                        style="margin-bottom: 10px;">
                        {{ trialline.background_source == 'own' ? 'Use Template Background' : 'Use My Own Background' }}
                      </button>
                      <div class="form-body" [hidden]="trialline.background_source != 'own'">
                        <div class="form-group ">
                          <label class="note-small">
                            Click on the image or icon below to upload a new background.
                          </label><br>
                          <label for="background_input">
                            <input type="file" ng2FileSelect [uploader]="uploader" id="background_input"
                              style="display: none;" [accept]="allowedMimeTypes.toString()">
                            <div class="fileinput fileinput-new upload-box">
                              <div class="fileinput-preview thumbnail tile upload-files"
                                style="width: 210px; position: relative;">
                                <div class="tile-body">
                                  <img *ngIf="trialline.background_file && trialline.background_file != undefined"
                                    [attr.src]="apiPath + 'filesystem/' + trialline.background_file.encrypt_id +
                                        '?preview=1'" class="img-responsive" width="200px" alt="thumbnail">
                                  <img *ngIf="!trialline.background_file && backgrounds[0] != undefined"
                                    [attr.src]="apiPath + 'filesystem/' + backgrounds[0] + '?preview=1'"
                                    class="img-responsive" width="200px" alt="thumbnail">
                                </div>
                              </div>
                            </div>
                          </label>
                          <div class="progress" style="height: 3px; width: 200px; margin: 0">
                            <div class="progress-bar" role="progressbar" [style.width]="uploader.progress + '%'"></div>
                          </div>
                          <p class="help-block small">
                            <span class="label label-sm label-danger">Note:&nbsp;</span>
                            For best results, background image or video should be at least 1908 x 901 pixels.
                          </p>
                          <div class="background_trialline container">
                            <div class="row">
                              <div class="image-picker">
                                <div class="form-group">
                                  <div *ngFor="let file of trialline.files">
                                    <label *ngIf="file.destination === 'background'" for="file{{file.id}}"
                                      class="col-lg-4 col-md-6 col-xs-12" (click)="checkedBackground(file)">
                                      <div class="remove" (click)="removeFile(file)">&times;</div>
                                      <img [attr.src]="apiPath + 'filesystem/' +  file.encrypt_id  + '?preview=1'"
                                        [attr.class]="file.id == backgroundId ? 'img_checked' : null ">
                                      <input type="radio" name="template_src" id="file{{file.id}}" value="{{ file.id }}"
                                        [attr.checked]="file.id == backgroundId ? true : null">
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-body" [hidden]="trialline.background_source != 'template'">
                        <select class="image-picker" name="template_src">
                          <option *ngFor="let background of backgrounds"
                            [attr.data-img-src]="apiPath + 'filesystem/' + background.encrypt_id + '?preview=1'"
                            [attr.data-img-class]="'img img-responsive'" value="{{ background.id }}"
                            [attr.selected]="background.id == backgroundId ? true : null">
                            {{ background.title }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane category-table" id="categories">
                    <app-trialline-edit-categories [categoryEvents]="trialline?.events"
                      [categories]="trialline?.categories"></app-trialline-edit-categories>
                  </div>
                  <div class="tab-pane category-table" id="contacts">
                    <app-trialline-contacts (onUpdatedFile)="addFileFromContacts($event)"
                      [filesUpdate]="updateFileToContact" [contactEvents]="trialline?.events"
                      [contacts]="trialline?.contacts"></app-trialline-contacts>
                  </div>
                  <div class="upload-box tab-pane upload-box-tab" id="files">
                    <div style="padding:0px;">
                      <div class="col-md-8">
                        <app-shared-dropzone (onSuccess)="addFile($event)"
                          (onQueueChanged)="handleDropzoneQueue($event)" [path]="fullpath">
                        </app-shared-dropzone>
                      </div>
                      <div class="col-md-4">
                        <div class="upload-container" style="padding:8px;">
                          <div style="height: 141px;">
                            <button (click)="$event.preventDefault(); openFromLibraryModal()"
                              class="btn btn-lg btn-primary" style="margin-top: 40px; font-size: 14px;">Click here to
                              add a file <br> from the Company file library.</button>
                            <app-shared-from_library *ngIf="fromLibraryModal" [triallineId]="triallineId"
                              [existFileIds]="exceptFileIds" [tlineLibraryList]="false" (onSelected)="addFile($event)"
                              [exceptFileName]="exceptFileName">
                            </app-shared-from_library>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr
                      style="height:1px;border:none;color:#868383;background-color:#a9a7a7;margin-top:8px;margin-right:1pc;" />
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-sm-7">
                            <i *ngIf='structure == false && backfolder.length>0'
                              style="font-size: 16px;color: #0075bb;cursor:pointer;" class="fa fa-plus-circle"
                              aria-hidden="true" (click)="assigne()" title="Show folder structure "></i>
                            <i *ngIf='structure == true' style="font-size: 16px;color: #0075bb;cursor:pointer;"
                              class="fa fa-minus-circle" aria-hidden="true" (click)="hide()"
                              title="Hide folder structure "></i>
                            <span (click)="backtofolder(1)"
                              style="font-size: 15px;color: #0075bb;cursor: pointer;text-decoration: none;font-family: GibsonSemiBold,Geneva,Tahoma,Verdana,sans-serif;line-height: 1.5em;font-weight:bold;font-style: normal;"
                              title="Home Directory">
                              You are in folder <i class="fa fa-arrow-right" aria-hidden="true">&nbsp; </i>Home
                            </span>
                            <!-- <span *ngFor="let sa of backfolder"><a  (click)="backtofolder(sa)" style="font-size: 20px;color:#0075bb;cursor: pointer;text-decoration: none;font-family: GibsonSemiBold,Geneva,Tahoma,Verdana,sans-serif;line-height: 1.5em;font-weight: normal;font-style: normal;">/ {{sa}}</a>
                              </span>  -->
                            <span *ngIf='structure==false'>
                              <span *ngFor="let sa of backfolder;let i = index ">
                                <a (click)="backtofolder(sa)"
                                  style="font-size: 15px;color:#0075bb;cursor: pointer;text-decoration: none;font-family: GibsonSemiBold,Geneva,Tahoma,Verdana,sans-serif;line-height: 1.5em;font-weight:bold;font-style: normal;"
                                  title={{sa}}>
                                  <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                  {{sa}}
                                </a>
                              </span>
                            </span>
                            <span *ngIf='structure==true'>
                              <span *ngFor="let sa of backfolder;let i = index "><a (click)="backtofolder(sa)"
                                  style="font-size: 15px;color:#0075bb;cursor: pointer;text-decoration: none;font-family: GibsonSemiBold,Geneva,Tahoma,Verdana,sans-serif;line-height: 1.5em;font-weight:bold;font-style: normal;"
                                  title={{sa}}>
                                  <div id="divmid{{i+1}}"><span style='font-size:27px;'>&#8627;</span>{{sa}}</div>
                                </a>
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12" style="margin-bottom:10px;">
                          <hr style="height:1px;border:none;color:#868383;background-color:#a9a7a7;margin-top: 7px;" />
                          <!-- Uncomment This Code  -->
                          <span *ngIf="this.newsubfoldervisible==true" id="folderbtn-newsub" style="margin-left:35pc;">
                            <span data-toggle="modal" data-target="#submyModal">Create a Sub Folder</span>
                          </span>
                          <!-- Temp-1 -->
                          <div *ngIf='backfolder.length>0' class="col-md-4" style="margin-top: 25px;"
                            title="click here to go up">
                            <a (click)="levelup()"> <i class="fa fa-folder-open"
                                style="font-size: 24px;color: #034375;"></i>&nbsp;&nbsp;
                              <!-- <span style="font-size: 16px;font-family: sans-serif;font-weight: bold;"> -->
                              <!-- <i class="fa fa-ellipsis-h"></i> -->
                              <span style='font-size:22px;'>&#8226;</span><span style='font-size:22px;'>&#8226;
                              </span></a>
                          </div>
                          <div *ngFor="let nefo of TempFolder |orderByName">
                            <div class="col-md-3" style="margin-top: 1pc;">
                              <div class="row">
                                <div class="col-sm-12 col-md-12">
                                  <a (click)="OpenTrlFolder('',nefo)">
                                    <i class="fa fa-folder-open"
                                      style="font-size: 24px;color: #034375;"></i>&nbsp;&nbsp;<span
                                      style="font-size: 16px;font-family: sans-serif;font-weight: bold;">
                                      {{nefo}}
                                    </span></a>
                                  <i class="fa fa-pencil-square-o" aria-hidden="true"
                                    style="margin-left:0pc;font-size:14px;font-weight: initial;color: #337ab7; padding: 5px 4px 4px 4px;vertical-align: -0.1rem;"
                                    title="Rename Folder" (click)="renamefoldermodal(nefo)"></i>
                                  <i class="fa fa-trash" aria-hidden="true"
                                    style="margin-left:0px;font-size: 14px;font-weight: initial;color: #337ab7;"
                                    title="Delete Folder" (click)="deletefoldermodal(nefo)"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- <label *ngIf="TempFolder.length==0" style="font-weight: bold;color: #337ab7;">*Looks like you haven't created any folder to <span style="color: black;">{{foldernametoshow}}</span> yet.</label> -->
                        </div>
                      </div>
                      <div id="upload-filter-form" class="form-inline no-validate">
                        <div class="form-group">
                          <select (change)="selectFileType($event)" #filterType name="type" class="form-control">
                            <option value="" selected>All Types</option>
                            <option value="image">Image</option>
                            <option value="text">Text</option>
                            <option value="table">Excel</option>
                            <option value="pdf">PDF</option>
                            <option value="audio">Audio</option>
                            <option value="video">Video</option>
                          </select>
                        </div>
                        <div class="form-group">
                          <label class="sr-only" for="idSearch">Search</label>
                          <input #filterName type="text" name="search" class="form-control" id="idSearch"
                            placeholder="Search" value="" (keyup)="filterFileName($event)">
                        </div>
                        <div class="pull-right" style="margin-top: 7px;">
                          <span id="folderbtn" class="folderbtn" style="margin-left:-1pc; font-size: 12px;"
                            (click)='zipfiles()' title="Export Documents">
                            <!--<i class="fa fa-folder" style="color:white;font-size: 19px;"></i> -->
                            Download All Case Documents to my computer(ZIP)
                          </span>
                          <span id="folderbtn-m" class="folderbtn" style="margin:10px;padding: 8px 11px;">
                            <!--<i class="fa fa-folder" style="color:white;font-size: 19px;"></i> -->
                            <span data-toggle="modal" data-target="#moveModal" (click)="clearallchek()"
                              title="Move Files"> Move Files</span>
                          </span>
                          <span id="folderbtn-newfolder" class="folderbtn" style="padding: 8px 10px;"
                            title="Create Folder">
                            <!-- <i class="fa fa-folder" style="color:white;font-size: 19px;"></i> -->
                            <span data-toggle="modal" data-target="#myModal">Create a New Folder</span>
                          </span>
                          <!-- End -->
                        </div>
                      </div>

                      <!-- Left Side - Buttons for Multiple Actions -->
                      <div *ngIf='isButtonsShow && !listiconview' style="display:inline-block">
                        <span disabled id="download-to-computer" (click)="multiDownloadToComputer()" class="folderbtn"
                          style="margin-right: 3px; padding: 5px 5px; font-size: 8pt; margin-bottom: 0;">
                          Download to Computer
                        </span>
                        <span id="delete-from-file-library" (click)="multiDeleteFromFileLibraryPopup()"
                          class="folderbtn"
                          style="margin-right: 3px; padding: 5px 5px; font-size: 8pt; margin-bottom: 0;">
                          Delete from File Library
                        </span>
                        <span id="upload-to-company-file-library" (click)="multiUploadToComputerFileLibrary()"
                          class="folderbtn"
                          style="margin-right: 3px; padding: 5px 5px; font-size: 8pt; margin-bottom: 0;">
                          Upload to Company File Library
                        </span>
                      </div>
                      <!-- Right Side - list view or icon view button -->
                      <span *ngIf='listiconview' id="listbtn" (click)="icontolist(false);"
                        style="display: inline-block;float: right;margin-bottom: 0;margin-right: 0;padding: 5px 5px; font-size: 8pt;">
                        <i class="fa fa-list"></i> Switch to List View
                      </span>
                      <span *ngIf='!listiconview' id="iconbtn" (click)="icontolist(true);"
                        style="display: inline-block;float: right;margin-bottom: 0;margin-right: 0;padding: 5px 5px; font-size: 8pt;">
                        <i class="fa fa-th-large"></i> Switch to Icon View
                      </span>
                      <!-- Horizontal Line -->
                      <hr
                        style="height: 1px;border: none;color: #868383;background-color: #a9a7a7;float: left;width: 100%;margin-top: 0;" />
                      <div *ngIf='listiconview' class="row">
                        <div class="col-md-12">
                          <label *ngIf="filesi.length==0" style="font-weight: bold;color: #337ab7;">
                            *Looks like you haven't added any documents to
                            <span style="color: black;">{{foldernametoshow}}</span> yet.
                          </label>
                          <img *ngIf="isLoading" src="assets/squares.gif" class="img spinners" alt="spinner"
                            style="opacity: .6;position: relative;top: 10px;left:10%;z-index: 9999999;">
                          <div class="tiles upload-files no-preview" style="margin-top: -1pc;">
                            <ul>
                              <ng-container onScrollDown(true)>
                                <li *ngFor="let file of filesi | paginate: { id: 'foo',
                                                                  itemsPerPage: perPage,
                                                                  currentPage: page,
                                                                  totalItems: total}; let i = index;"
                                  class="tile image" (click)="selectFile(file)">
                                  <div class="tile-body">
                                    <div class="cloud" (click)="$event.stopPropagation(); toggleCloud(file)"
                                      [class.cloud-active]="file.in_library ? true : null"
                                      title="Add file to company File Library">
                                      <i class="fa fa-cloud"></i>
                                    </div>
                                    <div class="cloud" style="left: 35px;"
                                      (click)="$event.stopPropagation(); editFile(file)" title="Edit file settings">
                                      <i class="fa fa-gear"></i>
                                    </div>
                                    <div class="cloud" style="left: 65px;"
                                      (click)="$event.stopPropagation(); connectContact(file)"
                                      title="Associate File with Case Contact">
                                      <i class="fa fa-user"></i>
                                    </div>
                                    <div class="remove" (click)="$event.stopPropagation(); remove(file)">&times;</div>
                                    <div *ngIf="file.type=='image'" class="upload-file-img"
                                      title="{{ getFileName(file.title) }}"
                                      [style.background-image]="'url(' + apiPath + 'filesystem/' + file.encrypt_id + '?preview=1)'">
                                    </div>
                                    <div *ngIf="file.type!='image'" class="upload-file-img"
                                      title="{{ getFileName(file.title) }}">
                                      <img src="{{ imageIcons(file.type) }}" style="width: inherit;"
                                        alt="{{ getFileName(file.title) }}">
                                    </div>
                                    <div class="tile-object">
                                      <div class="name" style="width: 127px;overflow: hidden; line-break: anywhere;">
                                        {{getFileName(file.title)}}</div>
                                    </div>
                                  </div>
                                </li>
                              </ng-container>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="!listiconview" class="conteiner">
                        <div class="row">
                          <div class="col-xs-12 col-sm-2 bor-file nop col-thumb">
                            <div class="col-xs-5 col-sm-12 tit-file-main  tbm title-thumb"><input id="selectAllChecks"
                                class="selectAllChecks" type="checkbox" (click)="selectAllFiles()" /> Select All</div>
                          </div>
                          <div class="col-xs-12 col-sm-1 bor-file nop col-thumb">
                            <div class="col-xs-5 col-sm-12 tit-file-main  tbm title-thumb">Preview</div>
                          </div>
                          <div class="col-xs-12 col-sm-3 bor-file nop col-file-title ">
                            <div (click)="reorder('title')"
                              class="col-xs-5 col-sm-12 tit-file-main tbm title-file-title filter-by">
                              Title <span *ngIf="currentStatus=='title'" class="e2e-inner-html-bound"
                                [style.opacity]="order == 'title' ? '1' : '.3'"
                                [innerHTML]="getSortIcon('title')"></span></div>
                          </div>
                          <div class="col-xs-12 col-sm-1 col-md-1 bor-file nop col-file-tipe">
                            <div class="col-xs-5 col-sm-12 tit-file-main tbm title-file-tipe filter-by"
                              (click)="reorder('type')">
                              Type <span *ngIf="currentStatus=='type'" class="e2e-inner-html-bound"
                                [style.opacity]="order == 'type' ? '1' : '.3'" [innerHTML]="getSortIcon('type')"></span>
                            </div>
                          </div>
                          <!-- <div class="col-xs-12 col-sm-2 bor-file nop col-atta">
                              <div class="col-xs-5 col-sm-12 tit-file-main tbm title-atta filter-by"
                                   (click)="reorder('attached')">
                                Attached to <span *ngIf="currentStatus=='attached'"  class="e2e-inner-html-bound"
                                                  [style.opacity]="order == 'attached' ? '1' : '.3'"
                                           [innerHTML]="getSortIcon('attached')"></span></div>
                          </div> -->
                          <div class="col-xs-12 col-sm-2 col-md-2 bor-file nop col-desc">
                            <div class="col-xs-12 col-sm-12 tit-file-main tbm title-desc filter-by"
                              (click)="reorder('description')">
                              Description <span *ngIf="currentStatus=='description'" class="e2e-inner-html-bound"
                                [style.opacity]="order == 'description' ? '1' : '.3'"
                                [innerHTML]="getSortIcon('description')"></span></div>
                          </div>
                          <div class="col-xs-12 col-sm-2 col-md-1 bor-file nop col-desc">
                            <div class="col-xs-12 col-sm-12 tit-file-main tbm title-desc"
                              (click)="reorder('created_at')">
                              Upload Date <span *ngIf="currentStatus=='created_at'" class="e2e-inner-html-bound"
                                [style.opacity]="order == 'created_at' ? '1' : '.3'"
                                [innerHTML]="getSortIcon('upload')"></span></div>
                          </div>
                          <div class="col-xs-12 col-sm-2 col-md-2 bor-file nop col-file-act">
                            <div class="col-xs-5 col-sm-12 tit-file-main tbm title-file-act">Action</div>
                          </div>
                        </div>
                        <!-- <div *ngFor="let file of (allFiles | filterFiles:filterType.value:filterName.value)" class="row row-files"> -->
                        <div>
                          <label *ngIf="filesi.length==0" style="font-weight: bold;color: #337ab7;">
                            *Looks like you haven't added any documents to
                            <span style="color: black;">{{foldernametoshow}}</span> yet.</label>
                        </div>
                        <div *ngFor="let file of filesi | paginate: { id: 'foo',
                                                                  itemsPerPage: perPage,
                                                                  currentPage: page,
                                                                  totalItems: total}; let i = index;"
                          class="row row-files">
                          <div class="col-xs-12 col-sm-2 bor-file nop col-file-title">
                            <div class="col-12" style="margin-top:15px; text-align: center;">
                              <input id="multiselect_checkbox_{{i}}" class="multiFiles" type="checkbox"
                                (click)="multiFilesChecked(file, i)" />
                            </div>
                          </div>
                          <div class="col-xs-12 col-sm-1 bor-file nop col-thumb">
                            <div class="col-xs-5 col-sm-12 tit-file  tbm title-thumb">Preview</div>
                            <div class="col-xs-7 col-sm-12 inf-file  tbm info-thumb" (click)="selectFile(file)">
                              <img *ngIf="file.type!='pdf'"
                                [attr.src]="apiPath + 'filesystem/' + file.encrypt_id + '?preview=1'"
                                alt="{{ getFileName(file.title) }}" class="img-thumbnail"
                                style="height: 25px; max-width: 75px;">
                              <img *ngIf="file.type=='pdf'" src="assets/pdfimages/pdf1.png" class="img-thumbnail"
                                style="height: 25px; max-width: 75px;" alt="{{ getFileName(file.title) }}">
                            </div>
                          </div>
                          <div class="col-xs-12 col-sm-3 bor-file nop col-file-title">
                            <div (click)="reorder('title')" class="col-xs-5 col-sm-12 tit-file tbm title-file-title">
                              Title f
                            </div>
                            <div class="col-xs-7 col-sm-12 inf-file tbm info-file-title" style=" line-break: anywhere;">
                              {{ getFileName(file.title) }}
                            </div>
                          </div>
                          <div class="col-xs-12 col-sm-1 col-md-1 bor-file nop col-file-tipe">
                            <div class="col-xs-5 col-sm-12 tit-file tbm title-file-tipe">Type</div>
                            <div class="col-xs-7 col-sm-12 inf-file tbm info-file-tipe">{{ file.type }}</div>
                          </div>
                          <!-- <div class="col-xs-12 col-sm-2 bor-file nop col-atta">
                              <div class="col-xs-5 col-sm-12 tit-file tbm title-atta">Attached to</div>
                              <div class="col-xs-7 col-sm-12 inf-file tbm info-atta">
                                  <div class="atta" *ngFor="let item of file.list">
                                      {{ item }}
                                  </div>
                              </div>
                          </div> -->
                          <div class="col-xs-12 col-sm-2 col-md-2 bor-file nop col-desc">
                            <div class="col-xs-12 col-sm-12 tit-file tbm title-desc">Description</div>
                            <div id='descdiv_{{i}}' class="col-xs-12 col-sm-12 inf-file tbm info-desc"
                              title="{{ file.description }}" (click)='showdescription(i)'>
                              {{ file.description }}
                            </div>
                            <div style="display: none;" id='descdivclose_{{i}}'
                              class="col-xs-12 col-sm-12 inf-file tbm info-desc" title="{{ file.description }}"
                              (click)='closedescription(i,file.description)'>
                              {{ file.description }}
                            </div>
                          </div>
                          <div class="col-xs-12 col-sm-2 col-md-1 bor-file nop col-desc">
                            <div class="col-xs-12 col-sm-12 tit-file tbm title-desc">Upload Date</div>
                            <div id='upload_{{i}}' class="col-xs-12 col-sm-12 tbm info-desc"
                              title="{{ file.created_at | date }}">
                              {{ file.created_at | date }}
                            </div>
                            <div style="display: none;" id='uploadclose_{{i}}' class="col-xs-12 col-sm-12 tbm info-desc"
                              title="{{ file.created_at | date }}">
                              {{ file.created_at | date }}
                            </div>
                          </div>
                          <div class="col-xs-12 col-sm-2 col-md-2 bor-file nop col-file-act">
                            <div class="col-xs-5 col-sm-12 tit-file tbm title-file-act">Action</div>
                            <div class="col-xs-12 col-sm-12 inf-file tbm info-file-act">
                              <span id='ebtn' (click)="$event.stopPropagation(); editFile(file)"
                                class="btn btn-sm btn-info" title="Edit">
                                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                              </span>
                              <span id='cbtn' (click)="$event.stopPropagation(); connectContact(file)"
                                class="btn btn-sm btn-info" style="margin-left:5px; margin-right:5px;"
                                title="Connect image to a Contact">
                                <i class="fa fa-user" aria-hidden="true"></i>
                              </span>
                              <span id='rbtn' (click)="$event.stopPropagation(); remove(file)"
                                class="btn btn-sm btn-danger" title="Delete from everywhere"><i class="fa fa-times"
                                  aria-hidden="true"></i>
                              </span>
                              <!-- <span id='smbtn' (click)="$event.stopPropagation(); toggleCloud(file)"
                                        class="btn btn-sm"
                                          title="Hide from file library"><i class="fa fa-lock" aria-hidden="true"></i>
                                  </span> -->
                            </div>
                          </div>
                          <div class="col-xs-12 col-sm-2 col-md-4 bor-file nop col-desc"></div>
                          <div class="col-xs-12 col-sm-2 col-md-7 bor-file nop col-desc" style="margin-top: -1pc;">
                            <textarea id='desc_{{i}}' rows="1" cols="40" value="{{ file.description }}"
                              class="form-control" style="display: none;" readonly title="click to Edit"
                              (focusout)="hidedescription(i)"
                              (click)="$event.stopPropagation(); editFile(file)"></textarea>
                          </div>
                        </div>
                      </div>
                      <div style="padding-top: 10px">
                        <select (change)="changePages($event)" style="float: right; margin-left: 10px">
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        <div style="float: right">
                          <!--                          <pagination-controls (pageChange)="page = $event"></pagination-controls>-->
                          <pagination-controls id="foo" (pageChange)="pageChanged($event)" maxSize="9" responsive="true"
                            previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination"
                            screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page">
                          </pagination-controls>
                        </div>
                      </div>
                      <app-file-edit-case [file]="file" (onUpdated)="updated($event)"></app-file-edit-case>
                    </div>
                  </div>
                  <div class="tab-pane category-table" id="access" *ngIf="_layout.user && (_layout.user?.user_type != 3 || (_layout.user?.team_access_level == 3
                       || _layout.user?.team_access_level == 2))">
                    <app-trialline-edit-team [teamIds]="trialline.team_ids" [triallineId]="trialline.id">
                    </app-trialline-edit-team>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-actions">
              <button *ngIf="this.savehideshow==true" [disabled]="! saveAvailable" type="submit" name="saveBtn"
                value="saveBtn" class="btn blue" style="margin: 10px;">
                Save Change
              </button>
              <a [routerLink]="['/dashboard']">
                <button type="button" class="btn default" *ngIf="this.savehideshow==true">Cancel</button>
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Create New Folder Model -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" *ngIf="!modalStatus"
  aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header" style="border-bottom:none">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
        <h5 class="modal-title" id="myModalLabel">New Folder</h5>
      </div>
      <div class="modal-body" style="height: 36px;">

        <input class="form-control input-text" id="input-text" type="text" [(ngModel)]="cfolderNamemain"
          placeholder="New Folder" required />
      </div>
      <div class="modal-footer" style="border-top:none">
        <button type="button" class="btn btn-sm btn-default" data-dismiss="modal">Cancel</button>
        <!-- <button type="button" class="btn btn-sm btn-primary" (click)="createFolder()">Create</button> -->
        <button type="button" class="btn btn-sm btn-primary" (click)="createFolderonmaindirectory()">
          Create
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Create New Folder Model -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" *ngIf="!modalStatus"
  aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header" style="border-bottom:none">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
        <h5 class="modal-title" id="myModalLabel">New Folder</h5>
      </div>
      <div class="modal-body" style="height: 36px;">
        <input class="form-control input-text" id="input-text" type="text" [(ngModel)]="folderNamemain"
          placeholder="New Folder" required />
      </div>
      <div class="modal-footer" style="border-top:none">
        <button type="button" class="btn btn-sm btn-default" data-dismiss="modal">Cancel</button>
        <!-- <button type="button" class="btn btn-sm btn-primary" (click)="createFolder()">Create</button> -->
        <button type="button" class="btn btn-sm btn-primary" (click)="createFolderonmaindirectory()">Create</button>
      </div>
    </div>
  </div>
</div>
<!-- Create sub Folder Model -->
<div class="modal fade" id="submyModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header" style="border-bottom:none">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
        <h5 class="modal-title" id="myModalLabel">New Sub Folder</h5>
      </div>
      <div class="modal-body" style="height: 36px;">
        <input class="form-control input-text" id="input-text" type="text" [(ngModel)]="folderName"
          placeholder="New Sub Folder" required />
      </div>
      <div class="modal-footer" style="border-top:none">
        <button type="button" class="btn btn-sm btn-default" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-sm btn-primary" (click)="createFolder()">Create</button>
      </div>
    </div>
  </div>
</div>
<div id="preview-modal" class="modal fade bs-modal-lg in" tabindex="-1" role="dialog"
  style="z-index: 9999999; overflow: visible">
  <div class="modal-dialog modal-lg modal-preview " ngClass="{{ fullscreen ?
   'zoom_active' : 'zoom_deactivate' }}">
    <div class="modal-content draggable" #previewModal2 [style.width]="fullscreen ? '100%' : width+'px' "
      [style.height]="fullscreen ? '100%' : height+'px'"
      [style.transform]="'translate3d('+ left + 'px,' + top + 'px,' + '0px)'"
      [class.active]="status === 1 || status === 2" (mousedown)="setStatus($event, 2)"
      (window:mouseup)="setStatus($event, 0)">
      <div class="modal-header">
        <button (click)="closePreviewer()" type="button" class="close"><span
          aria-hidden="true">&times;</span></button>
        <!-- <button (click)="zoomPreviewToggle()" type="button" class="close close1 fullscrean">
              <span aria-hidden="true">
                <i class="fa fa-search-{{ fullscreen ? 'minus' : 'plus' }}" aria-hidden="true"></i>
              </span>
        </button> -->
        <h4 class="modal-title">{{file?.title.length > 50 ? (file?.title.substr(0, 50) + '...') : file?.title}}</h4>
      </div>
      <div class="modal-body {{file?.type === 'image' ? 'body-image' : ''}} -{{file?.mime}}-
          {{file?.type === 'pdf' ? 'body-pdf' : ''}}" [ngSwitch]="file?.type || file?.mime">

        <img *ngSwitchCase="'image'" class="img-responsive" [attr.src]="getSafeResourceUrl(file)">
        <video *ngSwitchCase="'video'" width="100%" controls class="test">
          <source [attr.src]="getSafeResourceUrl(file)" type="video/mp4">
          Your browser does not support HTML5 video.
        </video>
        <audio *ngSwitchCase="'audio/mp3'" controls>
          <source [attr.src]="getSafeResourceUrl(file)" type="audio/mpeg">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/wav'" controls>
          <source [attr.src]="getSafeResourceUrl(file)" type="audio/wav">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/flac'" controls>
          <source [attr.src]="getSafeResourceUrl(file)" type="audio/flac">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio'" controls>
          <source [attr.src]="getSafeResourceUrl(file)" type="audio/mp3">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/x-aiff'" controls>
          <source [attr.src]="getSafeResourceUrl(file)" type="audio/x-aiff">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/aac'" controls>
          <source [attr.src]="getSafeResourceUrl(file)" type="audio/aac">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/ac3'" controls>
          <source [attr.src]="getSafeResourceUrl(file)" type="audio/ac3">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/x-ms-wma'" controls>
          <source [attr.src]="getSafeResourceUrl(file)" type="audio/x-ms-wma">
          Your browser does not support the audio element.
        </audio>
        
        <!-- <div class="my-dashboard-list-body common-loader" *ngIf="isLoading">
            <div class="my-dashboard-list-item">
                <h4 class="panel-title">
                  Loading...
                </h4>
            </div>
        </div> -->
        <ng-container *ngSwitchCase="'pdf'"> 
          <iframe *ngIf="screenW" scrolling="auto" class="mobile-pdf" [src]="pdfpathIpad" width="100%" height="100%"
            type='application/pdf'>
          </iframe>
          <iframe *ngIf="!screenW" scrolling="auto" class="desktop-pdf" type="application/pdf" [src]='pdfpath'
            width="100%" height="100%"></iframe>
        </ng-container>
        <!-- <iframe *ngSwitchCase="'pdf'" type="application/pdf" [src]=pdfpath width="100%" height="100%"></iframe> -->
        <div *ngSwitchDefault>
          <div class="download-icon">
            <img src="../../../assets/images/filenotfound.png" alt="Download file">
          </div>
             <p class="download-text">It looks like  this file doesn't have  a <br>
             Preview  we can show you</p>
          <a [href]="apiPath + 'filesystem/' + file?.encrypt_id + '/download'"class="btn btn-sm btn-primary download-btn"> <i class="fa fa-download" style="margin-right: 15px;" aria-hidden="true"></i> Download attachment </a>
        </div>
      </div>
      <div class="resize-action" (mousedown)="setStatus($event, 1)"></div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>

<div id="preview-modal-1" class="modal fade bs-modal-lg in" tabindex="-1" role="dialog"
  style="z-index: 9999999; overflow: visible">
  <div class="modal-dialog modal-lg modal-preview " ngClass="{{ fullscreen ?
   'zoom_active' : 'zoom_deactivate' }}">
    <div class="modal-content draggable" #previewModal2 [style.width]="fullscreen ? '100%' : width+'px' "
      [style.height]="fullscreen ? '100%' : height+'px'"
      [style.transform]="'translate3d('+ left + 'px,' + top + 'px,' + '0px)'"
      [class.active]="status === 1 || status === 2" (mousedown)="setStatus($event, 2)"
      (window:mouseup)="setStatus($event, 0)">
      <div class="modal-header">
        <button (click)="closePreviewer1()" type="button" class="close"><span
          aria-hidden="true">&times;</span></button>
        <!-- <button (click)="zoomPreviewToggle()" type="button" class="close close1 fullscrean">
              <span aria-hidden="true">
                <i class="fa fa-search-{{ fullscreen ? 'minus' : 'plus' }}" aria-hidden="true"></i>
              </span>
        </button> -->
        <h4 class="modal-title">{{file?.title.length > 50 ? (file?.title.substr(0, 50) + '...') : file?.title}}</h4>
      </div>
      <div class="modal-body {{file?.type === 'image' ? 'body-image' : ''}} -{{file?.mime}}-
          {{file?.type === 'pdf' ? 'body-pdf' : ''}}" [ngSwitch]="file?.type || file?.mime">

        <img *ngSwitchCase="'image'" class="img-responsive" [attr.src]="getSafeResourceUrl(file)">
        <video *ngSwitchCase="'video'" width="100%" controls class="test">
          <source [attr.src]="getSafeResourceUrl(file)" type="video/mp4">
          Your browser does not support HTML5 video.
        </video>
        <audio *ngSwitchCase="'audio/mp3'" controls>
          <source [attr.src]="getSafeResourceUrl(file)" type="audio/mpeg">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/wav'" controls>
          <source [attr.src]="getSafeResourceUrl(file)" type="audio/wav">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/flac'" controls>
          <source [attr.src]="getSafeResourceUrl(file)" type="audio/flac">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio'" controls>
          <source [attr.src]="getSafeResourceUrl(file)" type="audio/mp3">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/x-aiff'" controls>
          <source [attr.src]="getSafeResourceUrl(file)" type="audio/x-aiff">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/aac'" controls>
          <source [attr.src]="getSafeResourceUrl(file)" type="audio/aac">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/ac3'" controls>
          <source [attr.src]="getSafeResourceUrl(file)" type="audio/ac3">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/x-ms-wma'" controls>
          <source [attr.src]="getSafeResourceUrl(file)" type="audio/x-ms-wma">
          Your browser does not support the audio element.
        </audio>

        <!-- <div class="my-dashboard-list-body common-loader" *ngIf="isLoading">
            <div class="my-dashboard-list-item">
                <h4 class="panel-title">
                  Loading...
                </h4>
            </div>
        </div> -->
        <ng-container *ngSwitchCase="'pdf'"> 
          <iframe *ngIf="screenW" scrolling="auto" class="mobile-pdf" [src]="pdfpathIpad" width="100%" height="100%"
            type='application/pdf'>
          </iframe>
          <iframe *ngIf="!screenW" scrolling="auto" class="desktop-pdf" type="application/pdf" [src]='pdfpath'
            width="100%" height="100%"></iframe>
        </ng-container>
        <!-- <iframe *ngSwitchCase="'pdf'" type="application/pdf" [src]=pdfpath width="100%" height="100%"></iframe> -->
        <div *ngSwitchDefault>
          <div class="download-icon">
            <img src="../../../assets/images/filenotfound.png" alt="Download file">
          </div>
             <p class="download-text">It looks like  this file doesn't have  a <br>
             Preview  we can show you</p>
          <a [href]="apiPath + 'filesystem/' + file?.encrypt_id + '/download'" class="btn btn-sm btn-primary download-btn"> <i class="fa fa-download" style="margin-right: 15px;" aria-hidden="true"></i> Download attachment </a>
        </div>
      </div>
      <div class="resize-action" (mousedown)="setStatus($event, 1)"></div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>
<!-- Move Files Modal  -->
<div id="moveModal" class="modal fade" role="dialog" data-backdrop="static">
  <div class="modal-dialog " style="width:75pc;height: 49pc;">
    <div class="modal-content">
      <div class="modal-header" style="height:5pc;">
        <div class="row">
          <div class="col-sm-2">
            <h4 class="modal-title" style="margin-left:-3pc;text-align: center;font-size: larger;">Move Files</h4>
          </div>
          <div class="col-sm-10">
            <h4 class="modal-title" style="margin-left: -13pc;text-align: center;font-size: larger;">Select
              Files/Folders That You Would Like To Move</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-10">
            <div class="col-sm-12">
              <span (click)="backtofolder(1)"
                style="font-size: 15px;color: #0075bb;cursor: pointer;text-decoration: none;font-family: GibsonSemiBold,Geneva,Tahoma,Verdana,sans-serif;line-height: 1.5em;font-weight:bold;font-style: normal;"
                title="Home Directory">
                You are in folder <i class="fa fa-arrow-right" aria-hidden="true">&nbsp; </i>Home
              </span>
              <span *ngFor="let sa of backfolder"><a (click)="backtofolder(sa)"
                  style="font-size: 15px;color:#0075bb;cursor: pointer;text-decoration: none;font-family: GibsonSemiBold,Geneva,Tahoma,Verdana,sans-serif;line-height: 1.5em;font-weight:bold;font-style: normal;"
                  title={{sa}}>
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>
                  {{sa}}</a>
              </span>
            </div>
          </div>
          <div class="col-sm-1">
            <button id="folderbtn-btn" type="button" data-toggle="modal" data-target="#movestomodal"
              class="btn btn-sm btn-primary" (click)="movesto()"
              [disabled]="moveid.length==0 && checkboxstore.length==0" style="padding: 7px 16px;">Move to... </button>
          </div>
          <div class="col-sm-1">
            <button class="btn " style="background-color: #337ab7;color: white;" data-dismiss="modal"><i
                class="fa fa-close"></i></button>
          </div>
          <!-- MOves Popup Work  -->
          <div>
            <div class="modal fade" id="movestomodal" role="dialog">
              <div class="modal-dialog"
                style="width:48pc;margin-top: -16pc;margin-left: 15pc;overflow: auto;height: 21pc;">
                <div class="modal-content">
                  <div class="modal-header">
                    <button type="button" class="close" (click)='hidemovetomnodal()'>&times;</button>
                    <h5 class="modal-title">Move ({{countfile}}/{{foldercount}}) files/folder here ...</h5>
                  </div>
                  <div class="modal-body">
                    <span *ngIf="'Home' != foldernametoshow" (click)="moveshere('')"
                      style="font-size: 15px;color: #0075bb;cursor: pointer;text-decoration: none;font-family: GibsonSemiBold,Geneva,  Tahoma,Verdana,sans-serif;line-height: 1.5em;font-weight:bold;font-style: normal;"
                      title="Home Directory">Home Directory</span>
                    <hr style="margin-top:-2px;height:1px;border:none;color:#868383;background-color:#a9a7a7;" />
                    <span *ngFor="let mainitem of arr_name;let j = index ">
                      <i *ngIf='structure1==true && showid!=j && mainitem != foldernametoshow'
                        style="font-size: 16px;color: #0075bb;cursor:pointer;" class="fa fa-plus-circle"
                        aria-hidden="true" (click)="moveassigne(j)" title="Show folder structure "></i>
                      <i *ngIf='structure2==false && showid==j && mainitem != foldernametoshow'
                        style="font-size: 16px;color: #0075bb;cursor:pointer;" class="fa fa-minus-circle"
                        aria-hidden="true" (click)="movehides(j)" title="Hide folder structure "></i>
                      <span *ngIf="mainitem != foldernametoshow" (click)="moveshere(mainitem)"
                        style="font-size: 15px;color: #0075bb;cursor: pointer;text-decoration: none;font-family: GibsonSemiBold,Geneva,  Tahoma,Verdana,sans-serif;line-height: 1.5em;font-weight:bold;font-style: normal;"
                        title="Home Directory">Home</span>
                      <span *ngFor="let subitem of mainitem;let i = index ">
                        <span *ngIf='structure1==true && showid!=j && mainitem != foldernametoshow'
                          (click)="moveshere(mainitem)">
                          <a
                            style="font-size: 15px;color:#0075bb;cursor: pointer;text-decoration: none;font-family: GibsonSemiBold,Geneva,Tahoma,Verdana,sans-serif;line-height: 1.5em;font-weight:bold;font-style: normal;">
                            <i class="fa fa-arrow-right" aria-hidden="true"></i>{{subitem}}
                          </a>
                        </span>
                        <span *ngIf='structure2==false && showid==j && mainitem != foldernametoshow'
                          (click)="moveshere(mainitem)">
                          <a
                            style="font-size: 15px;color:#0075bb;cursor: pointer;text-decoration: none;font-family: GibsonSemiBold,Geneva,Tahoma,Verdana,sans-serif;line-height: 1.5em;font-weight:bold;font-style: normal;">
                            <div id="divmid{{i+1}}"><span style='font-size:27px;'>&#8627;</span>{{subitem}}
                            </div>
                          </a>
                        </span>
                      </span>

                      <hr style="margin-top:-2px;height:1px;border:none;color:#868383;background-color:#a9a7a7;" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End -->
        </div>
      </div>
      <div class="modal-body">
        <!-- Display Folders -->
        <div class="row">
          <div class="col-md-12">
            <!-- Temp-1 -->
            <div *ngFor="let nefo of TempFolder;let i = index ">
              <div class="col-md-4" style="margin-top:1pc;">
                <div class="row">
                  <div class="col-sm-12 col-md-12">
                    <span class="cloud">
                      <input type="checkbox" id="{{htmlgeneration(nefo)}}" name="foldercheck"
                        (change)="checkValue($event,nefo)">
                    </span>
                    <a (click)="OpenTrlFolder('',nefo)">
                      <i class="fa fa-folder-open" style="font-size: 24px;color:#034375;">
                      </i>&nbsp;&nbsp;<span style="font-size: 16px;font-family: sans-serif;font-weight: bold;">
                        {{nefo}}
                      </span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End -->
        <!-- Display Files -->
        <div class="row">
          <div class="col-md-12">
            <hr *ngIf='TempFolder.length>0 && filesi.length>0'
              style="height:1px;border:none;color:#868383;background-color:#a9a7a7;" />
            <div class="tiles upload-files no-preview">
              <div class="multiselect" *ngIf='filesi.length>0'>
                <label>Select All Files </label>
                <input class="selectall" type="checkbox" [checked]="isMultiSelect" (click)="moveAllFiletofolder($event)"
                  value="selectall">
              </div>
              <ul>
                <ng-container onScrollDown(true)>
                  <li *ngFor="let file of filesi" class="tile image">
                    <div class="tile-body">
                      <!-- <div>
                              <input type="checkbox" (click)="moveFiletofolder(file)"  [checked]="file.created_at==true">
                          </div> -->
                      <div class="cloud" (click)="moveFiletofolder(file)"
                        [class.cloud-active]="file.created_at ? true : null">
                        <input type="checkbox" [checked]="file.created_at==true">
                      </div>
                      <div *ngIf="file.type=='image'" class="upload-file-img" title="{{ getFileName(file.title) }}11"
                        [style.background-image]="'url(' + apiPath + 'filesystem/' + file.encrypt_id + '?preview=1)'">
                      </div>
                      <div *ngIf="file.type!='image'" class="upload-file-img" title="{{ getFileName(file.title) }}">
                        <img src="{{ imageIcons(file.type) }}" style="width: inherit;"
                          alt="{{ getFileName(file.title) }}">
                      </div>
                      <div class="tile-object">
                        <div class="name" style="width: 127px;overflow: hidden; line-break: anywhere;">{{ getFileName(file.title)}}</div>
                      </div>
                    </div>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
        <!-- End -->
      </div>
    </div>
  </div>
</div>
<!-- Rename folder modal -->
<div class="modal fade" id="renameModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true"
  data-backdrop="static">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header" style="border-bottom:none">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
        <h5 class="modal-title" id="myModalLabel">Rename Folder</h5>
      </div>
      <div class="modal-body" style="height: 36px;">
        <input class="form-control input-text" id="rename-text" type="text" [(ngModel)]="renamefolderName"
          placeholder="Rename Folder" required />
      </div>
      <div class="modal-footer" style="border-top:none">
        <button type="button" class="btn btn-sm btn-default" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-sm btn-primary" (click)="renamefolder()">Rename</button>
      </div>
    </div>
  </div>
</div>
<div id="deletemodal" class="modal fade" role="dialog" data-backdrop="static">
  <div class="modal-dialog " style="width:70pc;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Confirmation</h4>
      </div>
      <div class="modal-body">
        <label>Are you sure you want to DELETE this folder from this TrialLine? To avoid losing the files in this
          folder, move them to a new folder first, THEN delete this folder. Are you sure you want to delete this
          folder?</label>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)='confirmedDeletefolder()'>Yes, delete folder. </button>
        <!-- <button type="button" class="btn btn-primary" data-dismiss="modal">No,nevermind </button> -->
        <button type="button" class="btn btn-default" data-dismiss="modal">No, nevermind.</button>
      </div>
    </div>
  </div>
</div>
<div class="modal-contact">
  <app-modal-contacts [file]="fileContact" [triallinesId]="" (onAddContact)="addContactsToFile($event)">
  </app-modal-contacts>
</div>