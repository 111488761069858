import { Input, Output, ChangeDetectorRef, Component, OnChanges, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import {TriallinesResourceService} from "../shared/triallines-resoruce.service";
import {Trialline} from "../../shared/trialline.model";
import {ToastrService} from 'ngx-toastr';
import {Modal} from 'ngx-modialog-7/plugins/bootstrap';
declare var jQuery: any;

@Component({
    selector: 'app-dashboard-archived',
    templateUrl: './archived-triallines.component.html',
    styleUrls: ['../triallines.component.css']
})

export class ArchivedTriallinesComponent implements OnInit {
    
    isLoadingGrid: boolean = true;

    //Pagination
    // page: number = 1;
    // order: string = 'created_at';
    // orderValue: string='';
    // perPage:number=2;
    // total: number;
    // reverse: boolean = true;
    // triallineCall: boolean = true;
    
    archivedTriallines: any;
    newArchivedTriallines = [];

    constructor(
        private datePipe: DatePipe,
        protected toasts: ToastrService,
        public modal: Modal,
        private triallinesResourceService: TriallinesResourceService,
    ) { };

    @Input() activeTab: string;
    @Input() forbidden: boolean = false;
    @Input() triallines: any;
    
    ngOnInit(): void {

        this.triallinesResourceService.getArchivedTriallines()
        .toPromise()
        .then((archivedTriallines: any) => {
            if (archivedTriallines.triallines.status == 403) {
                this.forbidden = true;
            }

            this.isLoadingGrid = false;
            let todayDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
            let counter = 0;

            archivedTriallines.triallines.forEach((item, index) => {
                if(item.archive_deleted === 1) {
                    if(todayDate >= this.getExpiryDate(item.archive_date)) { //delete it
                       this.deleteTriallinebyDateMatching(item);
                    } else {                        
                        this.newArchivedTriallines[counter] = item;
                        counter++;
                    }
                } else {
                    this.newArchivedTriallines[counter] = item;
                    counter++;
                }
            });
            
            this.archivedTriallines = this.newArchivedTriallines;
            
        })
        .catch((err: Response) => {
            return err;
        });

    }

    getExpiryDate(archiveDate) {
        let dt = new Date(archiveDate);
        
        return this.datePipe.transform(dt.setDate(dt.getDate() + 30), 'yyyy-MM-dd');
    }

    confirmReopenTrialline(trialline, currentIndex) {
        jQuery('.list-action-'+currentIndex).html('<a href="javascript:;" style="width: 90px;" class="btn blue btn-outline btn-sm btn-circle replace-reopen-text">Reopen <img src="assets/squares.gif" alt="spinner" width="20px" ></a>');
        
        this.triallinesResourceService.openTrialline(trialline.id).toPromise()
        .then((archivedTriallines: Trialline) => {

            let index = this.archivedTriallines.indexOf(this.archivedTriallines.find(e => (trialline.id == archivedTriallines.id) ));
            //this.archivedTriallines[index] = archivedTriallines;
            this.archivedTriallines.splice(index, 1);

            this.toasts.success('The TrialLine has been opened');
            this.ngOnInit();
        })
        .catch((error) => {

            if (error.status == 403 && error.error.error.message) {

                this.toasts.error(error.error.error.message.error.message)
            } else {

                this.toasts.error(error);
            }

        });
    }


    openTrialline(trialline: Trialline, currentIndex)
    {
        const dialogRef = this.modal.confirm()
        .size('lg')
        .isBlocking(true)
        .showClose(true)
        .keyboard(27)
        .title('You are about to reactivate the TrialLine "' + trialline.name + '".')
        .body('You are about to reactivate this TrialLine. It will be moved from the archive back to your home dashboard. If the data in this TrialLine exceeds your plan\'s allotted data, data charges will resume.')
        .open()
        dialogRef.result
        .catch((err: any) => {
            console.log('ERROR: ' + err)
            this.modelClose()
        })
        .then((dialog: any) => {
            if(dialog){
            this.modelClose()
            this.confirmReopenTrialline(trialline, currentIndex)
            }
        })
        this.modelAction();
    }

    deleteTrialline(trialline: Trialline) {

     const dialogRef = this.modal.confirm()
        .size('lg')
        .isBlocking(true)
        .showClose(true)
        .keyboard(27)
        .title('Please confirm about ' + trialline.name)
        .body('You are about to permanently delete this TrialLine and all the files associated with it. There is no going back. Don\'t ask us to recover it for you as it will be no longer available.')
        .okBtn('I understand')
        .cancelBtn('Cancel')
        .open()
        dialogRef.result
        .catch((err: any) => {
        console.log('ERROR: ' + err)
        this.modelClose()
        })
        .then((dialog: any) => {
        if(dialog){
            this.confirmedDeleteTrialline(trialline)
        }
        this.modelClose()
        });
        this.modelAction();
    }

    confirmedDeleteTrialline(trialline: Trialline) {
        this.triallinesResourceService.deleteTrialline(trialline.id).toPromise()
          .then((r: any) => {
            if (trialline.parent_trialline_id) {
              let parentIndex = this.archivedTriallines.indexOf(this.archivedTriallines.find(e => e.id == trialline.parent_trialline_id));
              let index = this.archivedTriallines[parentIndex].childs
                .indexOf(this.archivedTriallines[parentIndex].childs.find(e => e.id == trialline.id));
              this.archivedTriallines[parentIndex].childs.splice(index, 1);
            } else {
              let index = this.archivedTriallines.indexOf(this.archivedTriallines.find(e => e.id == trialline.id));
              this.archivedTriallines.splice(index, 1);
            }
            this.toasts.success('Successfully deleted TrialLine');
            this.ngOnInit();
          })
          .catch((error) => {
            let errorData = error.error;
            if (errorData.error) {
              this.toasts.error(errorData.error);
            } else {
              this.toasts.error(errorData);
            }
          });
    }

    deleteTriallinebyDateMatching(trialline: Trialline) {
        this.triallinesResourceService.deleteTrialline(trialline.id).toPromise()
          .then((r: any) => {
            return r;
          })
          .catch((error) => {
            let errorData = error.error;
            if (errorData.error) {
              this.toasts.error(errorData.error);
            } else {
              this.toasts.error(errorData);
            }
          });
    }

    modelAction() {
        $('bs-modal-container').addClass('show-midal-element');
    }

    modelClose() {
        $('body').removeClass('modal-open');
    }

}