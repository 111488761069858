<div id="subscription-modal" class="modal fade bs-modal-lg in" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header ">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">Which subscription level would you like to try?</h4>
      </div>
      <div class="modal-body portlet-body form">
        <div class="row">
          <div class="col-md-12">
            <div class="alert alert-info" role="alert"><strong>Remember!</strong> Your card will not be charged today.
              So try ANY plan for FREE.
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-4">
            <div class="panel panel-primary">
              <div class="panel-heading">
                <h3 class="panel-title">
                  BASIC</h3>
              </div>
              <div class="panel-body">
                <div class="the-price">
                  <h1>
                    $9.99<span class="subscript">/month</span></h1>
                </div>
                <table class="table table-striped">
                  <tr>
                    <td>
                      <b>Month to Month</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Online Only <br>(Internet Connection Required)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      $9.99 Per TrialLine Per Month <br>(First TrialLine is Free)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Full Price
                    </td>
                  </tr>
                  <tr>
                    <td>
                      1 User & 16GB Storage
                    </td>
                  </tr>
                  <tr>
                    <td>
                      7-Day Free Trial
                    </td>
                  </tr>
                </table>
              </div>
              <div class="panel-footer">
                <button [disabled]="processing" (click)="tryToSubscribe('basic')" class="btn btn-success">
                  Try It
                </button>
                <!--<a (click)="tryToSubscribe('trial')" class="btn btn-success" *ngIf="! user?.trial_ends_at">-->
                <!--Free trial week-->
                <!--</a>-->
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-md-4">
            <div class="panel panel-success">
              <div class="cnrflash">
                <div class="cnrflash-inner">
                        <span class="cnrflash-label">MOST
                            <br>
                            POPULAR</span>
                </div>
              </div>
              <div class="panel-heading">
                <h3 class="panel-title">
                  SMALL FIRM</h3>
              </div>
              <div class="panel-body">
                <div class="the-price">
                  <h1>
                    $199<span class="subscript">/year</span></h1>
                </div>
                <table class="table table-striped">
                  <tr>
                    <td>
                      <b>Annual Renewal (Auto)</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Online + Offline Version<br>& Printable Timeline Files
                    </td>
                  </tr>
                  <tr>
                    <td>
                      $7.49 Per TrialLine Per Month <br>(First TrialLine is Free)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      SAVE 25% Per TrialLine Over Basic Version!
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Up to 5 Users & 35GB Storage
                    </td>
                  </tr>
                  <tr>
                    <td>
                      7-Day Free Trial
                    </td>
                  </tr>
                </table>
              </div>
              <div class="panel-footer">
                <button [disabled]="processing" (click)="tryToSubscribe('small')" class="btn btn-success">
                  Try It
                </button>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-md-4">
            <div class="panel panel-info">
              <div class="panel-heading">
                <h3 class="panel-title">
                  LARGE FIRM</h3>
              </div>
              <div class="panel-body">
                <div class="the-price">
                  <h1>
                    $499<span class="subscript">/year</span></h1>
                </div>
                <table class="table table-striped">
                  <tr>
                    <td>
                      <b>Annual Renewal (Auto)</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Online + Offline Version<br>& Printable Timeline Files
                    </td>
                  </tr>
                  <tr>
                    <td>
                      $7.49 Per TrialLine Per Month <br>(First TrialLine is Free)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      SAVE 25% Per TrialLine Over Basic Version!
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Up to 20 Users & 100GB Storage
                    </td>
                  </tr>
                  <tr>
                    <td>
                      7-Day Free Trial
                    </td>
                  </tr>
                </table>
              </div>
              <div class="panel-footer">
                <button [disabled]="processing" (click)="tryToSubscribe('large')" class="btn btn-success">
                  Try It
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-add-card (onAdded)="cardAdded($event)" [user]="user" [id_form]="4"></app-add-card>
