import {Router} from "@angular/router";
import { Injectable, Directive } from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient, HttpResponse} from '@angular/common/http';

import {AuthService} from "../../auth/shared/auth.service";
import {Rest} from "./rest";

@Directive()
@Injectable()
export class RestService extends Rest
{
  public constructor(protected http: HttpClient , protected auth: AuthService, protected router: Router) {
    super(http, auth, router);
  }


  public get(url: string, options?: any, events: boolean = true): Observable<any>
  {
    return this.intercept('get', url, null, options, events);
  }


  public post(url: string, body: any, options?: any, events: boolean = true): Observable<any>
  {
    return this.intercept('post', url, body, options);
  }


  public put(url: string, body: any, options?: any, events: boolean = true): Observable<any>
  {
    return this.intercept('put', url, body, options);
  }


  public delete(url: string, options?: any, events: boolean = true): Observable<any>
  {
    return this.intercept('delete', url, options);
  }
}
