import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";

import {AuthService} from "./auth.service";

@Injectable()
export class GuestCanActivate implements CanActivate
{
  constructor(protected authService: AuthService, protected router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : boolean
  {
    let authenticate = this.authService.check();

    if (authenticate) {
      this.router.navigate(['/dashboard']);
    }

    return authenticate == false;
  }
}
