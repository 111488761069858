import { EventEmitter, Injectable, Output, Directive } from "@angular/core";
import { DatePipe } from "@angular/common";

@Directive()
@Injectable()
export class SharedService
{
  // public triallineTime:TriallineTime;
  StartTime:any;
  EndTime:any;
  status:boolean;
  encriptedpaththumbnail:any;
  filterarray:any=[];
  tagarray:any=[];
  tagsid=[];
  checkid:number;
  eventdata:any=[];
  triallineidcheck:number;
  newaddtag=[];
  tagfilterid:number=0;
  tagcategoriesid:number=0;
  

  @Output() localProcessing: EventEmitter<boolean> = new EventEmitter();

  emitLocalProcessing(status: boolean = false)
  {
    this.localProcessing.emit(status);
  }
}

