<style>
  th {
    cursor: pointer;
  }

  .container {
    width: 95%;
  }

  .box {
    background-color: #dad6cc;
    max-width: 0px;
    overflow-y: hidden;
    transition: ease-in-out 900ms max-width;
    float: right;
  }

  .box.opened {
    max-width: 500px;
    transition: ease-in-out 900ms max-width;
    float: right;
  }

  #sidebar {
    position: fixed;
    top: 0px;
    right: 0px;
    width: auto;
    height: 100%;
    background: #DCDCDC;
    transition: all 300ms linear;
    margin-left: 10px;
    overflow-y: auto;
    max-width: 244px;
  }

  #left-sidebar {
    position: fixed;
    top: 0px;
    right: 0px;
    width: auto;
    height: 100%;
    background: #DCDCDC;
    transition: all 300ms linear;
    margin-left: 10px;
    overflow-y: auto;
  }

  .left-sidebar-add-new-button {
    position: fixed;
    left: -50px;
    top: 50%;
    transform: rotateZ(90deg);
    color: rgba(0, 0, 255, 0.94);
    background: #B0B0B0;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
  }

  #sidebar.active {
    right: -200px;
  }

  .toggle-btn {
    position: fixed;
    right: 160px;
    top: 50%;
    width: 165px;
    transform: rotateZ(90deg);
    color: rgba(0, 0, 255, 0.94);
    background: #B0B0B0;
    cursor: pointer;
    padding-left: 15px;
  }

  .to-scroll {
    position: fixed;
    right: -76px;
    top: 50%;
    margin-right: 10px;
    transform: rotateZ(90deg);
    color: rgba(0, 0, 255, 0.94);
    background: #B0B0B0;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
  }

  .table-width-95 {
    width: 90%;
  }

  .search-table-outter {
    overflow-x: scroll;
  }


  .bs-chevron {
    display: none;
  }

  th,
  td {
    min-width: 100px;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
  }

  .custom-column-wrapper {
    width: 200px;
  }

  .radio-inline {
    margin-bottom: 15px;
  }

  .first-radio {
    margin-left: 10px;
  }

  .radio-img {
    width: 40px;
  }

  .resizeble {
    resize: both;
    overflow: auto;
  }

  .upload-container {
    border: 3px dashed #e1f2fe;
    text-align: center;
    padding: 37px;
    margin-bottom: 25px;
  }

  .upload-container .upload-info {
    color: blue;
  }

  .upload-container .upload-info h3 {
    font-size: 21px;
    font-weight: bold;
    margin: 0 0 8px;
  }

  .upload-container .upload-info button {
    background: blue;
    color: #fff;
    height: 34px;
    padding: 0 60px;
    font-size: 13px;
    font-weight: 600;
    border: none;
  }

  .upload-container .upload-info button:hover {
    background: #33f;
    transition: background 0.2s ease;
  }

  .show-less {
    height: 6.5rem;
    overflow: hidden;
    padding: 5px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
  }

  iframe#frameId {
    min-height: 450px;
    max-height: 100%;
  }

  .showMore {
    display: block;
    margin: auto;
    background: transparent;
    border: 1px solid;
    border-radius: 5px;
  }


  /* start */
  .zoom_deactivate {
    width: 70%;
    top: -35px;
  }

  .zoom_active {
    width: 100%;
    height: 100%;
  }

  .zoom_active .modal-content {
    height: 100%;
  }

  .zoom_active .modal-body {
    height: 90%;
  }

  .modal-dialog.zoom_active {
    margin: 0 auto;
  }

  .zoom_deactivate .modal-body.body-image {
    overflow: auto;
    max-height: 500px;
  }

  /* End */
  .zoom_deactivate {
    width: 70%;
  }

  .zoom_active {
    width: 100%;
    height: 100%;
  }

  .zoom_active .modal-content {
    height: 100%;
  }

  .zoom_active .modal-body {
    height: 90%;
  }

  .modal-dialog.zoom_active {
    margin: 0 auto;
  }

  .zoom_deactivate .modal-body.body-image {
    overflow: auto;
    max-height: 650px;
  }

  #frameId>img {
    width: 100%;
  }

  .resize-action {
    position: absolute;
    left: 100%;
    top: 100%;
    transform: translate3d(-50%, -50%, 0) rotateZ(45deg);
    border-style: solid;
    border-width: 14px;
    border-color: transparent transparent transparent #008000;

    &:hover,
    &:active {
      cursor: nwse-resize;
    }
  }

  .draggable {
    &:hover {
      cursor: all-scroll;
    }
  }

  .zoom_deactivate .modal-body.body-pdf {
    overflow: hidden;
    height: 85%;
  }

  .zoom_deactivate .modal-content .modal-body.body-image {
    overflow: auto;
    height: 85%;
    max-height: 85%;
  }

  /* #preview-modal .zoom_deactivate {
    width: 630px;
  } */


  .spinnersDiv{
    position: fixed;
    background-color: rgba(218, 218, 218, 0.5);
    z-index: 9999999;
    height: 100%;
    width: 100%;
    top: 0%;
    bottom: 0;
}

.spinner-inner{
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-40%, 50%);
}

.spinners {
/* opacity: .6;
top:50%;
left:50%;
transform: translate(-50%, 50%);
z-index: 9999999;
position: sticky; */
}
.delete-column{

  cursor: pointer;
}
.edit-custom-column{
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 5px;
}
.edit-custom-column input{
  max-width: 80%;
}
.edit-custom-column i.fa.fa-check {
    color: #008000;
    padding: 0px 4px;
    display: flex;
    align-items: center;
    margin-left: 5px;
    cursor: pointer;
}
i.delete-column.fa-trash {
    color: red;
    display: flex;
    align-items: center;
    margin-left: 5px;
    cursor: pointer;
}

li.editListLi {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    width: 100%;
}

.editFields {
    display: flex;
    align-items: center;
}
.actionBtns {
    display: flex;
    align-items: center;
}
.column_name{
  text-align: left !important;
  width: 100%;
  padding: 0px 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 86%;
  text-overflow: ellipsis;
  background-attachment: fixed;
}
.nopointer{
  cursor:default !important;
}
.table-element p{
  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 21px;
    /* max-height: 32px; */
    -webkit-line-clamp: 11;
    -webkit-box-orient: vertical;
    word-break: break-word;
}
.text-ellipsis{
  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 21px;
    /* max-height: 32px; */
    -webkit-line-clamp: 11;
    -webkit-box-orient: vertical;
    word-break: break-word;
}
.blue{
  background-color: #034375;
}

.buttontext {
  width: 95px;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
}
.buttontextPopup {
  width: 142px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 4px;
}
.buttontext i.fa.fa-remove{
    float: right;
    top: -5px;
    position: relative;
    padding-left: 4px;
}
.buttontextPopup i.fa.fa-remove{
float: right;
top:-3px
}

</style>
<!-- *ngIf="uploadFilesProcessing" -->
<div class="spinnersDiv"  *ngIf="uploadFilesProcessing">
  <div class="spinner-inner" >
    <img src="assets/squares.gif" class="img spinners" alt="spinner" >

  </div>
</div>
<div class="page-container page-wrap">

  <div class="page-content-wrapper">
    <div class="page-head">
      <div class="container ">
        <!-- BEGIN Search -->
        <div class="page-title">
          <h1>Events
            <small>on trialline</small>
          </h1>
        </div>
        <!-- END Search -->
      </div>
    </div>
    <div class="page-content">
      <div class="container">
        <!--BEGIN Breadcrumb-->
        <ul class="page-breadcrumb breadcrumb">
          <li>
            <span>List of events</span>
          </li>
        </ul>
        <div class="portlet light list-events">
          <div class="portlet-title">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="list-events-left">
                  <a *ngIf="! trialline.parent_trialline_id" (click)="create()"
                    class="btn btn-default btn-outline btn-event">
                    <i class="fa fa-plus"></i>
                    ADD NEW EVENT
                  </a>
                  <div class="btn-group" *ngIf="! trialline.parent_trialline_id">
                    <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown"><i
                        class="fa fa-download"></i>
                      DOWNLOAD TEMPLATE
                      <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu">
                      <li><a href="assets/Basic Import.xlsx"> Basic</a></li>
                      <li><a href="assets/Import With Provider Details.xlsx"> Full Features</a></li>
                    </ul>
                  </div>
                  <!-- My Changes -->
                  <a *ngIf="! trialline.parent_trialline_id" class="btn btn-default btn-outline btn-event"
                    data-toggle="modal" data-target="#excelimport" (click)="reference.value=''">
                    <i class="fa fa-upload"></i>
                    UPLOAD SPREADSHEET
                  </a>
                  <!-- <a [routerLink]="['../views']" class="btn btn-default btn-outline btn-event">
                    <i class="fa fa-eye"></i>
                    OTHER VIEWS
                  </a>
                  <a [routerLink]="['/dashboard']" class="btn btn-default btn-outline btn-event">
                    <i class="fa fa-home"></i>
                    DASHBOARD
                  </a> -->
                  <a *ngIf="! trialline.parent_trialline_id" [routerLink]="['../edit']"
                    class="btn btn-default btn-outline btn-event">
                    <i class="fa fa-gear"></i>
                    EDIT SETTINGS
                  </a>
                  <a [routerLink]="['../']" class="btn btn-default btn-outline btn-event" (click)='setfalsefun()'>
                    <i class="fa fa-comments"></i>
                    VIEW TRIALLINE
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="portlet-body list-events tttsad" style="overflow:auto; width: auto">
            <table id="mainTable"
              class="table table-bordered table-striped table-condensed flip-content table-sortable table-vertical-align-center"
              [className]="!toggle ? 'table table-bordered table-striped table-condensed flip-content table-sortable table-vertical-align-center'
               : 'table table-bordered table-striped table-condensed flip-content table-sortable table-vertical-align-center table-width-95'">
              <thead class="flip-content">
                <tr>
                  <th (click)="reorder('start_date')" *ngIf="allColumns[0]['display']">
                    Start Date <span *ngIf="currentStatus=='start_date'" class="e2e-inner-html-bound"
                      [style.opacity]="order == 'start_date' ? '1' : '.3'"
                      [innerHTML]="getSortIcon('start_date')"></span>
                  </th>
                  <th *ngIf="allColumns[1]['display']" class="nopointer">
                    Start Time
                  </th>
                  <th (click)="reorder('end_date')" *ngIf="allColumns[2]['display']">
                    End Date<span *ngIf="currentStatus=='end_date'" class="e2e-inner-html-bound"
                      [style.opacity]="order == 'end_date' ? '1' : '.3'" [innerHTML]="getSortIcon('end_date')"></span>
                  </th>
                  <th *ngIf="allColumns[3]['display']" class="nopointer">
                    End Time
                  </th>
                  <th (click)="reorder('category_name')" *ngIf="allColumns[4]['display']">
                    Category <span *ngIf="currentStatus=='category_name'" class="e2e-inner-html-bound"
                      [style.opacity]="order == 'category_name' ? '1' : '.3'"
                      [innerHTML]="getSortIcon('category_name')"></span>
                  </th>
                  <th (click)="reorder('title')" *ngIf="allColumns[5]['display']">
                    Title <span *ngIf="currentStatus=='title'" class="e2e-inner-html-bound"
                      [style.opacity]="order == 'title' ? '1' : '.3'" [innerHTML]="getSortIcon('title')"></span>
                  </th>
                  <th *ngIf="allColumns[6]['display']">
                    Description
                  </th>
                  <th (click)="reorder('tag')" *ngIf="allColumns[7]['display']">
                    Tags <span *ngIf="currentStatus=='tag'" class="e2e-inner-html-bound"
                      [style.opacity]="order == 'tag' ? '1' : '.3'" [innerHTML]="getSortIcon('tag')"></span>
                  </th>
                  <th (click)="reorder('notes')" *ngIf="allColumns[8]['display']">
                    Notes <span *ngIf="currentStatus=='notes'" class="e2e-inner-html-bound"
                      [style.opacity]="order == 'notes' ? '1' : '.3'"
                      [innerHTML]="getSortIcon('notes')"></span>
                  </th>
                  <th (click)="reorder('comments_count')" *ngIf="allColumns[9]['display']">
                    Comments <span *ngIf="currentStatus=='comments_count'" class="e2e-inner-html-bound"
                      [style.opacity]="order == 'comments_count' ? '1' : '.3'"
                      [innerHTML]="getSortIcon('comments_count')"></span>
                  </th>
                  <th (click)="reorder('exhibit_number')" *ngIf="allColumns[10]['display']">
                    Exhibit # <span *ngIf="currentStatus=='exhibit_number'" class="e2e-inner-html-bound"
                      [style.opacity]="order == 'exhibit_number' ? '1' : '.3'"
                      [innerHTML]="getSortIcon('exhibit_number')"></span></th>
                    <!-- <th *ngIf="allColumns[11]['display']">
                      Bates #
                    </th> -->
                  <th (click)="reorder('bates_number')" *ngIf="allColumns[11]['display']">
                    Bates # <span *ngIf="currentStatus=='bates_number'" class="e2e-inner-html-bound"
                      [style.opacity]="order == 'bates_number' ? '1' : '.3'"
                      [innerHTML]="getSortIcon('bates_number')"></span></th>

                  <th (click)="reorder('is_disputed')" *ngIf="allColumns[12]['display']">
                    Disputed <span *ngIf="currentStatus=='is_disputed'" class="e2e-inner-html-bound"
                      [style.opacity]="order == 'disputed' ? '1' : '.3'"
                      [innerHTML]="getSortIcon('is_disputed ')"></span>
                  </th>

                  <th (click)="reorder('attached_files')" *ngIf="allColumns[15]['display']">
                    Attached Files<span *ngIf="currentStatus=='attached_files'" class="e2e-inner-html-bound"
                      [style.opacity]="order == 'attached_files' ? '1' : '.3'"
                      [innerHTML]="getSortIcon('attached_files')"></span></th>
                  <ng-container *ngIf="customColumns.length > 0">
                    <ng-container *ngFor="let column of customColumns ">
                      <!-- <th *ngIf="column.display">
                        {{column.name}}
                      </th> -->
                      <th (click)="reorder('custom_'+column.name)" *ngIf="column.display">
                        {{column.name}}<span *ngIf="currentStatus == column.name" class="e2e-inner-html-bound"
                          [style.opacity]="order == column.name ? '1' : '.3'"
                          [innerHTML]="getSortIcon(column.name)"></span>
                        </th>
                    </ng-container>
                  </ng-container>
                  <th (click)="reorder('created_at')" *ngIf="allColumns[13]['display']">
                    Date Added <span *ngIf="currentStatus=='created_at'" class="e2e-inner-html-bound"
                      [style.opacity]="order == 'created_at' ? '1' : '.3'"
                      [innerHTML]="getSortIcon('created_at')"></span>
                  </th>
                  <th *ngIf="! trialline.parent_trialline_id && allColumns[14]['display']" class="nopointer"> Action</th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let event of ( events | paginate: { id: 'foo',
                                                      itemsPerPage: perPage,
                                                      currentPage: page,
                                                      totalItems: total } ) let i = index">

                  <td class="text-left" *ngIf="allColumns[0]['display']">

                    <div *ngIf="event.id == editBox.eventId && editBox.column == 'start_date'; else startDateRead"
                      style="width: 150px">
                      <input name="dateOfBirth" [(ngModel)]="event.date_picker_date_start" [bsConfig]="{ dateInputFormat: displayDateFormat.fullDateFormat }" class="form-control" type="text" bsDatepicker (ngModelChange)="startDateChange($event, event)" />
                    </div>
                    <ng-template #startDateRead>
                      <!-- {{event.date_picker_date_start}} == {{displayDateFormat.fullDateFormat}} == {{datePickerConfig | json}} -->
                      <div tooltip="Click To Edit" (click)="clickedBox(event.id, 'start_date')">
                        <input name="dateOfBirth" [(ngModel)]="event.date_picker_date_start"
                          [bsConfig]="datePickerConfig" class="form-control" type="text" bsDatepicker
                          (ngModelChange)="startDateChange($event, event )"  />
                      </div>
                    </ng-template>
                  </td>
                  <td class="text-left" *ngIf="allColumns[1]['display']">
                    <div style="width: 160px;">
                    <timepicker [mousewheel]="false" [ngModel]="event.date_picker_date_start_time"
                      (ngModelChange)="changeEventStartTime($event, event)" [showSpinners]="false"></timepicker>
                    </div>
                  </td>

                  <td class="text-left" *ngIf="allColumns[2]['display']">
                    <div *ngIf="event.id == editBox.eventId && editBox.column == 'end_date'; else endDateRead">
                      <input [(ngModel)]="event.date_picker_date_end" [bsConfig]="{ dateInputFormat: displayDateFormat.fullDateFormat }" class="form-control"
                        type="text" bsDatepicker (ngModelChange)="endDateChange($event, event)">
                    </div>
                    <ng-template #endDateRead>
                      <div *ngIf="event.end_date != null; else noEndDate" (click)="clickedBox(event.id, 'end_date') ">
                        <input [(ngModel)]="event.date_picker_date_end" [bsConfig]="{ dateInputFormat: displayDateFormat.fullDateFormat }"
                          class="form-control" type="text" bsDatepicker>
                      </div>
                      <ng-template #noEndDate>
                        <span style="color: grey; cursor: pointer">
                          <i class="fa fa-plus fa-lg" (click)="clickedBox(event.id, 'end_date')"
                            style="cursor: pointer"></i>
                        </span>


                      </ng-template>
                    </ng-template>
                  </td>
                  <td class="text-left" *ngIf="allColumns[3]['display']">
                    <div *ngIf="event.end_date != null" style="width: 160px;">
                      <timepicker [mousewheel]="false" [ngModel]="event.date_picker_date_end_time"
                        (ngModelChange)="changeEventEndTime($event, event)" [showSpinners]="false" ></timepicker>
                      </div>
                  </td>
                  <td class="text-center" *ngIf="allColumns[4]['display']" (click)="clickedBox(event.id, 'category')">

                    <div *ngIf="event.id == editBox.eventId && editBox.column == 'category'; else categoryRead"
                      class="input-group" style="width: 200px">
                      <select type="text" class="form-control" [(ngModel)]="event.category_id" name="category_id"
                        required (change)="focusOutFunction(event)">
                        <option [attr.value]="0">Choose category</option>
                        <option *ngFor="let category of eventCategories" [attr.value]="category.id"
                          [attr.selected]="event.category_id == category.id ? true : null">
                          {{ category.name }}

                        </option>
                      </select>
                      <span class="input-group-addon">
                        <i class="fa fa-plus fa-lg" (click)="open(content, event.tag_name, event)"></i>
                      </span>
                    </div>
                    <ng-template #categoryRead>
                      <span *ngIf='event.category_name!="No Category"' class="label label-sm"
                        [style.background-color]="event.category.background" tooltip="Click To Edit">
                        {{ event.category_name}}

                      </span>
                      <span *ngIf='event.category_name=="No Category"' class="label label-sm"
                        style="background-color:grey">
                        None
                      </span>
                    </ng-template>
                  </td>
                  <td class="text-left table-element" *ngIf="allColumns[5]['display']">
                    <div *ngIf="event.id == editBox.eventId && editBox.column == 'title'; else titleRead">
                      <textarea class="form-control resizeble" type="text" name="" [(ngModel)]="event.title" size=80
                        (change)="focusOutFunction(event)" (keyup.enter)="focusOutFunction(event)"
                        (focusout)="clearEditBox()"></textarea>
                    </div>
                    <ng-template #titleRead>
                      <p class="table-element" tooltip="Click To Edit" (click)="clickedBox(event.id, 'title')">
                        {{ event.title }}
                      </p>
                    </ng-template>
                  </td>
                  <td *ngIf="allColumns[6]['display']">
                    <div *ngIf="event.id == editBox.eventId && editBox.column == 'description'; else notes">
                      <textarea class="form-control table-element" type="text" name="" [(ngModel)]="event.notes"
                        (change)="focusOutFunction(event)" (keyup.enter)="focusOutFunction(event)"
                        (focusout)="clearEditBox()"></textarea>
                    </div>
                    <ng-template #notes>
                      <div *ngIf="event.description != null; else addDescription" tooltip="Click To Edit"
                        (click)="openRichTextEditor(descriptionModal, event)">
                        {{ event.escapedDescription }}
                      </div>
                      <ng-template #addDescription>
                        <span style="color: grey; cursor: pointer">
                          <i class="fa fa-plus fa-lg" (click)="openRichTextEditor(descriptionModal, event)"
                            style="cursor: pointer"></i>
                        </span>

                      </ng-template>
                    </ng-template>
                  </td>
                  <td class="text-center" style="width:75px;height:75px;" 
                  *ngIf="allColumns[7]['display']">
                    <div tooltip="Click To Edit" *ngIf= "event.tag_name" >
                      <a *ngIf="event.showHideTagFile == false" href="javascript:void(0)"
                      (click)="open(tagsModal, event?.tag_name[0], event)" title="{{tag?.tag_name|uppercase }}" style="margin-top: 7px;">
                      <!-- {{tag?.tag_name|uppercase }}  -->
                      {{event.tag_name[0]?.tag_name |uppercase}}
                    </a>
                      <span *ngFor="let tag of event.tag_name" >
                        <a class="btn btn-default btn-outline btn-event buttontext" *ngIf="event.showHideTagFile == true" href="javascript:void(0)" (click)="open(tagsModal, event.tag_name, event)"  title="{{tag.tag_name|uppercase }}" style="margin-top: 7px;">
                          {{tag.tag_name|uppercase }}
                        </a>
                      </span>
                    </div>
                    <button *ngIf="event.tag_name && event.tag_name.length > 1" type="button"
                      (click)="showMoreTagName(event.id)" class="showMore" id="showMore_{{event.id}}">
                      {{ !event.showHideTagFile ? 'Show more +' +(event.tag_name.length-1) : 'Show less' }}
                    </button>
                    <span style="color: grey; cursor: pointer">
                      <i class="fa fa-plus fa-lg" (click)="open(tagsModal, event.tag_name, event)"></i>
                    </span>
                  </td>
                  <td *ngIf="allColumns[8]['display']">
                    <div *ngIf="event.id == editBox.eventId && editBox.column == 'notes'; else notes">
                      <textarea class="form-control" type="text" name="" [(ngModel)]="event.notes" size=80
                        (change)="focusOutFunction(event)" (keyup.enter)="focusOutFunction(event)"
                        (focusout)="clearEditBox()"></textarea>
                    </div>
                    <ng-template #notes>
                      <div *ngIf="event.notes != null; else addNotes" tooltip="Click To Edit"
                        (click)="openRichTextEditor(notesModal, event)" class="text-ellipsis">
                        {{ event.escapedNotes }}
                      </div>
                      <ng-template #addNotes>
                        <span style="color: grey; cursor: pointer">
                          <i class="fa fa-plus fa-lg" (click)="openRichTextEditor(notesModal, event)"
                            style="cursor: pointer"></i>
                        </span>

                      </ng-template>
                    </ng-template>
                  </td>
                  <td class="text-center" *ngIf="allColumns[9]['display']">{{ event.comments_count }}</td>
                  <td *ngIf='descriptionstatus && allColumns[9]["display"]'>
                    <span>{{event.description}}</span>
                  </td>

                  <td class="text-left" *ngIf="allColumns[10]['display']">
                    <div *ngIf="event.id == editBox.eventId && editBox.column == 'exhibit'; else exhibit">
                      <input class="form-control" type="text" name="" [(ngModel)]="event.exhibit_number"
                        (change)="focusOutFunction(event)" (focusout)="clearEditBox()">
                    </div>
                    <ng-template #exhibit>
                      <div *ngIf="event.exhibitNumberDisplay != null; else addExhibit"
                        (click)="clickedBox(event.id, 'exhibit')" tooltip="Click To Edit" class="text-ellipsis">
                        {{ event.exhibitNumberDisplay }}
                      </div>
                      <ng-template #addExhibit>
                        <span style="color: grey; cursor: pointer">
                          <i class="fa fa-plus fa-lg" (click)="clickedBox(event.id, 'exhibit')"
                            style="cursor: pointer"></i>
                        </span>
                      </ng-template>
                    </ng-template>
                  </td>
                  <td *ngIf="allColumns[11]['display']">
                    <div *ngIf="event.id == editBox.eventId && editBox.column == 'bates'; else bates">
                      <input class="form-control" type="text" name="" [(ngModel)]="event.bates_number"
                        (change)="focusOutFunction(event)" (focusout)="clearEditBox()">
                    </div>
                    <ng-template #bates>
                      <div *ngIf="event.bates_number != null; else addBates" (click)="clickedBox(event.id, 'bates')"
                        tooltip="Click To Edit" class="text-ellipsis">
                        {{ event.bates_number }}
                      </div>
                      <ng-template #addBates>
                        <span style="color: grey; cursor: pointer">
                          <i class="fa fa-plus fa-lg" (click)="clickedBox(event.id, 'bates')"
                            style="cursor: pointer"></i>
                        </span>


                      </ng-template>
                    </ng-template>
                  </td>
                  <td *ngIf="allColumns[12]['display']">
                    <select [(ngModel)]="event.is_disputed" (change)="focusOutFunction(event)">
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </td>
                  <td *ngIf="allColumns[15]['display']">
                    <div *ngIf="event.attached_files">
                      <a *ngIf="event.showHideFile == false" href="javascript:void(0)"
                        (click)="openImageBox(attachedFiles, event?.attached_files[0] ,event)">{{
                        event.attached_files[0]?.title}} </a>
                      <li *ngFor="let attached_files of event.attached_files"
                        style="text-decoration: none; list-style: none; margin: 0 auto;">

                        <a *ngIf="event.showHideFile == true" href="javascript:void(0)"
                          (click)="openImageBox(attachedFiles, attached_files ,event)">{{ attached_files.title}} </a>
                      </li>
                    </div>
                    <button *ngIf="event.attached_files && event.attached_files.length > 1" type="button"
                      (click)="alterDescriptionText(event.id)" class="showMore" id="showMore_{{event.id}}">
                      {{ !event.showHideFile ? 'Show more +' +(event.attached_files.length-1) : 'Show less' }}
                    </button>
                    <i class="fa fa-plus fa-lg" (click)="eventFiles(filesModal, event)" style="cursor: pointer"></i>
                  </td>
                  <ng-container *ngIf="customColumns.length > 0">
                    <ng-container *ngFor="let column of customColumns; index as i;">
                      <ng-container *ngIf="column.display">
                        <td>
                          <ng-container *ngIf="event.custom_field">
                            <div
                              *ngIf="event.id == customEditBox.eventId && customEditBox.columnId == i; else customFieldRead">
                              <textarea class="form-control" type="text" name=""
                                [(ngModel)]="event?.custom_field[i]['value']" size=80 (change)="focusOutFunction(event)"
                                (keyup.enter)="focusOutFunction(event)" (focusout)="clearEditBox()"></textarea>
                            </div>
                            <ng-template #customFieldRead>

                              <div *ngIf="event.custom_field[i] !== undefined">
                              <div *ngIf="event.custom_field[i]['value'] != null; else addCustomValue">
                                <p (click)="clickedCustomBox(event.id, i)" class="text-ellipsis">
                                  {{event.custom_field[i]['value']}}
                                </p>
                              </div>
                            </div>
                              <ng-template #addCustomValue>

                                <span style="color: grey; cursor: pointer">
                                  <i class="fa fa-plus fa-lg" (click)="clickedCustomBox(event.id, i)"
                                    style="cursor: pointer"></i>
                                </span>
                              </ng-template>
                            </ng-template>
                          </ng-container>
                        </td>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <td class="text-left" *ngIf="allColumns[13]['display']">{{ event.dateCreatedDisplay}}</td>
                  <td *ngIf="! trialline.parent_trialline_id && allColumns[14]['display']" style="width: 200px">
                    <!-- class="list-events-action" -->
                    <div>
                      <button (click)="edit(event)" class="btn blue btn-outline btn-edit editBtn">
                        Edit
                      </button>
                      <a (click)="deleteEvent(event)" class="btn red btn-outline btn-delete "
                        data-confirm="Warning! In case of deleting this Event you won't be able to renew it. Do you want to proceed?">
                        Delete
                      </a>
                      <button (click)="toggleHidden(event)" class="btn  btn-outline "
                        [class.btn-warning]="event.is_hidden" [class.btn-danger]="! event.is_hidden">
                        {{ event.is_hidden ? 'Show' : 'Hide' }}
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <select (change)="changePages($event)" style="float: right; margin-left: 10px">
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value={{total}}>All</option>
            </select>
            <div style="float: right">
              <pagination-controls id="foo" (pageChange)="pageChanged($event)" maxSize="25" responsive="true"
                previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination"
                screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page">
              </pagination-controls>
            </div>
            <div id="left-sidebar">
              <a *ngIf="! trialline.parent_trialline_id" (click)="create()" class="left-sidebar-add-new-button">Add New
                Event
              </a>
            </div>
            <div id="sidebar">
              <div (click)="clickEvent($event)" [className]="!toggle ? 'to-scroll' : 'toggle-btn'">
                <span>Hide/Show Columns <span *ngIf="IsAllChecked(allColumns) == false"
                    style="color: red">*</span></span>
              </div>
              <div *ngIf="toggle" style="margin: 10px">
                Hide/Show Columns
                <li *ngFor="let column of allColumns" style="text-decoration: none; list-style: none; margin: 0 auto;">
                  <input type="checkbox" class="filter-select" [(ngModel)]="column.display" (change)="displayColumns()"
                    style="padding-right: 10px"> {{ column.name }}
                </li>
                <li class="editListLi" *ngFor="let column of customColumns let i = index"
                  style="text-decoration: none; list-style: none; margin: 0 auto;">
                  <div class="inputFields">
                    <input type="checkbox" class="filter-select" [(ngModel)]="column.display"
                    (change)="displayCustomColumn(column)" style="padding-right: 10px">
                  </div>
                  <div class="custom_{{i}} hide edit-custom-column" >
                    <input  type="text"  id="custom_{{i}}" value="{{column.name}}">
                    <i class="btn btn-info delete-column fa fa-check " style ="margin-right:10px" aria-hidden="true" (click)="renameCustomColumns(column, i)"></i>
                  </div>
                  <div class="column_name" id="column_name_custom_{{i}}" title="{{ column.name }}">{{ column.name }}</div>
                  <div class="editFields">
                    <div class="actionBtns">
                      <i *ngIf="toggleEdit" id="edit_custom_{{i}}" class="fa fa-pencil" aria-hidden="true"  (click)="showHideCustomColumns(i)"></i>
                      <i  id="delete_custom_{{i}}" class="delete-column fa fa-trash" (click)="confirmedDeleteCustomColumns(column)"aria-hidden="true"></i>
                    </div>
                  </div>

                </li>
                <li style="text-decoration: none; list-style: none; margin: 0 auto; padding: 10px">
                  <input type="checkbox" class="filter-select" checked [(ngModel)]="isAllColumnsChecked"
                    (change)="selectAll()" style="padding-right: 10px"> Select/Unselect All
                </li>
                <hr>
                <div class="custom-column-wrapper">

                  <form #customColumn="ngForm" (ngSubmit)="addColumn(customColumn)">
                    <label for="columnName">Add Custom Column</label>
                    <input id="columnName" class="form-control" type="text" name="columnName" [(ngModel)]="columnName"
                      style="margin-bottom: 15px">
                    <label class="radio-inline first-radio">
                      <input type="radio" name="columnImage" [ngModel]="columnImage" checked
                        value="/assets/images/custom-column-images/calculator.png"> <img
                        src="/assets/images/custom-column-images/calculator.png" alt="" class="radio-img">
                    </label>
                    <label class="radio-inline">
                      <input type="radio" name="columnImage" [ngModel]="columnImage"
                        value="/assets/images/custom-column-images/clock.png"><img
                        src="/assets/images/custom-column-images/clock.png" alt="" class="radio-img">
                    </label>
                    <label class="radio-inline">
                      <input type="radio" name="columnImage" [ngModel]="columnImage"
                        value="/assets/images/custom-column-images/courthouse.png"><img
                        src="/assets/images/custom-column-images/courthouse.png" alt="" class="radio-img">
                    </label>
                    <label class="radio-inline">
                      <input type="radio" name="columnImage" [ngModel]="columnImage"
                        value="/assets/images/custom-column-images/gavel.png"> <img
                        src="/assets/images/custom-column-images/gavel.png" alt="" class="radio-img">
                    </label>
                    <label class="radio-inline">
                      <input type="radio" name="columnImage" [ngModel]="columnImage"
                        value="/assets/images/custom-column-images/gear.png"><img
                        src="/assets/images/custom-column-images/gear.png" alt="" class="radio-img">
                    </label>
                    <label class="radio-inline">
                      <input type="radio" name="columnImage" [ngModel]="columnImage"
                        value="/assets/images/custom-column-images/glass.png"><img
                        src="/assets/images/custom-column-images/glass.png" alt="" class="radio-img">
                    </label>
                    <label class="radio-inline">
                      <input type="radio" name="columnImage" [ngModel]="columnImage"
                        value="/assets/images/custom-column-images/handshake.png"><img
                        src="/assets/images/custom-column-images/handshake.png" alt="" class="radio-img">
                    </label>
                    <label class="radio-inline">
                      <input type="radio" name="columnImage" [ngModel]="columnImage"
                        value="/assets/images/custom-column-images/lightbulb.png"><img
                        src="/assets/images/custom-column-images/lightbulb.png" alt="" class="radio-img">
                    </label>
                    <label class="radio-inline">
                      <input type="radio" name="columnImage" [ngModel]="columnImage"
                        value="/assets/images/custom-column-images/mail.png"> <img
                        src="/assets/images/custom-column-images/mail.png" alt="" class="radio-img">
                    </label>
                    <label class="radio-inline">
                      <input type="radio" name="columnImage" [ngModel]="columnImage"
                        value="/assets/images/custom-column-images/paperwork.png"><img
                        src="/assets/images/custom-column-images/paperwork.png" alt="" class="radio-img">
                    </label>
                    <label class="radio-inline">
                      <input type="radio" name="columnImage" [ngModel]="columnImage"
                        value="/assets/images/custom-column-images/scale.png"><img
                        src="/assets/images/custom-column-images/scale.png" alt="" class="radio-img">
                    </label>
                    <p *ngIf="customColumnErrors" class="text-danger">
                      {{customColumnErrors}}
                    </p>
                    <button class="button" type="submit" class="form-control"> submit</button>
                  </form>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-trialline-event-modal [event]="event" *ngIf="editModal" (onClose)="modalClosed($event)"
  (onUpdated)="updateEvent($event)" (onCreated)="addEvent($event)" (onDelete)="confirmedDeleteEvent($event)"
  (onCopy)="addCopyEvent($event)" [customField]="customColumns">
</app-trialline-event-modal>
<app-trialline-event-copy-modal [event]="copyEventData" *ngIf="copyEvent" (onClose)="modalClosed($event)"
  (onCreated)="addEvent($event)"></app-trialline-event-copy-modal>
<span defaultOverlayTarget></span>
<!-- Modal For Upload the file -->
<div class="modal fade" id="excelimport" role="dialog" data-backdrop="static">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" (click)="eraseFile()" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Import Excel File </h4>
      </div>
      <div class="modal-body">
        <label>Select Excel File To import.</label>
        <input type="file" #fileInput (change)="incomingfile($event)" class="form-control" #reference id='importexcel'
          placeholder="Upload file" accept=".xlsx">
        <!-- <input type="file" [multiple]="multiple" #fileInput class="browse-btn" (change)="incomingfile()" accept=".xlsx"> -->
        <span>Please note that Start Date, Category, and Event Title are mandatory fields in the Excel file. </span>
      </div>
      <div class="modal-footer">
        <span style="float: left" *ngIf="uploading">Uploading...one moment</span>
        <button type="reset" class="btn btn-default" (click)="eraseFile()" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-success" [disabled]="uploading" (click)='importexcelfile($event);'>Upload
          File
        </button>
      </div>
    </div>
  </div>
</div>


<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Category</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <div class="input-group">
          <app-trialline-event-modal-categories [triallineId]="triallineId" (onCreated)="addCategory($event)">
          </app-trialline-event-modal-categories>

        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>

<ng-template #tagsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title2">Add Tag</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <div class="panel-body">
        <ng-select [items]="tagList" [addTag]="addTags" bindLabel="tag_name" bindValue="id" [(ngModel)]="selectedTag"
          (change)="selectTag()">
        </ng-select>
        <!--          <ng-autocomplete class="fa fa-arrow-down arrow_down"-->
        <!--                           [data]="contactList"-->
        <!--                           [searchKeyword]="keyword"-->
        <!--                           placeholder="Enter the Contact Name"-->
        <!--                           (selected)='selectContact($event)'-->
        <!--                           [itemTemplate]="itemTemplate"-->
        <!--                           [notFoundTemplate]="notFoundTemplate">-->
        <!--          </ng-autocomplete>-->
      </div>
      <div class="row" style="margin-left:2px;margin-top: 10px;">
        <span *ngFor="let tag of selectedTags">
          <a class="btn btn-default btn-outline btn-event buttontextPopup"  title="{{tag.tag_name|uppercase }}"style="margin-top: 7px;">
            &nbsp;<i class="fa fa-remove" style="color: #7e878e;" (click)='removeTag(tag)'></i>
            {{tag.tag_name|uppercase }}

          </a>
        </span>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>

<ng-template #notesModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-notes">Notes</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <ckeditor [(ngModel)]="event.notes" name="description" id="editor1" debounce="500" [config]="config" #ckeditor
      editorUrl="https://cdn.ckeditor.com/4.16.2/full-all/ckeditor.js">
    </ckeditor>
    <!--    <textarea class="form-control resizeble" type="text" name="" [(ngModel)]="event.notes"-->
    <!--              size=80-->
    <!--              (change)="focusOutFunction(event)" (keyup.enter)="focusOutFunction(event)" (focusout)="clearEditBox()"></textarea>-->

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark"
      (click)="focusOutFunction(event); modal.dismiss('Cross click')">Save</button>
  </div>
</ng-template>

<ng-template #description let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Description</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <ckeditor [(ngModel)]="event.description" name="description" debounce="500" [config]="config" #ckeditor
      editorUrl="https://cdn.ckeditor.com/4.16.2/full-all/ckeditor.js">
    </ckeditor>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark"
      (click)="focusOutFunction(event); modal.dismiss('Cross click') ">Save</button>
  </div>
</ng-template>

<ng-template #descriptionModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Description</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <ckeditor [(ngModel)]="event.description" name="description" debounce="500" [config]="config" #ckeditor
      editorUrl="https://cdn.ckeditor.com/4.16.2/full-all/ckeditor.js">
    </ckeditor>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark"
      (click)="focusOutFunction(event); modal.dismiss('Cross click') ">Save</button>
  </div>
</ng-template>

<ng-template #filesModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Add Files</h4>
    <button type="button" class="close" aria-label="Close" (click)="onClose()" (click)="modal.dismiss('Cross click')" >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="upload-box">
      <!--      <app-shared-files  #sharedFiles [files]="event.files"-->
      <!--                        (onRemove)="removeFile($event)" [preview]="true"></app-shared-files>-->
      <div class="col-md-8">
        <ngx-dropzone (change)="onSelect($event)">
          <ngx-dropzone-label>Click Or Drag Files Here</ngx-dropzone-label>
          <ngx-dropzone-preview *ngFor="let f of dropzoneEventFiles" [removable]="true" (removed)="onRemove(f)">
            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
          </ngx-dropzone-preview>
        </ngx-dropzone>

      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button [attr.disabled]="dropzoneEventFiles.length ? null : true"  type="button" class="btn btn-outline-dark blue" (click)="uploadFiles()">Save Files</button>
    <button type="button" class="btn btn-outline-red" aria-label="Close" (click)="onClose()" (click)="modal.dismiss('Cross click')" >
      Close
    </button>
  </div>
</ng-template>



<!-- Previewm  modal -->
<div id="preview-modal" class="modal fade bs-modal-lg in" tabindex="-1" role="dialog" data-backdrop="static"
  style="z-index: 9999999; overflow: visible">
  <div class="modal-dialog modal-lg modal-preview" ngClass="{{ fullscreen ?
   'zoom_active' : 'zoom_deactivate' }}" style="{{ fullscreen ? '' : 'top:-30px;'}} " tabindex="-1">
    <div class="modal-content draggable" #previewModal4 [style.width]="fullscreen ? '100%' : width+'px' "
      [style.height]="fullscreen ? '100%' : height+'px'"
      [style.transform]="'translate3d('+ left + 'px,' + top + 'px,' + '0px)'"
      [class.active]="status === 1 || status === 2" (mousedown)="setStatus($event, 2)"
      (window:mouseup)="setStatus($event, 0)">
      <div class="modal-header" style="cursor: all-scroll;">
        <button (click)="closePreviewer()" id="closePreviewer" type="button" class="close"><span
            aria-hidden="true">&times;</span></button>
        <!-- <button (click)="zoomPreviewToggle()" id="zoomPreviewToggle" type="button" class="close close1 fullscrean">
          <span aria-hidden="true">
            <i class="fa fa-search-{{ fullscreen ? 'minus' : 'plus' }}" aria-hidden="true"></i>
          </span>
        </button> -->
        <h4 class="modal-title">{{file?.title.length > 50 ? (file?.title.substr(0, 50) + '...') : file?.title}}</h4>
        {{file?.title}}
      </div>
      <div class="modal-body {{file?.type === 'image' ? 'body-image' : ''}} -{{file?.mime}}-
            {{file?.type === 'pdf' ? 'body-pdf' : ''}}" [ngSwitch]="file?.type || file?.mime">
        <img *ngSwitchCase="'image'" class="img-responsive" [attr.src]="getSafeResourceUrlEventImage()"
          alt="{{ getFileName(file.title) }}">
        <video *ngSwitchCase="'video'" width="100%" controls>
          <source [attr.src]="getSafeResourceUrlEventImage()" type="video/mp4">
          Your browser does not support HTML5 video.
        </video>
        <audio *ngSwitchCase="'audio/mp3'" controls>
          <source [attr.src]="getSafeResourceUrlEventImage()" type="audio/mpeg">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/wav'" controls>
          <source [attr.src]="getSafeResourceUrlEventImage()" type="audio/wav">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio'" controls>
          <source [attr.src]="getSafeResourceUrlEventImage()" type="audio/mp3">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/flac'" controls>
          <source [attr.src]="getSafeResourceUrlEventImage()" type="audio/flac">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/x-aiff'" controls>
          <source [attr.src]="getSafeResourceUrlEventImage()" type="audio/x-aiff">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/aac'" controls>
          <source [attr.src]="getSafeResourceUrlEventImage()" type="audio/aac">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/ac3'" controls>
          <source [attr.src]="getSafeResourceUrlEventImage()" type="audio/ac3">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/x-ms-wma'" controls>
          <source [attr.src]="getSafeResourceUrlEventImage()" type="audio/x-ms-wma">
          Your browser does not support the audio element.
        </audio>
        <div class="pdf  ghgh" *ngSwitchCase="'pdf'" style="height: 100% ">
          <iframe *ngIf="screenW" scrolling="auto" class="mobile-pdf" [src]="pdfpathIpad" width="100%" height="100%"
            type='application/pdf'>
          </iframe>
          <iframe *ngIf="!screenW" scrolling="auto" class="desktop-pdf" type="application/pdf" [src]='getSafeResourceUrlEventImage()'
            id='frameId' width="100%" height="100%"></iframe>
        </div>
        <div *ngSwitchDefault>
          <div class="download-icon">
            <img src="../../../assets/images/filenotfound.png" alt="Download file">
          </div>
             <p class="download-text">It looks like  this file doesn't have  a <br>
             Preview  we can show you</p>
          <a [href]="apiPath + 'filesystem/' + file?.encrypt_id + '/download'" class="btn btn-sm btn-primary download-btn"> <i class="fa fa-download" style="margin-right: 15px;" aria-hidden="true"></i> Download attachment </a>
        </div>
      </div>
      <div class="resize-action" (mousedown)="setStatus($event, 1)"></div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>




<div id="preview-modal-shared" class="modal fade bs-modal-lg in" tabindex="-1" role="dialog" 
  style="z-index: 9999999; overflow: visible">
  <div class="modal-dialog modal-lg modal-preview" ngClass="{{ fullscreen ?
   'zoom_active' : 'zoom_deactivate' }}"  tabindex="-1">
    <div class="modal-content draggable" #previewModal4 [style.width]="fullscreen ? '100%' : width+'px' "
      [style.height]="fullscreen ? '100%' : height+'px'"
      [style.transform]="'translate3d('+ left + 'px,' + top + 'px,' + '0px)'"
      [class.active]="status === 1 || status === 2" (mousedown)="setStatus($event, 2)"
      (window:mouseup)="setStatus($event, 0)">
      <div class="modal-header" style="cursor: all-scroll;">
        <button (click)="closePreviewerShared()" id="closePreviewer" type="button" class="close"><span
            aria-hidden="true">&times;</span></button>
        <!-- <button (click)="zoomPreviewToggle()" id="zoomPreviewToggle" type="button" class="close close1 fullscrean">
          <span aria-hidden="true">
            <i class="fa fa-search-{{ fullscreen ? 'minus' : 'plus' }}" aria-hidden="true"></i>
          </span>
        </button> -->
        <h4 class="modal-title">{{file?.title.length > 50 ? (file?.title.substr(0, 50) + '...') : file?.title}}</h4>
        {{file?.title}}
      </div>
      <div class="modal-body {{file?.type === 'image' ? 'body-image' : ''}} -{{file?.mime}}-
            {{file?.type === 'pdf' ? 'body-pdf' : ''}}" [ngSwitch]="file?.type || file?.mime">
        <img *ngSwitchCase="'image'" class="img-responsive" [attr.src]="getSafeResourceUrlEventImage()"
          alt="{{ getFileName(file.title) }}">
        <video *ngSwitchCase="'video'" width="100%" controls>
          <source [attr.src]="getSafeResourceUrlEventImage()" type="video/mp4">
          Your browser does not support HTML5 video.
        </video>
        <audio *ngSwitchCase="'audio/mp3'" controls>
          <source [attr.src]="getSafeResourceUrlEventImage()" type="audio/mpeg">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/wav'" controls>
          <source [attr.src]="getSafeResourceUrlEventImage()" type="audio/wav">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio'" controls>
          <source [attr.src]="getSafeResourceUrlEventImage()" type="audio/mp3">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/flac'" controls>
          <source [attr.src]="getSafeResourceUrlEventImage()" type="audio/flac">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/x-aiff'" controls>
          <source [attr.src]="getSafeResourceUrlEventImage()" type="audio/x-aiff">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/aac'" controls>
          <source [attr.src]="getSafeResourceUrlEventImage()" type="audio/aac">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/ac3'" controls>
          <source [attr.src]="getSafeResourceUrlEventImage()" type="audio/ac3">
          Your browser does not support the audio element.
        </audio>
        <audio *ngSwitchCase="'audio/x-ms-wma'" controls>
          <source [attr.src]="getSafeResourceUrlEventImage()" type="audio/x-ms-wma">
          Your browser does not support the audio element.
        </audio>
        <div class="pdf  ghgh" *ngSwitchCase="'pdf'" style="height: 90% ">
          <iframe *ngIf="screenW" scrolling="auto" class="mobile-pdf" [src]="pdfpathIpad" width="100%" height="100%"
            type='application/pdf'>
          </iframe>
          <iframe *ngIf="!screenW" scrolling="auto" class="desktop-pdf" type="application/pdf" [src]='getSafeResourceUrlEventImage()'
            id='frameId' width="100%" height="100%"></iframe>
        </div>
        <div *ngSwitchDefault>
          <div class="download-icon">
            <img src="../../../assets/images/filenotfound.png" alt="Download file">
          </div>
             <p class="download-text">It looks like  this file doesn't have  a <br>
             Preview  we can show you</p>
          <a [href]="apiPath + 'filesystem/' + file?.encrypt_id + '/download'" class="btn btn-sm btn-primary download-btn"> <i class="fa fa-download" style="margin-right: 15px;" aria-hidden="true"></i> Download attachment </a>
        </div>
      </div>
      <div class="resize-action" (mousedown)="setStatus($event, 1)"></div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>

