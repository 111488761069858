<div class="container page-content-inner page-wrap">
  <div class="row">
    <div class="col-md-12">
      <app-profile-sidebar [user]="user"></app-profile-sidebar>
      <!-- BEGIN PROFILE CONTENT -->
      <div class="profile-content">
        <div class="row">
          <div class="col-md-12">
            <!-- BEGIN PORTLET -->
            <div class="portlet light ">
              <div class="portlet-body">
                <form #profileForm="ngForm" (submit)="update(profileForm)" class="form-horizontal margin-bottom" novalidate>
                  <div class="form-body">
                    <div class="form-group" [hubValidator]="errors?.first_name" transformer="inline">
                      <label class="col-md-3 control-label">First Name</label>
                      <div class="col-md-9">
                        <input name="first_name" type="text" class="form-control" [ngModel]="user?.first_name" maxlength="50">
                      </div>
                    </div>
                    <div class="form-group" [hubValidator]="errors?.last_name" transformer="inline">
                      <label class="col-md-3 control-label">Last Name</label>
                      <div class="col-md-9">
                        <input name="last_name" type="text" class="form-control" [ngModel]="user?.last_name" maxlength="50">
                      </div>
                    </div>
                    <div class="form-group" [hubValidator]="errors?.email" transformer="inline">
                      <label class="col-md-3 control-label">Email Address</label>
                      <div class="col-md-9">
                        <input name="email" type="email" class="form-control" [ngModel]="user?.email" readonly maxlength="50">
                      </div>
                    </div>
                    <div class="form-group" [hubValidator]="errors?.phone" transformer="inline">
                      <label class="col-md-3 control-label">Phone Number</label>
                      <div class="col-md-9">
                        <input name="phone" type="tel" class="form-control" [ngModel]="user?.phone" maxlength="15">
                      </div>
                    </div>
                    <div class="form-group" [hubValidator]="errors?.company" transformer="inline">
                      <label class="col-md-3 control-label">Company Name</label>
                      <div class="col-md-9">
                        <input name="company" type="text" class="form-control" [ngModel]="user?.company" maxlength="50">
                      </div>
                    </div>
                    <div class="form-group" [hubValidator]="errors?.firm_size" transformer="inline">
                      <label class="col-md-3 control-label">Firm Size</label>
                      <div class="col-md-9">
                        <input name="firm_size" type="text" class="form-control" [ngModel]="user?.firm_size" maxlength="50">
                      </div>
                    </div>
                  </div>
                  <div class="form-group" transformer="inline" style="display: flex;">
                    <label class="col-md-3" style="width: auto">Secure Account with OTP</label>
                    <label class="switch">
                      <input name="is_secure_otp" id="is_secure_otp" type="checkbox" [ngModel]="user?.is_secure_otp">
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <div class="form-actions">
                    <div class="row">
                      <div class="col-md-offset-3 col-md-9">
                        <button type="submit" class="btn blue submit" value="Submit Payment">Save</button>
                        <button type="button" class="btn default">Cancel</button>
                      </div>
                    </div>
                  </div>
                </form>
                <form #passwordUpdateForm="ngForm" (submit)="updatePassword(passwordUpdateForm)" class="form-horizontal" id="profile-password" >
                  <div class="form-body">
                    <div class="form-group" [hubValidator]="passwordErrors?.password" transformer="inline">
                      <label class="col-md-3 control-label">Current Password</label>
                      <div class="col-md-9">
                        <input type="password" [ngModel] name="password" class="form-control" autocomplete="on">
                      </div>
                    </div>
                    <div class="form-group" [hubValidator]="passwordErrors?.new_pass" transformer="inline">
                      <label class="col-md-3 control-label">New Password</label>
                      <div class="col-md-9">
                        <input type="password" [ngModel] id="new_pass" name="new_pass" class="form-control" autocomplete="on">
                      </div>
                    </div>
                    <div class="form-group" [hubValidator]="passwordErrors?.new_pass_confirmation" transformer="inline">
                      <label class="col-md-3 control-label">Confirm Password</label>
                      <div class="col-md-9">
                        <input type="password" [ngModel] name="new_pass_confirmation" class="form-control" autocomplete="on">
                      </div>
                    </div>
                  </div>
                  <div class="form-actions">
                    <div class="row">
                      <div class="col-md-offset-3 col-md-9">
                        <button type="submit" class="btn blue submit" value="Submit Payment">Save</button>
                        <button type="button" class="btn default" (click)="passwordUpdateForm.reset(); passwordErrors = {}">Cancel</button>
                      </div>
                    </div>
                  </div>
                </form>
                <div class="row" style="margin: 15px">
                  <button disabled id="delete_button" class="btn btn-large btn-danger pull-right" data-toggle="modal" data-target="#confirm-submit">
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <!-- END PORTLET -->
          </div>
        </div>
      </div>
      <!-- END PROFILE CONTENT -->
    </div>
  </div>
</div>

<div class="modal fade" id="confirm-submit" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        Confirm deleting
      </div>
      <div class="modal-body">
        Are you sure you want to completely delete your account with all files?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
        <a href="#" id="submit" class="btn btn-success success">Yes</a>
      </div>
    </div>
  </div>
</div>
