
import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChange} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TriallineResourceService} from "../../../shared/trialline-resoruce.service";
import {env} from "../../../../../../../.env";
import {FileUploader} from "ng2-file-upload";
import {AuthService} from "../../../../../auth/shared/auth.service";
import {Modal} from 'ngx-modialog-7/plugins/bootstrap';
import {DomSanitizer} from "@angular/platform-browser";
import {Contacts} from "../../../../shared/contacts.model";
import {Event} from '../../../../shared/event.model'
import {Trialline} from "../../../../shared/trialline.model";
import { NgSelectConfig } from '@ng-select/ng-select';

declare var jQuery:any;

@Component({
  selector: 'app-trialline-event-modal-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class EventEditModalContactsComponent implements AfterViewInit
{
  @Input() contacts: Contacts[];
  @Input() contactEvent: Event[];
  @Input() triallinesId: Trialline;
  @Output() onAddContact = new EventEmitter<Contacts>();
  @Output() onRemoveContact = new EventEmitter<Contacts>();
  @Output() onAddTag = new EventEmitter<Contacts>();

  publicFolderUrl = env.publicFolderUrl;
  publicImages = env.publicImages;
  apiPath = env.apiUrl;
  modalId = '#contact-edit-modal';
  errors: any = {};
  contact: Contacts = new Contacts();
  color: string = '#'+(Math.random()*0xFFFFFF<<0).toString(16);
  contactValid:any;
  contactList:any;
  selectedContact:any;
  showEdit: boolean = false;
  keyword:any = 'name';
  listIdContacts:any = [];
  editContact: Contacts;
  getListContact:boolean = false;
  isAddTag:boolean = false;
  uploader: FileUploader;


  constructor(
    protected toasts: ToastrService,
    private triallineResoureService: TriallineResourceService,
    private authService: AuthService,
    private _changeDetectorRef: ChangeDetectorRef,
    public modal: Modal,
    public triallineResourceService: TriallineResourceService,
    private _sanitizer: DomSanitizer,
    private config: NgSelectConfig
  ) {}

  ngOnDestroy() {
    console.log('ngOnDestroy');
    this._changeDetectorRef.detach();
  }

  ngOnInit() {
    this.getListContact = false;
  }

  ngAfterViewInit() {
    this.contactList = this.contactEvent['contacts']
    jQuery('[data-toggle="tooltip"]').tooltip({
      animated: 'fade',
      placement: 'right',
      html: true
    });
  }

  ngOnChanges() {
    if((this.contactList == undefined || this.contactList.length < 1) && !this.getListContact) {
      this.triallineResourceService.getContacts(this.triallinesId, 'categories,privateFiles').subscribe((response: any) => {
        this.contactList = response;
        this.getListContact = true;
      }, (error: any) => { console.log(error);} );
    }
  }
  getSafeThumbnailUrl(category) {
    if (!category.thumbnail_visible) {
      return this.publicImages + '/images/start-event/contact_thumbnail.png'
    }
    let obj;
    if( category.thumbnail_encryptid != null && category.thumbnail_encryptid != false ) {
      obj =  this.publicFolderUrl + 'index.php/v1/filesystem/' +
        category.thumbnail_encryptid + '?preview=1'
    } else {
      obj =  this.publicFolderUrl +
        (category.thumbnail ? category.thumbnail.substring(1).replace("'", "\\'") : '')
    }

    return obj;
  }

  getSafeUrl(category) {
    let obj = this.getSafeThumbnailUrl(category);
    return "<img src='" + obj + "' style='height: 230px;'/>";
  }

  closeModal(Contact: Contacts) {
    this.showEdit = false;
  }

  updateContact(contact: Contacts) {
    let indexContacts = this.contacts.indexOf(this.contacts.find(e => e.id == contact.id));
    let index = this.contactList.indexOf(this.contacts.find(e => e.id == contact.id));
    this.contactList[index] = contact;
    this.contacts[indexContacts] = contact;
    this._changeDetectorRef.detectChanges();
    this.ngAfterViewInit();
  }

  createContact(contact: Contacts) {
    //this.contactList.push(contact);
    this.contactList=[...this.contactList, contact];
    console.log(contact, this.contactList);
    this.selectedContact = contact.id;
    this.connectToEvent();
    this._changeDetectorRef.detectChanges();
    //this.ngAfterViewInit();
  }

  edit(contact: Contacts) {
    this.editContact = contact;
    this.showEdit = true;
    this._changeDetectorRef.detectChanges();
    jQuery(this.modalId).modal('show');
  }

  create() {
    this.editContact = new Contacts();
    this.showEdit = true;
    this._changeDetectorRef.detectChanges();
    jQuery(this.modalId).modal('show');
  }

  connectToEvent() {
    if (this.selectedContact !== undefined){
      let index = this.contactList.indexOf(this.contactList.find(e => e.id == this.selectedContact));
      if(this.contacts == undefined) {
        this.contacts = [];
      }
      if(this.contacts.indexOf(this.contacts.find(e => e.id == this.selectedContact)) == -1) {
        this.listIdContacts.push(this.contactList[index].id)
        this.contacts.push(this.contactList[index])
        if (this.isAddTag) {
          this.onAddTag.emit(this.contactList[index].name);
        }
        this.onAddContact.emit(this.selectedContact);
      }else{
        this.toasts.info('Contact has already been added from event.');
      }
    }else{
      this.toasts.info('Please select contact.');
    }
  }
  //
  // isAddTagChecked() {
  //   return this.isAddTag;
  // }
  //
  // connectToEventTag() {
  //   this.isAddTag = !this.isAddTag;
  // }

  deleteContact(contact: Contacts) {
    const dialogRef = this.modal.confirm()
      .size('lg')
      .isBlocking(true)
      .showClose(true)
      .keyboard(27)
      .title('Confirmation')
      .body('Disconnecting this contact will remove the contact\'s association with this event. Proceed?')
      .open()
    dialogRef.result
      .catch((err: any) => {
        this.modelClose()
        console.log('ERROR: ' + err)})
      .then((dialog: any) => {
        this.modelClose()
        if(dialog){
          this.confirmedDeleteContact(contact);
        }
      });
    $('bs-modal-container').addClass('show-midal-element')
  }

  confirmedDeleteContact(contact: Contacts) {
    let index = this.contacts.indexOf(this.contacts.find(e => e.id == contact.id));
      this.contacts.splice(index, 1);
      this.onRemoveContact.emit(contact);
      this.toasts.success('Successfully deleted contact from event.');


  }

  modelClose() {
    //$('body').removeClass('modal-open');
    jQuery(this.modalId).modal('hide');
    jQuery("#event-edit-modal").modal('show');
  }

  selectContact(event){
    this.selectedContact = event.id
  }

}

