<div class="comments-panel" style="z-index: 30">
  <div id="views-list-template">
    <div class="panel-group">
      <div *ngFor="let event of events | visibleEvents" class="panel panel-default" style="margin: 0;">
        <div class="panel-heading clearfix" role="tab" id="headingOne">
          <h4 class="panel-title pull-left" style="padding-top: 7.5px;">
            <a (click)="onSelect.emit(event.id)">{{ event.title }}</a>
            <small>Date: {{ event.date_created_at | date:'MM/dd/y' }}</small>
          </h4>
          <a (click)="toggleVisibility(event)" class="btn btn-sm btn-default pull-right" id="comments-visibility">
            <span class="glyphicon" [class.glyphicon-chevron-down]="event.comments_visibility"
                  [class.glyphicon-chevron-left]="!event.comments_visibility"></span>
          </a>
        </div>
        <div class="collapse" [class.in]="event.comments_visibility">
          <div class="panel-body">
            <div class="title" [attr.data-id-event]="event.id" style="margin-bottom: 7px;">
              <div class="row">
                <div class="col-md-4" *ngIf="event.thumbnail_visible">
                  <img (click)="onSelect.emit(event.id)" [attr.src]="publicPath + event.thumbnail_attr.substring(1)"
                       alt="img" class="img-rounded" width="90%">
                </div>
                <div class="col-md-{{event.thumbnail_visible ? '8' : '12'}}" style="padding-left: 0px;">
                  <div class="notes sidebar-notes" *ngIf="event.notes">
                    <div class="pull-right">
                    </div>
                    <p [innerHTML]="event.notes"></p>
                  </div>
                </div>
              </div>
            </div>
            <ng-container *ngIf="event.comments_count > 0">
              <!--@{{#if views}}-->
              <strong>Comments:</strong>
              <ng-container *ngFor="let view of event.view">
                <ng-container *ngIf="view.comments.length > 0">
                <div class="event-title">
                  View: {{ view.name }}
                </div>
                <ul class="comments-panel-box">
                  <li *ngFor="let comment of view.comments | sortById" class="comment" style="list-style-type: decimal"
                      [ngStyle]="{display: comment.visibility ? 'block' : 'none'}">
                    <div class="comment-msg">
                      <div class="pull-right">
                        <button class="btn btn-sm" data-placement="left" mwlConfirmationPopover
                                [customTemplate]="customTemplate">&times;</button>
                        <ng-template #customTemplate let-options="options">
                          <div class="popover confirmation fade left in"
                               style="display: block;">
                            <div class="arrow" style="top: 50%;"></div>
                            <h3 class="popover-title">
                              Do you want to delete this comment from the event bubble as well?
                            </h3>
                            <div class="popover-content"><p class="confirmation-content" style="display: none;"></p>
                              <div class="confirmation-buttons text-center">
                                <div class="btn-group">
                                  <a (click)="deleteComment(event.id, comment.id, view.id, 'hard')"
                                     class="btn btn-xs btn-primary" data-apply="confirmation">
                                    <i class="glyphicon glyphicon-ok"></i> Yes
                                  </a>
                                  <a (click)="deleteComment(event.id, comment.id, view.id, 'soft')"
                                     class="btn btn-xs btn-default" data-dismiss="confirmation">
                                    <i class="glyphicon glyphicon-remove"></i> No, only from pullout
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </div>
                      {{ comment.comment }}
                    </div>
                    <div class="comment-title">
                      <div class="owner">by {{ comment.author }}</div>
                      <div class="date">{{ comment.created_at|date:'short' }}</div>
                    </div>
                  </li>
                  <li class="comments-form">
                    <form class="form-inline comment-panel" #commentForm="ngForm"
                          (submit)="storeComment(event.id, view.id, commentForm)">
                      <div class="col-md-9 text-message">
                        <div class="form-group comments-form-group">
                          <textarea required [ngModel] name="comment" class="form-control"
                                    placeholder="Your comments"></textarea>
                        </div>
                      </div>
                      <div class="col-md-3 pull-right">
                        <button type="submit" class="btn btn-sm btn-primary">Post</button>
                      </div>
                    </form>
                  </li>
                </ul>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="comments-panel-wrapper">
  </div>
</div>
