import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../rest/shared/rest.service";

@Injectable()
export class PresentResourceService {
  constructor(public http: RestService) {}

  getView(uuid, password?, token?, withParam?: string) {
    console.log('I am here')
    return this.http.post('view/' + uuid + (withParam ? '&with=' + withParam : ''),
      {password: password, token: token}).pipe( map((response: any) => {
      let body = response.body;
      jQuery('#nameMonth .month').css({
        "opacity": '1'
      });
      return body.view;
    }));

    // return this.http.get('view/' + uuid + (withParam ? '&with=' + withParam : ''),
    //   {params: {password: password, token: token}}).pipe( map((response: any) => {
    //   let body = response.body;
    //   jQuery('#nameMonth .month').css({
    //     "opacity": '1'
    //   });
    //   return body.view;
    // }));

  }

  checkPassword(uuid, password) { 
    
    return this.http.post('view/' + uuid + '/pwd', {password: password})
      .pipe( map((response: any) => {
        let body = response.body;
        return body.password;
    }));

    // return this.http.get('view/' + uuid + '/pwd', {params: {password: password}})
    //   .pipe( map((response: any) => {
    //     let body = response.body;
    //     return body.password;
    // }));
  }

  getEvent(uuid, event_id, password): Observable<any> {
    return this.http.get('view/' + uuid + '/event/' + event_id, {params: {password: password}})
      .pipe( map((response: any) => {
        let body = response.body;
        return body.event;
    }));
  }

  storeComment(uuid, event_id, comment, password) {
    return this.http.post('view/' + uuid + '/event/' + event_id + '/comment',
      {comment: comment, password: password}).pipe( map((response: any) => {
        let body = response.body;
        return body.comment;
    }));
  }

  storeCommentTrialline(triallineId, uuid, event_id, comment) {
    return this.http.post('trialline/' + triallineId + '/views/' + uuid + '/events/' + event_id + '/comment',
      {comment: comment}).pipe( map((response: any) => {
      let body = response.body;
      return body.comment;
    }));
  }
}
