import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OTPVerificationService {
  private readonly fakeOTP = '123456';

  verifyOTP(otp: string, userEmail: string): Observable<boolean> {
    if (otp === this.fakeOTP) {
      return of(true);
    } else {
      return of(false);
    }
  }
}
