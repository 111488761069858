import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer, Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Event } from "../../shared/event.model";
import { EventsResourceService } from "./shared/events-resoruce.service";
import { Modal } from 'ngx-modialog-7/plugins/bootstrap';
import { Trialline } from "../../shared/trialline.model";
import { TriallinesResourceService } from "../../triallines/shared/triallines-resoruce.service";

//import * as XLSX from 'ts-xlsx';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
declare var jQuery:any;
declare var moment:any;
import { EventEditModalComponent } from "./edit/edit.component";

import { SharedService } from 'app/shared/shared.service';

import { TriallineResourceService } from "../shared/trialline-resoruce.service";
import { FileLibraryResourceService } from "../../file-library/shared/file-library-resoruce.service";
import { AuthService } from "../../../auth/shared/auth.service";
import { RestService } from "app/rest/shared/rest.service";
import { Category } from "../../shared/category.model";
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NgForm } from "@angular/forms";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FileModel } from "../../file-library/shared/file.model";
import { FilesComponent } from "../../../shared-components/file/files.component";
import { forEach } from "vis-util";
// import { data } from "jquery";
import { env } from "../../../../../.env";
import * as screenfull from 'screenfull';
import { any } from 'codelyzer/util/function';
import { Console } from 'console';
declare var jQuery: any;
declare var moment: any;
const enum Status {
  OFF = 0,
  RESIZE = 1,
  MOVE = 2
}
@Component({
  selector: 'app-trialline-events',
  templateUrl: './events.component.html'
})
export class TriallineEventsComponent implements OnInit, OnDestroy {
  fileSelected: any;
  imageGot: boolean = false;
  popupImage : any = '';
  myFiles: File;
  sMsg: string = '';
  valueToSend = 'check here';
  pdfpathIpad: any;
  fileName: string;
  @ViewChild('fileInput', { static: true }) FileInput
  @ViewChild('mainTable') mainTable: ElementRef
  // my change
  files: any;
  uploading: boolean = false;
  arrayBuffer: any;
  ExcelData:any;
  // pdfpathIpad: any;
  // file: File;
  file: FileModel;
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  copyEventData: Event;
  events: Event[];
  triallineId: number;
  perPage: number = 25;
  event: Event;
  trialline: Trialline;
  editModal: boolean = false;
  copyEvent: boolean = false;
  page: number = 1;
  order: string = 'start_date';
  reverse: boolean = true;
  total: number;
  sub: any;
  orderValue: string;
  totalInventories: number;
  currentStatus: string;
  thumbnail_visible: boolean;
  EventEditModalComponent1: EventEditModalComponent;
  tagconcatarray = [];
  events1: Event[];
  /* Rheal Changes */
  divstatus: boolean = false;
  addressstatus: boolean = false;
  descriptionstatus: boolean = false;
  notesstatus: boolean = false;
  toggle: boolean = false;
  toggleEdit: boolean = true;
  toggleEditTick: boolean = false;
  

  pdfPath: any
  imagePath: any
  showShortDesciption: boolean = false;
  showShortTagDesciption: boolean = false

  fullscreen: boolean = false;
  fullIpad: boolean = false;
  screenW: any = false;
  url: any
  isImageRender: boolean = false;
  uploadFilesProcessing: boolean = false;
  fileCount : number = 0;
  allColumns: any = [
    {
      name: "Start Date",
      display: true
    },
    {
      name: "Start Time",
      display: true
    },
    {
      name: "End Date",
      display: true
    },

    {
      name: "End Time",
      display: true
    },
    {
      name: "Category",
      display: true
    },
    {
      name: "Title",
      display: true
    },
    {
      name: "Description",
      display: true
    },
    {
      name: "Tags",
      display: true
    },

    {
      name: "Notes",
      display: true
    },
    {
      name: "Comments",
      display: true
    },
    {
      name: "Exhibit #",
      display: true
    },
    {
      name: "Bates #",
      display: true
    },
    {
      name: "Disputed",
      display: true
    },

    {
      name: "Date Added",
      display: true
    },
    {
      name: "Action",
      display: true
    },
    {
      name: "Attached Files",
      display: true
    },
  ];

  selectedColumns: any = [
    'Start Date', 'Start Time', 'End Date', 'End Time', 'Category', 'Title', 'Description', 'Tags', 'Notes', 'Exhibit #', 'Bates #', 'Comments', 'Disputed', 'Date Added', 'Attached Files', 'Action'
  ];

  apiPath = env.apiUrl;
  isAllColumnsChecked: boolean = true;

  hoursPlaceholder = 'hh';
  minutesPlaceholder = 'mm';
  secondsPlaceholder = 'ss';

  dateStart = null;

  editBox: any = [];

  eventCategories: any = [];
  categories: Category[];
  datePickerConfig: Partial<BsDatepickerConfig>;

  //custom columns
  customColumns: any = [];
  customEditBox: any = [];
  customColumnErrors: string;

  //tags
  selectedTags: any = [];
  selectedTag: any;
  notSelectedTags: any = [];
  tagName: string;

  tagList = [];

  dropzoneEventFiles: File[] = [];

  displayDateFormat: any;

  width: number;
  height: number;
  left: number = 0;
  top: number = 0;
  private boxPosition: { left: number, top: number };
  private containerPos: { left: number, top: number, right: number, bottom: number };
  public mouse: { x: number, y: number }
  private mouseClick: { x: number, y: number, left: number, top: number }
  public status: Status = Status.OFF;
  @ViewChild("previewModal4") public previewModal4: ElementRef;
  constructor(private triallinesResourceService: TriallinesResourceService,
    protected toasts: ToastrService,
    protected title: Title,
    protected route: ActivatedRoute, public router: Router,
    private eventsResourceService: EventsResourceService,
    private _changeDetectionRef: ChangeDetectorRef,
    private modal: Modal,
    private _sanitizer: DomSanitizer,
    // private readonly activeRoute: ActivatedRoute,
    protected sharedService: SharedService,
    public triallineResourceService: TriallineResourceService,
    public FileLibraryResourceService: FileLibraryResourceService,
    public AuthService: AuthService,
    public RestService: RestService,
    private triallineResoureService: TriallineResourceService,
    private modalService: NgbModal
  ) {    
    title.setTitle('Trialline | Events')
    localStorage.setItem("folderpath", '');
    this.EventEditModalComponent1 = new EventEditModalComponent(this.toasts, this.title, this.route, this.router, this.sharedService, this.eventsResourceService, this._changeDetectionRef, this.triallineResourceService, this.FileLibraryResourceService, this.triallinesResourceService, this.AuthService, this.modal, this.RestService)
    this.datePickerConfig = Object.assign({showWeekNumbers: false}, { containerClass: 'theme-dark-blue' });

  }

  reorder(column: string) {
    this.currentStatus = column;
    if (column == this.order) {
      this.orderValue = this.orderValue === 'DESC' ? 'ASC' : 'DESC';
      this.reverse = !this.reverse;
      this.eventsResourceService.getListEvents(this.triallineId, {
        page: this.page, sort: {
          field: column,
          order: this.orderValue
        }, limit: this.perPage
      })
        .subscribe((response) => {
          this.events = this.formatEvents(response['data']);
          this.total = response['total'];
          this.callfunction();
        });
    } else {
      this.orderValue = 'ASC';
      this.order = column;
      this.reverse = true;
      this.eventsResourceService.getListEvents(this.triallineId, {
        page: this.page, sort: {
          field: column,
          order: this.orderValue
        }, limit: this.perPage
      })
        .subscribe((response) => {
          this.events = this.formatEvents(response['data']);
          this.total = response['total'];
          this.callfunction();
        });
    }

  }

  toggleHidden(event_in) {

    this.eventsResourceService.toggleHidden(this.triallineId, event_in.id).toPromise()
      .then((event: any) => {

        let index = this.events.indexOf(this.events.find(e => e.id == event.id));
        this.events[index] = this.formatEvent(event);
        this._changeDetectionRef.detectChanges();
      })
      .catch((error) => {

        this.toasts.error(error);
      });
  }

  getSortIcon(column: string) {
    return this._sanitizer.bypassSecurityTrustHtml('<i class="fa fa-sort' +
      (column == this.order ? '-' + (this.reverse ? 'asc' : 'desc') : '') + '" ' +
      'aria-hidden="true"></i>');
  }

  changePages(data: any) {

    this.perPage = data.target.value;
    if (this.order != 'tag_name') {
      this.eventsResourceService.getListEvents(this.triallineId, {
        page: this.page, sort: {
          field: this.order,
          order: this.orderValue
        }, limit: this.perPage
      })
        .subscribe((response) => {
          this.events = this.formatEvents(response['data']);
          this.total = response['total'];
          if (this.events.length > 0) {
            this.callfunction()
          }
        });
    } else {
      this.total = this.events1.length;
      this.page = data;

      if (this.events1.length > 0) {
        this.callfunction()
      }
    }
  }

  ngOnInit() {    
    var i = 0;
    var j = 0;
    localStorage.setItem("folderpath", '');
    this.currentStatus = 'start_date';
    this.route.data.subscribe((data: { events: Event[], tl: Trialline }) => {
      this.events1 = []
      this.events = this.formatEvents(data.events);
      this.trialline = data.tl;      
      if (this.events.length > 0) {
        this.callfunction()
      }
      this.events1 = this.events
    });
    this.sub = this.route.parent.params.subscribe(params => {
      this.triallineId = +params["trialline_id"];
    });
    this.eventsResourceService.getListEvents(this.triallineId, {
      page: this.page, sort: {
        field: this.order,
        order: this.orderValue
      }, limit: this.perPage
    })
      .subscribe((response) => {
        this.events = this.formatEvents(response['data']);
        console.log(this.events);
        if (this.events.length > 0) {
          this.callfunction()
        }
        this.total = response['total'];
      });
    this._changeDetectionRef.detectChanges();
    this.loadCategories();
    document.addEventListener('keydown', (event) => {
      const keyName = event.key;
      let table = document.querySelector('.tttsad');
      if (keyName === 'ArrowRight') {
        table.scrollLeft += 20;
      } else if (keyName == 'ArrowLeft') {
        table.scrollLeft -= 20;
      }
      if (keyName === 'Escape') {
        this.editBox = {}
      }
    }, false);
    document.addEventListener('click', (event) => {
      let classes = (event.target as Element).classList
      if (classes.contains('text-left')
        || classes.contains('portlet-title')
        || classes.contains('light list-events')
        || classes.contains('page-breadcrumb')
        || classes.contains('container')
        || classes.contains('page-content')) {
        this.editBox = {}
      } else {
        return
      }
    }, false);
    this.customColumns = [];
    this.eventsResourceService.getCustomColumns(this.triallineId).subscribe((data) => {
      if(data) {
        data.forEach(item => {
          this.customColumns.push({
            'name': item.name,
            'value': item.value,
            'image_url': item.image_url,
            'display': item.display
          });
          this.selectedColumns.push(item.name)
        });
      }
      console.log(this.customColumns)
    }, (error) => {
      console.log(error)
    });
    this.eventsResourceService.getSelectedColumns(this.triallineId).subscribe((data) => {
      let selectedColumns = data
      console.log(selectedColumns)
      if (selectedColumns != null) {
        this.allColumns = selectedColumns;
        console.log(this.allColumns)
      }
    }, (error) => {
      console.log(error)
    });
    this.eventsResourceService.getTagList(this.triallineId).subscribe((data) => {
      data.forEach(item => {
        this.tagList.push({
          'id': item.id,
          'tag_name': item.tag_name,
          'event_id': item.event_id
        });
        // this.selectedColumns.push(item.name)
      });
      console.log(this.tagList)
    }, (error) => {
      console.log(error)
    });

    // Assign date format to the component
    this.displayDateFormat = this.calendarDateFormatSelection();

    this.datePickerConfig['dateInputFormat'] = this.displayDateFormat.fullDateFormat;

  }

  loadCategories() {
    this.triallineResoureService.getCategories(this.triallineId).toPromise()
      .then((categories: Category[]) => {
        this.eventCategories = categories;
      })
      .catch((error) => {
        this.toasts.error(error);
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    console.log(this.customColumns)
    let columns = {
      'columns': this.allColumns
    }
    this.eventsResourceService.updateSelectedColumns(this.triallineId, columns).subscribe((data) => {
      //
    }, (error) => {
      console.log(error)
    });
  }

  pageChanged(data: any) {

    if (this.order != 'tag_name') {
      this.eventsResourceService.getListEvents(this.triallineId, {
        page: data, sort: {
          field: this.order,
          order: this.orderValue
        }, limit: this.perPage
      })
        .subscribe((response) => {
          this.events = this.formatEvents(response['data']);
          this.total = response['total'];
          this.page = data;
          if (this.events.length > 0) {
            this.callfunction()
          }
        });
    } else {
      this.total = this.events1.length;
      this.page = data;
      //   for(let i = (this.perPage*data);i<this.events1.length;i++)
      //   {
      // //    console.log(this.events1[i])
      //   }

      if (this.events1.length > 0) {
        this.callfunction()
      }

    }
  }

  edit(event: Event) {
    this.sharedService.encriptedpaththumbnail = event.thumbnail_encryptid
    this.sharedService.tagarray = event.tag_name;
    this.loadEvent(event.id);
    jQuery('#general').collapse('show');
    jQuery('#add-tag').collapse('hide');
    jQuery('#provider-details').collapse('hide');
    jQuery('#add-media').collapse('hide');
    jQuery('#notes').collapse('hide');
  }

  create() {
    
    this.order = 'start_date';
    //  this.ngOnInit();
    this.event = new Event();
    this.sharedService.tagarray = [];
    //this.sharedService.newaddtag=[];

    this.editModal = true;
    jQuery('#event-edit-modal').modal('show');
    jQuery('#general').collapse('show');
    jQuery('#provider-details').collapse('hide');
    jQuery('#add-media').collapse('hide');
    jQuery('#notes').collapse('hide');
    jQuery('#add-tag').collapse('hide');
    localStorage.removeItem('folderpath');
    $("#thumbnail_visible").prop("checked", true);
    this._changeDetectionRef.detectChanges();
  }

  loadEvent(id: number) {
    console.log('Event Loading with id:', id)
    this.eventsResourceService.getEvent(this.triallineId, id,

      'category,files,files.contacts,files.billcosts,files.contacts.category,videos,privateFiles,contacts,contacts.categories,contacts.privateFiles,billCostFiles')

      .subscribe(
        (res: any) => {
          let event = res.body.event;
          this.event = event;
          this.thumbnail_visible = this.event.thumbnail_visible;
          this.event.date_start = new Date(this.event.start_date);
          if (this.event.date_end) {
            this.event.date_end = new Date(this.event.end_date);
          }
          console.log('Event Modal true')
          this.editModal = true;

          this._changeDetectionRef.detectChanges();

          if (this.thumbnail_visible == true) {
            $("#thumbnail_visible").prop("checked", true);
          } else {
            $("#thumbnail_visible").prop("checked", false);
          }
          jQuery('#event-edit-modal').modal('show');
        },
        (error: any) => {
          console.log('error' + error);
          this.toasts.error(error);
        }
      );
  }

  parseDateTo(date) {

    if (!date) return null;

    return TriallineEventsComponent.parseDate(date);
  }

  static parseDate(date) {

    let parsed = new Date(date);

    let offset = parsed.getTimezoneOffset() * 60000;

    return new Date(parsed.getTime() - offset);
  }

  static testParse(date) {

    let parsed = new Date(date);

    let offset = parsed.getTimezoneOffset() * 60000;

    return new Date(parsed.getTime() - offset);
  }
  static parseDateNew(date) {
    let finaldate = date
    if(date != null){
    var myarr = date.split("T");
    var dateArr = myarr[0].split('-')
    finaldate = dateArr[1]+"/"+dateArr[2]+"/"+dateArr[0];
    // let myarrTime = myarr[1].split('+')[0]

    //  let checkingTime = myarrTime.split(':')
    //  if(checkingTime[0] != '00' || checkingTime[1] != '00'){
    //    finaldate = finaldate +" "+ this.tConvert(myarrTime);
    //  }
    }

   return finaldate;
  }
  // static tConvert(time: any): any {
  //    // Check correct time format and split into components
  //    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  //    if (time.length > 1) { // If time format correct
  //      time = time.slice (1);  // Remove full string match value
  //      time[5] = +time[0] < 12 ? 'am' : 'pm'; // Set AM/PM
  //      time[0] = +time[0] % 12 || 12; // Adjust hours
  //    }
  //    return time[0]+":"+time[2]+" "+time[5]; // return adjusted time or original string
  // }

  clearEditBox() {
    this.editBox = {}
  }

  modalClosed(event: Event) {

    if (this.editModal) {
      // this.editModal = false;
    }
  }

  updateEvent(event: Event) {
    let index = this.events.indexOf(this.events.find(e => e.id == event.id));
    this.events[index] = this.formatEvent(event);
    this.eventsResourceService.getListEvents(this.triallineId, {
      page: this.page, sort: {
        field: this.order,
        order: this.orderValue
      }, limit: this.perPage
    }).subscribe((response) => {
        this.events = this.formatEvents(response['data']);
        console.log(this.events);
        if (this.events.length > 0) {
          this.callfunction()
        }
        this.total = response['total'];
      });
    this._changeDetectionRef.detectChanges();
    // this.callfunction()

  }


  deleteEvent(event) {

    const dialogRef = this.modal.confirm()
      .size('lg')
      .isBlocking(true)
      .showClose(true)
      .keyboard(27)
      .title('Are you sure?')
      // .body('Warning! In case of deleting this Event you won\'t be able to renew it. Do you want to proceed?')
      .body('Warning! If you delete this event, you will not be able to retrieve it. Do you still want to delete?')
      .open()
    $('bs-modal-container').addClass('show-midal-element');
    dialogRef.result
      .catch((err: any) => {
        $('body').removeClass('modal-open');
        console.log('ERROR: ' + err)
      })
      .then((dialog: any) => {
        $('body').removeClass('modal-open');
        return dialog.result
      })
      .then((result: any) => {
        this.confirmedDeleteEvent(event)
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  confirmedDeleteEvent(event: Event) {

    this.eventsResourceService.deleteEvent(this.triallineId, event.id).toPromise()
      .then((response: any) => {

        let index = this.events.indexOf(this.events.find(e => e.id == event.id));
        this.events.splice(index, 1);
        this.toasts.success('Successfully deleted event');
        let index1 = this.events1.indexOf(this.events1.find(e => e.id == event.id));
        this.events1.splice(index1, 1);
        //this.ngOnInit();
      })
      .catch((error) => {

        this.toasts.error(error);
      });
  }

  addEvent(event: Event) {
    this.eventsResourceService.getListEvents(this.triallineId, {
      page: this.page, sort: {
        field: this.order,
        order: this.orderValue
      }, limit: this.perPage
    }).subscribe((response) => {
        this.events = this.formatEvents(response['data']);
        console.log(this.events);
        if (this.events.length > 0) {
          this.callfunction()
        }
        this.total = response['total'];
      });
    this._changeDetectionRef.detectChanges();
    this.events.push(this.formatEvent(event));
    this.events1.push(this.formatEvent(event));
    // this.callfunction()
  }

  addCopyEvent(e) {
    this.copyEventData = e;
    console.log('copy event data', this.copyEventData)
    this.copyEvent = true;
    this.editModal = false;
    this._changeDetectionRef.detectChanges();
    jQuery('#event-edit-copy-modal').modal('show');
    jQuery('#general_copy').collapse('show');
    jQuery('#provider-details_copy').collapse('hide');
    jQuery('#add-media_copy').collapse('hide');
    jQuery('#notes').collapse('hide');
    jQuery('#add-tag_copy').collapse('hide');
    $("#thumbnail_visible_copy").prop("checked", true);
  }

  formatEvents(events) {
    return events.map((event) => {
      return this.formatEvent(event);
    })
  }

  calendarDateFormatSelection(){
    console.log('this.trialline.trialline_date_format: ',this.trialline.trialline_date_format)
    switch(this.trialline.trialline_date_format) {
      case 'YMD':
        return {
          'halfDateFormat' : 'YYYY/MM',
          'fullDateFormat' : 'YYYY/MM/DD',
        };
      break;
      case 'DMY':
        return {
          'halfDateFormat' : 'MM/YYYY',
          'fullDateFormat' : 'DD/MM/YYYY',
        };
      break;
      case 'MDY':
        return {
          'halfDateFormat' : 'MM/YYYY',
          'fullDateFormat' : 'MM/DD/YYYY',
        };
      break;
      default:
        return {
          'halfDateFormat' : 'MM/YYYY',
          'fullDateFormat' : 'MM/DD/YYYY',
        };
      break;
    }

  }

  formatEvent(event) {  
    //console.log('I am in format events'); 
    //console.log('event:',event)
    let modified_startdate,modified_enddate;
    const startDateFormat = event.if_start_days ? 'MM/YY' : `MM/DD/YY`;
    const endDateFormat = event.if_end_days ? 'MM/YY' : `MM/DD/YY`;
    event.dateStartDisplay = event.start_date ? (event.start_date): '';
    event.dateEndDisplay = event.end_date ? (event.end_date) : '';
    event.categoryNameDisplay = event.category_name && event.category_name.length > 15 ?
      event.category_name.substring(0, 15) : event.category_name;
    event.exhibitNumberDisplay = event.exhibit_number && event.exhibit_number.length > 15 ?
      event.exhibit_number.substring(0, 15) : event.exhibit_number;
    //event.dateCreatedDisplay = event.date_created_at ? moment(event.date_created_at).format('MM/DD/YY') : '';
    event.dateCreatedDisplay = event.date_created_at ? moment(event.date_created_at).format(this.displayDateFormat.fullDateFormat) : '';

    //console.log('event.start_date',event.start_date)
    modified_startdate = event.start_date.split(' ');
    //event.date_picker_date_start = new Date(modified_startdate[0]);
    event.date_picker_date_start = new Date(event.start_date);

      //console.log('event.date_picker_date_start Date:',event.start_date)
      //console.log('event.date_picker_date_start Date:',event.date_picker_date_start)
    
    // event.date_picker_date_start = Date.parse(event.date_picker_date_start);
    // console.log('event.date_picker_date_start Date.parse:',event.date_picker_date_start)
    // event.date_picker_date_start = (event.date_picker_date_start | date:'yyyy-MM-dd HH:mm:ss Z')

    //console.log('event.end_date',event.end_date)

    if (event.date_end) {
      modified_enddate = event.end_date.split(' ');
      event.date_picker_date_end = new Date(modified_enddate[0]);
    } else {
      event.date_picker_date_end = new Date();
    }

    //console.log('event.date_picker_date_end',event.date_picker_date_end)

    if (event.has_times) {
      if(event.date_start != null){
        event.date_picker_date_start_time = new Date(event.start_date);
        event.timeStartDisplay = new Date(event.start_date) 
      } else {
          event.date_picker_date_start_time = null;
        }
      } else {
      event.date_picker_date_start_time = null;
    }
    if (event.has_times_end) {
      if(event.date_end != null){
        event.date_picker_date_end_time = new Date(event.end_date);
        event.timeEndDisplay = new Date(event.end_date)
      } else {
        event.date_picker_date_end_time = null;
      }
    } else {
      event.date_picker_date_end_time = null;
    }
    if (event.concattagdata) {
      let tags = event.concattagdata

      // event.spacedTags = tags.join(', ')
    }
    if (event.notes != null) {
      let notes = this.htmlDecode(event.notes) //replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ')
      event.escapedNotes = notes.split('. ')[0]
    }
    if (event.description != null) {
      let description = this.htmlDecode(event.description) //event.description.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ').replace(/&rsquo;/g, "'")
      //event.escapedDescription = description.split('.')[0]
      if(description.length > 55) {
        event.escapedDescription = description.substring(0, 55)+'...';
      } else {
        event.escapedDescription = description;
      }
      
    }
    // console.log("event.custom_field", event.custom_field)
    event.custom_field = event.custom_field != null  ? JSON.parse(event.custom_field) : null
    event.showHideFile =false;
    event.showHideTagFile = false;
    event.dateKeyFirstTime =false
    return event;
  }

   htmlDecode(input) {
    const doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }
  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, this.fileName + this.fileExtension);
  }

  incomingfile(event: any) {
    //this.files=(e.target.files[0]);

    const fileSelected: File = event.target.files[0];
    this.myFiles = fileSelected
  }

  eraseFile(){
    this.myFiles = null;
  }

  importexcelfile(event: any) {
    this.uploading = true;
    if ((this.myFiles != null) && (this.myFiles != undefined)) {
      let formData = new FormData();
      formData.append("file", this.myFiles, this.myFiles.name);
      let fileReader = new FileReader();
    fileReader.readAsBinaryString(this.myFiles);

    fileReader.onload = () => {
      var workBook = XLSX.read(fileReader.result,{type:'binary'});
      var sheetNames = workBook.SheetNames;
      this.ExcelData = XLSX.utils.sheet_to_json(workBook.Sheets[sheetNames[0]])
      console.log("myFiles",this.myFiles)
      console.log("ExcelData",this.ExcelData)
      console.log("formData",formData)
      for(let i=0; i<this.ExcelData.length; i++){
        let startDate = this.ExcelData[i]['Event Start Date (MM/DD/YYYY) *Required'];
        if (startDate) {
          if(typeof startDate != "number"){
            this.uploading = false;
            return this.toasts.error(`Invalide data in Row ${i+1}`);
          }
        }        
        console.log("startDate", startDate);
      }
      if(this.ExcelData.length == 0){
        this.uploading = false;
        return this.toasts.error("Excel file is empty.")
       }
       else{
        this.eventsResourceService.importexcl(this.triallineId, formData).toPromise()
        .then((response: any) => {
          if (response.result == 0) {
            this.uploading = false;
            this.toasts.error('Somthing goes wrong,please check your excel file.');
          } else {
            this.uploading = false;
            this.toasts.success('Successfully Imported ' + response.upload + ' Events');
            this.myFiles = null;
          }
          //this.toasts.success('Successfully Imported All Events');
          this.ngOnInit()
          jQuery('#excelimport').modal('hide');
        }).catch((error) => {
          this.uploading = false;
          return this.toasts.error('select a valid file');
          //console.log(this.myFiles);
        });
      }
    }
  }
  else {
    this.uploading = false;
    return this.toasts.error('Please Select File to Upload');
  }
}

  setfalsefun() {
    this.sharedService.status = false
    /* this.sharedService.tagsid=[];
    this.sharedService.checkid=0; */
  }

  callfunction() {
    for (let i = 0; i < this.events.length; i++) {
      this.tagconcatarray = [];
      if (this.events[i].tags_count > 0) {
        for (let j = 0; j < this.events[i].tag_name.length; j++) {
          //console.log(this.events[i].tag_name[j].tag_name)
          this.tagconcatarray.push(this.events[i].tag_name[j].tag_name)
        }
        this.tagconcatarray.sort();
        //console.log(this.tagconcatarray.join())
        this.events[i].concattagdata = this.tagconcatarray.join(', ');
      } else {
        this.events[i].concattagdata = '';
      }
    }
    let sortInfo = this.orderValue === 'DESC' ? '-' : '';
    // this.events.sort(this.dynamicSort(sortInfo + this.order))
  }

  tagreorder(column: string) {
    this.currentStatus = column;
    if (column == this.order) {
      this.orderValue = this.orderValue === 'DESC' ? 'ASC' : 'DESC';
      this.reverse = !this.reverse;
      if (this.orderValue == 'ASC') {
        for (let i = 0; i < this.events1.length; i++) {
          this.tagconcatarray = [];
          if (this.events1[i].tags_count > 0) {
            for (let j = 0; j < this.events1[i].tag_name.length; j++) {
              this.tagconcatarray.push(this.events1[i].tag_name[j].tag_name)
            }
            this.tagconcatarray.sort((a, b) => 0 - (a > b ? -1 : 1));
            this.events1[i].concattagdata = this.tagconcatarray.join();
          } else {
            this.events1[i].concattagdata = '';
          }
        }
        //this.events.sort((a,b) => 0-(a.concattagdata > b.concattagdata ? -1 : 1));
        this.events = this.events1.sort(this.alphabetically(true));
      } else {
        for (let i = 0; i < this.events1.length; i++) {
          this.tagconcatarray = [];
          if (this.events1[i].tags_count > 0) {
            for (let j = 0; j < this.events1[i].tag_name.length; j++) {
              this.tagconcatarray.push(this.events1[i].tag_name[j].tag_name)
            }
            this.tagconcatarray.sort((a, b) => 0 - (a > b ? 1 : -1));
            this.events1[i].concattagdata = this.tagconcatarray.join();
          } else {
            this.events1[i].concattagdata = '';
          }
        }
        //this.events.sort((a,b) => 0- (a.concattagdata > b.concattagdata ? 1 : -1));
        this.events = this.events1.sort(this.alphabetically(false));
      }
    } else {
      this.orderValue = 'ASC';
      this.order = column;
      this.reverse = true;
      for (let i = 0; i < this.events1.length; i++) {
        this.tagconcatarray = [];
        if (this.events1[i].tags_count > 0) {
          for (let j = 0; j < this.events1[i].tag_name.length; j++) {
            this.tagconcatarray.push(this.events1[i].tag_name[j].tag_name)
          }
          this.tagconcatarray.sort((a, b) => 0 - (a > b ? -1 : 1));

          this.events1[i].concattagdata = this.tagconcatarray.join();

        } else {
          this.events1[i].concattagdata = '';
        }

        //this.events.sort((a,b) =>0-(a.concattagdata > b.concattagdata ? -1 : 1));
        this.events = this.events1.sort(this.alphabetically(true));
      }

    }
  }

  alphabetically(ascending) {

    return function (a, b) {

      // equal items sort equally
      if (a.concattagdata === b.concattagdata) {
        return 0;
      }
      // nulls sort after anything else
      else if ((a.concattagdata === null) || (a.concattagdata === '')) {
        return 1;
      } else if ((b.concattagdata === null) || (b.concattagdata === '')) {
        return -1;
      }
      // otherwise, if we're ascending, lowest sorts first
      else if (ascending) {
        return a.concattagdata < b.concattagdata ? -1 : 1;
      }
      // if descending, highest sorts first
      else {
        return a.concattagdata < b.concattagdata ? 1 : -1;
      }

    };

  }

  opendiv() {
    this.divstatus = !this.divstatus;
  }

  showhide(columnname, event) {

    if (columnname == 'description') {
      this.descriptionstatus = !!event.target.checked;
    }

    if (columnname == 'notes') {
      this.notesstatus = !!event.target.checked;
    }
  }

  markAsEditable(event) {
    let selectEdevent = this.events.find(({ id }) => id === event.id);
    let index = this.events.indexOf(selectEdevent);
    this.events[index]['editable'] = true;
  }

  removeEditable(event) {
    let selectEdevent = this.events.find(({ id }) => id === event.id);
    let index = this.events.indexOf(selectEdevent);
    this.events[index]['editable'] = false;
  }

  clickEvent(event) {
    //if you just want to toggle the class; change toggle variable.
    this.toggle = !this.toggle;

    console.log(this.toggle)
  }

  displayColumns() {
    let arr = [];

    this.allColumns.forEach(item => {
      if (item.display === true) {
        arr.push(item.name)
      } else {
        this.isAllColumnsChecked = false;
      }
    });
    this.selectedColumns = arr;
    if (this.selectedColumns.length == this.allColumns.length) {
      this.isAllColumnsChecked = true
    }
    this.updateCustomColumnsAndSelectedColumns()
    console.log(this.selectedColumns)
  }

  clickedBox(eventId, eventColumn) {
    this.editBox = {}
    this.editBox = { 'eventId': eventId, 'column': eventColumn }
  }

  clickedCustomBox(eventId, eventColumn) {
    this.customEditBox = {}
    this.customEditBox = { 'eventId': eventId, 'columnId': eventColumn }
    console.log(this.customEditBox)
  }

  parseDate(date: Date, time: Date = null) {
    let outDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), time ? time.getHours() : 0,
      time ? time.getMinutes() : 0, time ? time.getSeconds() : 0);
    return outDate.toLocaleString('en-US')
  }

  focusOutFunction(event) {
    event.start_date = event.has_times ? moment(event.start_date).format("MM/DD/YYYY HH:mm:ss") : moment(event.start_date).format("MM/DD/YYYY");
    event.end_date = event.end_date !=null ? event.has_times_end ? moment(event.end_date).format("MM/DD/YYYY HH:mm:ss"): moment(event.end_date).format("MM/DD/YYYY"):null
    this.editBox = {};
    this.customEditBox = {};
    this.eventsResourceService.updateEvent(this.triallineId, event.id, event).subscribe((data) => {
      let selectEdevent = this.events.find(({ id }) => id === data.body.event.id);
      let index = this.events.indexOf(selectEdevent);
      this.tagconcatarray = data.body.event.tag_name
      // this.events[index] = data.body.event
      this.formatEvent(this.events[index])
      this.removeEditable(event)
      this.callfunction()
      this.toasts.success('Successfully updated');
      this._changeDetectionRef.detectChanges();
    }, (error) => {
      console.log(error)
    });
  }

  startDateChange(e, event) {
    let mainDate = new Date(event.date_picker_date_start_time);
    if (event.start_date != undefined) {
      let startDate = new Date(e);
      let endDate = new Date(event.end_date);
      if(startDate.getTime() > endDate.getTime()){
        this.toasts.error('Start Date should be smaller than End date!');
        event.start_date = ''
        e = '';
        event.date_picker_date_start= '';
        return;
      }
      mainDate = new Date(event.end_date);
    } else {
      mainDate = new Date(e);
    }
    let hour = mainDate.getHours()
    let minutes = mainDate.getMinutes()
    let seconds = mainDate.getSeconds()
    let year = e.getFullYear()
    let month = e.getMonth()
    let day = e.getDate()
    let date = new Date(year, month, day, hour, minutes, seconds);
    let data = {
      'start_date': date.toLocaleString('en-GB')
    }
    this.eventsResourceService.updateStartTime(this.triallineId, event.id, data).subscribe((data) => {
      this.toasts.success('Successfully updated');
      this.eventsResourceService.getListEvents(this.triallineId, {
        page: this.page, sort: {
          field: this.order,
          order: this.orderValue
        }, limit: this.perPage
      }).subscribe((response) => {
          this.events = this.formatEvents(response['data']);
          if (this.events.length > 0) {
            this.callfunction()
          }
          this.total = response['total'];
        });
      this._changeDetectionRef.detectChanges();
    }, (error) => {
      console.log(error)
    });
  }

  endDateChange(e, event) {
    let mainDate = null;
    if (event.end_date != undefined) {
      let endDate = new Date(e);
      let startDate = new Date(event.start_date);
      if(endDate.getTime() < startDate.getTime()){
        this.toasts.error('End Date should be greater than Start date!');
        event.end_date = ''
        e = '';
        event.date_picker_date_end= '';
        return;
      }
      mainDate = new Date(event.end_date);
    } else {
      mainDate = new Date(e);
    }
    let hour = mainDate.getHours() ? mainDate.getHours() : null;
    let minutes = mainDate.getMinutes() ? mainDate.getMinutes() : null;
    let seconds = mainDate.getSeconds() ? mainDate.getSeconds() : null;
    let year = e.getFullYear()
    let month = e.getMonth()
    let day = e.getDate()
    let date = new Date(year, month, day, hour, minutes, seconds);
    // console.log(date.toLocaleString('en-GB'));


    // event.end_date = event.date_picker_date_end;
    const endDateFormat = event.if_end_days ? 'MM/YY' : `MM/DD/YY`;
    event.dateEndDisplay = event.date_end ? moment(this.parseDateTo(event.date_end)).format(endDateFormat) : '';

    // console.log(e.getHours(), e.get)
    let data = {
      'end_date': date.toLocaleString('en-GB')

    }
    // console.log(data);
    this.eventsResourceService.updateEndDate(this.triallineId, event.id, data).subscribe((data) => {
      // this.ngOnInit()
      this.toasts.success('Successfully updated');
      this.eventsResourceService.getListEvents(this.triallineId, {
        page: this.page, sort: {
          field: this.order,
          order: this.orderValue
        }, limit: this.perPage
      })
        .subscribe((response) => {
          this.events = this.formatEvents(response['data']);
          console.log(this.events);
          if (this.events.length > 0) {
            this.callfunction()
          }
          this.total = response['total'];
        });
      this._changeDetectionRef.detectChanges();
      // event.date_picker_date_end = date.toLocaleString()
    }, (error) => {
      console.log(error)
    });
  }

  timeChange(targetDate, datePickerDate) {
    let finalDateHours;
    let finalDateMinutes;
    let finalDateYear = TriallineEventsComponent.parseDate(targetDate).getFullYear();
    let finalDateMonth = TriallineEventsComponent.parseDate(targetDate).getMonth();
    let finalDateDate = TriallineEventsComponent.parseDate(targetDate).getDate();
    let intendedDate;
    finalDateHours = datePickerDate.getHours();
    finalDateMinutes = datePickerDate.getMinutes();
    intendedDate = new Date(finalDateYear, finalDateMonth, finalDateDate, finalDateHours, finalDateMinutes);
    return TriallineEventsComponent.testParse(intendedDate);
  }

  changeEventStartTime(e, event) {
    $('.bs-timepicker-field').on('mousewheel', function(e) {
      $(e.target).blur();     
    });

    //console.log("event.date_start", event.date_start)
    if (event.date_picker_date_start_time === e) {
      //console.log('dont edit')
    } else {
      //console.log('edit')
      let mainDate = new Date(event.date_start);
      let testDate = new Date(e);
      let hour = e.getHours()
      let minutes = e.getMinutes()
      let seconds = e.getSeconds()
      // let year = mainDate.getFullYear()
      // let month = mainDate.getMonth()
      // let day = mainDate.getDate()
      // let date = new Date(year, month, day, hour, minutes, seconds);
        
      let newDate = TriallineEventsComponent.parseDateNew(event.date_start)+ " "+ hour +":"+minutes +":"+seconds
      let startDateTime = moment(newDate).format("DD/MM/YYYY, HH:mm:ss");
      let data = {
        'start_date':startDateTime,
        'has_times': true
      }
      
      this.eventsResourceService.updateStartTime(this.triallineId, event.id, data).subscribe((data) => {
        // this.ngOnInit()
        this.eventsResourceService.getListEvents(this.triallineId, {
          page: this.page, sort: {
            field: this.order,
            order: this.orderValue
          }, limit: this.perPage
        }).subscribe((response) => {
            this.events = this.formatEvents(response['data']);
            console.log(this.events);
            if (this.events.length > 0) {
              this.callfunction()
            }
            this.total = response['total'];
          });
        this._changeDetectionRef.detectChanges();



        this.toasts.success('Successfully updated');

      }, (error) => {
        console.log(error)
      });
    }

  }

  changeEventEndTime(e, event) {
   console.log("event.date_start", event.date_end)
    if (event.date_picker_date_end_time === e) {
      console.log('dont edit')
    } else {
      console.log('edit')
      let mainDate = new Date(event.date_end);
      console.log(mainDate)
      console.log(e)
      let hour = e.getHours()
      let minutes = e.getMinutes()
      let seconds = e.getSeconds()
      // let year = mainDate.getFullYear()
      // let month = mainDate.getMonth()
      // let day = mainDate.getDate()
      // let date = new Date(year, month, day, hour, minutes, seconds);
      // console.log(date.toUTCString());
      // console.log(e.getHours(), e.get)
      let newDate = TriallineEventsComponent.parseDateNew(event.date_end)+ " "+ hour +":"+minutes +":"+seconds
      let endDateTime = moment(newDate).format("DD/MM/YYYY, HH:mm:ss");
      let data = {
        'end_date': endDateTime,
        'has_times_end': true
      }
      console.log(data);
      this.eventsResourceService.updateEndDate(this.triallineId, event.id, data).subscribe((data) => {
        this.eventsResourceService.getListEvents(this.triallineId, {
          page: this.page, sort: {
            field: this.order,
            order: this.orderValue
          }, limit: this.perPage
        }).subscribe((response) => {
            this.events = this.formatEvents(response['data']);
            console.log(this.events);
            if (this.events.length > 0) {
              this.callfunction()
            }
            this.total = response['total'];
          });
        this._changeDetectionRef.detectChanges();
        this.toasts.success('Successfully updated');

      }, (error) => {
        console.log(error)
      });
    }
  }

  IsAllChecked(allColumns) {
    let result = true

    for (let i = 0; i < allColumns.length; i++) {
      if (allColumns[i]['display'] == false) {
        result = false;
        break;
      }
    }
    return result
  }

  selectAll() {
    let allColumns = this.allColumns;
    if (this.isAllColumnsChecked) {
      for (let i = 0; i < allColumns.length; i++) {
        allColumns[i]['display'] = true
        this.selectedColumns = [
          'Start Date', 'Start Time', 'End Date', 'End Time', 'Title', 'Category', 'Tags', 'Exhibit #', 'Date Added', 'Comments', 'Notes', 'Bates #', 'Action'
        ]
      }
      // customColumns selected
      let customColumns = this.customColumns;
      for (let j = 0; j < customColumns.length; j++) {
        customColumns[j]['display'] = true
      }
      this.customColumns = customColumns
    } else {
      for (let i = 0; i < allColumns.length; i++) {
        allColumns[i]['display'] = false
        this.selectedColumns = []
      }
      // customColumns unselected
      let customColumns = this.customColumns;
      for (let j = 0; j < customColumns.length; j++) {
        customColumns[j]['display'] = false
      }
      this.customColumns = customColumns
    }
    this.updateCustomColumnsAndSelectedColumns()
  }

  addColumn(form: NgForm) {
    console.log(form.value.columnImage)
    if (form.value.columnName === undefined || form.value.columnName === null) {
      this.customColumnErrors = 'Please Enter Column Name ';
      // form.resetForm();
      console.log('failure')
      return;
    } else if (form.value.columnImage === undefined || form.value.columnImage === null) {
      this.customColumnErrors = 'Please Select Icon';
      console.log('failure')

      // form.resetForm();
    } else {
      this.customColumnErrors = '';
      let column = [{
        "name": form.value.columnName,
        "value": '',
        "image_url": form.value.columnImage,
        "display": true
      }]
      this.eventsResourceService.addCustomColoumn(this.triallineId, column).subscribe((data) => {
        this.ngOnInit()
        form.resetForm()
      }, (error) => {
        console.log(error)
      });
      console.log('success')
      form.resetForm()
      this.customColumnErrors = '';
    }
  }

  open(content, selectedTags, event) {
    this.selectedTags = selectedTags;
    let triallineTagIds = this.tagList.map(a => a.id);
    let selectedTag = this.tagList.map(a => a.id);

    // console.log(result)
    this.event = event;
    // console.log(this.selectedTags)
    // console.log(this.event.id)
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  showMoreTagName(id) {
    this.showShortTagDesciption = !this.showShortTagDesciption
    var objIndex =  this.events.findIndex((obj => obj.id == id));
    if(this.events[objIndex].showHideTagFile){
      this.events[objIndex].showHideTagFile = false
    } else {
      this.events[objIndex].showHideTagFile = true
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  addCategory(category: Category) {
    this.loadCategories();
  }

  removeTag(tag) {
    console.log(tag.id)
    let data = {};
    this.eventsResourceService.detachTag(this.triallineId, this.event.id, tag.id, data).subscribe((data) => {
      // this.ngOnInit()
      // this.event.tag_name.push(data);
      this.toasts.success('Successfully deleted');
      let index = this.event.tag_name.findIndex(function (o) {
        return o.id;
      })

      this.event.tag_name.splice(this.event.tag_name.findIndex(function (i) {
        return i.id === tag.id;
      }), 1);
      console.log(index)
      // if (index !== -1)  this.event.tag_name.splice(index, 1);
      console.log(this.event.tag_name)
    }, (error) => {
      console.log(error)
    });
  }

  // tags

  addTags = (tag_name) => {
    let data = {
      "tag_name": tag_name
    }
    this.eventsResourceService.createTagAndAttach(this.triallineId, this.event.id, data).subscribe((data) => {
      // this.ngOnInit()
      this.toasts.success('Successfully added');
      this.event.tag_name.push(data)
    }, (error) => {
      console.log(error)
    });

    console.log(this.event.tag_name)
  };

  selectTag() {
    let selectedTags = this.selectedTags.map(a => a.id);
    let tagIndex = this.tagList.findIndex(x => x.id === this.selectedTag);

    let data = {}
    let selectedTag = this.tagList[tagIndex];
    if (!selectedTags.includes(this.selectedTag)) {
      this.eventsResourceService.attachTag(this.triallineId, this.event.id, selectedTag.id, data).subscribe((data) => {
        // this.ngOnInit()
        this.toasts.success('Successfully added');
        this.event.tag_name.push(this.tagList[tagIndex]);
      }, (error) => {
        console.log(error)
      });

    }
  }

  alterDescriptionText(id) {
    this.showShortDesciption = !this.showShortDesciption
    var objIndex =  this.events.findIndex((obj => obj.id == id));
    if(this.events[objIndex].showHideFile){
      this.events[objIndex].showHideFile = false
    } else {
      this.events[objIndex].showHideFile = true
    }
  }
  

  openImageBox(content, fileDetail, event) {
    this.popupImage = '';
    this.imageGot = false;
    console.log("openImageBox");

    console.log('content', content);
    console.log('file detail', fileDetail);
    console.log('event', event);

    this.event = event;
    this.file = fileDetail

    jQuery('#preview-modal-shared').modal('show');
    if (fileDetail.type === 'pdf' && this.screenW) {
      this.pdfpathIpad = this.getSafeResourceUrlPdf();
    }
   

  // getSafeResourceUrlPdf() {
  //   return this._sanitizer
  //     .bypassSecurityTrustResourceUrl('https://drive.google.com/viewerng/viewer?embedded=true&url='
  //       + this.apiPath + 'filesystem/' + this.file.encrypt_id);
  }
  getSafeResourceUrlPdf() {
    return this._sanitizer
      .bypassSecurityTrustResourceUrl('https://drive.google.com/viewerng/viewer?embedded=true&url='
        + this.apiPath + 'filesystem/' + this.file.encrypt_id);
  }

  openRichTextEditor(content, event) {
    this.event = event;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: "lg" }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  eventFiles(content, event) {
    this.event = event;
    console.log(this.event)
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: "lg" }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  onClose(event) {
    console.log(event);
    this.dropzoneEventFiles = []
  }
  onSelect(event) {
    console.log(event);
    this.dropzoneEventFiles.push(...event.addedFiles);
  }

  onRemove(event) {
    console.log(event);
    this.dropzoneEventFiles.splice(this.dropzoneEventFiles.indexOf(event), 1);
  }

  uploadFiles() {
    this.fileCount = 0;
    this.uploadFilesProcessing = true
    var filelen = this.dropzoneEventFiles.length
    this.dropzoneEventFiles.forEach((element) => {
      let file = element;
      const formData = new FormData();
      formData.append("file", file, file.name);
      formData.append("attach_to", "event");
      // @ts-ignore
      formData.append("attach_to_id", this.event.id);
      this.eventsResourceService.addFile(formData).subscribe((response: any) => {
        this.fileCount = this.fileCount + 1;
        if(this.fileCount == filelen) {
          this.getImages();
        }
      }, (error) => {
        this.fileCount = this.fileCount + 1;
        if(this.fileCount == filelen) {
          this.uploadFilesProcessing = false
          this.getImages();
        }
        this.toasts.error(error);
      });
    })
    this.dropzoneEventFiles = [];
    this.modalService.dismissAll();
    // setTimeout(() => {
    //   this.modalService.dismissAll();
    // },
    //   1000);
  }

  getImages() {
      this.orderValue = 'ASC';
      this.reverse = !this.reverse;
      this.eventsResourceService.getListEvents(this.triallineId, {
        page: this.page, sort: {
          field: this.order,
        }, limit: this.perPage
      })
      .subscribe((response) => {
        this.events = this.formatEvents(response['data']);
        this.events
        this.total = response['total'];
        this.callfunction();
        this.uploadFilesProcessing = false
        this.toasts.success('Files Uploaded Successfully');
      });
  }
  showHideCustomColumns(index) {
    jQuery('.custom_'+index).removeClass('hide')
    jQuery('#edit_custom_'+index).hide()
    jQuery('#delete_custom_'+index).hide()
    jQuery('#column_name_custom_'+index).hide()
  }

  renameCustomColumns(columns: any, index : any) {
    console.log("columns", columns, index, this.customColumns);
    let newName = jQuery('#custom_'+index).val()

    if(newName ===  ''){
      this.toasts.error('please add column name');
      return false;
    }
    this.customColumns[index].name = newName;
    jQuery('.custom_'+index).addClass('hide')
    jQuery('#delete_custom_'+index).show()
    jQuery('#edit_custom_'+index).show()
    jQuery('#column_name_custom_'+index).show()

    let data = {
      "columns": this.customColumns
    }
    this.eventsResourceService.updateCustomColumns(this.triallineId, data).subscribe((data) => {
      this.toasts.success('Successfully rename custom column');
    }, (error) => {
      console.log(error)
    });
    this.eventsResourceService.updateSelectedColumns(this.triallineId, columns).subscribe((data) => {
      //
    }, (error) => {
      console.log(error)
    });
  }

  confirmedDeleteCustomColumns(columns: any) {

    const dialogRef = this.modal.confirm()
    .size('lg')
    .isBlocking(true)
    .showClose(true)
    .keyboard(27)
    .dialogClass('in')
    .title('Are you sure?')
    .body('<h4>Please confirm you want to delete this column.</h4><h5><b>Note: <b>This will delete all data inside this column where it appears in any events.</h5>')
    .open()
    dialogRef.result.catch((err: any) => {console.log('ERROR: ' + err)
      this.modelClose()
    })
    .then((dialog: any) => {
      this.modelClose()
      if(dialog){
        this.deleteCustomColumns(columns)
      }
    });
    this.modelNewAction();
  }
  deleteCustomColumns(columns){
    console.log("columns", columns, this.customColumns);
    let filterCustomColumns = this.customColumns.filter((item) => item.name !== columns.name);
    this.customColumns = filterCustomColumns
    console.log("this.customColumns", this.customColumns, filterCustomColumns);
    let data = {
      "columns": this.customColumns
    }

    this.eventsResourceService.deleteCustomColumns(this.triallineId, data).subscribe((data) => {
      this.toasts.success('Successfully deleted custom column');
      this.eventsResourceService.getListEvents(this.triallineId, {
        page: this.page, sort: {
          field: this.order,
          order: this.orderValue
        }, limit: this.perPage
      }).subscribe((response) => {
          this.events = this.formatEvents(response['data']);
          console.log(this.events);
          if (this.events.length > 0) {
            this.callfunction()
          }
          this.total = response['total'];
        });
      this._changeDetectionRef.detectChanges();
    }, (error) => {
      console.log(error)
    });

  }

  updateCustomColumnsAndSelectedColumns() {
    let data = {
      "columns": this.customColumns
    }
    this.eventsResourceService.updateCustomColumns(this.triallineId, data).subscribe((data) => {
      //
    }, (error) => {
      console.log(error)
    });
    let columns = {
      'columns': this.allColumns
    }
    this.eventsResourceService.updateSelectedColumns(this.triallineId, columns).subscribe((data) => {
      //
    }, (error) => {
      console.log(error)
    });
  }


  displayCustomColumn(column) {
    this.updateCustomColumnsAndSelectedColumns()
  }

  /**
   * Function to sort alphabetically an array of objects by some specific key.
   *
   * @param {String} property Key of the object to sort.
   */
  dynamicSort(property) {
    let sortOrder = 1;
    let start_date = property == 'start_date'
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.


    return function (a, b) {
      if (sortOrder == -1) {
        if (start_date) {
          // @ts-ignore
          return new Date(b[property]) - new Date(a[property]);
        } else {
          return b[property].localeCompare(a[property]);
        }

      } else {
        if (start_date) {
          // @ts-ignore
          return new Date(a[property]) - new Date(b[property]);
        } else {
          return a[property].localeCompare(b[property]);
        }
      }
    }
  }

  closePreviewer() {
    this.width = 630;
    this.height = 560;
    this.left = 0;
    this.top = 0;
    jQuery('#preview-modal').modal('hide');
    jQuery('#files').removeAttr("style");
    jQuery('.tab-content').removeAttr("style");
    if (this.file != undefined) {
      if (this.file.type == 'video') {
        let file = this.file;
        // this.file = new FileModel();
        // this.ref.detectChanges();
        // this.file = file;
      }
    }
  }

  closePreviewerShared() {
    this.width = 630;
    this.height = 560;
    this.left = 0;
    this.top = 0;
    jQuery('#preview-modal-shared').modal('hide');
    jQuery('#files').removeAttr("style");
    jQuery('.tab-content').removeAttr("style");
    if (this.file != undefined) {
      if (this.file.type == 'video') {
        let file = this.file;
        // this.file = new FileModel();
        // this.ref.detectChanges();
        // this.file = file;
      }
    }
  }

  // zoomPreviewToggle() {
  //   if (screenfull && typeof screenfull.on !== "undefined") {
  //     screenfull.toggle();
  //   } else if (screenfull === false && navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null && !this.fullIpad) {
  //     this.fullIpad = true;
  //     this.fullscreen = true;
  //     setTimeout(() => {
  //       jQuery('#preview-modal').find('.zoom_active').css({
  //         'position': 'fixed',
  //         'display': 'inline-grid', 'left': '-35px', 'top': '-100px', 'width': '110%', 'max-height': '160%',
  //         'min-height': '102%'
  //       });
  //       jQuery('#preview-modal').find('.zoom_deactivate').css({
  //         'position': 'fixed',
  //         'display': 'inline-grid', 'left': '-35px', 'top': '-100px', 'width': '110%', 'max-height': '160%',
  //         'min-height': '102%'
  //       });
  //       let height = jQuery(window).height();
  //       if (height < 750) {
  //         height = height - 50;
  //       } else if (height >= 750 && height < 900) {
  //         height = height - 200;
  //       } else {
  //         height = height - 170;
  //       }
  //       jQuery('#preview-modal').find('.zoom_active').find('.modal-content').css({ 'height': height + 'px' });
  //     }, 200)
  //   } else if (screenfull === false && navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null && this.fullIpad) {
  //     this.fullIpad = false;
  //     this.fullscreen = false;
  //     jQuery('#preview-modal').find('.modal-preview').removeAttr("style");
  //     jQuery('#preview-modal').find('.zoom_deactivate').attr("style", "");
  //     jQuery('#preview-modal').find('.modal-preview').find('.modal-content').removeAttr("style");
  //   }
  // }

  getFileName(name: string) {
    if (name != undefined) {
      return name.replace(/\d{9,}_/, '');
    }
    return '';
  }


  getSafeResourceUrlEventImage() {
    if (this.imageGot == true) {
      console.log(this.popupImage);
      return this.popupImage;
    }
    if (this.file.type == 'image') {
      console.log("case 1", this.file, this.isImageRender);
      this.isImageRender = true;
      this.imageGot = true;
      this.popupImage =  this._sanitizer.bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/' + this.file.encrypt_id);
    } else {
      console.log("case 2", this.file, this.isImageRender);
      this.isImageRender = true;
      this.imageGot = true;
      this.popupImage =  this._sanitizer.bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/' + this.file.encrypt_id);
    }
      return this.popupImage;

  }
  showdescription(i, desc) {
    if (desc !== '') {
      $('#desc_' + i).show();
      $('#descdiv_' + i).hide();
      $('#descdivclose_' + i).show();
    }
  }

  closedescription(i, desc) {
    if (desc !== null) {
      $('#desc_' + i).hide();
      $('#descdiv_' + i).show();
      $('#descdivclose_' + i).hide();
    }
  }

  hidedescription(i) {
    $('#desc_' + i).hide();
    $('#descdiv_' + i).show();
    $('#descdivclose_' + i).hide();
  }

  modelAction() {
    $('bs-modal-container').addClass('show-midal-element');

  }

  modelNewAction() {
    $('bs-modal-container').addClass('show-midal-element').addClass('show-midal-element-sub');

  }


  modelClose() {
    $('body').removeClass('modal-open');
  }

  ngAfterViewInit() {
    let self = this;
    jQuery('#preview-modal').on('hidden.bs.modal', function (e) {
      self.closePreviewer();
    });
    if (screenfull && typeof screenfull.on !== "undefined") {
      screenfull.on('change', () => {
        this.fullscreen = screenfull.isFullscreen;
      });
    }
    if (navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null) {
      jQuery(window).resize(function (e) {
        let height = $(window).height();
        if (height < 750) {
          height = height - 50;
        } else {
          height = height - 170;
        }
        $('#preview-modal').find('.zoom_active').find('.modal-content')
          .css({ 'height': height + 'px' });
      });
    }
    jQuery('#closePreviewer').on('touchstart', function (e) {
      console.log('closePreviewer');
    });
    // jQuery('#zoomPreviewToggle').on('touchstart', function (e) {
    //   console.log('zoomPreviewToggle');
    // });
    this.width = 630;
    this.height = 560;
    self.create();
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.mouse = { x: event.clientX, y: event.clientY };
    if (this.status === Status.RESIZE) {
      this.resize()
    } else if (this.status === Status.MOVE) {
      this.move();
    }
  }
  private loadContainer() {
    const left = this.boxPosition.left - this.left;
    const top = this.boxPosition.top - this.top;
    const right = left + 630;
    const bottom = top + 560;
    this.containerPos = { left, top, right, bottom };
  }

  setStatus(event: MouseEvent, status: number) {
    //console.log("m setStatus")
    if (status === 1) event.stopPropagation();
    else if (status === 2) this.mouseClick = { x: event.clientX, y: event.clientY, left: this.left, top: this.top };
    else this.loadBox();
    this.status = status;
  }

  private resize() {
    this.loadBox();
    this.width = Number(this.mouse.x > this.boxPosition.left) ? this.mouse.x - this.boxPosition.left : 0;
    this.height = Number(this.mouse.y > this.boxPosition.top) ? this.mouse.y - this.boxPosition.top : 0;
  }

  private move() {
    this.left = this.mouseClick.left + (this.mouse.x - this.mouseClick.x);
    this.top = this.mouseClick.top + (this.mouse.y - this.mouseClick.y);
  }


  private loadBox() {
    //console.log("m hererere")
    const { left, top } = this.previewModal4.nativeElement.getBoundingClientRect();
    this.boxPosition = { left, top };
  }


  log(data) {
    console.log('data to be send > ' , data);
  }

}
