<style>
  div#wordFader {
      position: absolute;
      top: 20%;
      font-size: 18pt;
      text-align: center;
      width: 100%;
      color: #000;
      font-weight: bold;
      font-family: "Brandon Grotesque", serif;
  }
</style>
<img src="assets/squares.gif" class="img spinners" alt="spinner" style="opacity: .8;
  position: absolute;
  top: 10px;
  left:50%;">

<div id="wordFader">Hello! We're setting up your account, just a moment...</div>
