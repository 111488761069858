<div class="portlet-title">
  <button (click)="create()" data-create-btn type="button" class="btn blue">New Category
  </button>
</div>
<div class="portlet-body">
  <div class="table-scrollable table-scrollable-borderless">
    <table class="table table-hover editable">
      <thead>
      <tr class="uppercase">
        <th>Thumbnail</th>
        <th (click)="reorder('name')"  class="filter-by">
          Name <span *ngIf="currentStatus=='name'" class="e2e-inner-html-bound" [style.opacity]="order == 'name' ? '1' : '.3'"
                      [innerHTML]="getSortIcon('name')"></span></th>
        <th>Slug</th>
        <th>Color</th>
        <th>Action</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let category of categories">
        <td>
          <div class="category-view-thumbnail">
            <img [attr.src]=" getSafeThumbnailUrl(category)" alt="{{ category.slug }}"
                 class="img-thumbnail" style="height: 25px;" data-toggle="tooltip" title="{{getSafeUrl(category)}}">
          </div>
        </td>
        <td>
          <span class="category-view-name">{{ category.name }}</span>
        </td>
        <td>
          <span class="category-view-slug">{{ category.slug }}</span>
        </td>
        <td>
          <span class="category-view-color">
              <span class="span-color-name">{{ category.background }}</span>
              <span class="span-color"
                    [style.background-color]="category.background">
              </span>
          </span>
        </td>
        <td>
          <a class="btn blue btn-outline btn-w80" (click)="edit(category)" [attr.disabled]="editing">Edit</a>
          <a class="btn red btn-outline btn-w80"
             (click)="deleteCategory(category)"
          >Delete</a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<app-trialline-edit-categories-edit
  *ngIf="showEdit"
  [editCategory]="editCategory"
  [events]="categoryEvents"
  (onUpdated)="updateCategory($event)"
  (onCreated)="createCategory($event)"
  (onClose)="closeModal($event)"
  (onDelete)="deleteCategory($event)"
></app-trialline-edit-categories-edit>

<span defaultOverlayTarget></span>


