<div id="visualization_{{idVisualisation}}" #visualization
     [style.background-image]="trialline?.background_file && trialline?.background_file.type == 'image' ? 'url(' + apiPath + 'filesystem/' + trialline?.background_file.encrypt_id + ')' : ''"
     style="background-size: cover;
     background-repeat: no-repeat; overflow: hidden;"
>
  <div *ngIf="trialline?.background_file && trialline?.background_file.type == 'video'"
       style="position: fixed; width: 100%;">
    <video
      [attr.src]="apiPath + 'filesystem/' + trialline?.background_file.encrypt_id" width="100%" height="100%" autoplay
      loop muted>
      Your browser does not support the video tag.
    </video>
  </div>
  <div class="center-block monthBlock">
    <div id="nameMonth">
      <p class="trialName">{{ trialline?.name }}</p>
      <p class="month"></p>
    </div>
  </div>
  <div class="pyramid-view-wrapper" id="pyramid-view-wrapper"
       (swipe)="swipeEvent($event)"
       [ngStyle]="{'transform':'translateX('+ triallineOffset +'px)',
       'height': editButton ? 'calc(100vh - 173px)' : 'calc(100vh - 122px)'}"
  >
    <div class="pyramid-card" *ngFor="let event of events2 let i = index"
         id="{{ 'pyramid-card-' + i }}"
         [ngStyle]='{border:"1px solid " + event.background }'>
      <div class="pyramid-card-category"
           [ngStyle]='{background: event.background, fontSize: event.font_size,
            color:getCorrectTextColor(event.background)}'>
        {{event.category_name}}
      </div>
      <div class="pyramid-card-content">
        <div class="pyramid-card-date"
             [ngStyle]='{background: event.background , color:getCorrectTextColor(event.background)}'>
          <div class="pyramid-card-date-year">
            <span *ngIf="event.date_start">{{event.date_start}}</span>
            <span *ngIf="event.date_end"> - {{event.date_end}}</span>
          </div>
          <div class="pyramid-card-date-time">
            <span *ngIf="event.start_date">{{event.start_date}}</span>
            <span *ngIf="event.start_date && event.end_date">-</span>
            <span *ngIf="event.end_date">{{event.end_date}}</span>
          </div>
        </div>
        <article>
          <h4 (click)="scrollToEvent(i)" class="{{'event-flag-'+ event.id}}">{{event.title}}</h4>
          <p (click)="scrollToEvent(i)" class="{{'event-flag-'+ event.id}}" [innerHtml]="event.description"
             [ngStyle]="{'height':event?.thumbnail_visible ? '77px' : '180px'}"></p>
          <div (click)="scrollToEvent(i)"  class="img {{'event-flag-'+ event.id}}"
               *ngIf="event?.thumbnail_visible" [ngStyle]="event.thumbnail"></div>
        </article>
        <div class="pyramid-card-buttons">
          <button type="button" *ngIf="editButton" (click)="eventEdit(event.id)">EDIT</button>
          <button type="button" (click)="eventMore(event.id)">MORE</button>
        </div>
        <div class="pyramid-card-actions">
          <a (click)="event.files_count && eventMore(event.id,'files')">
            <i class="fa fa-paperclip"></i>
            <span class="calc">{{event.files_count}}</span>
          </a>
          <a *ngIf="event.bill_costs_count" (click)="event.bill_costs_count && eventMore(event.id,'bill_cost')">
            <i class="fa fa-usd"></i>
            <span class="calc">{{event.bill_costs_count}}</span>
          </a>
          <a *ngIf="commentsButton" (click)="event.comments_count && eventMore(event.id,'comments')">
            <i class="fa fa-comment-o"></i>
            <span class="calc">{{event.comments_count}}</span>
          </a>
          <a (click)="event.tags_count && eventMore(event.id,'tags')">
            <i class="fa fa-tags"></i>
            <span class="calc">{{event.tags_count}}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="pyramid-slider">
  <div class="pyramid-arrow-left">
    <img   (click)="arrowClickScroll" class ="click-left" data-direction="left" src="/assets/images/left-arrow.svg" >
  </div>
  <div class="pyramid-arrow-right">
    <img (click)="arrowClickScroll" class ="click-right" data-direction="right" src="/assets/images/right-arrow.svg" >
  </div>
</div>
  <div class="pyramid-navigation-bar">
    <div class="pyramid-navigation-bar-content">
      <div class="current-month">
        {{currentMonth}}
      </div>
      <div class="bar-wrapper" *ngFor="let year of timebar.years  let i = index">
        <div class="bar-wrap" (click)="timebarScroll(year +'-'+ monthNames[0])">
          <div class="spacer"></div>
          <div class="bar bar-md bar-wide orange" [attr.data-num]="1 + i * 12"
               id="{{'bar-' + year +'-'+ monthNames[0]}}">
            <div class="year">
              {{year}}
            </div>
          </div>
          <div class="spacer"></div>
        </div>
        <div class="bar-wrap" (click)="timebarScroll(year + '-' + monthNames[1])">
          <div class="spacer"></div>
          <div class="bar bar-sm blue" [ngClass]="{'grey':!timebar.datesAndIds[year + '-' + monthNames[1]]}"
               [attr.data-num]="2 + i * 12" id="{{'bar-' + year + '-' + monthNames[1]}}"></div>
          <div class="spacer"></div>
        </div>
        <div class="bar-wrap" (click)="timebarScroll( year +'-' + monthNames[2])">
          <div class="spacer"></div>
          <div class="bar bar-md blue" [ngClass]="{'grey':!timebar.datesAndIds[year + '-' + monthNames[2]]}"
               [attr.data-num]="3 + i * 12" id="{{'bar-' + year + '-' + monthNames[2]}}"></div>
          <div class="spacer"></div>
        </div>
        <div class="bar-wrap" (click)="timebarScroll( year + '-' + monthNames[3])">
          <div class="spacer"></div>
          <div class="bar bar-sm blue" [ngClass]="{'grey':!timebar.datesAndIds[year + '-' + monthNames[3]]}"
               [attr.data-num]="4 + i * 12" id="{{'bar-' + year + '-' + monthNames[3]}}"></div>
          <div class="spacer"></div>
        </div>
        <div class="bar-wrap" (click)="timebarScroll( year + '-' + monthNames[4])">
          <div class="spacer"></div>
          <div class="bar bar-md blue" [ngClass]="{'grey':!timebar.datesAndIds[year + '-' + monthNames[4]]}"
               [attr.data-num]="5 + i * 12" id="{{'bar-' + year + '-' + monthNames[4]}}"></div>
          <div class="spacer"></div>
        </div>
        <div class="bar-wrap" (click)="timebarScroll( year + '-' + monthNames[5])">
          <div class="spacer"></div>
          <div class="bar bar-sm blue" [ngClass]="{'grey':!timebar.datesAndIds[year + '-' + monthNames[5]]}"
               [attr.data-num]="6 + i * 12" id="{{'bar-' + year + '-' + monthNames[5]}}"></div>
          <div class="spacer"></div>
        </div>
        <div class="bar-wrap" (click)="timebarScroll( year + '-' + monthNames[6])">
          <div class="spacer"></div>
          <div class="bar bar-md blue" [ngClass]="{'grey':!timebar.datesAndIds[year + '-' + monthNames[6]]}"
               [attr.data-num]="7 + i * 12" id="{{'bar-' + year + '-' + monthNames[6]}}"></div>
          <div class="spacer"></div>
        </div>
        <div class="bar-wrap" (click)="timebarScroll( year + '-' + monthNames[7])">
          <div class="spacer"></div>
          <div class="bar bar-sm blue" [ngClass]="{'grey':!timebar.datesAndIds[year + '-' + monthNames[7]]}"
               [attr.data-num]="8 + i * 12" id="{{'bar-' + year + '-' + monthNames[7]}}"></div>
          <div class="spacer"></div>
        </div>
        <div class="bar-wrap" (click)="timebarScroll( year + '-' + monthNames[8])">
          <div class="spacer"></div>
          <div class="bar bar-md blue" [ngClass]="{'grey':!timebar.datesAndIds[year + '-' + monthNames[8]]}"
               [attr.data-num]="9 + i * 12" id="{{'bar-' + year + '-' + monthNames[8]}}"></div>
          <div class="spacer"></div>
        </div>
        <div class="bar-wrap" (click)="timebarScroll( year + '-' + monthNames[9])">
          <div class="spacer"></div>
          <div class="bar bar-sm blue" [ngClass]="{'grey':!timebar.datesAndIds[year + '-' + monthNames[9]]}"
               [attr.data-num]="10 + i * 12" id="{{'bar-' + year + '-' + monthNames[9]}}"></div>
          <div class="spacer"></div>
        </div>
        <div class="bar-wrap" (click)="timebarScroll(year + '-' + monthNames[10])">
          <div class="spacer"></div>
          <div class="bar bar-md blue" [ngClass]="{'grey':!timebar.datesAndIds[year + '-' + monthNames[10]]}"
               [attr.data-num]="11 + i * 12" id="{{'bar-' + year + '-' + monthNames[10]}}"></div>
          <div class="spacer"></div>
        </div>
        <div class="bar-wrap" (click)="timebarScroll( year + '-' + monthNames[11])">
          <div class="spacer"></div>
          <div class="bar bar-sm blue" [ngClass]="{'grey':!timebar.datesAndIds[year + '-' + monthNames[11]]}"
               [attr.data-num]="12 + i * 12" id="{{'bar-' + year + '-'+ monthNames[11]}}"></div>
          <div class="spacer"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="modal_popup">
  <div class="modal pyramid_scale_modal"
       [ngClass]="{'show' : pyramidPopupState}"
       (click)="$event.stopPropagation(); togglePopup(false)"
       data-clicker="true"
       id="long_popup" tabindex="-1" role="dialog" aria-labelledby="popap_formLabel" aria-hidden="true">
    <div class="modal-dialog" style=""
         [ngClass]="{'slideDown' : pyramidPopupState}">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                  data-clicker="true"
                  (click)="$event.stopPropagation(); togglePopup(false)">
            <span aria-hidden="true" data-clicker="true">&times;</span>
          </button>
          <h4 class="modal-title" id="long_popup_header">Event Scale Issues</h4>
        </div>
        <div class="modal-body">
          <p>If some of your events are cut-off, check your browser scale. Windows users can use the "Ctrl -", "Ctrl +",
            and "Ctrl 0" keys to adjust the event size. Mac users can use the "Command -", "Command +", and "Command 0"
            keys to adjust the event size.</p>
        </div>
      </div>
    </div>
  </div>
</section>
