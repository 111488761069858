import {Pipe} from '@angular/core';
import {Event} from "../../main/shared/event.model";
import {SortByDatePipe} from "./sortByDate.pipe";
import {env} from "../../../../.env";

declare var moment: any;

@Pipe({name: 'shorten'})

export class FilterToTemplatePipe {
  public publicPath = env.publicFolderUrl;
  public publicImages = env.publicImages;
  public apiPath = env.apiUrl;
  monthNames: string[] = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "June", "July", "Aug.", "Sept.", "Oct.", "Nov.", "Dec."];
  eventsRef: any = [];
  defaultThumbnail: string = '/images/start-event/trialline-event-thumb.jpg';
  constructor(
    private SortByDatePipe: SortByDatePipe,
  ) {

  }

  transformDateToTime(date: any) {
    if (!date) {
      return '';
    }
    return new Date(date.replace(/-/g, "/")).toLocaleString([], {hour: 'numeric', minute: 'numeric',
      hour12: true})
  }

  transformDate(date: any, showOnlyMonthAndYear?: boolean) {
    if (!date) {
      return '';
    }
    let dt = new Date(date);
    if (this.monthNames[dt.getUTCMonth()] != undefined){
      return showOnlyMonthAndYear
        ? this.monthNames[dt.getUTCMonth()] + ' ' + dt.getUTCFullYear()
        : this.monthNames[dt.getUTCMonth()] + ' ' + dt.getUTCDate() + ', ' + dt.getUTCFullYear();
    } else {
      const arr = date.split(/[- :]/);
       dt = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
      const dateIntArr = dt.toString().substring(0, 15).split(' ')
      return showOnlyMonthAndYear
        ? dateIntArr[1] + ' ' + dateIntArr[3]
        : dateIntArr[1] + ' ' + parseInt(dateIntArr[2]) + ', ' + dateIntArr[3];
    }
  }

  transformTitle(str: string) {
    return !str || str.length < 40
      ? str
      : str.slice(0, 40).concat('...');
  }

  setBackgroundColor(color: string) {
    return color !== '#FFFFFF' ? color : '#000000'
  }

  setCategory(category) {
    return category !== 'No Category' ? category : 'No Category';
  }

  setCategoryFontSize(categoryName: string) {
    return categoryName.length < 35 ? '14px' : '10px';
  }

  setThumbnailImage(path: string, visible: boolean, event: any) {
    let obj = {};
    if( event.thumbnail_encryptid != null && event.thumbnail_encryptid != false ) {
     obj = {'background':'url(\'' + this.publicPath + 'index.php/v1/filesystem/' +
         event.thumbnail_encryptid + '?preview=1&date='+ event.updated_at.replaceAll(' ', '').replaceAll(':', '').replaceAll('-', '') + '\') center center no-repeat', src: this.publicPath + 'index.php/v1/filesystem/' +
         event.thumbnail_encryptid + '?preview=1&date=' + event.updated_at.replaceAll(' ', '').replaceAll(':', '').replaceAll('-', '')}
    } else if (visible) {
      obj = {
        'background': 'url(\'' + this.publicImages +
          (path ? path.substring(1).replace("'", "\\'") : this.defaultThumbnail.substring(1)) + '\') center center no-repeat',
        src: this.publicImages + (path ? path.substring(1).replace("'", "\\'") : this.defaultThumbnail.substring(1))
      }
    }
    return obj;
  }

  setThumbnailImageUrl(path: string, visible: boolean, event: any) {
    let obj = {};
    if( event.thumbnail_encryptid != null && event.thumbnail_encryptid != false ) {
     obj =  this.publicPath + 'index.php/v1/filesystem/' +
         event.thumbnail_encryptid + '?preview=1&date=' + event.updated_at.replaceAll(' ', '').replaceAll(':', '').replaceAll('-', '')
    } else if (visible) {
      obj =  this.publicImages +
          (path ? path.substring(1).replace("'", "\\'") : this.defaultThumbnail.substring(1))
    }
    return obj;

  }


  filterText(itemDescription) {
    let re = /\s*<span\s*/;
    let description = '';
    let self = this;
    if(itemDescription !== null){
      itemDescription = self.refactString(itemDescription);
      itemDescription = itemDescription.replace( '<big>', '').replace( '</big>', '');

      let descriptionArr = itemDescription.split(re);
      if (descriptionArr.length > 1) {
        descriptionArr.forEach((element, index) => {
          element = self.refactString(element);
          let fontSize = element.indexOf('font-size:');
          if(fontSize != -1){
            fontSize = fontSize + 10;
            let textSize = element.substr(fontSize, 4);
            element =  element.replace('font-size:'+ textSize , 'font-size:12px')
           }
          if(index != 0){
            description  += '<span ' + element
          } else {
            description  +=  element
          }
        });
      } else{
        description = itemDescription;
      }
    }
    return description;
  }

  parseTime(time: any = null) {
    const arr = time.split(/[- :]/);
    const dt = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    return dt.toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})
  }

  refactString(str) {
    return str.replace( '<h1', '<h6').replace( '/h1>', '/h6>').replace( '<h2', '<h6').replace( '/h2>', '/h6>')
      .replace( '<h3', '<h6').replace( '/h3>', '/h6>').replace( '<h4', '<h6').replace( '/h4>', '/h6>')
      .replace( '<h5', '<h6').replace( '/h5>', '/h6>').replace( '<div', '<p').replace( '/div>', '/p>');
  }

  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}

  transformEvents(events: Event[]) {
    if (events && events.length != 0) {
      events = this.SortByDatePipe.transform(events);
      this.eventsRef = events.map((event: any) => {
        const real_start_date = moment(event.start_date).format('MM/DD/YYYY');
        const real_end_date = (event.end_date) ? moment(event.end_date).format('MM/DD/YYYY') : '';
        const ifStartDays = (event.if_start_days) ? true: false;
        const ifEndDays = (event.if_end_days) ? true: false;

        const start_date = this.transformDateToTime(event.start_date);
        const end_date = this.transformDateToTime(event.end_date);
        let item:object = {};
        let timeStart = '';
        let timeEnd = '';
        
        //console.log('our event', event);

        item['new_category_id'] = event.category_id;

        item['id'] = event.id;
        item['real_start_date'] = real_start_date;
        item['real_end_date'] = real_end_date;
        item['ifStartDays'] = ifStartDays;
        item['ifEndDays'] = ifEndDays;
        item['id'] = event.id;
        item['bill_cost_count'] = event.bill_costs_count;
        item['background'] = event.category != null ? this.setBackgroundColor(event.category.background): '#000000';
        item['font_size'] = this.setCategoryFontSize(event.category_name);
        item['category_name'] = this.setCategory(event.category_name);
        item['date_start'] = this.transformDate(event.date_start, event.if_start_days);
        item['date_end']= this.transformDate(event.date_end, event.if_end_days);
        item['start_date'] = event.start_date
        item['start_date_show'] = start_date !== '12:00 AM' ? start_date : '';
        item['end_date_show'] = (event.end_date && this.parseTime(event.end_date) !== '12:00 AM') ?  ' - '
          + this.parseTime(event.end_date) : ''
        item['end_date'] = end_date !== '12:00 AM' ? end_date : null;
        item['transformTitle'] = this.transformTitle(event.title);
        item['title'] = event.title ? event.title : '';
        item['description'] = event.description? this.filterText(event.description) : ' ';
        item['backsideimage'] = event.backsideimage;
        item['thumbnail'] = this.setThumbnailImage(event.thumbnail_attr, event.thumbnail_visible, event);
        item['thumbnail_visible'] = event.thumbnail_visible;
        item['thumbnail_url'] = this.setThumbnailImageUrl(event.thumbnail_attr, event.thumbnail_visible, event);
        item['thumbnail_encryptid'] = event.thumbnail_encryptid;
        
        item['files_count'] = event.files_count;
        item['comments_count'] = event.comments_count;
        item['tags_count'] = event.tags_count;
        item['formatStartDate'] = event.start_date
        if (event.if_start_days) {
          item['formatStartDateNew'] = moment(event.start_date).format('MMM YYYY');
          item['formatEndDateNew'] = moment(event.end_date).format('MMM YYYY');
          item['formatStartDateNew2'] = moment(event.start_date).format('MM/YYYY');
          item['formatEndDateNew2'] = moment(event.end_date).format('MM/YYYY');
        } else {
          item['formatStartDateNew'] = moment(event.start_date).format('MMM DD, YYYY');
          item['formatEndDateNew'] = moment(event.end_date).format('MMM DD, YYYY');
          item['formatStartDateNew2'] = moment(event.start_date).format('MM/DD/YYYY');
          item['formatEndDateNew2'] = moment(event.end_date).format('MM/DD/YYYY');
        }
        if (event.if_end_days) {
          item['formatEndDateNew'] = moment(event.end_date).format('MMM YYYY');
          item['formatEndDateNew2'] = moment(event.end_date).format('MM/YYYY');
        } else {
          item['formatEndDateNew'] = moment(event.end_date).format('MMM DD, YYYY');
          item['formatEndDateNew2'] = moment(event.end_date).format('MM/DD/YYYY');
        }
        if (event.has_times == true) {
          timeStart = (new Date(event.start_date).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/,
            "$1$3"));
        }
        if (event.has_times_end == true) {
          timeEnd = (new Date(event.end_date).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/,
            "$1$3"));
        }
        item['show_times_start'] =  item['formatStartDateNew'];
        item['show_times_end'] = event.end_date ? ' - ' + item['formatEndDateNew'] : '';
        item['show_times_start2'] =  item['formatStartDateNew2'];
        item['show_times_end2'] = event.end_date ? ' - ' + item['formatEndDateNew2'] : '';
        item['times_start'] = event.has_times ? timeStart : '';
        item['times_end'] = event.has_times_end ? timeEnd : '';
        item['monthYear'] = moment(event.start_date).format('MMMM YYYY');
        item['date_start_show'] = this.transformDate(event.start_date, !!event.if_start_days);
        item['date_end_show'] = this.transformDate(event.end_date, !!event.if_start_days);
        item['custom_columns'] = event.custom_field ? JSON.parse(event.custom_field) : null
        //console.log( item['custom_columns'])
        return item;
      });
    } else {
      this.eventsRef = [];
    }
    return this.eventsRef;
  }

  transformEventsTrack(events: Event[]) {
    if (events && events.length != 0) {
      events = this.SortByDatePipe.transform(events);
      this.eventsRef = events.map((event: any) => {
        const start_date = this.transformDateToTime(event.start_date);
        const end_date = this.transformDateToTime(event.end_date);
        let item:object = {};
        let timeStart = '';
        let timeEnd = '';
        item['id_second'] = event.id;
        item['id'] = event.id_second;
        item['content'] = event.id_second;
        item['bill_cost_count'] = event.bill_costs_count;
        item['background'] = event.category != null ? this.setBackgroundColor(event.category.background): '#000000';
        item['font_size'] = this.setCategoryFontSize(event.category_name);
        item['category_name'] = this.setCategory(event.category_name);
        item['date_start'] = this.transformDate(event.date_start, event.if_start_days);
        item['date_end']= this.transformDate(event.date_end, event.if_end_days);
        item['start_date'] = event.start_date;
        item['start_date_show'] = start_date !== '12:00 AM' ? start_date : '';
        item['end_date_show'] = (event.end_date && this.parseTime(event.end_date) !== '12:00 AM') ?  ' - '
          + this.parseTime(event.end_date) : ''
        item['end_date'] = end_date !== '12:00 AM' ? end_date : null;
        if (event.end_date) {
          item['start'] = new Date(event.date_start);
          item['end'] = new Date(event.date_end);
        }
        item['transformTitle'] = this.transformTitle(event.title);
        item['title'] = event.title ? event.title : '';
        item['description'] = event.description? this.filterText(event.description) : ' ';
        item['thumbnail'] = this.setThumbnailImage(event.thumbnail_attr, event.thumbnail_visible, event);
        item['thumbnail_visible'] = event.thumbnail_visible;
        item['thumbnail_url'] = this.setThumbnailImageUrl(event.thumbnail_attr, event.thumbnail_visible, event);
        item['thumbnail_encryptid'] = event.thumbnail_encryptid;
        
        item['files_count'] = event.files_count;
        item['group'] = event.track;
        item['comments_count'] = event.comments_count;
        item['tags_count'] = event.tags_count;
        item['formatStartDate'] = event.start_date
        if (event.if_start_days) {
          item['formatStartDateNew'] = moment(event.start_date).format('MMM YYYY');
          item['formatEndDateNew'] = moment(event.end_date).format('MMM YYYY');
          item['formatStartDateNew2'] = moment(event.start_date).format('MM/YYYY');
          item['formatEndDateNew2'] = moment(event.end_date).format('MM/YYYY');
        } else {
          item['formatStartDateNew'] = moment(event.start_date).format('MMM DD, YYYY');
          item['formatEndDateNew'] = moment(event.end_date).format('MMM DD, YYYY');
          item['formatStartDateNew2'] = moment(event.start_date).format('MM/DD/YYYY');
          item['formatEndDateNew2'] = moment(event.end_date).format('MM/DD/YYYY');
        }
        if (event.if_end_days) {
          item['formatEndDateNew'] = moment(event.end_date).format('MMM YYYY');
          item['formatEndDateNew2'] = moment(event.end_date).format('MM/YYYY');
        } else {
          item['formatEndDateNew'] = moment(event.end_date).format('MMM DD, YYYY');
          item['formatEndDateNew2'] = moment(event.end_date).format('MM/DD/YYYY');
        }
        if (event.has_times == true) {
          timeStart = (new Date(event.start_date).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/,
            "$1$3"));
        }
        if (event.has_times_end == true) {
          timeEnd = (new Date(event.end_date).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/,
            "$1$3"));
        }
        item['show_times_start'] =  item['formatStartDateNew'];
        item['show_times_end'] = event.end_date ? ' - ' + item['formatEndDateNew'] : '';
        item['show_times_start2'] =  item['formatStartDateNew2'];
        item['show_times_end2'] = event.end_date ? item['formatEndDateNew2'] : '';
        item['times_start'] = event.has_times ? timeStart : '';
        item['times_end'] = event.has_times_end ?  timeEnd : '';
        item['monthYear'] = moment(event.start_date).format('MMMM YYYY');
        item['date_start_show'] = this.transformDate(event.start_date, !!event.if_start_days);
        item['date_end_show'] = this.transformDate(event.end_date, !!event.if_start_days);
        item['custom_columns'] = event.custom_field ? JSON.parse(event.custom_field) : null
        return item;
      });
    } else {
      this.eventsRef = [];
    }
    return this.eventsRef;
  }
}
