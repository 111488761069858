import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {NgForm} from "@angular/forms";
import {ToastrService} from 'ngx-toastr';
import {Category} from "../../../../shared/category.model";
import {TriallineResourceService} from "app/main/trialline/shared/trialline-resoruce.service";
import {FileUploader} from "ng2-file-upload";
import {AuthService} from "../../../../../auth/shared/auth.service";
import {env} from "../../../../../../../.env";
import {ActivatedRoute, Router} from "@angular/router";
import Cropper from "cropperjs";

declare var jQuery: any;

@Component({
  selector: 'app-trialline-edit-categories-edit',
  templateUrl: './edit.component.html'
})
export class TriallineEditCategoriesEditComponent implements AfterViewChecked, OnInit, OnDestroy {
  @Input() editCategory: Category;
  @Input() events: any;
  @Output() onClose = new EventEmitter<Category>();
  @Output() onUpdated = new EventEmitter<Category>();
  @Output() onCreated = new EventEmitter<Category>();
  @Output() onDelete = new EventEmitter<Category>();

  category: Category;
  triallineId: number;
  thumbnail_visible: boolean;
  publicFolderUrl = env.publicFolderUrl;
  publicImages = env.publicImages;
  apiPath = env.apiUrl;
  uploading: boolean = false;
  private modalId = '#category-edit-modal';
  defaultThumbnail: string = '/images/start-event/trialline-event-thumb.jpg';
  errors: any = {};
  editing: number;
  sub: any;
  visible: any;
  category_thumbnail: any;
  uploader: FileUploader;
  background: string = '#' + (Math.random() * 0xFFFFFF << 0).toString(16);
  crop_image: HTMLImageElement;
  cropper: any;
  cropProcessing: boolean = false;
  blobImg: any = false;
  isCropped: boolean = false;
  cropped_image_src: string;

  constructor(
    protected toasts: ToastrService,
    private triallineResoureService: TriallineResourceService,
    private authService: AuthService,
    protected route: ActivatedRoute, public router: Router,
    private _changeDetectionRef: ChangeDetectorRef
  ) {
    this.sub = this.route.parent.params.subscribe(params => {
      this.triallineId = +params["trialline_id"];
    });
    this.uploader = new FileUploader({
      url: env.apiUrl + 'file/thumbnail?attach_to=trialline&attach_to_id=' + this.triallineId,
      authToken: 'Bearer ' + authService.token(),
      autoUpload: true
    });
    this.uploader.onBeforeUploadItem = () => {
      this.uploading = true;
    }

    this.uploader.onErrorItem = () => {
      this.uploading = false;
    }

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let body = JSON.parse(response).body;
      if (body.path) {
        this.category.thumbnail = body.path;
      }
      if (body.encrypt_id) {
        this.category.thumbnail_encryptid = body.encrypt_id;
      }
      this.category_thumbnail = this.setThumbnailImageUrl(this.category.thumbnail, this.visible, this.category);
      this.uploading = false;
      _changeDetectionRef.detectChanges();
      this.showCropImage();
    };
  }

  ngOnInit() {
    jQuery('#crop_block').hide();
    this.sub = this.route.parent.params.subscribe(params => {
      this.triallineId = +params["trialline_id"];
    });

    this.category = {...this.editCategory};
    if (!this.category.updated_at) {
      this.category.updated_at = 'null'
    }
    if (this.category.id) {

      this.background = this.category.background;
    }
    this.visible = this.getVisibleEventThumbnails();
    this.category_thumbnail = this.setThumbnailImageUrl(this.category.thumbnail, this.visible, this.category);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this._changeDetectionRef.detach();
  }

  showCropImage() {
    this.cropped_image_src = this.category_thumbnail;
    var self = this;
    this.cropProcessing = true;
    this.crop_image = jQuery('#crop_img')[0] as HTMLImageElement;
    this.crop_image.src = this.cropped_image_src ;
    this._changeDetectionRef.detectChanges();
    this.crop_image.addEventListener('load', function(event) {
      var cropper = new Cropper(self.crop_image, {
        aspectRatio: 1 / 1,
        highlight: false,
        zoomable: false,
        minContainerWidth: 250,
        minContainerHeight: 250,
        responsive: false,
        crop(event) {
          self.cropper = cropper;
        }
      });
    });
    this.crop_image.addEventListener( 'ready', function (event) {
      self.cropProcessing = false;
      self.isCropped = true;
      self._changeDetectionRef.detectChanges();
      jQuery('#crop_block').show();
    })
  }

  cropApply() {
    this.cropProcessing = true;
    this.isCropped = false;
    this.cropper.getCroppedCanvas({
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
    }).toBlob((blob) => {
      console.log("cate",this.category.thumbnail)
      const formData = new FormData();
      jQuery('#crop_block').hide();
      let filename = this.category.thumbnail.substring(this.category.thumbnail.lastIndexOf('/')+1);
      filename = filename.replace(/\.[^/.]+$/, "")
      let finalFilname = filename+".jpeg"
      console.log("filename",finalFilname)
      formData.append('file', blob,finalFilname);
      this.uploadThumbnail(formData);
    },'image/jpeg',1);
  }

  cropCancel() {
    jQuery('#crop_block').hide();
    this.isCropped = false;
    this.cropper.destroy();
  }

  uploadThumbnail(formData) {
    var self = this;
    jQuery.ajax(env.apiUrl + 'file/thumbnail', {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + this.authService.token()},
      data: formData,
      processData: false,
      contentType: false,
      success(response) {
        self.cropProcessing = false;
        if (response.body.path) {
          self.category.thumbnail = response.body.path;
        }
        if (response.body.encrypt_id) {
          self.category.thumbnail_encryptid = response.body.encrypt_id;
        }
        if (response.body.message) {
          self.toasts.success(response.body.message);
        }
        self.category_thumbnail = self.setThumbnailImageUrl(self.category.thumbnail, self.visible, self.category);
        self._changeDetectionRef.detectChanges();
        self.cropper.destroy();
        jQuery('#crop_block').hide();
      },
      error() {
        self.cropProcessing = false;
        self.toasts.error('Something went wrong', 'Sorry');
      },
    });
  }

  ngAfterViewChecked() {
    let self = this;
    jQuery(this.modalId).on('hidden.bs.modal', function (e) {
      self.onClose.emit(self.category);
    })
  }

  getVisibleEventThumbnails(){
    let eventThumbnailsVisibility = this.events.flatMap(event => event.category_id === this.category.id ? [event.thumbnail_visible] : []);
    if(eventThumbnailsVisibility.every((currentValue) => currentValue === true)){
      this.thumbnail_visible = true;
    }else if(eventThumbnailsVisibility.every((currentValue) => currentValue === false)){
      this.thumbnail_visible = false;
    }else{
      this.thumbnail_visible = false;
    }
  }

  saveCategory(form: NgForm) {
    let params = form.value;
    params.thumbnail = this.category.thumbnail;
    params.thumbnail_visible = this.thumbnail_visible;
    params.thumbnail_encryptid = this.category.thumbnail_encryptid;
    if (this.category.id) {
      this.triallineResoureService.updateCategory(this.triallineId, this.category.id, params).toPromise()
        .then((category: any) => {
          this.onUpdated.emit(category);
          this.toasts.success('Successfully updated');
          jQuery(this.modalId).modal('hide');
        })
        .catch((error) => {
          if (error.status == 422) {
            let errorData: any = error.error;
            this.errors = errorData.data;
          } else {
            this.toasts.error(error);
          }
        });
    } else {
      this.triallineResoureService.createCategory(this.triallineId, params).toPromise()
        .then((category: any) => {
          this.onCreated.emit(category);
          this.toasts.success('Successfully created');
          jQuery(this.modalId).modal('hide');
        })
        .catch((error) => {
          if (error.status == 422) {
            let errorData: any = error.error;
            this.errors = errorData.data;
          } else {
            this.toasts.error(error);
          }
        });
    }
    this.category_thumbnail = this.setThumbnailImageUrl(this.category.thumbnail, this.visible, this.category);
  }

  deleteCategory() {
    this.onDelete.emit(this.category);
    jQuery(this.modalId).modal('hide');
  }

  slugRegenerate(name: string) {
    return this.category.slug = name.replace(/\s+/g, '').substring(0, 4).toUpperCase();
  }

  showDeleteIcon(path) {
    if (this.category) {
      return decodeURI(path).indexOf(this.defaultThumbnail) === -1;
    }
  }

  deleteThumbnail() {
    this.category.thumbnail = this.defaultThumbnail;
    this._changeDetectionRef.detectChanges();
  }


  setThumbnailImageUrl(path: string, visible: boolean, category: any) {
    let obj = {};
    let addDate = category ?
      category.updated_at.replaceAll(' ', '').replaceAll(':', '').replaceAll('-', '') : 'null'
    if( category.thumbnail_encryptid != null && category.thumbnail_encryptid != false ) {
      obj =  this.publicFolderUrl + 'index.php/v1/filesystem/' +
        category.thumbnail_encryptid + '?preview=1&date=' + addDate
    } else {
      obj =  this.publicImages +
        (path ? path.substring(1).replace("'", "\\'") : this.defaultThumbnail.substring(1))
    }
    return obj;
  }
}
