import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {View} from "../../main/shared/view.model";
import {PresentResourceService} from "./present-resoruce.service";

@Injectable()
export class PresentResolver implements Resolve<View>
{
  constructor(protected presentResource: PresentResourceService) {}

  resolve(uuid, pass): Promise<View>
  {
      return this.presentResource.getView(uuid, pass)
        .toPromise()
        .then((view: View) => {return view;});
  }
}
