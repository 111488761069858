<div id="file-edit-modal" class="modal fade bs-modal-lg in" tabindex="-1" role="dialog"
     style=" overflow: auto; height: 100%">
  <div class="modal-dialog modal-md modal-preview">
    <div class="modal-content">
      <div class="modal-header ">
        <button (click)="closeEditModal()" type="button" class="close"><span
          aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">Edit file: <span class="file-title">{{ getFileName(file?.title) }}</span></h4>
      </div>
      <div class="modal-body">
        <!-- BEGIN PORTLET -->
        <div class="portlet light ">
          <div class="portlet-body">
            <!--BEGIN File Details-->
            <form id="file-view-inf-edit">
              <!-- <div class="form-body" *ngIf="! file">
                <p>Select a file to edit.</p>
              </div> -->
              <div class="form-body" *ngIf="file">
                <div class="form-group" style="margin-top: -20px;">
                  <div class="upload-box-details">
                    <!-- <p class="help-block">
                      <span class="label label-sm label-danger">Note:&nbsp;</span>
                      File will be overwritten without saving changes
                    </p> -->
                    <!-- <div class="progress" style="height: 3px; width: 200px; margin: 0">
                      <div class="progress-bar" role="progressbar" [style.width]="uploader.progress + '%'"></div>
                    </div> -->
                    <label for="file-input">
                      <img *ngIf="file.type == 'pdf'" src="{{ imageIcons(file.type) }}" style="width: inherit;" class="upload-box-details-file-img pull-left"
                      alt="{{ getFileName(file.title) }}" data-title="{{ getFileName(file.title) }}" (click)="selectFile(file)">

                      <div *ngIf="file.type != 'pdf'" data-preview data-preview_path data-type-file="image"
                           class="col-md-6 image-background upload-box-details-file-img pull-left"
                           [style.background-image]="'url(' + apiPath + 'filesystem/' + file.encrypt_id + '?preview=1)'"
                           data-title="{{ getFileName(file.title) }}" (click)="selectFile(file)">
                      </div>
                      <!-- <input id="file-input" type="file" ng2FileSelect [uploader]="uploader" style="display: none;"/> -->
                    </label>
                  </div>
                  <div class="col-md-6 overflow-h word-break">
                    <div data-name class="">{{ getFileName(file.title) }}</div>
                    <div data-date class="text-muted">{{ file.created_at | date }}</div>
                    <!--<div data-size class="text-muted">1560x1215</div>-->
                    <p>
                      <!-- <a class="btn blue download-file" href="{{ apiPath + 'filesystem/' + file.encrypt_id + '/download' }}">
                        Download file
                      </a> -->
                      <a class="btn blue download-file" href="{{ apiPath + 'filesystem/' + file.encrypt_id + '/download' }}">
                        Download file
                      </a>
                    </p>
                  </div>
                </div>
                <div class="clearfix"></div>
                <form #fileForm="ngForm" (submit)="save(fileForm)">
                  <div class="form-group">
                    <label>Title</label>
                    <input type="text" name="title" [ngModel]="getFileName(file.title)" class="form-control">
                  </div>
                  <div class="form-group">
                    <label>Description</label>
                    <textarea [ngModel]="file.description" name="description" class="form-control autosizeme" rows="3"
                              placeholder="Description...">{{ file.description }}</textarea>
                  </div>
                  <div id="contact-filter-form" class="form-inline no-validate" *ngIf="contactList && contactList.length">
                    <div class="">
                      <div class="form-group">
                        <h5>Select Contact</h5>
                        <div class="ng-autocomplete">
                          <ng-autocomplete
                            [data]="contactList"
                            [searchKeyword]="keyword"
                            placeholder="Enter the Contact Name"
                            (selected)='selectContact($event)'
                            [itemTemplate]="itemTemplate"
                            [notFoundTemplate]="notFoundTemplate">
                          </ng-autocomplete>
                          <ng-template #itemTemplate let-item>
                            <a [innerHTML]="item.name"></a>
                          </ng-template>
                          <ng-template #notFoundTemplate let-notFound>
                            <div [innerHTML]="notFound"></div>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                    <div class="sub-header">
                      <div class="form-group">
                        <h5>Action</h5>
                        <div class="form-group">
                          <label class="col-sm-4 control-label">Connect To Event</label>
                          <div class="col-sm-8">
                            <div class="portlet-title">
                              <button (click)="connectToEvent()" data-create-btn type="button" class="btn blue">
                                Connect To Event
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="portlet-body">
                      <div class="table-scrollable table-scrollable-borderless">
                        <table class="table table-hover editable">
                          <thead>
                            <tr class="uppercase">
                              <th></th>
                              <th>Name</th>
                              <th>Category</th>
                              <th>Color</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                          <tr *ngFor="let contact of contacts">
                            <td>
                              <div class="category-view-thumbnail">
                                <img [attr.src]=" getSafeThumbnailUrl(contact)" alt="{{ contact.slug }}"
                                     class="img-thumbnail" style="height: 25px;" data-toggle="tooltip"
                                     title="{{getSafeUrl(contact)}}">
                              </div>
                            </td>
                            <td>
                              <span class="category-view-name">{{ contact.name }}</span>
                            </td>
                            <td>
                              <span class="category-view-slug" *ngIf="contact">
                               <span *ngFor="let category of contact.categories; last as isLast">
                                 {{ category.name }}{{!isLast ? ', ' : ''}}
                               </span>
                              </span>
                            </td>
                            <td>
                              <span class="category-view-color">
                                  <span class="span-color-name">{{ contact.background }}</span>
                                  <span class="span-color"
                                        [style.background-color]="contact.background">
                                  </span>
                              </span>
                            </td>
                            <td>
                              <!--            <a class="btn blue btn-outline btn-w80" (click)="edit(contact)">Edit</a>-->
                              <a class="btn red btn-outline btn-w80" (click)="deleteContact(contact)">Delete</a>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="form-actions">
                    <button type="submit" class="btn blue">Save Change</button>
                  </div>
                </form>
              </div>
            </form>
            <!--END file details-->
          </div>
        </div>
        <!-- END PORTLET -->
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>
