<div class="content">
  <form #registerForm="ngForm" (submit)="register(registerForm)" class="register-form error" novalidate
        style="display: block;">
    <h3 style="font-family: 'Open Sans', sans-serif !important;">Registration</h3>
    <p style="font-family: 'Open Sans', sans-serif !important; font-size: 14px;"> Enter your personal details
      below: </p>
    <div [hubValidator]="errors?.first_name" class="form-group" transformer="inline">

            <div class="input-icon">
              <i class="fa fa-user"></i>
               <input [ngModel] class="form-control" name="first_name" placeholder="First name" type="text" required/>
            </div>
            <span style="float: right;color: red;margin-right: -9px;margin-top: -36px;">*</span>
     </div>
    <div [hubValidator]="errors?.last_name" class="form-group" transformer="inline">
      <div class="input-icon">
        <i class="fa fa-user"></i>
        <input [ngModel] class="form-control" name="last_name" placeholder="Last name" type="text" required>
      </div>
      <span style="float: right;color: red;margin-right: -9px;margin-top: -36px;">*</span>

    </div>
    <!-- [hubValidator]="errors?.email" -->
    <div  class="form-group" transformer="inline">
      <div class="input-icon">
        <i class="fa fa-envelope"></i>
        <input [ngModel] class="form-control" name="email" placeholder="Email address" type="email" required></div>
    </div>
    <span style="float: right;color: red;margin-right: -9px;margin-top: -36px;">*</span>
    <!-- [hubValidator]="errors?.password" -->
    <div  class="form-group" transformer="inline">
      <div class="input-icon">
        <i class="fa fa-lock"></i>
        <input [ngModel] class="form-control" id="register_password" name="password" placeholder="Create password"
               type="password" required autocomplete="off"></div>
    </div>
      <span style="float: right;color: red;margin-right: -9px;margin-top: -36px;">*</span>
    <div class="form-group">
      <div class="controls">
        <div class="input-icon">
          <i class="fa fa-check"></i>
          <input [ngModel] class="form-control" name="password_confirmation" placeholder="Confirm password"
                 type="password" required autocomplete="off"></div>
      </div>
      <span style="float: right;color: red;margin-right: -9px;margin-top: -36px;">*</span>
    </div>
    <div [hubValidator]="errors?.phone" class="form-group" transformer="inline">
      <div class="input-icon">
        <i class="fa fa-phone"></i>
        <input [ngModel] class="form-control" name="phone" placeholder="Phone number" required></div>
    </div>
    <span style="float: right;color: red;margin-right: -9px;margin-top: -36px;">*</span>
    <div [hubValidator]="errors?.firm" class="form-group" transformer="inline">
      <div class="input-icon">
        <i class="fa fa-building-o"></i>
        <input [ngModel] class="form-control" name="firm" placeholder="Company name" type="text" required>
      </div>
       <span style="float: right;color: red;margin-right: -9px;margin-top: -36px;">*</span>
    </div>

    <div class="form-group">
      <label class="sr-only">How did you find out about TrialLine?</label>
      <span class="how_found">
          <select #how_found_v (change)="how_found_i.value = prepareValue($event.target.value)" class="form-control">
            <option
              *ngFor="let element of how_founds"
              [disabled]="element === 'How did you find out about TrialLine?'"
              value="{{element}}"
            >{{element}}
            </option>
          </select>
        </span>
         <span style="float: right;color: red;margin-right: -9px;margin-top: -36px;">*</span>
    </div>
    <div [ngClass]="{'hidden' : how_found_v.value !== 'Other (Please explain)'}" class="form-group">
      <div class="input-icon">
        <i class="fa fa-check"></i>
        <input #how_found_i
               [(ngModel)]=how_found_i.value
               class="form-control"
               name="how_found"
               placeholder="Please explain"
               type="text" required>
      </div>
    </div>
    <div class="form-group">
      <label class="sr-only">Choose Your Practice Area</label>
      <span class="practice_area">
          <select #practice_area_v
                  (change)="practice_area_i.value = prepareValue($event.target.value)"
                  class="form-control">
              <option
                *ngFor="let element of practice_areas"
                [disabled]="element === 'Choose Your Practice Area'"
                value="{{element}}"
              >{{element}}</option>
          </select>
      </span>
     <span style="float: right;color: red;margin-right: -9px;margin-top: -36px;">*</span>
    </div>
    <div [ngClass]="{'hidden' : practice_area_v.value !== 'Other (Please explain)'}" class="form-group">
      <div class="input-icon">
        <i class="fa fa-check"></i>
        <input #practice_area_i [(ngModel)]=practice_area_i.value
               class="form-control"
               name="practice_area"
               placeholder="Please explain"
               type="text" required>
      </div>
    </div>

    <re-captcha (resolved)="resolved($event)" name="captcha" required siteKey="6LeQQBkUAAAAAGqShWJlo0s7lFmx9LFSY4Juw2Qv"></re-captcha><span style="float: right;color: red;margin-right: -9px;margin-top: -36px;">*</span>

    <label id="recaptcha-error" style="display: none; color: red">Please complete the security reCAPTCHA.</label>
    <div [hubValidator]="errors?.rules" class="form-group" transformer="inline">
      <label class="mt-checkbox mt-checkbox-outline">
        <input [ngModel] name="rules" type="checkbox" required> I agree to the
        <a href="/privacy-policy" target="_blank">Privacy Policy </a>
        <span></span>
      </label>
       <span style="float: right;color: red;margin-right: -9px;margin-top: -36px;">*</span>
    </div>
    <label><span style="color: red"> * indicates mandatory fields</span></label>
    <div class="form-actions">
      <a [routerLink]="['/login']">
        <button class="btn default" type="button"> Back to sign in</button>
      </a>
      <!--! captcha ||-->
       <button [disabled]="! captcha || ! registerForm.valid ||
                     processing || registerForm.controls.how_found.value === 'How did you find out about TrialLine?' ||
                      registerForm.controls.practice_area.value === 'Choose Your Practice Area'"
              class="btn blue pull-right" id="register-submit-btn" type="submit">
        Register
        <img *ngIf="processing" alt="spinner" src="assets/squares.gif" width="20px">
      </button>
      <!-- <button class="btn blue pull-right" id="register-submit-btn" type="submit">
        Register
        <img *ngIf="processing" alt="spinner" src="assets/squares.gif" width="20px">
      </button> -->
    </div>
    <div class="clearfix"></div>
  </form>
</div>

<div id="congratulations-modal" class="modal fade bs-modal-lg in" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header ">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
        <h3 class="modal-title">Congratulations!</h3>
      </div>
      <div class="modal-body">
        <h4>Congratulations <span id="username">{{ user?.first_name + ' ' + user?.last_name }}</span>! Your TrialLine
          account is almost ready!</h4>
        <p>
          Before you can start using TrialLine, <b>you must activate your account</b>.
          Check your inbox at <b><span class="useremail">{{ user?.email }}</span></b> and click the activation link
          provided.
          If you do not activate your site within two days, this account will
          be deleted and you will have to sign up again.
        </p>
        <h4>Still waiting for your activation email?</h4>
        <p>
          If you haven’t received your email yet, there are a number of things you can do:
        </p>
        <ul>
          <li>Wait a little longer. It sounds silly, but sometimes delivery of email can be delayed by
            processes outside of our control. So give it a few more minutes.
          </li>
          <li>Check your junk or spam folder - including any on your server. Sometimes emails from new
            accounts wind up there by mistake.
          </li>
          <li>
            Double check you've entered your email address correctly?
            We have sent your activation email to <span class="useremail"></span>
            Does that look right? If not, you may need to start over.
            You may also click here to request that our system resend the activation email.
            If that doesn't work, you may <a href="#contact" class="contact-us-button">click here</a> to contact
            customer support.
          </li>
        </ul>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>

<!--<div id="contact-modal" class="modal fade bs-modal-lg in" tabindex="-1" role="dialog">-->
<!--<div class="modal-dialog modal-md">-->
<!--<div class="modal-content">-->
<!--<div class="modal-header ">-->
<!--<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span-->
<!--aria-hidden="true">&times;</span></button>-->
<!--<h3 class="modal-title">Contact us</h3>-->
<!--</div>-->
<!--<div class="modal-body">-->
<!--<p>-->
<!--We would be glad to hear from you. Drop us a line, whether it is a comment, a question, a request for quotation or just a hello. You can use either the form below or the contact details below.-->
<!--</p>-->
<!--</div>-->
<!--</div>&lt;!&ndash; /.modal-content &ndash;&gt;-->
<!--</div>&lt;!&ndash; /.modal-dialog &ndash;&gt;-->
<!--</div>-->
