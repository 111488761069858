<div class="content">
  <form #passwordForm="ngForm" class="login-form" (submit)="confirm(passwordForm)" novalidate>
    <h3 class="form-title" style="font-family: 'Open Sans', sans-serif !important;">Create your new password</h3>
    <div class="alert alert-danger display-hide">
      <button class="close" data-close="alert"></button>
    </div>
    <div class="form-group">
      <label class="control-label visible-ie8 visible-ie9">Password</label>
      <div class="input-icon">
        <i class="fa fa-lock"></i>
        <input class="form-control" type="password" placeholder="Password" name="password" autocomplete="off" [(ngModel)]="password" #passwordInput="ngModel" required (input)="checkPasswordsMatch()">
      </div>
    </div>
    <div class="form-group">
      <label class="control-label visible-ie8 visible-ie9">Password confirmation</label>
      <div class="input-icon">
        <i class="fa fa-lock"></i>
        <input class="form-control" type="password" placeholder="Password confirmation" name="password_confirmation" autocomplete="off" [(ngModel)]="passwordConfirmation" #passwordConfirmationInput="ngModel" required (input)="checkPasswordsMatch()">
      </div>
    </div>
    <div class="form-actions">
      <button type="submit" class="btn blue pull-right" [disabled]="passwordForm.invalid || isConfirmButtonDisabled">Confirm</button>
      <div class="clearfix"></div>
    </div>
    <!--<div class="login-options">-->
    <!--<h4>Or login with</h4>-->
    <!--<ul class="social-icons">-->
    <!--<li>-->
    <!--<a class="facebook" data-original-title="facebook" href="/auth/facebook"> </a>-->
    <!--</li>-->
    <!--</ul>-->
    <!--</div>-->
    <div class="create-account">
      <p style="font-family: 'Open Sans', sans-serif !important; font-size: 14px;"> Don't have an account yet ?&nbsp;
        <a [routerLink]="['/register']" id="register-btn"> Create an account </a>
      </p>
    </div>
  </form>
</div>
