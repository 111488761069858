import {NgModule} from '@angular/core';
import {HttpClientModule} from "@angular/common/http";
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {ValidatorModule} from "../../form-validator/validator.module";
import {ManageComponent} from "./manage/manage.component";
import {TeamResourceService} from "./shared/team-resoruce.service";
import {TeamManageResolver} from "./shared/team-manage.resolver";
import {CreateTeammateComponent} from "./create-teammate/create-teammate.component";
import {ConfirmationPopoverModule} from "angular-confirmation-popover";
//import {CreateAccessTeammateComponent} from "./create-access-teammate/create-access-teammate.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        ValidatorModule,
        ConfirmationPopoverModule
    ],
  declarations: [
    ManageComponent,
    CreateTeammateComponent,
   // CreateAccessTeammateComponent
  ],
  providers: [
    TeamResourceService,
    TeamManageResolver
  ]
})
export class TeamModule {}
