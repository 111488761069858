<div id="file-contacts-edit-modal" class="modal fade bs-modal-lg in" tabindex="-1" role="dialog"
     style="z-index: 9999999; overflow: visible">
  <div class="modal-dialog modal-md modal-preview">
    <div class="modal-content">
      <div class="modal-header ">
        <button (click)="closeContactModal()" type="button" class="close"><span
          aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">Edit file Contacts: <span class="file-title" style=" line-break: anywhere;">{{ file?.title }}</span></h4>
      </div>
      <div class="modal-body">
        <!-- BEGIN PORTLET -->
        <div id="contact-filter-form" class="form-inline no-validate">
          <div class="">
            <div class="form-group">
              <h5>Select Contact</h5>
              <div class="ng-autocomplete" style="display: flex;">
                <ng-autocomplete class="fa fa-arrow-down arrow_down"
                  [data]="contactList"
                  [searchKeyword]="keyword"
                  placeholder="Enter the Contact Name"
                  (selected)='selectContact($event)'
                  [itemTemplate]="itemTemplate"
                  [notFoundTemplate]="notFoundTemplate">
                </ng-autocomplete>
                <ng-template #itemTemplate let-item>
                  <a [innerHTML]="item.name"></a>
                </ng-template>
                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
                <div class="col-sm-8">
                  <div class="portlet-title" style="margin: 10px;">
                    <button (click)="connectToEvent()" data-create-btn type="button" class="btn blue">Connect To Event
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="sub-header">
            <div class="form-group">
              <h5>Action</h5>
              <div class="form-group">
                <label class="col-sm-4 control-label">Connect To Event</label>
                <!-- <div class="col-sm-8">
                  <div class="portlet-title">
                    <button (click)="connectToEvent()" data-create-btn type="button" class="btn blue">Connect To Event
                    </button>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="portlet-body">
            <div class="table-scrollable table-scrollable-borderless">
              <table class="table table-hover editable">
                <thead>
                <tr class="uppercase">
                  <th></th>
                  <th>Name</th>
                  <th>Category</th>
                  <th>Color</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let contact of contacts">
                  <td>
                    <div class="category-view-thumbnail">
                      <img [attr.src]=" getSafeThumbnailUrl(contact)" alt="{{ contact.slug }}"
                           class="img-thumbnail" style="height: 25px;" data-toggle="tooltip" title="{{getSafeUrl(contact)}}">
                    </div>
                  </td>
                  <td>
                    <span class="category-view-name">{{ contact.name }}</span>
                  </td>
                  <td>
                    <span class="category-view-slug" *ngIf="contact">
                      <span *ngFor="let category of contact.categories; last as isLast">
                        {{ category.name }}{{!isLast ? ', ' : ''}}
                      </span>
                    </span>
                  </td>
                  <td>
                    <span class="category-view-color">
                        <span class="span-color-name">{{ contact.background }}</span>
                        <span class="span-color"
                              [style.background-color]="contact.background">
                        </span>
                    </span>
                  </td>
                  <td>
                    <!--            <a class="btn blue btn-outline btn-w80" (click)="edit(contact)">Edit</a>-->
                    <a class="btn red btn-outline btn-w80" (click)="deleteContact(contact)">Delete</a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="form-actions">
              <a  class="btn blue" (click)="saveFileContact()">Save Change</a>
            </div>
          </div>
        </div>
        <!-- END PORTLET -->
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>
