import {UserProfile} from "../../profile/shared/user.model";
import {Contacts} from "../../shared/contacts.model";

export class FileModel
{
  id: number;
  title: string;
  description: string;
  original_path: string;
  preview_path: string;
  type: string;
  user_id: number;
  encrypt_id: string;
  clio_id: string;
  file_id: number;
  is_private: boolean;
  attach_text: string;
  destination: string;
  created_at: any;
  updated_at: any;
  pivot: any;
  owner?: UserProfile;
  users?: UserProfile[];
  events?: any;
  dummy_path:string;
  list?: any;
  mime?:any;
  size?:any;
  in_library?: any;
  contacts?: Contacts[];
}
