<form [formGroup]="popupForm" (ngSubmit)="submit()" novalidate class="cmxform" id="contact_form_popup">
  <div class="container-fluid">
  <div class="row">
    <div class="col-sm-12 form-group form-width" >
      <p>
        <label class="sr-only">First Name</label>
        <span class="first_name">
          <input formControlName="popup_first_name" type="text"
                 name="popup_first_name" value="" size="40" class="form-control"
                 aria-required="true" aria-invalid="false" placeholder="First Name">
        </span>
      </p>
    </div>
    <div class="col-sm-12 form-group form-width">
      <p>
        <label class="sr-only">Email Address</label>
        <span class="your_email">
          <input formControlName="popup_email" type="email" name="popup_email"
                                         value="" size="40" class="form-control" aria-required="true"
                                         aria-invalid="false" placeholder="Email Address">
        </span>
      </p>
    </div>
    <div class="col-sm-12 form-group form-width">
      <p>
        <label class="sr-only">Phone</label>
        <span class="phone">
          <input formControlName="popup_phone" name="popup_phone"
                 class="form-control" aria-required="true" aria-invalid="false" placeholder="Phone">
        </span>
      </p>
    </div>
    <div class="col-sm-12 form-group form-width">
      <p>
        <label class="sr-only">Text Area</label>
        <span class="text_area">
          <textarea formControlName="popup_message" type="text-area"
                    name="popup_message" value="" cols="40" rows="3" class="form-control"
                    aria-required="true" aria-invalid="false"
                    placeholder="Enter text here..."></textarea>
        </span>
      </p>
    </div>
  </div>
  <div class="form_buttons_block">
    <div class="col-sm-6 form-group form-width recuptcha">
      <re-captcha (resolved)="resolved($event)" name="captcha" siteKey="6LeQQBkUAAAAAGqShWJlo0s7lFmx9LFSY4Juw2Qv"
                  required></re-captcha>
    </div>
    <div class="col-sm-6 form-group form-width butt">
      <button [disabled]="! captcha || ! popupForm.valid || processing" type="submit" name="submit"
              class="btn btn-info">
        submit <img src="assets/squares.gif" alt="spinner" width="30px" *ngIf="processing">
      </button>
    </div>
  </div>
  </div>
</form>
