<div class="portlet light ">
  <div class="portlet-body">
    <div *ngIf="forbidden">
      <div class="alert alert-warning" *ngIf="! user?.subscription_past_due">
        Your subscription is not active, please <a [routerLink]="['/profile/subscription']">choose a subscription
          plan</a> to continue.
      </div>
      <div class="alert alert-warning" *ngIf="user?.subscription_past_due">
        Your subscription is not active, please <a [routerLink]="['/profile/subscription']">choose a subscription
          plan</a> to continue.
      </div>
    </div>
    <ng-container *ngIf="!forbidden">
      <div class="my-dashboard-list-item __header">
        <div class="row">
          <div class="col-md-3 col-sm-3">
            <div class="title" style="cursor: pointer">Title</div>
          </div>
          <div class="col-md-2 col-sm-2">
            <div class="title" style="cursor: pointer">Created By</div>
          </div>

          <div class="col-md-2 col-sm-2">
            <div class="title" style="cursor: pointer">Create Date</div>
          </div>
          <div class="col-md-2 col-sm-2">
            <div class="title" style="cursor: pointer">Last Update</div>
          </div>
          <div class="col-md-3 col-sm-3">
            <div class="title">Actions</div>
          </div>
        </div>
      </div>
      <div class="my-dashboard-list-body">
        <img *ngIf="isLoadingGrid" src="assets/squares.gif" class="img spinners" alt="spinner" style="opacity: .6;position: relative;top: 30px; left:45%;z-index: 9999999;">
        <div *ngFor="let archiveTrialline of archivedTriallines; let i = index" class="my-dashboard-list-item">
          <div class="row accord">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true"
              style="margin-bottom: 0 !important;">
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="{{ 'heading' + archiveTrialline.id }}">
                  <div class="col-xs-12 full">
                    <div class="col-md-3 col-sm-3 __title full" style=" margin-bottom: 0 !important;">
                      <div>
                        <h4 class="panel-title">
                          <a href="javascript:;">
                            {{ archiveTrialline.name }}
                          </a>
                        </h4>
                      </div>
                      <div *ngIf="archiveTrialline.archive_deleted == 1" style="font-size: 8pt; color:black;">
                        (Will be permanently deleted on <span style="color: #FF0000;">{{getExpiryDate(archiveTrialline.archive_date)}}</span>)
                      </div>
                     
                    </div>
                    
                    <div *ngIf='archiveTrialline.userdetail!=null' class="col-md-2 col-sm-2 __title">

                      {{archiveTrialline.userdetail.first_name}} {{archiveTrialline.userdetail.last_name}}
                    </div>
                    <div *ngIf='archiveTrialline.userdetail==null' class="col-md-2 col-sm-2 __title">

                    </div>
                    <div class="col-md-2 col-sm-2 __title" style="">
                      {{ archiveTrialline.created_at }}
                    </div>
                    <div class="col-md-2 col-sm-2 __title" style="">
                      {{ archiveTrialline.updated_at }}
                    </div>
                    <div class="col-md-3 col-sm-3__date list-action-{{i}}">
                      <a (click)="openTrialline(archiveTrialline, i)" class="btn blue btn-outline btn-sm btn-circle replace-reopen-text">
                        Reopen
                      </a>&nbsp;
                      <a (click)="deleteTrialline(archiveTrialline)" style="width: 130px;" class="btn red btn-outline btn-sm btn-circle replace-reopen-text">
                        Permanently Delete
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="padding-top: 10px" >
          <label *ngIf="archivedTriallines==''">Archived Triallines Not Found </label>
        </div>
      </div>
    </ng-container>
  </div>
</div>