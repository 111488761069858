<style>
  body
{
  margin-top: 20px;
}
.panel
{
  text-align: center;
}
.panel:hover { box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4), 0 1px 5px rgba(130, 130, 130, 0.35); }
.panel-body
{
  padding: 0px;
  text-align: center;
}

.the-price
{
  background-color: rgba(220,220,220,.17);
  box-shadow: 0 1px 0 #dcdcdc, inset 0 1px 0 #fff;
  padding: 20px;
  margin: 0;
}

.the-price h1
{
  line-height: 1em;
  padding: 0;
  margin: 0;
}

.subscript
{
  font-size: 25px;
}

/* CSS-only ribbon styles    */
.cnrflash
{
  /*Position correctly within container*/
  position: absolute;
  top: -9px;
  right: 4px;
  z-index: 1; /*Set overflow to hidden, to mask inner square*/
  overflow: hidden; /*Set size and add subtle rounding  		to soften edges*/
  width: 100px;
  height: 100px;
  border-radius: 3px 5px 3px 0;
}
.cnrflash-inner
{
  /*Set position, make larger then 			container and rotate 45 degrees*/
  position: absolute;
  bottom: 0;
  right: 0;
  width: 145px;
  height: 145px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -o-transform: rotate(45deg); /* Opera */
  -moz-transform: rotate(45deg); /* Firefox */
  -webkit-transform: rotate(45deg); /* Safari and Chrome */
  -webkit-transform-origin: 100% 100%; /*Purely decorative effects to add texture and stuff*/ /* Safari and Chrome */
  -ms-transform-origin: 100% 100%;  /* IE 9 */
  -o-transform-origin: 100% 100%; /* Opera */
  -moz-transform-origin: 100% 100%; /* Firefox */
  background-image: linear-gradient(90deg, transparent 50%, rgba(255,255,255,.1) 50%), linear-gradient(0deg, transparent 0%, rgba(1,1,1,.2) 50%);
  background-size: 4px,auto, auto,auto;
  background-color: #aa0101;
  box-shadow: 0 3px 3px 0 rgba(1,1,1,.5), 0 1px 0 0 rgba(1,1,1,.5), inset 0 -1px 8px 0 rgba(255,255,255,.3), inset 0 -1px 0 0 rgba(255,255,255,.2);
}
.cnrflash-inner:before, .cnrflash-inner:after
{
  /*Use the border triangle trick to make  				it look like the ribbon wraps round it's 				container*/
  content: " ";
  display: block;
  position: absolute;
  bottom: -16px;
  width: 0;
  height: 0;
  border: 8px solid #800000;
}
.cnrflash-inner:before
{
  left: 1px;
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.cnrflash-inner:after
{
  right: 0;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.cnrflash-label
{
  /*Make the label look nice*/
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  padding-bottom: 5px;
  color: #fff;
  text-shadow: 0 1px 1px rgba(1,1,1,.8);
  font-size: 0.95em;
  font-weight: bold;
  text-align: center;
}

.panel-body {

  min-height: 360px;
}

</style>

<div class="container support page-wrap">
 <div class="row">
  <div class="col-xs-12 col-sm-5 head-1">
     <h3>TEAM</h3>
  </div>
  <div class="col-xs-12 col-sm-7 head-2">
      <button
              (click)="openTeammateModal()"
              class="btn btn-primary btn-sm pull-right">Create New Teammate</button>
  </div>

 </div>
        <div class="row row-ma">
            <div class="col-xs-12 col-sm-2 bor-team nop col-first">
                <div class="col-xs-5 col-sm-12 tit-team-main  tbm title-first">First Name</div>
            </div>
            <div class="col-xs-12 col-sm-1 bor-team nop col-last">
                <div class="col-xs-5 col-sm-12 tit-team-main tbm title-last">Last Name</div>
            </div>
            <div class="col-xs-12 col-sm-3 col-md-2 bor-team nop col-email">
                <div class="col-xs-5 col-sm-12 tit-team-main tbm title-email">Email</div>
            </div>
            <div class="col-xs-12 col-sm-1 bor-team nop col-role">
                <div class="col-xs-5 col-sm-12 tit-team-main tbm title-role">Role</div>
            </div>
            <div class="col-xs-12 col-sm-1 col-md-1 bor-team nop col-veref">
                <div class="col-xs-5 col-sm-12 tit-team-main tbm title-veref">Verification</div>
            </div>
            <div class="col-xs-12 col-sm-1 col-md-1 bor-team nop col-veref">
              <div class="col-xs-5 col-sm-12 tit-team-main tbm title-veref">Subscription Status</div>
          </div>
          <!--   <div class="col-xs-12 col-sm-1 bor-team nop col-last">
                <div class="col-xs-5 col-sm-12 tit-team-main tbm title-last">Plan</div>
            </div> -->
           <div class="col-xs-12 col-sm-1 bor-team nop col-last">
                <div class="col-xs-5 col-sm-12 tit-team-main tbm title-last">Resend Account Invitation</div>
            </div>
            <div class="col-xs-12 col-sm-2 bor-team nop col-but">
                <div class="col-xs-5 col-sm-12 tit-team-main tbm title-but">Action</div>
        </div>
        </div>
  <div class="row row-ma">
        <div class="col-xs-12 col-sm-2 bor-team nop col-first">
                <div class="col-xs-5 col-sm-12 tit-team  tbm title-first">First Name</div>
                <div class="col-xs-7 col-sm-12 inf-team  tbm info-first">{{ team?.owner.first_name }}</div>
            </div>
            <div class="col-xs-12 col-sm-1 bor-team nop col-last">
                <div class="col-xs-5 col-sm-12 tit-team tbm title-last">Last Name</div>
                <div class="col-xs-7 col-sm-12 inf-team tbm info-last">{{ team?.owner.last_name }}</div>
            </div>
            <div class="col-xs-12 col-sm-3 col-md-2 bor-team nop col-email">
                <div class="col-xs-5 col-sm-12 tit-team tbm title-email">Email</div>
                <div class="col-xs-7 col-sm-12 inf-team tbm info-email">{{ team?.owner.email }}</div>
            </div>
            <div class="col-xs-12 col-sm-1 bor-team nop col-role">
                <div class="col-xs-5 col-sm-12 tit-team tbm title-role">Role</div>
                <div class="col-xs-7 col-sm-12 inf-team tbm info-role">
                    <span class="label label-success">Owner</span>
                </div>
            </div>
            <div class="col-xs-12 col-sm-1 col-md-1 bor-team nop col-veref">
                <div class="col-xs-5 col-sm-12 tit-team tbm title-veref">Verification</div>
                <div class="col-xs-7 col-sm-12 inf-team tbm info-veref">
                    <span class="ovner-ver">verified</span>
                </div>
            </div>
            <div class="col-xs-12 col-sm-1 col-md-1 bor-team nop col-veref">
                <div class="col-xs-5 col-sm-12 tit-team tbm title-veref">Subscription</div>
                <div class="col-xs-7 col-sm-12 inf-team tbm info-veref">
                    <span class="ovner-ver">Active</span>
                </div>
            </div>
             <div class="col-xs-12 col-sm-1 bor-team nop col-last">
              <div class="col-xs-5 col-sm-12 tit-team tbm title-last">-</div>
              <div class="col-xs-7 col-sm-12 inf-team tbm info-last"></div>
          </div>
          <!--   <div class="col-xs-12 col-sm-1 bor-team nop col-last">
              <div class="col-xs-5 col-sm-12 tit-team tbm title-last">Plan</div>
              <div class="col-xs-7 col-sm-12 inf-team tbm info-last"></div>
          </div> -->
            <div class="col-xs-12 col-sm-3 col-md-3 bor-team nop col-but">
                <div class="col-xs-5 col-sm-12 tit-team tbm title-but">
                    Action
                </div>
                <div class="col-xs-7 col-sm-12 inf-team tbm info-but">

                </div>
            </div>
    </div>
        <div *ngFor="let user of team.users; let i = index" class="row row-se">


            <div *ngIf="user.team_access_level!=4">
              <div *ngIf="!hideLoginUserData(user)">
            <div class="col-xs-12 col-sm-2 bor-team nop col-first">
                <div class="col-xs-5 col-sm-12 tit-team  tbm title-first">First Name</div>
                <div class="col-xs-7 col-sm-12 inf-team  tbm info-first">{{ user.first_name }}</div>
            </div>
            <div class="col-xs-12 col-sm-1 bor-team nop col-last">
                <div class="col-xs-5 col-sm-12 tit-team tbm title-last">Last Name</div>
                <div class="col-xs-7 col-sm-12 inf-team tbm info-last">{{ user.last_name }}</div>
            </div>
            <div class="col-xs-12 col-sm-3 col-md-2 bor-team nop col-email">
                <div class="col-xs-5 col-sm-12 tit-team tbm title-email">Email</div>
                <div class="col-xs-7 col-sm-12 inf-team tbm info-email">{{ user.email }}</div>
            </div>
            <div class="col-xs-12 col-sm-1 bor-team nopRole col-role">
                <div class="col-xs-5 col-sm-12 tit-team tbm title-role">Role</div>
                <div class="col-xs-7 col-sm-12 inf-team tbm info-role">
                        <select  [(ngModel)]="user.team_access_level"  (change)="ChangeRole(user.id,$event,user)" class="role" *ngIf="user.team_access_level!=3">
                                <option value=1>Staff</option>
                                <option  value=2>Manager</option>
                                <option  value=5 *ngIf='this.team.owner.subscription_plan=="annual" || this.team.owner.subscription_plan=="month" || this.team.owner.subscription_plan=="annual_499_per_year" || this.team.owner.subscription_plan=="month_59_per_month"'>Guest User</option>
                              </select>
                    <span class="role" *ngIf="user.team_access_level===3">{{ teamResourceService.getRoleName(user.team_access_level) }}</span>
                </div>
            </div>
            <div class="col-xs-12 col-sm-1 col-md-1 bor-team nop col-veref">
                <div class="col-xs-5 col-sm-12 tit-team tbm title-veref">Verification</div>
                <div class="col-xs-7 col-sm-12 inf-team tbm info-veref">
                    <span [ngClass]="{'label-primary': user.verified == 1, 'label-warning': user.verified != 1}" class="label label-sm">{{ user.verified ? 'Verified' : 'Not Verified' }}</span>
                </div>
            </div>
            <div class="col-xs-12 col-sm-1 col-md-1 bor-team nop col-veref">
              <div class="col-xs-5 col-sm-12 tit-team tbm title-veref">status</div>
              <div class="col-xs-7 col-sm-12 inf-team tbm info-veref">
                  <span title="{{user.subscription_cancelled=='stripe'? 'Cancelled By Strip' : user.subscription_cancelled == 'owner' ?'Cancelled By Owner':'Active'}}" [ngClass]="{'ovner-ver': user.subscription_cancelled == null, 'label-danger': user.subscription_cancelled != null}" class="label label-sm">{{ user.subscription_cancelled == null ? 'Active' : 'Cancelled' }}</span>
              </div>
          </div>

           <!--  <div class="col-xs-12 col-sm-1 bor-team nop col-last">
              <div *ngIf=" user.subscriptions.length!=0 && user.subscriptions[1].ends_at==null && user.user_type!=5"  class="col-xs-5 col-sm-12 tit-team tbm title-last">Plan</div>
              <div class="col-xs-7 col-sm-12 inf-team tbm info-last">{{ user.subscription_plan}}</div>
          </div> -->
          <div class="col-xs-12 col-sm-1 col-md-1 bor-team nop col-veref active-confirm">
            <div class="col-xs-5 col-sm-12 tit-team tbm title-veref">Action</div>
            <div class="col-xs-7 col-sm-12 inf-team tbm info-but">
              <a *ngIf="!user.verified"
                 class="btn btn-primary btn-sm"
                 mwlConfirmationPopover
                 [popoverMessage]="'Resend account invitation email?'"
                 [confirmText] ="'Yes'"
                 [cancelText] ="'No'"
                 placement="left"
                 (confirm)="resendTeammate(user)">
                <i class="fa fa-envelope" aria-hidden="true"></i>
                Resend
              </a>
              <a *ngIf="user.verified"
                 (click)="$event.stopPropagation()"
                 class="btn btn-lg btn-primary btn-sm" disabled >
                <i class="fa fa-envelope" aria-hidden="true"></i>
                Resend
              </a>
            </div>

          </div>
            <div class="col-xs-12 col-sm-2  col-md-3  bor-team nop col-but">
                <div class="col-xs-5 col-sm-12 tit-team tbm title-veref">Action</div>
                <div *ngIf="team?.owner.subscription_plan!='month' && team?.owner.subscription_plan!='annual' && team?.owner.subscription_plan!='annual_499_per_year' && team?.owner.subscription_plan!='month_59_per_month'"  class="col-xs-7 col-sm-12 inf-team tbm info-but">
                    <button (click)="confirmedDeleteSubscription(user)" class="btn btn-danger btn-sm pull-right" title="Delete Team Mate">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                </div>

                <div *ngIf="(team?.owner.subscription_plan=='month' || team?.owner.subscription_plan=='annual'|| team?.owner.subscription_plan=='annual_499_per_year' || team?.owner.subscription_plan=='month_59_per_month')">
                 <!--  <div  class="col-xs-7 col-sm-2 inf-team tbm info-but">

                  </div> -->
                    <div class="col-xs-7 col-sm-2 inf-team tbm info-but">
                      <button (click)="confirmedDeleteSubscription(user)" class="btn btn-danger btn-sm pull-right" title="Delete Team Mate">
                          <i class="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div  class="col-xs-7 col-sm-2 inf-team tbm info-but">
                      <button (click)="editTeammate(user)" class="btn btn-primary btn-sm pull-right" title="Edit Team Mate">
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                        <!-- <i class="fas fa-edit"></i> -->
                    </button>
                    </div>
                    <div *ngIf="user.subscriptions.length>0 && !(user.subscription_plan == 'month_59_per_month' || user.subscription_plan == 'month_59_per_month_team' || user.subscription_plan == 'annual_499_per_year' || user.subscription_plan == 'annual_499_per_year_team' )"
                         class="col-xs-7 col-sm-4 inf-team tbm info-but">
                       <span   *ngIf="user.subscription_plan == 'month'" class="ovner-ver">$49/month</span>
                       <span   *ngIf="user.subscription_plan == 'annual'" class="ovner-ver">$348/annual</span>
                    </div>
                    <div *ngIf=" user.subscriptions.length>0 && (user.subscription_plan == 'month_59_per_month' || user.subscription_plan == 'annual_499_per_year' || user.subscription_plan == 'month_59_per_month_team' || user.subscription_plan == 'annual_499_per_year_team')" class="col-xs-7 col-sm-4 inf-team tbm info-but">
                        <span *ngIf="(user.subscriptions[0].stripe_plan == 'month_59_per_month' || user.subscriptions[0].stripe_plan == 'month_59_per_month_team') && user.subscription_past_due == false " class="ovner-ver">$59/month</span>
                        <span  *ngIf="(user.subscriptions[0].stripe_plan == 'annual_499_per_year' || user.subscriptions[0].stripe_plan == 'annual_499_per_year_team') && user.subscription_past_due == false " class="ovner-ver">$499/year</span>
                    </div>

                    <div *ngIf="user.subscriptions.length>0 && !(user.subscription_plan == 'month_59_per_month' || user.subscription_plan == 'annual_499_per_year' || user.subscription_plan == 'month_59_per_month_team' || user.subscription_plan == 'annual_499_per_year_team') " class="col-xs-7 col-sm-4 inf-team tbm info-but">
                      <button *ngIf="user.subscription_plan == 'month' ||user.subscription_plan == 'annual' "  (click)="subscribe(user, i)" class="btn btn-info btn-sm pull-right">
                          Change Plan
                      </button>
                      <button *ngIf="user.subscription_past_due"  (click)="subscribe(user, i)" class="btn btn-info btn-sm pull-right"  >
                        Subscribe
                    </button>
                  </div>

                  <div *ngIf="user.subscriptions.length>0 && (user.subscription_plan == 'month_59_per_month' || user.subscription_plan == 'annual_499_per_year' || user.subscription_plan == 'month_59_per_month_team' || user.subscription_plan == 'annual_499_per_year_team')" class="col-xs-7 col-sm-4 inf-team tbm info-but">
                    <button *ngIf="user.subscriptions[0].ends_at==null"  (click)="subscribe2022(user, i)" class="btn btn-info btn-sm pull-right">
                      Change Plan
                    </button>
                    <button *ngIf="user.subscriptions[0].ends_at!=null"  (click)="subscribe2022(user, i)" class="btn btn-info btn-sm pull-right"  >
                      Subscribe
                    </button>
                  </div>
                  
                    <div *ngIf="user.subscriptions.length==0 " class="col-xs-7 col-sm-4 inf-team tbm info-but">
                      <button (click)="subscribe2022(user)" [disabled]='user.user_type==5' class="btn btn-info btn-sm pull-right">
                          Subscribe
                      </button>
                  </div>
               </div>
            </div>
            </div>
          </div>
    </div>

</div>

<div id="cancelmodal" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h5 class="modal-title">Are you sure?</h5>
      </div>
      <div class="modal-body">
        <h5 class="modal-title">Are you sure you want to cancel User subscription?You can subscribe back at any time.</h5>
          <h6>This will also cancel access for all sub-users subscribed.</h6>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        <button  style="height: 2pc;"(click)="confirmsubscribe(user)" class="btn btn-info btn-sm pull-right">
          Confirm
      </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="team.owner.subscription_plan == 'month_59_per_month' || team.owner.subscription_plan == 'annual_499_per_year' || team.owner.subscription_plan == 'month' || team.owner.subscription_plan == 'annual'" class="modal fade" id="subscriptionusermodalNew" role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" (click)="removeCoupon()">&times;</button>
        </div>

      <div class="modal-body">
          <!-- BEGIN PROFILE CONTENT -->
              <div class="profile-content">
                <div class="row">
                  <div class="col-md-12">
                    <!-- BEGIN PORTLET -->
                    <div class="portlet light">
                      <div class="row">
                        <div class="col-xs-12 col-md-1"></div>
                        <div class="col-xs-12 col-md-5">
                          <div class="panel panel-primary">
                            <div class="panel-heading">
                              <h3 class="panel-title">
                                Month-to-Month</h3>
                            </div>
                            <div class="panel-body">
                              <div class="the-price">
                                <h2>
                                  <ng-container *ngIf="couponObj.planId == 'month_59_per_month' && this.couponObj.active == true && couponObj.finalAmount > 0">
                                    <span style="text-decoration-line: line-through; -webkit-text-decoration-line: line-through;">$59</span>&nbsp;<span class="subscript"><label>${{couponObj.finalAmount}}</label>/User per month</span>
                                  </ng-container>
                                  <ng-container *ngIf="couponObj.planId != 'month_59_per_month'">
                                    $59<span class="subscript">/User per month</span>
                                  </ng-container>
                                </h2>
                              </div>
                              <table class="table table-striped">
                                <tr>
                                  <td>
                                    <b>Billed Monthly</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Flat fee. No per case charges.
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Free Two Week Trial Period
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    35GB** per User (**additional fee based storage is available.)
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Includes Offline Version
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Includes High Quality Poster Print Timeline Graphics
                                  </td>
                                </tr>
                              </table>
                              <div *ngIf="userflagfordisplay !='month_59_per_month_team'">
                                <a (click)="toggleCouponInput('month')" [class.disabled]="isYearInputVisible">Have a coupon code?</a>
                                <div *ngIf="isMonthInputVisible" style="width:100%;float: left;margin-bottom: 15px;">
                                  <div style="margin: 0 auto;display: block;width: 66%;">
                                    <span style="float:left;width: 80%;">
                                        <input type="text" placeholder="Enter coupon code" [(ngModel)]="monthCouponCode" (ngModelChange)="onCouponCodeChangeMonth()" class="form-control" />
                                    </span>
                                    <span style="float:left;width: 20%;">
                                        <button [disabled]="!monthCouponCode" class="btn btn-success" (click)="isCouponApplied ? removeCoupon() : checkCoupon(monthCouponCode, 59)" [ngClass]="{'red-background': isCouponApplied && isMonthCouponApplied()}">
                                          <i *ngIf="!isCouponApplied || isYearCouponApplied()" class="fa fa-check-square" style="margin: 0 !important;"></i>
                                          <i *ngIf="isCouponApplied && isMonthCouponApplied()" class="fa fa-times" style="margin: 0 !important;"></i>
                                        </button>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                             <div class="panel-footer">
                              <!-- <button (click)="tryToSubscribeteammate('month_59_per_month_team')"
                                      [disabled]="userflagfordisplay=='month_59_per_month_team' || userflagfordisplay=='annual_499_per_year_team' || isCouponCodeEnteredMonth"
                                      class="btn btn-success" role="button">
                                      {{userflagfordisplay =='month_59_per_month_team' ?'Current':'Select'}}
                              </button> -->
                              <ng-container *ngIf="!isCouponApplied && isMonthInputVisible">
                                <button (click)="tryToSubscribeteammate('month_59_per_month_team')"
                                  [disabled]="isCouponCodeEnteredMonth"
                                  class="btn btn-success" role="button">
                                  {{userflagfordisplay =='month_59_per_month_team' ?'Current':'Select'}}
                                </button>
                              </ng-container>
                              <ng-container *ngIf="isCouponApplied || !isMonthInputVisible">
                                <button (click)="tryToSubscribeteammate('month_59_per_month_team')"
                                  [disabled]="userflagfordisplay=='month_59_per_month_team' || userflagfordisplay=='annual_499_per_year_team'"
                                  class="btn btn-success" role="button">
                                  {{userflagfordisplay =='month_59_per_month_team' ?'Current':'Select'}}
                                </button>
                              </ng-container>
                              
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-12 col-md-5">
                          <div class="panel panel-success">
                            <div class="panel-heading">
                              <h3 class="panel-title">
                                Annual</h3>
                            </div>
                            <div class="panel-body">
                              <div class="the-price">
                                <h2>
                                  <ng-container *ngIf="couponObj.planId == 'annual_499_per_year' && this.couponObj.active == true && couponObj.finalAmount > 0">
                                    <span style="text-decoration-line: line-through; -webkit-text-decoration-line: line-through;">$499</span>&nbsp;<span class="subscript"><label>${{couponObj.finalAmount}}</label>/User per year</span>
                                  </ng-container>
                                  <ng-container *ngIf="couponObj.planId != 'annual_499_per_year'">
                                    $499<span class="subscript">/User per year</span>
                                  </ng-container>
                                </h2>
                              </div>
                              <table class="table table-striped">
                                <tr>
                                  <td>
                                    <b>Save Over $200 Annually</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Flat fee. No per case charges.
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Free Two Week Trial Period
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    35GB** per User (**additional fee based storage is available.)
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Includes Offline Version
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Includes High Quality Poster Print Timeline Graphics
                                  </td>
                                </tr>
                              </table>
                              <div *ngIf="userflagfordisplay !='annual_499_per_year_team'">
                                <a (click)="toggleCouponInput('year')" [class.disabled]="isMonthInputVisible">Have a coupon code?</a>
                                <div *ngIf="isYearInputVisible" style="width:100%;float: left;margin-bottom: 15px;">
                                  <div style="margin: 0 auto;display: block;width: 66%;">
                                    <span style="float:left;width: 80%;">
                                      <input type="text" placeholder="Enter coupon code" [(ngModel)]="yearCouponCode" (ngModelChange)="onCouponCodeChangeYear()" class="form-control" />
                                    </span>
                                    <span style="float:left;width: 20%;">
                                      <button [disabled]="!yearCouponCode" class="btn btn-success" (click)="isCouponApplied ? removeCoupon() : checkCoupon(yearCouponCode, 499)" [ngClass]="{'red-background': isCouponApplied && isYearCouponApplied()}">
                                        <i *ngIf="!isCouponApplied || isMonthCouponApplied()" class="fa fa-check-square" style="margin: 0 !important;"></i>
                                        <i *ngIf="isCouponApplied && isYearCouponApplied()" class="fa fa-times" style="margin-bottom: 0 !important;"></i>
                                      </button>
                                    </span>                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="panel-footer">
                              <!-- <button (click)="tryToSubscribeteammate('annual_499_per_year_team')"
                              [disabled]="userflagfordisplay=='annual_499_per_year_team' ||  team?.owner.is_on_trial==true || team?.owner.subscription_plan == 'month_59_per_month'"
                                      class="btn btn-success"
                                      role="button">
                                <ng-container *ngIf="userflagfordisplay == 'annual_499_per_year_team';else select">
                                    Current
                                </ng-container>
                                <ng-template #select>
                                  Select
                                </ng-template>
                              </button> -->
                              <ng-container *ngIf="!isCouponApplied && isYearInputVisible">
                              <button (click)="tryToSubscribeteammate('annual_499_per_year_team')"
                              [disabled]="isCouponCodeEnteredYear"
                                      class="btn btn-success"
                                      role="button">
                                <ng-container *ngIf="userflagfordisplay == 'annual_499_per_year_team';else select">
                                    Current
                                </ng-container>
                                <ng-template #select>
                                  Select
                                </ng-template>
                              </button>
                            </ng-container>
                            <ng-container *ngIf="isCouponApplied || !isYearInputVisible">
                              <button (click)="tryToSubscribeteammate('annual_499_per_year_team')"
                              [disabled]="userflagfordisplay=='annual_499_per_year_team' ||  team?.owner.is_on_trial==true || team?.owner.subscription_plan == 'month_59_per_month'"
                                      class="btn btn-success"
                                      role="button">
                                <ng-container *ngIf="userflagfordisplay == 'annual_499_per_year_team';else select">
                                    Current
                                </ng-container>
                                <ng-template #select>
                                  Select
                                </ng-template>
                              </button>
                            </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="userflagfordisplay== 'annual_499_per_year_team' ||  team?.owner.is_on_trial==true || team?.owner.subscription_plan == 'month_59_per_month' || userflagfordisplay=='month_59_per_month_team' || userflagfordisplay=='annual_499_per_year_team'" class="row">
                        <a (click)="unsubscribeNew()" class="btn btn-sm btn-default center-block">Cancel  Subscription</a>
                      </div>

                    </div>
                  </div>
                </div>
                </div>
              <!-- END PROFILE CONTENT -->
          </div>
    </div>
  </div>
</div>

<div id="subscribemodal" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h5 class="modal-title">Please, confirm your subscription</h5>
      </div>
      <ng-container *ngIf="couponObj.planId == ''">
      <div class="modal-body">
        <h5 class="modal-title">User will be subscribed to the <b>{{subscription_plan}}</b> plan. User will be billed <b>{{subscription_amount }}.</b></h5>
      </div>
    </ng-container>
    <!-- <ng-container *ngIf="couponObj.planId != 'annual_499_per_year'">
      <div class="modal-body">
        <h5 class="modal-title">User will be subscribed to the <b>{{subscription_plan}}</b> plan. User will be billed <b>{{subscription_amount }}.</b></h5>
      </div> -->
    <!-- </ng-container> -->
    <ng-container *ngIf="couponObj.planId == 'month_59_per_month' && this.couponObj.active == true && couponObj.finalAmount > 0 || couponObj.planId == 'annual_499_per_year' && this.couponObj.active == true && couponObj.finalAmount > 0">
      <div class="modal-body">
        <h5 class="modal-title">User will be subscribed to the <b>{{subscription_plan}}</b> plan. User will be billed <label style="text-decoration-line: line-through; -webkit-text-decoration-line: line-through;"> {{ subscription_amount }} </label> <b> ${{couponObj.finalAmount}} </b></h5>
      </div>
    </ng-container>
    <!-- <ng-container *ngIf="couponObj.planId == 'annual_499_per_year' && this.couponObj.active == true && couponObj.finalAmount > 0">
      <div class="modal-body">
        <h5 class="modal-title">User will be subscribed to the <b>{{subscription_plan}}</b> plan. User will be billed <label style="text-decoration-line: line-through; -webkit-text-decoration-line: line-through;">{{subscription_amount }}</label> <b>${{couponObj.finalAmount}}</b></h5>
      </div>
    </ng-container> -->
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        <button [disabled]="processing" style="height: 2pc;" (click)="confirmsubscription()" class="btn btn-info btn-sm pull-right">
          <img *ngIf="processing" alt="spinner" src="assets/squares.gif" width="20px">
          Confirm
      </button>
      </div>
    </div>
  </div>
</div>



<!-- Edit Teammate -->
<div *ngIf="editmodal" id="edit-teammate" class="modal fade bs-modal-md in" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header ">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">Edit Teammate</h4>
      </div>
      <form #editTeammateForm="ngForm" (submit)="editTeammateconfirm(editTeammateForm)" role="form-horizontal">
        <div class="modal-body portlet-body form">
          <div class="form-body">
            <div class="form-group">
              <label>First Name</label>
              <input type="text" name="first_name" class="form-control" [ngModel]='edituser.first_name'>
              <span class="help-block" *ngIf="errors?.first_name" style="color: red;">{{ errors?.first_name[0] }}</span>
            </div>
            <div class="form-group">
              <label>Last Name</label>
              <input type="text" name="last_name" class="form-control" [ngModel]='edituser.last_name'>
              <span class="help-block" *ngIf="errors?.last_name" style="color: red;">{{ errors?.last_name[0] }}</span>
            </div>

            <div class="form-group">
              <label>Email</label>
              <input type="email" name="email" class="form-control" [ngModel]='edituser.email'>
              <span class="help-block" *ngIf="errors?.email" style="color: red;">{{ errors?.email[0] }}</span>
            </div>
            <div class="form-group">
              <label>Access</label>
              <select    [(ngModel)]="edituser.team_access_level" name="team_access_level" class="form-control" [ngModel] >
                <!--<option value="3">Owner</option>-->
                <option value="2" *ngIf='edituser.team_access_level!=5'>Manager</option>
                <option value="1" *ngIf='edituser.team_access_level!=5'>Staff</option>
                <option value="5"  *ngIf='edituser.team_access_level==5'>Guest User</option>
              </select>
              <span class="help-block" *ngIf="errors?.team_access_level" style="color: red;">{{ errors?.team_access_level[0] }}</span>
            </div>
            <div class="form-group" style="display: none;">
              <input type="text" name="user_id" class="form-control" [ngModel]='edituser.id'>

            </div>
            <!--  <div class="form-group" *ngIf='this.team.owner.subscription_plan=="annual" || this.team.owner.subscription_plan=="month"'>
              <label>Subscription Plan</label>
              <select    [(ngModel)]="edituser.team_access_level" name="subscription_plan" class="form-control" [ngModel] id='ddlViewBy'>
                <option value="1">Month-to-Month</option>
                <option value="2" *ngIf='this.team.owner.subscription_plan=="annual"'>Annual</option>

              </select>
              <span class="help-block" *ngIf='suberror' style="color: red;">Select user subscription plan.</span>

            </div>  -->

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-primary">Update</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div id="guestusermodalchange" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h5 class="modal-title">Please, confirm your subscription</h5>
      </div>
      <div class="modal-body">
        <h5 class="modal-title">If you change user role Guest user  to Manager or Staff. You will be subscribed to the <b>Month to Month </b> subscription  plan and will be billed <b>$59</b>. </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal" (click)='reloaduser()'> Cancel</button>
        <button type="button" class="btn btn-primary" (click)='confirmchangerole()' > Confirm</button>

      </div>
    </div>
  </div>
</div>

<app-create-teammate *ngIf="showTeammateForm" (onCreated)="addTeammate($event)" [team]='team' ></app-create-teammate>
<span defaultOverlayTarget></span>
