<div class="page-container">
  <div>
   <div>
      <ul class="nav">
        <li *ngIf="simpleView !== 3">
            <a data-toggle="dropdown" (click)='togglediv()' (mouseenter)="togglediv()"  class="btn btn-default btn-icon"
               style="position:fixed;top:0px;left:6px;z-index:3;height: 34px;" >Filters
              <span id="notification" style="color: red;" [style.display]="isAllChecked() ? 'none' : 'inline-block'">*</span>
              <span *ngIf='isAllChecked()'>
              <span id="notification" style="color: red;"
                    [style.display]="isAlltagChecked() ? 'none' : 'inline-block'">*</span>
              </span>
            </a>
            <ul id='test' class="dropdown-menu multi-level" role="menu" aria-labelledby="dropdownMenu" style="display: block;margin-top: 35px;margin-left: 6px;" >
              <li id="catcolor">
                <a (mouseover)="toggleCategories()"  style="font-weight: bold;" (mouseenter)="color('catcolor')">
                  Categories
                </a>
              </li>
              <li id="tagcolor">
                <a (mouseover)="toggletag()"   style="font-weight: bold;" (mouseenter)="color('tagcolor')">Tag</a>
              </li>
            </ul>
            <div [class.open]="categories" class="menu-dropdown filters"  (mouseenter) ="togglediv()" >
              <ul id="filter-dropdown" class="dropdown-menu" style="margin: 0;overflow:auto;margin-left: 182px;margin-top:-15px; " [style.max-height]="window().innerHeight -105+ 'px'" >
                 <div (click)="test()" class="close-filters" style="margin-top: 7px;">
                  <a  data-toggle-custom="#filter-dropdown">
                    <i class="fa fa-times" aria-hidden="true"></i><br />
                  </a>
                </div>
                <li style="margin-top:8px;margin-left: 11px;">
                  <label class="mt-checkbox mt-checkbox-outline">
                    <input id='catcheck' (change)="all($event)" type="checkbox" [checked]="isAllChecked()"
                           class="filter-select-all" value="all" [disabled]="processing">
                    Show All
                    <span></span>
                  </label>
                </li>
                <li class="divider" style="margin-top: -6px;"></li>
                <li *ngFor="let category of categoriesArray" style="margin-left: 11px;">
                  <label class="mt-checkbox mt-checkbox-outline">
                    <input (change)="changedCategories()" type="checkbox"
                           [(ngModel)]="category.state" [attr.value]="category.id"
                           class="filter-select"> {{ category.name }}
                    <span></span>
                  </label>
                </li>
              </ul>
            </div>
            <div [class.open]="tags" class="menu-dropdown filters" (mouseenter) ="togglediv()">
              <ul id="filter-dropdown" class="dropdown-menu" style="margin: 0;overflow:auto;margin-left: 182px;margin-top:37px;min-width: 250px;"
                  [style.max-height]="window().innerHeight -145 + 'px'">
                 <div class="close-filters" style="margin-top: 7px;">
                  <a (click)="test()" data-toggle-custom="#filter-dropdown">
                    <i class="fa fa-times" aria-hidden="true"></i><br />
                  </a>
                </div>
                <li style="margin-top:8px;margin-left: 11px;">
                  <label class="mt-checkbox mt-checkbox-outline">
                    <input (change)="tagcheckall($event)" type="checkbox" [checked]="isAlltagChecked()"
                           class="filter-select-all" value="all" [disabled]="processing">
                    Show All
                    <span></span>
                  </label>
                </li>
                <li class="divider" style="margin-top: -6px;"></li>
                <li *ngFor="let tag of tagsarray" style="margin-left: 11px;">
                  <label class="mt-checkbox mt-checkbox-outline">
                    <input (change)="changedTags($event,tag.id)" type="checkbox"
                           [(ngModel)]="tag.status" [attr.value]="tag.id"
                           class="filter-select"> {{ tag.tag_name|uppercase}}
                    <span></span>
                  </label>
                </li>
              </ul>
              <div *ngIf='hiddentag==true' [class.open]="tags" class="menu-dropdown filters"
                   (mouseenter) ="togglediv()">
                <ul id="filter-dropdown" class="dropdown-menu" style="margin: 0;overflow:auto;margin-left: 182px;min-width: 250px;margin-top:-15px" >
                  <li style="margin-top:8px;margin-left: 11px;">
                    <label class="mt-checkbox mt-checkbox-outline">
                      <input (change)="onlytagdatawithcategorydataviews($event)" type="checkbox"
                             class="filter-select-all" value="all" id='allcattag' [(ngModel)]='hiddeneventcheckbox' >
                      Show All Hidden Tags
                      <span></span>
                    </label>
                  </li>
                  <li  style="margin-top: -16px;margin-left: 10px;"><span style="color:red;font-size:11px;">*Some tags are hidden by Categories Filter</span></li>
                </ul>
              </div>
            </div>
        </li>
      </ul>
    </div>
    <div *ngIf="view.public" class="public-ng-link" >
        <a class="btn-text-link" href="{{publicPathUrl}}"> Timeline by TrialLine</a>
    </div>
    <div class="btn-group-vertical view-by-login">
      <div class="btn-group-vertical widget-export">
        <div [hidden]="IsExportHidden">
          <a [attr.href]="getDocUrl()"
             data-toggle="tooltip" title="Export"
             type="button" class="btn btn-default btn-icon">
            <i class="fa fa-file"></i><br />
            <div class="btn-text">DOCX</div>
          </a>
          <a [attr.href]="getXlsUrl()"
             data-toggle="tooltip" title="Export"
             type="button" class="btn btn-default btn-icon">
            <i class="fa fa-table"></i><br />
            <div class="btn-text">XLSX</div>
          </a>
        </div>
      </div>
      <div class="btn btn-default btn-icon hidden-xs" id="view-toggle">
        <img src="assets/squares.gif" alt="spinner" *ngIf="disabled"
             style="max-height: 34px; max-width: 34px; opacity: .6;">
        <div class="fa fa-eye" *ngIf="!disabled"></div>
        <div class="btn-text" *ngIf="!disabled">
          Select Layout
        </div>
        <ul *ngIf="!disabled">
          <li class="btn btn-default btn-icon" *ngIf="simpleView !== 0" (click)="changedView(0)">
            <img src="../../../../assets/images/relative.svg" alt="">
          </li>
          <li class="btn btn-default btn-icon" *ngIf="simpleView !== 1" (click)="changedView(1)">
            <img src="../../../../assets/images/carousel.svg" alt="">
          </li>
          <li class="btn btn-default btn-icon" *ngIf="simpleView !== 2" (click)="changedView(2)">
            <img src="../../../../assets/images/pyramid.svg" alt="">
          </li>
          <li class="btn btn-default btn-icon" *ngIf="simpleView !== 3 && showTracks" (click)="changedView(3)">
            <img src="../../../../assets/images/track.png" alt="">
          </li>
        </ul>
      </div>
    </div>
    <app-shared-trialline *ngIf="password && simpleView === 0" (onMore)="more($event)" [events]="shownEvents"
                          [trialline]="trialline"                          
                          [commentsButton]="view.comment" [editButton]="false"></app-shared-trialline>
    <app-shared-trialline-mobile *ngIf="password && simpleView === 1" [events]="events" [trialline]="trialline"
                                 (onMore)="more($event)" [commentsButton]="view.comment" [editButton]="false">
    </app-shared-trialline-mobile>
    <app-pyramid-trialline *ngIf="password && simpleView === 2"  [getEvents]="shownEvents" [trialline]="trialline"
                                 (onMore)="more($event)" [commentsButton]="view.comment" [editButton]="false">
    </app-pyramid-trialline>
    <div class="view_tracks">
      <app-track-trialline *ngIf="password && simpleView === 3" [events]="trackEvents" [trialline]="trialline"
                           [tracks]="tracks" [editButton]="false"
                           [offsetHeight]="50" [commentsButton]="view.comment" (onMore)="more($event)">
      </app-track-trialline>
    </div>
  </div>
</div>
<div *ngIf="! password" id="password-modal" class="modal fade bs-modal-lg in" tabindex="-1" role="dialog"
     style="z-index: 9999999">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header ">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">Enter password</h4>
      </div>
      <div class="modal-body">
        <form #passwordForm="ngForm" (submit)="passwordConfirm(passwordForm)">

          <div class="form-group">
            <label class="control-label visible-ie8 visible-ie9">Password</label>
            <div class="input-icon">
              <i class="fa fa-lock"></i>
              <input [ngModel] class="form-control placeholder-no-fix" type="password" placeholder="Password"
                     name="password" autocomplete="off"></div>
          </div>
          <button type="submit" class="btn btn-primary">Ok</button>
        </form>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>
<div id="save-view-modal" class="modal fade bs-modal-lg in" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header ">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">{{ share ? 'Share' : 'Save' }} view</h4>
      </div>
      <form #createViewForm="ngForm" (submit)="createView(createViewForm)" role="form-horizontal" class="no-validate"
            *ngIf="!shareId">
        <div class="modal-body portlet-body form">
          <div class="form-body">
            <div class="form-group">
              <label>Name</label>
              <input type="text" [ngModel] name="name" class="form-control" placeholder="Please name your view">
              <span class="help-block" *ngIf="errors?.name" style="color: red;">{{ errors?.name[0] }}</span>
            </div>
            <div class="form-group">
              <label>Description</label>
              <textarea [ngModel] name="description" class="form-control autosizeme" rows="3"
                        placeholder="Add a description"></textarea>
            </div>
            <div class="form-group">
              <div class="mt-checkbox-inline">
                <label class="mt-checkbox">
                  <input [ngModel] name="comment" type="checkbox" id="inlineCheckbox1">
                  Make Comments Available
                  <span></span>
                </label>
              </div>
            </div>
            <div class="form-group" *ngIf="share">
              <label>Enter an email address to securely send this view to</label>
              <input [ngModel] type="email" class="form-control" name="email">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary">{{ share ? 'Share' : 'Create' }}</button>
        </div>
      </form>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>
<!--<app-trialline-event-more-modal [isView]="true" [viewUuid]="view?.uuid" [viewPassword]="passwordPhrase"-->
<!--                                [eventsIdsOrder]="eventsIdsOrder" [activeTab]="activeTab" [comments]="view.comment"-->
<!--                                (onComment)="addComment($event)" [event]="event" *ngIf="moreModal && simpleView !== 2"-->
<!--                                (onClose)="moreModalClosed($event)"></app-trialline-event-more-modal>-->
<app-trialline-event-more-pyramid-modal  [isView]="true" [viewUuid]="view?.uuid" [viewPassword]="passwordPhrase"
                                         [eventsIdsOrder]="eventsIdsOrder" [activeTab]="activeTab"
                                         [comments]="view.comment"
                                         (onComment)="addComment($event)" [event]="event" *ngIf="moreModal"
                                         (onClose)="moreModalClosed($event)"></app-trialline-event-more-pyramid-modal>
