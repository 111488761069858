export interface HttpEvent {
  url: string;
}

export class HttpStartEvent implements HttpEvent {
  constructor(public url: string) {}
}

export class HttpFinishEvent implements HttpEvent {
  constructor(public url: string) {}
}
