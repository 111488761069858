<div id="contact-filter-form" class="form-inline no-validate">
  <div class="">
    <div class="form-group">
      <h5>Select Contact</h5>
      <div class="ng-autocomplete">
        <ng-autocomplete class="fa fa-arrow-down arrow_down"
          [data]="contactList"
          [searchKeyword]="keyword"
          placeholder="Enter the Contact Name"
          (selected)='selectContact($event)'
          [itemTemplate]="itemTemplate"
          [notFoundTemplate]="notFoundTemplate">
        </ng-autocomplete>
        <ng-template #itemTemplate let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>
        <ng-template #notFoundTemplate let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="sub-header">
    <div class="form-group">
      <h5>Action</h5>
      <div class="form-group">
        <div class="col-sm-8">
          <div class="portlet-title">
            <button (click)="connectToEvent()" data-create-btn type="button" class="btn blue">Connect Contact to Event
            </button>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-8">
          <div class="portlet-title">
            <button (click)="create()" data-create-btn type="button" class="btn blue">Create New Contact
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="portlet-body">
    <div class="table-scrollable table-scrollable-borderless">
      <table class="table table-hover editable">
        <thead>
        <tr class="uppercase">
          <th></th>
          <th>Name</th>
          <th>Category</th>
          <th>Files</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let contact of contacts">
          <td>
            <div class="category-view-thumbnail">
              <img [attr.src]=" getSafeThumbnailUrl(contact)" alt="{{ contact.slug }}"
                   class="img-thumbnail" style="height: 140px;" data-toggle="tooltip" title="{{getSafeUrl(contact)}}">
            </div>
          </td>
          <td>
            <span class="category-view-name">{{ contact.name }}</span>
          </td>
          <td>
            <span class="category-view-slug" *ngIf="contact">
              <span *ngFor="let category of contact.categories; last as isLast">
                {{ category.name }}{{!isLast ? ', ' : ''}}
              </span>
            </span>
          </td>
          <td>
            <span *ngFor="let file of contact?.private_files">
               <div>
                 <p><a class="" href="{{ apiPath + 'filesystem/' + file.file.encrypt_id + '/download' }}" style="line-break: anywhere;">{{file.file.title}}</a></p>
               </div>
            </span>
          </td>
          <td>
            <a class="btn blue btn-outline btn-w80" (click)="edit(contact)">Edit</a>
            <a class="btn red btn-outline btn-w80" (click)="deleteContact(contact)">Delete</a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<app-trialline-event-contacts-edit
  *ngIf="showEdit"
  [editContact]="editContact"
  [events]="contactEvent"
  (onUpdated)="updateContact($event)"
  (onCreated)="createContact($event)"
  (onClose)="closeModal($event)"
  (onDelete)="deleteContact($event)"
></app-trialline-event-contacts-edit>
<span defaultOverlayTarget></span>
