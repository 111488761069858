
import {map} from 'rxjs/operators';
import {HttpResponse} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {User} from "./user.model";
import {RestService} from "../../rest/shared/rest.service";

@Injectable()
export class AuthResourceService
{
  constructor(public http: RestService) {}

  user(): Observable<any>
  {

    return this.http.get('user').pipe(map((response: any) => {
      let body = response.body;

      return body.user;
    }));
  }


  otpEmail(data): any
  {

    return this.http.post('auth/otpemail', data).pipe(map(r => r.body));

  }


  login(data): any
  {

    return this.http.post('auth/login', data).pipe(map(r => r.body));

  }

  register(data): Observable<any>
  {

    return this.http.post('auth/register', data).pipe(map((response: any) => {
      let body = response.body;

      return body.user;
    }));
  }

  sendResetPasswordEmail(data): Observable<any>
  {
    return this.http.post('auth/password/email', data).pipe(map((response: any) => {

      return response.body;
    }));

  }

  resetPassword(data)
  {

    return this.http.post('auth/password/reset', data).pipe(map((response: any) => {

      return response;
    }));

  }

  confirm(token): Observable<any>
  {

    return this.http.post('auth/register/confirm/' + token, []).pipe(map((response: any) => {
      return response.body;
    }));

  }

  confirmInvitation(token, data = []): Observable<any>
  {

    return this.http.post('auth/register/invited/' + token, data).pipe(map((response: any) => {
      return response.body;
    }));
  }

  confirmInvitationAccess(token): Observable<any>
  {

    return this.http.post('auth/register/invited-access', {token: token}).pipe(map((response: any) => {
      return response.body;
    }));

  }

  resendActivationEmail(email): Observable<any>
  {

    return this.http.post('auth/register/resend', {email: email}).pipe(map((response: any) => {
      return response.body;
    }));

  }
}
