import {NgModule} from '@angular/core';
import {HttpClientModule} from "@angular/common/http";
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {FileLibraryComponent} from "./file-library.component";
import {FileLibraryResourceService} from "./shared/file-library-resoruce.service";
import {SharedComponentsModule} from "../../shared-components/shared-components.module";
import {FilesResolver} from "./shared/files.resolver";
import {FileLibraryDetailsComponent} from "./details/details.component";
import {FileUploadModule} from "ng2-file-upload";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    SharedComponentsModule,
    FileUploadModule
  ],
  declarations: [
    FileLibraryComponent,
    FileLibraryDetailsComponent
  ],
  providers: [
    FileLibraryResourceService,
    FilesResolver
  ]
})
export class FileLibraryModule {}
