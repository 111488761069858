<div class="container page-content-inner page-wrap">
  <script type="text/javascript" src="https://js.stripe.com/v3/"></script>
  <div class="row">
    <div class="col-md-12">
      <app-profile-sidebar [user]="user"></app-profile-sidebar>
      <!-- BEGIN PROFILE CONTENT -->
      <div class="profile-content">
        <div class="col-md-12">
          <!-- BEGIN PORTLET -->
          <div class="portlet light">
            <div *ngIf="user.subscription_past_due && user.isFree==0" class="row">
              <div class="alert alert-warning" role="alert">
                Your subscription is not active, please choose a subscription plan to continue.
                <!--Your subscription is not active, please <a (click)="renewSubscription()" [class.disabled]="renewing" class="alert-link">choose a subscription plan</a> to continue.-->
              </div>
            </div>
            <!-- <div *ngIf="!oldtonew" class="row" style="margin-left: 1px;">
              <button (click)="oldtonewsubscription()" class="btn btn-success" role="button">
                New Pricing Plan
              </button>
            </div>
            <div *ngIf="!january22User && oldtonew" class="row" style="margin-left: 1px;">
              <button (click)="oldtonewsubscription()" class="btn btn-success" role="button">
                New Pricing Plan
              </button>
            </div> -->
            <div class="row">
              <!--  <div *ngIf="user.subscription_plan!='month' && user.subscription_plan!='annual' && user.subscription_plan!=null "> -->
              <div *ngIf="!oldtonew && !january22User" style="margin-top: 4px;">
                <div class="col-xs-12 col-md-4">
                  <div class="panel panel-primary">
                    <div class="panel-heading">
                      <h3 class="panel-title">
                        BASIC</h3>
                    </div>
                    <div class="panel-body">
                      <div class="the-price">
                        <h1>
                          $9.99<span class="subscript">/month</span></h1>
                      </div>
                      <table class="table table-striped">
                        <tr>
                          <td>
                            <b>Month to Month</b>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Online Only <br>(Internet Connection Required)
                          </td>
                        </tr>
                        <tr>
                          <td>
                            $9.99 Per TrialLine Per Month <br>(First TrialLine is Free)
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <br>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            1 User & 16GB Storage
                          </td>
                        </tr>
                        <tr>
                          <td>
                            7-Day Free Trial
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div class="panel-footer">
                      <button (click)="tryToSubscribe('basic')" [attr.disabled]="(user.subscription_plan == 'basic' || user.subscription_plan == 'small' ||
                                   user.subscription_plan == 'large' ? true : null) || processing"
                              class="btn btn-success" role="button">
                        {{ user.subscription_plan == 'basic' ? 'Current' : user.subscription_plan ? 'Change' : 'Select' }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-4">
                  <div class="panel panel-success">
                    <div class="cnrflash">
                      <div class="cnrflash-inner">
                          <span class="cnrflash-label">MOST
                              <br>
                              POPULAR</span>
                      </div>
                    </div>
                    <div class="panel-heading">
                      <h3 class="panel-title">
                        SMALL FIRM</h3>
                    </div>
                    <div class="panel-body">
                      <div class="the-price">
                        <h1>
                          $199<span class="subscript">/year</span></h1>
                      </div>
                      <table class="table table-striped">
                        <tr>
                          <td>
                            <b>Annual Renewal (Auto)</b>
                          </td>
                        </tr>
                        <tr>
<!--                          <td>-->
<!--                            Online + Offline Version-->
<!--                          </td>-->
                          <td>
                            Online Version
                          </td>
                        </tr>
                        <tr>
                          <td>
                            $7.49 Per TrialLine Per Month <br>(First TrialLine is Free)
                          </td>
                        </tr>
                        <tr>
                          <td>
                            SAVE 25% Per TrialLine Over Basic Version!
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Up to 5 Users & 35GB Storage
                          </td>
                        </tr>
                        <tr>
                          <td>

                          </td>
                        </tr>
                      </table>
                    </div>
                    <div class="panel-footer">
                      <button (click)="tryToSubscribe('small')"
                              [attr.disabled]="(user.subscription_plan == 'small' || user.subscription_plan == 'large' ? true : null) || processing"
                              class="btn btn-success"
                              role="button">
                        {{ user.subscription_plan == 'small' ? 'Current' : user.subscription_plan ? 'Change' : 'Select' }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-4">
                  <div class="panel panel-info">
                    <div class="panel-heading">
                      <h3 class="panel-title">
                        LARGE FIRM</h3>
                    </div>
                    <div class="panel-body">
                      <div class="the-price">                       
                        <h1>
                          $499<span class="subscript">/year</span></h1>
                      </div>
                      <table class="table table-striped">
                        <tr>
                          <td>
                            <b>Annual Renewal (Auto)</b>
                          </td>
                        </tr>
                        <tr>
<!--                          <td>-->
<!--                            Online + Offline Version-->
<!--                          </td>-->
                          <td>
                            Online Version
                          </td>
                        </tr>
                        <tr>
                          <td>
                            $7.49 Per TrialLine Per Month <br>(First TrialLine is Free)
                          </td>
                        </tr>
                        <tr>
                          <td>
                            SAVE 25% Per TrialLine Over Basic Version!
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Up to 20 Users & 100GB Storage
                          </td>
                        </tr>
                        <tr>
                          <td>

                          </td>
                        </tr>
                      </table>
                    </div>
                    <div class="panel-footer">
                      <button (click)="tryToSubscribe('large')"
                              [attr.disabled]="(user.subscription_plan == 'large' ? true : null) || processing"
                              class="btn btn-success"
                              role="button">
                        {{ user.subscription_plan == 'large' ? 'Current' : user.subscription_plan ? 'Change' : 'Select' }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--  <div *ngIf="user.subscription_plan=='month' || user.subscription_plan=='annual' || user.subscription_plan==null "> -->
              <div *ngIf="oldtonew"  style="margin-top: 4px;">
                <div *ngIf="january22User == false">
                  <div class="col-xs-12 col-md-5">
                    <div class="panel panel-primary">
                      <div class="panel-heading">
                        <h3 class="panel-title">
                          Month-to-Month</h3>
                      </div>
                      <div class="panel-body">
                        <div class="the-price">
                          <h2>
                            $49.00<span class="subscript">/User per month</span></h2>
                        </div>
                        <table class="table table-striped">
                          <tr>
                            <td>
                              <b>Billed Monthly</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Flat fee. No per case charges.
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Free Two Week Trial Period
                            </td>
                          </tr>
                          <tr>
                            <td>
                              100GB** per User (**additional fee based storage is available.)
                            </td>
                          </tr>
<!--                          <tr>-->
<!--                            <td>-->
<!--                              Includes Offline Version-->
<!--                            </td>-->
<!--                          </tr>-->
                          <tr>
                            <td>
                              Includes High Quality Poster Print Timeline Graphics
                            </td>
                          </tr>
                        </table>
                        <div>
                          <a (click)="toggleCouponInputMonth()">Have a coupon code?</a>
                          <input type="text" class="panel panel-primary custom-input" [(ngModel)]="user.couponCode" [hidden]="!isCouponInputVisible" placeholder="Apply Coupon">
                        </div>
                      </div>
                      <div class="panel-footer">
                        <button *ngIf="oldtonew1" (click)="tryToSubscribe('month')"
                                class="btn btn-success" role="button">
                          Change
                        </button>
                        <button *ngIf="!oldtonew1" (click)="tryToSubscribe('month')" [attr.disabled]="(user.subscription_plan == 'month' || user.subscription_plan == 'small' || user.subscription_plan == 'annual' ||
                                   user.subscription_plan == 'large' || user.subscription_plan == 'annual_499_per_year'  ? true : null) || processing"
                                class="btn btn-success" role="button">
                          {{ user.subscription_plan == 'month' ? 'Current' : user.subscription_plan ? 'Change' : 'Select' }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-5">
                    <div class="panel panel-success">
                      <div class="panel-heading">
                        <h3 class="panel-title">
                          Annual</h3>
                      </div>
                      <div class="panel-body">
                        <div class="the-price">
                          <h2>
                            $29.00<span class="subscript">/User per month</span></h2>
                        </div>
                        <table class="table table-striped">
                          <tr>
                            <td>
                              <b>$348.00 Billed Annually</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Flat fee. No per case charges.
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Free Two Week Trial Period
                            </td>
                          </tr>
                          <tr>
                            <td>
                              100GB** per User (**additional fee based storage is available.)
                            </td>
                          </tr>
<!--                          <tr>-->
<!--                            <td>-->
<!--                              Includes Offline Version-->
<!--                            </td>-->
<!--                          </tr>-->
                          <tr>
                            <td>
                              Includes High Quality Poster Print Timeline Graphics
                            </td>
                          </tr>
                        </table>
                        <div>
                          <a (click)="toggleCouponInputMonth()">Have a coupon code?</a>
                          <input type="text" class="panel panel-primary custom-input" [(ngModel)]="user.couponCode" [hidden]="!isCouponInputVisible" placeholder="Apply Coupon">
                        </div>
                      </div>
                      <div class="panel-footer">
                        <button *ngIf="oldtonew1" (click)="tryToSubscribe('annual')"
                                class="btn btn-success" role="button">
                          Change
                        </button>

                        <button *ngIf="!oldtonew1" (click)="tryToSubscribe('annual')"
                                [attr.disabled]="(user.subscription_plan == 'small' || user.subscription_plan == 'large'  ? true : null) || processing"
                                class="btn btn-success"
                                role="button">
                          {{ user.subscription_plan == 'annual' ? 'Current' : user.subscription_plan ? 'Change' : 'Select' }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="january22User">
                <div style="margin-top:50px;">
                  <!-- <div class="col-xs-12 col-md-2 m-0 p-0">
                    <div class="form-inline yearly-discount-label">
                        <div class="form-group mx-sm-3 mb-2">
                          <div class="yearly-text">Yearly Billing</div>
                          <div class="yearly-text-label">Save 30%</div>
                        </div>
                    </div>
                  </div> -->
                  <div class="col-xs-12 col-md-5">
                    <div class="panel panel-primary">
                      <div class="panel-heading">
                        <h3 class="panel-title">
                          Month-to-Month</h3>
                      </div>
                      <div class="panel-body">
                        <div class="the-price">
                          <h2>                          
                            <ng-container *ngIf="newTrialUser; else existingMonthlyUserTemplate">
                              <ng-container *ngIf="this.monthlyDiscountCoupon.couponName != ''; else noMonthSubscriptionExist">
                                <label style="text-decoration-line: line-through; -webkit-text-decoration-line: line-through;color:red;">$59</label> ${{freeCouponForTrialUsers.month.finalAmount}}<span class="subscript">/per user, billed monthly</span>
                              </ng-container>
                              <ng-template #noMonthSubscriptionExist>
                                $59<span class="subscript">/per user, billed monthly</span>
                              </ng-template>
                            </ng-container>
                            <ng-template #existingMonthlyUserTemplate>
                              <ng-container *ngIf="couponObj.planId == 'month_59_per_month' && this.couponObj.active == true && couponObj.finalAmount > 0">
                                <label style="text-decoration-line: line-through; -webkit-text-decoration-line: line-through;">$59</label> {{couponObj.finalAmount}}
                                <span class="subscript">/per user, billed monthly</span>
                              </ng-container>
                              <ng-container *ngIf="couponObj.planId != 'month_59_per_month'">
                                $59<span class="subscript">/per user, billed monthly</span>
                              </ng-container>
                            </ng-template>
                          </h2>
                        </div>
                        <table class="table table-striped" style="margin-top: 10px;">
                          <tr>
                            <td style="color: #B61B1B; padding-bottom: 5px;">
                              <b>Billed Monthly</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Flat fee. No per case charges.
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Free Two Week Trial Period
                            </td>
                          </tr>
                          <tr>
                            <td>
                              100GB** per User (**additional fee based storage is available.)
                            </td>
                          </tr>
  <!--                        <tr>-->
  <!--                          <td>-->
  <!--                            Includes Offline Version-->
  <!--                          </td>-->
  <!--                        </tr>-->
                          <tr>
                            <td>
                              Includes High Quality Poster Print Timeline Graphics
                            </td>
                          </tr>
                        </table>
                        <div class="coupon-container" style="width: 100%; float: left;">
                          <div class="coupon-text" style="width: 100%; float: left;" *ngIf="!isCouponApplied">
                            <a (click)="toggleCouponInput('month')" [class.disabled]="isYearCouponApplied()">Have a coupon code?</a>
                          </div>
                          <div class="coupon-inner-box-container" style="width: 100%; float: left;" [hidden]="!isCouponInputVisibleMonth">
                            <div class="coupon-input-text" style="width: 100%;float: left;">
                              <div style="text-align: right;display: inline-flex;margin-top: 10px;">
                                <input type="text" class="panel panel-primary custom-input"
                                  [(ngModel)]="user.couponCodeMonth" placeholder="Enter coupon code" #couponCodeMonth
                                  (input)="checkCouponInput('month')" [attr.readonly]="isCouponApplied ? true : null" [ngClass]="{'readonly-background': isCouponApplied && isMonthCouponApplied()}" />&nbsp;
                                <button class="btn btn-success" [ngClass]="{'red-background': isCouponApplied && isMonthCouponApplied()}"
                                  style="text-align: left;height: 29px;padding-top: 3px !important; border: none;"
                                  (click)="isCouponApplied ? removeCoupon() : checkCouponCode(couponCodeMonth.value, 59)">
                                  <i *ngIf="!isCouponApplied || isYearCouponApplied()" class="fa fa-check-square" style="margin: 0 !important;"></i>
                                  <i *ngIf="isCouponApplied && isMonthCouponApplied()" class="fa fa-times" style="margin: 0 !important;"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="panel-footer">
                        <button *ngIf="oldtonew1" (click)="tryToSubscribe('month_59_per_month')"
                                [attr.disabled]="(user.subscription_plan == 'month_59_per_month' ? true : null) || processing"
                                class="btn btn-success" role="button">
                          {{ user.subscription_plan == 'month_59_per_month'  ? 'Current' : user.subscription_plan ? 'Change' : 'Select' }}
                        </button>
                        <ng-container *ngIf="!isCouponInputVisibleMonth || isCouponApplied">
                          <button *ngIf="!oldtonew1" (click)="tryToSubscribe('month_59_per_month')" [attr.disabled]="(user.subscription_plan == 'month' || user.subscription_plan == 'small' || user.subscription_plan == 'annual' ||
                                      user.subscription_plan == 'large' || user.subscription_plan == 'month_59_per_month' || user.subscription_plan == 'annual_499_per_year' || user.is_on_trial == true ? true : null) || processing"
                                  class="btn btn-success" role="button">
                            {{ user.subscription_plan == 'month'  || user.subscription_plan == 'month_59_per_month'  ? 'Current' : user.subscription_plan ? 'Change' : 'Select' }}
                          </button>
                        </ng-container>
                        <ng-container *ngIf="isCouponInputVisibleMonth && !isCouponApplied">
                          <button *ngIf="!oldtonew1" (click)="tryToSubscribe('month_59_per_month')" 
                                  [disabled]="isCouponInputValid && !isYearCouponApplied()" class="btn btn-success" role="button">
                            {{ user.subscription_plan == 'month' || user.subscription_plan == 'month_59_per_month' ? 'Current' : user.subscription_plan ? 'Change' : 'Select' }}
                          </button>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-5">
                    <ng-container *ngIf="newTrialUser">
                      <label id="freeTiralDiscountPopup" class="freeTiralDiscountPopup" style="display: none;">showpopup</label>
                    </ng-container>
                    <div class="form-inline" style="position: absolute;
                      top: -53px;
                      left: 89px;">
                        <div class="form-group mx-sm-3 mb-2">
                          <div style="font-weight: bold; font-size: 13pt; padding-left: 10px;">Annual Billing</div>
                          <div style="color: #289cf9; font-weight: bold; text-align: center;">Save 30%</div>
                        </div>
                      </div>
                    <div class="panel panel-success">
                      <div class="panel-heading">
                        <h3 class="panel-title">
                          Annual</h3>
                      </div>
                      <div class="panel-body">
                        <div class="the-price">
                          <h2>
                            <ng-container *ngIf="newTrialUser; else existingYearlyUserTemplate">
                              <ng-container *ngIf="this.yearlyDiscountCoupon.couponName != ''; else noYearSubscriptionExist">
                                <label style="text-decoration-line: line-through; -webkit-text-decoration-line: line-through;color:red;">$41.5</label> ${{freeCouponForTrialUsers.year.tempDisplayAmount}}<span class="subscript">/per user, billed annually</span>
                              </ng-container>
                              <ng-template #noYearSubscriptionExist>
                                $41.5<span class="subscript">/per user, billed annually</span>
                              </ng-template>
                            </ng-container>
                            <ng-template #existingYearlyUserTemplate>
                              <ng-container *ngIf="couponObj.planId == 'annual_499_per_year' && this.couponObj.active == true && couponObj.finalAmount > 0">
                                <label style="text-decoration-line: line-through; -webkit-text-decoration-line: line-through;">$41.5</label> ${{couponObj.finalAmount}}
                                <span class="subscript">/per user, billed annually</span>
                              </ng-container>
                              <ng-container *ngIf="couponObj.planId != 'annual_499_per_year'">
                                $41.5<span class="subscript">/per user, billed annually</span>
                              </ng-container>
                            </ng-template>
                          </h2>
                        </div>
                        <table class="table table-striped" style="margin-top: 10px;">
                          <tr>
                            <td style="color: #B61B1B; padding-bottom: 5px;">
                              <b>Save over $190 Annually</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Flat fee. No per case charges.
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Free Two Week Trial Period
                            </td>
                          </tr>
                          <tr>
                            <td>
                              100GB** per User (**additional fee based storage is available.)
                            </td>
                          </tr>
  <!--                        <tr>-->
  <!--                          <td>-->
  <!--                            Includes Offline Version-->
  <!--                          </td>-->
  <!--                        </tr>-->
                          <tr>
                            <td>
                              Includes High Quality Poster Print Timeline Graphics
                            </td>
                          </tr>
                        </table>
                        <div class="coupon-container" style="width: 100%; float: left;">
                          <div class="coupon-text" style="width: 100%; float: left;" *ngIf="!isCouponApplied">
                            <a (click)="toggleCouponInput('year')" [class.disabled]="isMonthCouponApplied()">Have a coupon code?</a>
                          </div>
                          
                          <div class="coupon-inner-box-container" style="width: 100%; float: left;" [hidden]="!isCouponInputVisibleyear">
                            <div class="coupon-input-text" style="width: 100%;float: left;">
                              <div style="text-align: right;display: inline-flex;margin-top: 10px;">
                                <input type="text" class="panel panel-primary custom-input" [(ngModel)]="user.couponCodeyear"
                                  placeholder="Enter coupon code" #couponCodeyear (input)="checkCouponInput('year')"
                                  [attr.readonly]="isCouponApplied ? true : null" [ngClass]="{'readonly-background': isCouponApplied && isYearCouponApplied()}" /> &nbsp;
                                <button class="btn btn-success" [ngClass]="{'red-background': isCouponApplied && isYearCouponApplied()}"
                                  style="text-align: left;height: 29px;padding-top: 3px !important; border: none;"
                                  (click)="isCouponApplied ? removeCoupon() : checkCouponCode(couponCodeyear.value, 499)">
                                  <i *ngIf="!isCouponApplied || isMonthCouponApplied()" class="fa fa-check-square" style="margin: 0 !important;"></i>
                                  <i *ngIf="isCouponApplied && isYearCouponApplied()" class="fa fa-times" style="margin: 0 !important;"></i>
                                </button>
                              </div>
                            </div>                          
                          </div>
                        </div>
                      </div>
                      <div class="panel-footer">
                        <button *ngIf="oldtonew1" (click)="tryToSubscribe('annual_499_per_year')"
                                [attr.disabled]="(user.subscription_plan == 'annual_499_per_year' ? true : null) || processing"
                                class="btn btn-success" role="button">
                          {{ user.subscription_plan == 'annual_499_per_year' ? 'Current' : user.subscription_plan ? 'Change' : 'Select' }}
                        </button>
                        <ng-container *ngIf="!isCouponInputVisibleyear || isCouponApplied">
                          <button *ngIf="!oldtonew1" (click)="tryToSubscribe('annual_499_per_year')"
                                [attr.disabled]="(user.subscription_plan == 'small' || user.subscription_plan == 'large' || user.subscription_plan == 'annual_499_per_year' ? true : null) || processing"
                                class="btn btn-success"
                                role="button">
                          {{ user.subscription_plan == 'annual_499_per_year' ? 'Current' : user.subscription_plan ? 'Change' : 'Select' }}
                        </button>
                        </ng-container>
                        <ng-container *ngIf="isCouponInputVisibleyear && !isCouponApplied">
                          <button *ngIf="!oldtonew1" (click)="tryToSubscribe('annual_499_per_year')" 
                                  [disabled]="isCouponInputValid && !isMonthCouponApplied()" class="btn btn-success" role="button">
                            {{ user.subscription_plan == 'annual_499_per_year' ? 'Current' : user.subscription_plan ? 'Change' : 'Select' }}
                          </button>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div *ngIf="user.subscription_plan" class="row">
              <a (click)="cancelSubscription()" class="btn btn-sm btn-default center-block">Cancel My Subscription</a>
            </div>

            <!--  <div *ngIf="!oldtonew" class="row">
               <button (click)="oldtonewsubscription()" class="btn btn-success" role="button">
                 New Pricing Plan
               </button>
             </div> -->

          </div>
        </div>
      </div>
    </div>
    
    <!-- END PROFILE CONTENT -->
  </div>
</div>
<app-add-card (onAdded)="cardAdded($event)" [user]="user" [id_form]="3"></app-add-card>
<span defaultOverlayTarget></span>
