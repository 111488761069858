<img src="assets/squares.gif" class="img spinners" alt="spinner" *ngIf="processing" style="opacity: .8;
  position: absolute;
  top: 10px;
  left:50%;">

<div class="content">
  <form class="form-horizontal" #resetPassword="ngForm" (submit)="reset(resetPassword)" novalidate="novalidate" style="display: block;">

    <div class="form-group" [hubValidator]="errors?.email" transformer="inline">
      <div class="input-icon">
        <i class="fa fa-envelope"></i>
        <input id="email" type="email" [ngModel]="user?.email" class="form-control"  name="email" placeholder="Email">
      </div>
    </div>
    <div class="form-group" [hubValidator]="errors?.password" transformer="inline">
      <div class="input-icon">
        <i class="fa fa-lock"></i>
        <input id="password" type="password" class="form-control"  [ngModel]="user?.password" name="password" placeholder="New password" autocomplete="off">
      </div>
    </div>
    <div class="form-group" [hubValidator]="errors?.password_confirmation"  transformer="inline">
      <div class="input-icon">
        <i class="fa fa-lock"></i>
        <input id="password-confirm" type="password" class="form-control"  [ngModel]="user?.password_confirmation"  name="password_confirmation" placeholder="Password confirmation" autocomplete="off">
      </div>
    </div>


    <div class="form-group">
      <button type="submit" class="btn btn-primary">
        <i class="fa fa-btn fa-refresh"></i> Reset Password
      </button>
    </div>
  </form>
</div>
