import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Title} from "@angular/platform-browser";
import {Trialline} from "../shared/trialline.model";
import {ActivatedRoute, Router} from "@angular/router";
import {RestService} from "../../rest/shared/rest.service";
import * as xml2js from "xml2js";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {FeedServiceService} from '../profile/clio/feed-service.service';
import {NewsRss} from './news-rss';
//import {Headers} from "@angular/http";
import {env} from "../../../../.env";
import {User} from "../../auth/shared/user.model";
import {AuthService} from "../../auth/shared/auth.service";

declare var require: any
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html'
})
export class SupportComponent implements  OnInit
{
  triallines: Trialline[];

  forbidden: boolean = false;

  processing: boolean = false;
  feeds: any;
  private feedUrl: string = 'https://blog.trialline.net/feed/';
  issupport: number;
  captcha: boolean = false;
  email_error: string;
  subject_error: string;
  trl_error: string;
  desc_error: string;
  captcha_error: string;
  datafeeds: any;
  feed: any;
  RssData: NewsRss;


  constructor(
    protected toasts: ToastrService,
    protected title: Title,
    public feedService: FeedServiceService,
    protected route: ActivatedRoute,
    public router: Router,
    protected auth: AuthService,
   // private http: HttpClient
     public http: RestService
  ) { title.setTitle('Trialline | Support')

    }

  ngOnInit() {

    this.refreshFeed();
    this.route.data.subscribe((data: {triallines: Trialline[]}) => {

      let triallines: any = data.triallines;

      if (triallines.status == 403) {

        this.forbidden = true;
      } else {

        this.triallines = triallines.triallines;
      }
    });
  }

  submitTicket(form: any)
  {
    let s;let s1;let s2;let s3;let s4;
    if(form.value.email==null)
    {
       s=0;
      this.email_error='Please enter valid email address.'
    }else{
      this.email_error=''
      s=1
    }
    if(form.value.title==null)
    {
      s1=0;
      this.subject_error='Please enter subject.'
    }else{
      s1=1
      this.subject_error='';
    }
    if(form.value.trialline==null)
    {
      s2=0
      this.trl_error='Please select trialLine.'
    }else{
      s2=1
      this.trl_error=''
    }
    if(form.value.description==null)
    {
      s3=0
      this.desc_error='Please enter description.'
    }else{
      this.desc_error=''
      s3=1
    }

    if((s==1)&&(s1==1)&&(s2==1)&&(s3==1))
    {
      this.processing = true;
      return this.http.post('support', form.value).toPromise()
        .then(r => {
          this.processing = false;
          form.reset();
          this.toasts.success('Successfully submitted')
        })
        .catch(e => {
          console.log(e.json);
          this.processing = false;
          this.toasts.error('Something went wrong')
        });
    }
  }

 async  refreshFeed()
  {
    let self = this;
    localStorage.setItem('support','support');

    this.http.get( 'support-feed').subscribe(
      (data: any) => {
        let rss = data.body.rss;
        let parseString = xml2js.parseString;
        parseString(rss, (err, result: NewsRss) => {
          if (result.rss != undefined) {
            self.feeds = self.TriallineFeedsSub(result.rss.channel[0].item)
          }
        });
      },
      (error: any) => {
        console.log(error);
      }
    );

   }

  private TriallineFeedsSub(feeds:any)
     {
       return feeds.filter(function(x){
        if(x.category != undefined && (x.category.indexOf("TrialLine Tutorial") > -1))
          return true;
        else
          return false;
      });
     }

   resolved(captchaResponse: string) {
    this.captcha = true;
    this.captcha_error='';

  }

  clear(form:any)
  {
    form.reset();
    this.email_error=''
    this.subject_error='';
    this.trl_error=''
    this.desc_error=''
    this.captcha_error='';
    this.captcha=false;
    grecaptcha.reset();
  }
}
