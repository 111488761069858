import {NgModule} from '@angular/core';
import {HttpClientModule} from "@angular/common/http";
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {MainRoutingModule} from "./main-routing.module";
import {TriallinesModule} from "./triallines/triallines.module";
import {ProfileModule} from "./profile/profile.module";
import {SupportModule} from "./support/support.module";
import {FileLibraryModule} from "./file-library/file-library.module";
import {TriallineModule} from "./trialline/trialline.module";
import {ValidatorModule} from "../form-validator/validator.module";
import {LayoutsModule} from "../layouts/layouts.module";
import {TeamModule} from "./team/team.module";
import {EditCanActivate} from "./trialline/shared/edit.guard";
import {UserTypeGuestCanActivate} from "../auth/shared/user_type_guest.guard";
import {ApplicationModule} from "./application/application.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    MainRoutingModule,
    TriallinesModule,
    ProfileModule,
    SupportModule,
    FileLibraryModule,
    TriallineModule,
    ValidatorModule,
    TeamModule,
    LayoutsModule,
    ApplicationModule
  ],
  declarations: [
  ],
  providers: [
    EditCanActivate,
    UserTypeGuestCanActivate
  ],
  exports:[
  ]
})
export class MainModule {}
