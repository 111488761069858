import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute} from "@angular/router";
import {Event} from "../../../shared/event.model";
import {EventsResourceService} from "../../events/shared/events-resoruce.service";
import {env} from "../../../../../../.env";
import {NgForm} from "@angular/forms";

declare var jQuery:any;

@Component({
  selector: 'app-trialline-manage-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class TriallineManageCommentsComponent
  implements OnInit, OnDestroy
{
  @Input() events: Event[];
  @Output() processing = new EventEmitter<boolean>();
  @Output() onSelect = new EventEmitter<number>();

  public publicPath = env.publicFolderUrl;
  public apiPath = env.apiUrl;

  sub: any;
  triallineId: number;

  constructor(
    private eventsResourceService: EventsResourceService,
    private route: ActivatedRoute,
    private toasts: ToastrService,
    private _changesDetectionRef: ChangeDetectorRef
  ) {}

  ngOnInit() {

    this.sub = this.route.parent.params.subscribe(params => {
      this.triallineId = +params["trialline_id"];
    });
  }

  ngOnDestroy() {

    this.sub.unsubscribe();
  }

  toggleVisibility(event: Event) {

    this.processing.emit(true);
    this.eventsResourceService.commentVisibility(this.triallineId, event.id).toPromise()
      .then((event: any) => {

        let index = this.events.indexOf(this.events.find(e => e.id == event.id));

        this.events[index].comments_visibility = ! this.events[index].comments_visibility;
        this.processing.emit(false);
      })
      .catch((error) => { console.log(error); this.processing.emit(false); });
  }

  storeComment(event_id, view_id, form: NgForm) {

    let comment = form.value.comment;
    form.reset();
    if (! comment) { return this.toasts.error('Comment can not be empty') }

    this.processing.emit(true);
    this.eventsResourceService.postComment(this.triallineId, event_id, view_id, comment).toPromise()
      .then((comment: any) => {

        let index = this.events.indexOf(this.events.find(e => e.id == event_id));
        this.events[index].comments_count++;
        let event = this.events[index];
        let viewIndex = event.view.indexOf(event.view.find(e => e.id == view_id));
        this.events[index].view[viewIndex].comments.push(comment);
        this.processing.emit(false);
      })
      .catch((error) => { console.log(error); this.processing.emit(false); });
  }

  deleteComment(event_id, comment_id, view_id, level) {

    this.processing.emit(true);
    this.eventsResourceService.deleteComment(this.triallineId, event_id, comment_id, view_id, level).toPromise()
      .then((r: any) => {

        let index = this.events.indexOf(this.events.find(e => e.id == event_id));
        this.events[index].comments_count++;
        let event = this.events[index];
        let viewIndex = event.view.indexOf(event.view.find(e => e.id == view_id));
        let commentIndex = event.view[viewIndex].comments.indexOf(event.view[viewIndex].comments.find(e => e.id == comment_id));
        this.events[index].view[viewIndex].comments.splice(commentIndex, 1);
        this.processing.emit(false);
      })
      .catch((error) => { console.log(error); this.processing.emit(false); });
  }
}
