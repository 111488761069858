import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {View} from "../main/shared/view.model";
import {Trialline} from "../main/shared/trialline.model";
import {Event} from "../main/shared/event.model";
import {PresentResourceService} from "./shared/present-resoruce.service";
import {ToastrService} from 'ngx-toastr';
import {Category} from "../main/shared/category.model";
import {TriallineEventsComponent} from "../main/trialline/events/events.component";

declare var jQuery: any;
declare var _:any;
declare var moment: any;

@Component({
  selector: 'app-present2',
  templateUrl: './present.component.html',
  styleUrls: ['./present.component.scss']
})

export class Present2Component implements OnInit, AfterViewInit, OnDestroy
{
  @Input() password: string;
  @Input() uuid: string;
  trialline: Trialline;
  events: Event[] = [];
  shownEvents: Event[] = [];
  view: View;
  disabled: boolean = false;
  eventsIdsOrder: number[] = [];
  simpleView: boolean = false;
  trackView: boolean = false;
  event: Event;
  moreModal: boolean = false;
  moreModalId = '#event-more-modal';
  activeTab = 'description';
  processing: boolean = false;
  sub: any;
  categories: boolean = false;
  categoriesArray: Category[] = [];

  constructor(
    private presentResourceService: PresentResourceService,
    private _changeDetectionRef: ChangeDetectorRef,
    private toasts: ToastrService
  ) {}

  ngOnInit() {
    console.log('app-present2');
    this.presentResourceService.getView(this.uuid, this.password).subscribe(
      (view: View) => {
        this.view = view;
        console.log(this.view);
        if (view.trialline) {

          this.trialline = view.trialline;
          this.events = view.events;

          this.shownEvents = this.events;

          let categories: Category[] = [];
          for(let event of this.events) {

            let category = event.category;
            category.state = true;
            categories.push(category);
          }

          this.categoriesArray = (_.uniq(categories, (i) => {return i.id}));
          this.idsList();
        }
      })
  }

  load() {
    this.presentResourceService.getView(this.uuid, this.password).toPromise()
      .then((view: View) => {
        this.view = view;
        if (view.trialline) {
          this.trialline = view.trialline;
          this.events = view.events;
          this.shownEvents = this.events;
          let categories: Category[] = [];
          for(let event of this.events) {

            let category = event.category;
            category.state = true;
            categories.push(category);
          }
          this.categoriesArray = (_.uniq(categories, (i) => {return i.id}));
          this.idsList();
        }
      })
  }

  toggleCategories() {
    this.categories = ! this.categories;
  }

  changedView() {
    this.disabled = true;

    setTimeout(() => {
        this.disabled = false;
    }, 2000);
  }

  changedCategories() {
    let selected = this.getSelectedCategories();
    this.shownEvents = this.events.filter((event) => {
      return selected.indexOf(event.category_id) > -1;
    });
    this._changeDetectionRef.detectChanges();
  }

  getSelectedCategories() {
    return _.pluck(this.categoriesArray.filter(
      (obj) => {
        return obj.state
      }), 'id')
  }

  all(ev) {
    this.categoriesArray.forEach(x => x.state = ev.target.checked)
    this.changedCategories();
  }

  isAllChecked() {
    return this.categoriesArray
      .every(c => c.state);
  }

  window() {
    return window;
  }

  idsList() {
    this.eventsIdsOrder = _.pluck(_.sortBy(this.events, function(item) { return new Date(item.date_start) }), 'id');
  }

  ngOnDestroy() { };

  ngAfterViewInit() {
    this.load();
  }

  more(info) {
    this.activeTab = info.activeTab;
    this.processing = true;
    this.loadEvent(info.id);
  }

  loadEvent(event_id: number) {
    this.presentResourceService.getEvent(this.uuid, event_id, this.password).toPromise()
      .then((event: Event) => {
        this.moreModal = true;
        this.processing = false;
        this.event = event;
        this.event.date_start = TriallineEventsComponent.parseDate(this.event.date_start);
        if (this.event.date_end) {
          this.event.date_end = TriallineEventsComponent.parseDate(this.event.date_end);
        }
        this._changeDetectionRef.detectChanges();
        jQuery(this.moreModalId).modal('show');
      })
      .catch((error) => {

        this.moreModal = false;
        this.processing = false;
        this.toasts.error(error);
      });
  }

  moreModalClosed(event: Event) {
    if (this.moreModal) {

      this.moreModal = false;
    }
  }
}
