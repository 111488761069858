import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output, ViewChild
} from '@angular/core';
import {NgForm} from "@angular/forms";
import {ToastrService} from 'ngx-toastr';
import {Contacts} from "../../../../../shared/contacts.model";
import {TriallineResourceService} from "../../../../shared/trialline-resoruce.service";
import {FileItem, FileUploader} from "ng2-file-upload";
import {AuthService} from "../../../../../../auth/shared/auth.service";
import {env} from "../../../../../../../../.env";
import {ActivatedRoute, Router} from "@angular/router";
import {FileModel} from "../../../../../file-library/shared/file.model";
import {ContactsCategory} from "../../../../../shared/contactsCategory.model";
import {FileLibraryResourceService} from "../../../../../file-library/shared/file-library-resoruce.service";
import {TriallineContactsEditComponent} from "../../../../contacts/edit/edit.component";
import { param } from 'jquery';
declare var jQuery: any;

@Component({
  selector: 'app-trialline-event-contacts-edit',
  templateUrl: './edit.component.html'
})
export class TrillionEventContactsEditComponent extends TriallineContactsEditComponent{
  @Input() editContact: Contacts;
  @Input() events: any;
  @Output() onClose = new EventEmitter<Contacts>();
  @Output() onUpdated = new EventEmitter<Contacts>();
  @Output() onCreated = new EventEmitter<Contacts>();
  @Output() onDelete = new EventEmitter<Contacts>();
  @ViewChild("ckeditor1", { static: true }) ckeditor1: any;

  config: any;
  contact: Contacts;
  triallineId: number;
  thumbnail_visible: boolean;
  publicFolderUrl = env.publicFolderUrl;
  publicImages = env.publicImages;
  apiPath = env.apiUrl;
  public uploader: FileUploader;
  public privateUploader: FileUploader;
  public modalId = '#contact-edit-modal';
  defaultThumbnail: string = '/images/start-event/contact_thumbnail.png';
  filesIds: number[] = [];
  exceptFileIds: number[] = [];
  exceptFileName: string[] = [];
  privateFilesIds: number[] = [];
  errors: any = {};
  contactCategories:any = [];
  listUploadFiles:any = [];
  editing: number;
  contactCategoriesSelected: any = [];
  sub: any;
  visible: any;
  contact_thumbnail: any;
  categoryValid: any;
  valid: any;
  nameValid: any = false;
  status1: boolean = false;
  endUpload: boolean = false;
 // colorValue
  background: string = '#' + (Math.random() * 0xFFFFFF << 0).toString(16);

  constructor(
    public toasts: ToastrService,
    public triallineResoureService: TriallineResourceService,
    public authService: AuthService,
    public route: ActivatedRoute, public router: Router,
    public _changeDetectionRef: ChangeDetectorRef,
    public triallineResourceService: TriallineResourceService,
    public fileLibraryResourceService: FileLibraryResourceService
  ) {
    // @ts-ignore
    super(toasts, triallineResoureService,TrillionEventContactsEditComponent, authService, route, _changeDetectionRef, triallineResourceService, fileLibraryResourceService);

    this.privateUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let body = JSON.parse(response).body;

      if (status == 200 && body.file.id) {
        item.file = body.file
        let patch = true;
        if (this.listUploadFiles.length > 0) {
          this.listUploadFiles.forEach((fileElement) => {
            if (fileElement.file.file.id === item.file.file.id) {
              patch = false;
            }})
        }
        if (patch) {
          this.listUploadFiles.push(item);
          this.privateFilesIds.push(body.file.id);
          this.onUpdatedFile.emit(body.file);
        }
        this._changeDetectionRef.detectChanges();

      } else {

        if (status == 400) {
          let errorResponse = JSON.parse(response);
          toasts.error(errorResponse.error.message);
        } else {
          toasts.error('Something went wrong, please try again.', 'Sorry')
        }
      }
    };
  }

  ngOnInit() {
    this.config = { toolbar:'Full', uiColor : '#f8f8f8'}
    this.sub = this.route.parent.params.subscribe(params => {
      this.triallineId = +params["trialline_id"];
    });
    let contact_id = this.editContact.id != undefined ? this.editContact.id : 0;

    this.triallineResourceService.getContactsCategory(this.triallineId, contact_id).subscribe((response: any) => {
     this.contactCategories = response;
     this._changeDetectionRef.detectChanges();
      },
      (error: any) => { console.log(error);} );
    this.contact = {...this.editContact};
    if (this.contact.categories && this.contact.categories.length) {
      this.contact.categories.forEach((item)=> {
        this.contactCategoriesSelected.push(item.id)
      })
    }
    if (this.contact.id) {
      this.valid = this.contact.category_id !== null
      this.background = this.contact.background;
    }
    this.contact_thumbnail = this.setThumbnailImageUrl(this.contact.thumbnail, this.visible, this.contact);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this._changeDetectionRef.detach();
  }

  ngAfterViewChecked() {

    let self = this;
    jQuery(this.modalId).on('hidden.bs.modal', function (e) {

      self.onClose.emit(self.contact);
    })
  }

  deleteContact() {
    this.onDelete.emit(this.contact);
    jQuery(this.modalId).modal('hide');
    jQuery("#event-edit-modal").modal('show');
  }

  slugRegenerate(name: string) {
    return this.contact.slug = name.replace(/\s+/g, '').substring(0, 4).toUpperCase();
  }

  showContactDeleteIcon(path)
  {
    if (this.contact) {
      return decodeURI(path).indexOf(this.defaultThumbnail) === -1;
    }
  }

  deleteContactThumbnail()
  {
    this.contact.thumbnail = this.defaultThumbnail;
    this._changeDetectionRef.detectChanges();
  }


  setContactThumbnailImageUrl(path: string, visible: boolean, contact: any) {
    let obj = {};

    if( contact.thumbnail_encryptid != null && contact.thumbnail_encryptid != false ) {
      obj =  this.publicFolderUrl + 'index.php/v1/filesystem/' +
        contact.thumbnail_encryptid + '?preview=1'
    } else {
      obj =  this.publicImages +
        (path ? path.substring(1).replace("'", "\\'") : this.defaultThumbnail.substring(1))
    }
    return obj;
  }


  updateFiles(files: FileModel[]) {
     this.contact.files = files;
     this._changeDetectionRef.detectChanges();
  }

  addFile(file: FileModel) {
    if (!this.contact.files) this.contact.files = [];
    this.contact.files.push(file);
    this.filesIds.push(file.id);
    this.exceptFileIds.push(file.id);
    this._changeDetectionRef.detectChanges();
  }

  removeFile(file: FileModel) {
    this.fileLibraryResourceService.deleteFileFromEvent(file).subscribe((response: any) => {
      this.toasts.success('Successfully deleted');
      // let indexPrivate = this.contact.private_files.indexOf(file);
      // this.contact.private_files.splice(indexPrivate, 1);
      this.contact.private_files = this.contact.private_files?.filter(item => {
        return item.file_id !== file.id
      })
      this.listUploadFiles = this.listUploadFiles.filter(item => item.file.id !== file.id);
      this.privateFilesIds = this.privateFilesIds.filter(item => item !== file.id);
      this.filesIds = this.filesIds.filter(item => item !== file.id);
      this._changeDetectionRef.detectChanges();
    }, (error) => {
      this.toasts.error(error);
    });
  }


  categoryContactChanged(id) {
    if (id) {
      let category = this.contactCategories.find(e => e.id == id);
      this.background = category.background;
      this.contact.category = category
      this.valid = true;
      this._changeDetectionRef.detectChanges();
    }
  }

  fileSelected(event) {
    this.privateUploader.queue.forEach((item: FileItem) => {
      // @ts-ignore
      if (!item.file.id) {
        // @ts-ignore
      item.options.additionalParameter = {
        attachText: item.file.name,
        is_private: 1,
        trialline_id: this.triallineId,
        type: 'contact',
      };
      item.upload();
    }
    })
  }

  upload(item, attachText, isPublic = false) {
    if (!attachText) {
      this.toasts.error('Attach text field is required')
    } else {
      item.options.additionalParameter = {
          attachText: attachText,
          is_private: 1,
          trialline_id: this.triallineId,
          type: 'contact',
          file_id:item.file.file_id
        };
      item.upload();
    }
  }

  rename(file_id, attachText) {
    if (!attachText) {
      this.toasts.error('Attach text field is required');
    } else {
      this.triallineResoureService.renameFiles({
        file_id,
        attachText,
        type: 'contact'
      })
        .toPromise()
        .then(() => {
          this.toasts.success('Attach text field edited');
        })
      //  .catch((error) => { this.router.navigateByUrl(error.status == 403 ? '/403' : '/404') });
    }
  }

  onCKChange(description: any) {
    let TextGrab = description.editor.getData();
    TextGrab = $(TextGrab).text(); // html to text
    TextGrab = TextGrab.replace(/\r?\n|\r/gm, " "); // remove line breaks
    TextGrab = TextGrab.replace(/\s\s+/g, " ").trim();
    let res1 = TextGrab.substr(TextGrab.length - 1)
    if (res1 == "#") {
      this.status1 = true;
      jQuery("#triallinemodal").modal('show');
      jQuery($('#triallinemodal').css('top:70%'));
    } else {
      this.status1 = false;
      jQuery("#triallinemodal").modal('hide');
    }
  }


  addCategory(category: ContactsCategory) {
    // this.contactCategories.push(category);
    this.contactCategories = [...this.contactCategories, category];
    this.contactCategoriesSelected = [...this.contactCategoriesSelected, category.id];
    this.contact.category_id = category.id
    this.background = category.background;
    this.valid = true;
  }

  onChangeBackground(val) {
   this.background = val ;
  }
  closePopup(){
    jQuery(this.modalId).modal('hide');
    jQuery("#event-edit-modal").modal('show');
  }

  saveContact(form: NgForm) {

    let params = form.value;
    console.log('param', params);
    params.thumbnail = this.contact.thumbnail;
    params.thumbnail_visible = this.contact.thumbnail_visible;
    params.thumbnail_encryptid = this.contact.thumbnail_encryptid;
    params['trialline_id'] = this.triallineId;
    params['background'] = this.background;
    if(this.contact.slug !== undefined) {
      params['slug'] = this.contact.slug;
    }
    if(!this.contactCategoriesSelected.length){
      this.toasts.error('Contact Category is Required');
    }
    if(!params.name){
      this.toasts.error('Contact Name is Required');
    }
    params['categories'] = this.contactCategoriesSelected;
    if (this.privateFilesIds && this.privateFilesIds.length > 0) {
      params.private_attachments = this.privateFilesIds;
    }
    if (this.contact.id) {
      this.triallineResoureService.updateContact(this.triallineId, this.contact.id, params).toPromise()
        .then((contact: any) => {
          contact.category = this.contact.category;
          this.onUpdated.emit(contact);
          this.toasts.success('Successfully updated');
          jQuery(this.modalId).modal('hide');
        })
        .catch((error) => {
          if (error.status == 422) {
            let errorData: any = error.error;
            this.errors = errorData.data;
            this.toasts.error(error);
          } else {
            this.toasts.error(error);
          }
        });
    } else {
      this.triallineResoureService.createContact(this.triallineId, params).toPromise()
        .then((contact: any) => {
          contact.category = this.contact.category;
          this.onCreated.emit(contact);
          this.toasts.success('Successfully created');
          let triallineIdContacts = JSON.parse(localStorage.getItem('triallineIdContacts_' + this.triallineId));
          let contactsNew = [];
          if (triallineIdContacts != null) {
            let uniqueItems = triallineIdContacts;
            if (!Array.isArray(triallineIdContacts)) {
              uniqueItems = triallineIdContacts.split(',');
            }
            if (uniqueItems.length > 0 ) {
              for (let item in uniqueItems) {
                contactsNew.push(parseInt(uniqueItems[item]));
              }
            }
          }
          contactsNew.push(parseInt(contact.id));
          localStorage.setItem('triallineIdContacts_' + this.triallineId, JSON.stringify(contactsNew));
          jQuery(this.modalId).modal('hide');
        })
        .catch((error) => {
          if (error.status == 422) {
            let errorData: any = error.error;
            this.errors = errorData.data;
            this.toasts.error(this.errors.message);
          } else {
            this.toasts.error(error);
          }
        });
    }
    this.contact_thumbnail = this.setThumbnailImageUrl(this.contact.thumbnail, this.visible, this.contact);
  }
}
