import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output, ViewChild
} from '@angular/core';
import {NgForm} from "@angular/forms";
import {ToastrService} from 'ngx-toastr';
import {Contacts} from "../../../shared/contacts.model";
import {TriallineResourceService} from "../../shared/trialline-resoruce.service";
import {FileItem, FileUploader} from "ng2-file-upload";
import {AuthService} from "../../../../auth/shared/auth.service";
import {env} from "../../../../../../.env";
import {ActivatedRoute, Router} from "@angular/router";
import {FileModel} from "../../../file-library/shared/file.model";
import {ContactsCategory} from "../../../shared/contactsCategory.model";
import {FileLibraryResourceService} from "../../../file-library/shared/file-library-resoruce.service";
import Cropper from 'cropperjs';
import {HelpersService} from "../../../../services/helpers.service";

declare var jQuery: any;

@Component({
  selector: 'app-trialline-contacts-edit',
  templateUrl: './edit.component.html'
})
export class TriallineContactsEditComponent implements AfterViewChecked, OnInit, OnDestroy {
  @Input() contacts: Contacts[];
  @Input() editContact: Contacts;
  @Input() events: any;
  @Output() onClose = new EventEmitter<Contacts>();
  @Output() onUpdated = new EventEmitter<Contacts>();
  @Output() onCreated = new EventEmitter<Contacts>();
  @Output() onDelete = new EventEmitter<Contacts>();
  @Output() onUpdatedFile = new EventEmitter<File>();
  @ViewChild("ckeditor1", {static: true}) ckeditor1: any;

  config: any;
  //contact: Contacts;
  contact: Contacts = new Contacts();
  triallineId: number;
  thumbnail_visible: boolean;
  publicFolderUrl = env.publicFolderUrl;
  public publicImages = env.publicImages;
  apiPath = env.apiUrl;
  public uploader: FileUploader;
  public privateUploader: FileUploader;
  public modalId = '#contact-edit-modal';
  defaultThumbnail: string = '/images/start-event/contact_thumbnail.png';
  filesIds: number[] = [];
  exceptFileIds: number[] = [];
  exceptFileName: string[] = [];
  privateFilesIds: number[] = [];
  errors: any = {};
  contactCategories: any = [];
  contactCategoriesSelected: any = [];
  listUploadFiles: any = [];
  editing: number;
  sub: any;
  visible: any;
  contact_thumbnail: any;
  categoryValid: any;
  nameValid: any = false;
  endUpload: boolean = false;
  valid: any;
  // colorValue
  background: string = '#' + (Math.random() * 0xFFFFFF << 0).toString(16);
  crop_image: HTMLImageElement;
  cropper: any;
  cropped_image_src: string;
  cropProcessing: boolean = false;
  blobImg: any = false;
  isCropped: boolean = false;
  categories: any = [];
  isImageInvalid: boolean = false;

  constructor(
    private helpersService: HelpersService,
    public toasts: ToastrService,
    public triallineResoureService: TriallineResourceService,
    public authService: AuthService,
    public route: ActivatedRoute, public router: Router,
    public _changeDetectionRef: ChangeDetectorRef,
    public triallineResourceService: TriallineResourceService,
    public fileLibraryResourceService: FileLibraryResourceService
  ) {
    this.sub = this.route.parent.params.subscribe(params => {
      this.triallineId = +params["trialline_id"];
    });
    this.uploader = new FileUploader({
      url: env.apiUrl + 'file/thumbnail?attach_to=trialline&attach_to_id=' + this.triallineId,
      authToken: 'Bearer ' + authService.token(),
      autoUpload: true
    });
    this.uploader.onAfterAddingFile = (fileItem) => {
      this.handleFileSelection(fileItem._file);
    };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.isCropped = false;
      jQuery('#crop_block').hide();
      let body = JSON.parse(response).body;
      if (body.path) {
        this.contact.thumbnail = body.path;
      }
      if (body.encrypt_id) {
        this.contact.thumbnail_encryptid = body.encrypt_id;
      }
      this.contact_thumbnail = this.setThumbnailImageUrl(this.contact.thumbnail, this.visible, this.contact);
      this._changeDetectionRef.detectChanges();
      this.showCropImage();
    };
    this.privateUploader = new FileUploader({
      url: env.apiUrl + 'file/private',
      authToken: 'Bearer ' + authService.token(),
    });
    this.privateUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let body = JSON.parse(response).body;
      if (status == 200 && body.file.id) {
        item.file = body.file
        let patch = true;
        if (this.listUploadFiles.length > 0) {
          this.listUploadFiles.forEach((fileElement) => {
            if (fileElement.file.file.id === item.file.file.id) {
              patch = false;
            }
          })
        }
        if (patch) {
          this.listUploadFiles.push(item);
          this.privateFilesIds.push(body.file.id);
          this.onUpdatedFile.emit(body.file);
        }
        this._changeDetectionRef.detectChanges();
      } else {
        if (status == 400) {
          let errorResponse = JSON.parse(response);
          toasts.error(errorResponse.error.message);
        } else {
          toasts.error('Something went wrong, please try again.', 'Sorry')
        }
      }
    };
  }
  handleFileSelection(file: File) {
    if (file.type.startsWith('image/')) {
      this.isImageInvalid = false;
      this.uploadThumbnail(file);
    } else {
      this.isImageInvalid = true;
      this.uploader.clearQueue();
      this.toasts.error('Please select a valid image file.', 'Error');
    }
  }


  // handleFileSelection(file: File) {
  //   if (file.type.startsWith('image/')) {
  //     this.isImageInvalid = false;
  //     this.uploadThumbnail(file);
  //   } else {
  //     this.isImageInvalid = true;
  //     this.uploader.clearQueue();
  //     this.toasts.error('Please select a valid image file.', 'Error');
  //   }
  // }

  trackByFn(index, file) {
    return index;
  }

  ngOnInit() {
    jQuery('#crop_block').hide();
    this.config = {toolbar: 'Full', uiColor: '#f8f8f8'}
    this.sub = this.route.parent.params.subscribe(params => {
      this.triallineId = +params["trialline_id"];
    });
    let contact_id = this.editContact.id != undefined ? this.editContact.id : 0;
    this.triallineResourceService.getContactsCategory(this.triallineId, contact_id).subscribe((response: any) => {
      this.contactCategories = response;
      this._changeDetectionRef.detectChanges();
    }, (error: any) => {
      console.log(error);
    });
    this.contact = {...this.editContact};
    if (this.contact.id) {
      this.valid = this.contact.category_id !== null
      this.background = this.contact.background;
    }
    if (!this.contact.updated_at) {
      this.contact.updated_at = 'null'
    }
    if (this.contact.categories && this.contact.categories.length) {
      this.contact.categories.forEach((item)=> {
        this.contactCategoriesSelected.push(item.id)
      })
    }
    this.contact_thumbnail = this.setThumbnailImageUrl(this.contact.thumbnail, this.visible, this.contact);
    if (this.contact.name !== undefined) {
      this.nameValid = this.contact.name.length > 0;
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this._changeDetectionRef.detach();
  }

  showCropImage() {
    this.cropped_image_src = this.contact_thumbnail;
    var self = this;
    this.crop_image = jQuery('#crop_img')[0] as HTMLImageElement;
    this.crop_image.src = this.cropped_image_src;
    this._changeDetectionRef.detectChanges();
    this.cropProcessing = true;
    this.crop_image.addEventListener('load', function (event) {
      var cropper = new Cropper(self.crop_image, {
        aspectRatio: 1 / 1,
        minContainerWidth: 300,
        minContainerHeight: 300,
        highlight: false,
        zoomable: false,
        responsive: false,
        crop(event) {
          self.cropper = cropper;
        }
      });
    });
    this.crop_image.addEventListener( 'ready', function (event) {
      self.cropProcessing = false;
      self.isCropped = true;
      self._changeDetectionRef.detectChanges();
      jQuery('#crop_block').show();
    })
  }

  cropApply() {
    this.cropProcessing = true;
    this.isCropped = false;
    this.cropper.getCroppedCanvas({
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
    }).toBlob((blob) => {
      const formData = new FormData();
      jQuery('#crop_block').hide();
      let filename = this.contact_thumbnail.substring(this.contact_thumbnail.lastIndexOf('/')+1);
      filename = filename.replace(/\.[^/.]+$/, "")
      let finalFilname = filename+".jpeg"
      formData.append('file', blob,finalFilname);
      this.uploadThumbnail(formData);
    },'image/jpeg',1);
  }

  sendContact(form) {
    let params = form.value;
    params.thumbnail = this.contact.thumbnail;
    params.thumbnail_visible = this.contact.thumbnail_visible;
    params.thumbnail_encryptid = this.contact.thumbnail_encryptid;
    params['trialline_id'] = this.triallineId;
    params['background'] = this.background;
    params['categories'] = this.contactCategoriesSelected;
    if (this.contact.slug !== undefined) {
      params['slug'] = this.contact.slug;
    }
    if (this.privateFilesIds && this.privateFilesIds.length > 0) {
      params.private_attachments = this.privateFilesIds;
    }
    if (this.contact.id) {
      this.updateContact(params);
    } else {
      this.createContact(params);
    }
    this.isCropped = false;
    this.contact_thumbnail = this.setThumbnailImageUrl(this.contact.thumbnail, this.visible, this.contact);
  }

  updateContact(params) {
    this.triallineResoureService.updateContact(this.triallineId, this.contact.id, params).toPromise()
      .then((contact: any) => {
        this.onUpdated.emit(contact);
        this.toasts.success('Successfully updated');
        jQuery(this.modalId).modal('hide');
       // jQuery('#contact-edit-modal').modal('hide');
      })
      .catch((error) => {
        if (error.status == 422) {
          let errorData: any = error.error;
          this.errors = errorData.data;
        } else {
          this.toasts.error(error);
        }
      });
  }

  createContact(params) {
    this.triallineResoureService.createContact(this.triallineId, params).toPromise()
      .then((contact: any) => {
        contact.category = this.contact.category;
        this.onCreated.emit(contact);
        this.toasts.success('Successfully created');
        jQuery(this.modalId).modal('hide');
        jQuery('#contact-edit-modal').modal('hide');
        let triallineIdContacts = JSON.parse(localStorage.getItem('triallineIdContacts_' + this.triallineId));
        let contactsNew = [];
        if (triallineIdContacts != null) {
          let uniqueItems = triallineIdContacts;
          if (!Array.isArray(triallineIdContacts)) {
            uniqueItems = triallineIdContacts.split(',');
          }
          if (uniqueItems.length > 0) {
            for (let item in uniqueItems) {
              contactsNew.push(parseInt(uniqueItems[item]));
            }
          }
        }
        contactsNew.push(parseInt(contact.id));
        localStorage.setItem('triallineIdContacts_' + this.triallineId, JSON.stringify(contactsNew));
      })
      .catch((error) => {
        console.log(error);
        if (error.status == 422) {
          let errorData: any = error.error;
          this.errors = errorData.data;
          this.toasts.error(this.errors.message);
        } else {
          this.toasts.error(error);
        }
      });
  }

  cropCancel() {
    this.isCropped = false;
    jQuery('#crop_block').hide();
    this.cropper.destroy();
  }

  uploadThumbnail(formData) {
    var self = this;
    jQuery.ajax(env.apiUrl + 'file/thumbnail', {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + this.authService.token()},
      data: formData,
      processData: false,
      contentType: false,
      success(response) {
        self.cropProcessing = false;
        if (response.body.path) {
          self.contact.thumbnail = response.body.path;
        }
        if (response.body.encrypt_id) {
          self.contact.thumbnail_encryptid = response.body.encrypt_id;
        }
        if (response.body.message) {
          self.toasts.success(response.body.message);
        }
        self.contact_thumbnail = self.setThumbnailImageUrl(self.contact.thumbnail, self.visible, self.contact);
        self._changeDetectionRef.detectChanges();
        self.cropper.destroy();
        jQuery('#crop_block').hide();
      },
      // error() {
      //   self.cropProcessing = false;
      //   self.toasts.error('Something went wrong', 'Sorry');
      // },
    });
  }

  ngAfterViewChecked() {
    let self = this;
    jQuery(this.modalId).on('hidden.bs.modal', function (e) {
      self.onClose.emit(self.contact);
    })
  }

  saveContact(form: NgForm) {
    this.sendContact(form);
    this.closeModal();

  }
  closeModal(){
    this.contacts.sort( this.helpersService.compareNameAb );
    this.contacts.forEach((item) => {
      item.categories.forEach((category) => {
        if (!this.categories.includes(category.name)) {
          this.categories.push(category.name);
        }
      })
    })
    jQuery(this.modalId).modal('hide');
  }

  deleteContact() {
    this.onDelete.emit(this.contact);
    jQuery(this.modalId).modal('hide');
  }

  slugRegenerate(name: string) {
    return this.contact.slug = name.replace(/\s+/g, '').substring(0, 4).toUpperCase();
  }

  showDeleteIcon(path) {
    if (this.contact) {
      return decodeURI(path).indexOf(this.defaultThumbnail) === -1;
    }
  }

  deleteThumbnail() {
    this.contact.thumbnail = this.defaultThumbnail;
    this.isCropped = false;
    jQuery('#crop_block').hide();
    if (this.cropper) {
      this.cropper.destroy();
    }
    this._changeDetectionRef.detectChanges();
  }

  setThumbnailImageUrl(path: string, visible: boolean, contact: any) {
    let obj = {};
    let addDate = contact ?
      contact.updated_at?.replaceAll(' ', '').replaceAll(':', '').replaceAll('-', '') : 'null'
    if (contact.thumbnail_encryptid != null && contact.thumbnail_encryptid != false) {
      obj = this.publicFolderUrl + 'index.php/v1/filesystem/' +
        contact.thumbnail_encryptid + '?preview=1&date=' + addDate
    } else {
      obj = this.publicImages +
        (path ? path.substring(1).replace("'", "\\'") : this.defaultThumbnail.substring(1))
    }
    return obj;
  }


  updateFiles(files: FileModel[]) {
    this.contact.files = files;
    this._changeDetectionRef.detectChanges();
  }

  addFile(file: FileModel) {
    if (!this.contact.files) this.contact.files = [];
    this.contact.files.push(file);
    this.filesIds.push(file.id);
    this.exceptFileIds.push(file.id);
    this._changeDetectionRef.detectChanges();
  }

  removeFile(file: FileModel) {
    this.fileLibraryResourceService.deleteFileFromContact(file, this.contact).subscribe((response: any) => {
      this.toasts.success('Successfully deleted');
      // let indexPrivate = this.contact.private_files?.indexOf(file);
      // this.contact.private_files?.splice(indexPrivate, 1);
       this.contact.private_files = this.contact.private_files?.filter(item => {
        return item.file_id !== file.id
      })
      this.listUploadFiles = this.listUploadFiles.filter(item => item.file.id !== file.id)
      this.privateFilesIds = this.privateFilesIds.filter(item => item !== file.id);
      this.filesIds = this.filesIds.filter(item => item !== file.id);
      this._changeDetectionRef.detectChanges();
    }, (error) => {
      this.toasts.error(error);
    });
  }


  categoryContactChanged(id) {
    if (id) {
      let category = this.contactCategories.find(e => e.id == id);
      this.background = category.background;
      // this.contact.category = category
      this.valid = true;
      this._changeDetectionRef.detectChanges();
    }
  }

  categoryNameChanged(name) {
    if (name) {
      this.nameValid = name.length > 0;
    }
  }

  upload(item, attachText, isPublic = false) {
    if (!attachText) {
      this.toasts.error('Attach text field is required');
    } else {
      item.options.additionalParameter = {
        attachText: attachText,
        is_private: 1,
        trialline_id: this.triallineId,
        type: 'contact',
        file_id: item.file.file_id
      };
      item.upload();
    }
  }

  rename(file_id, attachText) {
    if (!attachText) {
      this.toasts.error('Attach text field is required');
    } else {
      this.triallineResoureService.renameFiles({
        file_id,
        attachText,
        type: 'contact'
      })
        .toPromise()
        .then((response) => {
          this.onUpdatedFile.emit(response.file.file);
          this.toasts.success('Attach text field edited');
        })
    }
    let fileObj = this.contact.private_files.map(file => {
      if (file.file_id == file_id) {
        return file;
      }
    })
  }

  fileSelected(event) {
    this.privateUploader.queue.forEach((item: FileItem) => {
      // @ts-ignore
      if (!item.file.id) {
        // @ts-ignore
      item.options.additionalParameter = {
        attachText: item.file.name,
        is_private: 1,
        trialline_id: this.triallineId,
        type: 'contact',
      };
      item.upload();
    }
    })
  }

  addCategory(category: ContactsCategory) {
    // console.log('I am in add category function');
    // console.log('categories', category);
    // console.log('contactCategories:', this.contactCategories);
    
    
    // this.contactCategories.push(category);
    this.contactCategories = [...this.contactCategories, category];
    //console.log('updated contactCategories:', this.contactCategories);
    
    this.contactCategoriesSelected = [...this.contactCategoriesSelected, category.id];

    //console.log("this.contactCategoriesSelected pushed:",this.contactCategoriesSelected, category.id)
    
    this.contact.category_id = category.id;
    
    this.contact.category = category;
    this.background = category.background;
    this.valid = true;

    this._changeDetectionRef.detectChanges();


    
  }

  onChangeBackground(val) {
    this.background = val;
  }

  closePopup(){
    jQuery(this.modalId).modal('hide');
  }

  

}
