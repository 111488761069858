
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";





import {RestService} from "../../../rest/shared/rest.service";
import {Trialline} from "../../shared/trialline.model";
import {Category} from "../../shared/category.model";
import {View} from "../../shared/view.model";

@Injectable()
export class TriallineResourceService
{
  constructor(public http: RestService) {}

  getTrialline(id, withParam?: string): Observable<any>
  {

    return this.http.get('trialline/' + id + (withParam ? '?with=' + withParam : '')).pipe(map((response: any) => {
      let body = response.body;

      return body.trialline;
    }));

  }

  updateTrialline(id, data): Observable<any>
  {

    return this.http.put('trialline/' + id, data).pipe(map((response: any) => {
      let body = response.body;

      return body.trialline;
    }));
  }

  getCategories(id, withParam?: string): Observable<any>
  {

    return this.http.get('trialline/' + id + '/categories' + (withParam ? '?with=' + withParam : ''), '', false).pipe(map((response: any) => {
      let body = response.body;

      return body.categories;
    }));
  }

  getViews(id, withParam?: string): Observable<any>
  {

    return this.http.get('trialline/' + id + '/views' + (withParam ? '?with=' + withParam : '')).pipe(map((response: any) => {
      let body = response.body;

      return body.views;
    }));
  }

  createCategory(id, params) {

    return this.http.post('trialline/' + id + '/categories', params).pipe(map((response: any) => {
      let body = response.body;

      return body.category;
    }));
  }

  renameFiles(params) {
    return this.http.post('file/private', params).pipe(map((response: any) => {
      return response.body;
    }));
  }

  updateCategory(id, category_id, params) {

    return this.http.put('trialline/' + id + '/categories/' + category_id, params).pipe(map((response: any) => {
      let body = response.body;

      return body.category;
    }));

  }

  deleteCategory(id, category_id) {

    return this.http.delete('trialline/' + id + '/categories/' + category_id).pipe(map((response: any) => {
      let body = response.body;

      return body.category;
    }));

  }

  deleteTeammember(id, member_id) {

    return this.http.delete('trialline/' + id + '/team?member_id' + member_id).pipe(map(
      r => r.body
    ));
  }

  addTeammember(id, member_id) {

    return this.http.post('trialline/' + id + '/team', {member_id: member_id}).pipe(map(
      r => r.body
    ));
  }

  downloadExport(id) {

    return this.http.delete('trialline/' + id + '/export').pipe(map(
      r => {  return r.body; }
    ))
  }

  addGuestUser(data) {

    return this.http.post('team/guestuser', {data:data});
  }

  getContacts(trialline_id, withParam?: string): Observable<any>
  {

    return this.http.get('trialline/' + trialline_id + '/contacts' + (withParam ? '?with=' + withParam : ''), '', false).pipe(map((response: any) => {
      let body = response.body;

      return body.contacts;
    }));
  }

  createContact(id, params) {

    return this.http.post('trialline/' + id + '/contacts', params).pipe(map((response: any) => {
      let body = response.body;

      return body.contact;
    }));
  }

  updateContact(id, contact_id, params) {

    return this.http.put('trialline/' + id + '/contacts/' + contact_id, params).pipe(map((response: any) => {
      let body = response.body;

      return body.contact;
    }));

  }
  
  // saveBackgroundImage(imageUrl)
  // {

  //   return this.http.post('trialline/image/save', {'background': imageUrl}).pipe(map((response: any) => {
  //     let body = response.body; 
  //     return body;
  //   }));

  // }

  deleteContact(trialline_id, contact_id) {

    return this.http.delete('trialline/' + trialline_id + '/contacts/' + contact_id).pipe(map((response: any) => {
      let body = response.body;

      return body.contact;
    }));

  }

  getContactsCategory(id, contact_id, withParam?: string): Observable<any>
  {

    return this.http.get('trialline/' + id + '/contacts/'+ contact_id + '/categories' + (withParam ? '?with=' + withParam : ''), '', false).pipe(map((response: any) => {
      let body = response.body;

      return body.categories;
    }));
  }

  createContactCategory(id, params) { 

    return this.http.post('trialline/' + id + '/contacts-category', params).pipe(map((response: any) => {
      let body = response.body;

      return body.category;
    }));
  }

  updateContactCategory(id, contact_id, category_id, params) {

    return this.http.put('trialline/' + id + '/contacts/' + contact_id + '/category/' + category_id, params).pipe(map((response: any) => {
      let body = response.body;

      return body.contact;
    }));

  }

  deleteContactCategory(id, contact_id, category_id) {

    return this.http.delete('trialline/' + id + '/contacts/' + contact_id + '/category/' + category_id).pipe(map((response: any) => {
      let body = response.body;

      return body.contact;
    }));

  }
}
