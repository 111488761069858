<div id="visualizations_{{idVisualisation}}" class="track_view" #visualizations
     [style.background-image]="trialline?.background_file && trialline?.background_file.type == 'image' ? 'url(' + apiPath + 'filesystem/' + trialline?.background_file.encrypt_id + ')' : ''"
     style="background-size: cover;
     background-repeat: no-repeat;"
>
  <div *ngIf="trialline?.background_file && trialline?.background_file.type == 'video'" style="position: fixed; width: 100%;">
    <video
      [attr.src]="apiPath + 'filesystem/' + trialline?.background_file.encrypt_id" width="100%" height="100%" autoplay loop muted>
      Your browser does not support the video tag.
    </video>
  </div>
  <div class="center-block monthBlock">
    <div id="nameMonth">
      <p class="trialName">{{ trialline?.name }}</p>
      <p class="month"></p>
    </div>
  </div>
  <div class="widget-zoom">
    <div class="btn-group-vertical margin-right-10">
      <a (click)="zoomPercent(-0.3)" id="zoom-plus" class="btn btn-default btn-icon">
        <i class="fa fa-search-plus"></i>
      </a>
      <a (click)="zoomPercent(0.3)" id="zoom-minus" class="btn btn-default btn-icon">
        <i class="fa fa-search-minus"></i>
      </a>
    </div>
  </div>
  <div class="navigation_track">
    <app-shared-trialline_navigation [timeline]="timeline" [minDate]="minDate" [maxDate]="maxDate"
                                     (onRangeChanged)="showMonthYear($event)"></app-shared-trialline_navigation>
  </div>
</div>
