import {NgModule} from '@angular/core';
import {HttpClientModule} from "@angular/common/http";
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {SupportComponent} from "./support.component";
import {SupportResourceService} from "./shared/support-resoruce.service";
import {ProfileModule} from '../profile/profile.module';
import {RecaptchaModule} from "ng-recaptcha";
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    ProfileModule,
    RecaptchaModule
  ],
  declarations: [
    SupportComponent
  ],
  providers: [
    SupportResourceService
  ],

})
export class SupportModule {}
