import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  HostListener,
  ViewChild, ElementRef
} from '@angular/core';
import {env} from "../../../../.env";
import {FileModel} from "../../main/file-library/shared/file.model";
import {Modal} from 'ngx-modialog-7/plugins/bootstrap';
import {AuthService} from "../../auth/shared/auth.service";
import {User} from "../../auth/shared/user.model";
import {FileLibraryResourceService} from "../../main/file-library/shared/file-library-resoruce.service";
import {ToastrService} from 'ngx-toastr';
import {DropzoneComponent} from "../dropzone/dropzone.component";
import {DomSanitizer} from "@angular/platform-browser";
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import * as screenfull from 'screenfull';
import JSZipUtils from "jszip-utils";
 import {PreviewComponent} from "../preview/preview.component";

declare var jQuery: any;

const enum Status {
  OFF = 0,
  RESIZE = 1,
  MOVE = 2
}

@Component({
  selector: 'app-shared-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})

export class FilesComponent implements OnInit, OnDestroy {
  userid: any;
  fullpath: any;
  backfolder: any;
  structuree: boolean;
  cemptyfolder: any;
  cmainfolderavailabelinsubdirectory=[];
  cfolderNamemain: any;
  folderName: any;
  dummypath: any;
  cdeltefolderName: any;
  deleteid = [];
  folderdeleteid = [];
  renamefolderName: any;
  folderNameforrename: any;
  renameid=[];
  folderrenameid=[];
  checkpath: any;
  checkindexid: number;
  fileoffolderid=[];
  fileoffolderidanother=[];
  folderoffolderid=[];
  folderoffolderidanother=[];
  checkboxstore=[];
  foldercount: any;
  movestatus: boolean;
  countfile: any;
  moveid=[];
  arr_name=[];
  structure1: boolean;
  structure2: boolean;
  showid: number;
  DropzoneComponentobj: DropzoneComponent;
  listView: boolean = true;
  list1: any;
  foldernametoshow: string;
  callid=[];
  urls: any[];
  anotherdata: any[];
  sss: any[];
  filepath: any;
  PreviewComponents: PreviewComponent;
  pdfpath: any;
  pdfpathIpad: any;
  fullscreen: boolean = false;
  modalStatus: boolean = false;
  screenW: any = false;
  fullIpad: boolean = false;
  currentStatus: string;
  page: number = 1;
  orderCol: string = 'title';
  order: string = 'title';
  orderValue: string = '';
  perPage: number = 25;
  total: number;
  reverse: boolean = true;
  forbidden: boolean = false;
  loading: boolean;
  user: User;
  file: FileModel;
  newFiles: any[];
  allFiles: FileModel[] = [];
  fileContact:any;
  width: number;
  height: number;
  left: number = 0;
  top: number = 0;
  imageGot: boolean = false;
  popupImage : any = '';
  isMultiSelect:false;
  displayPortion: boolean = true;
  zipProcessing: boolean = false;

  private boxPosition: { left: number, top: number };
  private containerPos: { left: number, top: number, right: number, bottom: number };
  public mouse: {x: number, y: number};
  public status: Status = Status.OFF;
  private mouseClick: {x: number, y: number, left: number, top: number}
  @ViewChild("previewModal3")  public previewModal3: ElementRef;

  @Input()
  set files(files: FileModel[]) {
    this.allFiles=[];
     this.newFiles = files;
    if (this.moreFiles) {
      this.allFiles = files;
      this.displayPortion = false;
    } else {
      if (files) {
        for (let i = 0; i < files.length; i++) {
          this.allFiles.push(files[i]);
        }
      }
    }
    if(this.allFiles !== undefined){
      this.allFiles.sort(this.dynamicSort('title'))
    }
  }

  @Input() uploadedFiles: FileModel[];
  @Input() preview: boolean;
  @Input() onlyevent: boolean;
  @Input() inLibrary: boolean;
  @Input() list: boolean;
  @Input() cloudFunc: boolean;
  @Input() removeFunc: boolean;
  @Input() moreFiles: boolean;
  @Output() onClick = new EventEmitter<FileModel>();
  @Output() onRemove = new EventEmitter<FileModel>();
  @Output() onCloud = new EventEmitter<FileModel>();
  @Output() onFilesUpdated = new EventEmitter<FileModel[]>();
  @Output() onChangeContacts = new EventEmitter<FileModel[]>();

  editModalId: string = '#file-edit-modal';
  editFileContactModalId: string = '#file-contacts-edit-modal';
  previewodalId: string = '#preview-modal';

  apiPath = env.apiUrl;
  subscribtion: any;
  allid:any;
  id:number;
  TempFolder=[];
  companyFiles=[];
  constructor(
    protected ref: ChangeDetectorRef,
    public modal: Modal,
    public auth: AuthService,
    protected resourceService: FileLibraryResourceService,
    private fileLibraryResourceService: FileLibraryResourceService,
    protected toasts:ToastrService,private _sanitizer: DomSanitizer
  ) {
    this.PreviewComponents= new PreviewComponent(ref,_sanitizer);
    this.allid =[ ];
    this.user  = auth.userModel;
    this.subscribtion = auth.user().subscribe((user: User) => {
      this.user = user;
      this.test(user)
    });
  }

  ngOnInit() {

    if (typeof this.preview === 'undefined') this.preview = false;
    if (typeof this.list === 'undefined') this.list = false;
    if (typeof this.cloudFunc === 'undefined') this.cloudFunc = true;
    if (typeof this.removeFunc === 'undefined') this.removeFunc = true;
    if (typeof this.onlyevent === 'undefined') {
      this.onlyevent=false;
      localStorage.setItem("uploadcomponent",'0');
      localStorage.setItem("folderpath",'');
    } else {
      this.foldernametoshow='Home';
      this.DropzoneComponentobj=new DropzoneComponent(this.auth,this.toasts, this.resourceService);
      localStorage.setItem("uploadcomponent",'2');
      localStorage.setItem("folderpath",'');
      this.structure1=true;
      this.onlyevent=true;
      this.structuree=false
      this.fullpath='';
      this.backfolder=[];
      this.companyFiles=[];
      this.TempFolder=[]
      for(let i=0; i<this.allFiles.length; i++) {
        this.callid.push(this.allFiles[i].id)
        if(((this.allFiles[i].original_path).split("/",100)).length<4) {
          this.companyFiles.push(this.allFiles[i])
        } else {
           var  splittext = (this.allFiles[i].original_path).split("/",100)[2]
           if(this.TempFolder.includes(splittext)==false)
           {
             this.TempFolder.push((this.allFiles[i].original_path).split("/",100)[2]);
           }
        }
      }
    }
  }

  ngOnDestroy() {
    this.subscribtion.unsubscribe();
    this.ref.detach();
  }

  selectFile(file: FileModel)
  {
    jQuery('#files').css({display: "initial"});
    jQuery('.tab-content').css({padding: "15px"});
    jQuery('#files').css({display: "initial"});
    this.popupImage = '';
    this.imageGot = false;
    if (this.preview) {
      this.file = file;
      this.resourceService.logFile(file).toPromise()
        .then((response: any) => {
          setTimeout(()=>{
            $("#frameId").contents().find("img").attr("style","width:100%");
            $("#frameId").contents().find("embed").attr("style","width:100%");
          }, 60000000)
          this.ref.detectChanges();
          this.pdfpath=this._sanitizer.bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/'
            + this.file.encrypt_id);
            console.log("this.pdfpath", this.pdfpath);
        })
        .catch((error) => {
          console.log(error);
        });

      jQuery(this.previewodalId).modal('show');
      this.screenW = navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null;
    }
    if (file.type === 'pdf' && this.screenW) {
      this.pdfpathIpad = this.getSafeResourceUrlPdf();
    }
    this.loadBox();
    this.loadContainer();
  }

  ngAfterViewInit() {
    this.onScrollDown(true);
      let self = this;
      jQuery('#preview-modal').on('hidden.bs.modal', function (e) {
        self.closePreviewer();
      });
      if (screenfull && typeof screenfull.on !== "undefined") {
          screenfull.on('change', () => {
              this.fullscreen = screenfull.isFullscreen;
          });
      }
    if (navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null) {
      jQuery(window).resize(function (e) {
        let height = $(window).height();
        if (height < 750){
          height = height - 50;
        } else {
          height = height - 170;
        }
        $('#preview-modal').find('.zoom_active').find('.modal-content')
          .css({'height': height + 'px'});
      });
    }
    jQuery('#closePreviewer').on('touchstart', function (e) {
      console.log('closePreviewer');
    });
    jQuery('#zoomPreviewToggle').on('touchstart', function (e) {
      console.log('zoomPreviewToggle');
    });
    this.width = 570;
    this.height = 390;
  }

  onScrollDown(needAll: boolean = false) {
    if (typeof this.newFiles !== 'undefined' && this.newFiles.length !== this.allFiles.length) {
      let count = 0;
      for (let i = 0; i < this.newFiles.length; i++) {
        if (this.allFiles.indexOf(this.newFiles[i]) === -1) {
          this.allFiles.push(this.newFiles[i]);
          count++;
        }
        if (count === 10 && !needAll) break; //TODO
      }
    }
  }

  editFile(file: FileModel) {
    if (file !== undefined) {
      this.updated(file);
    }
    if (this.cloudFunc) {
      this.file = file;
      this.pdfpath=this._sanitizer.bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/' + this.file.encrypt_id + '?embedded=true');
      this.ref.detectChanges();
      this.pdfpath=this._sanitizer.bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/' + this.file.encrypt_id);
      jQuery(this.editModalId).modal('show');
      this.screenW = navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null;
    if (file.type === 'pdf' && this.screenW) {
      this.pdfpathIpad = this.getSafeResourceUrlPdf();
    }
    }
  }

  connectContact(file: FileModel) {
    this.file = file;
    this.ref.detectChanges();
    this.fileContact = this.file;
    jQuery(this.editFileContactModalId).modal('show');
  }

  getFileName(name: string) {
    if(name != undefined) {
      return name.replace(/\d{9,}_/, '');
    }
    return '';
  }

  imageIcons(fExtension: string) {
      switch(fExtension) { 
        case 'pdf': 
        { 
          return 'assets/images/icon-file-type/pdf.png';
          break; 
        } 
        case 'audio':
        { 
          return 'assets/images/icon-file-type/mp3.png'; 
          break; 
        }
        case 'table':
        { 
          return 'assets/images/icon-file-type/xls.png'; 
          break; 
        }
        case 'text':
        { 
          return 'assets/images/icon-file-type/doc.png';  
          break; 
        } 
        default: 
        { 
          return 'assets/images/custom-column-images/paperwork.png'; 
          break; 
        } 
      }
    }

  updated(file: FileModel) {
    let index3 = this.companyFiles.indexOf(this.companyFiles.find(f => f.id == file.id));
    this.companyFiles[index3] = file;
    let index2 = this.allFiles.indexOf(this.allFiles.find(f => f.id == file.id));
    this.allFiles[index2] = file;
    if(this.files !== undefined) {
      let index = this.files.indexOf(this.files.find(f => f.id == file.id));
      this.files[index] = file;
    }
    this.ref.detectChanges();
  }

  remove(file: FileModel) {
    if (file.pivot) {
      if (file.pivot.event_id) {
        const dialogRef = this.modal.confirm()
          .size('lg')
          .isBlocking(true)
          .showClose(true)
          .keyboard(27)
          .dialogClass('modal-dialog overflowed')
          .okBtn('Delete It')
          .title('Confirmation')
          .body('Are you sure you want to remove this document from this event?')
          .open()
        dialogRef.result
          .catch((err: any) => {
            this.modelClose()
            console.log('ERROR: ' + err)})
          .then((dialog: any) => {
            this.modelClose()
            if(dialog){
              this.confirmedRemove(file)
            }
          });
      } else if (file.pivot.trialline_id) {
        const dialogRef =  this.modal.confirm()
          .size('lg')
          .isBlocking(true)
          .showClose(true)
          .keyboard(27)
          .dialogClass('modal-dialog overflowed')
          .title('Confirmation')
          .okBtn('Delete Document')
          .body('Are you sure you want to remove this document from this trialline?')
          .open()
        dialogRef.result
          .catch((err: any) => {
            this.modelClose()
            console.log('ERROR: ' + err)
          })
          .then((dialog: any) => {
            this.modelClose()
            if(dialog){
              this.confirmedRemove(file)
            }
          });
      } else {
        const dialogRef =  this.modal.confirm()
          .size('lg')
          .isBlocking(true)
          .showClose(true)
          .keyboard(27)
          .dialogClass('modal-dialog overflowed')
          .title('Confirmation')
          .okBtn('Delete Document')
          .body('Are you sure you want to delete this document from your file library? If you do, it will also be' +
            ' deleted from any events that reference this document.')
          .open()
        dialogRef.result
          .catch((err: any) => {
            this.modelClose()
            console.log('ERROR: ' + err)
          })
          .then((dialog: any) => {
            this.modelClose()
            if(dialog){
              this.confirmedRemove(file)
            }
          });
      }
    } else {
      const dialogRef = this.modal.confirm()
        .size('lg')
        .isBlocking(true)
        .showClose(true)
        .keyboard(27)
        .dialogClass('modal-dialog overflowed')
        .title('Confirmation')
        .okBtn('Delete Document')
        .body('Are you sure you want to remove this document?')
        .open()
      dialogRef.result
        .catch((err: any) => {
          this.modelClose()
          console.log('ERROR: ' + err)
        })
        .then((dialog: any) => {
          this.modelClose()
          if(dialog){
            this.confirmedRemove(file)
          }
        });
    }
    this.modelAction()
  }

  toggleCloud(file: FileModel) {
    if (!file.in_library) {
      const dialogRef = this.modal.confirm()
        .size('lg')
        .isBlocking(true)
        .showClose(true)
        .keyboard(27)
        .dialogClass('modal-dialog overflowed')
        .title('Confirmation')
        .body('File will be added to your company file library for future use.')
        .open()
      jQuery(".modal.fade.show").addClass("in");
      dialogRef.result
        .catch((err: any) => {
          this.modelClose()
          console.log('ERROR: ' + err)
        })
        .then((dialog: any) => {
          this.modelClose()
          if(dialog){
            this.confirmedToggleCloud(file)
          }
        });
    } else {
      const dialogRef = this.modal.confirm()
        .size('lg')
        .isBlocking(true)
        .showClose(true)
        .keyboard(27)
        .dialogClass('modal-dialog overflowed')
        .title('Confirmation')
        .body('This file is already part of your Company File Library. Click "OK" to remove this file from your' +
          ' Company File Library (file will remain in your Case File Library).')
        .open()
      console.log(this.inLibrary);
      jQuery(".modal.fade.show").addClass("in");
      dialogRef.result
        .catch((err: any) => {
          this.modelClose()
          console.log('ERROR: ' + err)
        })
        .then((dialog: any) => {
          this.modelClose()
          if(dialog){
            this.confirmedToggleCloud(file)
          }
        });
    }
    this.modelAction()
  }

  confirmedRemove(file: FileModel) {
    this.companyFiles=[];
    this.onRemove.emit(file);
    this.ref.detectChanges();
    let tempFiles = [];
    for (const el of this.allFiles) {
      if (file.id === el.id) continue;
      tempFiles.push(el);

    }
    this.allFiles = tempFiles;
    if(this.onlyevent==true) {
      this.refreshfunction();
    }
    return  this.allFiles;
  }

  confirmedToggleCloud(file: FileModel) {
    this.onCloud.emit(file);
    if (this.inLibrary) {
      this.companyFiles=[];
      this.ref.detectChanges();
      let tempFiles = [];
      for (const el of this.allFiles) {
        if (file.id === el.id) continue;
        tempFiles.push(el);
      }
      this.allFiles = tempFiles;
      if(this.onlyevent==true) {
        this.refreshfunction();
      }
    }
    this.ref.detectChanges();
  }

  selectFileType(data: any): void {
    if (this.files !== undefined && this.files.length !== this.allFiles.length) {
      let count = 0;
      for (let i = 0; i < this.files.length; i++) {
        if (this.allFiles.indexOf(this.files[i]) === -1 && this.files[i].type === data.target.value) {
          this.allFiles.push(this.files[i]);
          count++;
        }
        if (count === 10) break;
      }
    }
  }

  filterFileName(data: any): void {
    if (this.files !== undefined && this.files.length !== this.allFiles.length) {
      let count = 0;
      let need = data.target.value.toLowerCase();
      for (let i = 0; i < this.files.filter(file => new RegExp(need).test(file.title.toLowerCase())).length; i++) {
        if (this.allFiles.indexOf(this.files[i]) === -1) {
          this.allFiles.push(this.files[i]);
          count++;
        }
        if (count === 10) break;
      }
    }
  }

  onFileSubmit(data: any): void {
    if (this.allFiles && this.allFiles.length) {
      let index2 = this.allFiles.indexOf(this.allFiles.find(f => f.id == data.id));
      if (index2 === -1) {
        this.allFiles.push(data);
      }
    } else {
      this.allFiles = [];
      this.allFiles.push(data);
    }
    if(this.onlyevent==true) {
      this.refreshfunction();
    }

  }
  addFile(file: FileModel) {
    this.allFiles.push(file);
    this.onFileSubmit(file);
    this.callid.push(file.id);
    this.ref.detectChanges();
    this.toasts.success('Successfully Added.')
  }

  /* My Changes  */
  createnewfolderincompanyfileslibray() {
    jQuery("#myModal").modal("show");
  }

  test(id) {
    this.userid=id.id;
    this.resourceService.getcFolders({'user_id':this.userid}).toPromise().then((response: any) => {
      this.cemptyfolder=response.folderData;
      for(let i=0; i<this.cemptyfolder.length; i++) {
        var  splittext=(this.cemptyfolder[i].folder_path).split("/",100)[0]
        if(this.TempFolder.includes(splittext)==false) {
          this.TempFolder.push((this.cemptyfolder[i].folder_path).split("/",100)[0]);
        }
      }
    })
  }

  OpenTrlFolder(folder,Fname) {

    this.callid=[];
    this.foldernametoshow=Fname;
    if(this.fullpath==undefined||this.fullpath=='') {
        this.fullpath=Fname.trim();
    } else {
        this.fullpath=(this.fullpath).concat('/'+Fname.trim());
    }
    localStorage.setItem("folderpath",this.fullpath);
    this.backfolder=this.fullpath.split("/",100);
    this.companyFiles=[];
    this.TempFolder=[];
    for(let i=0;i<this.allFiles.length;i++) {
        var cte=this.fullpath;
        var cpatharray1=(this.allFiles[i].original_path.split('/'));
        var cjoinedArray1=cpatharray1.slice(2,cte.split("/",100).length+2).join("/");
        if(cjoinedArray1==this.fullpath) {
            if((this.allFiles[i].original_path.indexOf(this.fullpath)!== -1)) {
                this.callid.push(this.allFiles[i].id)
                var indexid=(this.fullpath.split("/",100).length);
                if(((this.allFiles[i].original_path).split("/",100))
                  .length-3==(this.fullpath.split("/",100).length)) {
                    this.companyFiles.push(this.allFiles[i])
                } else {
                  var splittext=(this.allFiles[i].original_path).split("/",100)[indexid+2]
                  if(this.TempFolder.includes(splittext)==false) {
                    this.TempFolder.push((this.allFiles[i].original_path).split("/",100)[indexid+2]);
                  }
                }
            }
        }
        for(let j=0;j<this.cemptyfolder.length;j++) {
          var ctef=this.fullpath;
          var cpatharray=(this.cemptyfolder[j].folder_path.split('/'));
          var cjoinedArray =cpatharray.slice(0,ctef.split("/",100).length).join("/");
          if(cjoinedArray==this.fullpath) {
            if(this.cemptyfolder[j].folder_path.indexOf(this.fullpath)!==-1) {
              var emindexid=(this.fullpath.split("/",100).length);
              var emsplittext=(this.cemptyfolder[j].folder_path).split("/",100)[emindexid]
              if((this.TempFolder.includes(emsplittext)==false)&&(emsplittext!==undefined)) {
                this.TempFolder.push((this.cemptyfolder[j].folder_path).split("/",100)[emindexid]);
              }
            }
          }
        }
      }
      this.DropzoneComponentobj.autoload();
      if(this.checkboxstore.length>0) {
        setInterval(() =>
          {
            console.log(554);
            this.callFuntionAtIntervals();
          }, 1000);
      }
  }

  backtofolder(fname) {
    if(fname==1) {
      this.foldernametoshow='Home';
      this.callid=[];
      this.companyFiles=[];
      this.fullpath='';
      this.backfolder=[];
      this.TempFolder=[];

      for(let i=0;i<this.allFiles.length;i++) {
        this.callid.push(this.allFiles[i].id)
        if(((this.allFiles[i].original_path).split("/",100)).length<4) {
         this.companyFiles.push(this.allFiles[i])
        } else {
          let  splittext = (this.allFiles[i].original_path).split("/",100)[2];
          if(this.TempFolder.includes(splittext)==false) {
             this.TempFolder.push((this.allFiles[i].original_path).split("/",100)[2]);
          }
        }
      }
      for(let j=0;j<this.cemptyfolder.length;j++) {
         let  emsplittext =(this.cemptyfolder[j].folder_path).split("/",100)[0]
         if(this.TempFolder.includes(emsplittext)==false) {
             this.TempFolder.push((this.cemptyfolder[j].folder_path).split("/",100)[0]);
         }
      }
    } else {
      this.foldernametoshow=fname;
      this.callid=[];
      this.fullpath = this.fullpath.substring(0, this.fullpath.indexOf(fname));
      this.fullpath=(this.fullpath).concat(fname);
      this.backfolder=this.fullpath.split("/",100);
      this.companyFiles=[];
      this.TempFolder=[];
      for(let i=0;i<this.allFiles.length;i++) {
        var cte=this.fullpath;
        var cpatharray1=(this.allFiles[i].original_path.split('/'));
        var cjoinedArray1=cpatharray1.slice(2,cte.split("/",100).length+2).join("/");
        if(cjoinedArray1==this.fullpath) {
          var indexid=(this.fullpath.split("/",100).length);
          if((this.allFiles[i].original_path.indexOf(this.fullpath)!== -1)) {
            this.callid.push(this.allFiles[i].id)
            if(((this.allFiles[i].original_path).split("/",100))
              .length-3==(this.fullpath.split("/",100).length)) {
                this.companyFiles.push(this.allFiles[i])
            } else {
              var splittext=(this.allFiles[i].original_path).split("/",100)[indexid+2]
              if(this.TempFolder.includes(splittext)==false) {
                this.TempFolder.push((this.allFiles[i].original_path).split("/",100)[indexid+2]);
              }
            }
          }
        }
      }
      for(let j=0;j<this.cemptyfolder.length;j++) {
        var ctef=this.fullpath;
        var cpatharray=(this.cemptyfolder[j].folder_path.split('/'));
        var cjoinedArray =cpatharray.slice(0,ctef.split("/",100).length).join("/");
        if(cjoinedArray==this.fullpath) {
          if(this.cemptyfolder[j].folder_path.indexOf(this.fullpath)!==-1) {
            var indexid=(this.fullpath.split("/",100).length);
            var emsplittext=(this.cemptyfolder[j].folder_path).split("/",100)[indexid]
            if((this.TempFolder.includes(emsplittext)==false)&&(emsplittext!==undefined)) {
              this.TempFolder.push((this.cemptyfolder[j].folder_path).split("/",100)[indexid]);
            }
          }
        }
      }
    }

    localStorage.setItem("folderpath",this.fullpath);
    this.DropzoneComponentobj.autoload();

    if(this.checkboxstore.length>0) {
      setInterval(() => {
            this.callFuntionAtIntervals();
      }, 1000);
    }
  }

  levelup() {
    if(this.backfolder.length==1) {
      this.backtofolder(1)
    } else {
      let test = [];
      let levelup=this.fullpath.split('/');
      test=levelup.splice(levelup.length-2,1)
      this.backtofolder(test);
    }
  }

  hide() {
    this.structuree=false;
  }

  assigne() {
    this.structuree=true;
  }

  createFolderonmaindirectory() {
    if((this.cfolderNamemain!=null) && (this.cfolderNamemain!='') && (this.cfolderNamemain!=undefined)) {
      if (this.cmainfolderavailabelinsubdirectory.includes(this.cfolderNamemain) === false) {
        if ((this.fullpath === null)||(this.fullpath === '')) {
         this.TempFolder.push(this.cfolderNamemain);
         this.cmainfolderavailabelinsubdirectory = this.TempFolder;
        }
        jQuery("#myModal").modal("hide");
        this.toasts.success('Main Folder Successfully Created');
        this.resourceService.CreatecEmptyFolder({'folder_path':this.cfolderNamemain.trim(),'user_id':this.userid})
          .toPromise().then((response: any) => {
            this.cfolderNamemain='';
            if(response.Status=="Successfully Created") {
              this.resourceService.getcFolders({'user_id':this.userid}).toPromise().then((response: any) => {
                    this.cemptyfolder=response.folderData;
              });
            }
        })
      } else {
        this.toasts.error(' Folder Already Created');
      }
    } else {
      document.getElementById("input-text-folder").style.borderColor = "red";
    }
  }

  createFolder() {
    if ((this.folderName !== null) && (this.folderName !== '') && (this.folderName !== undefined)) {
      if (this.fullpath==undefined||this.fullpath=='') {
        this.dummypath=this.folderName.trim();
      } else {
        this.dummypath=(this.fullpath.trim()).concat('/'+this.folderName);
      }
      if (this.TempFolder.includes(this.folderName) === false) {
        this.TempFolder.push(this.folderName);
        jQuery("#submyModal").modal("hide");
        this.toasts.success('Sub Folder Successfully Created');
        this.folderName = '';
        this.resourceService.CreatecEmptyFolder({'folder_path':this.dummypath,'user_id':this.userid})
          .toPromise().then((response: any) => {
            if (response.Status=="Successfully Created") {
              this.resourceService.getcFolders({'user_id':this.userid}).toPromise().then((response: any) => {
                    this.cemptyfolder=response.folderData;
              });
            }
        })
      } else {
        this.toasts.error('Sub Folder Already Created');
      }
    } else {
      document.getElementById("input-text").style.borderColor = "red";
    }
  }

  deletefoldermodal(name) {
    jQuery('#deletemodal').modal("show");
    this.cdeltefolderName = name;
  }

  confirmedDeletefolder() {
    let name = this.cdeltefolderName;
    let chekcontain = '';
    if((this.fullpath === null) || (this.fullpath === '')) {
      chekcontain = name;
    } else {
      chekcontain = this.fullpath.concat('/'+name);
    }
    let indexid = (chekcontain.split("/",100).length);
    for(let i=0; i < this.allFiles.length; i++) {
      let cte = chekcontain;
      let cpatharray1 = (this.allFiles[i].original_path.split('/'));
      let cjoinedArray1 = cpatharray1.slice(2,cte.split("/",100).length+2).join("/");
      if(cjoinedArray1 == chekcontain) {
         indexid=(chekcontain.split("/",100).length);
        if((this.allFiles[i].original_path.indexOf(chekcontain)!== -1)) {
          this.deleteid.push(this.allFiles[i].id)
          jQuery('#deletemodal').modal("hide");
        }
      }
    }
    if((this.cemptyfolder!==undefined)||(this.cemptyfolder!=='')) {
      for(let k = 0; k < this.cemptyfolder.length; k++) {
        var ctef = chekcontain;
        var cpatharray = (this.cemptyfolder[k].folder_path.split('/'));
        var cjoinedArray = cpatharray.slice(0,ctef.split("/",100).length).join("/");
        if(cjoinedArray == chekcontain) {
          if((this.cemptyfolder[k].folder_path.indexOf(chekcontain) !== -1)) {
            this.folderdeleteid.push(this.cemptyfolder[k].id);
            jQuery('#deletemodal').modal("hide");
          }
        }
      }
    }
    this.deletecombofunction();
  }

  deletecombofunction() {
    if (this.deleteid.length>0) {
      for(let order of this.allFiles) {
          for(let j = 0; j < this.deleteid.length;j++) {
            this.allFiles = this.allFiles.filter(order => order.id != this.deleteid[j]);
          }
       }
      this.resourceService.deletecfiles({'deletefileid':this.deleteid,'user_id':this.userid})
        .toPromise().then((response: any) => {
          //   console.log(response);
        })
    }

    if(this.folderdeleteid.length>0) {
      for(let item of this.cemptyfolder) {
        for(let i=0;i<this.folderdeleteid.length;i++) {
          this.cemptyfolder = this.cemptyfolder.filter(item => item.id != this.folderdeleteid[i]);
        }
      }
      this.resourceService.deletefolders({'deletefolderid':this.folderdeleteid}).toPromise()
       .then((response: any) => {})
    }
    this.renamecombofunction(0);
    this.folderdeleteid=[];
    this.deleteid=[];
 }

  renamecombofunction(id) {
    if (id==1) {
      this.toasts.success('Successfully Rename Folder');
    } else {
      this.toasts.success('Successfully Deleted Folder');
    }
    if (this.fullpath=='') {
      this.companyFiles=[];
      this.fullpath='';
      this.backfolder=[];
      this.TempFolder=[];
      for(let i=0; i<this.allFiles.length;i++) {
        if (((this.allFiles[i].original_path).split("/",100)).length < 4) {
         this.companyFiles.push(this.allFiles[i]);
        } else {
          var splittext = (this.allFiles[i].original_path).split("/",100)[2];
          if(this.TempFolder.includes(splittext) == false) {
             this.TempFolder.push((this.allFiles[i].original_path).split("/",100)[2]);
          }
        }
      }
      for(let j=0;j<this.cemptyfolder.length;j++) {
        let emsplittext =(this.cemptyfolder[j].folder_path).split("/",100)[0]
        if (this.TempFolder.includes(emsplittext) == false) {
         this.TempFolder.push((this.cemptyfolder[j].folder_path).split("/",100)[0]);
        }
      }
    } else {
      this.companyFiles = [];
      this.TempFolder   = [];
      for (let i=0; i<this.allFiles.length; i++) {
        let cte = this.fullpath;
        let cpatharray1   = (this.allFiles[i].original_path.split('/'));
        let cjoinedArray1 = cpatharray1.slice(2,cte.split("/",100).length+2).join("/");
        if (cjoinedArray1 == this.fullpath) {
          let indexid=(this.fullpath.split("/",100).length);
          if ((this.allFiles[i].original_path.indexOf(this.fullpath)!== -1)) {
            if ((((this.allFiles[i].original_path).split("/",100))
              .length-3) == (this.fullpath.split("/",100).length)) {
                this.companyFiles.push(this.allFiles[i]);
            } else {
              let splittext = (this.allFiles[i].original_path).split("/",100)[indexid+2];
              if (this.TempFolder.includes(splittext) == false) {
                this.TempFolder.push((this.allFiles[i].original_path).split("/",100)[indexid+2]);
              }
            }
          }
        }
      }
      for (let j=0; j<this.cemptyfolder.length; j++) {
        let ctef       = this.fullpath;
        let cpatharray = (this.cemptyfolder[j].folder_path.split('/'));
        let cjoinedArray = cpatharray.slice(0,ctef.split("/",100).length).join("/");
        if (cjoinedArray==this.fullpath) {
          if (this.cemptyfolder[j].folder_path.indexOf(this.fullpath)!==-1) {
            let indexid = (this.fullpath.split("/",100).length);
            let emsplittext = (this.cemptyfolder[j].folder_path).split("/",100)[indexid];
            if ((this.TempFolder.includes(emsplittext)==false)&&(emsplittext!==undefined)) {
                this.TempFolder.push((this.cemptyfolder[j].folder_path).split("/",100)[indexid]);
            }
          }
        }
      }
    }
    localStorage.setItem("folderpath",this.fullpath);
    jQuery("#renameModal").modal("hide");
  }
  // Rename Folder
  renamefoldermodal(name) {
     jQuery("#renameModal").modal("show");
     this.renamefolderName = name;
     this.folderNameforrename = name;
  }

  renamefolder() {
    let name = this.folderNameforrename;
    let chekcontain = '';
    if((this.renamefolderName != null) && (this.renamefolderName != '') && (this.renamefolderName != undefined)) {
        if((this.fullpath==null)||(this.fullpath=='')) {
           chekcontain = name;
        } else {
           chekcontain = this.fullpath.concat('/'+name);
        }
        let indexid = (chekcontain.split("/",100).length);
        for (let i=0;i<this.allFiles.length;i++) {
          let cte = chekcontain;
          let cpatharray1 = (this.allFiles[i].original_path.split('/'));
          let cjoinedArray1=cpatharray1.slice(2,cte.split("/",100).length+2).join("/");
          if (cjoinedArray1==chekcontain) {
            let indexid    = (chekcontain.split("/",100).length);
            let splittext  = (this.allFiles[i].original_path).split("/",100)[indexid+1]
            let splittext1 = ((this.allFiles[i].original_path).split("/",100))
            if((this.allFiles[i].original_path.indexOf(chekcontain)!== -1)) {
              this.renameid.push(this.allFiles[i].id);
              for (let j=0;j<splittext1.length;j++) {
                if (j==indexid+1) {
                  splittext1[j]=this.renamefolderName.trim();
                }
              }
              this.allFiles[i].original_path=splittext1.join('/');
            }
           }
        }
        if( this.renameid.length > 0) {
         this.resourceService.renameFoldersapi({'renameid':this.renameid,'renamefile':this.allFiles})
           .toPromise().then((response: any) => {})
        }
        if((this.cemptyfolder!==undefined) || (this.cemptyfolder!=='')) {
          for(let k=0; k<this.cemptyfolder.length; k++) {
            let ctef = chekcontain;
            let cpatharray = (this.cemptyfolder[k].folder_path.split('/'));
            let cjoinedArray = cpatharray.slice(0,ctef.split("/",100).length).join("/");
            if (cjoinedArray==chekcontain) {
              if ((this.cemptyfolder[k].folder_path.indexOf(chekcontain)!== -1)) {
                  this.folderrenameid.push(this.cemptyfolder[k].id);
                  let splittext2 = ((this.cemptyfolder[k].folder_path).split("/",100));
                  for (let l=0; l<splittext2.length; l++) {
                    if (l == indexid - 1) {
                      splittext2[l] = this.renamefolderName.trim();
                    }
                    this.cemptyfolder[k].folder_path = splittext2.join('/');
                  }
                }
             }
          }
        }
        if (this.folderrenameid.length > 0) {
          this.resourceService
            .renameFolderstable({'renameidsfolder':this.folderrenameid,'renamepath':this.cemptyfolder})
            .toPromise().then((response: any) => {})
        }
    } else {
      document.getElementById("rename-text").style.borderColor = "red";
    }
    this.renamecombofunction(1);
  }

  /* Move Folder  or Move files */
  htmlgeneration(str) {
    if((str !== undefined) && (str !== null)) {
      str = str.replace(/\s/g,"_");
      return str;
    }
  }
  checkValue(event: any,id) {
    if (this.fullpath == undefined || this.fullpath == '') {
      this.checkpath = id;
      this.checkindexid = 0;
    } else {
      this.checkpath=(this.fullpath).concat('/'+id);
      this.checkindexid=(this.fullpath).split("/",100).length;
    }
    if(event.currentTarget.checked == true) {
      for (let i=0; i < this.allFiles.length; i++) {
        let cte = this.checkpath;
        let cpatharray1 = (this.allFiles[i].original_path.split('/'));
        let cjoinedArray1 = cpatharray1.slice(2,cte.split("/",100).length+2).join("/");
        if (cjoinedArray1 == this.checkpath) {
          if((this.allFiles[i].original_path.indexOf(this.fullpath)!== -1)) {
            if((this.allFiles[i].original_path.indexOf(this.checkpath)!== -1)) {
              this.fileoffolderid.push(this.allFiles[i].id);
              this.fileoffolderidanother.push({"id":this.allFiles[i].id,'foldername':id});
            }
          }
        }
      }
      for(let j=0; j<this.cemptyfolder.length; j++) {
        let ctef = this.checkpath;
        let cpatharray=(this.cemptyfolder[j].folder_path.split('/'));
        let cjoinedArray =cpatharray.slice(0,ctef.split("/",100).length).join("/");
        if(cjoinedArray==this.checkpath) {
          if(this.cemptyfolder[j].folder_path.indexOf(this.checkpath)!=-1) {
              this.folderoffolderid.push(this.cemptyfolder[j].id)
              this.folderoffolderidanother.push({'id':this.cemptyfolder[j].id,'foldername':id})
          }
        }
      }
      if(this.fullpath==undefined||this.fullpath=='') {
        this.checkboxstore.push({'index':0,"fodername":this.htmlgeneration(id)})
      } else {
        this.checkboxstore.push({'index':(this.fullpath).split("/",100).length,"fodername":this.htmlgeneration(id)})
      }
    } else {
      for(let i=0; i<this.allFiles.length; i++) {
        let cte = this.checkpath;
        let cpatharray1 = (this.allFiles[i].original_path.split('/'));
        let cjoinedArray1 = cpatharray1.slice(2,cte.split("/",100).length+2).join("/");
        if (cjoinedArray1 == this.checkpath) {
          if((this.allFiles[i].original_path.indexOf(this.fullpath)!== -1)) {
            if((this.allFiles[i].original_path.indexOf(this.checkpath)!== -1)) {
                this.fileoffolderid.splice(this.fileoffolderid.indexOf(this.allFiles[i].id), 1);
                this.fileoffolderidanother.splice(this.fileoffolderidanother
                  .indexOf(this.allFiles[i].id), 1);
            }
          }
        }
      }
     for(let j=0; j<this.cemptyfolder.length; j++) {
        let ctef = this.checkpath;
        let cpatharray = (this.cemptyfolder[j].folder_path.split('/'));
        let cjoinedArray = cpatharray.slice(0,ctef.split("/",100).length).join("/");
        if (cjoinedArray == this.checkpath) {
          if (this.cemptyfolder[j].folder_path.indexOf(this.checkpath) !== -1) {
              this.folderoffolderid.splice(this.folderoffolderid.indexOf(this.cemptyfolder[j].id), 1);
              this.folderoffolderidanother.splice(this.folderoffolderidanother
                .indexOf(this.cemptyfolder[j].id), 1);
          }
        }
      }
      for(let k=0; k<this.checkboxstore.length; k++) {
        if((this.checkindexid==this.checkboxstore[k].index)&&(this.htmlgeneration(id)==this.checkboxstore[k].fodername)) {
            this.checkboxstore.splice(this.checkboxstore.indexOf(this.checkboxstore[k].fodername),1);
        }
      }
    }
    this.foldercount=this.checkboxstore.length;
  }

  callFuntionAtIntervals() {
      let id;
      if((this.fullpath=='') || (this.fullpath==null)) {
        id = this.fullpath.split("/",100).length-1
      } else {
         id = this.fullpath.split("/",100).length
      }
      for(let i=0;i<this.checkboxstore.length;i++) {
        if(this.checkboxstore[i].index===id) {
           $("#"+this.htmlgeneration(this.checkboxstore[i].fodername)).prop('checked',true);
        } else {
          $("#"+this.htmlgeneration(this.checkboxstore[i].fodername)).prop('checked',false);
        }
      }
  }
  // move all files to folder
  moveAllFiletofolder(event){
    for(let i=0;i<this.companyFiles.length;i++) {
      if(event.target.checked) {
        this.companyFiles[i].created_at = true;
        this.moveid.push(this.companyFiles[i].id);
       }else {
        this.companyFiles[i].created_at=false;
        this.moveid.splice(this.moveid.indexOf(this.companyFiles[i].id), 1);
       }
       this.countfile =this.moveid.length;
   } 
  }

  moveFiletofolder(file) {
    this.movestatus = true;
    this.countfile = this.moveid.length;
    for(let i=0;i<this.companyFiles.length;i++) {
       if(this.companyFiles[i].id==file.id) {
         if(this.companyFiles[i].created_at!==true) {
          this.companyFiles[i].created_at=true;
          this.moveid.push(this.companyFiles[i].id);
         }else {
          this.companyFiles[i].created_at=false;
          this.moveid.splice(this.moveid.indexOf(this.companyFiles[i].id), 1);
         }
       }
       this.countfile=this.moveid.length;
    }
  }

  movesto() {
      this.movestatus=false;
      this.companyFiles=[];
      this.fullpath='';
      this.backfolder=[];
      this.TempFolder=[];
      for ( let i=0;i<this.allFiles.length;i++) {
        if (((this.allFiles[i].original_path).split("/",100)).length<4) {
          this.companyFiles.push(this.allFiles[i])
        } else {
          var  splittext=(this.allFiles[i].original_path).split("/",100)[2]
          if(this.TempFolder.includes(splittext)==false) {
            this.TempFolder.push((this.allFiles[i].original_path).split("/",100)[2]);
          }
        }
      }
      //Empty Folder
      for(let j=0; j<this.cemptyfolder.length; j++) {
        let emsplittext = (this.cemptyfolder[j].folder_path).split("/",100)[0]
        if(this.TempFolder.includes(emsplittext) == false) {
          this.TempFolder.push((this.cemptyfolder[j].folder_path).split("/",100)[0]);
        }
      }
      this.arr_name=[];
      for(let i = 0; i<this.cemptyfolder.length; i++) {
         if(this.folderoffolderid.indexOf(this.cemptyfolder[i].id) > -1) {
         } else {
           let splitt = (this.cemptyfolder[i].folder_path).split("/",100);
           this.arr_name[i] = []
           for(let j=0;j<splitt.length;j++) {
             this.arr_name[i][j]=splitt[j];
           }
        }
      }
      this.arr_name=this.arr_name.filter(Boolean)
  }

  clearallchek() {
    this.companyFiles=[];
    this.fullpath='';
    this.backfolder=[];
    this.TempFolder=[];
    this.moveid=[];
    this.checkboxstore=[];
    this.foldercount=0;
    $('input:checkbox').prop('checked',false);
    this.fileoffolderidanother=[];
    this.fileoffolderid=[];
    this.folderoffolderid=[];
    this.folderoffolderidanother=[];
    this.countfile=this.moveid.length;
    for(let i=0; i<this.allFiles.length; i++) {
      this.allFiles[i].created_at=false;
      if(((this.allFiles[i].original_path).split("/",100)).length<4) {
       this.companyFiles.push(this.allFiles[i])
      } else {
        var  splittext = (this.allFiles[i].original_path).split("/",100)[2]
        if(this.TempFolder.includes(splittext)==false) {
           this.TempFolder.push((this.allFiles[i].original_path).split("/",100)[2]);
        }
      }
    }
    //Empty Folder
    for(let j=0;j<this.cemptyfolder.length;j++) {
      //Temp
       var  emsplittext =(this.cemptyfolder[j].folder_path).split("/",100)[0]
       if(this.TempFolder.includes(emsplittext)==false) {
         this.TempFolder.push((this.cemptyfolder[j].folder_path).split("/",100)[0]);
       }
    }
  }

  hidemovetomnodal() {
      jQuery('#movestomodal').modal('hide');
  }

  movehides(j) {
     this.structure1=true;
     this.structure2=true;
     this.showid=1000;
  }

  moveassigne(j) {
    this.structure1=true;
    this.structure2=false;
    this.showid=j
  }

  moveshere(mainitem) {
    if((mainitem=='')||(mainitem==null)) {
      this.fullpath='';
      jQuery("#movestomodal").modal("hide");
    } else {
      this.fullpath=(mainitem.join('/'));
      jQuery("#movestomodal").modal("hide");
    }
    this.movehere();
  }

  movehere() {
    this.movestatus=true;
    let i=0;let j=0;
    for(let i=0;i<this.allFiles.length;i++) {
      this.allFiles[i].created_at=false;
      for(let j=0;j<this.moveid.length;j++) {
        if(this.moveid[j]==this.allFiles[i].id) {
          let  splittext=(this.allFiles[i].original_path).split("/",100)[0]
          let  splittext1=(this.allFiles[i].original_path).split("/",100)[1]
            if(this.fullpath=='') {
              var newpath=splittext+'/'+splittext1+'/'+this.allFiles[i].title;
            }else{
              var newpath=splittext+'/'+splittext1+'/'+this.fullpath+'/'+this.allFiles[i].title;
            }
           this.allFiles[i].original_path=newpath;
          this.companyFiles=[];
          this.TempFolder=[];
          let k=0;
          for(k=0;k<this.allFiles.length;k++) {
            var indexid=(this.fullpath.split("/",100).length);
            if((this.allFiles[k].original_path.indexOf(this.fullpath)!== -1)) {
              if(((this.allFiles[k].original_path).split("/",100))
                .length-3==(this.fullpath.split("/",100).length)) {
                  this.companyFiles.push(this.allFiles[k])
              } else {
                splittext=(this.allFiles[k].original_path).split("/",100)[indexid+2]
                if(this.TempFolder.includes(splittext)==false) {
                  this.TempFolder.push((this.allFiles[k].original_path).split("/",100)[indexid+2]);
                }
              }
            }
          }
          for(let l=0; l<this.cemptyfolder.length; l++) {
            if(this.cemptyfolder[l].folder_path.indexOf(this.fullpath)!==-1) {
              var indexid=(this.fullpath.split("/",100).length);
              var emsplittext=(this.cemptyfolder[l].folder_path).split("/",100)[indexid]
              if((this.TempFolder.includes(emsplittext)==false)&&(emsplittext!==undefined)) {
                this.TempFolder.push((this.cemptyfolder[l].folder_path).split("/",100)[indexid]);
              }
            }
          }
        }
      }
    }
    if(this.moveid.length>0) {
      this.resourceService.renameFoldersapi({'renameid':this.moveid,'renamefile':this.allFiles})
        .toPromise().then((response: any) => {
        this.alertMessage(response);
        jQuery("#moveModal").modal("hide");
        jQuery("#movestomodal").modal("hide");
        this.clearallchek();
        this.moveid=[];
      })
    }
    //
    this.movefoldertofolderhere();
  }

  alertMessage(data : any) {
    switch (data.Status) {
      case 'success':
        this.toasts.success(data.message);
        break;
      case 'error':
        this.toasts.error(data.message);
        break;
    }
  }

  movefoldertofolderhere() {
    this.movestatus=true;
    let i=0;let j=0;let x=0;let y=0; let z=0;
    if(this.fileoffolderid.length>0) {
      for(i=0;i<this.allFiles.length;i++) {
        for(j=0;j<this.fileoffolderidanother.length;j++) {
          if(this.fileoffolderidanother[j].id==this.allFiles[i].id) {
            var  splittext=(this.allFiles[i].original_path).split("/",100)[0]
            var  splittext1=(this.allFiles[i].original_path).split("/",100)[1]
            var result = this.allFiles[i].original_path.substr(this.allFiles[i].original_path.indexOf("/") +1);
            var result1 = result.substr(result.indexOf("/") +1);
            if(this.fullpath=='') {
                let power=((this.allFiles[i].original_path).split("/",100));
                var index1 = power.indexOf(this.fileoffolderidanother[j].foldername);
                var newpath=splittext+'/'+splittext1+'/'+power.slice(index1, power.length).join("/");
            } else {
                let power=((this.allFiles[i].original_path).split("/",100));
                var index1 = power.indexOf(this.fileoffolderidanother[j].foldername);
                var newpath=splittext+'/'+splittext1+'/'+this.fullpath+'/'+power.slice(index1, power.length).join("/");
            }
            //console.log(newpath);
            this.allFiles[i].original_path=newpath;
            this.companyFiles=[];
            this.TempFolder=[];
            let k=0;
            for(k=0;k<this.allFiles.length;k++) {
              var indexid=(this.fullpath.split("/",100).length);
              if((this.allFiles[k].original_path.indexOf(this.fullpath)!== -1)) {
                if(((this.allFiles[k].original_path).split("/",100)).length-3==(this.fullpath.split("/",100).length)) {
                    this.companyFiles.push(this.allFiles[k])
                } else {
                  var splittext=(this.allFiles[k].original_path).split("/",100)[indexid+2]
                  if(this.TempFolder.includes(splittext)==false) {
                    this.TempFolder.push((this.allFiles[k].original_path).split("/",100)[indexid+2]);
                  }
                }
              }
            }
            var l=0
            for(l=0;l<this.cemptyfolder.length;l++) {
              if(this.cemptyfolder[l].folder_path.indexOf(this.fullpath)!==-1) {
                  /* this.fileoffolderid
                    this.folderoffolderid */
                var indexid=(this.fullpath.split("/",100).length);
                var emsplittext=(this.cemptyfolder[l].folder_path).split("/",100)[indexid]
                if((this.TempFolder.includes(emsplittext)==false)&&(emsplittext!==undefined)) {
                  this.TempFolder.push((this.cemptyfolder[l].folder_path).split("/",100)[indexid]);
                }
              }
            }
          }
        }
      }
    }
    if(this.folderoffolderid.length > 0) {
      for(let x=0;x<this.cemptyfolder.length; x++) {
        for(let y=0; y<this.folderoffolderidanother.length; y++) {
          if(this.folderoffolderidanother[y].id==this.cemptyfolder[x].id) {
            var newpath1 = '';
            let index = '';
            if(this.fullpath == '') {
              let power = ((this.cemptyfolder[x].folder_path).split("/",100));
               index = power.indexOf(this.folderoffolderidanother[y].foldername);
              newpath1=(power.slice(index, power.length).join("/"));
            } else {
              let power = ((this.cemptyfolder[x].folder_path).split("/",100));
                 index = power.indexOf(this.folderoffolderidanother[y].foldername);
              newpath1 = this.fullpath+'/'+(power.slice(index, power.length).join("/"));
            }
           this.cemptyfolder[x].folder_path = newpath1;
        }
       }
     }
    }
   if (this.fileoffolderid.length>0) {
        this.resourceService.renameFoldersapi({'renameid':this.fileoffolderid,'renamefile':this.allFiles})
          .toPromise().then((response: any) => {
            jQuery("#moveModal").modal("hide");
            this.clearallchek();
            this.fileoffolderid=[]
            this.fileoffolderidanother=[]
            this.checkboxstore=[];
        })
      }
     if(this.folderoffolderid.length>0) {
        this.resourceService
          .renameFolderstable({'renameidsfolder':this.folderoffolderid,'renamepath':this.cemptyfolder})
          .toPromise().then((response: any) => {
            jQuery("#moveModal").modal("hide");
            this.clearallchek();
            this.folderoffolderid=[]
            this.folderoffolderidanother=[]
            this.checkboxstore=[]
          })
      }
  }

  preventDefault(list) {
    this.list = list != true;

  }

  refreshfunction() {
    if(this.fullpath!='') {
        this.backfolder=this.fullpath.split("/",100);
        this.companyFiles=[];
        this.TempFolder=[];
        for(let i=0;i<this.allFiles.length;i++) {
          var cte=this.fullpath;
          var cpatharray1=(this.allFiles[i].original_path.split('/'));
          var cjoinedArray1=cpatharray1.slice(2,cte.split("/",100).length+2).join("/");
          if(cjoinedArray1==this.fullpath) {
            if((this.allFiles[i].original_path.indexOf(this.fullpath)!== -1)) {
              var indexid=(this.fullpath.split("/",100).length);
              if(((this.allFiles[i].original_path)
                .split("/",100)).length-3==(this.fullpath.split("/",100).length)) {
                  this.companyFiles.push(this.allFiles[i])
              } else {
                var splittext=(this.allFiles[i].original_path).split("/",100)[indexid+2]
                if(this.TempFolder.includes(splittext)==false) {
                  this.TempFolder.push((this.allFiles[i].original_path).split("/",100)[indexid+2]);
                }
              }
           }
          }

          for(let j=0;j<this.cemptyfolder.length;j++) {
            var ctef=this.fullpath;
            var cpatharray=(this.cemptyfolder[j].folder_path.split('/'));
            var cjoinedArray =cpatharray.slice(0,ctef.split("/",100).length).join("/");
            if(cjoinedArray==this.fullpath) {
              if(this.cemptyfolder[j].folder_path.indexOf(this.fullpath)!==-1) {
                var emindexid=(this.fullpath.split("/",100).length);
                var emsplittext=(this.cemptyfolder[j].folder_path).split("/",100)[emindexid]
                if((this.TempFolder.includes(emsplittext)==false)&&(emsplittext!==undefined)) {
                  this.TempFolder.push((this.cemptyfolder[j].folder_path).split("/",100)[emindexid]);
                }
               }
            }
          }
        }
      } else {
        this.structure1=true;
        this.onlyevent=true;
        this.structuree=false
        this.fullpath='';
        this.backfolder=[];
        this.foldernametoshow='Home';
        this.companyFiles=[];
        this.TempFolder=[];
        for(let i=0;i<this.allFiles.length;i++) {
            if(((this.allFiles[i].original_path).split("/",100)).length<4) {
              this.companyFiles.push(this.allFiles[i])
            } else {
              var splittext = (this.allFiles[i].original_path).split("/",100)[2]
              if(this.TempFolder.includes(splittext)==false) {
                this.TempFolder.push((this.allFiles[i].original_path).split("/",100)[2]);
              }
            }
        }
        for(let i=0;i<this.cemptyfolder.length;i++) {
            var  splittext1=(this.cemptyfolder[i].folder_path).split("/",100)[0]
            if(this.TempFolder.includes(splittext1)==false) {
                    this.TempFolder.push((this.cemptyfolder[i].folder_path).split("/",100)[0]);
            }
         }
      }
   }

  generatezip() {
     this.zipProcessing=true;
     var file=this.allFiles
     this.urls=[]
     this.anotherdata=[]
     this.toasts.success('Your case documents are being prepared for download. If the total size of the documents in this library exceeds 150 MB,' +
       ' multiple ZIP files of your case documents will be created.');
     this.toasts.success('We\'re currently preparing your documents. You can keep using another task in the mean time');
    let item = 1;
    let limitSize = 2500;
    let size = 0;
    for(let i=0;i<file.length;i++) {
      if(this.callid.indexOf(file[i].id) > -1) {
        size = size + Number.parseFloat(file[i].size)
        var test = this._sanitizer
          .bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/' + file[i].encrypt_id)
        var myString=file[i].original_path;
        myString = myString.substring(myString.indexOf('/')+1)
        myString = myString.substring(myString.indexOf('/')+1)
        this.anotherdata.push(myString)
        this.urls.push({'id':file[i].id,'url':file[i].dummy_path,'encriptedpath':test['changingThisBreaksApplicationSecurity'],'path':myString})
      }
      if (size > limitSize) {
        this.createZip(this.urls, item)
        this.urls = []
        size = 0
        item ++;
      }
    }
    jQuery(".totat_zip_size").html('Total zip size is: '+ size.toFixed(2)  + ' MB');
    this.createZip(this.urls, 'last')
   }

  /**
   * Сreate Zip
   * @param urls
   * @param item
   */
   createZip(urls, item){

     var zip = new JSZip();
     var url=urls;
     this.sss=[];
     for(let i=0;i<url.length;i++) {
       let dataRes = this.urlToPromise(url[i].encriptedpath);
       if (dataRes != null) {
         // @ts-ignore
         this.sss.push({'test':url[i].id,'zip':zip.file(url[i].path, dataRes.then((value) => {return  value }),
             {binary: true})});
       }
     }
     let self=this;
     var ss = this.sss[this.sss.length-1];
     if (ss === undefined) {
       this.zipProcessing=false;
       this.toasts.error('Files are not available or do not exist');
       return false;
     }
     ss = ss.zip;
     ss.generateAsync({type:"blob",compression: "DEFLATE",compressionOptions: {level:9}})
       .then((content) => {
         saveAs(content,'CompanyFileLibrary_' + item + '.zip');
         if(item === 'last'){
           self.successdownload()
         } else {
           this.toasts.success('The ZIP file of your case documents has been downloaded successfully. We\'re currently preparing your documents for the next download, please wait.');
           this.zipProcessing = false;
         }
       }).catch((error) => {
       if (error.status == 422) {
         let errorData: any = error.error;
         this.zipProcessing = false;
         console.log(errorData);
       } else { 
        this.toasts.error(error); 
        this.zipProcessing = false;
      }});
   }

  successdownload() {
     this.toasts.success('Your case documents have been downloaded successfully.');
     this.toasts.success('Documents will be downloaded shortly. You can continue with your work.');
     this.zipProcessing = false;
   }

  urlToPromise(url) {
    return new Promise(function(resolve, reject) {
      JSZipUtils.getBinaryContent(url, function (err, data) {
          if(err) {
              reject(err);
          } else {
              resolve(data);
          }
      });
    });
  }

 getSafeResourceUrl() {
  if (this.imageGot == true) {
    return this.popupImage;
  }
    if(this.file.type=='image') {
      this.imageGot = true;
      this.popupImage =  this._sanitizer.bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/' + this.file.encrypt_id);
    } else {
      this.imageGot = true;
      this.popupImage =  this._sanitizer.bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/' + this.file.encrypt_id);
    }
    return this.popupImage;
  }

  getSafeResourceUrlPdf() {
    return this._sanitizer
      .bypassSecurityTrustResourceUrl('https://drive.google.com/viewerng/viewer?embedded=true&url='
        + this.apiPath + 'filesystem/' + this.file.encrypt_id);
  }

  closePreviewer() {
    jQuery('#preview-modal').modal('hide'); 
    this.width = 570;
    this.height = 390;
    this.left = 0;
    this.top = 0;
    this.file = undefined;

    jQuery('#files').removeAttr( "style");
    jQuery('.tab-content').removeAttr( "style");
    
    if (this.file.type == 'video') {
      let file = this.file;
      this.file = new FileModel();
      this.ref.detectChanges();
      this.file = file;
    }
  }

//   zoomPreviewToggle() {
//     if (screenfull && typeof screenfull.on !== "undefined") {
//         screenfull.toggle();
//     } else if(screenfull === false && navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null && !this.fullIpad) {
//       this.fullIpad = true;
//       this.fullscreen = true;
//       setTimeout( ()=>{
//         jQuery('#preview-modal').find('.zoom_active').css({'position': 'fixed',
//           'display': 'inline-grid', 'left': '-35px', 'top': '-100px', 'width': '110%', 'max-height': '160%',
//           'min-height': '102%'});
//         jQuery('#preview-modal').find('.zoom_deactivate').css({'position': 'fixed',
//           'display': 'inline-grid', 'left': '-35px', 'top': '-100px', 'width': '110%', 'max-height': '160%',
//           'min-height': '102%'});
//         let height = jQuery(window).height();
//         if (height < 750) {
//           height = height - 50;
//         } else if (height >= 750 && height < 900) {
//           height = height - 200;
//         } else {
//           height = height - 170;
//         }
//         jQuery('#preview-modal').find('.zoom_active').find('.modal-content').css({ 'height': height + 'px'});
//       }, 200)
//     } else if(screenfull === false && navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null && this.fullIpad) {
//       this.fullIpad = false;
//       this.fullscreen = false;
//       jQuery('#preview-modal').find('.modal-preview').removeAttr( "style" );
//       jQuery('#preview-modal').find('.zoom_deactivate').attr("style","");
//       jQuery('#preview-modal').find('.modal-preview').find('.modal-content').removeAttr( "style" );
//     }
//  }
  showdescription(i,desc) {
   if(desc !== ''){
    $('#desc_'+i).show();
    $('#descdiv_'+i).hide();
    $('#descdivclose_'+i).show();
   }
  }

  closedescription(i,desc) {
     if(desc !== null) {
        $('#desc_'+i).hide();
        $('#descdiv_'+i).show();
        $('#descdivclose_'+i).hide();
     }
  }

  hidedescription(i) {
    $('#desc_'+i).hide();
    $('#descdiv_'+i).show();
    $('#descdivclose_'+i).hide();
  }

  modelAction() {
    $('bs-modal-container').addClass('show-midal-element');
  }

  modelClose() {
    $('body').removeClass('modal-open');
  }
  //pagination
  pageChanged(data: any){
    this.page = data
    return this.page;
  }

  changePages(data: any){
    this.perPage = data.target.value;
  }

  getSortIcon(column: string) {
    return this._sanitizer.bypassSecurityTrustHtml('<i class="fa fa-sort' +
      (column == this.orderCol ? '-' + (this.reverse ? 'asc' : 'desc') : '')  +'" aria-hidden="true"></i>');
  }

  reorder(column: string) {
    this.currentStatus = column;
    this.orderCol = column;
    if (column == this.order) {
      this.orderValue = this.orderValue === 'DESC' ? 'ASC' : 'DESC';
      if(this.companyFiles !== undefined) {
        this.orderValue === 'DESC' ? this.companyFiles.sort((a, b) => (a[column] > b[column]) ? 1 : -1)
          : this.companyFiles.sort((a, b) => (a[column] < b[column]) ? 1 : -1);
      }
      this.reverse = ! this.reverse;
    } else {
      this.orderValue = 'ASC';
      this.order = column;
      this.reverse = true;
      if(this.companyFiles !== undefined) {
        this.companyFiles.sort((a, b) => (a[column] < b[column]) ? 1 : -1)
      }
    }
  }

  /**
   * Function to sort alphabetically an array of objects by some specific key.
   *
   * @param {String} property Key of the object to sort.
   */
  dynamicSort(property) {
    let sortOrder = 1;

    if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a,b) {
      if(sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    }
  }

  addContactsToFile(dataFile){
    this.updated(dataFile);
  }

  private loadContainer(){
    const left = this.boxPosition.left - this.left;
    const top = this.boxPosition.top - this.top;
    const right = left + 570;
    const bottom = top + 390;
    this.containerPos = { left, top, right, bottom };
  }

  // setStatus(event: MouseEvent | TouchEvent, status: number){
  //   if(status === 1) event.stopPropagation();
  //   else if(status === 2) this.mouseClick = { x: event.clientX, y: event.clientY, left: this.left, top: this.top };
  //   else this.loadBox();
  //   this.status = status;
  // }
  // @HostListener('window:mousemove', ['$event'])
  // onMouseMove(event: MouseEvent){
  //   this.mouse = { x: event.clientX, y: event.clientY };
  //   if(this.status === Status.RESIZE) {
  //     this.resize()
  //   } else if (this.status === Status.MOVE){
  //     this.move();
  //   }
  // }
  setStatus(event: MouseEvent | TouchEvent, status: number) {
    if (status === 1) {
      event.stopPropagation();
    } else if (status === 2) {
      if (event instanceof MouseEvent) {
        this.mouseClick = { x: event.clientX, y: event.clientY, left: this.left, top: this.top };
      } else if (event instanceof TouchEvent) {
        const touch = event.touches[0];
        this.mouseClick = { x: touch.clientX, y: touch.clientY, left: this.left, top: this.top };
      }
    } else {
      this.loadBox();
    }
    this.status = status;
  }
  
  @HostListener('window:mousemove', ['$event'])
  @HostListener('window:touchmove', ['$event'])
  onMouseMove(event: MouseEvent | TouchEvent) {
    event.preventDefault();
    
    if (event instanceof MouseEvent) {
      this.mouse = { x: event.clientX, y: event.clientY };
    } else if (event instanceof TouchEvent) {
      const touch = event.touches[0];
      this.mouse = { x: touch.clientX, y: touch.clientY };
    }
    if (this.status === Status.RESIZE) {
      this.resize();
    } else if (this.status === Status.MOVE) {
      this.move();
    }
  }

  private resize(){
    this.loadBox();
    this.width = Number(this.mouse.x > this.boxPosition.left) ? this.mouse.x - this.boxPosition.left : 0;
    this.height = Number(this.mouse.y > this.boxPosition.top) ? this.mouse.y - this.boxPosition.top : 0;
  }

  private move(){
    this.left = this.mouseClick.left + (this.mouse.x - this.mouseClick.x);
    this.top = this.mouseClick.top + (this.mouse.y - this.mouseClick.y);
  }
  // if (status === 1) {
  //   event.stopPropagation();
  // } else if (status === 2) {
  //   if (event instanceof MouseEvent) {
  //     this.mouseClick = { x: event.clientX, y: event.clientY, left: this.left, top: this.top };
  //   } else if (event instanceof TouchEvent) {
  //     const touch = event.touches[0];
  //     this.mouseClick = { x: touch.clientX, y: touch.clientY, left: this.left, top: this.top };
  //   }
  // } else {
  //   this.loadBox();
  // }
  private loadBox(){
    const {left, top} = this.previewModal3.nativeElement.getBoundingClientRect();
    this.boxPosition = {left, top};
  }

}
