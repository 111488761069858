export class DefaultTransformer
{
  errorsBlock: Element;

  constructor(public renderer, public errors, public element: Element)
  {
    this.addErrorsToElement();
    this.getErrorsBlock();
    this.prepareErrorsBlock();
    this.addErrorsToErrorBlock();
  }

  private getErrorsBlock(): void
  {
    let errorsBlock = this.element.querySelector('div');

    if (! errorsBlock) {
      this.errorsBlock = this.renderer.createElement(this.element, 'div');
    } else {
      this.errorsBlock = errorsBlock;
    }
  }

  private prepareErrorsBlock(): void
  {
    this.errorsBlock.innerHTML = '';
    this.renderer.setElementClass(this.errorsBlock, 'form-control-feedback', true);
  }

  private addErrorsToErrorBlock(): void
  {
    if (this.errors) {
      for (let error of this.errors) {
        var dom = 'span';
        if (this.element.getAttribute('hub-small')) {
          dom = 'small';
        }

        let span = this.renderer.createElement(this.errorsBlock, dom);
        this.renderer.createText(span, error);
      }
    }
  }

  private addErrorsToElement()
  {
    let input = this.element.querySelector('input');

    this.renderer.setElementClass(this.element, 'has-danger', this.errors);
    this.renderer.setElementClass(input, 'form-control-danger', this.errors);
  }
}
