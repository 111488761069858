import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {CookieModule } from 'ngx-cookie';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {IndexModule} from "./index/index.module";
import {AuthModule} from "./auth/auth.module";
import {ToastrModule} from 'ngx-toastr';
import {AuthService} from "./auth/shared/auth.service";
import {MainModule} from "./main/main.module";
import {RestService} from "./rest/shared/rest.service";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {SharedComponentsModule} from "./shared-components/shared-components.module";
import {SharedService} from "./shared/shared.service";
import {LayoutsModule} from "./layouts/layouts.module";
import {PresentModule} from "./present/present.module";
import {AdminModeComponent} from "./admin-mode/admin-mode.component";
import {StorageServiceModule} from 'ngx-webstorage-service';
import {EventsResourceService} from "./main/trialline/events/shared/events-resoruce.service";
import {RecaptchaModule} from "ng-recaptcha";
import {CKEditorModule} from 'ckeditor4-angular';
import { SpellCheckerModule } from 'ngx-spellchecker';
import { SpellCheckerService } from 'ngx-spellchecker';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import {NgxDropzoneModule } from "ngx-dropzone"

@NgModule({
  declarations: [
    AppComponent,
    AdminModeComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    IndexModule,
    AuthModule,
    MainModule,
    SharedComponentsModule,
    LayoutsModule,
    PresentModule,
    CookieModule.forRoot(),
    RecaptchaModule,
    CKEditorModule,
    StorageServiceModule,
    SpellCheckerModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    NgxDropzoneModule
  ],
  providers: [
    AuthService,
    RestService,
    SharedService,
    EventsResourceService,
    SpellCheckerService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
