import {Component, forwardRef, Inject, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {UserProfile} from "../user.model";
import {MainComponent} from "../../../../layouts/main/main.component";
import {View} from "../../../shared/view.model";
import {Category} from "../../../shared/category.model";

@Component({
  selector: 'app-profile-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class ProfileSidebarComponent implements OnInit
{
  @Input() user: UserProfile;
  uncollapsed = false;
  constructor(
    @Inject(forwardRef(() => MainComponent)) public _layout: MainComponent,
    public router: Router
  ) {
    
  }
  
  ngOnInit() {
    this.uncollapsed = false;
  }

  isActiveRoute(routename: string): boolean{    
    return this.router.url.indexOf(routename) > -1;
  }
  
  onMenuClick() {
    this.uncollapsed = !this.uncollapsed;
  }
}
