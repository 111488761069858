export class InlineTransformer
{
  errorsBlock: Element;

   constructor(public renderer, public errors, public element: Element)
  {
    this.addErrorsToElement();
    this.getErrorsBlock();
    this.prepareErrorsBlock();
    this.addErrorsToErrorBlock();
  }

  private getErrorsBlock(): void
  {
    let errorsBlock = this.element.querySelector('div.help-block');

    if (! errorsBlock) {
      this.errorsBlock = this.renderer.createElement('div');
      this.renderer.appendChild(this.element, this.errorsBlock);
    } else {
      this.errorsBlock = errorsBlock;
    }
  }

  private prepareErrorsBlock(): void
  {
    this.errorsBlock.innerHTML = '';
    this.renderer.addClass(this.errorsBlock, 'help-block');
  }

  private addErrorsToErrorBlock(): void
  {
    if (this.errors) {
      for (let error of this.errors) {
        var dom = 'span';
        if (this.element.getAttribute('hub-small')) {
          dom = 'small';
        }

      //  let span = this.renderer.createElement(this.errorsBlock, dom);
        let span = this.renderer.createElement(dom);
        let txt = this.renderer.createText(span);
        this.renderer.appendChild(span, txt);
        this.renderer.appendChild(this.element, txt);
      }
    }
  }

  private addErrorsToElement()
  {
    let input: any = this.element.querySelector('input');
    if (input == null) input = this.element.querySelector('textarea');
    this.renderer.addClass(this.element, 'has-error', this.errors);
    this.renderer.addClass(input, 'form-control-danger', this.errors);
  }
}
