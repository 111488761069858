import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, Output, Input} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {View} from "../main/shared/view.model";
import {Trialline} from "../main/shared/trialline.model";
import {Event} from "../main/shared/event.model";
import {PresentResourceService} from "./shared/present-resoruce.service";
import {ToastrService} from 'ngx-toastr';
import {NgForm} from "@angular/forms";
import {Category} from "../main/shared/category.model";
import {TriallineEventsComponent} from "../main/trialline/events/events.component";
import {TriallineMobileComponent} from "../shared-components/trialline-mobile/trialline-mobile.component";
import {ViewsResourceService} from "../main/trialline/views/shared/views-resoruce.service";
import {SharedService} from 'app/shared/shared.service';
import {env} from "../../../.env";
import {SEOService} from '../services/seo.service';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';

declare var jQuery: any;
declare var _: any;
declare var moment: any;

@Component({
  selector: 'app-present',
  templateUrl: './present.component.html',
  styleUrls: ['./present.component.scss']
})
export class PresentComponent implements OnInit, AfterViewInit, OnDestroy {

  errors: any = {};
  token: string;
  apiUrl = env.apiUrl;
  publicPathUrl = env.applicationUrl;
  trialline: Trialline;
  events: Event[] = [];
  trackEvents: Event[] = [];
  shownEvents: Event[] = [];
  view: View;
  disabled: boolean = false;
  eventsIdsOrder: number[] = [];
  simpleView: number = 0;
  showTracks: boolean = true;
  IsExportHidden = true;
  share: boolean = true;
  shareId: number;
  saveViewModal: boolean = false;
  shareView: boolean = false;
  modalId: string = '#save-view-modal';
  @ViewChild(TriallineMobileComponent) tlMobile: TriallineMobileComponent;
  event: Event;
  moreModal: boolean = false;
  moreModalId = '#event-more-modal';
  passwordModalId = '#password-modal';
  activeTab = 'description';
  tracks = [
    {
      name: 'Track 1',
      id: 'track1',
      filterCategory: '',
      filterValue: ''
    },
    {
      name: 'Track 2',
      id: 'track2',
      filterCategory: '',
      filterValue: ''
    },
    {
      name: 'Track 3',
      id: 'track3',
      filterCategory: '',
      filterValue: ''
    }
  ]
  uuid: string;
  processing: boolean = false;
  password: boolean = false;
  sub: any;
  passwordPhrase: string;
  categories: boolean = false;
  categoriesArray: Category[] = [];
  tagsarray = [];
  tagsid = [];
  tags: boolean;
  alldata: any;
  dummyshownEvents: Event[] = [];
  hiddentag: boolean;
  selectedcategories = [];
  hiddeneventcheckbox: boolean = false;
  allcatuniqid = [];
  metaDescription: any;
  

  constructor(
    private presentResourceService: PresentResourceService,
    private route: ActivatedRoute,
    private _changeDetectionRef: ChangeDetectorRef,
    private viewsResourceService: ViewsResourceService,
    private toasts: ToastrService,
    private seoService: SEOService,
    protected sharedService: SharedService,
    private titleService: Title, private metaService: Meta,
  ) {
  }

  ngOnInit() {
    
    $("#test").hide();
    setTimeout(()=>  $(".intercom-lightweight-app").hide(), 3100);
    setTimeout(()=>  {
    $(".intercom-lightweight-app").hide();
    $("#intercom-container").hide();
    }, 6000);
    this.route.data.subscribe((data: { view: View }) => {
      
      this.view = data.view;
      this.simpleView = data.view.view ? data.view.view : 0
      if (this.simpleView != 3) {
        this.showTracks = false;
      }
      if (this.view.trialline) {
        if (this.view.name) {
          this.seoService.updateTitle(this.view.name);
        }
        if (this.view.description) {
          this.seoService.updateDescription(this.view.description);
        }
        this.trialline = this.view.trialline;
        this.events = this.view.events;
        this.trackEvents = this.view.events_tracks;
        this.shownEvents = this.events;
        this.sharedService.eventdata = this.shownEvents;
        let categories: Category[] = [];
        for (let event of this.events) {
          let category = event.category;
          if(category){
            category.state = true;
            categories.push(category);
          }
        }
        this.tagsarray = [];
        var i = 0;
        for (let tagdata of this.events) {
          for (i = 0; i < tagdata.tag_name.length; i++) {
            tagdata.tag_name[i].status = true;
            if (this.tagsarray.some(x => x.tag_name === tagdata.tag_name[i].tag_name) == true) {
            } else {
              this.tagsarray.push(tagdata.tag_name[i])
              this.tagsid.push(tagdata.tag_name[i].id)
            }
            this.tagsarray.sort((a, b) => a.tag_name.localeCompare(b.tag_name));
          }
        }
        this.categoriesArray = (_.uniq(categories, (i) => {
          return i.id
        }));
        this.selectedcategories = this.getSelectedCategories();
        this.idsList();
        this.password = true;
        this.passwordPhrase = this.route.snapshot.queryParams['pass'];
        this.token = this.route.snapshot.queryParams['token'];
      } else if (this.view.public) {
        jQuery(this.passwordModalId).modal('hide');
        this.password = true;
        this.uuid = this.view.uuid;
        this.load('free');
      } else {
        this.password = false
      }
    });
    setTimeout(()=>  $(".intercom-lightweight-app").hide(), 9000);
    setTimeout(()=>  $("#intercom-container").hide(), 9000);
    this.sub = this.route.params.subscribe(params => {
      this.uuid = params["uuid"];
    });
    setTimeout(()=>  $("#test").hide(), 100);
  }

  toggleCategories() {
    this.categories = !this.categories;
    this.tags = false;
  }

  changedView(id) {
    this.disabled = true;
    if(id !== null && id !== undefined){
      this.simpleView = id;
    }
    setTimeout(() => {
      this.disabled = false;
    }, 2000);
  }

  changedCategories() {
    this.allcatuniqid = [];
    let selected = this.getSelectedCategories();
    this.selectedcategories = this.getSelectedCategories();
    if (selected.length == 0 && this.tagsid.length > 0) {
      this.hiddentag = true;
    } else {
      var j = 0
      for (let catid of this.events) {
        if (this.allcatuniqid.includes(catid.category_id) == false) {
          this.allcatuniqid.push(catid.category_id)
        }
      }
      if (this.allcatuniqid.length == selected.length) {
        this.hiddentag = false;
      } else {
        if (this.tagsid.length > 0) {
          for (let event of this.events) {
            if (event.category.state == false) {
              for (j = 0; j < event.tag_name.length; j++) {
                if (this.tagsid.includes(event.tag_name[j].id) == true) {
                  this.hiddentag = true;
                }
              }
            }
          }
        } else {
          this.hiddentag = false;
        }
      }
    }
    this.shownEvents = this.events.filter((event) => {
      return selected.indexOf(event.category_id) > -1;
    });
    this.dummyshownEvents = this.shownEvents;
    this.categorychangefunction()
    this._changeDetectionRef.detectChanges();
  }

  getSelectedCategories() {
    return _.pluck(this.categoriesArray.filter(
      (obj) => {
        return obj.state
      }), 'id')
  }

  all(ev) {
    this.categoriesArray.forEach(x => x.state = ev.target.checked)
    this.hiddeneventcheckbox = false;
    this.changedCategories();

  }

  isAllChecked() {
    return this.categoriesArray
      .every(c => c.state);
  }

  window() {
    return window;
  }

  idsList() {
    this.eventsIdsOrder = _.pluck(_.sortBy(this.events, function (item) {
      return new Date(item.date_start)
    }), 'id');
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
    this._changeDetectionRef.detach();
  };

  ngAfterViewInit() {
    if (!this.password) {
      jQuery(this.passwordModalId).modal('show');
    }
  }

  load(password) {
    $("#test").hide();
    this.presentResourceService.getView(this.uuid, password).toPromise()
      .then((view: View) => {
        
        if (view.trialline) {
          
          this.trialline = view.trialline;
          this.events = view.events;
          this.trackEvents = view.events_tracks;
          this.shownEvents = this.events;
          this.sharedService.eventdata = this.shownEvents;
          let categories: Category[] = [];
          for (let event of this.events) {
            let category = event.category;
            if (category) {
              category.state = true;
              categories.push(category);
            }
          }
          this.categoriesArray = (_.uniq(categories, (i) => {
            return i.id
          }));
          this.selectedcategories = this.getSelectedCategories();
          this.tagsarray = [];
          var i = 0;
          for (let tagdata of this.events) {

            for (i = 0; i < tagdata.tag_name.length; i++) {
              tagdata.tag_name[i].status = true;
              if (this.tagsarray.some(x => x.tag_name === tagdata.tag_name[i].tag_name) == true) {
              } else {
                this.tagsarray.push(tagdata.tag_name[i])
                this.tagsid.push(tagdata.tag_name[i].id)
              }
              this.tagsarray.sort((a, b) => a.tag_name.localeCompare(b.tag_name));
            }
          }
          this.idsList();
          this.password = true;
          this.passwordPhrase = password;

          console.log(view.trialline);
          if(view.trialline.metatags.description)
            this.metaDescription = view.trialline.metatags.description;
          else
            this.metaDescription = 'TrialLine is the only cloud-based legal timeline software made for attorneys. Organize and share case events and documents on any internet connected device.';
          
          this.titleService.setTitle(view.trialline.metatags.title);
          this.metaService.updateTag({name: 'description', content: this.metaDescription});

          this.metaService.addTag({property: 'og:description', content: this.metaDescription});
          this.metaService.updateTag({property: 'og:title', content: view.trialline.metatags.title});
          this.metaService.updateTag({property: 'og:image', content: view.trialline.metatags.image});
          
          this.metaService.updateTag({name: 'twitter:title', content: view.trialline.metatags.title});
          this.metaService.updateTag({name: 'twitter:description', content: this.metaDescription});
          this.metaService.updateTag({name: 'twitter:image', content: view.trialline.metatags.image});
          
        }
      })
      .catch((error) => {
        this.catchError(error);
      });
  }

  more(info) {
    this.activeTab = info.activeTab;
    this.processing = true;
    this.loadEvent(info.id);
  }

  loadEvent(event_id: number) {
    this.presentResourceService.getEvent(this.uuid, event_id, this.passwordPhrase).toPromise()
      .then((event: Event) => {
        this.moreModal = true;
        this.processing = false;
        this.event = event;
        this.event.date_start = TriallineEventsComponent.parseDate(this.event.date_start);
        if (this.event.date_end) {
          this.event.date_end = TriallineEventsComponent.parseDate(this.event.date_end);
        }
        this._changeDetectionRef.detectChanges();
        jQuery(this.moreModalId).modal('show');
      })
      .catch((error) => {
        this.moreModal = false;
        this.processing = false;
        this.toasts.error(error);
      });
  }

  passwordConfirm(form: NgForm) {
    this.processing = true;
    this.presentResourceService.checkPassword(this.uuid,  form.value.password).toPromise()
      .then((bool: boolean) => {
        this.processing = false;
        if (bool) {
          jQuery(this.passwordModalId).modal('hide');
          this.password = true;
          this.passwordPhrase = form.value.password;
          this.load(this.passwordPhrase);
        } else {
          this.toasts.error('Wrong password');
        }
      })
      .catch((error) => {
        this.catchError(error);
      });
  }

  catchError(e) {
    this.processing = false;
    this.toasts.error(e);
    this.password = false;
    this.passwordPhrase = null;
    jQuery(this.passwordModalId).modal('show');
  }

  moreModalClosed(event: Event) {
    if (this.moreModal) {
      this.moreModal = false;
    }
  }

  addComment(data) {
    this.presentResourceService.storeComment(this.uuid, data.id, data.comment, this.passwordPhrase).toPromise()
      .then((comment: any) => {
        this.event.comments.push(comment);
        this.loadEvent(data.id);
        this._changeDetectionRef.detectChanges();
      })
      .catch((e: any) => console.log(e))
  }

  getDocUrl() {
    return this.genDownloadUrl('world');
  }

  getXlsUrl() {
    return this.genDownloadUrl('exel');
  }

  genDownloadUrl(type) {
    var url = '';
    if (this.token) {
      url = this.apiUrl + 'trialline/' + this.trialline.id + '/export/' + type + '?token=' + this.token + '&categories=' + this.getSelectedCategories().join();
    }
    return url;
  }

  exportShow() {
    if (this.token) {
      this.IsExportHidden = !this.IsExportHidden;
    }
  }

  sharingView(id) {
    this.shareId = id;
    this.saveViewModal = true;
    this.shareView = true;
    jQuery(this.modalId).modal('show');
  }

  createView(form: NgForm) {
    let data = form.value;
    data.categories = [];
    this.viewsResourceService.storeView(this.trialline.id, data).toPromise()
      .then((view: any) => {
        form.reset();
        jQuery(this.modalId).modal('hide');
        this.toasts.success('Successfully created new view')
      })
      .catch((error) => {
        if (error.status == 422) {
          let errorData: any = error.error;
          this.errors = errorData.data;
        } else {
          this.toasts.error(error);
        }
      });
  }

  screenshot() {
    this.tlMobile.print();
  }

  togglediv() {
    $("#test").show();
  }

  isAlltagChecked() {
    var i = 0;
    var count = 0;
    for (i = 0; i < this.tagsarray.length; i++) {
      if (this.tagsarray[i].status == true) {
        count++
      }
    }
    return count == this.tagsarray.length;
  }

  toggletag() {
    this.tags = !this.tags;
    this.categories = false;
  }

  test() {
    this.tags = false;
    this.categories = false;
    $('#test').hide()
    $("#catcolor").css("background", "white");
    $("#tagcolor").css("background", "white");
  }

  color(id) {
    if (id == 'catcolor') {
      $("#catcolor").css("background", "#ded4d4");
      $("#tagcolor").css("background", "white");
    } else {
      $("#catcolor").css("background", "white");
      $("#tagcolor").css("background", "#ded4d4");
    }

  }

  tagcheckall(data) {
    this.alldata = []
    this.tagsarray.forEach(x => x.status = data.target.checked)
    var i = 0;
    for (i = 0; i < this.tagsarray.length; i++) {
      if (data.target.checked == true) {
        this.tagsid.push(this.tagsarray[i].id)
      } else {
        this.tagsid = []
      }
    }
    if (this.hiddeneventcheckbox == true) {
      this.dummyonlytagdatawithcategorydataviews();
    } else {
      if (this.selectedcategories.length > 0) {
        for (let event of this.events) {
          if (event.category.state == true) {
            if (event.tags_count > 0) {
              if (data.target.checked == true) {
                this.alldata.push(event)
              }
            } else {
              this.alldata.push(event)
            }
          }
          this.shownEvents = this.alldata;
        }
      }
    }
    this.checktagsselection();
  }

  changedTags(data, id) {
    this.alldata = [];
    this.tagsid = []
    this.shownEvents = [];
    var j = 0;
    var i = 0;
    for (j = 0; j < this.tagsarray.length; j++) {
      if (this.tagsarray[j].status == true) {
        this.tagsid.push(this.tagsarray[j].id)
      }
    }
    if (this.hiddeneventcheckbox == true) {
      this.dummyonlytagdatawithcategorydataviews();
    } else {
      if (this.selectedcategories.length > 0) {
        for (let event of this.events) {
          if (event.category.state == true) {
            if (event.tags_count > 0) {
              for (i = 0; i < event.tag_name.length; i++) {
                if (this.tagsid.includes(event.tag_name[i].id) == true) {
                  if (this.alldata.includes(event) == false) {
                    this.alldata.push(event)
                  }
                }
              }
            } else {
              this.alldata.push(event)
            }
          }
          this.shownEvents = this.alldata;
        }
      }
    }
    this.checktagsselection();
    this.isAlltagChecked()
  }

  categorychangefunction() {
    if (this.hiddeneventcheckbox == true) {
      this.dummyonlytagdatawithcategorydataviews();
    } else {
      this.alldata = [];
      var i = 0;
      for (let event of this.dummyshownEvents) {
        if (event.category.state == true) {
          if (event.tags_count > 0) {
            for (i = 0; i < event.tag_name.length; i++) {
              if (this.tagsid.includes(event.tag_name[i].id) == true) {
                if (this.alldata.includes(event) == false) {
                  this.alldata.push(event)
                }
              }
            }
          } else {
            this.alldata.push(event)
          }
        }
        this.shownEvents = this.alldata;
      }
    }
  }

  checktagsselection() {
    if (this.selectedcategories.length == 0 && this.tagsid.length > 0) {
      this.hiddentag = true;
    } else {
      var j = 0
      if (this.events.length == this.selectedcategories.length) {
        this.hiddentag = false;
      } else {
        if (this.tagsid.length > 0) {
          for (let event of this.events) {
            if (event.category.state == false) {
              for (j = 0; j < event.tag_name.length; j++) {
                if (this.tagsid.includes(event.tag_name[j].id) == true) {
                  this.hiddentag = true;
                }
              }
            }
          }
        } else {
          this.hiddentag = false;
        }
      }
    }
  }

  onlytagdatawithcategorydataviews(event) {
    this.alldata = [];
    if (event.target.checked == false) {
      this.hiddeneventcheckbox = event.target.checked
      this.shownEvents = [];
      this.categorychangefunction();
    } else {
      this.hiddeneventcheckbox = event.target.checked
      var i = 0;
      for (let event of this.events) {
        if (event.tags_count > 0) {
          for (i = 0; i < event.tag_name.length; i++) {
            if (this.tagsid.includes(event.tag_name[i].id) == true) {
              if (this.alldata.includes(event) == false) {
                this.alldata.push(event)
              }
            }
          }
        }
        for (let event of this.events) {
          if (this.selectedcategories.includes(event.category.id) == true) {
            if (this.alldata.includes(event) == false) {
              this.alldata.push(event)
            }
          }
        }
        this.shownEvents = this.alldata;
      }
    }
  }

  dummyonlytagdatawithcategorydataviews() {
    var i = 0;
    this.shownEvents = [];
    this.alldata = [];
    for (let event of this.events) {
      if (event.tags_count > 0) {
        for (i = 0; i < event.tag_name.length; i++) {
          if (this.tagsid.includes(event.tag_name[i].id) == true) {
            if (this.alldata.includes(event) == false) {
              this.alldata.push(event)
            }
          }
        }
      }
    }
    for (let event of this.events) {
      if (this.selectedcategories.includes(event.category.id) == true) {
        if (this.alldata.includes(event) == false) {
          this.alldata.push(event)
        }
      }
    }
    this.shownEvents = this.alldata;
  }
}
