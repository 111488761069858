
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output, SimpleChange
} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TriallineResourceService} from "../../main/trialline/shared/trialline-resoruce.service";
import {env} from "../../../../.env";
import {FileUploader} from "ng2-file-upload";
import {AuthService} from "../../auth/shared/auth.service";
import {Modal} from 'ngx-modialog-7/plugins/bootstrap';
import {DomSanitizer} from "@angular/platform-browser";
import {Contacts} from "../../main/shared/contacts.model";
import {Event} from '../../main/shared/event.model'
import {Trialline} from "../../main/shared/trialline.model";
import {FileModel} from "../../main/file-library/shared/file.model";
import { NgSelectConfig } from '@ng-select/ng-select';
import {ActivatedRoute, Router} from "@angular/router";
import {FileLibraryResourceService} from "../../main/file-library/shared/file-library-resoruce.service";

declare var jQuery:any;

@Component({
  selector: 'app-modal-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})

export class ContactComponent implements OnInit, OnDestroy {


  @Input() file: FileModel;
  @Input() triallinesId: Trialline;
  @Output() onAddContact = new EventEmitter ;
  @Output() onRemoveContact = new EventEmitter<Contacts>();

  publicFolderUrl = env.publicFolderUrl;
  publicImages = env.publicImages;
  apiPath = env.apiUrl;
  modalId = '#file-contacts-edit-modal';
  errors: any = {};
  contact: Contacts = new Contacts();
  color: string = '#'+(Math.random()*0xFFFFFF<<0).toString(16);
  contactValid:any;
  contactList:any;
  selectedContact:any;
  showEdit: boolean = false;
  keyword:any = 'name';
  listFileContacts:any = [];
  contacts:any = [] ;
  editContact: Contacts;

  uploader: FileUploader;


  constructor(
    protected toasts: ToastrService,
    private triallineResoureService: TriallineResourceService,
    private authService: AuthService,
    private _changeDetectorRef: ChangeDetectorRef,
    public modal: Modal,
    public triallineResourceService: TriallineResourceService,
    private _sanitizer: DomSanitizer,
    private fileLibraryResourceService: FileLibraryResourceService,
    protected route: ActivatedRoute, public router: Router,
  ) {}

  ngOnDestroy() {
    this._changeDetectorRef.detach();
  }

  ngOnInit() {
    if(this.file !== undefined) {
      this.contacts = this.file.contacts
    }
    let self = this;
    this.route.parent.params.subscribe(params =>
    {
      if(params["trialline_id"] !== undefined){
        self.triallinesId = params["trialline_id"];
      }
    });
    if(this.triallinesId) {
      this.triallineResourceService.getContacts(this.triallinesId, 'categories,privateFiles').subscribe((response: any) => {
        this.contactList = response;
      }, (error: any) => { console.log(error);} );
    }
  }

  ngAfterViewInit() {

    // if(this.contactEvent['contacts'] !== undefined) {
    //   this.contactList = this.contactEvent['contacts']
    // } 

    jQuery('[data-toggle="tooltip"]').tooltip({
      animated: 'fade',
      placement: 'right',
      html: true
    });
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    if(this.file !== undefined) {
      this.contacts = this.file.contacts
      if (this.file.contacts.length > 0) {
        for (let i = 0; i < this.file.contacts.length; i++) {
          this.listFileContacts.push(this.file.contacts[i].id)
        }
      }
    }
  }

  getSafeThumbnailUrl(category) {

    let obj;
    if( category.thumbnail_encryptid != null && category.thumbnail_encryptid != false ) {
      obj =  this.publicFolderUrl + 'index.php/v1/filesystem/' +
        category.thumbnail_encryptid + '?preview=1'
    } else {
      obj =  this.publicImages +
        (category.thumbnail ? category.thumbnail.substring(1).replace("'", "\\'") : '')
    }

    return obj;
  }

  getSafeUrl(category) {
    let obj = this.getSafeThumbnailUrl(category);
    return "<img src='" + obj + "' style='height: 130px;'/>";
  }

  updateContact(contact: Contacts) {
    let indexContacts = this.contacts.indexOf(this.contacts.find(e => e.id == contact.id));
    let index = this.contactList.indexOf(this.contacts.find(e => e.id == contact.id));
    this.contactList[index] = contact;
    this.contacts[indexContacts] = contact;
    this._changeDetectorRef.detectChanges();
    this.ngAfterViewInit();
  }

  createContact(contact: Contacts) {

    this.contactList.push(contact);
  }

  edit(contact: Contacts) {

    this.editContact = contact;
    this.showEdit = true;
    this._changeDetectorRef.detectChanges();
    jQuery(this.modalId).modal('show');
  }

  create() {

    this.editContact = new Contacts();
    this.showEdit = true;
    this._changeDetectorRef.detectChanges();
    jQuery(this.modalId).modal('show');
  }

  connectToEvent() {
  //  this.onAddContact.emit(this.contactList[index]);
    if (this.selectedContact !== undefined) {
      let index = this.contactList.indexOf(this.contactList.find(e => e.id == this.selectedContact));
      if(this.contacts.indexOf(this.contacts.find(e => e.id == this.selectedContact)) == -1) {
        this.listFileContacts.push(this.contactList[index].id)
        this.contacts.push(this.contactList[index])
      }else{
        this.toasts.info('Contact has already been added from event.');
      }
    }else{
      this.toasts.info('Please select contact.');
    }
  }

  deleteContact(contact: Contacts) {

    const dialogRef = this.modal.confirm()
      .size('lg')
      .isBlocking(true)
      .showClose(true)
      .keyboard(27)
      .title('Confirmation')
      .body('Disconnecting this contact will remove the contact\'s association with this event. Proceed?')
      .open()
    dialogRef.result
      .catch((err: any) => {
        this.modelClose()
        console.log('ERROR: ' + err)})
      .then((dialog: any) => {
        this.modelClose()
        if(dialog){
          this.confirmedDeleteContact(contact)
        }
      });
    $('bs-modal-container').addClass('show-midal-element')
  }

  confirmedDeleteContact(contact: Contacts) {
    let index = this.contacts.indexOf(this.contacts.find(e => e.id == contact.id));
    if( index != -1) {
      this.listFileContacts.splice(contact.id, 1);
      this.contacts.splice(index, 1);
      this.onRemoveContact.emit(contact);
      this.toasts.success('Successfully deleted contact from file.');
    }
  }

  modelClose() {
    $('body').removeClass('modal-open');
  }

  selectContact(event){
    this.selectedContact = event.id
  }

  saveFileContact(){
    let fileContact = {'file_id':this.file.id, 'contacts_id':[]};

    if(this.contacts.length > 0){
        this.contacts.forEach(element => {
        fileContact.contacts_id.push(element.id)
      });
    }
    this.file.contacts = this.contacts;
    let data = {};
    data['description'] = this.file.description;
    data['title'] = this.file.title;
    let listContactsId = [];
    if (this.file.contacts.length > 0) {
      for (let i = 0; i < this.file.contacts.length; i++) {
        listContactsId.push(this.file.contacts[i].id)
      }
    }
    data['contacts_id'] = listContactsId;

    this.fileLibraryResourceService.updateFile(this.file, data).toPromise()
      .then((file: FileModel) => {
        this.toasts.success('Successfully update');
        console.log(file);
        this.closeContactModal();
      })
      .catch((error) => {
        this.toasts.error(error);
      });
    this.onAddContact.emit(this.file);
  }

  closeContactModal(){
    jQuery(this.modalId).modal('hide');
    jQuery('#file-contacts-edit-modal').removeClass('modal-open');
  }
}

