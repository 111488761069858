import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, Router} from "@angular/router";
import {FileModel} from "./file.model";
import {FileLibraryResourceService} from "./file-library-resoruce.service";

@Injectable()
export class FilesResolver implements Resolve<FileModel[]>
{
  constructor(protected fileLibraryResource: FileLibraryResourceService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Promise<FileModel[]>
  {
      return this.fileLibraryResource.getFiles()
        .toPromise()
        .then((files: FileModel[]) => {return files;});
      //  .catch((error) => { this.router.navigateByUrl(error.status == 403 ? '/403' : '/404') });
  }
}
