import {Component, Inject, OnInit} from '@angular/core';
import {Router} from "@angular/router";

import {ToastrService} from 'ngx-toastr';
import {AuthResourceService} from "../shared/auth-resoruce.service";
import {User} from "../shared/user.model";
import {AuthService} from "../shared/auth.service";
import {LOCAL_STORAGE, WebStorageService} from 'ngx-webstorage-service';
import { OTPVerificationService } from '../shared/otpverification.service';

declare var jQuery: any;

@Component({
  selector: 'app-auth-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  errors: any = {};
  processing: boolean = false;
  public data: any = [];
  divStatus: boolean = false;
  errorEmail: boolean = false;
  userEmail: string = '';
  errorMess: string = 'Please enter your email';
  showOTP: boolean = false;
  otp: string = '';
  sendOtp: any;
  otpCode: number;
  isSecureAccountEnabled: boolean;

  constructor(
    protected authResourceService: AuthResourceService,
    protected router: Router,
    private otpVerificationService: OTPVerificationService,
    protected authService: AuthService,
    private toastr: ToastrService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) {

  }

  sendOtpEmail(form: any) {
    
    this.authResourceService.otpEmail(form.value).subscribe(
      (response: any) => {        
          this.otpCode = response.otpcode;       
      }
    );

  }

  login(form: any) {
    this.processing = true;
    
    if (!form.value.email) {
      this.toastr.error('Email is required');
    } else if(!form.value.password) {
      this.toastr.error('Password is required');
    } else {

      this.authResourceService.login(form.value).subscribe(
        (response: any) => {
          if(response.is_secure_otp != 0) {
            this.showOTP = true;
            this.toastr.success('OTP required. Please enter OTP sent to your email.');          
            // send otp email and get response set variables for them
            this.sendOtpEmail(form);
          } else {
            this.authResourceService.login(form.value).subscribe(
              (response: any) => this.handleLogin(response),
              (error: Response) => this.handleError(error)
            );
          }
        },
        (error: Response) => this.handleError(error),
      );
      
      try {
        // @ts-ignore
        fbq('track', 'Lead');
        this.processing = false;
        // @ts-ignore
        window?.lintrk('track', { conversion_id: 4527276 });
      } catch (e){
        console.log(e);
      }

    }
  }

  verifyOTP(form: any) {
    this.processing = true;

    this.processing = true;
    if (!form.value.email) {
      this.toastr.error('Email is required');
    } else if(!form.value.password) {
      this.toastr.error('Password is required');
    } else {

      if(this.otpCode == form.value.secure_otp) {

        this.authResourceService.login(form.value).subscribe(
          (response: any) => this.handleLogin(response),
          (error: Response) => this.handleError(error)
        );

      } else {
        this.toastr.error('Invalid OTP. Please try again.');
      }

    }

    try {
      // @ts-ignore
      fbq('track', 'Lead');
      this.processing = false;
      // @ts-ignore
      window?.lintrk('track', { conversion_id: 4527276 });
    } catch (e){
      console.log(e);
    }

  }

  handleOTPVerificationSuccess(response: any) {
    if (response.success) {
      this.handleLogin(response);
      this.otp = '';
      this.showOTP = false;
    } else {
      this.toastr.error('Invalid OTP. Please try again.');
      this.processing = false;
    }
  }

  handleOTPVerificationError(error: any) {
    this.toastr.error('Error verifying OTP. Please try again later.');
    this.processing = false;
  }

  handleLogin(response: any) {
    
    this.authService.store(response.token);
    this.authService.emit();
    this.saveInLocal('needCheckChromeNotification', true);
    this.router.navigateByUrl('/dashboard');
   // this.processing = false;
  }

  handleError(error: any) {
   
    let errorData: any = error.error;
    if (error.status == 422) {
      this.toastr.error(error.error.message);
    } else if (error.status == 400) {
      this.toastr.error('That email address has already been verified. Please contact customer support for help if you are unable to log in.');
      this.errors = errorData.data;
    } else {
      this.toastr.error(errorData.data[0]);
    }
    this.processing = false;
  }

  saveInLocal(key, val): void {
    this.storage.set(key, val);
    this.data[key] = this.storage.get(key);
  }

  getFromLocal(key): void {
    this.data[key] = this.storage.get(key);
    return this.data[key];
  }

  ngOnInit(): void {
    localStorage.clear();
  }

  dialogUser() {
    this.divStatus = true;
  }

  cancel() {
    this.divStatus = false;
  }

  userSend(form: any): void {
   // console.log(form);
  }

  resetPassword(form: any): void {
    let email = form.value.email;
    if (email !== null) {
      this.errorEmail = false;

      this.authResourceService.sendResetPasswordEmail(form.value).subscribe(
          (response: any) =>  {this.handleSuccess(response, 'We have e-mailed your password reset link!');
          this.cancel()
          },
          (error: any)    =>  {this.toastr.error(error.error.email[0]);
          }
      );
    } else {
      this.errorEmail = true;
    }

  }
  activationEmail(form: any): void {
    let email = form.value.email;
    if (email !== null) {
      this.errorEmail = false;
      this.authResourceService.resendActivationEmail(email).subscribe(
          (response: any) => {this.handleSuccess(response, 'We have e-mailed your activation link!'); this.cancel()},
          (error: any)    => this.handleError(error)
      );

    } else {
      this.errorEmail = true;
    }
  }

  handleSuccess(response: Response, message)
  {
    this.processing = false;
    this.errors = {};
    this.toastr.success(message);
  }

}
