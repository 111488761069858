<div id="category-edit-modal" class="modal fade bs-modal-lg in" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">
          {{ (category.id ? '' : 'Create new category ') + (category.name ? category.name : '') }}
        </h4>
      </div>
      <form #categoryForm="ngForm" role="form-horizontal" class="event-form" (submit)="saveCategory(categoryForm)">
        <div class="modal-body portlet-body">
          <div class="">
            <div class="row">
              <div class="col-md-8">
                <div class="form-group">
                  <label>Title</label>
                  <input #name type="text" name="name" [ngModel]="category.name" (change)="slugRegenerate(name.value)"
                         class="form-control" placeholder="Enter name here ..">
                  <span class="help-block" *ngIf="errors?.name" style="color: red;">{{ errors?.name[0] }}</span>
                </div>
                <div class="form-group">
                  <label>Slug</label>
                  <input type="text" name="slug" [ngModel]="category.slug" class="form-control"
                         placeholder="Enter slug here ..">
                  <span class="help-block" *ngIf="errors?.slug" style="color: red;">{{ errors?.slug[0] }}</span>
                </div>
                <div class="form-group">
                  <label>Color</label>
                  <input [(ngModel)]="background" name="background" class="form-control" [(colorPicker)]="background"
                         [cpPosition]="'left'" [cpPositionRelativeToArrow]="true"
                         [style.background]="background" [value]="background">
                  <span class="help-block" *ngIf="errors?.slug" style="color: red;">{{ errors?.slug[0] }}</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="note-small mb-1">Click on image to change the thumbnail</div>
                <label for="thumbnail_input">
                  <input type="file" ng2FileSelect [uploader]="uploader" id="thumbnail_input" style="display: none;">
                  <div class="fileinput fileinput-new upload-box">
                    <div class="fileinput-preview thumbnail tile upload-files" style="width: 210px; position: relative;">
                      <div class="tile-body">
                        <img #thumbnail [attr.src]="category_thumbnail" class="img-responsive" width="200px"
                             alt="thumbnail">
                        <a *ngIf="showDeleteIcon(thumbnail.src)" (click)="deleteThumbnail(); $event.preventDefault()"
                           id="delete-background" class="remove-background">&times;</a>
                      </div>
                    </div>
                  </div>
                </label>
                <div class="progress" style="height: 3px; width: 200px; margin: 0">
                  <div class="progress-bar" role="progressbar" [style.width]="uploader.progress + '%'"></div>
                </div>
                <div *ngIf="thumbnail_visible === null">Some thumbnails are visible and some - not</div>
                <div class="mt-checkbox-inline" style="margin-top: 10px;">
                  <label class="mt-checkbox">
                    <input name="thumbnail_visible" id='thumbnail_visible' [(ngModel)]="thumbnail_visible"
                           type="checkbox">
                    Show thumbnail
                    <span></span>
                  </label>
                </div>
                <div style="width: 30%; margin-top: 10px" *ngIf="!isCropped">
                  <div style="display: flex; flex-direction: row;">
                    <button type="button" class="btn btn-success" *ngIf="category.thumbnail_encryptid"
                            (click)="showCropImage()">
                      Crop Image
                    </button>
                    <img src="assets/squares.gif" class="img spinners" alt="spinner" width="30px" id="processing"
                         *ngIf="cropProcessing">
                  </div>
                </div>
                <div id="crop_block" style="margin-top: 10px;">
                  <div style="display: flex; flex-direction: row;">
                    <button style="display: flex;margin-bottom: 10px" type="button" (click)="cropApply()"
                            class="btn btn-success">
                      Apply
                    </button>
                    <button style="display: flex; margin-left: 2rem; margin-bottom: 10px" type="button"
                            (click)="cropCancel()"
                            class="btn btn-danger">
                      Cancel
                    </button>
                  </div>
                  <img class="mr-auto p-2" [attr.src]="cropped_image_src" style="display: block; max-width: 100%;"
                       alt="" id="crop_img">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary" [disabled]="uploading">
            {{ category.id ? 'Save changes' : 'Create' }}
          </button>
          <a (click)="deleteCategory()" class="btn red btn-delete pull-left"
             data-confirm="Warning! If you delete this Event you won't be able to retrieve it. Are you sure you want to proceed?"
             *ngIf="category.id">
            Delete
          </a>
        </div>
      </form>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>
