import {NgModule} from '@angular/core';
import {HttpClientModule} from "@angular/common/http";
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {PresentComponent} from "./present.component";
import {PresentResourceService} from "./shared/present-resoruce.service";
import {PresentResolver} from "./shared/present.resolver";
import {SharedComponentsModule} from "../shared-components/shared-components.module";
import {EventsModule} from "../main/trialline/events/events.module";
import {CookieService} from "ngx-cookie";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    SharedComponentsModule,
    EventsModule,
  ],
  declarations: [
    PresentComponent
  ],
  providers: [
    PresentResourceService,
    PresentResolver,
    CookieService
  ]
})
export class PresentModule {}
