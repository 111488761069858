import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {View} from "../../../shared/view.model";
import {ViewsResourceService} from "../../views/shared/views-resoruce.service";
import {NgForm} from "@angular/forms";
import {ToastrService} from 'ngx-toastr';

declare var jQuery: any;

@Component({
  selector: 'app-trialline-manage-save-view',
  templateUrl: './save-view.component.html'
})
export class TriallineManageSaveViewComponent implements AfterViewInit
  // OnInit, OnDestroy
{
  @Input() share: boolean;
  @Input() triallineId: number;
  @Input() shareId: number;
  @Input() categories: any;
  @Input() events: any;
  @Input() eventsTracks: any;
  @Input() simpleView: number;
  @Input() screenView: any;
  @Output() onClose = new EventEmitter<boolean>();

  modalId: string = '#save-view-modal';

  errors: any = {};

  constructor(
    private viewsResourceService: ViewsResourceService,
    private toasts: ToastrService
  ) {}

  ngAfterViewInit() {

    let self = this;

    jQuery(this.modalId).on('hidden.bs.modal', function (e) {
      self.onClose.emit(true);
    })
  }

  createView(form: NgForm) {

    let data = form.value;
    data.events = this.events.map(event => event.id);
    data.view = this.simpleView;
    console.log("this.simpleView",this.simpleView);
    console.log("data",data);

    const formData = new FormData();
    // formData.append('file', this.screenView, 'image.png')
    formData.set('comment', data.comment)
    formData.set('events', data.events)
    formData.set('view', data.view)
    formData.set('name', data.name)
    formData.set('description', data.description)
    formData.set('file_id',  jQuery("#file_id").val())
    this.viewsResourceService.storeView(this.triallineId, formData).toPromise()
      .then((view: any) => {
        console.log(view)
        form.reset();
        jQuery(this.modalId).modal('hide');
        this.toasts.success('Successfully created new view')
        // this.sendViewTest( view);
        if (data.email !=null){
          this.sendViewTest(view, data.email)
        }
      })
      .catch((error) => {
        if (error.status == 422) {
          form.reset();
          let errorData: any = error.error;
          this.errors = errorData.data;
          this.toasts.error(errorData.error.message);
        } else { this.toasts.error(error); }});
    console.log(this.shareId)
  }

  sendView(form: NgForm) {
      let data = form.value;
      data.file = this.screenView;
      this.viewsResourceService.sendView(this.triallineId, data, this.shareId).toPromise()
          .then((view: any) => {
              form.reset();
              jQuery(this.modalId).modal('hide');
              this.toasts.success('Successfully send view')
          })
          .catch((error) => {
              if (error.status == 422) {
                  let errorData: any = error.error;
                  this.errors = errorData.data;
                  this.toasts.error(errorData.error.message);
              } else if (error.status == 403){
                  let errorData: any = error.error;
                  this.toasts.error(errorData.error.message)
              } else { this.toasts.error(error); }});
  }

  sendViewTest(data, email) {
    data.file = this.screenView;
    data.email = email;
    this.viewsResourceService.sendView(this.triallineId, data, data.id).toPromise()
      .then((view: any) => {
        jQuery(this.modalId).modal('hide');
        this.toasts.success('Successfully send view')
      })
      .catch((error) => {
        if (error.status == 422) {
          let errorData: any = error.error;
          this.errors = errorData.data;
          this.toasts.error(errorData.error.message);
        } else if (error.status == 403){
          let errorData: any = error.error;
          this.toasts.error(errorData.error.message)
        } else { this.toasts.error(error); }});
  }
}
