import {
  AfterViewInit,
  ChangeDetectorRef, Component,
  ElementRef,
  EventEmitter, HostListener,
  Input,
  OnInit,
  Output,
  ViewChild

} from '@angular/core';
import {Event} from '../../main/shared/event.model'
import {env} from "../../../../.env";
import {Trialline} from "../../main/shared/trialline.model";
import {SortByDatePipe} from "../shared/sortByDate.pipe";
import {CookieService} from "ngx-cookie";

@Component({
  selector: 'app-pyramid-trialline',
  templateUrl: './trialline-pyramid.component.html',
  styleUrls: ['./trialline-pyramid.component.scss'],
})
export class TriallinePyramidComponent implements OnInit, AfterViewInit {

  @Input()
  set getEvents(events: Event[]) {
    this.renderAndSetItems(events, false);
  };

  @Input() trialline: Trialline;
  @Input() password: string;
  @Input() offsetHeight: number;
  @Input() editButton: boolean = true;
  @Input() commentsButton: boolean;
  @Output() onEdit = new EventEmitter<number>();
  @Output() onMore = new EventEmitter<any>();

  @ViewChild('visualization',  { static: true }) el: ElementRef;

  public publicPath = env.publicFolderUrl;
  public publicImages = env.publicImages;
  public apiPath = env.apiUrl;
  public idVisualisation: string;
  defaultThumbnail: string = '/images/start-event/trialline-event-thumb.jpg';
  events: Event[];
  events2: any;
  status: any;
  minDate: any = 0;
  maxDate: any = 0;
  $pyramid: any;
  eventWidth: number = 260;
  triallineOffset: number = 0;
  windowWidth: number;
  elemOnTopOfPyramid: number = 0;
  monthNames: string[] = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "June", "July", "Aug.", "Sept.", "Oct.", "Nov.", "Dec."];

  macScroll: boolean = true;
  arrowEmulationScroll: boolean = true;

  //timebar
  $timebar: any;
  timebar: any = [];
  currentMonth: string;

  pyramidPopupState: boolean = false;

  startX: number;
  mobileWidth: boolean = false;

  constructor(
    private element: ElementRef,
    private _changeDetectorRefPyr: ChangeDetectorRef,
    private SortByDatePipe: SortByDatePipe,
    // private DatePipe: DatePipe,
    public cookieService: CookieService,
  ) {

  }


  ngOnInit() {
    //debugger;
    if (this.password) {
      this.idVisualisation = this.password;
    } else {
      this.idVisualisation = '0'
    }
    if (typeof this.editButton === 'undefined') this.editButton = true;
    if (typeof this.commentsButton === 'undefined') this.commentsButton = true;
    this.onResize(window)
  }

  ngAfterViewInit(){
    let cookieName = 'showPyramidPopupOnInit';
    !this.cookieService.get(cookieName) && this.togglePopup(true)
    this.cookieService.put(cookieName, 'false');
  }

  ngOnDestroy() {
    this._changeDetectorRefPyr.detach();
  }

  @HostListener('document:keydown', ['$event'])
  arrowScroll(e: any) {

    if ((this.arrowEmulationScroll) && ([37, 39].indexOf(e.keyCode) > -1) && !(document.getElementsByTagName("ckeditor").length > 0)) {
      this.arrowEmulationScroll = false;
      e.preventDefault();
      switch (e.which) {
        case 37: // left
          this.scrollEvents({wheelDelta: 1});
          break;
        case 39: // right
          this.scrollEvents({wheelDelta: -1});
          break;
        default:
          return;
      }
      setTimeout(() => {
        this.arrowEmulationScroll = true;
      }, 150)
    }
  }

  @HostListener('window:resize', ['$event'])
  onResizeEvent() {
    this.windowWidth = window.innerWidth;
  }

  swipeEvent(e?: any) {
    e.preventDefault();
    this.scrollEvents({wheelDelta: e.deltaX})
  }

  @HostListener('touchstart', ['$event'])
  touchStart(e? : any) {
    e.preventDefault();
    this.startX = e.changedTouches[0].clientX;
  }

  @HostListener('touchend', ['$event'])
  touchEnd(e? : any) {
    e.preventDefault();
    var currentX = e.changedTouches[0].clientX;
    if (this.startX == currentX) {
      jQuery(e.target).trigger("click");
    } else {
      var direction = currentX > this.startX ? 1 : (currentX < this.startX) ? -1 : null;
      if (direction) {
        let speed = currentX - this.startX;
        (speed < 0) ? speed = (speed * (-1)) / 100: speed /= 100;
        var parsedSpeed = Math.round(speed);
        console.log('parsed: ' + parsedSpeed);
        for(let i = 0; i <= parsedSpeed; i++) {
          this.scrollEvents({wheelDelta: direction});
        }
      }
    }
  }
  @HostListener('click', ['$event'])
  arrowClickScroll(e? : any) {
    e.preventDefault();
    var arrowDirection = e.target.dataset.direction
    console.log("arrowDirection", arrowDirection);
    if(arrowDirection !== undefined) {
      var direction = arrowDirection === 'right' ? -1 :  1 ;
      this.scrollEvents({wheelDelta: direction})
    }
  }

  @HostListener('mousewheel', ['$event'])
  scrollEvt(e?: any) {
    e.preventDefault();
    const fastScroll = e.wheelDelta < 30 && e.wheelDelta > -30
    if (!fastScroll) {
      this.scrollEvents(e);
    } else if (this.macScroll) {
      this.macScroll = false;
      this.scrollEvents(e);
      setTimeout(() => {
        this.macScroll = true;
      }, 150)
    }

  }

  scrollEvents(e?: any, scrollToPosition?: number) {
    if (e.target) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!this.$pyramid) {
      this.$pyramid = document.getElementById('pyramid-view-wrapper');
    }
    if (!this.windowWidth) {
      this.windowWidth = window.innerWidth;
    }
    if (!this.triallineOffset) {
      this.triallineOffset = 0;
    }
    const eventWrapperWidth = this.$pyramid.offsetWidth;
    const scroll = e.wheelDelta;
    let scrollDirection = Math.sign(scroll);
    let newPos = scrollDirection * this.eventWidth + this.triallineOffset;

    const elementsOnScreen = Math.round(this.windowWidth / this.eventWidth);
    let maxOffset = eventWrapperWidth - this.windowWidth / 2 - 70;
    this.elemOnTopOfPyramid = scrollDirection === -1
      ? -Math.round(this.triallineOffset / this.eventWidth) + 1
      : -Math.round(this.triallineOffset / this.eventWidth) - 1;
    if (scrollToPosition !== null && scrollToPosition !== undefined) {
      this.triallineOffset = newPos = scrollToPosition;
      this.elemOnTopOfPyramid = -Math.round(this.triallineOffset / this.eventWidth);
    }
    if (newPos <= -maxOffset) {
      this.triallineOffset = -maxOffset;
      this.elemOnTopOfPyramid = this.events2.length - 1;
    } else if (newPos > 0) {
      this.triallineOffset = 0;
      this.elemOnTopOfPyramid = 0;
    } else {
      this.triallineOffset = newPos;
    }
    let elevate = 0;
    let elevateBase;
    if (elementsOnScreen >= 10 && elementsOnScreen < 15) {
      elevateBase = -((window.innerHeight - 120) / elementsOnScreen * 0.8);
    } else if (elementsOnScreen >= 8) {
      elevateBase = -((window.innerHeight - 120) / elementsOnScreen * 0.6);
    } else {
      elevateBase = -50;
    }
    if(window.innerHeight <= 700){
      elevateBase = 0;
    }
    for (let i = this.elemOnTopOfPyramid - Math.round(elementsOnScreen / 2); i <= this.elemOnTopOfPyramid + Math.round(elementsOnScreen / 2); i++) {
      if (document.getElementById('pyramid-card-' + i)) {
        document.getElementById('pyramid-card-' + i).style.transform = 'translateY(' + elevate + 'px)';
      }
      (i === this.elemOnTopOfPyramid - 1) && (elevate -= 20);
      (i === this.elemOnTopOfPyramid) && (elevate += 20);
      if (i < this.elemOnTopOfPyramid) {
        elevate = elevate + elevateBase;
      } else {
        elevate >= 0
          ? elevate = 0
          : elevate = elevate - elevateBase;
      }
    }
    if (this.events2[this.elemOnTopOfPyramid]) {
      this.timebarScroll(new Date(this.events2[this.elemOnTopOfPyramid].date_start).getFullYear() + '-'
        + this.monthNames[new Date(this.events2[this.elemOnTopOfPyramid].date_start).getMonth()], false);
    }
  }

  scrollToEvent(id: number) {
    let eventPosition;
    eventPosition = -(this.eventWidth * id);
    if (id === 0) {
      eventPosition = 0;
    }
    this.scrollEvents({wheelDelta: 0}, eventPosition)
  }

  renderAndSetItems(events: Event[], saveCursor = true) {
    if (events.length != 0) {
      this.events = this.SortByDatePipe.transform(events);
      this.events2 = this.events.map((event: any) => {
        //console.log('event:', event)
        const start_date = this.transformDateToTime(event.start_date);
        const end_date = this.transformDateToTime(event.end_date);
        //console.log('modified start date:',start_date)
        return {
          id: event.id,
          background: event.category ? this.setBackgroundColor(event.category.background) : this.setBackgroundColor(''),
          font_size: this.setCategoryFontSize(event.category_name),
          category_name: this.setCategory(event.category_name),
          date_start: this.transformDate(event.date_start, !!event.if_start_days),
          date_end: this.transformDate(event.date_end, !!event.if_end_days),
          // start_date: start_date !== '12:00 AM' ? start_date : null,
          // end_date: end_date !== '12:00 AM' ? end_date : null,
          start_date: (event.has_times == true) ? start_date : null,
          end_date: (event.has_times_end == true) ? end_date : null,          
          title: this.transformTitle(event.title),
          description: event.description,
          thumbnail: this.setThumbnailImage(event.thumbnail_attr, event.thumbnail_visible, event),
          thumbnail_visible: event.thumbnail_visible,
          files_count: event.files_count,
          comments_count: event.comments_count,
          tags_count: event.tags_count,
          bill_costs_count: event.bill_costs_count,
          bill_cost: event.bill_cost
        }
      });
      this.transformEventsForTimebar(events);

    } else {
      this.events2 = [];
      setTimeout(() => {
        this.scrollEvents({wheelDelta: 0}, this.windowWidth / 2 - this.eventWidth / 2)
      }, 2000);
    }
    this._changeDetectorRefPyr.detectChanges();
  }

  transformDateToTime(date: any) {
    if (!date) {
      return '';
    }
    return new Date(date.replace(/-/g, "/")).toLocaleString([], {hour: 'numeric', minute: 'numeric', hour12: true})
  }

  transformDate(date: any, showOnlyMonthAndYear?: boolean) {
    if (!date) {
      return '';
    }
    // console.log(date, this.DatePipe.transform(date,'MMM d, yyyy Z'))
    const dt = new Date(date);
    return showOnlyMonthAndYear
      ? this.monthNames[dt.getUTCMonth()] + ' ' + dt.getUTCFullYear()
      : this.monthNames[dt.getUTCMonth()] + ' ' + dt.getUTCDate() + ', ' + dt.getUTCFullYear();

  }

  transformTitle(str: string) {
    return !str || str.length < 40
      ? str
      : str.slice(0, 40).concat('...');
  }

  setBackgroundColor(color: string) {
    return color !== '#FFFFFF' ? color : '#000000'
  }

  setCategoryFontSize(categoryName: string) {
    return categoryName.length < 35 ? '14px' : '10px';
  }

  setCategory(category) {
    return category !== 'No Category' ? category : ' ';
  }

  setThumbnailImage(path: string, visible: boolean, event:any) {
    let obj = {};
    if( event.thumbnail_encryptid != null && event.thumbnail_encryptid != false ) {
      obj = {'background':'url(\'' + this.publicPath + 'index.php/v1/filesystem/' +
          event.thumbnail_encryptid + '?preview=1&date=' + event.updated_at.replaceAll(' ', '').replaceAll(':', '').replaceAll('-', '') + '\') center center no-repeat'}
    } else if (visible) {
      obj = {
        'background': 'url(\'' + this.publicImages +
          (path ? path.substring(1).replace("'", "\\'") : this.defaultThumbnail.substring(1)) + '\') center center no-repeat'
      }
    }
    return obj;
  }

  eventEdit(id: number) {
    this.onEdit.emit(id);
  }

  eventMore(id: number, activeTab?) {
    this.onMore.emit({id: id, activeTab: (activeTab ? activeTab : 'description')});
  }

  @HostListener('window:resize', ['$event'])
    onResize(event) {
      console.log("m hereerere");
      console.log("event",event)
     var Width =  event.target ? event.target.innerWidth : event.innerWidth;
     console.log("Width",Width)
      if(Width < 768) {
        this.mobileWidth = true
      } else {
        this.mobileWidth = false
      }
    }
  eventMoreMobile(id: number) {
    if(this.mobileWidth) {
      this.onMore.emit({id: id, activeTab: 'description'});
    }
  }

  // timebar functions

  transformEventsForTimebar(events: Event[]) {
    let transformed = {
      datesAndIds: {},
      years: [],
      total: 0,
    };
    let firstYear = new Date(events[0]['date_start']).getFullYear()
    let lastYear = new Date(events[events.length - 1]['date_start']).getFullYear()

    for (let i = firstYear; i <= lastYear; i++) {
      transformed.years.push(i);
    }

    let tmp;
    events.forEach((event, index) => {
      tmp = transformed.datesAndIds[new Date(event.date_start).getFullYear() + '-' + this.monthNames[new Date(event.date_start).getMonth()]];
      if (tmp !== index) {
        transformed.datesAndIds[new Date(event.date_start).getFullYear() + '-' + this.monthNames[new Date(event.date_start).getMonth()]] = {item: index};
        transformed.total += 1;
      }

    })
    this.timebar = transformed;
  }

  timebarScroll(item: any, fromBar: boolean = true) {
    let timebarItem = this.timebar.datesAndIds[item];
    if (!this.$timebar) {
      this.$timebar = document.getElementById('scroll-wrapper');
    }

    if (this.timebar.datesAndIds[item]) {
      Array.from(document.getElementsByClassName('bar')).forEach(function (element) {
        element.classList.remove('bar-lg')
      });
      document.getElementById('bar-' + item).classList.add('bar-lg');
      this.setCurrentMonth(item);
    }

    if (fromBar) {
      if (timebarItem) {
        this.scrollToEvent(timebarItem.item);
      } else {
        let itemDate: any = new Date(item);
        let keys = Object.keys(this.timebar.datesAndIds);
        const prev = keys.filter(key => new Date(key) < itemDate);
        const next = keys.filter(key => new Date(key) > itemDate);
        let prevDate: any = 0, nextDate: any = 0;
        if (prev.length !== 0) {
          prevDate = new Date(prev[prev.length - 1])
        } else {
          this.scrollToEvent(this.timebar.datesAndIds[next[0]].item);
          this.setCurrentMonth(next[0]);
          document.getElementById('bar-' + next[0]).classList.add('bar-lg');
          return;
        }

        if (next.length !== 0) {
          nextDate = new Date(next[0])
        } else {
          this.scrollToEvent(this.timebar.datesAndIds[prev[prev.length - 1]].item);
          this.setCurrentMonth(prev[prev.length - 1]);
          document.getElementById('bar-' + prev[prev.length - 1]).classList.add('bar-lg');
          return;
        }

        if (nextDate - itemDate < itemDate - prevDate) {
          this.scrollToEvent(this.timebar.datesAndIds[next[0]].item)
          this.setCurrentMonth(next[0]);
          document.getElementById('bar-' + next[0]).classList.add('bar-lg');
        } else {
          this.scrollToEvent(this.timebar.datesAndIds[prev[prev.length - 1]].item);
          this.setCurrentMonth(prev[prev.length - 1]);
          document.getElementById('bar-' + prev[prev.length - 1]).classList.add('bar-lg');
        }
      }
    }
  }

  setCurrentMonth(item: any) {
    this.currentMonth = item.match(/[a-zA-z]+\.?/gm);
  }

  togglePopup(state: boolean) {
      this.pyramidPopupState = state;
  }
   getCorrectTextColor(hex){
   let threshold = 130; /* about half of 256. Lower threshold equals more dark text on dark background  */

     let hRed = hexToR(hex);
     let hGreen = hexToG(hex);
     let hBlue = hexToB(hex);
    function hexToR(h) {return parseInt((cutHex(h)).substring(0,2),16)}
    function hexToG(h) {return parseInt((cutHex(h)).substring(2,4),16)}
    function hexToB(h) {return parseInt((cutHex(h)).substring(4,6),16)}
    function cutHex(h) {return (h.charAt(0)=="#") ? h.substring(1,7):h}

     let cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;
    if (cBrightness > threshold){return "#000000";} else { return "#ffffff";}
  }
}


