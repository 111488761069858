<section class="modal_popup">
  <div class="modal fade" id="popap_form" tabindex="-1" role="dialog" aria-labelledby="popap_formLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="popap_formLabel">Send Us a Message!</h4>
        </div>
        <div class="modal-body">
          <app-popup-form></app-popup-form>
        </div>
      </div>
    </div>
  </div>
</section>
<div id = "samples_holder">
  <h1>Sample Triallines</h1>
  <div *ngFor="let view of views">
    <h3>{{ view.title }}</h3>
    <app-present2 [password]="view.passwordPhrase" [uuid]="view.uuid"></app-present2>
  </div>
</div>
