<div class="upload-container" style="padding:0px;">
  <!-- <div><h3>Drop Files Here</h3><div>or</div> -->
  <!-- <h5 *ngIf='path.length>0' style="font-style: normal;color: #337ab7;font-weight: bold;">{{'Upload file in - '+path}}</h5> -->
    <div class="dropzone upload-info" id="dropzone-drop-area" [dropzone]="config" (error)="onUploadError($event)"
         (success)="onUploadSuccess($event)" (complete)="complete($event)"  (sending)="onSending($event)" >
         <h4 *ngIf='editcomp==1'  style="font-style: normal;color: #337ab7;font-weight: bold;margin-top: 20px;">Drag Files Here</h4>
          <h4 *ngIf='editcomp==2'  style="font-style: normal;color: #337ab7;font-weight: bold;margin-top: 20px;">Drop Files to <span>
            "Home<span *ngIf='path.length>0' >/{{path}}</span>"
          </span>Folder Here</h4>
          <h3 *ngIf='editcomp==0' style="font-style: normal;color: #337ab7;font-weight: bold;margin-top: 20px">Drop Files Here</h3><div>or
         <!-- <h3>Drop Files Here</h3><div>or -->
        </div>
      <div id="myProgress" style="display:none;">
      </div>
    </div>
</div>
