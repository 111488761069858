import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from "@angular/router";
import {NgForm} from "@angular/forms";
import {User} from "../../../auth/shared/user.model";
import {TeamResourceService} from "../shared/team-resoruce.service";
import {UserProfile} from "../../profile/shared/user.model";
import { CouponResourceService } from '../../profile/shared/coupon-resoruce.service';

declare var jQuery: any;

@Component({
  selector: 'app-create-teammate',
  templateUrl: './create-teammate.component.html'
})
export class CreateTeammateComponent {
  @Input() user: User;
  @Input() team
  @Output() onCreated = new EventEmitter<UserProfile>();

  errors: any = {};
  showplanmenu: boolean;
  subscription_plan: string;
  subscription_amount: string;
  selectedsubscription:number=0;
  suberror: boolean;
  selectedlevel: boolean;
  showSaveMessage: boolean = false;
  isCouponApplied: boolean = false;
  teamCoupon: string = '';
  selectedSubscriptionplan: string = '';
  couponObj = {
    planId: '',
    planPrice: 0,
    discountedAmount: 0,
    finalAmount: 0,
    active: false,
    percentageDiscount: 0
  };


  constructor(
    protected toasts: ToastrService,
    protected route: ActivatedRoute, public router: Router,
    protected teamResourceService: TeamResourceService,
    private _changeDetectorRef: ChangeDetectorRef,
    private couponResourceService: CouponResourceService,
  ) {
  }
  ngOnInit() {
    if((this.team.owner.subscription_plan=='month')||(this.team.owner.subscription_plan=='annual')) {
      this.showplanmenu=true;
    } else{
      this.showplanmenu=false;
   }
  }

  checkCoupon(form: NgForm){
    if(form.value.subscription_plan === null){
      this.toasts.error('Please choose subscription plan');
    } else if(form.value.subscription_plan == 3){ //$59
      this.checkCouponCode(form.value.team_coupon, 59)
    } else if(form.value.subscription_plan == 4){ //$499
      this.checkCouponCode(form.value.team_coupon, 499)
    } else{
      this.toasts.error('Invalid coupon code');
    }
  }

  isInputEmpty(): boolean {
    return !this.teamCoupon?.trim();
  }

  checkCouponCode(couponvalue, planPrice) {
    if (!couponvalue || couponvalue.trim() === '') {
      this.toasts.error('Coupon code should not be empty');
      return;
    }
  
    this.couponResourceService.getCouponByName(couponvalue).toPromise()
      .then((response: any) => {
        
        this.couponObj.planId = this.couponUpdatePlanId(planPrice);
        this.couponObj.planPrice = planPrice;
        this.couponObj.discountedAmount = planPrice * response.body.coupon.percent_off / 100;
        this.couponObj.percentageDiscount = response.body.coupon.percent_off;
        this.couponObj.finalAmount = planPrice - this.couponObj.discountedAmount;
        this.couponObj.active = true;
        this.toasts.success(response.body.success);
        this.isCouponApplied = true;
        this.showSaveMessage = true;
      })
      .catch(err => {
        this.showSaveMessage = false;
        this.toasts.error(err.error.error);
      });
    }

    couponUpdatePlanId(planPrice) {
      switch(planPrice) {
        case 499:
          return 'annual_499_per_year'
        break;
        case 59:
          return 'month_59_per_month'
        break;
      }
    }

  createTeammate(form: NgForm) {
   if(form.value.subscription_plan === null && form.value.team_access_level!=5) {
     this.toasts.error('Please select subscription plan')
     return false;
   }
  if(form.value.team_access_level!=5)
  {
    if(this.showplanmenu==true) {
      if(this.selectedsubscription==0) {
        this.suberror=true
      }else{
        this.suberror=false
      }
    }else{
      this.suberror=false
    }
  }
  else{
    this.suberror=false
  }
    if(this.suberror==false)
    {
     this.teamResourceService.createTeammate(form.value).toPromise()
      .then((user: any) => {

        this.errors = {};
        form.reset();
        jQuery('#new-teammate').modal('hide');
        this.onCreated.emit(user);
        this.toasts.success('Teammate successfully created');
        this.showSaveMessage = false;
      })
      .catch((error) => {

        if (error.status == 422) {
          let errorData = error.error;
          this.errors = errorData;
          console.log(this.errors);

          try {
            for (let key in errorData) {
              let value = errorData[key];
              this.toasts.error(value.join(" "), key);
            }
          } catch (e) {
            console.log(e)
          }
        } else if (error.status == 403) {
          this.toasts.error(error.error.error.message)
        } else {
          this.toasts.error(error);
        }
      });
    }
    this._changeDetectorRef.detectChanges();
  }
  selectdPlan(ev) {
    if (ev && ev.value && ev.value.team_coupon !== undefined) {
      this.selectedSubscriptionplan = ev;
      if (ev.value.team_coupon) {
        if (ev == 1) {
          this.subscription_plan = 'month_59_per_month_team';
          this.subscription_amount = '$59/month';
        } else {
          this.subscription_plan = 'annual_499_per_year_team';
          this.subscription_amount = '$499/annual';
        }
        jQuery("#subteammate").modal('show');
        this.resetTeamCoupon();
      } else {
        console.error('Team coupon is not provided.');
      }
    } else {
      console.error('Invalid or undefined input for selectdPlan.');
    }
  }

  resetTeamCoupon() {
    this.teamCoupon = '';
  }

  selectdRole(id) {
    if(id==5) {
      this.selectedlevel=true;
    }
    else
    {
      this.selectedlevel=false;
    }
  }

}
