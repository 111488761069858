<style>
   #nameMonth .month {
    opacity: 0;
        }
</style>
 
<!-- <img src="{{ base64Image }}" style="position:absolute; width:100%;" /> -->

<!-- <img src="{{apiPath}}filesystem/{{trialline?.background_file.encrypt_id}}" style="position:absolute; width:100%;" /> -->

<!-- <img src="https://api.dev.trialline.net/index.php/v1/filesystem/eyJpdiI6IkU0UXBBc1k1WXFxVldlN0E3Q092QkE9PSIsInZhbHVlIjoiTnhlc2JkSndHaXoySjFIMFZ4MlBpQT09IiwibWFjIjoiODM2NmIyZGNiZGRiNzJhYjllYzVkYTdlNjcwNjM4YTc1OWUwNTFhZmZiMjUyNzA2MDdkMWIwN2E1M2E3NjNjMiJ9" style="position:absolute; width:100%;" /> -->

<!-- <img src="https://trialline-test.s3.amazonaws.com/1/1630626298/smart+phone+dark.jpg" style="position:absolute; width:100%;" /> -->

<div id="visualization_{{idVisualisation}}" #visualization
     [style.background-image]="trialline?.background_file && trialline?.background_file.type == 'image' ? 'url(' + apiPath + 'filesystem/' + trialline?.background_file.encrypt_id + ')' : ''"
     style="background-size: cover;
     background-repeat: no-repeat;"
>
    <div *ngIf="trialline?.background_file && trialline?.background_file.type == 'video'" style="position: fixed; width: 100%;">
      <video
        [attr.src]="apiPath + 'filesystem/' + trialline?.background_file.encrypt_id" width="100%" height="100%" autoplay loop muted>
      Your browser does not support the video tag.
      </video>
    </div>

  <!--@if ($trialline_id == 0 AND $is_owner)-->
  <!--<a href="/trialline/{{$redirect_id}}" class="vis-btn-back">Back to TrialLine</a>-->
  <!--@endif-->
  <div class="center-block monthBlock">
    <div id="nameMonth">
      <p class="trialName">{{ trialline?.name }}</p>
      <p class="month"></p>
    </div>
  </div>

  <div class="widget-zoom">
    <div class="btn-group-vertical margin-right-10">
      <a (click)="zoomPercent(-0.3)" id="zoom-plus" class="btn btn-default btn-icon">
        <i class="fa fa-search-plus"></i>
      </a>
      <a (click)="zoomPercent(0.3)" id="zoom-minus" class="btn btn-default btn-icon">
        <i class="fa fa-search-minus"></i>
      </a>
    </div>
  </div>

  <app-shared-trialline_navigation [timeline]="timeline" [minDate]="minDate" [maxDate]="maxDate" (onRangeChanged)="showMonthYear($event)"></app-shared-trialline_navigation>

</div>
