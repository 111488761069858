import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChange} from '@angular/core';
import {FileModel} from "../shared/file.model";
import {env} from "../../../../../.env";
import {NgForm} from "@angular/forms";
import {FileLibraryResourceService} from "../shared/file-library-resoruce.service";
import {ToastrService} from 'ngx-toastr';
import {AuthService} from "../../../auth/shared/auth.service";
import {FileUploader} from "ng2-file-upload";
import {DomSanitizer} from "@angular/platform-browser";

declare var jQuery: any;

@Component({
  selector: 'app-file_library-details',
  templateUrl: './details.component.html'
})
export class FileLibraryDetailsComponent implements OnChanges
{
  @Input() file: FileModel;
  @Output() onUpdated = new EventEmitter<FileModel>();

  modalId: string = '#file-edit-modal';

  public uploader: FileUploader;

  apiPath = env.apiUrl;

  constructor(
    private toasts: ToastrService,
    private fileLibraryResourceService: FileLibraryResourceService,
    private authService: AuthService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _sanitizer: DomSanitizer
  ) {}


  ngOnDestroy() {
    this._changeDetectorRef.detach();
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {

    this.file = changes['file'].currentValue;

    if (this.file) {

      this.uploader = new FileUploader({
        url:   env.apiUrl + 'file/' + this.file.id + '/update',
        authToken: 'Bearer ' + this.authService.token(),
        autoUpload: true
      });

      this.uploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {

        let body = JSON.parse(response).body;

        this._changeDetectorRef.detectChanges();

        this.file = body.file;
        this.onUpdated.emit(this.file);

        this._changeDetectorRef.detectChanges();

      };
    }
  }

  save(form: NgForm)
  {
    let data = form.value;

    this.fileLibraryResourceService.updateFile(this.file, data).toPromise()
      .then((file: FileModel) => {

        this.toasts.success('Successfully update');
        this.onUpdated.emit(file);
        jQuery(this.modalId).modal('hide');
      })
      .catch((error) => {

        this.toasts.error(error);
      });
  }
}
