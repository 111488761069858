<div id="event-more-modal-old" class="modal fade bs-modal-lg in show-style" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg" [class.fullscreen]="fullscreen">
    <div class="modal-content">
      <img src="assets/squares.gif" class="img spinners center-block" alt="spinner" *ngIf="processing">
      <button (click)="closePopup()" type="button" class="close close1" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true"><i class="fa fa-times-circle-o" aria-hidden="true"></i></span>
      </button>
      <button (click)="fullscreenToggle()" type="button" class="close close1 fullscrean"
              *ngIf="fullscreenButtonVisible">
        <span aria-hidden="true">
          <i class="fa fa-search-{{ fullscreen ? 'minus' : 'plus' }}" aria-hidden="true"></i>
        </span>
      </button>
      <div class="modal-header">
        <div class="row title">
          <div class="modal-title col-xs-6 pull-left" id="modals-windowsLabel" style="line-height: 1 !important;">
              <span>
                  {{ event?.title }}
              </span>
          </div>
          <div class="modal-date col-xs-6 col-sm-6" id="modals-windowsDate">
            <div class="pull-right" style="margin-right: 2vw; white-space: nowrap;">
              <div class="one_column col">
                <p class="first {{event?.if_start_days ? 'no_border' : ''}}">
                  <span class="date-time" *ngIf="event?.has_times && !event?.if_start_days">
                    {{ parseTime(event?.start_date) }}<br>
                  </span>
                  <span class="date-month">{{ parseMonth(event?.start_date) }}<br></span>
                  <span class="date-year">{{ parseYear(event?.start_date) }}</span>
                </p>

              </div>
              <div class="two_column col" *ngIf="!event?.if_start_days">
                <p class="second">
                  <span>{{ parseDay(event?.start_date) }}</span>
                </p>
              </div>
              <ng-container *ngIf="event?.end_date && showtwodate">
                <div class="three_column col">
                  <p class="deliver">&#8212;</p>
                </div>
                <div class="four_column col">
                  <p class="first">
                    <span class="date-time" *ngIf="event?.has_times_end && !event?.if_end_days">
                      {{ parseTime(event?.end_date) }}<br>
                    </span>
                    <span class="date-month">{{  parseMonth(event?.end_date) }}<br></span>
                    <span class="date-year">{{ parseYear(event?.end_date) }}</span>
                  </p>
                </div>
                <div class="five_column col" *ngIf="!event?.if_end_days">
                  <p class="second">
                    <span>{{ parseDay(event?.end_date) }}</span>
                  </p>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
        <div  class="title-second" style="text-shadow: 1px 1px 2px #000; opacity: .9;" [style.background-color]="event?.category?.background">
          <h5 *ngIf="event.category_name!='No Category'">{{ event?.category?.name }}</h5>
        </div>
        <div class="title-second"><h5></h5></div>
      </div>
      <div class="modal-body">
        <div class="tab-content">
          <div role="tabpanel" class="tab-pane" [class.active]="activeTab == 'description' || ! activeTab" id="info">
            <img *ngIf="event?.thumbnail_visible  && event.thumbnail_encryptid==null " class="imageinbox"
                 [src]="publicFolderUrl + event.thumbnail_attr.substring(1)" alt="">
            <img *ngIf="event?.thumbnail_visible  && event.thumbnail_encryptid!=null " class="imageinbox"
                 [src]=" event.thumbnail_encryptid && event.thumbnail_encryptid  !== null
                  ? publicFolderUrl+'index.php/v1/filesystem/'+ event.thumbnail_encryptid+'?preview=1' : ''" alt="">
            <p [innerHTML]="event?.description | safe: 'html'"></p>
          </div>
          <div role="tabpanel" class="tab-pane" [class.active]="activeTab == 'files'" id="files"
               *ngIf="event?.files?.length">
            <app-shared-files [moreFiles]='moreFiles' [files]="event?.files" [cloudFunc]="false"
                              [removeFunc]="false" [preview]="true"></app-shared-files>
          </div>
          <div role="tabpanel" class="tab-pane" [class.active]="activeTab == 'media'" id="media"
               *ngIf="event?.videos?.length">
            <app-shared-videos [videos]="event?.videos" [preview]="true" [removeFunc]="false"></app-shared-videos>
          </div>
          <div role="tabpanel" class="tab-pane" [class.active]="activeTab == 'comments'" id="comments"
               *ngIf="event?.comments?.length > 0">
            <ul class="view-panel-box">
              <li class="views">
                <div class="comments">
                  <ul class="comments-box">
                    <li *ngFor="let comment of event?.comments">
                      <div class="comments-box-avatar"
                           [attr.data-letters]="comment.avatar"></div>
                      <div class="comments-box-body">
                        <div class="comments-box-body-title">
                          <span class="name">{{ comment.author }}</span>
                          <span class="date">{{ comment.created_at|date:'short' }}</span>
                        </div>
                        <div class="comments-box-body-message">{{ comment.comment }}</div>
                      </div>
                    </li>
                    <li class="comments-form">
                      <form #commentForm="ngForm" (submit)="storeComment(commentForm)"
                            class="form-inline no-validate-comment-form">
                        <div class="col-md-10 comments-form-text">
                          <div class="form-group comments-form-group">
                              <textarea [ngModel] name="comment" class="form-control"
                                        placeholder="Your comments"
                                        maxlength="200"></textarea>
                          </div>
                        </div>
                        <div class="col-md-1 comments-form-button">
                          <button type="submit" class="btn blue btn-circle btn-sm pull-right">Post
                          </button>
                        </div>
                      </form>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div role="tabpanel" class="tab-pane" [class.active]="activeTab == 'tags'" id="tags"
               *ngIf="event?.tag_name?.length">
            <div class="row" style="margin-left:2px;">
              <span *ngFor="let tag of event?.tag_name">
                <a class="btn btn-default btn-outline btn-event" style="margin-top: 7px;">
                  {{tag.tag_name}} </a>
              </span>
           </div>
          </div>
          <div role="tabpanel" class="tab-pane" [class.active]="activeTab == 'notes'" id="notes"
               *ngIf="event?.notes && ! isView">
            <div class="form-body form-date" [innerHTML]="event?.notes | safe: 'html'"></div>
          </div>
        </div>
        <div class="row tabs">
          <div class="col-sm-7 pull-left borderleft">
          </div>
          <div class="col-sm-5 pull-right tabs-button">
            <div class="card">
              <ul class="nav nav-tabs" role="tablist">
                <li role="presentation" [class.active]="activeTab == 'notes'" *ngIf="event?.notes && ! isView">
                  <a (click)="activeTab = 'notes'" aria-controls="notes" role="tab" data-toggle="tab">
                    <p style="margin-top: 0.2vw !important;" class="numbers"></p>
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </a>
                </li>
                <li role="presentation" [class.active]="activeTab == 'tags'" *ngIf="event?.tag_name?.length > 0">
                  <a (click)="activeTab = 'tags'" aria-controls="tags" role="tab" data-toggle="tab">
                    <p style="margin-top: 0.2vw !important;"><span class="numbers">{{ event?.tag_name?.length }}</span></p>
                    <i class="fa fa-tags" aria-hidden="true"></i>
                  </a>
                </li>
                <li role="presentation" [class.active]="activeTab == 'comments'" *ngIf="event?.comments?.length > 0">
                  <a (click)="activeTab = 'comments'" aria-controls="comments" role="tab" data-toggle="tab">
                    <p style="margin-top: 0.2vw !important;"><span class="numbers">{{ event?.comments?.length }}</span></p>
                    <i class="fa fa-comment-o" aria-hidden="true"></i>
                  </a>
                </li>
                <li role="presentation" [class.active]="activeTab == 'media'" *ngIf="event?.videos?.length > 0">
                  <a (click)="activeTab = 'media'" aria-controls="media" role="tab" data-toggle="tab">
                    <p style="margin-top: 0.2vw !important;"><span class="numbers">{{ event?.videos?.length }}</span></p>
                    <i class="fa fa-play-circle" aria-hidden="true"></i>
                  </a>
                </li>
                <li role="presentation" [class.active]="activeTab == 'files'" *ngIf="event?.files?.length > 0">
                  <a (click)="activeTab = 'files'" aria-controls="files" role="tab" data-toggle="tab"
                     aria-expanded="false">
                    <p style="margin-top: 0.2vw !important;"><span class="numbers">{{ event?.files?.length }}</span></p>
                    <i class="fa fa-folder" aria-hidden="true"></i>
                  </a>
                </li>
                <li role="presentation" [class.active]="activeTab == 'description' || !activeTab">
                  <a (click)="activeTab = 'description'" aria-controls="info" role="tab" data-toggle="tab"
                     aria-expanded="true">
                    <p style="margin-top: 0.2vw !important;"></p>
                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer" [style.background-color]="event?.category?.background" style="opacity: .9">
        <button type="button" class="left pull-left" (click)="prevEvent()">
          <i style="margin-top: 0.4vw !important;" class="fa faarrow fa-arrow-left" aria-hidden="true"></i>
        </button>
        <button type="button" class="right pull-right" (click)="nextEvent()">
          <i style="margin-top: 0.4vw !important;" class="fa faarrow fa-arrow-right" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
</div>
