
<div class="col-sm-12 form_col signupForm">
  <div role="form" class="" id="contact_form" lang="en-US" dir="ltr">
    <form [formGroup]="registerForm" (ngSubmit)="register()" novalidate class="cmxform">
      <div class="row ">
        <div class="col-sm-6 form-group ">
          <label class="sr-only">First Name</label> <span class="form_ico"><img src="/assets/img/form_ico_2.png" alt=""
              width="16" height="16"></span>
          <span class="first_name"> <input formControlName="first_name" id="first_name" type="text" name="first_name"
              value="" size="40" class="form-control" aria-required="true" aria-invalid="false"
              placeholder="First Name (Required)"></span>

          <span id='error_fn'>*</span>
          <label id="errormsg_first_name" class="errormsg">{{errors?.first_name}}</label>
        </div>
        <div class="col-sm-6 form-group ">
          <label class="sr-only">Last Name</label> <span class="form_ico"><img src="/assets/img/form_ico_2.png" alt=""
              width="16" height="16"></span>
          <span class="last_name"> <input formControlName="last_name" id="last_name" type="text" name="last_name"
              value="" size="40" class="form-control" aria-required="true" aria-invalid="false"
              placeholder="Last Name (Required)"></span>
          <span id='error_lm'>*</span>
          <label id="errormsg_last_name" class="errormsg">{{errors?.last_name}}</label>
        </div>
      </div>
      <div class="row ">
        <div class="col-sm-6 form-group ">
          <label class="sr-only">Create Password</label> <span class="form_ico"><img src="/assets/img/form_ico_1.png"
              alt="" width="14" height="18"></span>
          <span class="create_password"> <input formControlName="password" id="password" type="password"
              name="create_password" value="" size="40" class="form-control" aria-required="true" aria-invalid="false"
              placeholder="Create Password (Required)" autocomplete="off"></span>
          <span id='error_p'>*</span>
          <label id="errormsg_password" class="errormsg">{{errors?.password}}</label>
        </div>
        <div class="col-sm-6 form-group ">
          <!-- form-width include this class everywhere in div -->
          <label class="sr-only">Confirm Password</label> <span class="form_ico"><img src="/assets/img/form_ico_1.png"
              width="14" height="18" alt=""></span>
          <span class="confirm_password"> <input formControlName="password_confirmation" id="password_confirmation"
              type="password" name="confirm_password" value="" size="40" class="form-control" aria-required="true"
              aria-invalid="false" placeholder="Confirm Password (Required)" autocomplete="off"></span>
          <span id='error_cp'>*</span>
          <label id="errormsg_confirm_password" class="errormsg"></label>
        </div>
      </div>
      <div class="row ">
        <div class="col-sm-6 form-group">
          <label class="sr-only">Email Address</label> <span class="form_ico"><img src="/assets/img/form_ico_3.png"
              alt="" width="15" height="12"></span>
          <span class="your_email"> <input formControlName="email" id="email" type="email" name="your_email" value=""
              size="40" class="form-control" aria-required="true" aria-invalid="false"
              placeholder="Email Address (Required)"></span>
          <span id='error_e'>*</span>
          <label id="errormsg_email" class="errormsg">{{errors?.email}}</label>
        </div>
        <div class="col-sm-6 form-group">
          <label class="sr-only">Phone</label>
          <!-- <input id="phone" name="phone" type="tel" size="40" class="form-control" aria-required="true" aria-invalid="false" /> -->
          <span class="form_ico"><img src="/assets/img/form_ico_4.png" alt="" width="15" height="15"></span>
          <span class="phone"> <input formControlName="phone" id="phone" type="tel" name="phone" value="" size="40"
              class="form-control ClsPhone" aria-required="true" aria-invalid="false" placeholder="Phone (Required)" /></span>

          <span id='error_phon'>*</span>
          <label id="errormsg_phone" class="errormsg">{{errors?.phone}}</label>

        </div>
      </div>
      <div class="row ">
        <div class="col-sm-6 form-group">
          <label class="sr-only">Company Name</label>
          <span class="form_ico">
            <img src="/assets/img/form_ico_5.png" alt="" width="17" height="17">
          </span>
          <span class="company_name">
            <input formControlName="firm" id="firm" type="text" name="company_name" value="" size="40"
              class="form-control" aria-required="true" aria-invalid="false" placeholder="Company Name">
          </span>
          <span id='error_f'>*</span>
        </div>
        <div class="col-sm-6 form-group">
            <label class="sr-only">Firm Size</label>
            <span class="form_ico">
                <img src="/assets/img/form_ico_5.png" alt="" width="17" height="17">
            </span>
            <span class="firm_size">
                <select formControlName="firm_size" id="firm_size" class="form-control" aria-required="true" aria-invalid="false">
                    <option *ngFor="let element of firm_size"
                        [disabled]="element === 'Choose Your Firm Size (Required)'">{{element}}
                    </option>
                </select>
            </span>
            <span id='error_firm_size'>*</span>
            <label id="errormsg_firm_size" class="errormsg">{{errors?.firm_size}}</label>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 form-group">
          <label class="sr-only">How did you find out about </label>
          <span class="form_ico"><img src="/assets/img/form_ico_2.png" alt="" width="16" height="16"></span>
          <span class="how_found">
            <select #how_found_v class="form-control" (change)="how_found_i.value = prepareValue($event.target.value)">
              <option value="{{element}}" *ngFor="let element of how_founds"
                [disabled]="element === 'How did you find out about TrialLine?'">{{element}}
              </option>
            </select>
          </span>
          <label id="errormsg_how_found" class="errormsg">{{errors?.how_found}}</label>
          <p [style.display]="howfindDisplay ? 'block' : 'none'">
            <span class="how_found">
              <input #how_found_i formControlName="how_found" type="text" [(ngModel)]=how_found_i.value size="40" 
                class="form-control" placeholder="Type text" [hidden]="how_found_v.value">
            </span>
          </p>
        </div>
        <div class="col-sm-6 form-group ">
          <label class="sr-only">Choose Your Practice Area</label>
          <span class="form_ico"><img src="/assets/img/form_ico_5.png" alt="" width="17" height="17"></span>
          <span class="practice_area">
            <select #practice_area_v class="form-control"
              (change)="practice_area_i.value = prepareValue($event.target.value)">
              <option value="{{element}}"
                *ngFor="let element of practice_areas" [disabled]="element === 'Choose Your Practice Area'">{{element}}
              </option>
            </select>
          </span>
          <label id="errormsg_area" class="errormsg">{{errors?.practice_area}}</label>
          <span class="how_found form-group" [style.display]="practiceAreaDisplay ? 'block' : 'none'">
            <input #practice_area_i [hidden]="practice_area_v.value" formControlName="practice_area" type="text"
              name="practice_area" [(ngModel)]="practice_area_i.value" size="40" class="form-control"
              placeholder="Type text">
          </span>
        </div>
      </div>
      <div class="form-group fixed-error-message flex " transformer="inline" style="padding-bottom: 13px;">
        <input formControlName="rules" name="rules" required="" type="checkbox" style="-webkit-appearance: checkbox;"
          class="ng-untouched ng-pristine ng-invalid">
        <span>
          <a target="_blank" href="https://trialline.net/privacy-policy">I agree to the Privacy Policy.</a>
          <span style="font-size: 19px;">*</span>
          <label id="errormsg_rules " class="errormsg"
            style='text-transform: none;font-size: small;font-family: "Open Sans", sans-serif;'>{{errors?.rules}}</label>
        </span>
        <div class="help-block"></div>
      </div>
      <div class="form_buttons_block">
        <div class="col-sm-6 form-group form-width recuptcha">
          <re-captcha (resolved)="resolved($event)" name="captcha" siteKey="6LeQQBkUAAAAAGqShWJlo0s7lFmx9LFSY4Juw2Qv"
            required></re-captcha>
          <span id="cerrror">*</span>
          <p><label id="errormsg_captchaerr" class="errormsg">{{captchaerr}}</label></p>
        </div>
        <div class="col-sm-6 form-group form-width butt">
          <!-- onclick="gtag_report_conversion()" -->
          <button type="submit" name="submit" id="submit" class="btn btn-info">
            submit <img src="assets/squares.gif" alt="spinner" width="30px" *ngIf="processing">
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<div id="congratulations-modal" class="modal fade bs-modal-lg in" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header ">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
        <h3 class="modal-title">Congratulations!</h3>
      </div>
      <div class="modal-body">
        <h4>Congratulations <span id="username">{{ user?.first_name + ' ' + user?.last_name }}</span>! Your
          TrialLine account is almost ready!</h4>
        <p>
          Before you can start using TrialLine, <b>you must activate your account</b>.
          Check your inbox at <b><span class="useremail">{{ user?.email }}</span></b> and click the activation
          link provided.
          If you do not activate your site within two days, this account will
          be deleted and you will have to sign up again.
        </p>
        <h4>Still waiting for your activation email?</h4>
        <p>
          If you haven’t received your email yet, there are a number of things you can do:
        </p>
        <ul>
          <li>Wait a little longer. It sounds silly, but sometimes delivery of email can be delayed by
            processes outside of our control. So give it a few more minutes.
          </li>
          <li>Check your junk or spam folder - including any on your server. Sometimes emails from new
            accounts wind up there by mistake.
          </li>
          <li>
            Double check you've entered your email address correctly.
            We have sent your activation email to <span class="useremail">{{ user?.email }}</span>
            Does that look right? If not, you may need to start over.
            You may also <a (click)="resendActivationEmail(user?.email)">click here</a> to request that our
            system resend the activation email.
            If that doesn't work, you may <a href="#contact" class="contact-us-button">click here</a> to
            contact customer support.
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
