<!-- BEGIN PROFILE SIDEBAR -->
<div class="profile-sidebar">
  <!-- PORTLET MAIN -->
  <div class="portlet light profile-sidebar-portlet ">
    <!-- SIDEBAR USERPIC -->
    <div class="profile-userpic">
      <img class="img-responsive" alt="">
      <div [attr.data-letters]="user?.first_name.charAt(0).toUpperCase() + '' + user?.last_name.charAt(0).toUpperCase()" class="profile-avatar"></div>
    </div>
    <!-- END SIDEBAR USERPIC -->
    <!-- SIDEBAR USER TITLE -->
    <div class="profile-usertitle">
      <div class="profile-usertitle-name">{{ user?.first_name + ' ' + user?.last_name }}</div>
    </div>
    <!-- END SIDEBAR USER TITLE -->
    <!-- SIDEBAR MENU -->
    <div class="profile-usermenu">
      <ul class="nav">
        <li [ngClass]="{active: isActiveRoute('/profile/settings')}">
          <a [routerLink]="['/profile/settings']">
            <i class="icon-home"></i> Account Settings </a>
        </li>
        <ng-container *ngIf="_layout.user?.user_type != 3  || _layout.user?.team_access_level==4">
        
          <li *ngIf="_layout.user?.user_type !=5" [ngClass]="{active: isActiveRoute('/profile/billing')}">
            <a [routerLink]="['/profile/billing']">
              <i class="icon-settings"></i> Billing Settings </a>
          </li>
          <li  *ngIf="_layout.user?.user_type !=5" [ngClass]="{active: isActiveRoute('/profile/subscription')}">
            <a [routerLink]="['/profile/subscription']">
              <i class="icon-settings"></i> Subscription </a>
          </li>
          <li *ngIf="_layout.user?.user_type !=5" [ngClass]="{active: isActiveRoute('/profile/clio')}">
            <a [routerLink]="['/profile/clio']">
              <i class="icon-settings"></i> Third-Party Applications </a>
          </li>
        
        </ng-container>
        <li>
          <a href="/terms-of-use" target="_blank">
            <i class="icon-settings"></i> Terms of Use
          </a>
        </li>
      </ul>
    </div>
    <!-- END MENU -->
  </div>
  <!-- END PORTLET MAIN -->

  <!-- PORTLET MAIN -->
  <div class="portlet light ">
    <!-- STAT -->
    <div class="row list-separated profile-stat">
      <div class="col-md-6 col-sm-6 col-xs-6">
        <div class="uppercase profile-stat-title"> {{ user.counts.triallines }} </div>
        <div class="uppercase profile-stat-text"> TrialLines</div>
      </div>
      <div class="col-md-6 col-sm-6 col-xs-6">
        <div class="uppercase profile-stat-title"> {{ user.counts.events }} </div>
        <div class="uppercase profile-stat-text"> Events</div>
      </div>
    </div>
    <!-- END STAT -->
  </div>
  <!-- END PORTLET MAIN -->
</div>
<!-- END BEGIN PROFILE SIDEBAR -->
