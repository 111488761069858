import {Component} from '@angular/core';
import {AuthResourceService} from "../../../auth/shared/auth-resoruce.service";
import {Router} from "@angular/router";
import {ToastrService} from 'ngx-toastr';

import {AuthService} from "../../../auth/shared/auth.service";

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: [
    './login-form.component.css'
  ]
})
export class LoginFormComponent
{
  errors: any = {};
  processing: boolean = false;

  constructor(
    protected authResourceService: AuthResourceService,
    protected router: Router,
    protected toasts: ToastrService,
    protected authService: AuthService
  ) {}

  login(form: any)
  {
    this.processing = true;
    this.authResourceService.login(form.value).toPromise().then(
      (response: any)      =>  this.handleLogin(response),
      (error: Response)     =>  this.handleError(error)
    );
  }

  handleLogin(response: any)
  {

    this.authService.store(response.token);

    // this.toasts.success("Logged in", null, {
    //   showCloseButton: true,
    //   positionClass: 'toast-bottom-full-width',
    //   animate: 'flyRight'
    // });
    this.router.navigateByUrl('/dashboard');

    this.processing = false;
  }

  handleError(error: any)
  {
    this.processing = false;

    if (error.status == 422) {
      let errorData: any = error.error;
      this.errors = errorData.data;
    }
  }

}
