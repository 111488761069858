import {Component, OnDestroy, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Title} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";
import {View} from "../../shared/view.model";
import {ViewsResourceService} from "./shared/views-resoruce.service";
import {ClipboardService} from "ngx-clipboard";
import {env} from "../../../../../.env";
import {AuthService} from "../../../auth/shared/auth.service";

declare var jQuery:any;
declare var _:any;

@Component({
  selector: 'app-trialline-views',
  templateUrl: './views.component.html',
  styleUrls: ['./views.component.scss']
})
export class TriallineViewsComponent implements OnInit, OnDestroy
{
  views: View[];

  sub: any;
  selectedCategories: any;
  triallineId: number;
  shareId: number;
  saveViewModal: boolean = false;
  shareView: boolean = false;
  pyramidView: boolean = true;
  appUrl: string = env.applicationUrl;
  saveViewModalId = '#save-view-modal';

  constructor(
    private route: ActivatedRoute,
    private title: Title,
    private viewsResourceService: ViewsResourceService,
    private toasts: ToastrService,
    private clipboard: ClipboardService,
    public auth: AuthService,
  ) { title.setTitle('TrialLine | Views') }

  ngOnInit() {

    this.route.data.subscribe((data: {views: View[]}) => {

      this.views = data.views;
      const new_array = this.views.filter((item)=>{
        if(item.viewsort !== 1){
            return item;
        }
        });

        const actual_item = this.views.filter((item)=>{
          if(item.viewsort === 1){
              return item;
          }
        });

        this.views  = actual_item.concat(new_array);

    });

    this.sub = this.route.parent.params.subscribe(params => {
      this.triallineId = +params["trialline_id"];
    });
  }

  ngOnDestroy() {

    this.sub.unsubscribe();
  }

  deleteView(view: View) {

    this.viewsResourceService.deleteView(this.triallineId, view.id).toPromise()
      .then((res: any) => {

        let index = this.views.indexOf(this.views.find(e => e.id == view.id));
        this.views.splice(index, 1);
        this.toasts.success('Successfully deleted')
      })
      .catch((e: any) => { console.log(e) })
  }

  updatePassword(view: View, password) {

    this.viewsResourceService.updatePassword(this.triallineId, view.id, password).toPromise()
      .then((res: any) => {

        let index = this.views.indexOf(this.views.find(e => e.id == view.id));
        this.views[index].password = password;
        this.toasts.success('Password changed')
      })
      .catch((e: any) => { console.log(e) })
  }

  generatePassword(view: View) {

    let password = Math.random().toString(36).substring(7).slice(1, 6);
    view.password = password;

    this.updatePassword(view, password);
  }

  copyValue(view: View, value) {

    switch (value) {

      case 'url': {

        this.toClipboard(this.appUrl + 'view/' + view.uuid + '?share=true');
        break;
      }

      default: { this.toClipboard(view[value]); }
    }
  }

  accessLine(view){
    this.viewsResourceService.updatePassword(this.triallineId, view.id, 'free', !view.public).toPromise()
      .then((res: any) => {
        let index = this.views.indexOf(this.views.find(e => e.id == view.id));
        this.views[index].public =  !view.public;
        this.toasts.success('Access changed.')
      })
      .catch((e: any) => { console.log(e) })
  }

  publicAccess(){
    return false;
  }

  toClipboard(value) {

    this.clipboard.copyFromContent(value);
    this.toasts.success('Copied');
  }

  sharingView(id) {
      this.shareId = id;
      this.saveViewModal = true;
      this.shareView = true;
      jQuery(this.saveViewModalId).modal('show');
  }

  changeView(view){
    console.log('view', view);
    const new_value = view.view;
    this.viewsResourceService.changeView(this.triallineId, view.id, view.view).toPromise()
      .then((res: any) => {
        let index = this.views.indexOf(this.views.find(e => e.id == view.id));
        this.views[index].view =  view.view ? 0 : 1;
        this.toasts.success('View changed.')
      })
      .catch((e: any) => { console.log(e) })
  }
}
