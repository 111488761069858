import { Directive, ElementRef, Input, OnChanges, SimpleChanges, Renderer2 } from '@angular/core';

import {InlineTransformer} from "./shared/inline.transformer";
import {DefaultTransformer} from "./shared/default.transformer";

// @ts-ignore
@Directive({
  selector: '[hubValidator]'
})
export class HubValidatorDirective implements OnChanges
{
  @Input('hubValidator') hubValidator: any;
  @Input('transformer') transformer: string;

  constructor(public el: ElementRef, public renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void
  {
    switch(this.transformer)
    {
      case 'inline':
        new InlineTransformer(this.renderer, this.hubValidator, this.el.nativeElement);
        break;
      default:
        new DefaultTransformer(this.renderer, this.hubValidator, this.el.nativeElement)
    }
  }
}
