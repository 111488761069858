
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";





import {RestService} from "../../../rest/shared/rest.service";

@Injectable()
export class TeamResourceService
{
  roles = {
    1: 'User',
    2: 'Manager',
    3: 'Owner'
  };

  constructor(public http: RestService) {}

  getTeam()
  {
    return this.http.get('team', {}, false).pipe(map((response: any) => {
      let body = response.body;

      return body.team;
    }));
  }

  getTeamAccess(triallineId)
  {
    return this.http.get('team/access/'+ triallineId , {}, false).pipe(map((response: any) => {
      let body = response.body;

      return body.access;
    }));
  }
 
  createTeammate(data)
  {
    return this.http.post('team', data).pipe(map((response: any) => {
      let body = response.body;

      return body.user;
    }));
  }

  resendInvitation(data)
  {
    return this.http.post('team/resend-account-invitation', data).pipe(map((response: any) => {
      let body = response.body;

      return body.user;
    }));
  }

  deleteTeammate(member_id)
  {
    return this.http.delete('team?member_id=' + member_id).pipe(map(
      r => r.body
    ));
  }

  getRoleName(index) {

    return this.roles[index];
  }

  UpdateUserRole(member_id,userRole) {

    return this.http.get('team/updateTeamMember?member_id='+member_id+'&team_access_level='+userRole, {}, false).pipe(map((response: any) => {
      let body = response.body;

      return body.team;
    }));
  }

  subscribeteammate(data)
  {

    return this.http.post('billing/teamembersubscription', data).pipe(map((response: any) => {
      let body = response.body;

      return body.user;
    }));

  }

  unsubscribeteammate(id)
  {
    return this.http.post('billing/cancleteamembersubscription',id).pipe(map(
      r => r.body
    ));

  }
  updateTeammate(data)
  {

    return this.http.post('team/editTeamMember', data).pipe(map((response: any) => {
      let body = response.body;

      return body.user;
    }));
  }

  UpdateguestUserRole(member_id,userRole) {

    return this.http.get('team/updateGuestTeamMember?member_id='+member_id+'&team_access_level='+userRole, {}, false).pipe(map((response: any) => {
      let body = response.body;

      return body.team;
    }));
  }

  accessTeam(data)
  {

    return this.http.post('team/access-team', data).pipe(map((response: any) => {
      let body = response.body;

      return body.user;
    }));

  }

  createAccessTeam(data)
  {
    return this.http.post('team/create-access-team', data).pipe(map((response: any) => {
      let body = response.body;

      return body.user;
    }));
  }
}
