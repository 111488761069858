<style>
  .createbtn {
    width: 115px;
    height: 25px;
    background:#044e89;
    padding: 7px;
    text-align: center;
    border-radius: 5px;
    color: white;
 }
</style>
<!-- <div class="portlet-title">
  Team
</div>
<button (click)="openTeammateModal()" class="btn btn-primary btn-sm pull-right">Create Guest User</button>
 -->
<div class="row">
  <div class="col-sm-10 portlet-title">
     Team
  </div>
  <!-- <div class="col-sm-2" >
    <span class="createbtn" data-toggle="modal" data-target="#newguest">Create Guest User</span>
  </div> -->
</div>

<div class="portlet-body">
  <table class="table table-hover" id="worked">
    <thead>
    <tr>
      <th>First Name</th>
      <th>Last Name</th>
      <th>Email</th>
      <!--<th>Role</th>-->
      <th>Access</th>
    </tr>
    </thead>
    <tbody>
    <tr class="info">
      <td>{{ team?.owner.first_name }}</td>
      <td>{{ team?.owner.last_name }}</td>
      <td>{{ team?.owner.email }}</td>
      <td>
        <span class="label label-success list-team">Owner</span>
      </td>
    </tr>
    <tr *ngFor="let user of team?.users">
      <td>
        {{ user.first_name }}
      </td>
      <td>
        {{ user.last_name }}
      </td>
      <td>
        {{ user.email }}
      </td>
      <td>
        <button *ngIf="user?.user_type == 3 && user?.team_access_level != 3 ||user?.user_type == 5"
          (click)="$event.preventDefault(); toggle(user)"
          class="btn list-team"
          [disabled]='user?.user_type == 5 && user?.triallines_count > 0 && teamIds.indexOf(user.id)==-1 '
          [class.btn-danger]="teamIds.indexOf(user.id) > -1 ? true : null"
          [class.btn-success]="teamIds.indexOf(user.id) == -1 ? true : null">
          <i class="fa"
             [class.fa-times]="teamIds.indexOf(user.id) > -1 ? true : null"
             [class.fa-check]="teamIds.indexOf(user.id) == -1 ? true : null" aria-hidden="true">
            {{ teamIds.indexOf(user.id) > -1 ? 'Revoke Access' : 'Give Access' }}
          </i>
        </button>

        <span class="label label-default" *ngIf="user.team_access_level == 3">Owner</span>
      </td>
    </tr>
    <tr *ngFor="let share of team?.share">
      <td>
        {{ share.first_name }}
      </td>
      <td>
        {{ share.last_name }}
      </td>
      <td>
        {{ share.email }}
      </td>
      <td>
        <button class="{{ share.status_access ? 'blue' : 'label-default' }} btn list-team"  (click)="$event.preventDefault(); toggleAccess(share)" >
          <i class="fa" aria-hidden="true"></i>{{'Revoke Access' }}</button>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<!-- {{user?.email}} == {{ team?.owner.email }} *ngIf="user?.email == team?.owner.email" --> 
<div *ngIf="team?.owner.email && user?.email == team?.owner.email">
  <div class="btn blue share-btn" (click)="createnewaccessinteem();">
    <span> Share With Outside TrialLine User </span>
  </div>
</div>

<div id="newguest" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header ">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">Invite Free Guest User</h4>
      </div>

        <div class="modal-body">

            <div class="form-group">
              <label>First name</label>
              <input type="text" name="first_name" class="form-control" id='fname'>
              <span class="help-block" id="fname_error" style="color: red;"></span>
            </div>
            <div class="form-group">
              <label>Last name</label>
              <input type="text" name="last_name" class="form-control" id='lname'>
              <span class="help-block" id="lname_error" style="color: red;"></span>
            </div>
            <div class="form-group">
              <label>Email</label>
              <input type="email" name="email" class="form-control" id='email'>
              <span class="help-block" id="email_error" style="color: red;"></span>
            </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            <span class="createbtn" (click)='invite();'>Invite</span>
          </div>
        </div>
    </div>
  </div>
</div>
<span defaultOverlayTarget></span>

<app-create-access-team   [triallineId]="triallineId" (onCreated)="addAccess($event)" ></app-create-access-team>
