import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TriallineEditComponent} from "./edit/edit.component";
import {TriallineResolver} from "./shared/trialline.resolver";
import {EventsResolver} from "./events/shared/events.resolver";
import {TriallineEventsComponent} from "./events/events.component";
import {ManageResolver} from "./manage/shared/manage.resolver";
import {TriallineManageComponent} from "./manage/manage.component";
import {MainComponent} from "../../layouts/main/main.component";
import {TriallineViewsComponent} from "./views/views.component";
import {ViewsResolver} from "./views/shared/views.resolver";
import {BackgroundsResolver} from "./edit/shared/backgrounds.resolver";
import {EditCanActivate} from "./shared/edit.guard";
import {TlResolver} from "./events/shared/tl.resolver";

const routes: Routes = [
  { path: '', component: TriallineManageComponent, resolve: { trialline: ManageResolver } },
  { path: 'views', component: TriallineViewsComponent, resolve: { views: ViewsResolver } },
  {
    path: '',
    component: MainComponent,
    children: [
      { path: 'edit', component: TriallineEditComponent, canActivate: [ EditCanActivate ], resolve: { trialline: TriallineResolver, backgrounds: BackgroundsResolver } },
      { path: 'events', component: TriallineEventsComponent, resolve: { events: EventsResolver, tl: TlResolver } }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class TriallineRoutingModule {}
