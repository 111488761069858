<div class="login form_col">
  <form #loginForm="ngForm" name="loginform" id="loginform" (submit)="login(loginForm)" novalidate>
    <div class="col-xs-12 form-group form-width" [hubValidator]="errors?.email" transformer="inline">
      <p>
        <label class="sr-only">Email Address</label> <span class="form_ico"><img src="/assets/img/form_ico_3.png" alt=""></span>
        <span class="your_email">
          <input id="user_login"  type="email" name="email" value="" size="40" class="form-control" aria-required="true" aria-invalid="false" placeholder="Email Address">
        </span>
      </p>
    </div>
    <div class="col-xs-12 form-group form-width" [hubValidator]="errors?.password" transformer="inline">
      <p>
        <label class="sr-only">Create Password</label> <span class="form_ico"><img src="/assets/img/form_ico_1.png" alt=""></span>
        <span class="create_password">
          <input id="user_pass"  type="password" name="login_password" value="" size="40" class="form-control" aria-required="true" aria-invalid="false" placeholder="Password" autocomplete="off">
        </span>
      </p>
    </div>
    <div class="form-group">
      <div>
        <button type="submit" name="submit" class="button btn btn-default btn-xs login_button">Log in</button>
        <img src="assets/squares.gif" alt="spinner" width="25px" *ngIf="processing">

        <!--TODO Facebook completely delete???-->
        <!--<span style="padding: 0 10px;">or via</span>-->
        <!--<a class="btn btn-xs btn-blue" data-original-title="facebook" href="/auth/facebook">-->
          <!--<i class="fa fa-facebook"></i>-->
        <!--</a>-->
      </div>
    </div>
    <div>Forgot password?<br>
      <a [routerLink]="['/reset/password']" data-dismiss="modal" aria-label="Close">Reset Password</a>
    </div>
    <div>New User?<br>
      <a onclick="window.location.href='#contact'" data-dismiss="modal" aria-label="Close">
        Create New Account
      </a>
    </div>
  </form>
</div>
