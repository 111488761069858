<div id="contacts-shared-show-modal" class="modal fade bs-modal-lg in" tabindex="-1" role="dialog" style="{{isModaStyle}}">
  <div class="modal-dialog modal-md modal-preview modal-dialog-show-contact">
    <div class="modal-content content-show-contact">
      <div class="modal-header ">
        <button (click)="closeContactModal()" type="button" class="close"><span
          aria-hidden="true">&times;</span></button>
        <h4 class="modal-title"><span class="file-title">{{ contact?.name }}</span></h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-4">
            <div class="fileinput-preview " style="width: 250px; position: relative;">
              <div class="tile-body">
                <img #thumbnail_contact [attr.src]="contact_thumbnail" class="img-responsive" width="250px"
                     alt="thumbnail_contact">
              </div>
            </div>
            <div class="panel-body">
              <h4>Contact Category:</h4>
              <span class="second-block" *ngIf="contact">
                <span *ngFor="let category of contact.categories; last as isLast">
                  {{ category.name }}{{!isLast ? ', ' : ''}}
                </span>
              </span>
            </div>
          </div>
          <div class="col-md-8">
            <div class="panel-body panel-body-title">
              <h4>Contact Details</h4>
            </div>
            <ul class="info">
              <li><span class="first-block">Contact Name:</span> <span class="second-block">{{contact?.name}}</span>
              </li>
              <li><span class="first-block">Contact Phone:</span> <span class="second-block">{{contact?.phone}}</span>
              </li>
              <li><span class="first-block">Contact Email:</span> <span class="second-block">{{contact?.email}}</span>
              </li>
              <li><span class="first-block">Contact Website:</span> <span
                class="second-block">  {{contact?.website}}</span></li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-md-7">
            <div class="panel-body panel-body-title">
              <h4>Contact Location Details</h4>
            </div>
            <ul class="info">
              <li><span class="first-block">Location Name:</span> <span
                class="second-block">{{contact?.facility_name}}</span></li>
              <li><span class="first-block">Location Address:</span> <span
                class="second-block">{{contact?.address}}</span></li>
              <li><span class="first-block">Suite Number:</span> <span
                class="second-block">{{contact?.office_number}}</span></li>
              <li><span class="first-block">City:</span> <span class="second-block">{{contact?.city}}</span></li>
              <li><span class="first-block">State:</span> <span class="second-block">{{contact?.state}}</span></li>
              <li><span class="first-block">Zip code:</span> <span
                class="second-block">{{contact?.postal_code}}</span></li>
            </ul>
          </div>
          <div class="col-md-5">
            <div class="panel-body panel-body-note">
              <h4>Notes</h4>
            </div>
            <div class="panel-body">
              <div class="event-text" [innerHTML]="contact?.notes"></div>
            </div>
          </div>
          </div>
          <!-- BEGIN PORTLET -->
          <div class="row">
            <div class="panel-body">
              <div class="attach-wrap">
                <h5>Attachments</h5>
                <table class="table">
                  <thead>
                  <tr>
                    <th>File name</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let file of contact?.private_files">
                    <td>
                      <input class="form-control input-sm" type="text" disabled
                             [attr.value]="file.file.title" placeholder="Attach Text">
                    </td>
                    <td nowrap>
                      <a [attr.href]="apiPath + 'filesystem/' + file.file.encrypt_id + '/download'"
                         class="btn btn-success btn-xs">
                        <span class="glyphicon glyphicon-download"></span> Download
                      </a>
                      <span> </span>
                      <a  (click)="ShowInPreviewer(file.file)"  class="btn btn-success btn-xs btn-show-prev "> Show </a>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        <div *ngIf="showEditButton" >
          <a class="btn blue btn-outline btn-w180" (click)="edit()">Edit Contact</a>
        </div>
        <div class="file-prev">
          <app-shared-previewer [file]="filePreviewer" [showDownload]="true" (onClosePreviewer)="closePreviewerFile()"  *ngIf="preview"></app-shared-previewer>
        </div>
      </div>
      <!-- END PORTLET -->
    </div>
  </div><!-- /.modal-content -->
</div><!-- /.modal-dialog -->





