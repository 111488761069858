import {
  AfterViewInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Component,
  SimpleChange,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  OnInit
} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TriallineResourceService} from "../shared/trialline-resoruce.service";
import {env} from "../../../../../.env";
import {FileUploader} from "ng2-file-upload";
import {AuthService} from "../../../auth/shared/auth.service";
import {Modal} from 'ngx-modialog-7/plugins/bootstrap';
import {DomSanitizer} from "@angular/platform-browser";
import {Contacts} from "../../shared/contacts.model";
import {FileModel} from "../../file-library/shared/file.model";
import * as JSZip from "jszip";
import JSZipUtils from "jszip-utils"
import { saveAs, encodeBase64 } from 'file-saver';
import {HelpersService} from "../../../services/helpers.service";

declare var jQuery:any;

@Component({
  selector: 'app-trialline-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TriallineContactsComponent implements AfterViewInit, OnInit
{
  @Input() contacts: Contacts[];
  @Input() contactEvents: Event[];
  @Input() filesUpdate: FileModel;
  @Output() onUpdatedFile = new EventEmitter<File>();

  publicFolderUrl = env.publicFolderUrl;
  publicImages = env.publicImages;
  apiPath = env.apiUrl;
  private modalId = '#contact-edit-modal';
  errors: any = {};
  contact: Contacts = new Contacts();
  color: string = '#'+(Math.random()*0xFFFFFF<<0).toString(16);
  contactValid:any;
  orderValue: string = 'asc';
  order: string = 'name';
  keyword:any = 'name';
  categories: any = [];
  filteredCategory: string;
  currentStatus: string = 'name';
  contactShow: Contacts;
  showEdit: boolean = false;
  editContact: Contacts;
  uploader: FileUploader;
  filePreviewer:FileModel;
  fileL:FileModel[];
  preview:boolean = false;

  constructor(
    private helpersService: HelpersService,
    protected toasts: ToastrService,
    private triallineResoureService: TriallineResourceService,
    private authService: AuthService,
    private _changeDetectorRef: ChangeDetectorRef,
    public modal: Modal,
    private _sanitizer: DomSanitizer
  ) {

  }

  ngOnDestroy() {
    this._changeDetectorRef.detach();
  }

  ngAfterViewInit() {
    jQuery('[data-toggle="tooltip"]').tooltip({
      animated: 'fade',
      placement: 'right',
      html: true
    });
  }
  ngOnInit() {
    this.contacts.sort( this.helpersService.compareNameAb );
    this.contacts.forEach((item) => {
      item.categories.forEach((category) => {
        if (!this.categories.includes(category.name)) {
          this.categories.push(category.name);
        }
      })
    })
  }

  getSortIcon(column: string) {
    return this._sanitizer.bypassSecurityTrustHtml('<i class="fa fa-sort' +
      (column == this.order ? '-' + this.orderValue : '')  +'" '+
      'aria-hidden="true"></i>');
  }

  reorder(column: string) {
    this.currentStatus = column;
    this.orderValue = this.orderValue === 'desc' ? 'asc' : 'desc';
    this.order = column;
    if (this.orderValue == 'asc' && column == 'name') {
      this.contacts.sort( this.helpersService.compareNameAb );
    } else if (this.orderValue == 'desc' && column == 'name'){
      this.contacts.sort( this.helpersService.compareNameBa );
    }
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    if(this.filesUpdate) {
      for (let i = 0; i < this.contacts.length; i++) {
        if (this.contacts[i].private_files) {
          for (let f = 0; f < this.contacts[i].private_files.length; f++) {
            if(this.contacts[i].private_files[f].file_id === this.filesUpdate.id) {
              this.contacts[i].private_files[f].attach_text = this.filesUpdate.title
              this._changeDetectorRef.detectChanges();
            }
          }
        }
      }
    }
  }

  clearCategory(event){
    this.filteredCategory = '';
    this.contacts.map((item) => {
      item.dshow = false;
      return item;
    })
  }

  changeCategory(event){
   if (!event.length) {
     this.filteredCategory = '';
     this.contacts.map((item) => {
       item.dshow = false;
       return item;
     })
   }
  }

  selectedCategory(event){
    this.filteredCategory = event;
    this.contacts.map((item) => {
      item.dshow = false;
      let check = false;
      item.categories.forEach((category) => {
        if (event == category.name) {
          check = true;
        }
      })
      if (!check) {
        item.dshow = true;
      }
      console.log(item);
      return item;
    })
  }


  getSafeThumbnailUrl(category) {
    if (!category.thumbnail_visible) {
      return this.publicImages + '/images/start-event/contact_thumbnail.png'
    }
    let obj;
    if( category.thumbnail_encryptid != null && category.thumbnail_encryptid != false ) {
      obj = this.publicFolderUrl + 'index.php/v1/filesystem/' +
        category.thumbnail_encryptid + '?preview=1'
    } else {
      obj =  this.publicFolderUrl +
        (category.thumbnail ? category.thumbnail.substring(1).replace("'", "\\'") : '')
    }
    return obj;
  }

  getSafeUrl(category) {
    let obj = this.getSafeThumbnailUrl(category);
    return "<img src='" + obj + "' style='height: 130px;'/>";
  }

  getSafeFileUrl(file) {
    if (file.file && file.file.type=='pdf') {
      return "<img src='assets/pdfimages/pdf1.png' style='height: 130px;'/>";
    }
    return "<img src='" + this.apiPath + 'filesystem/' + file.file.encrypt_id + "?preview=1' style='height: 130px;'/>";
  }

  closeModal(Contact: Contacts) {
    this.showEdit = false;
  }

  updateContact(contact: Contacts) {
    let index = this.contacts.indexOf(this.contacts.find(e => e.id == contact.id));
   // TODO check filter
    contact.categories.forEach((category) => {
      if (this.filteredCategory && category.name == this.filteredCategory) {
        contact.dshow = false;
      } else if (this.filteredCategory) {
        contact.dshow = true;
      }
    })
    this.contacts[index] = contact;
    this.categories = [];
    this.contacts.forEach((item) => {
      item.categories.forEach((category) => {
        if (!this.categories.includes(category.name)) {
          this.categories.push(category.name);
        }
      })
    })
    this._changeDetectorRef.detectChanges();
    this.ngAfterViewInit();
  }

  createContact(contact: Contacts) {
    // this.contacts.push(contact);
    this.contacts = [...this.contacts, contact];
    this.categories = [];
    this.contacts.forEach((item) => {
      item.categories.forEach((category) => {
        if (!this.categories.includes(category.name)) {
          this.categories.push(category.name);
        }
      })
    })
  }

  show(contact) {
    if (contact !== undefined) {
      this.contactShow = contact;
      jQuery('#contacts-shared-show-modal').modal('show');
    }
  }

  edit(contact: Contacts) {
    this.editContact = contact;
    this.showEdit = true;
    this._changeDetectorRef.detectChanges();
    jQuery(this.modalId).modal('show');
  }

  openEdit(contact: Contacts) {
    this.editContact = contact;
    this.showEdit = true;
    this._changeDetectorRef.detectChanges();
    jQuery(this.modalId).modal('show');
  }

  create() {
    this.editContact = new Contacts();
    this.showEdit = true;
    this._changeDetectorRef.detectChanges();
    jQuery(this.modalId).modal('show');
  }

  updatedFile(file){
    this.onUpdatedFile.emit(file);
  }

  deleteContact(contact: Contacts) {

    const dialogRef = this.modal.confirm()
      .size('lg')
      .isBlocking(true)
      .showClose(true)
      .keyboard(27)
      .title('Confirmation')
      .body('You are about to delete this contact. Deleting this contact will remove them from the case and they ' +
        'will no longer be associated with any of your case facts. Proceed?')
      .open()
    dialogRef.result
      .catch((err: any) => {
        this.modelClose()
        console.log('ERROR: ' + err)})
      .then((dialog: any) => {
        this.modelClose()
        if(dialog){
         this.confirmedDeleteContact(contact)
          let index = this.contacts.indexOf(this.contacts.find(e => e.id == contact.id));
          if( index != -1) {
            this.contacts.splice(index, 1);
            this.categories = [];
            this.contacts.forEach((item) => {
              item.categories.forEach((category) => {
                if (!this.categories.includes(category.name)) {
                  this.categories.push(category.name);
                }
              })
            })
            this._changeDetectorRef.detectChanges();
          }
        }
      });
    $('bs-modal-container').addClass('show-midal-element')

  }

  confirmedDeleteContact(contact: Contacts) {

    this.triallineResoureService.deleteContact(contact.trialline_id, contact.id).subscribe((response: any)=> {
        let index = this.contacts.indexOf(this.contacts.find(e => e.id == contact.id));
        this.contacts.splice(index, 0);
        this.toasts.success('Successfully deleted contact: ', contact.name);
      }, (error) => {
      if (error.status == 403) {
        let errorData: any = error.error;
        if (errorData.error.message) { this.toasts.error(errorData.error.message); }
      } else {
        this.toasts.error(error);
      }
    })
  }

  modelClose() {
    $('body').removeClass('modal-open');
  }

  ShowInPreviewer(file) {
    console.log(file);
    this.preview = true;
    this.filePreviewer = file;
    jQuery('#preview-modal-shared').modal('show');
  }

  closePreviewerFile() {
    this.preview = false;
    jQuery('#preview-modal-shared').modal('hide');
  }
}
