<script src="https://cdn.ckeditor.com/4.12.1/full/ckeditor.js"></script>
<img src="assets/squares.gif" class="img spinners" alt="spinner" *ngIf="processing">
<div id="custom-bootstrap-menu" class="navbar navbar-default " role="navigation">
  <div class="container-fluid">
    <div class="navbar-header" (click)="closeMenu()">
      <a [routerLink]="['/dashboard']" class="logoimg">
        <img src="assets/images/logo.png" alt="">
      </a>
      <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-menubuilder">
        <span class="sr-only">Toggle navigation</span><span class="icon-bar"></span><span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
    </div>
    <div class="collapse navbar-collapse navbar-menubuilder" style="overflow-y: visible; position: relative;">
      <ul class="nav navbar-nav navbar-left widthfull">
        <li *ngIf="simpleView !== 3">
          <a data-toggle="dropdown" (click)="togglediv()" (mouseenter)="togglediv()">Filters
            <span id="notification-1" style="color: red;" [style.display]="isShowRedShowNew() ? 'inline-block': 'none'">
              *
            </span>
          </a>
          <ul id='allMenu' class="dropdown-menu multi-level" role="menu" aria-labelledby="dropdownMenu"
              style="overflow: visible;" >
            <li style="padding: 5px 16px;" >
            <div style="display: flex;">
              <label class="mt-checkbox mt-checkbox-outline">
                <input id='filterChange' (change)="switchFilter()" type="checkbox" [checked]="filterParallel"
                       class="filter-select-all" value="parallel" [disabled]="processing" style=" display: flex;">
                Criteria Filter 
             <span></span>             
             <a  [tooltip]="criteriaFilterTooltip"><i class="fa fa-question"  style="color: red;" aria-hidden="true"></i></a>             
              </label> 
              <div (click)="closeMenu()" style="margin-top: 1px;">
                <a  data-toggle-custom="#filter-dropdown">
                  <i class="fa fa-times" aria-hidden="true" style=" margin-right: -16px;"></i><br />
                </a>            
              </div>
            </div>
              <button class="btn default" *ngIf="showAll" (click)="deselect()">Deselect all filters</button>
              <button class="btn default" *ngIf="!showAll" (click)="selectAllFilters()">
                Select all filters
              </button>
            </li>
            <li id="catcolor">
              <a (mouseover)="toggleCategories()"  style="font-weight: bold;" (mouseenter)="color('catcolor')">
                Categories
                <span id="notification-2" style="color: red;"
                      [style.display]="isAllCheckedForAsterisk() ? 'inline-block' : 'none'">*</span>
              </a>
            </li>
            <li id="tagcolor" *ngIf="tagsdata.length">
              <a (mouseover)="toggletag()"   style="font-weight: bold;" (mouseenter)="color('tagcolor')">
                Tags
                <span id="notification-3" style="color: red;"
                      [style.display]="isAlltagCheckedForAsterisk() ? 'inline-block' : 'none'">*</span>
              </a>
            </li>
            <li id="contactCategorycolor" *ngIf="contactsCategory.length">
              <a (mouseover)="toggleContactsCategory()" style="font-weight: bold;"
                 (mouseenter)="color('contactCategorycolor')">
                Contact Categories
                <span id="notification-4" style="color: red;"
                      [style.display]="isAllContactsCategoryCheckedForAsterisk() ? 'inline-block' : 'none'">*</span>
              </a>
            </li>
            <li id="contactcolor" *ngIf="trialline?.contacts.length">
              <a (mouseover)="toggleContacts()"   style="font-weight: bold;" (mouseenter)="color('contactcolor')">
                Contact Names
                <span id="notification-5" style="color: red;"
                      [style.display]="isAllContactsCheckedForAsterisk() ? 'inline-block' : 'none'">*</span>
              </a>
            </li>
          </ul>
          <div [class.open]="categories" class="menu-dropdown filters"  (mouseenter) ="togglediv()" >
            <ul id="filter-dropdown-1" class="dropdown-menu"
                style="overflow:auto;margin: 2px 0 0 13pc;background: #fff!important;"
                [style.max-height]="window().innerHeight -105+ 'px'" >
               <div (click)="closeMenu()" class="close-filters" style="margin-top: 7px;">
                <a  data-toggle-custom="#filter-dropdown">
                  <i class="fa fa-times" aria-hidden="true"></i><br />
                </a>
              </div>
              <li style="margin-top:8px;margin-left: 11px;">
                <label class="mt-checkbox mt-checkbox-outline">
                  <input id='catcheck' (change)="all($event)" type="checkbox" [checked]="isAllChecked()"
                         class="filter-select-all" value="all" [disabled]="processing">
                  Show All
                  <span></span>
                </label>
              </li>
              <li class="divider" style="margin-top: -6px;"></li>
              <li *ngFor="let category of trialline.categories | visibleCategories" style="margin-left: 11px;">
                <label class="mt-checkbox mt-checkbox-outline">
                  <input (change)="changedCategories()" type="checkbox" [(ngModel)]="category.state"
                         [disabled]="showAll == true && !category.state && simpleView == 3"
                         [attr.value]="category.id" class="filter-select"> {{ category.name }}
                  <span></span>
                </label>
              </li>
            </ul>
          </div>
          <div [class.open]="tags" class="menu-dropdown filters" (mouseenter) ="togglediv()">
            <ul id="filter-dropdown-2" class="dropdown-menu"
                style="overflow:auto;margin: 4pc 0 0 13pc;min-width: 250px; background: #fff!important;"
                [style.max-height]="window().innerHeight -145 + 'px'">
               <div class="close-filters" style="margin-top: 7px;">
                <a (click)="closeMenu()" data-toggle-custom="#filter-dropdown">
                  <i class="fa fa-times" aria-hidden="true"></i><br />
                </a>
              </div>
              <li style="margin-top:8px;margin-left: 11px;" *ngIf="simpleView !== 3">
                <label class="mt-checkbox mt-checkbox-outline">
                  <input (change)="tagcheckall($event)" type="checkbox" [checked]="isAlltagChecked()"
                         class="filter-select-all" value="all" [disabled]="processing">
                  Show All
                  <span></span>
                </label>
              </li>
              <li class="divider" style="margin-top: -6px;"></li>
              <li *ngFor="let tag of tagsdata" style="margin-left: 11px;">
                <label class="mt-checkbox mt-checkbox-outline">
                  <input (change)="changedTags($event,tag.id)" type="checkbox" [(ngModel)]="tag.status"
                         [disabled]="showAll == true && !tag.state && simpleView == 3"
                         [attr.value]="tag.id" class="filter-select"> {{ tag.tag_name|uppercase}}
                  <span></span>
                </label>
              </li>
            </ul>
          </div>
          <div [class.open]="contactCategories" class="menu-dropdown filters">
            <ul id="filter-dropdown-3" class="dropdown-menu"
                style="overflow:auto;margin: 40px 0 0 13pc;background: #fff!important;"
                [style.max-height]="window().innerHeight -110+ 'px'" >
              <div (click)="closeMenu()" class="close-filters" style="margin-top: 7px;">
                <a  data-toggle-custom="#filter-dropdown">
                  <i class="fa fa-times" aria-hidden="true"></i><br />
                </a>
              </div>
              <li style="margin-top:8px;margin-left: 11px;">
                <label class="mt-checkbox mt-checkbox-outline">
                  <input id='catcheck-contact-category' (change)="allContactCategoty($event)" type="checkbox"
                         [checked]="isAllContactsCategoryChecked()"  class="filter-select-all" value="all"
                         [disabled]="processing">
                  Show All
                  <span></span>
                </label>
              </li>
              <li class="divider" style="margin-top: -6px;"></li>
              <li *ngFor="let cCategory of getUniqueCategoryNames()" style="margin-left: 11px;">
                <label class="mt-checkbox mt-checkbox-outline">
                  <input (change)="changedCContacts()" type="checkbox" [(ngModel)]="cCategory.status"
                         [disabled]="showAll == true && !cCategory.state && simpleView == 3"
                         [attr.value]="cCategory.id" class="filter-select"> {{ cCategory.name }}
                  <span></span>
                </label>
              </li>
            </ul>
          </div>
          <div [class.open]="contactsShow" class="menu-dropdown filters">
            <ul id="filter-dropdown-4" class="dropdown-menu"
                style="overflow:auto;margin: 50px 0 0 13pc; background: #fff!important;"
                [style.max-height]="window().innerHeight -200+ 'px'" >
              <div (click)="closeMenu()" class="close-filters" style="margin-top: 7px;">
                <a  data-toggle-custom="#filter-dropdown">
                  <i class="fa fa-times" aria-hidden="true"></i><br />
                </a>
              </div>
              <li style="margin-top:8px;margin-left: 11px;" *ngIf="simpleView !== 3">
                <label class="mt-checkbox mt-checkbox-outline">
                  <input id='catcheck-contact' (change)="allContact($event)" type="checkbox"
                         [checked]="isAllContactsChecked()"  class="filter-select-all" value="all"
                         [disabled]="processing">
                  Show All
                  <span></span>
                </label>
              </li>
              <li class="divider" style="margin-top: -6px;"></li>
              <li *ngFor="let contact of trialline?.contacts " style="margin-left: 11px;">
                <label class="mt-checkbox mt-checkbox-outline">
                  <input (change)="changedContacts()" type="checkbox" [(ngModel)]="contact.status"
                         [attr.value]="contact.id"
                         [disabled]="showAll == true && !contact.status && simpleView == 3"
                         class="filter-select"> {{ contact.name }}
                  <span></span>
                </label>
              </li>
            </ul>
          </div>
        </li>
        <li><a [routerLink]="['./views']">Views</a>
        </li>
        <form #dateForm="ngForm" class="range_filter">
          <li>
            <div class="form-group">
              <div class="date col-md-1 date-picker-date-from">
                <custom-datetime [timepicker]="false" [ngModel]="rangeFilterFromModel"
                                 [datepicker]="{maxViewMode: 2,
                                                      format: displayDateFormat?.fullDateFormat, autoclose:true, placeholder: displayDateFormat?.fullDateFormat.toUpperCase()}"
                                 name="start_date" (change)="rangeChange($event)">
                </custom-datetime>
              </div>
            </div>
          </li>
          <li style="margin-top: 7px;">&#8212;</li>
          <li>
            <div class="form-group">
              <div class="date col-md-1 date-picker-date-to">
                <custom-datetime [timepicker]="false" [ngModel]="rangeFilterToModel"
                                 [datepicker]="{maxViewMode: 3,
                                                      format: displayDateFormat?.fullDateFormat, autoclose:true,
                                                       placeholder: displayDateFormat?.fullDateFormat.toUpperCase()}"
                                 (change)="rangeChange($event)"                                
                                 name="end_date">
                </custom-datetime>
              </div>
            </div>
          </li>
          <label class="mt-checkbox mt-checkbox-outline">
            <div class="form-group">
            <input (change)="changeRange(dateForm)" type="checkbox" [(ngModel)]="rangeFilterApply"
                   [attr.value]="rangeFilterApply" name="range_apply" checked
                   class="filter-select">
                    APPLY DATE RANGE
            </div>
          </label>
        </form>
        <li class="bigright">
          <a [routerLink]="['/support']">Technical Support</a>
        </li>
        <li class="bigright">
          <a [routerLink]="['/dashboard']" >Dashboard</a>
        </li>
        <li class="bigright">
          <a *ngIf="! trialline.parent_trialline_id" [routerLink]="['./edit']">Case Settings & Files</a>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="page-container" style="" (click)="closeMenu()" #screen>  
  <div class="time-line-content" style="">
    <app-shared-trialline *ngIf="simpleView === 0" [events]="events" [trialline]="trialline"
                          [offsetHeight]="66" (onEdit)="edit($event)" (onMore)="more($event)"></app-shared-trialline>
    <app-shared-trialline-mobile *ngIf="simpleView === 1" [events]="events" [trialline]="trialline"
                                 (onEdit)="edit($event)" (onMore)="more($event)" [commentsButton]="true"
                                 [editButton]="true"></app-shared-trialline-mobile>
   <app-pyramid-trialline *ngIf="simpleView === 2" [getEvents]="events" [trialline]="trialline"
                          [offsetHeight]="50" (onEdit)="edit($event)" (onMore)="more($event)"></app-pyramid-trialline>
    <app-track-trialline *ngIf="simpleView === 3" [events]="eventsTracks" [trialline]="trialline" [tracks]="tracks"
    [offsetHeight]="50" (onEdit)="edit($event)" (onMore)="more($event)"></app-track-trialline>
  </div>
</div>
<div class="collaps-panel" (click)="closeMenu()">
  <div class="widget-tools">
    <div class="btn-group-vertical widget-export">
      <div [hidden]="IsExportHidden">
         <button  (click)="Applyexportfilter('world')"  title="Export"  type="button"
                  class="btn btn-default btn-icon">
          <i class="fa fa-file"></i><br />
          <div class="btn-text">DOCX</div>
        </button>
         <button (click)="Applyexportfilter('exel')"
           data-toggle="tooltip" title="Export"
           type="button" class="btn btn-default btn-icon">
          <i class="fa fa-table"></i><br />
          <div class="btn-text">XLSX</div>
        </button>
      </div>
    </div>
    <div class="btn-group-vertical">
      <button *ngIf="! trialline.parent_trialline_id" (click)="create()" type="button" name="new-event"
              class="btn btn-default btn-icon">
        <i class="fa fa-plus-square"></i><br />
        <div class="btn-text">New Event</div>
      </button>
      <button (click)="sharingView()" *ngIf="simpleView !== 3"
        type="button" name="share"
        data-toggle="tooltip" title="Share"
        class="btn btn-default btn-icon">
        <i class="fa fa-share-alt"></i><br />
        <div class="btn-text">Share</div>
      </button>
      <button [routerLink]="['./events']" class="btn btn-default btn-icon">
        <i class="fa fa-list"></i><br />
        <div class="btn-text">List of Events</div>
      </button>
      <button (click)="saveView()"
        type="button" name="save"
        data-toggle="tooltip" title="Save view"
        class="btn btn-default btn-icon">
        <i class="fa fa-save"></i><br />
        <div class="btn-text">Save View</div>
      </button>
      <button [attr.href]="" (click)="exportShow()"
         data-toggle="tooltip" title="Export"
         type="button" class="btn btn-default btn-icon">
        <i class="fa fa-share-square-o"></i><br />
        <div class="btn-text">Export</div>
      </button>
      <button type="button" name="comments"
              data-toggle="tooltip" title="Comments"
              class="btn btn-default btn-icon active-comment" (click)="$event.preventDefault(); commentPanel()">
        <i class="fa fa-comment"></i><br />
        <div class="btn-text">Comments</div>
      </button>
      <button *ngIf="simpleView === 1" type="button" name="screenshot"
              data-toggle="tooltip" title="Print Screen"
              class="btn btn-default btn-icon active-comment" (click)="screenshot()">
        <i class="fa fa-camera"></i><br />
        <div class="btn-text">Print Screen</div>
      </button>
      <span class="btn btn-default btn-icon hidden-xs" id="view-toggle">
        <img src="assets/squares.gif" alt="spinner" *ngIf="disabled"
             style="max-height: 34px; max-width: 34px; opacity: .6;">
        <i class="fa fa-eye" *ngIf="!disabled"></i>
        <div class="btn-text" *ngIf="!disabled">
          Select Layout
        </div>
          <ul *ngIf="!disabled">
            <li class="btn btn-default btn-icon" *ngIf="simpleView !== 0" (click)="changedView(0)">
              <img src="../../../../assets/images/relative.svg" alt="">
            </li>
            <li class="btn btn-default btn-icon" *ngIf="simpleView !== 1" (click)="changedView(1)">
              <img src="../../../../assets/images/carousel.svg" alt="">
            </li>
            <li class="btn btn-default btn-icon" *ngIf="simpleView !== 2" (click)="changedView(2)">
              <img src="../../../../assets/images/pyramid.svg" alt="">
            </li>
            <li class="btn btn-default btn-icon" *ngIf="simpleView !== 3" (click)="changedView(3)">
              <img src="../../../../assets/images/track.png" alt="">
            </li>
          </ul>
      </span>
      <button *ngIf="simpleView === 3" type="button" name="screenshot"
              data-toggle="tooltip" title="Assign tracks"
              class="btn btn-default btn-icon active-comment" (click)="assignShow()">
        <i class="fa fa-road"></i>
        <div class="btn-text">Assign tracks</div>
      </button>
    </div>
  </div>
  <app-trialline-manage-comments [events]="events" (onSelect)="selectEventOnTimeline($event)"
                                 (processing)="processing = $event"></app-trialline-manage-comments>
</div>
<app-trialline-event-modal [event]="event" *ngIf="editModal && ! trialline.parent_trialline_id"
                           (onClose)="modalClosed($event)" (onUpdated)="updateEvent($event)"
                           (onCreated)="addEvent($event)" (onDelete)="confirmedDeleteEvent($event)"
                           (onCopy)="addCopyEvent($event)"></app-trialline-event-modal>
<div id="copy">
 <app-trialline-event-copy-modal [event]="copyEventData"  *ngIf="copyEvent"  (onClose)="modalClosed($event)"
                                 (onCreated)="addEvent($event)" ></app-trialline-event-copy-modal>
</div>
<app-trialline-event-more-pyramid-modal [triallineId]="triallineId" [eventsIdsOrder]="eventsIdsOrder"
                                        [eventsIdsOrderTracks]="eventsIdsOrderTracks" [view]="simpleView"
                                        [comments]="true"
                                        [answer_comment]="true"
                                        (onComment)="addComment($event)"  [activeTab]="activeTab" [event]="event" *ngIf="moreModal"
                                (onClose)="moreModalClosed($event)"></app-trialline-event-more-pyramid-modal>
<app-trialline-manage-save-view [share]="shareView" [triallineId]="triallineId" [simpleView]="simpleView"
                                [eventsTracks]="eventsTracks" [events]="events" [screenView]="screenView"
                                [categories]="selectedCategories" ></app-trialline-manage-save-view>
<span defaultOverlayTarget></span>
<div id="exportfiltermodal" class="modal fade " role="dialog"  >
  <div class="modal-dialog">
     <div class="modal-content" >
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" style="font-size:xx-large;" >&times;</button>
            <h4 class="modal-title">Select Export Columns</h4>
          </div>
       <div class="modal-body">
        <div class="container">
          <ul class="menu">
            <li>
              <label class="mt-checkbox mt-checkbox-outline">
                <input (change)="checkall($event)" type="checkbox" [(ngModel)]="allchecked"
                       class="filter-select-all" value="all" [disabled]="processing">
                Select All Export Columns
                <span></span>
              </label>
            </li>
            <div class="row" style="margin-top:22px;"></div>
            <li *ngFor="let he of headers">
               <label class="mt-checkbox mt-checkbox-outline">
                <input (change)="onCheckboxChange(he.id,$event)" type="checkbox"
                       [(ngModel)]="he.checked" value="{{he.id}}"
                     class="filter-select"> {{ he.name }}
                <span></span>
              </label>
            </li>
          </ul>
              <span id="error_msg" style="color:red" *ngIf="checkedList.length<1">
                Please Select Atleast One Filed to Export
              </span>
            </div>
      </div>
      <div class="modal-footer">
          <a data-dismiss="modal"  type="button" class="btn btn-sucess crole" role="button" >
         Cancel
       </a>
        <a style="margin-right: 62px;" (click)="download()"  *ngIf="checkedList.length>0 && simpleView != 3"
            [attr.href]="this.apiUrl+'trialline/' +triallineId + '/export/'+ urltext+ '?token=' +auth.token()
             + '&category_id=' +getSelectedCategories().join()+ '&selected_columns='+checkedList
             + '&contacts_category_id=' +getSelectedContactsCategory().join()
             + '&contacts_id=' +getSelectedContacts().join()+ '&tag_id=' +getSelectedTags().join()"
           data-toggle="tooltip" title="Export" type="button" class="btn btn-sucess role" role="button">
          Ok
        </a>
        <a style="margin-right: 62px;" (click)="download()"  *ngIf="simpleView == 3"
           [attr.href]="this.apiUrl+'trialline/' +triallineId + '/export/'+ urltext  +'?token=' +auth.token()
             + '&simpleView=true'
             + '&selected_columns='+checkedList"
           data-toggle="tooltip" title="Export" type="button" class="btn btn-sucess role" role="button">
          Ok
        </a>
        <a style="margin-right: 62px;" *ngIf="checkedList.length<1" [attr.disabled]="checkedList.length<1"
           data-toggle="tooltip" title="Export" type="button" class="btn btn-sucess role" role="button">
          Ok
        </a>
      </div>
    </div>
  </div>
</div>
<div id="assignModal" class="modal fade " role="dialog">
  <div class="modal-dialog">
    <div class="modal-content" >
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" style="font-size:xx-large;" >&times;</button>
        <h4 class="modal-title">Assign tracks</h4>
      </div>
      <div class="modal-body">
        <div class="form-group" *ngFor="let track of tracks">
          <span>{{track.name}}</span>
          <select [ngModel]="track?.filterCategory" #categoryId
                  (change)="changeTracksCategories(categoryId.value, track.id)"
                  style="margin-left: 15px">
            <option value="">
              Select an option
            </option>
            <option [attr.value]="trackCategory.id" *ngFor="let trackCategory of tracksCategory">
              {{trackCategory.name}}
            </option>
          </select>
          <select *ngIf="track?.filterCategory == 'category_id'" [ngModel]="track?.filterValue" #filterId
                  (change)="changeTracksCategoriesValue(filterId.value, track.id)"
                  style="margin-left: 15px;" class="track_select_items">
            <option [attr.value]="category.id" *ngFor="let category of trialline.categories | visibleCategories">
              {{ category.name.length > 30 ? category.name.substr(0, 30)+'...' : category.name}}
            </option>
          </select>
          <select *ngIf="track?.filterCategory == 'tag_id'" [ngModel]="track?.filterValue" #filterId
                  (change)="changeTracksCategoriesValue(filterId.value, track.id)"
                  style="margin-left: 15px;"  class="track_select_items">
            <option [attr.value]="tag.id"  *ngFor="let tag of tagsdata">
              {{ tag.tag_name.length > 30
              ? tag.tag_name.substr(0, 30)+'...' : tag.tag_name | uppercase}}
            </option>
          </select>
          <select *ngIf="track?.filterCategory == 'contacts_category_id'" [ngModel]="track?.filterValue" #filterId
                  (change)="changeTracksCategoriesValue(filterId.value, track.id)"
                  style="margin-left: 15px;" class="track_select_items">
            <option [attr.value]="cCategory.id" *ngFor="let cCategory of contactsCategory ">
              {{ cCategory.name.length > 30 ? cCategory.name.substr(0, 30)+'...' : cCategory.name }}
            </option>
          </select>
          <select *ngIf="track?.filterCategory == 'contacts_id'" [ngModel]="track?.filterValue" #filterId
                  (change)="changeTracksCategoriesValue(filterId.value, track.id)"
                  style="margin-left: 15px;" class="track_select_items">
            <option [attr.value]="contact.id" *ngFor="let contact of trialline?.contacts ">
              {{ contact.name.length > 30 ? contact.name.substr(0, 30)+'...' : contact.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="modal-footer">
        <a data-dismiss="modal"  type="button" class="btn btn-sucess crole" role="button" >
          Cancel
        </a>
        <a data-toggle="tooltip" title="Assign" type="button" class="btn btn-sucess role" role="button"
           (click)="assignTrack()">
          Ok
        </a>
      </div>
    </div>
  </div>
</div>
