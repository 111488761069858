<section id="timeline" class="main-section"   [style.background-image]="trialline?.background_file
  && trialline?.background_file.type == 'image'
  ? 'url(' + publicPathUrl + 'filesystem/' + trialline?.background_file.encrypt_id + ')' : ''"
         style="background-size: cover;  will-change: transform;   background-repeat:repeat-x;"  >
  <div id="visn_{{idVisualisation}}" #visn>
    <div id="pyramid-view-wrapper" class="container-fluid"
         [ngStyle]="{'transform':'translateX('+ triallineOffset +'px)'}" style="position:relative; transition: transform 300ms linear;
  will-change: transform;  ">
      <ol class="timeline">
        <li class="timeline_item" *ngFor="let item of eventsRef   let i = index" data-id="{{ item.id }}">
          <div  id="{{ 'pyramid-card-' + i }}"  class="timeline-panel" [style.border-color]="item.background">
            <div class="title" [style.background]="item.background">
              <div class="event-date">
                <div class="event-date-year">
                  <span *ngIf="item.date_start">{{item.formatStartDateNew}}</span>
                  <span *ngIf="item.date_end && item.date_start"> - {{item.formatEndDateNew}}</span>
                </div>
                <div class="event-date-time">
            <span
              *ngIf="item.times_start">{{item.times_start}}</span>
              <span
              *ngIf="item.times_start && item.times_end">-</span>
                  <span
                    *ngIf="item.times_end"> {{item.times_end}}</span>
                </div>
              </div>
            </div>
            <div class="content">
              <div class="content_container">
                <div  style="min-width: 60%;" [ngStyle]="{'max-width': item?.thumbnail_visible ? '60%' : '100%'}">
                  <h4 class="event-title">{{ item?.transformTitle }}</h4>
                  <p [innerHTML]="item?.description | safenew: 'html'"></p>
                </div>
                <span *ngIf="item?.thumbnail_visible" class="content-img img"   [ngStyle]="item.thumbnail">
              </span> 
              </div>
            </div>
            <div class="content-footer" [ngStyle]="{'background': item.background}">
              <div class="action-buttons">
                <div class="button" data-html2canvas-ignore>
                  <a (click)=" item?.files_count && onMore.emit({id: item.id, activeTab : 'files'})">
                    <i class="fa fa-paperclip"></i>
                    <span class="calc">{{ item?.files_count }}</span>
                  </a>
                  <a *ngIf="item?.bill_cost_count" (click)="onMore.emit({id: item.id, activeTab : 'bill_cost'})">
                    <i class="fa fa-usd"></i>
                    <span class="calc">{{ item?.bill_cost_count }}</span>
                  </a>
                  <a (click)=" item?.tags_count && onMore.emit({id: item.id, activeTab : 'tags'})">
                    <i class="fa fa-tags"></i>
                    <span class="calc">{{ item?.tags_count }}</span>
                  </a>
                  <a (click)="item?.comments_count && onMore.emit({id: item.id, activeTab : 'comments'})"
                     *ngIf="commentsButton">
                    <i class="fa fa-comment-o"></i>
                    <span class="calc">{{ item?.comments_count }}</span>
                  </a>
                  <a *ngIf="editButton"  (click)="onEdit.emit(item.id)">EDIT</a>
                  <a (click)="onMore.emit({id: item.id, activeTab : 'description'})" class="pl-0">MORE</a>
                </div>
              </div>
              <div class="category" *ngIf="item.category_name != 'No Category'">
                {{item.category_name}}
              </div>
            </div>
          </div>
        </li>
      </ol>
    </div>
  <app-shared-trialline_navigation id="bottom-mobile-list" [timeline]="timelineNew" [minDate]="minDate"
                                   [maxDate]="maxDate"
                                   (onSelectFlag)="selectFlag($event)" ></app-shared-trialline_navigation>
  </div>
</section>
