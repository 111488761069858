<div class="portlet-title">
  <button (click)="create()" data-create-btn type="button" class="btn blue">New Contact
  </button>
</div>
<div class="portlet-body">
  <div class="table-scrollable table-scrollable-borderless">
    <table class="table table-hover editable">
      <thead>
      <tr class="uppercase">
        <th></th>
        <th (click)="reorder('name')" style="vertical-align:top">
          Name <span  *ngIf="currentStatus=='name'" class="e2e-inner-html-bound"
                        [style.opacity]="order == 'name' ? '1' : '.3'"
                        [innerHTML]="getSortIcon('name')"></span></th>
        <th>
          <div style="vertical-align:top; margin-bottom: 5px">
            Category <span  *ngIf="currentStatus=='category'" class="e2e-inner-html-bound"
               [style.opacity]="order == 'category' ? '1' : '.3'"
               [innerHTML]="getSortIcon('category')"></span>
          </div>
          <div class="ng-autocomplete">
            <ng-autocomplete
                             [data]="categories"
                             placeholder="Enter the Category Name"
                             (selected)='selectedCategory($event)'
                             (inputCleared) = 'clearCategory($event)'
                             (inputChanged) = 'changeCategory($event)'
                             [itemTemplate]="itemTemplate"
                             [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>
            <ng-template #itemTemplate let-item>
              <a [innerHTML]="item"></a>
            </ng-template>
            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
        </th>
        <th style="vertical-align:top">Files</th>
        <th style="vertical-align:top">Action</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let contact of contacts">
          <td *ngIf="!contact.dshow">
            <div class="category-view-thumbnail">
              <img [attr.src]=" getSafeThumbnailUrl(contact)" alt="{{ contact.slug }}"
                   class="img-thumbnail" style="height: 100px;" data-toggle="tooltip" title="{{getSafeUrl(contact)}}">
            </div>
          </td>
          <td *ngIf="!contact.dshow">
            <span class="category-view-name">{{ contact.name }}</span>
          </td>
          <td *ngIf="!contact.dshow">
            <span class="category-view-slug" *ngIf="contact">
              <span *ngFor="let category of contact.categories; last as isLast">
                {{ category.name }}{{!isLast ? ', ' : ''}}
              </span>
            </span>
          </td>
          <td *ngIf="!contact.dshow">
          <span *ngFor="let file of contact?.private_files">
             <div>
               <p>
                  <a class="" (click)="ShowInPreviewer(file.file)"
                     data-toggle="tooltip"  title="{{getSafeFileUrl(file)}}" style="line-break: anywhere;">{{file.file.title}}</a>
               </p>
             </div>
          </span>
          </td>
          <td *ngIf="!contact.dshow">
            <a class="btn blue btn-outline btn-w120" (click)="show(contact)">Show Contact Details</a>
            <a class="btn blue btn-outline btn-w80" (click)="edit(contact)">Edit</a>
            <a class="btn red btn-outline btn-w80"
               (click)="deleteContact(contact)"
            >Delete</a>
          </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

  <app-trialline-contacts-edit
    *ngIf="showEdit"
    [editContact]="editContact"
    [events]="contactEvents"
    (onUpdated)="updateContact($event)"
    (onCreated)="createContact($event)"
    (onClose)="closeModal($event)"
    (onDelete)="deleteContact($event)"
    (onUpdatedFile)="updatedFile($event)"
  ></app-trialline-contacts-edit>

  <app-shared-contacts-show [contact]="contactShow" [elementStyle]="true" (onOpenEdit)="openEdit($event)"
                            [showEditButton]="true" ></app-shared-contacts-show>
  <div class="file-prev">
    <app-shared-previewer [file]="filePreviewer" [showDownload]="true" (onClosePreviewer)="closePreviewerFile()"
                          *ngIf="preview"></app-shared-previewer>
  </div>

<span defaultOverlayTarget></span>


