<div id="add-from-library-modal" class="modal fade_ bs-modal-lg in_ overflowed_" tabindex="-1" role="dialog"
     style="z-index: 99999999; overflow: visible">
  <div class="modal-dialog modal-md modal-preview">
    <div class="modal-content">
      <div class="modal-header ">
        <button (click)="closePreviewer()" type="button" class="close"><span
        aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">Add file from library</h4>
      </div>
      <img src="assets/squares.gif" class="img spinners center-block" alt="spinner" *ngIf="processing">
      <div class="modal-body" *ngIf="! processing" style="max-height: calc(100vh - 180px);overflow-y: auto;">
        <div class="tabbable-line">
          <ul class="nav nav-tabs" *ngIf="tlineLibraryList==true">
            <li class="active"><a data-toggle="tab" href="#trialline-library" *ngIf="tlineLibraryList">
              Case File Library</a>
            </li>
            <li ><a data-toggle="tab" href="#general-library">Company File Library</a></li>
          </ul>
          <ul class="nav nav-tabs" *ngIf="tlineLibraryList==false">
              <li ><a data-toggle="tab" href="#trialline-library" *ngIf="tlineLibraryList">Case File Library</a></li>
              <li  class="active"><a data-toggle="tab" href="#general-library">Company File Library</a></li>
          </ul>
          <div class="tab-content" *ngIf="tlineLibraryList==true">
            <div id="general-library" class="tab-pane fade">
              <div class="row">
                  <div class="col-sm-8">
                    <span (click)="backtofolder1(1)" style="font-size: 15px;color: #0075bb;cursor: pointer;text-decoration: none;font-family: GibsonSemiBold,Geneva,Tahoma,Verdana,sans-serif;line-height: 1.5em;font-weight:bold;font-style: normal;" title="Home Directory">
                      You are in folder <i class="fa fa-arrow-right" aria-hidden="true">&nbsp; </i>Home
                    </span>
                    <span  *ngFor="let sa of backfolder;let i = index ">
                      <a (click)="backtofolder1(sa)" style="font-size: 15px;color:#0075bb;cursor: pointer;text-decoration: none;font-family: GibsonSemiBold,Geneva,Tahoma,Verdana,sans-serif;line-height: 1.5em;font-weight:bold;font-style: normal;" title={{sa}}>
                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        {{sa}}
                      </a>
                    </span>
                  </div>
              </div>
              <div class="row">
                  <div  class="col-md-4"  style="margin-top: 1pc;"  title="click here to go up">
                      <a (click)="levelupc()"> <i class="fa fa-folder-open"  style="font-size: 24px;color: #034375;" ></i>&nbsp;&nbsp;<span style="font-size: 16px;font-family: sans-serif;font-weight: bold;">
                        <i class="fa fa-ellipsis-h"></i>
                      </span></a>
                  </div>
                  <div *ngFor="let nefo of ctfolder">
                      <div class="col-md-4" style="margin-top: 1pc;">
                       <div class="row">
                         <div class="col-sm-12 col-md-12">
                             <a (click)="OpenTrlFolderc(nefo)">
                               <i class="fa fa-folder-open"  style="font-size: 24px;color: #034375;"></i>&nbsp;&nbsp;<span style="font-size: 16px;font-family: sans-serif;font-weight: bold;">
                                 {{nefo}}
                               </span>
                             </a>
                         </div>
                       </div>
                     </div>
                 </div>
              </div>
              <div>
                  <hr style="height:1px;border:none;color:#868383;background-color:#a9a7a7;" />
              </div>
              <div class="row">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th>Thumbnail</th>
                    <th>Title</th>
                    <th>Type</th>
                    <th>Attached to</th>
                    <th>Description</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                    
                    <tr *ngFor="let file of ctfilesi">
                      <td>
                        <img [attr.src]="file.type !=='pdf' ? ( file.type !== 'video' ? apiPath + 'filesystem/' + file.encrypt_id + '?preview=1' : 'assets/images/icon-file-type/mp4.png'): 'assets/pdfimages/pdf2.png' "
                             alt="{{ getFileName(file.title) }}" class="img-thumbnail" style="height: 25px; max-width: 75px;">
                      </td>
                      <td>{{ getFileName(file.original_path) }}</td>
                      <td>{{ file.type }}</td>
                      <td>
                        <ul style="list-style: none; padding: 0">
                          <li *ngFor="let item of file.list">
                            {{ item }}
                          </li>
                        </ul>
                      </td>
                      <td>{{ file.description }}</td>
                      <td>
                        <button  (click)="$event.preventDefault(); selectFile(file)"
                                [attr.disabled]="checkExistFile(file) ? true : null"
                                class="btn btn-sm btn-primary">
                          {{ checkExistFile(file) ? 'File Added' : '+' }}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- case  -->
            <div id="trialline-library" class="tab-pane fade in active" *ngIf="tlineLibraryList">
                <div class="row">
                    <div class="col-sm-8">
                      <span (click)="backtofolder(1)" style="font-size: 15px;color: #0075bb;cursor: pointer;text-decoration: none;font-family: GibsonSemiBold,Geneva,Tahoma,Verdana,sans-serif;line-height: 1.5em;font-weight:bold;font-style: normal;" title="Home Directory">
                        You are in folder <i class="fa fa-arrow-right" aria-hidden="true">&nbsp; </i>Home</span>
                        <span  *ngFor="let sa of backfolder1;let i = index ">
                          <a (click)="backtofolder(sa)" style="font-size: 15px;color:#0075bb;cursor: pointer;text-decoration: none;font-family: GibsonSemiBold,Geneva,Tahoma,Verdana,sans-serif;line-height: 1.5em;font-weight:bold;font-style: normal;" title={{sa}}>
                            <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            {{sa}}
                        </a>
                      </span>
                    </div>
                  </div>
                  <div class="row">
                      <div  class="col-md-4"  style="margin-top: 1pc;"  title="click here to go up">
                          <a (click)="levelup()"> <i class="fa fa-folder-open"  style="font-size: 24px;color: #034375;" ></i>&nbsp;&nbsp;<span style="font-size: 16px;font-family: sans-serif;font-weight: bold;">
                            <i class="fa fa-ellipsis-h"></i>
                          </span></a>
                      </div>
                      <div *ngFor="let nefo of tfolder">
                          <div class="col-md-4" style="margin-top: 1pc;">
                           <div class="row">
                             <div class="col-sm-12 col-md-12">
                                   <a (click)="OpenTrlFolder(nefo)">
                                     <i class="fa fa-folder-open"  style="font-size: 24px;color: #034375;"></i>
                                     <span style="font-size: 16px;font-family: sans-serif;font-weight: bold;">
                                       {{nefo}}
                                     </span>
                                   </a>
                             </div>
                           </div>
                         </div>
                     </div>
                  </div>
              <div>
                  <hr style="height:1px;border:none;color:#868383;background-color:#a9a7a7;" />
              </div>
              <div class="row">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th>Thumbnail</th>
                    <th>Title</th>
                    <th>Type</th>
                    <th>Description</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let file of tfilesi">
                    <td>
                      <img [attr.src]="file.type !=='pdf' ? ( file.type !== 'video' ? apiPath + 'filesystem/' + file.encrypt_id + '?preview=1' : 'assets/images/icon-file-type/mp4.png'): 'assets/pdfimages/pdf2.png' "
                           alt="{{ getFileName(file.title) }}" class="img-thumbnail" style="height: 25px; max-width: 75px;">
                    </td>
                    <td>{{ getFileName(file.original_path) }}</td>
                    <td>{{ file.type }}</td>
                    <td>{{ file.description }}</td>
                    <td>
                      <button (click)="$event.preventDefault(); selectFile(file)"
                              [attr.disabled]="checkExistFile(file) ? true : null"
                              class="btn btn-sm btn-primary">
                        {{ checkExistFile(file) ? 'File Added' : '+' }}
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!--Only Company Library-->
          <div id="general-library" class="tab-pane fade in active" *ngIf="tlineLibraryList==false">
              <div class="row">
                  <div class="col-sm-8">
                    <span (click)="backtofolder1(1)" style="font-size: 15px;color: #0075bb;cursor: pointer;text-decoration: none;font-family: GibsonSemiBold,Geneva,Tahoma,Verdana,sans-serif;line-height: 1.5em;font-weight:bold;font-style: normal;" title="Home Directory">
                      You are in folder <i class="fa fa-arrow-right" aria-hidden="true">&nbsp; </i>Home
                    </span>
                    <span  *ngFor="let sa of backfolder;let i = index ">
                      <a (click)="backtofolder1(sa)" style="font-size: 15px;color:#0075bb;cursor: pointer;text-decoration: none;font-family: GibsonSemiBold,Geneva,Tahoma,Verdana,sans-serif;line-height: 1.5em;font-weight:bold;font-style: normal;" title={{sa}}>
                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        {{sa}}
                      </a>
                    </span>
                  </div>
                </div>
                <div class="row">
                    <div  class="col-md-4"  style="margin-top: 1pc;"  title="click here to go up">
                        <a (click)="levelupc()"> <i class="fa fa-folder-open"  style="font-size: 24px;color: #034375;" ></i>&nbsp;&nbsp;<span style="font-size: 16px;font-family: sans-serif;font-weight: bold;">
                          <i class="fa fa-ellipsis-h"></i>
                        </span></a>
                    </div>
                    <div *ngFor="let nefo of ctfolder">
                        <div class="col-md-4" style="margin-top: 1pc;">
                         <div class="row">
                           <div class="col-sm-12 col-md-12">
                             <a (click)="OpenTrlFolderc(nefo)">
                               <i class="fa fa-folder-open"  style="font-size: 24px;color: #034375;"></i>&nbsp;&nbsp;<span style="font-size: 16px;font-family: sans-serif;font-weight: bold;">
                                 {{nefo}}
                               </span>
                             </a>
                           </div>
                         </div>
                       </div>
                   </div>
                </div>
              <div>
                  <hr style="height:1px;border:none;color:#868383;background-color:#a9a7a7;" />
              </div>
              <div class="row">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th>Thumbnail</th>
                    <th>Title</th>
                    <th>Type</th>
                    <th>Attached to</th>
                    <th>Description</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let file of ctfilesi">
                    <td>
                      <img [attr.src]="file.type !=='pdf' ? ( file.type !== 'video' ? apiPath + 'filesystem/' + file.encrypt_id + '?preview=1' : 'assets/images/icon-file-type/mp4.png'): 'assets/pdfimages/pdf2.png' "
                           alt="{{ getFileName(file.title) }}" class="img-thumbnail" style="height: 25px; max-width: 75px;">
                    </td>
                    <td>{{ getFileName(file.original_path) }}</td>
                    <td>{{ file.type }}</td>
                    <td>
                      <ul style="list-style: none; padding: 0">
                        <li *ngFor="let item of file.list">
                          {{ item }}
                        </li>
                      </ul>
                    </td>
                    <td>{{ file.description }}</td>
                    <td>
                      <button (click)="$event.preventDefault(); selectFile(file)"
                              [attr.disabled]="exceptFileName.indexOf(file.title) > -1 ? true : null"
                              class="btn btn-sm btn-primary">
                        {{exceptFileName.indexOf(file.title) > -1 ? 'File Added' : '+'}}
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
        </div>
      </div>
      <div  class="modal-footer">
        <button (click)="closePreviewer()" type="button"  class="btn btn-primary">Done</button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>
