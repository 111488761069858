<div class="trial-line-navigation" style="position: absolute;
  width: 100%;
  height: 51px;
  overflow: hidden;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.4);">
  <div class="trial-line-navigation-line" style="position: relative;"></div>
  <div id="trial-line-navigation">
    <ul style="margin-left: 0;
      padding-left: 0;
      font-size: 18px;
      margin-bottom: 5px;">
      <li *ngFor="let flag of flags" (click)="select(flag.id)" id="{{ 'event-flag-' + flag.id }}" [ngStyle]="{left: flag.left + 'px', top: flag.top + 'px'}" style="position: absolute;
        list-style-type: none;
        width: 25px;
        height: 25px;
        z-index: 20;" class="event-flag"
          data-toggle="tooltip" data-html="true"
          title="{{ flag.formatStartDate }}<br>{{ flag.title }}<br>{{ flag.category_name ?  flag.category_name :  flag.category.title }}">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="25px" height="25px" viewBox="0 0 42.000000 51.000000"
             preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,51.000000) scale(0.100000,-0.100000)"
             stroke="black" stroke-width="10"
             [attr.fill]="  flag.background ?  flag.background :  flag.category.background">
            <path
              d="M95 474 c-65 -32 -70 -50 -73 -251 -2 -101 1 -183 5 -183 5 0 24 16 43 35 l34 35 96 0 c84 0 102 3 131 23 58 38 69 74 69 225 l0 132 -137 0 c-103 0 -146 -4 -168 -16z"/>
          </g>
        </svg>

      </li>
    </ul>
    <div class="trial-line-navigation-line-cursor"
         draggable="true"
         [ngStyle]="{left: cursor.left + 'px', width: cursor.width ? (cursor.width + 'px') : '100%'}"
         style="z-index: 10; position: absolute;
      border: 2px solid lighten(#000, 93%);
      height: 53px;
      margin-top: 4px;
      left: 10px;
      top: -5px;
      background-color: rgba(0, 0, 40, 0.3);
      transition: all 0.5s;
       -webkit-transition: all 0.5s;">
    </div>
  </div>
</div>
