import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {env} from "../../../../.env";
import {Modal} from 'ngx-modialog-7/plugins/bootstrap';
import {Video} from "../../main/shared/video.model";
import {DomSanitizer} from "@angular/platform-browser";

declare var jQuery:any;

@Component({
  selector: 'app-shared-videos',
  templateUrl: './videos.component.html'
})
export class VideosComponent implements OnInit{

  @Input() videos: Video[];
  @Input() preview: boolean;
  @Input() removeFunc: boolean = true;
  @Output() onRemove = new EventEmitter<Video>();

  video: Video;

  apiPath = env.apiUrl;

  constructor(
    protected ref: ChangeDetectorRef,
    public modal: Modal,
    private _sanitizer: DomSanitizer
  ) {}

  getThumbnail(video: Video) {

    return this._sanitizer.bypassSecurityTrustStyle(`url(${video.preview})`);
  }

  ngOnInit() {

    if (typeof this.preview === 'undefined') this.preview = false;
    if (typeof this.removeFunc === 'undefined') this.removeFunc = true;
  }

  ngOnDestroy() {
    this.ref.detach();
  }

  selectFile(video: Video) {

    if (this.preview) {

      this.video = video;
      this.ref.detectChanges();
      jQuery('#youtube-preview-modal').modal('show');
    }
  }

  remove(video: Video) {

    this.modal.confirm()
      .size('lg')
      .isBlocking(true)
      .showClose(true)
      .keyboard(27)
      .title('Confirmation')
      .body('Are you sure you want delete this video?')
      .open()
      .result
      .catch((err: any) => console.log('ERROR: ' + err))
      .then((dialog: any) => { return dialog.result })
      .then((result: any) => { this.confirmedRemove(video) })
      .catch((err: any) => { /** Nothing to do */ });
    $(".tes").parents('.fade').css("opacity", "1");
  }

  confirmedRemove(video: Video) {

    this.onRemove.emit(video);
  }
}
