import {map} from 'rxjs/operators';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Title} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {Event} from "../../../shared/event.model";
import {EventsResourceService} from "../shared/events-resoruce.service";
import {SharedService} from "../../../../shared/shared.service";
import {NgForm} from "@angular/forms";
import {Category} from "../../../shared/category.model";
import {TriallineResourceService} from "../../shared/trialline-resoruce.service";
import {FileModel} from "../../../file-library/shared/file.model";
import {FileLibraryResourceService} from "../../../file-library/shared/file-library-resoruce.service";
import {FileItem, FileUploader} from "ng2-file-upload";
import {env} from "../../../../../../.env";
import {AuthService} from "../../../../auth/shared/auth.service";
import {Modal} from 'ngx-modialog-7/plugins/bootstrap';
import {Video} from "../../../shared/video.model";
import {Trialline} from "app/main/shared/trialline.model";
import {TriallinesResourceService} from "app/main/triallines/shared/triallines-resoruce.service";
import {RestService} from "app/rest/shared/rest.service";
import Cropper from 'cropperjs';
import { log } from 'console';

declare var jQuery: any;
declare var moment: any;
declare var _: any;

@Component({
  selector: 'app-trialline-event-copy-modal',
  templateUrl: './edit-copy.component.html',
  styleUrls: ['./edit-copy.component.css']
})
export class EventEditCopyModalComponent  implements OnInit, OnDestroy, AfterViewInit {

  config: any;
  isCollapsed: boolean;
  description: any;
  @ViewChild("ckeditor_copy", { static: true }) ckeditor: any;
  @ViewChild("ckeditor1_copy", { static: true }) ckeditor1: any;
  @Input() event: Event;
  @Output() onClose = new EventEmitter<Event>();
  @Output() onUpdated = new EventEmitter<Event>();
  @Output() onCreated = new EventEmitter<Event>();
  @Output() onDelete = new EventEmitter<Event>();
  @ViewChild('sharedFilesCopy', { static: true }) sharedFilesCopy;
  testTime = new Date();
  moreFiles: boolean = true;
  apiPath = env.apiUrl;
  eventCopy: any;
  public uploader: FileUploader;
  public privateUploader: FileUploader;
  public billCostUploader: FileUploader;
  private modalId = '#event-edit-copy-modal';
  fromLibraryModalId = '#add-from-library-modal';
  fromLibraryModal: boolean = false;
  processing: boolean = false;
  categories: Category[];
  billCostFiles: any = [];
  triallineId: number;
  filesIds: number[] = [];
  privateFilesIds: number[] = [];
  videosIds: number[] = [];
  sub: any;
  defaultThumbnail: string = '/images/start-event/trialline-event-thumb.jpg';
  publicFolderUrl: string = env.publicFolderUrl;
  publicImages = env.publicImages;
  errors: any = {};
  valid: boolean = true;
  categoryValid: boolean = true;
  exceptFileIds: number[] = [];
  saveAvailable: boolean = true;
  status: boolean = false;
  triallines: Trialline[];
  cursor_position: any;
  top: number;
  prev_pos: any;
  status1: boolean = false;
  alltr: any;
  visibility: boolean;
  thumbnailfilesarray = [];
  rgeneral: any;
  thumbnailstatus: boolean;
  encrypt_id: any;
  thumbnail_encryptid: any;
  ttfilesi = [];
  ttfolder = [];
  thumbnailpath: any;
  thumbnailbackfolder: any;
  thumbnailfiles: any;
  eventtagslist = [];
  eventdata = [];
  searchtag = [];
  contactsEventId = [];
  firsttime: boolean;
  tag: any;
  alreadyExistFiles = [];
  existFileIds = [];
  crop_image: HTMLImageElement;
  cropProcessing: boolean = false;
  blobImg: any = false;
  isCropped: boolean = false;
  cropper: any;
  cropped_image_src: string;
  thumbnail_Image_src: any;
  progressBarUploder: boolean = false;

  constructor(protected toasts: ToastrService,
              protected title: Title,
              protected route: ActivatedRoute, public router: Router,
              protected sharedService: SharedService,
              private eventsResourceService: EventsResourceService,
              private _changeDetectionRef: ChangeDetectorRef,
              private triallineResoureService: TriallineResourceService,
              private fileLibraryResourceService: FileLibraryResourceService,
              private triallinesResourceService: TriallinesResourceService,
              private authService: AuthService,
              private modal: Modal,
              private http: RestService) {
    this.firsttime = false
    this.sharedFilesCopy = [];
    // refactor to component
    this.sub = this.route.parent.params.subscribe(params => {
      this.triallineId = + params["trialline_id"];
    });
    this.uploader = new FileUploader({
      url: env.apiUrl + 'file/thumbnail?attach_to=trialline&attach_to_id=' + this.triallineId,
      authToken: 'Bearer ' + authService.token(),
      autoUpload: true,
      maxFileSize: 1024 * 1024 * 2
    });
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      if (
        item.file.type == "image/png" ||
        item.file.type == "image/jpg" ||
        item.file.type == "image/jpeg" ||
        item.file.type == "image/webp"
      ) {
        this.progressBarUploder = true;
      } else {
        this.progressBarUploder = false;
        return toasts.error("Invalid file format. Please select an image.");
      }
      let body = JSON.parse(response).body;
      if (body.path) {
        let encrypt_id =  body.encrypt_id ? body.encrypt_id : '';
        let file_id =  body.file_id ? body.file_id : null;
        this.thumbnailUploaded(body.path, encrypt_id, file_id);
      } else {
        toasts.error('Something went wrong, please try again.', 'Sorry')
      }
    };
    this.privateUploader = new FileUploader({
      url: env.apiUrl + 'file/private',
      authToken: 'Bearer ' + authService.token(),
    });
    this.privateUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let body = JSON.parse(response).body;
      if (status == 200 && body.file.id) {
        this.privateFilesIds.push(body.file.id);
        let encrypt_id =  body.encrypt_id ? body.encrypt_id : '';
        let file_id =  body.file_id ? body.file_id : null;
        this.thumbnailUploaded(body.path, encrypt_id, file_id);
        this.showCropImage();
      } else {
        if (status == 400) {
          let errorResponse = JSON.parse(response);
          toasts.error(errorResponse.error.message);
        } else {
          toasts.error('Something went wrong, please try again.', 'Sorry')
        }
      }
    };
    this.billCostUploader = new FileUploader({
      url: env.apiUrl + 'file/private',
      authToken: 'Bearer ' + authService.token(),
    });
    this.billCostUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let body = JSON.parse(response).body;
      item.file = body.file
      let patch = true;
      if (this.billCostFiles.length > 0) {
        this.billCostFiles.forEach((fileElement) => {
          if (fileElement.file.file.id === item.file.file.id) {
            patch = false;
          }
        })
      }
      if (status == 200 && body.file.id) {
        if (patch) {
          this.billCostFiles.push(item);
        }
      } else {
        if (status == 400) {
          let errorResponse = JSON.parse(response);
          toasts.error(errorResponse.error.message);
        } else {
          toasts.error('Something went wrong, please try again.', 'Sorry')
        }
      }
    };
    this.uploader.onWhenAddingFileFailed = (item: any, filter: any, options: any) => {
      switch (filter.name) {
        case 'fileSize':
          toasts.error(`Maximum upload size exceeded (less than 2 Mb files allowed)`);
          break;
        default:
          toasts.error(`Unknown error (filter is ${filter.name})`);
          break;
      }
    };
  }


  upload(item, attachText, isPublic = false) {
    if (!attachText) {
      this.toasts.error('Attach text field is required')
    } else {
      item.options.additionalParameter =
        {
          attachText: attachText,
          is_private: 1,
        };
      item.upload();
    }
  }

  fileSelected(event) {
    this.billCostUploader.queue.forEach((item: FileItem) => {
      // @ts-ignore
      if (!item.file.id) {
        // @ts-ignore
        item.options.additionalParameter = {
          attachText: item.file.name,
          is_private: 1,
          trialline_id: this.triallineId,
          type: 'bill_cost',
        };
        item.upload();
      }
    })
  }

  changeBillCostFilesAttached2(event, file) {
    let index =this.billCostFiles.indexOf(file);
    this.billCostFiles[index].file.attach_text = event.target.value;
  }

  changeBillCostFilesAmount2(event, file) {
    let index = this.billCostFiles.indexOf(file);
    this.billCostFiles[index].file.amount = event.target.value;
  }

  changeBillCostFilesType2(event, file) {
    let index = this.billCostFiles.indexOf(file);
    this.billCostFiles[index].file.bill_cost = event.target.value;
  }

  handleDropzoneQueue(count: number) {

    this.saveAvailable = count < 1;
    this._changeDetectionRef.detectChanges();
  }

  ngOnInit() {
    this.config = { toolbar:'Full', uiColor : '#f8f8f8'}
    this.thumbnail_Image_src = ''
    this.eventCopy = new Event();
    console.log("this.eventCopy", this.eventCopy)
    this.eventCopy.thumbnail_encryptid = this.event.thumbnail_encryptid;
    this.eventCopy.title = 'Copy ' + this.event.title;
    this.eventCopy.date_start = this.event.date_start;
    this.eventCopy.if_start_days = this.event.if_start_days;
    this.eventCopy.has_times = this.event.has_times;
    this.eventCopy.has_times_end = this.event.has_times_end;
    this.eventCopy.date_end = this.event.date_end;
    this.eventCopy.if_end_days = this.event.if_end_days;
    this.eventCopy.bates_number = this.event.bates_number;
    this.eventCopy.exhibit_number = this.event.exhibit_number;
    this.eventCopy.description = this.event.description;
    this.eventCopy.provider_name = this.event.provider_name;
    this.eventCopy.provider_phone = this.event.provider_phone;
    this.eventCopy.provider_email = this.event.provider_email;
    this.eventCopy.facility_name = this.event.facility_name;
    this.eventCopy.address = this.event.address;
    this.eventCopy.office_number = this.event.office_number;
    this.eventCopy.city = this.event.city;
    this.eventCopy.state = this.event.state;
    this.eventCopy.postal_code = this.event.postal_code;
    this.eventCopy.files = this.event.files;
    this.eventCopy.notes = '';
    this.eventCopy.updated_at = this.event.updated_at;
    this.eventCopy.category_id = this.event.category_id;
    this.eventCopy.tag_name = this.event.tag_name;
    this.eventCopy.thumbnail_attr = this.event.thumbnail_attr;
    this.eventCopy.thumbnail = this.event.thumbnail;

    this.eventCopy.thumbnail_visible = this.event.thumbnail_visible;
    this.eventCopy.thumbnail_encryptid = this.event.thumbnail_encryptid;
    this.thumbnailbackfolder = [];
    this.thumbnailfilesarray = [];
    this.eventtagslist = [];

    this.thumbnail_Image_src = this.event.thumbnail_encryptid  !== null ? this.publicFolderUrl +'index.php/v1/filesystem/'+(this.event.thumbnail_encryptid)+'?preview=1&date='+ this.event.updated_at?.replaceAll(' ', '').replaceAll(':', '').replaceAll('-', ''):(this.publicImages + (this.event.thumbnail_attr ? this.event.thumbnail_attr.substring(1) : this.defaultThumbnail.substring(1)))
    this.eventCopy.custom_field =this.event.custom_field ?JSON.parse(this.event.custom_field) :[]
    jQuery('#crop_block_1').hide();
    jQuery('#general_copy').collapse('show');
    jQuery('#provider-details_copy').collapse('hide');
    jQuery('#add-media_copy').collapse('hide');
    jQuery('#add-tag_copy').collapse('hide');
    jQuery('#notes_copy').collapse('hide');
    console.log("ngOnInit edit copy");

    this.triallinesResourceService.getTriallinesByPage({
      page: 1,
      sort: {field: "created_at", order: "ASC"},
      name: null,
      limit: 1000
    })
      .subscribe((response) => {
        this.triallines = response['triallines'];
      });
    this.loadCategories();
    this.updateEventData(this.event)
    this.http.get('file/fromLibrary' + (this.triallineId ? '?trialline_id=' + this.triallineId : ''), {},
      false).pipe(map(r => r.body)).subscribe
    ((r: any) => {
      this.thumbnailfiles = r
      let length = r.general.length;
      for (let i = 0; i < length; i++) {
        if (((r.general[i].original_path).split("/", 100)).length < 4) {
          if (r.general[i].type == 'image') {
            if (this.thumbnailfilesarray.includes(r.general[i]) == false) {
              this.thumbnailfilesarray.push(r.general[i])
            }
          }
        } else {
          if (r.general[i].type == 'image') {
            let splittext = (r.general[i].original_path).split("/", 100)[2]
            if (this.ttfolder.includes(splittext) == false) {
              this.ttfolder.push((r.general[i].original_path).split("/", 100)[2]);
            }
          }
        }
      }
      for (let i = 0; i < r.trialline.length; i++) {
        if (((r.trialline[i].original_path).split("/", 100)).length < 4) {
          if (r.trialline[i].type == 'image') {
            this.thumbnailfilesarray.push(r.trialline[i])
          }
        } else {
          if (r.trialline[i].type == 'image') {
            let splittext = (r.trialline[i].original_path).split("/", 100)[2]
            if (this.ttfolder.includes(splittext) == false) {
              this.ttfolder.push((r.trialline[i].original_path).split("/", 100)[2]);
            }
          }
        }
      }
    })
  }

  loadCategories() {
    this.triallineResoureService.getCategories(this.triallineId).toPromise()
      .then((categories: Category[]) => {
        this.categories = categories;
      })
      .catch((error) => {
        this.toasts.error(error);
      });
  }

  showCropImage() {
    this.cropped_image_src = this.publicFolderUrl +'index.php/v1/filesystem/'+(this.event.thumbnail_encryptid)+'?preview=1';
    var self = this;
    this.cropProcessing = true;
    this.crop_image = jQuery('#crop_img')[0] as HTMLImageElement;
    this.crop_image.src = this.cropped_image_src ;
    this._changeDetectionRef.detectChanges();
    this.crop_image.addEventListener('load', function(event) {
      var cropper = new Cropper(self.crop_image, {
        aspectRatio: 1 / 1,
        highlight: false,
        zoomable: false,
        responsive: false,
        minContainerWidth: 300,
        minContainerHeight: 300,
        crop(event) {
          self.cropper = cropper;
        }
      });
    });
    this.crop_image.addEventListener( 'ready', function (event) {
      self.cropProcessing = false;
      self.isCropped = true;
      self._changeDetectionRef.detectChanges();
      jQuery('#crop_block_1').show();
    })
  }

  cropApply() {
    this.cropProcessing = true;
    this.isCropped = false;
    this.cropper.getCroppedCanvas({
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
    }).toBlob((blob) => {
      const formData = new FormData();
      jQuery('#crop_block').hide();
      let filename = this.event.thumbnail_attr.substring(this.event.thumbnail_attr.lastIndexOf('/')+1);
      filename = filename.replace(/\.[^/.]+$/, "")
      let finalFilname = filename+".jpeg"
      formData.append('file', blob,finalFilname);
      this.uploadThumbnail(formData);
    },'image/jpeg',1);
  }

  cropCancel() {
    jQuery('#crop_block_1').hide();
    this.cropper.destroy();
  }

  uploadThumbnail(formData) {
    var self = this;
    jQuery.ajax(env.apiUrl + 'file/thumbnail', {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + this.authService.token()},
      data: formData,
      processData: false,
      contentType: false,
      success(response) {
        jQuery('#processing').hide();
        if (response.body.path) {
          self.event.thumbnail = response.body.path;
        }
        if (response.body.encrypt_id) {
          self.event.thumbnail_encryptid = response.body.encrypt_id;
        }
        self.cropProcessing = false;
        if (response.body.message) {
          self.toasts.success(response.body.message);
        }
        if (response.body.file_id) {
          var file_id =  response.body.file_id ? response.body.file_id : null;
        }
        self.thumbnailUploaded(self.event.thumbnail, self.event.thumbnail_encryptid, file_id);
        self._changeDetectionRef.detectChanges();
        self.cropper.destroy();
        jQuery('#crop_block_1').hide();
      },
      error() {
        self.cropProcessing = false;
        jQuery('#processing').hide();
        self.toasts.error('Something went wrong', 'Sorry');
      },
    });
  }

  ngOnDestroy() {
   // this.sub.unsubscribe();
    this._changeDetectionRef.detach();
  }

  ngOnChanges(event) {
    if(this.event != undefined && event.event.currentValue != undefined) {
      this.updateEventData(this.event)
    }
  }

  ngAfterViewInit() {
    jQuery(this.modalId)
      .velocity("transition.slideRightBigIn");
    let self = this;
    jQuery(this.modalId).on('hidden.bs.modal', function () {
      if (!jQuery(this).hasClass('in')) {
        self.onClose.emit(self.eventCopy);
      } else {
        jQuery('body').addClass('modal-open');
      }
    });
  }

  switchHasTimes() {
    if (this.eventCopy) {
      this.event.has_times = !this.event.has_times;
    }
  }

  switchHasTimesEnd() {
    if (this.event) {
      this.event.has_times_end = !this.event.has_times_end;
    }
  }

  switchEndDate() {
    if (this.eventCopy) {
      this.event.end_date = this.event.end_date == null ? new Date() : null;
    }
    this.event.has_times_end = false;
  }

  checkNumber(value: any) {
    return Number.isInteger(value);
  }

  saveEventCopy(form: NgForm) {

    this.errors = {};
    this.valid = form.valid;
    if (!Number.isInteger(form.controls.category_id.value * 1) && this.valid) {
      this.valid = false;
      this.categoryValid = false;
    }
    if(form.value.category_id === '0'){
      this.toasts.error("Please Select a valid Category");
      return;
    }
    if (this.valid) {

      let data: any = form.value;
      if (this.filesIds && this.filesIds.length > 0) {
        data.files = this.filesIds;
      }
      if (this.videosIds && this.videosIds.length > 0) {
        data.videos = this.videosIds;
      }
      if (this.privateFilesIds && this.privateFilesIds.length > 0) {
        data.private_attachments = this.privateFilesIds;
      }
      if (this.contactsEventId && this.contactsEventId.length > 0) {
        data.contacts_id = this.contactsEventId;
      }
      data.thumbnail = this.eventCopy.thumbnail_attr;
      data.start_date = data.start_date ? this.parseDate(data.start_date, data.has_times ? data.start_time : null)
        : null;
      data.end_date = data.end_date ? this.parseDate(data.end_date, (data.has_times_end ? data.end_time : null))
        : null;
      if ((this.sharedService.encriptedpaththumbnail == '') || (this.sharedService.encriptedpaththumbnail == null)) {
        this.thumbnailstatus = false;
        // this.thumbnail_encryptid = '';
        this.thumbnail_encryptid = this.eventCopy.thumbnail_encryptid;
      } else {
        this.thumbnailstatus = true;
        this.thumbnail_encryptid = this.sharedService.encriptedpaththumbnail;
      }
      data.bill_cost_attachments = [];
      if (this.billCostFiles && this.billCostFiles.length > 0) {
        this.billCostFiles.forEach((item) => {
          data.bill_cost_attachments.push({
            id: item.file.id,
            amount: item.file.amount,
            attach_text: item.file.attach_text,
            bill_cost: item.file.bill_cost,
            file_id: item.file.file.id
          });
        })
      }
      data.thumbnail_id = this.eventCopy.thumbnail_id ;
      data.thumbnailstatus = this.thumbnailstatus;
      data.thumbnail_encryptid = this.thumbnail_encryptid;
      this.eventtagslist = this.sharedService.tagarray
      if (this.eventtagslist.length > 0) {
        data.tag = this.eventtagslist;
      } else {
        data.tag = [];
      }
      this.createEvent(data);
    } else {
      this.toasts.error('Please, fill the required fields!');
      if (!Number.isInteger(form.controls.category_id.value * 1)) {
        this.categoryValid = false;
      }
    }
  }

  parseDate(date: Date, time: Date = null) {
    // console.log("date", date);
    // let newdate = new Date(date);
    // let newTime = new Date(time);
    // console.log("time",newdate, date,  time, newTime);

    // let finalDate = (newdate .getFullYear(), newdate.getMonth(), newdate .getDate())
    // let finalTime = (time ? newTime.getHours() : 0, time ? newTime.getMinutes() : 0, time ? newTime.getSeconds() : 0)
    // let outDate = new Date(finalDate, finalTime);
    return date
  }

  createEvent(data) {
    data.old_event = this.event.id;
    this.eventsResourceService.createEvent(this.triallineId, data).toPromise()
      .then((event) => {
        // @ts-ignore
        if (event.tags_count > 0) {
          // @ts-ignore
          for (let i = 0; i < event.tag_name.length; i++) {
            // @ts-ignore
            this.sharedService.tagsid.push(event.tag_name[i].id)
          }
        }
        // @ts-ignore
        this.onCreated.emit(event);
        this.toasts.success('Successfully created');
        jQuery(this.modalId).modal('hide');
        jQuery('#event-edit-modal').modal('hide');
        jQuery("#general").css('collapse')
        jQuery("#general_copy").css('collapse')
      })
      .catch((error) => {
        if (error.status == 422) {
          let errorData: any = error.error;
          this.errors = errorData.data;
        } else {
          this.toasts.error(error);
        }
      });
  }

  addCategory(category: Category) {
    this.categories.push(category);
    this.eventCopy.category_id = category.id;
    this.eventCopy.category = category;
    this.categoryValid = true;
  }

  updateFiles(files: FileModel[]) {
    this.eventCopy.files = files;
    this._changeDetectionRef.detectChanges();
  }


  addContact(contact_id) {
    this.contactsEventId.push(contact_id);
    this._changeDetectionRef.detectChanges();
  }

  removeContact(contact) {
    let index = this.contactsEventId.indexOf(contact.id);
    this.contactsEventId.splice(index, 1);
    this._changeDetectionRef.detectChanges();
  }

  updateEventData(event: Event) {
    let title = event.title ? event.title : '';
    this.config = { toolbar:'Full', uiColor : '#f8f8f8'}
    this.eventCopy = new Event();
    this.eventCopy.title          = 'Copy ' + title;
    this.eventCopy.date_start     = event.date_start;
    this.eventCopy.if_start_days  = event.if_start_days;
    this.eventCopy.has_times      = event.has_times;
    this.eventCopy.has_times_end      = event.has_times_end;
    this.eventCopy.date_end       = event.date_end;
    this.eventCopy.if_end_days    = event.if_end_days;
    this.eventCopy.bates_number   = event.bates_number;
    this.eventCopy.exhibit_number = event.exhibit_number;
    this.eventCopy.description    = event.description;
    this.eventCopy.provider_name  = event.provider_name;
    this.eventCopy.provider_phone = event.provider_phone;
    this.eventCopy.provider_email = event.provider_email;
    this.eventCopy.facility_name  = event.facility_name;
    this.eventCopy.address        = event.address;
    this.eventCopy.office_number  = event.office_number;
    this.eventCopy.city           = event.city;
    this.eventCopy.state          = event.state;
    this.eventCopy.postal_code    = event.postal_code;
    this.eventCopy.files          = [];
    this.eventCopy.notes          = '';
    this.eventCopy.category_id    = event.category_id;
    this.eventCopy.contacts       = event.contacts;
    this.eventCopy.tag_name       = event.tag_name;
    this.eventCopy.thumbnail_attr = event.thumbnail_attr;
    this.eventCopy.thumbnail      = event.thumbnail;
    this.eventCopy.thumbnail_visible = event.thumbnail_visible;
    this.eventCopy.custom_field = JSON.parse(event.custom_field);
    this.eventCopy.thumbnail_encryptid = event.thumbnail_encryptid;
    this.thumbnail_Image_src = event.thumbnail_encryptid  !== null ? this.publicFolderUrl +'index.php/v1/filesystem/'+(event.thumbnail_encryptid)+'?preview=1&date='+ event.updated_at.replaceAll(' ', '').replaceAll(':', '').replaceAll('-', '') : (this.publicImages + (event.thumbnail_attr ? event.thumbnail_attr.substring(1) : this.defaultThumbnail.substring(1)))
    this.thumbnailbackfolder = [];
    this.thumbnailfilesarray = [];
    this.eventtagslist       = [];
    for (let i = 0; i < event.files.length; i++) {
      let isNew = true;
      if (this.eventCopy.files.length > 0){
        for (let f = 0; f < this.eventCopy.files.length; f++) {
          if(this.eventCopy.files[f].title == event.files[i].title
            && this.eventCopy.files[f].size == event.files[i].size) {
            isNew = false;
          }
        }
      }
      if (isNew) {
        this.eventCopy.files.push(event.files[i])
      }
    }
    if (!this.eventCopy) {
      this.eventCopy.date_start = null;
    } else {
      this.contactsEventId = _.pluck(this.eventCopy.contacts, 'id');
      this.exceptFileIds = _.pluck(this.eventCopy.files, 'id');
    }
    this.eventtagslist = this.eventCopy.tag_name;
    this.sharedService.tagarray = this.eventtagslist;
    this.sub = this.route.parent.params.subscribe(params => {
      this.triallineId = + params["trialline_id"];
    });
    this._changeDetectionRef.detectChanges();
  }

  addFile(file: FileModel) {
    if (!this.eventCopy.files) this.eventCopy.files = [];
    this.eventCopy.files.push(file);
    this.filesIds.push(file.id);
    this.exceptFileIds.push(file.id);
    this.sharedFilesCopy.onFileSubmit(file);
    this._changeDetectionRef.detectChanges();
  }

  removeFile(file: FileModel) {
    this.fileLibraryResourceService.deleteFileFromEvent(file).toPromise()
      .then((response: any) => {
        this.toasts.success('Successfully deleted');
        let index = this.eventCopy.files.indexOf(file);
        this.eventCopy.files.splice(index, 1);
        let indexPrivate = this.eventCopy.private_files.indexOf(file);
        this.eventCopy.private_files.splice(indexPrivate, 1);
        this.privateFilesIds = this.privateFilesIds.filter(item => item !== file.id);
        this.billCostFiles = this.billCostFiles.filter(item => item.file.id !== file.id)
        this.filesIds = this.filesIds.filter(item => item !== file.id);
        this._changeDetectionRef.detectChanges();
      }).catch((error) => {
      this.toasts.error(error);
    });
  }

  cloudFile(file: FileModel) {
    this.fileLibraryResourceService.cloudFile(file).toPromise()
      .then((status: string) => {
        this.toasts.success('Successfully ' + status);
        let index = this.eventCopy.files.indexOf(file);
        this.eventCopy.files[index].in_library = !this.eventCopy.files[index].in_library;
      }).catch((error) => {
      this.toasts.error(error);
    });
  }

  openFromLibraryModal() {
    this.fromLibraryModal = true;
    this._changeDetectionRef.detectChanges();
    jQuery(this.fromLibraryModalId).modal('show');
  }

  thumbnailUploaded(path: string, encryptid = '', file_id) {
    this.eventCopy.thumbnail_attr = path;
    this.eventCopy.thumbnail_encryptid = encryptid;
    this.thumbnail_Image_src = encryptid  !== null ? this.publicFolderUrl +'index.php/v1/filesystem/'+(encryptid)+'?preview=1&date='+ this.event.updated_at?.replaceAll(' ', '').replaceAll(':', '').replaceAll('-', ''):(this.publicImages + (this.event.thumbnail_attr ? this.event.thumbnail_attr.substring(1) : this.defaultThumbnail.substring(1)))
    this.eventCopy.thumbnail_id = file_id;
    this.sharedService.encriptedpaththumbnail = '';
    this._changeDetectionRef.detectChanges();
  }

  deleteThumbnail(path) {
    if (decodeURI(path).indexOf(this.eventCopy.category.thumbnail) === -1) {
      this.eventCopy.thumbnail_attr = this.eventCopy.category.thumbnail;
      this.eventCopy.thumbnail_encryptid = '';
      this.sharedService.encriptedpaththumbnail = '';
      this._changeDetectionRef.detectChanges();
    }
  }

  showDeleteIcon(path) {
    if (this.eventCopy && this.eventCopy.category) {
      return decodeURI(path).indexOf(this.eventCopy.category.thumbnail) === -1
        && decodeURI(path).indexOf(this.defaultThumbnail) === -1;
    }
  }

  deleteEvent() {
    jQuery(this.modalId).modal('hide');
    this.onDelete.emit(this.eventCopy);
  }

  categoryChanged(id) {
    if (id) {
      let category = this.categories.find(e => e.id == id);
      let previousCategory = this.eventCopy.category;
      if (this.eventCopy.id) {
        if (
          (previousCategory &&
            this.eventCopy.thumbnail_attr.indexOf(previousCategory.thumbnail) !== -1) ||
          this.eventCopy.thumbnail_attr.indexOf(this.defaultThumbnail) !== -1) {
          this.eventCopy.thumbnail_attr = category.thumbnail;
        }
      } else {

        if (!this.eventCopy.thumbnail_attr ||
          (this.eventCopy.thumbnail_attr && this.eventCopy.thumbnail_attr.indexOf(this.defaultThumbnail) !== -1) ||
          (previousCategory && this.eventCopy.thumbnail_attr.indexOf(previousCategory.thumbnail) !== -1)) {
          if (category) {
            this.eventCopy.thumbnail_attr = category.thumbnail;
          }
        }
      }
      this.categoryValid = true;
      if (!category) {
        this.categoryValid = false;
      }
      this.eventCopy.category = category;
      this._changeDetectionRef.detectChanges();
    }
  }

  uploadVideo(video_url) {
    this.eventsResourceService.uploadVideo(this.triallineId, video_url).toPromise()
      .then((video) => {
        this.eventCopy.videos = this.eventCopy.videos ? this.eventCopy.videos : [];
        this.eventCopy.videos.push(video);
        // @ts-ignore
        this.videosIds.push(video.id);
      })
  }

  deleteVideo(video: Video) {
    this.eventsResourceService.deleteVideo(this.triallineId, this.eventCopy.id, video.id).toPromise()
      .then((video) => {
        let index = this.eventCopy.videos.indexOf(video);
        this.eventCopy.videos.splice(index, 1);
      })
  }

  closeAddFilesModal() {
    jQuery(this.fromLibraryModalId).modal('hide');
    this.eventtagslist = [];
    this._changeDetectionRef.detach();
  }

  onCKChange(description: any) {
    let TextGrab = description.editor.getData();
    TextGrab = $(TextGrab).text(); // html to text
    TextGrab = TextGrab.replace(/\r?\n|\r/gm, " "); // remove line breaks
    TextGrab = TextGrab.replace(/\s\s+/g, " ").trim();
    let res1 = TextGrab.substr(TextGrab.length - 1)
    if (res1 == "#") {
      this.status1 = true;
      jQuery("#triallinemodal_copy").modal('show');
      jQuery($('#triallinemodal_copy').css('top:70%'));
    } else {
      this.status1 = false;
      jQuery("#triallinemodal_copy").modal('hide');
    }
  }


  getTriallineLink(trlID, trlName) {
    this.prev_pos = this.cursor_position + trlName.length;
    this.status1 = false;
    if (this.eventCopy.description == null) {
      this.eventCopy.description = '';
    }
    this.alltr += trlName;
    this.eventCopy.description = this.eventCopy.description.replace('#</p>', '');
    this.eventCopy.description += (' <a target="_blank" title=\x22' + trlName
      + '\x22 href=\x22https://trialline.net/trialline/' + trlID + '\x22>' + trlName + '</a>');
  }


  showaccordian_copy(id) {
    const $general = jQuery('#general_copy');
    const $providerDetails = jQuery('#provider-details_copy');
    const $addMedia = jQuery('#add-media_copy');
    const $notes = jQuery('#notes_copy');
    const $addTag = jQuery('#add-tag_copy');
    const $addContact = jQuery('#add-contact');
    const $billCost = jQuery('#add-bill-cost');
    if (id == 1) {
      $general.collapse('show');
      $providerDetails.collapse('hide');
      $addMedia.collapse('hide');
      $notes.collapse('hide');
      $addTag.collapse('hide');
      $billCost.collapse('hide');
    } else if (id == 2) {
      $general.collapse('hide');
      $providerDetails.collapse('show');
      $addMedia.collapse('hide');
      $notes.collapse('hide');
      $billCost.collapse('hide');
      $addTag.collapse('hide');
    } else if (id == 3) {
      $general.collapse('hide');
      $providerDetails.collapse('hide');
      $addMedia.collapse('show');
      $notes.collapse('hide');
      $billCost.collapse('hide');
      $addTag.collapse('hide');
    } else if (id == 4) {
      $general.collapse('hide');
      $providerDetails.collapse('hide');
      $addMedia.collapse('hide');
      $notes.collapse('show');
      $addTag.collapse('hide');
      $billCost.collapse('hide');
    } else if (id == 5) {
      $general.collapse('hide');
      $providerDetails.collapse('hide');
      $addMedia.collapse('hide');
      $notes.collapse('hide');
      $addTag.collapse('show');
      $billCost.collapse('hide');
      this.eventtagslist = this.sharedService.tagarray
    } else if (id == 6) {
      $general.collapse('hide');
      $providerDetails.collapse('hide');
      $addMedia.collapse('hide');
      $notes.collapse('hide');
      $addTag.collapse('hide');
      $addContact.collapse('show');
      $billCost.collapse('hide');
    } else if (id == 7) {
      $general.collapse('hide');
      $providerDetails.collapse('hide');
      $addMedia.collapse('hide');
      $notes.collapse('hide');
      $addTag.collapse('hide');
      $addContact.collapse('hide');
      $billCost.collapse('show');
    }
  }

  openthumbnailFromLibraryModal() {
    if (this.firsttime == false) {
      jQuery("#add-thumbnail-from-library-modal_copy").modal('hide');
    } else {
      jQuery("#add-thumbnail-from-library-modal_copy").modal('show');
    }
    this._changeDetectionRef.detectChanges();
  }

  getFileName(name: string) {
    if (name) {
      return name.replace(/\d{9,}_/, '');
    }
    return name;
  }

  selectFileeventthumbnail(file) {
    this.thumbnailstatus = true;
    this.encrypt_id = file.encrypt_id
    this.sharedService.encriptedpaththumbnail = file.encrypt_id
    this.eventCopy.thumbnail_encryptid = file.encrypt_id
    jQuery("#add-thumbnail-from-library-modal_copy").modal('hide');
  }

  closePreviewer1() {
    jQuery("#add-thumbnail-from-library-modal_copy").modal('hide');
  }

  OpenTrlFolderthumbnail(Fname) {
    if (this.thumbnailpath == undefined || this.thumbnailpath == '') {
      this.thumbnailpath = Fname;
    } else {
      this.thumbnailpath = (this.thumbnailpath).concat('/' + Fname);
    }
    this.thumbnailbackfolder = this.thumbnailpath.split("/", 100);
    this.thumbnailfilesarray = [];
    this.ttfolder = [];
    for (let i = 0; i < this.thumbnailfiles.general.length; i++) {
      let tte = this.thumbnailpath;
      let patharray1 = (this.thumbnailfiles.general[i].original_path.split('/'));
      let joinedArray1 = patharray1.slice(2, tte.split("/", 100).length + 2).join("/");
      if (this.thumbnailfiles.general[i].type == 'image') {
        if (joinedArray1 == this.thumbnailpath) {
          if ((this.thumbnailfiles.general[i].original_path.indexOf(this.thumbnailpath) !== -1)) {
            let indexid = (this.thumbnailpath.split("/", 100).length) + 2;
            if (((this.thumbnailfiles.general[i].original_path)
              .split("/", 100)).length - 3 == (this.thumbnailpath.split("/", 100).length)) {
              this.thumbnailfilesarray.push(this.thumbnailfiles.general[i])
            } else {
              let splittext = (this.thumbnailfiles.general[i].original_path).split("/", 100)[indexid]
              if (this.ttfolder.includes(splittext) == false) {
                this.ttfolder.push((this.thumbnailfiles.general[i].original_path).split("/", 100)[indexid]);
              }
            }
          }
        }
      }
    }
    for (let j = 0; j < this.thumbnailfiles.trialline.length; j++) {
      let ttet = this.thumbnailpath;
      let patharray = (this.thumbnailfiles.trialline[j].original_path.split('/'));
      let joinedArray = patharray.slice(2, ttet.split("/", 100).length + 2).join("/");
      if (this.thumbnailfiles.trialline[j].type == 'image') {
        if (joinedArray == this.thumbnailpath) {
          if ((this.thumbnailfiles.trialline[j].original_path.indexOf(this.thumbnailpath) !== -1)) {
            let indexid = (this.thumbnailpath.split("/", 100).length) + 2;
            if (((this.thumbnailfiles.trialline[j].original_path)
              .split("/", 100)).length - 3 == (this.thumbnailpath.split("/", 100).length)) {
              this.thumbnailfilesarray.push(this.thumbnailfiles.trialline[j])
            } else {
              let splittext = (this.thumbnailfiles.trialline[j].original_path).split("/", 100)[indexid]
              if (this.ttfolder.includes(splittext) == false) {
                this.ttfolder.push((this.thumbnailfiles.trialline[j].original_path).split("/", 100)[indexid]);
              }
            }
          }
        }
      }
    }
  }

  thumbnaillevelup() {
    this.thumbnailpath = this.thumbnailpath.split('/');
    this.thumbnailpath.pop();
    this.thumbnailpath = this.thumbnailpath.join('/');
    this.thumbnailfilesarray = [];
    this.ttfolder = [];
    if (this.thumbnailpath / length < 1) {
      this.ngOnInit();
    } else {
      for (let i = 0; i < this.thumbnailfiles.general.length; i++) {
        var tte = this.thumbnailpath;
        var patharray1 = (this.thumbnailfiles.general[i].original_path.split('/'));
        var joinedArray1 = patharray1.slice(2, tte.split("/", 100).length + 2).join("/");
        if (this.thumbnailfiles.general[i].type == 'image') {
          if (joinedArray1 == this.thumbnailpath) {
            if ((this.thumbnailfiles.general[i].original_path.indexOf(this.thumbnailpath) !== -1)) {
              let indexid = (this.thumbnailpath.split("/", 100).length) + 2;
              if (((this.thumbnailfiles.general[i].original_path)
                .split("/", 100)).length - 3 == (this.thumbnailpath.split("/", 100).length)) {
                this.thumbnailfilesarray.push(this.thumbnailfiles.general[i])
              } else {
                let splittext = (this.thumbnailfiles.general[i].original_path).split("/", 100)[indexid]
                if (this.ttfolder.includes(splittext) == false) {
                  this.ttfolder.push((this.thumbnailfiles.general[i].original_path)
                    .split("/", 100)[indexid]);
                }
              }
            }
          }
        }
      }
      for (let j = 0; j < this.thumbnailfiles.trialline.length; j++) {
        var ttet = this.thumbnailpath;
        var patharray = (this.thumbnailfiles.trialline[j].original_path.split('/'));
        var joinedArray = patharray.slice(2, ttet.split("/", 100).length + 2).join("/");
        if (this.thumbnailfiles.trialline[j].type == 'image') {
          if (joinedArray == this.thumbnailpath) {
            if ((this.thumbnailfiles.trialline[j].original_path.indexOf(this.thumbnailpath) !== -1)) {
              var indexid = (this.thumbnailpath.split("/", 100).length) + 2;
              if (((this.thumbnailfiles.trialline[j].original_path)
                .split("/", 100)).length - 3 == (this.thumbnailpath.split("/", 100).length)) {
                this.thumbnailfilesarray.push(this.thumbnailfiles.trialline[j])
              } else {
                var splittext = (this.thumbnailfiles.trialline[j].original_path).split("/", 100)[indexid]
                if (this.ttfolder.includes(splittext) == false) {
                  this.ttfolder.push((this.thumbnailfiles.trialline[j].original_path).split("/", 100)[indexid]);
                }
              }
            }
          }
        }
      }
    }
  }

  eventtags_copy() {
    const tag = jQuery('#tag_copy');
    if ((tag.val().trim() != '') || (tag.val().trim() != null)) {
      if ((this.eventtagslist.some(e => e.tag_name === tag.val().trim().toUpperCase())) == true) {
        this.toasts.error('Sorry,Tag is Already Exists.');
      } else {
        this.eventtagslist.push({'tag_name': tag.val().trim().toUpperCase()});
        this.sharedService.newaddtag.push(tag.val().trim())
        $('#tag_copy').val('');
      }
    }
  }

  removetag_copy(tag) {
    this.eventtagslist = this.eventtagslist.filter(item => item !== tag);
    this.sharedService.tagarray = this.eventtagslist;
    let data = [];
    if (tag.id != undefined) {
      data.push({'tag_id': tag.id, 'event_id': tag.event_id})
      this.eventsResourceService.deletetag(this.triallineId, data)
    }
  }

  getparticulartag(event) {
    if (event.keyCode == 13) {
      this.eventtags_copy();
    } else {
      let data = [];
      const $tag = jQuery('#tag_copy');
      this.searchtag = [];
      if (($tag.val().trim() == '') || ($tag.val().trim() == null)) {
        this.searchtag = [];
      } else {
        data.push({"search_tag": $tag.val().trim()})
        this.eventsResourceService.searchtag(this.triallineId, data).toPromise()
          .then((response) => {
            // @ts-ignore
            this.searchtag = response;
          })
      }
    }
  }

  addtag(tag) {
    this.eventtags_copy();
    this.searchtag = [];
  }

  Test() {
    this.firsttime = true
  }

  mouseleavefunction() {
    this.firsttime = false
  }

  getkeyvalue(event) {
    $(":focus").each(function () {
      if ($(this).attr("id") == 'tag_copy') {
      }
    });
  }

  hidemodal() {
    this.status1 = false;
    jQuery("#triallinemodal_copy").modal('hide');
    this._changeDetectionRef.detach();
  }

  hideModelEvent() {
    jQuery(this.modalId).modal().hide();
    this._changeDetectionRef.detach();
  }

  modalClosed() {
    jQuery(this.modalId).modal('hide');
    this._changeDetectionRef.detach();
  }
}
