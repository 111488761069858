import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, ActivatedRoute} from "@angular/router";

import {AuthService} from "./auth.service";
import {User} from "./user.model";


@Injectable()
export class UserTypeGuestCanActivate implements CanActivate
{
  constructor(protected authService: AuthService, protected router: Router) {}


  canActivate() : boolean
  {
    this.authService.user().subscribe(
      (userObj: User) => {
        if (userObj.user_type == 5) {
          this.router.navigateByUrl('/dashboard');
        }
      })
    return true;
  }


}
