import {NgModule} from '@angular/core';
import {HttpClientModule} from "@angular/common/http";
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {ApplicationComponent} from "./application.component";
import {ModalModule} from "ngx-modialog-7";
import { BootstrapModalModule } from 'ngx-modialog-7/plugins/bootstrap';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    ModalModule.forRoot(),
    BootstrapModalModule
  ],
  declarations: [
    ApplicationComponent
  ],
})
export class ApplicationModule {}
