import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Inject,
  OnInit,
  ViewChild,
  EventEmitter,
  Input,
  Output,
  SimpleChange, ElementRef, HostListener
} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Title} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {NgForm} from "@angular/forms";
import {Trialline} from "../../shared/trialline.model";
import {env} from "../../../../../.env";
import {FileModel} from "../../file-library/shared/file.model";
import {FileUploader} from "ng2-file-upload";
import {AuthService} from "../../../auth/shared/auth.service";
import {TriallineResourceService} from "../shared/trialline-resoruce.service";
import {FileLibraryResourceService} from "../../file-library/shared/file-library-resoruce.service";
import {Modal} from 'ngx-modialog-7/plugins/bootstrap';
import {TriallinesResourceService} from "../../triallines/shared/triallines-resoruce.service";
import {MainComponent} from "../../../layouts/main/main.component";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import * as screenfull from "screenfull";
import {Screenfull} from "screenfull";
import {DropzoneComponent} from "../../../shared-components/dropzone/dropzone.component";
import {saveAs} from 'file-saver';
import * as JSZip from 'jszip';
import JSZipUtils from "jszip-utils"

declare var _: any;
declare var jQuery: any;

const enum Status {
  OFF = 0,
  RESIZE = 1,
  MOVE = 2
}

@Component({
  selector: 'app-trialline-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class TriallineEditComponent implements OnInit, AfterViewInit {
  @ViewChild('triallineForm', {static: true})
  public triallineForm: NgForm;
  @Input() uploadedFiles: FileModel[];
  @Input() preview: boolean;
  @Input() list: boolean;
  @Input() cloudFunc: boolean;
  @Input() removeFunc: boolean;
  @Input() moreFiles: boolean;
  @Output() onClick = new EventEmitter<FileModel>();
  @Output() onRemove = new EventEmitter<FileModel>();
  @Output() onCloud = new EventEmitter<FileModel>();
  @Output() onFilesUpdated = new EventEmitter<FileModel[]>();

  @Input() trueValue = true;
  @Input() falseValue = false;

  
  editModalId: string = '#file-edit-modal';
  previewodalId: string = '#preview-modal';
  fullscreen: boolean = false;
  checkboxstore = [];
  moveid = [];
  renameid = [];
  folderrenameid = [];
  deleteid = [];
  folderdeleteid = [];
  movefolder = [];
  index = 1;
  folderoffolderid = []
  fileoffolderid = [];
  file: FileModel;
  multiFileObj: FileModel;
  updateFileToContact:FileModel;
  checkbox_checked: boolean;
  state: number;
  fileoffolderidanother = [];
  folderoffolderidanother = [];
  allid = [];
  fullpath: any;
  config: any;
  arr_name: any;
  pdfpath: any;
  foldernametoshow: any;
  //@Input() file: FileModel;
  newsubfoldervisible: boolean;
  newfoldervisible: boolean;

  [x: string]: any;

  response: any;
  trialline: Trialline;
  newFolder: Array<{ name: string }> = [];

  //backfolder:Array<{name: string,id:number}> = [];
  backfolder = [];

  publicFolderUrl = env.publicFolderUrl;
  apiPath = env.apiUrl;
  errors: any = {};
  allowedMimeTypes: any;
  public uploader: FileUploader;
  files: FileModel[] = [];
  allTLFiles: FileModel[] = [];
  backgroundsOld: any;
  backgrounds: FileModel[];
  backgroundId: number;
  @ViewChild('sharedFiles', {static: true}) sharedFiles;
  fromLibraryModalId = '#add-from-library-modal';
  editFileContactModalId: string = '#file-contacts-edit-modal';
  fromLibraryModal: boolean = false;
  fileContact: any;
  filesIds: number[] = [];
  exceptFileIds: number[] = [];
  exceptFileName: string[] = [];

//Folder
  Folders: any[] = [];
  filesi: any = [];
  TempFolder: any = [];
  filesOfFolder: FileModel[] = [];
  mainfolderavailabelinsubdirectory = [];
  saveAvailable: boolean = true;
  showFiles: boolean = false;
  folderName: string;
  showFolder: boolean;
  subFolder: any;
  foldercount: any;
  listView: boolean = true;
  listdesc: boolean = false;
  folderNamemain: any;
  cfolderNamemain: any;
  currentStatus: string;
  triallineId: number;
  listiconview: boolean;
  structure: boolean;
  isLoading: boolean = false;
  
  isButtonsShow: boolean = false;
  fileObj = [];

  //Pagination
  page: number = 1;
  orderCol: string = 'title';
  order: string = 'title';
  orderValue: string = '';
  perPage: number = 25;
  total: number;
  reverse: boolean = true;
  forbidden: boolean = false;
  loading: boolean;
  zipProcessing: boolean = false;

  pdfURL: SafeResourceUrl;

  width: number;
  height: number;
  left: number = 0;
  top: number = 0;
  imageGot: boolean = false;
  popupImage : any = '';
  isMultiSelect:any;
  private boxPosition: { left: number, top: number };
  private containerPos: { left: number, top: number, right: number, bottom: number };
  public mouse: {x: number, y: number}
  public status: Status = Status.OFF;
  private mouseClick: {x: number, y: number, left: number, top: number}
  @ViewChild("previewModal2")  public previewModal2: ElementRef;

  // date format array
  dateFormatList = [
    {
      'key': 'MDY',
      'value': 'MM/DD/YYYY'
    },
    {
      'key': 'DMY',
      'value': 'DD/MM/YYYY'
    },
    {
      'key': 'YMD',
      'value': 'YYYY/MM/DD'
    }
  ];

  constructor(private _sanitizer: DomSanitizer,
              protected toasts: ToastrService,
              protected title: Title,
              protected route: ActivatedRoute, public router: Router,
              private triallineResourceService: TriallineResourceService,
              private triallinesResourceService: TriallinesResourceService,
              private fileLibraryResourceService: FileLibraryResourceService,
              private _changeDetectionRef: ChangeDetectorRef,
              private authService: AuthService,
              private modal: Modal,
              @Inject(forwardRef(() => MainComponent)) public _layout: MainComponent
  ) {

    this.savehideshow = true;
    this.foldernametoshow = 'Home';
    this.DropzoneComponentobj = new DropzoneComponent(this.authService, this.toasts, this.fileLibraryResourceService);
    this.structure = false;
    this.countfile = 0;
    this.multifilescounter = 0;
    this.foldercount = 0;
    this.spinner = false

    this.fullpath = '';
    title.setTitle('Trialline | Edit')
    //refactor to component
    this.allowedMimeTypes = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg'];
    this.uploader = new FileUploader({
      url: env.apiUrl + 'file',
      authToken: 'Bearer ' + authService.token(),
      autoUpload: true
    });

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let body = JSON.parse(response).body;

      if (body !== undefined && body.file) {
        this.updateBackgroundImage(body.file);
      } else {
        toasts.error('Something went wrong', 'Sorry')
      }
    };
    this.uploader.onCancelItem = (item: any, response: any, status: any, headers: any) => {
      toasts.error('Something went wrong', 'Sorry')
    };

    //end
  }

  updateBackgroundImage(backgroundFile: FileModel) {
    this.trialline.background_file = backgroundFile;
    this.backgroundId = backgroundFile.id;
    this.trialline.files.push(backgroundFile);
    this.filesi.push(backgroundFile);
  }

  switch() {
    if (this.trialline.background_source == 'own') {
      this.trialline.background_source = 'template';
      this.backgroundId = this.backgrounds[0].id;
    } else {
      this.trialline.background_source = 'own';
    }
  }

  ngOnInit() { 
    this.isLoading = true;
    this.config = {toolbar: 'Full', uiColor: '#f8f8f8'}
    this.listiconview = true
    this.newfoldervisible = true;
    this.structure1 = true;
    this.newsubfoldervisible = false;
    localStorage.setItem("folderpath", '');
    localStorage.setItem("uploadcomponent", '1');
    this.movestatus = true;
    this.route.data.subscribe((data: { trialline: Trialline, backgrounds: FileModel[] }) => {
      this.backgrounds = data.backgrounds;
      this.trialline = data.trialline;
      this.backgroundId = this.trialline.background_file === null ? 1 : this.trialline.background_file.id;
      this.zipFilename = this.trialline.name
      this.fileLibraryResourceService.getTriallineFiles(this.trialline.id, {
        limit: this.perPage,
        page: 1,
        order: this.orderValue
      }).subscribe((response) => {
        this.files = this.filesi = response['files'];
        this.loading = false;
        this.forbidden = false;
        this.isLoading = false;
        this.total = response['total'];
        this.page = response['page'];
      });
      // collect all trialline files list
      this.fileLibraryResourceService.getTriallineFiles(this.trialline.id, {
        limit: 100000,
        page: 1,
        order: this.orderValue
      }).subscribe((response) => {
        this.allTLFiles = response['files'];        
      });

      this.fileLibraryResourceService.getFolders({'trlid': this.trialline.id}).toPromise().then((response: any) => {
        this.emptyfolder = response.folderData;
        let i = 0;
        for (i = 0; i < this.emptyfolder.length; i++) {
          var splittext = (this.emptyfolder[i].folder_path).split("/", 100)[0].trim()
          if (this.TempFolder.includes(splittext) == false) {
            this.TempFolder.push((this.emptyfolder[i].folder_path).split("/", 100)[0]);
          }
        }
        this.mainfolderavailabelinsubdirectory = this.TempFolder;
        
      })

      this.exceptFileIds = _.pluck(this.files, 'id');
      this.exceptFileName = _.pluck(this.files, 'title');
    });

    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('attach_to', 'trialline_background');
      form.append('attach_to_id', this.trialline.id);
    };
    for (let i = 0; i < this.files.length; i++) {
      this.allid.push(this.files[i].id);
      if (((this.files[i].original_path).split("/", 100)).length < 4) {
        this.filesi.push(this.files[i])
      } else {
        let splittext = (this.files[i].original_path).split("/", 100)[2]
        if (this.TempFolder.includes(splittext) == false) {
          this.TempFolder.push((this.files[i].original_path).split("/", 100)[2]);
        }
      }
    }
    this.triallineId = this.trialline.id;
    if (this.files !== undefined) {
      this.files.sort(this.dynamicSort('title'))
    }

    console.log('this.file', this.file)

  }

  ngAfterViewInit() {

    let self = this;
    jQuery("select.image-picker").imagepicker({
      changed: function (a, s) {
        self.backgroundId = +s[0];
      }
    });
    jQuery('#preview-modal').on('hidden.bs.modal', function (e) {
      self.closePreviewer();
    });
    if (screenfull && typeof screenfull !== "undefined") {
      let sf = <Screenfull>screenfull;
      sf.on('change', () => {
        this.fullscreen = sf.isFullscreen;
      });
    }
    this.width = 630;
    this.height = 560;
  }

  openFromLibraryModal() {
    this.fromLibraryModal = true;
    this._changeDetectionRef.detectChanges();

    jQuery(this.fromLibraryModalId).modal('show');
  }

  addFileFromContacts(file) {
    let check = true;
    this.files.forEach(item => {
      if(item.id == file.id) {
        this.files.map(item => {
          if(item.id == file.id) {
            item.title = file.title;
          }
        })
        check = false;
        return;
      }
    })
    if (check) {
      this.files.push(file.file);
      this.filesIds.push(file.file.id);
      this.allid.push(file.file.id);
      this.exceptFileIds.push(file.file.id);
      this.exceptFileName.push(file.file.title);
    }
  }

  addFile(file: FileModel) {

    let orpath = file.original_path.split('/');
    orpath.splice(orpath.length - 1, 1);
    if (this.subFolder == null) {
      file.original_path = orpath.join('/').concat('/' + file.title);
    } else {
      file.original_path = orpath.join('/').concat('/' + this.subFolder + '/' + file.title);
    }
    // this.files.push(file);
    this.filesi.push(file);
    this.filesIds.push(file.id);
    this.allid.push(file.id);
    this.exceptFileIds.push(file.id);
    this.exceptFileName.push(file.title);
    this.autoupdate(this.triallineForm)
    this._changeDetectionRef.detectChanges();
    if (this.sharedFiles != undefined) {
      this.sharedFiles.onFileSubmit(file);
    }
  }

  SelectedFile($e) {
    console.log($e);
  }

  update(form: NgForm) {
    if (this.saveAvailable) {
      let data = form.value;
      data.background = this.backgroundId;
      data.background_source = (_.pluck(this.backgrounds, 'id')).indexOf(data.background != -1) ? 'template' : 'own';

      if (this.filesIds.length > 0) {
        data.files = this.filesIds;
      }
      this.triallineResourceService.updateTrialline(this.trialline.id, data).toPromise()
        .then((trialline: Trialline) => {
          console.log(456456);
          this.toasts.success('Successfully updated.');
        })
        .catch((error) => {
          if (error.status == 422) {
            let errorData: any = error.error;
            this.errors = errorData.data;
          } else {
            this.toasts.error(error);
          }
        });
    }
  }

  autoupdate(form: NgForm) {
    let data = form.value;
    data.background = this.backgroundId;
    data.background_source = (_.pluck(this.backgrounds, 'id')).indexOf(data.background != -1) ? 'template' : 'own';

    if (this.filesIds.length > 0) {
      data.files = this.filesIds;
    }
    this.triallineResourceService.updateTrialline(this.trialline.id, data).toPromise()
      .then((trialline: Trialline) => {
        this.toasts.success('Successfully updated.');
      })
      .catch((error) => {
        if (error.status == 422) {
          let errorData: any = error.error;
          this.errors = errorData.data;
        } else {
          this.toasts.error(error);
        }
      });
  }

  checkedBackground(file) {
    this.backgroundId = file.id;
  }

  removeFile(file: FileModel) {
    if (this.trialline.files.length == 1) {
      this.toasts.error("Can't delete last background");
      return;
    }
    this.fileLibraryResourceService.deleteFile(file).toPromise()
      .then((response: any) => {
        if (this.trialline.files.length > 1) {
          this.trialline.files = this.trialline.files.filter(item => item.id !== file.id);
          this.backgroundId = this.trialline.files[0].id;
        }
        this.toasts.success('Successfully deleted.');
        let index = this.files.indexOf(file);
        this.files.splice(index, 1);
        this._changeDetectionRef.detectChanges();

      }).catch((error) => {
      this.toasts.error(error);
    });
  }

  removeFileEvent(file: FileModel) {
    this.fileLibraryResourceService.deleteFileFromEvent(file).toPromise()
      .then((response: any) => {
        if (this.trialline.files.length > 1) {
          this.trialline.files = this.trialline.files.filter(item => item.id !== file.id);
          this.backgroundId = this.trialline.files[0].id;
        }
        this.toasts.success('Successfully deleted.');
        let index = this.files.indexOf(file);
        this.files.splice(index, 1);
        this._changeDetectionRef.detectChanges();

      }).catch((error) => {
      this.toasts.error(error);
    });
  }

  cloudFile(file: FileModel) {
    this.fileLibraryResourceService.cloudFile(file).toPromise()
      .then((status: string) => {

        this.toasts.success('Successfully ' + status);
        let index = this.files.indexOf(file);

        this.files[index].in_library = !this.files[index].in_library;

      }).catch((error) => {
      this.toasts.error(error);
    });
  }

  imageIcons(fExtension: string) {
    switch(fExtension) { 
      case 'pdf': 
      { 
        return 'assets/images/icon-file-type/pdf.png';
        break; 
      } 
      case 'audio':
      { 
        return 'assets/images/icon-file-type/mp3.png'; 
        break; 
      }
      case 'table':
      { 
        return 'assets/images/icon-file-type/xls.png'; 
        break; 
      }
      case 'text':
      { 
        return 'assets/images/icon-file-type/doc.png';  
        break; 
      } 
      default: 
      { 
        return 'assets/images/custom-column-images/paperwork.png'; 
        break; 
      } 
    }
  }

  confirmedArchiveTrialline() {
    this.triallinesResourceService.archiveTrialline(this.trialline.id).toPromise()
      .then((trialline: Trialline) => {

        this.toasts.success('The TrialLine was archivated.');
        this.router.navigateByUrl('/dashboard');
      })
      .catch((error) => {

        if (error.status == 403) {
          let errorData = error.error;

          if (errorData.error.message) {
            this.toasts.error(errorData.error.message);
          }
        } else {

          this.toasts.error(error);
        }
      });
  }

  // confirmedDeleteTrialline() {
  //   this.triallinesResourceService.deleteTrialline(this.trialline.id).toPromise()
  //     .then((r: any) => {

  //       this.toasts.success('Successfully deleted TrialLine');
  //       this.router.navigateByUrl('/dashboard');
  //     })
  //     .catch((error) => {

  //       if (error.status == 403) {
  //         let errorData = error.error;

  //         if (errorData.error.message) {
  //           this.toasts.error(errorData.error.message);
  //         }
  //       } else {

  //         this.toasts.error(error);
  //       }
  //     });
  // }

  // deleteTrialline() {

  //   const dialogRef = this.modal.confirm()
  //     .size('lg')
  //     .isBlocking(true)
  //     .showClose(true)
  //     .keyboard(27)
  //     .title('Please confirm')
  //     .body('Warning! In case of deleting this Event you won\'t be able to renew it. Do you want to proceed?')
  //     .open()
  //   dialogRef.result
  //     .catch((err: any) => console.log('ERROR: ' + err))
  //     .then((dialog: any) => {
  //       if (dialog) {
  //         this.modelClose()
  //         this.confirmedDeleteTrialline()
  //       }
  //     })
  //   this.modelAction()
  // }

  handleDropzoneQueue(count: number) {

    this.saveAvailable = count < 1;
    this._changeDetectionRef.detectChanges();
  }

  OpenTrlFolderMain(folder, Fname) {
    if (this.fullpath == undefined || this.fullpath == '') {
      this.fullpath = Fname;
    } else {
      // this.fullpath=(this.fullpath).concat('/'+Fname);
      var lastWord = this.fullpath.match(/\w+$/)[0];
      if (lastWord == Fname) {
        this.fullpath;
      } else {
        this.fullpath = (this.fullpath).concat('/' + Fname);
      }
    }
    this.backfolder = this.fullpath.split("/", 100);

    this.filesi = [];

    for (let i = 0; i < this.files.length; i++) {
      if ((this.files[i].original_path.indexOf(this.fullpath) !== -1)) {
        if (((this.files[i].original_path).split("/", 100)).length - 3 == (this.fullpath.split("/", 100).length)) {
          this.filesi.push(this.files[i])
        }
      }
    }
    return this.triallinesResourceService.retrivesubFolder({
      'name': this.fullpath,
      'trlid': this.trialline.id
    }).toPromise()
      .then((r: any) => {
        this.newFolder = r.directory;
      });
  }

  // DEPRECATED
  archiveTrialline() {

    return;
    // this.modal.confirm()
    //   .size('lg')
    //   .isBlocking(true)
    //   .showClose(true)
    //   .keyboard(27)
    //   .title('Please confirm')
    //   .body('TrialLine will be archived. While it\'s not active, no one will have an access to view it. The month fee is 0.99$. Do you wish to proceed?')
    //   .open()
    //   .catch((err: any) => console.log('ERROR: ' + err))
    //   .then((dialog: any) => { return dialog.result })
    //   .then((result: any) => { this.confirmedArchiveTrialline() })
    //   .catch((err: any) => { /** Nothing to do */ });
  }

  createFolderMain(path) {
    if ((this.folderName != null) && (this.folderName != '') && (this.folderName != undefined)) {
      if (this.fullpath == undefined || this.fullpath == '') {
        this.folderName
      } else {
        //this.folderName=(this.fullpath).concat('/'+this.folderName);
        this.folderName = (this.fullpath).concat('/' + this.folderName);

      }
      return this.triallinesResourceService.createFolder({
        'name': this.folderName,
        'trlid': this.trialline.id
      }).toPromise()
        .then((r: any) => {
          jQuery("#myModal").modal("hide");
          this.toasts.success('Folder Successfully Created');
          this.folderName = '';
          return this.triallinesResourceService.retrivesubFolder({
            'name': this.fullpath,
            'trlid': this.trialline.id
          }).toPromise()
            .then((r: any) => {
              this.newFolder = r.directory;
            });
        });
    } else {
      document.getElementById("input-text").style.borderColor = "red";
    }

  }

  getAllFolder(data, trlid) {
    this.triallinesResourceService.retriveFolder({'trlid': trlid}).toPromise()
      .then((r: any) => {
        this.newFolder = r.directory;
      });

  }

  backtofolderMain(fname) {
    this.filesi = [];
    if (fname == 1) {
      this.getAllFolder('', this.trialline.id);

      this.fullpath = '';
      this.backfolder = [];
      this.newFolder = [];
      let i = 0;
      for (let i = 0; i < this.files.length; i++) {
        if (((this.files[i].original_path).split("/", 100)).length < 4) {
          this.filesi.push(this.files[i])
        }
      }
    } else {
      this.fullpath = this.fullpath.substring(0, this.fullpath.indexOf(fname));
      this.fullpath = (this.fullpath).concat(fname);
      this.backfolder = this.fullpath.split("/", 100);
      this.filesi = [];
      let i = 0;
      for (i = 0; i < this.files.length; i++) {
        if ((this.files[i].original_path.indexOf(this.fullpath) !== -1)) {
          if (((this.files[i].original_path).split("/", 100)).length - 3 == (this.fullpath.split("/", 100).length)) {
            this.filesi.push(this.files[i])
          }
        }
      }

      return this.triallinesResourceService.retrivesubFolder({
        'name': this.fullpath,
        'trlid': this.trialline.id
      }).toPromise()
        .then((r: any) => {
          this.newFolder = r.directory;
        });
    }
  }

  getFileName(name: string) {
    return name.replace(/\d{9,}_/, '');
  }

  //Temp-2
  OpenTrlFolder(folder, Fname) {

    this.foldernametoshow = Fname;
    this.newfoldervisible = false
    this.newsubfoldervisible = true
    this.renameid = [];
    this.folderrenameid = [];
    this.deleteid = [];
    this.folderdeleteid = [];
    if (this.fullpath == undefined || this.fullpath == '') {
      this.fullpath = Fname.trim();

    } else {
      this.fullpath = (this.fullpath).concat('/' + Fname.trim());
    }

    localStorage.setItem("folderpath", this.fullpath);
    this.backfolder = this.fullpath.split("/", 100);
    this.filesi = [];
    this.allid = []
    this.TempFolder = [];

    for (let i = 0; i < this.files.length; i++) {
      let te = this.fullpath;
      let patharray1 = (this.files[i].original_path.split('/'));
      let joinedArray1 = patharray1.slice(2, te.split("/", 100).length + 2).join("/");
      if (joinedArray1 == this.fullpath) {
        if ((this.files[i].original_path.indexOf(this.fullpath) !== -1)) {
          this.allid.push(this.files[i].id)
          let indexid = (this.fullpath.split("/", 100).length);
          if (((this.files[i].original_path).split("/", 100)).length - 3 == (this.fullpath.split("/", 100).length)) {
            this.filesi.push(this.files[i])
          } else {
            let splittext = (this.files[i].original_path).split("/", 100)[indexid + 2]
            if (this.TempFolder.includes(splittext) == false) {
              this.TempFolder.push((this.files[i].original_path).split("/", 100)[indexid + 2]);
            }
          }
        }
      }

    }

    for (let j = 0; j < this.emptyfolder.length; j++) {
      let te = this.fullpath;
      let patharray = (this.emptyfolder[j].folder_path.split('/'));
      let joinedArray = patharray.slice(0, te.split("/", 100).length).join("/");
      if (joinedArray == this.fullpath) {
        if (this.emptyfolder[j].folder_path.indexOf(this.fullpath) !== -1) {
          let emindexid = (this.fullpath.split("/", 100).length);
          let emsplittext = (this.emptyfolder[j].folder_path).split("/", 100)[emindexid]
          if ((this.TempFolder.includes(emsplittext) == false) && (emsplittext !== undefined)) {
            this.TempFolder.push((this.emptyfolder[j].folder_path).split("/", 100)[emindexid]);
          }
        }
      }

    }

    this.DropzoneComponentobj.autoload();
    if (this.checkboxstore.length > 0) {
      setInterval(() => {
        this.callFuntionAtIntervals();
      }, 1000);
    }
  }

  hide() {
    this.structure = false;
  }

  assigne() {
    this.structure = true;
    let l = 0;
    let indo;
    $('#divmid1').css("margin-left", "50px");
    for (l = 1; l < this.backfolder.length; l++) {
      if (l == 1) {
        indo = 50;
      } else {
        indo = 50 * l
      }
      jQuery("#divmid" + l + "").css("margin-left", indo + "px");
    }
  }

  backtofolder(fname) {

    this.filesi = [];
    this.renameid = [];
    this.folderrenameid = [];
    this.deleteid = [];
    this.folderdeleteid = [];
    this.allid = [];
    if (fname == 1) {
      this.foldernametoshow = 'Home';
      this.newfoldervisible = true;
      this.newsubfoldervisible = false;
      this.filesi = [];
      this.fullpath = '';
      this.backfolder = [];
      this.newFolder = [];
      this.TempFolder = [];

      for (let i = 0; i < this.files.length; i++) {
        //this.files[i].created_at=false;
        this.allid.push(this.files[i].id)
        if (((this.files[i].original_path).split("/", 100)).length < 4) {
          this.filesi.push(this.files[i])
        } else {
          let splittext = (this.files[i].original_path).split("/", 100)[2]
          if (this.TempFolder.includes(splittext) == false) {
            this.TempFolder.push((this.files[i].original_path).split("/", 100)[2]);
          }
        }
      }

      //Empty Folder
      for (let j = 0; j < this.emptyfolder.length; j++) {
        let emsplittext = (this.emptyfolder[j].folder_path).split("/", 100)[0]
        if (this.TempFolder.includes(emsplittext.trim()) == false) {
          let id;
          this.TempFolder.push((this.emptyfolder[j].folder_path).split("/", 100)[0]);

          //console.log(this.checkboxstore);
          if ((this.fullpath == '') || (this.fullpath == null)) {
            id = this.fullpath.split("/", 100).length - 1
          } else {
            id = this.fullpath.split("/", 100).length
          }
          for (let i = 0; i < this.checkboxstore.length; i++) {
            if (this.checkboxstore[i].index == id) {
              //$(".ibtn").attr("checked", "checked");
              $(".ibtn").attr("checked", "checked");
            }
          }
        }
      }
    } else {
      this.foldernametoshow = fname;
      this.fullpath = this.fullpath.substring(0, this.fullpath.indexOf(fname));
      this.fullpath = (this.fullpath).concat(fname);
      this.backfolder = this.fullpath.split("/", 100);
      this.filesi = [];
      this.TempFolder = [];

      for (let i = 0; i < this.files.length; i++) {
        let te = this.fullpath;
        var patharray1 = (this.files[i].original_path.split('/'));
        var joinedArray1 = patharray1.slice(2, te.split("/", 100).length + 2).join("/");
        if (joinedArray1 == this.fullpath) {
          let indexid = (this.fullpath.split("/", 100).length);
          if ((this.files[i].original_path.indexOf(this.fullpath) !== -1)) {
            this.allid.push(this.files[i].id)
            if (((this.files[i].original_path).split("/", 100)).length - 3 == (this.fullpath.split("/", 100).length)) {
              this.filesi.push(this.files[i])

            } else {

              var splittext = (this.files[i].original_path).split("/", 100)[indexid + 2]
              if (this.TempFolder.includes(splittext) == false) {
                this.TempFolder.push((this.files[i].original_path).split("/", 100)[indexid + 2]);
              }
            }
          }
        }
      }
      for (let j = 0; j < this.emptyfolder.length; j++) {
        var te = this.fullpath;
        var patharray = (this.emptyfolder[j].folder_path.split('/'));
        var joinedArray = patharray.slice(0, te.split("/", 100).length).join("/");
        if (joinedArray == this.fullpath) {
          if (this.emptyfolder[j].folder_path.indexOf(this.fullpath) !== -1) {
            var indexid = (this.fullpath.split("/", 100).length);
            var emsplittext = (this.emptyfolder[j].folder_path).split("/", 100)[indexid]
            if ((this.TempFolder.includes(emsplittext) == false) && (emsplittext !== undefined)) {
              this.TempFolder.push((this.emptyfolder[j].folder_path).split("/", 100)[indexid]);
            }

          }
        }
      }
    }
    localStorage.setItem("folderpath", this.fullpath);
    this.DropzoneComponentobj.autoload();
    if (this.checkboxstore.length > 0) {
      setInterval(() => {
        this.callFuntionAtIntervals();
      }, 1000);
    }
  }

  createFolder() {
    if ((this.folderName != null) && (this.folderName != '') && (this.folderName != undefined)) {
      if (this.fullpath == undefined || this.fullpath == '') {
        this.dummypath = this.folderName.trim();
      } else {
        this.dummypath = (this.fullpath.trim()).concat('/' + this.folderName.trim());

      }
      console.log(this.dummypath);

      if (this.TempFolder.includes(this.folderName) == false) {
        this.TempFolder.push(this.folderName);
        jQuery("#submyModal").modal("hide");
        this.toasts.success('Sub Folder Successfully Created');
        this.folderName = '';

        this.fileLibraryResourceService.CreateEmptyFolder({
          'name': this.dummypath,
          'trlid': this.trialline.id
        }).toPromise().then((response: any) => {
          if (response.Status == "Successfully Created") {
            this.fileLibraryResourceService.getFolders({'trlid': this.trialline.id}).toPromise().then((response: any) => {
              this.emptyfolder = response.folderData;
            });
          }
        })
      } else {
        this.toasts.error('Sub Folder Already Created');
      }
    } else {
      document.getElementById("input-text").style.borderColor = "red";
    }
  }

  /* Create New Folder on main Directory */
  createFolderonmaindirectory() {
    this.folderNamemain = this.cfolderNamemain;
    if ((this.folderNamemain != null) && (this.folderNamemain != '') && (this.folderNamemain != undefined)) {
      if (this.mainfolderavailabelinsubdirectory.includes(this.folderNamemain) == false) {
        if ((this.fullpath == null) || (this.fullpath == '')) {
          this.TempFolder.push(this.folderNamemain);
          this.mainfolderavailabelinsubdirectory = this.TempFolder;
        }

        jQuery("#myModal").modal("hide");
        this.toasts.success('Main Folder Successfully Created');

        //empty  folder code
        this.fileLibraryResourceService.CreateEmptyFolder({
          'name': this.folderNamemain.trim(),
          'trlid': this.trialline.id
        }).toPromise().then((response: any) => {
          this.folderNamemain = '';
          if (response.Status == "Successfully Created") {
            this.fileLibraryResourceService.getFolders({'trlid': this.trialline.id}).toPromise().then((response: any) => {
              this.emptyfolder = response.folderData;
            });
          }
        })
      } else {
        this.toasts.error(' Folder Already Created');
      }
    } else {
      document.getElementById("input-text").style.borderColor = "red";
    }
  }

  /* End */
  remove(file: FileModel) {
    //console.log('remove file:', file); return false;
    if (file.pivot) {
      if (file.pivot.event_id) {
        const dialogRef = this.modal.confirm()
          .size('lg')
          .isBlocking(true)
          .showClose(true)
          .keyboard(27)
          .dialogClass('modal-dialog overflowed')
          .okBtn('Delete It')
          .title('Confirmation')
          .body('Are you sure you want to remove this document from this event?')
          .open()
        dialogRef.result
          .catch((err: any) => {
            this.modelClose()
            console.log('ERROR: ' + err)
          })
          .then((dialog: any) => {
            this.modelClose()
            if (dialog) {
              this.confirmedRemove(file)
            }
          });
      } else if (file.pivot.trialline_id) {
        const dialogRef = this.modal.confirm()
          .size('lg')
          .isBlocking(true)
          .showClose(true)
          .keyboard(27)
          .dialogClass('modal-dialog overflowed')
          .title('Confirmation')
          .okBtn('Delete Document')
          .body('Are you sure you want to remove this document from this trialline?')
          .open()
        dialogRef.result
          .catch((err: any) => {
            this.modelClose()
            console.log('ERROR: ' + err)
          })
          .then((dialog: any) => {
            this.modelClose()
            if (dialog) {
              this.confirmedRemove(file)
            }
          })
      } else {
        const dialogRef = this.modal.confirm()
          .size('lg')
          .isBlocking(true)
          .showClose(true)
          .keyboard(27)
          .dialogClass('modal-dialog overflowed')
          .title('Confirmation')
          .okBtn('Delete Document')
          .body('Are you sure you want to delete this document from your file library? If you do, it will also be' +
            ' deleted from any events that reference this document.')
          .open()
        dialogRef.result
          .catch((err: any) => {
            this.modelClose()
            console.log('ERROR: ' + err)
          })
          .then((dialog: any) => {
            this.modelClose()
            if (dialog) {
              this.confirmedRemove(file)
            }
          })
      }
    } else {
      const dialogRef = this.modal.confirm()
        .size('lg')
        .isBlocking(true)
        .showClose(true)
        .keyboard(27)
        .dialogClass('modal-dialog overflowed')
        .title('Confirmation')
        .okBtn('Delete Document')
        .body('Are you sure you want to remove this document?')
        .open()
      dialogRef.result
        .catch((err: any) => {
          this.modelClose()
         console.log('ERROR: ' + err)
    })
        .then((dialog: any) => {
          return dialog.result
        })
        .then((result: any) => {
          this.modelClose()
          this.confirmedRemove1(file)
        })
        .catch((err: any) => { /** Nothing to do */
        });
    }
    this.modelAction()
  }

  confirmedRemove(file) {
    this.fileLibraryResourceService.RemoveFile({
      'fileId': file.id,
      'trlid': this.trialline.id,
      'event_id': file.pivot.event_id
    }).toPromise().then((response: any) => {
      this.toasts.success('Successfully Deleted');
      if (response.Status == "Successfully Deleted") {
        this.filesi = this.filesi.filter(order => order.id != file.id);
        this.files = this.files.filter(order => order.id != file.id);
      }
    });
  }

  confirmedRemove1(file) {
    this.fileLibraryResourceService.RemoveFile({
      'fileId': file.id,
      'trlid': this.trialline.id,
      'event_id': null
    }).toPromise().then((response: any) => {
      this.filesi = this.filesi.filter(order => order.id != file.id);
      this.files = this.files.filter(order => order.id != file.id);
      this.renamecombofunction(0);
    });
  }

//Edit File Codeing
  editFile(file: FileModel) {
    this.file = file;
    this.pdfpath = this._sanitizer.bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/' + this.file.encrypt_id + '?embedded=true');
    jQuery('#library-file-edit-modal').modal('show');
  }

  save() {
    this.data = [{'title': this.file.title, 'description': this.file.description}]
    this.fileLibraryResourceService.updateFile(this.file, this.data).toPromise()
      .then((file: FileModel) => {

        this.toasts.success('Successfully update');
        jQuery('#library-file-edit-modal').modal('hide');
        this.onUpdated.emit(file);

      })
      .catch((error) => {

        this.toasts.error(error);
      });
  }

  connectContact(file) {
    this.file = file;
    this.fileContact = this.file;
    jQuery(this.editFileContactModalId).modal('show');
  }

  addContactsToFile(dataFile) {
    this.updated(dataFile);
  }

  closeEditModal() {
    jQuery('#library-file-edit-modal').modal('hide');
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    this.file = changes['file'].currentValue;
    if (this.file) {
      this.uploader = new FileUploader({
        url: env.apiUrl + 'file/' + this.file.id + '/update',
        authToken: 'Bearer ' + this.authService.token(),
        autoUpload: true
      });

      this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {

        let body = JSON.parse(response).body;
        this.file = body.file;
        this.onUpdated.emit(this.file);
        this._changeDetectorRef.detectChanges();
      };
    }
  }

  //Add TO company library
  toggleCloud(file: FileModel) {
    //console.log(file);
    if (!file.in_library) {
      const dialogRef = this.modal.confirm()
        .size('lg')
        .isBlocking(true)
        .showClose(true)
        .keyboard(27)
        .dialogClass('modal-dialog overflowed')
        .title('Confirmation')
        .body('File will be added to your company file library for future use.')
        .open()
      jQuery(".modal.fade.show").addClass("in");
      dialogRef.result
        .catch((err: any) => {
          this.modelClose()
          console.log('ERROR: ' + err)
        })
        .then((dialog: any) => {
          if (dialog) {
            this.modelClose()
            this.confirmedToggleCloud(file, 1)
          }
        })
    } else {
      const dialogRef = this.modal.confirm()
        .size('lg')
        .isBlocking(true)
        .showClose(true)
        .keyboard(27)
        .dialogClass('modal-dialog overflowed')
        .title('Confirmation')
        .body('This file is already part of your Company File Library. Click "OK" to remove this file from your' +
          ' Company File Library (file will remain in your Case File Library).')
        .open()
      jQuery(".modal.fade.show").addClass("in");
      dialogRef.result
      .catch((err: any) => {
        this.modelClose()
        console.log('ERROR: ' + err)
      })
      .then((dialog: any) => {
        if (dialog) {
          this.modelClose()
          this.confirmedToggleCloud(file, 0)
        }
      })
    }
  }

  confirmedToggleCloud(file: FileModel, check) {
    this.fileLibraryResourceService.AddcompanyFile({
      'status': check,
      'fileId': file.id
    }).toPromise().then((response: any) => {
      for (let i = 0; i < this.filesi.length; i++) {
        if (this.filesi[i].id == file.id) {
          this.filesi[i].in_library = check != 0;
        }
      }
    });
  }

  /* View File  */
  selectFile(file: FileModel) {
    this.file = file;
    this.fileLibraryResourceService.logFile(this.file).toPromise()
      .then((response: any) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
    this.pdfpath = this._sanitizer.bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/' + this.file.encrypt_id);
    jQuery(this.previewodalId).modal('show');
    this.loadBox();
    this.loadContainer();
  }


  getSafeResourceUrl(fileArray) {    
      
      // if (this.imageGot == true) {
      //   return this.popupImage;
      // }
      if(this.file.type=='image') {
        //this.imageGot = true;
        this.popupImage =  this._sanitizer.bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/' + fileArray.encrypt_id);
      } else {
        //this.imageGot = true;
        this.popupImage =  this._sanitizer.bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/' + fileArray.encrypt_id);
      }
      return this.popupImage;
  }

  // getSafeResourceUrlPdf(fileArray) {
  //   console.log('function for pdf url')
    
  //   if(this.pdfURL) {
  //     this.pdfURL= this._sanitizer.bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/' + fileArray.encrypt_id);
  //     return this.pdfURL;
  //   } else {
  //     this.pdfURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/' + fileArray.encrypt_id);
  //     return this.pdfURL;
  //   }

    
    
    //return this.apiPath + 'filesystem/' + fileArray.encrypt_id
    // 'https://drive.google.com/viewerng/viewer?embedded=true&url=' +

  // }

  // iframeLoaded() {
  //   console.log('iframe load event');
    
  //   jQuery("#pdfLoaderSpinner").css('display', 'none'); 
  // }

  // zoomPreviewToggle() {
  //   if (screenfull && typeof screenfull.on !== "undefined") {
  //     screenfull.toggle();
  //   } else if(screenfull === false && navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null && !this.fullIpad) {
  //     this.fullIpad = true;
  //     this.fullscreen = true;
  //     setTimeout( ()=>{
  //       jQuery('#preview-modal').find('.zoom_active').css({'position': 'fixed',
  //         'display': 'inline-grid', 'left': '-35px', 'top': '-100px', 'width': '110%', 'max-height': '160%',
  //         'min-height': '102%'});
  //       jQuery('#preview-modal').find('.zoom_deactivate').css({'position': 'fixed',
  //         'display': 'inline-grid', 'left': '-35px', 'top': '-100px', 'width': '110%', 'max-height': '160%',
  //         'min-height': '102%'});
  //       let height = jQuery(window).height();
  //       if (height < 750) {
  //         height = height - 50;
  //       } else if (height >= 750 && height < 900) {
  //         height = height - 200;
  //       } else {
  //         height = height - 170;
  //       }
  //       jQuery('#preview-modal').find('.zoom_active').find('.modal-content').css({ 'height': height + 'px'});
  //     }, 200)
  //   } else if(screenfull === false && navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null && this.fullIpad) {
  //     this.fullIpad = false;
  //     this.fullscreen = false;
  //     jQuery('#preview-modal').find('.modal-preview').removeAttr( "style" );
  //     jQuery('#preview-modal').find('.zoom_deactivate').attr("style","");
  //     jQuery('#preview-modal').find('.modal-preview').find('.modal-content').removeAttr( "style" );
  //   }
  // }

  closePreviewer() {   
    jQuery('#preview-modal').modal('hide');
    this.width = 630;
    this.height = 560;
    this.left = 0;
    this.top = 0;
    this.file = undefined;

    if (this.file.type == 'video') {
      let file = this.file;
      this.file = new FileModel();
      this._changeDetectionRef.detectChanges();
      this.file = file;
    }
  
    // } else {
    //   document.getElementsByTagName("audio")[0].pause();
    // }
  }
  closePreviewer1() {   
  
    jQuery('#preview-modal-1').modal('hide');

    if (this.file.type == 'video') {
      let file = this.file;
      this.file = new FileModel();
      this._changeDetectionRef.detectChanges();
      this.file = file;
    } 
    
  }
  // move all files to folder
  moveAllFiletofolder(event){
    for(let i=0;i<this.filesi.length;i++) {
      if(event.target.checked) {
        this.filesi[i].created_at = true;
        this.moveid.push(this.filesi[i].id);
       }else {
        this.filesi[i].created_at=false;
        this.moveid.splice(this.moveid.indexOf(this.filesi[i].id), 1);
       }
       this.countfile =this.moveid.length;
   }
  }
  /* Move One folder To another   */
  moveFiletofolder(file) {
    this.movestatus = true;
    this.countfile = this.moveid.length;

    for (let i = 0; i < this.filesi.length; i++) {
      if (this.filesi[i].id == file.id) {
        if (this.filesi[i].created_at !== true) {
          this.filesi[i].created_at = true;
          this.moveid.push(this.filesi[i].id);
        } else {
          this.filesi[i].created_at = false;
          this.moveid.splice(this.moveid.indexOf(this.filesi[i].id), 1);
        }
      }
      this.countfile = this.moveid.length;
    }
  }

  clearallchek() {
    this.filesi = [];
    this.fullpath = '';
    this.backfolder = [];
    this.newFolder = [];
    this.TempFolder = [];
    this.moveid = [];
    this.checkboxstore = [];
    this.foldercount = 0;
    $('input:checkbox').prop('checked', false);
    this.fileoffolderidanother = [];
    this.fileoffolderid = [];
    this.folderoffolderid = [];
    this.folderoffolderidanother = [];
    this.countfile = this.moveid.length;
    this.newsubfoldervisible = false
    this.newfoldervisible = true
    let i = 0;
    for (i = 0; i < this.files.length; i++) {
      this.files[i].created_at = false;
      if (((this.files[i].original_path).split("/", 100)).length < 4) {
        this.filesi.push(this.files[i])
      } else {
        var splittext = (this.files[i].original_path).split("/", 100)[2]
        if (this.TempFolder.includes(splittext) == false) {
          this.TempFolder.push((this.files[i].original_path).split("/", 100)[2]);
        }
      }
    }
    //Empty Folder
    for (let j = 0; j < this.emptyfolder.length; j++) {
      //Temp
      var emsplittext = (this.emptyfolder[j].folder_path).split("/", 100)[0]
      if (this.TempFolder.includes(emsplittext) == false) {
        this.TempFolder.push((this.emptyfolder[j].folder_path).split("/", 100)[0]);
      }
    }
  }

  movehere() {
    this.movestatus = true;
    let i = 0;
    let j = 0;
    for (i = 0; i < this.files.length; i++) {
      this.files[i].created_at = false;
      for (j = 0; j < this.moveid.length; j++) {
        if (this.moveid[j] == this.files[i].id) {
          let newpath
          let splittext = (this.files[i].original_path).split("/", 100)[0]
          let splittext1 = (this.files[i].original_path).split("/", 100)[1]
          if (this.fullpath == '') {
            newpath = splittext + '/' + splittext1 + '/' + this.files[i].title;
          } else {
            newpath = splittext + '/' + splittext1 + '/' + this.fullpath + '/' + this.files[i].title;
          }
          this.files[i].original_path = newpath;
          this.filesi = [];
          this.TempFolder = [];
          let k = 0;
          for (k = 0; k < this.files.length; k++) {
            let indexid = (this.fullpath.split("/", 100).length);
            if ((this.files[k].original_path.indexOf(this.fullpath) !== -1)) {
              if (((this.files[k].original_path).split("/", 100)).length - 3 == (this.fullpath.split("/", 100).length)) {
                this.filesi.push(this.files[k])
              } else {
                splittext = (this.files[k].original_path).split("/", 100)[indexid + 2]
                if (this.TempFolder.includes(splittext) == false) {
                  this.TempFolder.push((this.files[k].original_path).split("/", 100)[indexid + 2]);
                }
              }
            }
          }
          for (let l = 0; l < this.emptyfolder.length; l++) {
            if (this.emptyfolder[l].folder_path.indexOf(this.fullpath) !== -1) {
              var indexid = (this.fullpath.split("/", 100).length);
              var emsplittext = (this.emptyfolder[l].folder_path).split("/", 100)[indexid]
              if ((this.TempFolder.includes(emsplittext) == false) && (emsplittext !== undefined)) {
                this.TempFolder.push((this.emptyfolder[l].folder_path).split("/", 100)[indexid]);
              }
            }
          }
        }
      }
    }
    if (this.moveid.length > 0) {
      this.fileLibraryResourceService.renameFoldersapi({
        'renameid': this.moveid,
        'renamefile': this.files
      }).toPromise().then((response: any) => {
        jQuery("#moveModal").modal("hide");
        jQuery("#movestomodal").modal("hide");

        this.clearallchek();
        this.moveid = [];
      })
    }
    this.movefoldertofolderhere();
  }

  movesto() {
    this.movestatus = false;
    this.filesi = [];
    this.fullpath = '';
    this.backfolder = [];
    this.newFolder = [];
    this.TempFolder = [];
    let i = 0;
    for (i = 0; i < this.files.length; i++) {
      if (((this.files[i].original_path).split("/", 100)).length < 4) {
        this.filesi.push(this.files[i])
      } else {
        var splittext = (this.files[i].original_path).split("/", 100)[2]
        if (this.TempFolder.includes(splittext) == false) {
          this.TempFolder.push((this.files[i].original_path).split("/", 100)[2]);
        }
      }
    }
    //Empty Folder
    var j = 0
    for (j = 0; j < this.emptyfolder.length; j++) {
      //Temp
      var emsplittext = (this.emptyfolder[j].folder_path).split("/", 100)[0]
      if (this.TempFolder.includes(emsplittext) == false) {
        this.TempFolder.push((this.emptyfolder[j].folder_path).split("/", 100)[0]);
      }
    }
    var j = 0;
    this.arr_name = [];
    for (i = 0; i < this.emptyfolder.length; i++) {
      if (this.folderoffolderid.indexOf(this.emptyfolder[i].id) > -1) {
      } else {
        var splitt = (this.emptyfolder[i].folder_path).split("/", 100)
        this.arr_name[i] = []
        for (j = 0; j < splitt.length; j++) {
          this.arr_name[i][j] = splitt[j];
        }
      }
    }
    this.arr_name = this.arr_name.filter(Boolean)
  }

  /* Move folder to folder  */
  checkValue(event: any, id) {
    if (this.fullpath == undefined || this.fullpath == '') {
      this.checkpath = id;
      this.checkindexid = 0;
    } else {
      this.checkpath = (this.fullpath).concat('/' + id);
      this.checkindexid = (this.fullpath).split("/", 100).length;
    }
    let i = 0;
    var j = 0
    let k = 0;
    if (event.currentTarget.checked == true) {
      for (let i = 0; i < this.files.length; i++) {
        var te = this.checkpath;
        var patharray = (this.files[i].original_path.split('/'));
        var joinedArray = patharray.slice(2, te.split("/", 100).length + 2).join("/");
        if (joinedArray == this.checkpath) {
          if ((this.files[i].original_path.indexOf(this.fullpath) !== -1)) {
            if ((this.files[i].original_path.indexOf(this.checkpath) !== -1)) {
              this.fileoffolderid.push(this.files[i].id)
              this.fileoffolderidanother.push({"id": this.files[i].id, 'foldername': id})
            }
          }
        }
      }
      for (let j = 0; j < this.emptyfolder.length; j++) {
        var te = this.checkpath;
        var patharray1 = (this.emptyfolder[j].folder_path.split('/'));
        var joinedArray1 = patharray1.slice(0, te.split("/", 100).length).join("/");

        if (joinedArray1 == this.checkpath) {
          if (this.emptyfolder[j].folder_path.indexOf(this.checkpath) !== -1) {
            this.folderoffolderid.push(this.emptyfolder[j].id)
            this.folderoffolderidanother.push({'id': this.emptyfolder[j].id, 'foldername': id})
          }
        }
      }
      if (this.fullpath == undefined || this.fullpath == '') {
        this.checkboxstore.push({'index': 0, "fodername": this.htmlgeneration(id)})
      } else {
        this.checkboxstore.push({'index': (this.fullpath).split("/", 100).length, "fodername": this.htmlgeneration(id)})
      }
    } else {
      for (let i = 0; i < this.files.length; i++) {
        let te = this.checkpath;
        var patharray = (this.files[i].original_path.split('/'));
        var joinedArray = patharray.slice(2, te.split("/", 100).length + 2).join("/");
        if (joinedArray == this.checkpath) {
          if ((this.files[i].original_path.indexOf(this.fullpath) !== -1)) {
            if ((this.files[i].original_path.indexOf(this.checkpath) !== -1)) {
              this.fileoffolderid.splice(this.fileoffolderid.indexOf(this.files[i].id), 1);
              this.fileoffolderidanother.splice(this.fileoffolderidanother.indexOf(this.files[i].id), 1);
            }
          }
        }
      }
      for (let j = 0; j < this.emptyfolder.length; j++) {
        var te = this.checkpath;
        var patharray1 = (this.emptyfolder[j].folder_path.split('/'));
        var joinedArray1 = patharray1.slice(0, te.split("/", 100).length).join("/");
        if (joinedArray1 == this.checkpath) {
          if (this.emptyfolder[j].folder_path.indexOf(this.checkpath) !== -1) {
            this.folderoffolderid.splice(this.folderoffolderid.indexOf(this.emptyfolder[j].id), 1);
            this.folderoffolderidanother.splice(this.folderoffolderidanother.indexOf(this.emptyfolder[j].id), 1);
          }
        }
      }
      for (k = 0; k < this.checkboxstore.length; k++) {
        if ((this.checkindexid == this.checkboxstore[k].index) && (this.htmlgeneration(id) == this.checkboxstore[k].fodername)) {
          this.checkboxstore.splice(this.checkboxstore.indexOf(this.checkboxstore[k].fodername), 1);
        } else {
        }
      }
    }
    this.foldercount = this.checkboxstore.length;

  }

  movefoldertofolderhere() {
    this.movestatus = true;
    let i = 0;
    let j = 0;
    let x = 0;
    let y = 0;
    let z = 0;
    if (this.fileoffolderid.length > 0) {
      for (i = 0; i < this.files.length; i++) {
        for (j = 0; j < this.fileoffolderidanother.length; j++) {
          if (this.fileoffolderidanother[j].id == this.files[i].id) {
            var splittext = (this.files[i].original_path).split("/", 100)[0]
            var splittext1 = (this.files[i].original_path).split("/", 100)[1]
            var result = this.files[i].original_path.substr(this.files[i].original_path.indexOf("/") + 1);
            var result1 = result.substr(result.indexOf("/") + 1);

            if (this.fullpath == '') {
              let power = ((this.files[i].original_path).split("/", 100));
              var index1 = power.indexOf(this.fileoffolderidanother[j].foldername);
              var newpath = splittext + '/' + splittext1 + '/' + power.slice(index1, power.length).join("/");

            } else {
              let power = ((this.files[i].original_path).split("/", 100));
              var index1 = power.indexOf(this.fileoffolderidanother[j].foldername);

              var newpath = splittext + '/' + splittext1 + '/' + this.fullpath + '/' + power.slice(index1, power.length).join("/");
            }

            this.files[i].original_path = newpath;
            this.filesi = [];
            this.TempFolder = [];
            let k = 0;
            for (k = 0; k < this.files.length; k++) {
              var indexid = (this.fullpath.split("/", 100).length);
              if ((this.files[k].original_path.indexOf(this.fullpath) !== -1)) {
                if (((this.files[k].original_path).split("/", 100)).length - 3 == (this.fullpath.split("/", 100).length)) {
                  this.filesi.push(this.files[k])

                } else {

                  var splittext = (this.files[k].original_path).split("/", 100)[indexid + 2]
                  if (this.TempFolder.includes(splittext) == false) {
                    this.TempFolder.push((this.files[k].original_path).split("/", 100)[indexid + 2]);
                  }
                }
              }
            }
            var l = 0
            for (l = 0; l < this.emptyfolder.length; l++) {
              if (this.emptyfolder[l].folder_path.indexOf(this.fullpath) !== -1) {
                var indexid = (this.fullpath.split("/", 100).length);
                var emsplittext = (this.emptyfolder[l].folder_path).split("/", 100)[indexid]
                if ((this.TempFolder.includes(emsplittext) == false) && (emsplittext !== undefined)) {
                  this.TempFolder.push((this.emptyfolder[l].folder_path).split("/", 100)[indexid]);
                }
              }
            }
          }
        }
      }
    }
    if (this.folderoffolderid.length > 0) {
      for (x = 0; x < this.emptyfolder.length; x++) {
        for (y = 0; y < this.folderoffolderidanother.length; y++) {
          if (this.folderoffolderidanother[y].id == this.emptyfolder[x].id) {
            var newpath1 = '';
            if (this.fullpath == '') {
              let power = ((this.emptyfolder[x].folder_path).split("/", 100));
              var index = power.indexOf(this.folderoffolderidanother[y].foldername);
              newpath1 = (power.slice(index, power.length).join("/"));
            } else {
              let power = ((this.emptyfolder[x].folder_path).split("/", 100));
              var index = power.indexOf(this.folderoffolderidanother[y].foldername);
              newpath1 = this.fullpath + '/' + (power.slice(index, power.length).join("/"));
            }
            this.emptyfolder[x].folder_path = newpath1;
          }
        }
      }
    }

    if (this.fileoffolderid.length > 0) {
      this.fileLibraryResourceService.renameFoldersapi({
        'renameid': this.fileoffolderid,
        'renamefile': this.files
      }).toPromise().then((response: any) => {
        jQuery("#moveModal").modal("hide");
        this.clearallchek();
        this.fileoffolderid = []
        this.fileoffolderidanother = []
        this.checkboxstore = [];
      })
    }
    if (this.folderoffolderid.length > 0) {
      this.fileLibraryResourceService.renameFolderstable({
        'renameidsfolder': this.folderoffolderid,
        'renamepath': this.emptyfolder
      }).toPromise().then((response: any) => {
        jQuery("#moveModal").modal("hide");
        this.clearallchek();
        this.folderoffolderid = []
        this.folderoffolderidanother = []
        this.checkboxstore = []
      })
    }

  }

  /* Rename Folder  */
  renamefoldermodal(name) {
    jQuery("#renameModal").modal("show");
    this.renamefolderName = name;
    this.folderNameforrename = name;
  }

  renamefolder() {
    let name = this.folderNameforrename
    if ((this.renamefolderName != null) && (this.renamefolderName != '') && (this.renamefolderName != undefined)) {
      var chekcontain = ''
      if ((this.fullpath == null) || (this.fullpath == '')) {
        chekcontain = name;
      } else {
        chekcontain = this.fullpath.concat('/' + name);
      }

      let indexid = (chekcontain.split("/", 100).length);
      for (let i = 0; i < this.files.length; i++) {
        let te = chekcontain;
        let patharray1 = (this.files[i].original_path.split('/'));
        let joinedArray1 = patharray1.slice(2, te.split("/", 100).length + 2).join("/");
        if (joinedArray1 == chekcontain) {
          indexid = (chekcontain.split("/", 100).length);
          var splittext = (this.files[i].original_path).split("/", 100)[indexid + 1]
          var splittext1 = ((this.files[i].original_path).split("/", 100))
          if ((this.files[i].original_path.indexOf(chekcontain) !== -1)) {
            this.renameid.push(this.files[i].id)

            for (let j = 0; j < splittext1.length; j++) {
              if (j == indexid + 1) {
                splittext1[j] = this.renamefolderName.trim();
              }
            }
            this.files[i].original_path = splittext1.join('/');
          }
        }
      }
      if (this.renameid.length > 0) {
        this.fileLibraryResourceService.renameFoldersapi({
          'renameid': this.renameid,
          'renamefile': this.files
        }).toPromise().then((response: any) => {
          console.log(response);
        })

      }

      if ((this.emptyfolder !== undefined) || (this.emptyfolder !== '')) {
        for (let k = 0; k < this.emptyfolder.length; k++) {
          var te = chekcontain;
          var patharray = (this.emptyfolder[k].folder_path.split('/'));
          var joinedArray = patharray.slice(0, te.split("/", 100).length).join("/");
          if (joinedArray == chekcontain) {
            if ((this.emptyfolder[k].folder_path.indexOf(chekcontain) !== -1)) {
              this.folderrenameid.push(this.emptyfolder[k].id);
              let splittext2 = ((this.emptyfolder[k].folder_path).split("/", 100));
              let l = 0;
              for (l = 0; l < splittext2.length; l++) {
                if (l == indexid - 1) {
                  splittext2[l] = this.renamefolderName.trim();
                }
                this.emptyfolder[k].folder_path = (splittext2).join('/');
              }
            }
          }
        }
      }
      if (this.folderrenameid.length > 0) {
        this.fileLibraryResourceService.renameFolderstable({
          'renameidsfolder': this.folderrenameid,
          'renamepath': this.emptyfolder
        }).toPromise().then((response: any) => {
          //this.folderrenameid=[];
        })
      }
    } else {
      document.getElementById("rename-text").style.borderColor = "red";
    }
    this.renamecombofunction(1);

  }

  renamecombofunction(id) {
    if (id == 1) {
      this.toasts.success('Successfully Rename Folder');
    } else {
      this.toasts.success('Successfully Deleted Folder');
    }
    if (this.fullpath == '') {
      //this.getAllFolder('',this.trialline.id);
      this.filesi = [];
      this.fullpath = '';
      this.backfolder = [];
      this.newFolder = [];
      this.TempFolder = [];
      let i = 0;
      let splittext;

      for (i = 0; i < this.files.length; i++) {
        if (((this.files[i].original_path).split("/", 100)).length < 4) {
          this.filesi.push(this.files[i])
        } else {
          splittext = (this.files[i].original_path).split("/", 100)[2]
          if (this.TempFolder.includes(splittext) == false) {
            this.TempFolder.push((this.files[i].original_path).split("/", 100)[2]);
          }

        }
      }
      //Empty Folder
      for (let j = 0; j < this.emptyfolder.length; j++) {
        let emsplittext = (this.emptyfolder[j].folder_path).split("/", 100)[0]
        if (this.TempFolder.includes(emsplittext) == false) {
          this.TempFolder.push((this.emptyfolder[j].folder_path).split("/", 100)[0]);
        }
      }
    } else {
      this.filesi = [];
      this.TempFolder = [];
      for (let i = 0; i < this.files.length; i++) {
        var te = this.fullpath;
        var patharray1 = (this.files[i].original_path.split('/'));
        var joinedArray1 = patharray1.slice(2, te.split("/", 100).length + 2).join("/");
        if (joinedArray1 == this.fullpath) {
          var indexid = (this.fullpath.split("/", 100).length);
          if ((this.files[i].original_path.indexOf(this.fullpath) !== -1)) {
            if (((this.files[i].original_path).split("/", 100)).length - 3 == (this.fullpath.split("/", 100).length)) {
              this.filesi.push(this.files[i])

            } else {

              var splittext = (this.files[i].original_path).split("/", 100)[indexid + 2]
              if (this.TempFolder.includes(splittext) == false) {
                this.TempFolder.push((this.files[i].original_path).split("/", 100)[indexid + 2]);
              }
            }
          }
        }
      }
      var j = 0
      for (j = 0; j < this.emptyfolder.length; j++) {
        var te = this.fullpath;
        var patharray = (this.emptyfolder[j].folder_path.split('/'));
        var joinedArray = patharray.slice(0, te.split("/", 100).length).join("/");
        if (joinedArray == this.fullpath) {
          if (this.emptyfolder[j].folder_path.indexOf(this.fullpath) !== -1) {
            var indexid = (this.fullpath.split("/", 100).length);
            var emsplittext = (this.emptyfolder[j].folder_path).split("/", 100)[indexid]
            if ((this.TempFolder.includes(emsplittext) == false) && (emsplittext !== undefined)) {
              this.TempFolder.push((this.emptyfolder[j].folder_path).split("/", 100)[indexid]);
            }
          }
        }
      }
    }
    localStorage.setItem("folderpath", this.fullpath);
    jQuery("#renameModal").modal("hide");

  }

  /* Delete Folder */
  deletefoldermodal(name) {
    //console.log(name);
    jQuery('#deletemodal').modal("show");
    this.deltefolderName = name;
  }

  confirmedDeletefolder() {
    var name = this.deltefolderName
    this.eventfiles = []
    let chekcontain
    if ((this.fullpath == null) || (this.fullpath == '')) {
      chekcontain = name;
    } else {
      chekcontain = this.fullpath.concat('/' + name);
    }
    let indexid = (chekcontain.split("/", 100).length);
    for (let i = 0; i < this.files.length; i++) {
      var te = chekcontain;
      var patharray1 = (this.files[i].original_path.split('/'));
      var joinedArray1 = patharray1.slice(2, te.split("/", 100).length + 2).join("/");
      if (joinedArray1 == chekcontain) {
        indexid = (chekcontain.split("/", 100).length);
        if ((this.files[i].original_path.indexOf(chekcontain) !== -1)) {
          this.deleteid.push(this.files[i].id)
          if (this.files[i].pivot !== undefined) {
            if (this.files[i].pivot.event_id != undefined) {
              this.eventfiles.push({file_id: this.files[i].id, event_id: this.files[i].pivot.event_id});
            } else {

            }
          }
          jQuery('#deletemodal').modal("hide");
        }
      }
    }
    if ((this.emptyfolder !== undefined) || (this.emptyfolder !== '')) {
      for (let k = 0; k < this.emptyfolder.length; k++) {
        var te = chekcontain;
        /* console.log(te)
             console.log(this.emptyfolder[k].folder_path.split('/').length); */
        var patharray = (this.emptyfolder[k].folder_path.split('/'));
        var joinedArray = patharray.slice(0, te.split("/", 100).length).join("/");
        /* console.log(joinedArray); */
        if (joinedArray == chekcontain) {
          if ((this.emptyfolder[k].folder_path.indexOf(chekcontain) !== -1)) {
            this.folderdeleteid.push(this.emptyfolder[k].id);
            jQuery('#deletemodal').modal("hide");
          }
        }
      }
    }
    this.deletecombofunction();
  }

  deletecombofunction() {
    if (this.deleteid.length > 0) {
      for (let order of this.files) {
        for (let j = 0; j < this.deleteid.length; j++) {
          this.files = this.files.filter(order => order.id != this.deleteid[j]);
        }
      }
      this.fileLibraryResourceService.deletefiles({
        'deletefileid': this.deleteid,
        'trlid': this.trialline.id,
        'eventfiles': this.eventfiles
      }).subscribe((response: any) => {
        console.log(response);
      })
    }
    let k = 0;
    if (this.folderdeleteid.length > 0) {
      for (let item of this.emptyfolder) {
        for (let i = 0; i < this.folderdeleteid.length; i++) {
          this.emptyfolder = this.emptyfolder.filter(item => item.id != this.folderdeleteid[i]);
        }
      }
      this.fileLibraryResourceService.deletefolders({'deletefolderid': this.folderdeleteid}).subscribe((response: any) => {
        console.log(response);
      })
    }
    this.renamecombofunction(0);
    this.folderdeleteid = [];
    this.deleteid = [];
  }

  /* Hide & Show For Save & Cancel Button */
  findtab(id) {
    this.savehideshow = id == 0;
  }

  levelup() {
    if (this.backfolder.length == 1) {
      this.backtofolder(1)
    } else {
      var test = [];
      let levelup = this.fullpath.split('/');
      test = levelup.splice(levelup.length - 2, 1)
      this.backtofolder(test);
    }

  }

  htmlgeneration(str) {
    if ((str != undefined) && (str != null)) {
      str = str.replace(/\s/g, "_");
      return str
    }
  }

  callFuntionAtIntervals() {
    let id;
    if ((this.fullpath == '') || (this.fullpath == null)) {
      id = this.fullpath.split("/", 100).length - 1
    } else {
      id = this.fullpath.split("/", 100).length
    }
    let i = 0;
    //console.log(id)
    for (i = 0; i < this.checkboxstore.length; i++) {
      if (this.checkboxstore[i].index === id) {
        //console.log(this.checkboxstore[i])
        //$("#"+this.htmlgeneration(this.checkboxstore[i].fodername)).attr("checked", "checked");
        $("#" + this.htmlgeneration(this.checkboxstore[i].fodername)).prop('checked', true);
      } else {
        $("#" + this.htmlgeneration(this.checkboxstore[i].fodername)).prop('checked', false);
      }
    }
  }

  movehides(j) {
    this.structure1 = true;
    this.structure2 = true;
    this.showid = 1000;
  }

  moveassigne(j) {
    this.structure1 = true;
    this.structure2 = false;
    this.showid = j
  }

  moveshere(mainitem) {

    if ((mainitem == '') || (mainitem == null)) {
      this.fullpath = '';
      jQuery("#movestomodal").modal("hide");
    } else {
      this.fullpath = (mainitem.join('/'));
      jQuery("#movestomodal").modal("hide");
    }

    this.movehere();

  }

  hidemovetomnodal() {
    jQuery('#movestomodal').modal('hide');
  }


  zipfiles() {
    this.zipProcessing=true;
    this.toasts.success('Your case documents are being prepared for download. If the total size of the documents in this library exceeds 150 MB,' +
      ' multiple ZIP files of your case documents will be created.');
    this.toasts.success('We\'re currently preparing your documents. You can keep using another task in the mean time');
    let self = this;
    var file = this.files;
    this.urls = [];
    this.anotherdata = [];
    var i = 0;
    this.fileLibraryResourceService.getTriallineFiles(this.trialline.id, {
      limit: 3000,
      page: 1
    })
      .subscribe((response) => {
        file = response['files'];
        for (let i = 0; i < file.length; i++) {
          this.allid.push(file[i].id);
        }
        let item = 1;
        let limitSize = 2500;
        let size = 0;
        for (i; i < file.length; i++) {
          if (this.allid.indexOf(file[i].id) > -1) {

            size = size + Number.parseFloat(file[i].size)
            let test;
            if (file[i].mime === "audio/x-m4a") {
              test = this._sanitizer.bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/' + file[i].encrypt_id)
            } else if (file[i].type === "video") {
              test = this._sanitizer.bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/' + file[i].encrypt_id + '/download')
            } else {
              test = this._sanitizer.bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/' + file[i].encrypt_id)
            }

            var myString = file[i].original_path;   
            myString = myString.substring(myString.indexOf('/') + 1)
            myString = myString.substring(myString.indexOf('/') + 1)
            this.anotherdata.push(myString)

            this.urls.push({
              'id': file[i].id,
              'url': file[i].dummy_path,
              'encriptedpath': test['changingThisBreaksApplicationSecurity'],
              'path': myString
            })
          }          
          if (size > limitSize) {
            this.createZip(this.urls, this.zipFilename, item)
            this.urls = []
            size = 0
            item++;
          }
        }

        console.log('Total zip size is: '+ size.toFixed(2)  + ' MB');
        jQuery(".totat_zip_size").html('Total zip size is: '+ size.toFixed(2)  + ' MB');
        this.createZip(this.urls, this.zipFilename, 'last')
      });
  }

  createZip(urls, name, item) {

    var zip = new JSZip();

    var url = urls;
    this.sss = [];
    for (let i = 0; i < url.length; i++) {
      let dataRes = this.urlToPromise(url[i].encriptedpath);
      if (dataRes != null) {
        let index = url[i].path.lastIndexOf(".");
        let filename = url[i].path.substring(0,index)
        let ext =  url[i].path.slice((url[i].path.lastIndexOf(".")));
        let finalFilename = filename+"-"+i+ext
        // @ts-ignore
        this.sss.push({'test': url[i].id, 'zip': zip.file(finalFilename, dataRes.then((value) => { 
          
          return value;
        }), {binary: true})});
      }
    }
    console.log('this.sss', this.sss);
    let self = this;
    var ss = this.sss[this.sss.length - 1];
    if (ss === undefined) {
      this.zipProcessing=false;
      this.toasts.error('Files are not available or do not exist');
      return false;
    }
    ss = ss.zip;
    ss.generateAsync({type: "blob", compression: "DEFLATE", compressionOptions: {level: 9}})
      .then((content) => {
        saveAs(content, name + '_' + item + '.zip');
        if (item === 'last') {          
          self.successdownload();          
        } else {          
          this.toasts.success('The ZIP file of your case documents has been downloaded successfully. We\'re currently preparing your documents for the next download, please wait.');
          this.zipProcessing = false;
        }
      }).catch((error) => {
      if (error.status == 422) {
        let errorData: any = error.error;
        this.zipProcessing = false;
        console.log(errorData);
      } else {
        this.toasts.error(error);
        this.zipProcessing = false;
      }
    });
  }

  successdownload() {
    this.toasts.success('Your case documents have been downloaded successfully.');
    this.toasts.success('Documents will be downloaded shortly. You can continue with your work.');
    this.zipProcessing = false;
  }

  urlToPromise(url) {
    return new Promise<void>(function (resolve, reject) {
      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  updated(file: FileModel) {
    let index = this.files.indexOf(this.files.find(f => f.id == file.id));
    this.files[index] = file;
    this.updateFileToContact = file;
    let index2 = this.filesi.indexOf(this.filesi.find(f => f.id == file.id));
    this.filesi[index2] = file;
    this._changeDetectionRef.detectChanges();
  }

  /* Filter or list View chnages  */
  icontolist(list) {
    this.listiconview = list != false;
    this.fileObj = [];
  }

  FilefilterType(data: any): void {
    this.filesi = [];
    for (let i = 0; i < this.files.length; i++) {
      if (this.files[i].type === data.target.value) {
        this.filesi.push(this.files[i]);
      }
    }
  }

  showdescription(i, desc) {
    if (desc != '') {
      $('#desc_' + i).show();
      $('#descdiv_' + i).hide();
      $('#descdivclose_' + i).show();
    }
  }

  closedescription(i, desc) {
    if (desc != null) {
      $('#desc_' + i).hide();
      $('#descdiv_' + i).show();
      $('#descdivclose_' + i).hide();
    }
  }

  hidedescription(i) {
    $('#desc_' + i).hide();
    $('#descdiv_' + i).show();
    $('#descdivclose_' + i).hide();
  }

  reorder(column: string) {
    this.currentStatus = column;
    if (column == this.order) {
      this.orderValue = this.orderValue === 'DESC' ? 'ASC' : 'DESC';
      this.reverse = !this.reverse;
      this.fileLibraryResourceService.getTriallineFiles(this.trialline.id, {
        page: this.page,
        order_value: column,
        order: this.orderValue,
        limit: this.perPage
      })
        .subscribe((response) => {
          this.files = this.filesi = response['files'];
          this.loading = false;
          this.forbidden = false;
          this.total = response['total'];
          this.page = response['page'];
        });
    } else {
      this.orderValue = 'ASC';
      this.order = column;
      this.reverse = true;
      this.fileLibraryResourceService.getTriallineFiles(this.trialline.id,
        {
          page: this.page,
          order_value: column,
          order: this.orderValue,
          limit: this.perPage
        })
        .subscribe((response) => {
          this.files = this.filesi = response['files'];
          this.loading = false;
          this.forbidden = false;
          this.total = response['total'];
          this.page = response['page'];
        });
    }
  }

  getSortIcon(column: string) {
    return this._sanitizer.bypassSecurityTrustHtml('<i class="fa fa-sort' +
      (column == this.order ? '-' + (this.reverse ? 'asc' : 'desc') : '') + '" aria-hidden="true"></i>');
  }

//pagination
  pageChanged(data: any) {
    this.fileLibraryResourceService.getTriallineFiles(this.trialline.id, {
      limit: this.perPage,
      page: data,
      order: this.orderValue
    })
      .subscribe((response) => {
        this.files = this.filesi = response['files'];
        this.loading = false;
        this.forbidden = false;
        this.total = response['total'];
        this.page = response['page'];
      });
    return this.page;
  }

  changePages(data: any) {
    this.perPage = data.target.value;
    this.fileLibraryResourceService.getTriallineFiles(this.trialline.id, {
      limit: this.perPage,
      page: this.page,
      order: this.orderValue
    }).subscribe((response) => {
        this.files = this.filesi = response['files'];
        this.loading = false;
        this.forbidden = false;
        this.total = response['total'];
        this.page = response['page'];
    });
  }

  modelAction() {
    $('bs-modal-container').addClass('show-midal-element');
  }

  modelClose() {
    $('body').removeClass('modal-open');
  }


  selectFileType(data: any): void {
    if (this.files !== undefined && data.target.value !== '') {
      this.filesi = [];
      for (let i = 0; i < this.files.length; i++) {
        if (this.filesi.indexOf(this.files[i]) === -1 && this.files[i].type === data.target.value) {
          this.filesi.push(this.files[i]);
        }
      }
    } else if (this.files.length !== this.filesi.length) {
      this.filesi = this.files;
    }
  }

  filterFileName(data: any): void {
    if (this.files !== undefined && data.target.value !== '') {
      let need = data.target.value.toLowerCase();
      this.filesi = this.allTLFiles.filter(file => new RegExp(need).test(file.title.toLowerCase()));
      this.perPage = 1000;
    } else if (this.files.length !== this.allTLFiles.length) {
      this.filesi = this.files;   
      this.perPage = 25;
    } else if(data.target.value == '') {
      this.perPage = 25;
    }
  }

  transform(files: FileModel[], filterType, filterName) {
    let out: FileModel[];

    out = filterType != '' ? files.filter(file => {
        return file.type == filterType
      }) : files;

    out = filterName != '' ? out.filter(file => new RegExp(filterName).test(file.title)) : out;

    return out;
  }

  /**
   * Function to sort alphabetically an array of objects by some specific key.
   *
   * @param {String} property Key of the object to sort.
   */
  dynamicSort(property) {
    let sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    }
  }

  private loadContainer(){
    const left = this.boxPosition.left - this.left;
    const top = this.boxPosition.top - this.top;
    const right = left + 630;
    const bottom = top + 560;
    this.containerPos = { left, top, right, bottom };
  }

  setStatus(event: MouseEvent, status: number){
    if(status === 1) event.stopPropagation();
    else if(status === 2) this.mouseClick = { x: event.clientX, y: event.clientY, left: this.left, top: this.top };
    else this.loadBox();
    this.status = status;
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent){
    this.mouse = { x: event.clientX, y: event.clientY };
    if(this.status === Status.RESIZE) {
      this.resize()
    } else if (this.status === Status.MOVE){
      this.move();
    }

  }

  private resize(){
    this.loadBox();
    this.width = Number(this.mouse.x > this.boxPosition.left) ? this.mouse.x - this.boxPosition.left : 0;
    this.height = Number(this.mouse.y > this.boxPosition.top) ? this.mouse.y - this.boxPosition.top : 0;
  }

  private move(){
    this.left = this.mouseClick.left + (this.mouse.x - this.mouseClick.x);
    this.top = this.mouseClick.top + (this.mouse.y - this.mouseClick.y);
  }

  private loadBox(){
    const {left, top} = this.previewModal2.nativeElement.getBoundingClientRect();
    this.boxPosition = {left, top};
  }
  
  multiFilesChecked(currentFileObj, currentIndex) {
    
    var boxes = jQuery('.multiFiles:checked');
    
    if(boxes.length == jQuery('.multiFiles').length) {
      jQuery('#selectAllChecks').prop('checked',true);
    } else {
      jQuery('#selectAllChecks').prop('checked',false);
    }


    // How many are checked
    if(boxes.length > 0) {
      this.isButtonsShow = true;
    } else {
      this.isButtonsShow = false;
    }
    
    let isChecked = jQuery('#multiselect_checkbox_'+currentIndex).is(':checked');
    
    if(isChecked) {
      this.fileObj.push(currentFileObj);
    } else{
      let index = this.fileObj.indexOf(currentFileObj);
      this.fileObj.splice(index, 1);
    }

    console.log('this.fileObj:',this.fileObj)

  }

  multiDownloadToComputer() {
    var boxes = jQuery('.multiFiles:checked');
    
    if(boxes.length > 0) {

      this.zipFilename = 'test';
      this.zipProcessing=true;
      this.toasts.success('Your case documents are being prepared for download. If the total size of the documents in this library exceeds 150 MB,' +
        ' multiple ZIP files of your case documents will be created.');
      this.toasts.success('We\'re currently preparing your documents. You can keep using another task in the mean time');
      
      this.urls = [];
      
      this.anotherdata = [];
      var i = 0;
      
      let item = 1;
      let limitSize = 150;
      let size = 0;

      this.fileObj.forEach(file => {
        
        size = size + Number.parseFloat(file.size)
        let test;
        if (file.mime === "audio/x-m4a") {
          test = this._sanitizer.bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/' + file.encrypt_id)
        } else if (file.type === "video") {
          test = this._sanitizer.bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/' + file.encrypt_id + '/download')
        } else {
          test = this._sanitizer.bypassSecurityTrustResourceUrl(this.apiPath + 'filesystem/' + file.encrypt_id)
        }

        var myString = file.original_path;   
        myString = myString.substring(myString.indexOf('/') + 1)
        myString = myString.substring(myString.indexOf('/') + 1)
        this.anotherdata.push(myString)

        this.urls.push({
          'id': file.id,
          'url': file.dummy_path,
          'encriptedpath': test['changingThisBreaksApplicationSecurity'],
          'path': myString
        })
        
        if (size > limitSize) {
          this.createZip(this.urls, this.zipFilename, item)
          this.urls = []
          size = 0
          item++;
        }
        
      });

      jQuery(".totat_zip_size").html('Total zip size is: '+ size.toFixed(2)  + ' MB');
      this.createZip(this.urls, this.zipFilename, 'last')

    }
 
  }

  multiDeleteFromFileLibraryPopup() {
    const dialogRef = this.modal.confirm()
      .size('lg')
      .isBlocking(true)
      .showClose(true)
      .keyboard(27)
      .dialogClass('modal-dialog overflowed')
      .okBtn('Delete File\'s')
      .title('Confirmation')
      .body('Are you sure you want to remove this file\'s permanently?')
      .open()
    dialogRef.result
      .catch((err: any) => {
        this.modelClose()
        console.log('ERROR: ' + err)
      })
      .then((dialog: any) => {
        this.modelClose()
        if (dialog) {
          this.multiDeleteFromFileLibrary()
        }
      });
  }

  async multiDeleteFromFileLibrary() { 
    
    var boxes = jQuery('.multiFiles:checked');
    
    if(boxes.length > 0) {

        this.fileObj.forEach(file => {
          
          this.fileLibraryResourceService.RemoveFile({
            'fileId': file.id,
            'trlid': this.trialline.id,
            'event_id': null
          }).toPromise().then((response: any) => {                    
            
            if (response.Status == "Successfully Deleted") {              
              this.filesi = this.filesi.filter(order => order.id != file.id);
              this.files = this.files.filter(order => order.id != file.id);

              let index = this.fileObj.indexOf(file);
              this.fileObj.splice(index, 1);

              this.toasts.success('Successfully Deleted');
            }

          });
          
        });

    }

  }

  multiUploadToComputerFileLibrary() {
    
    var boxes = jQuery('.multiFiles:checked');
    if(boxes.length > 0) {
      console.log('this.authService',this.authService)
      console.log('logged in user id:',this.authService.userModel.id)

      console.log('this.fileObj:',this.fileObj)

      this.fileObj.forEach(file => {
          
        this.fileLibraryResourceService.MoveFileToCompanyLibrary({
          'fileId': file.id,
          'userId': this.authService.userModel.id
        }).toPromise().then((response: any) => {                    
          
          if (response.Status === 1) {
            
            this.toasts.success(file.title + ' has been uploaded in Comapny File Library');
          }

          if (response.Status === 2) {
            
            this.toasts.info(file.title + ' already existing in Comapny File Library');
          }

        });
        
      });

    }

  }

  selectAllFiles() {
    var isChecked = $("#selectAllChecks").is(":checked");
    

    if(isChecked) {
      
      jQuery('.multiFiles').each(function(i) {
        
        //jQuery('#multiselect_checkbox_'+i).prop('checked', true);
        if(!jQuery('#multiselect_checkbox_'+i).is(':checked')) {
          jQuery('#multiselect_checkbox_'+i).trigger('click');
        }
        
          
      });

    } else {
      
      jQuery('.multiFiles').each(function(i) {
        
        //jQuery('#multiselect_checkbox_'+i).prop('checked', false);
        if(jQuery('#multiselect_checkbox_'+i).is(':checked')) {
          jQuery('#multiselect_checkbox_'+i).trigger('click');
        }

      });

    }

  }

}
