import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output, SimpleChange
} from '@angular/core';
import {Contacts} from "../../main/shared/contacts.model";

import {TriallineResourceService} from "../../main/trialline/shared/trialline-resoruce.service";
import {AuthService} from "../../auth/shared/auth.service";

import {DomSanitizer} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {env} from "../../../../.env";
import {Modal} from "ngx-modialog-7/plugins/bootstrap";
import {FileModel} from "../../main/file-library/shared/file.model";
import {ToastrService} from "ngx-toastr";

declare var jQuery:any;

@Component({
  selector: 'app-shared-contacts-more',
  templateUrl: './contact-more.component.html'
})

export class ContactMoreComponent implements OnInit {
  @Input() contacts: Contacts[];

  publicFolderUrl = env.publicFolderUrl;
  publicImages = env.publicImages;
  apiPath = env.apiUrl;
  filePreviewer:FileModel;
  contactShow: any;
  preview:boolean = false;
  showModal:boolean = false;
  showEdit:boolean = false;
  editContact:Contacts;
  modalId:any = '#contact-edit-modal';

  constructor(
    private authService: AuthService,
    protected toasts: ToastrService,
    private _changeDetectorRef: ChangeDetectorRef,
    public modal: Modal,
    public triallineResourceService: TriallineResourceService,
    private _sanitizer: DomSanitizer,
    protected route: ActivatedRoute, public router: Router,
  ) {}

  ngOnDestroy() {
    this._changeDetectorRef.detach();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    jQuery('[data-toggle="tooltip"]').tooltip({
      animated: 'fade',
      placement: 'right',
      html: true
    });
  }


  getSafeThumbnailUrl(category) {
    if (!category.thumbnail_visible) {
      return this.publicImages + '/images/start-event/contact_thumbnail.png'
    }
    let obj;
    if( category.thumbnail_encryptid != null && category.thumbnail_encryptid != false ) {
      obj =  this.publicFolderUrl + 'index.php/v1/filesystem/' +
        category.thumbnail_encryptid + '?preview=1'
    } else {
      obj =  this.publicFolderUrl +
        (category.thumbnail ? category.thumbnail.substring(1).replace("'", "\\'") : '')
    }

    return obj;
  }

  getSafeUrl(category) {
    let obj = this.getSafeThumbnailUrl(category);
    return "<img src='" + obj + "' style='height: 150px;'/>";
  }

  show(contact){
    this.showModal = true;
    if(contact !== undefined){
      this.contactShow = contact;
      jQuery('#contacts-shared-show-modal').modal('show');
    }
  }


  getSafeFileUrl(file) {
    if (file.file.type=='pdf') {
      return "<img src='assets/pdfimages/pdf1.png' style='height: 430px;'/>";
    }
    return "<img src='" + this.apiPath + 'filesystem/' + file.file.encrypt_id + "?preview=1' style='height: 430px;'/>";
  }

  ShowInPreviewer(file) {
    this.preview = true;
    this.filePreviewer = file;
    jQuery('#preview-modal-shared').modal('show');
  }

  closePreviewerFile() {
    this.preview = false;
    jQuery('#preview-modal').modal('hide');
    jQuery('#files').removeAttr( "style");
    // jQuery('.tab-content').removeAttr( "style");
    // jQuery('#preview-modal-shared').modal('hide');
  }

  openEdit(contact: Contacts) {
    this.editContact = contact;
    this.showEdit = true;
    this._changeDetectorRef.detectChanges();
    jQuery(this.modalId).modal('show');
  }

  updateContact(contact: Contacts) {
    let index = this.contacts.indexOf(this.contacts.find(e => e.id == contact.id));
    this.contacts[index] = contact;
    this._changeDetectorRef.detectChanges();
    this.ngAfterViewInit();
  }

  closeModal(Contact: Contacts) {
    this.showEdit = false;
  }


  deleteContact(contact: Contacts) {

    const dialogRef = this.modal.confirm()
      .size('lg')
      .isBlocking(true)
      .showClose(true)
      .keyboard(27)
      .title('Confirmation')
      .body('You are about to delete this contact. Deleting this contact will remove them from the case and they ' +
        'will no longer be associated with any of your case facts. Proceed?')
      .open()
    dialogRef.result
      .catch((err: any) => {
        this.modelClose()
        console.log('ERROR: ' + err)})
      .then((dialog: any) => {
        this.modelClose()
        if(dialog){
          this.confirmedDeleteContact(contact)
        }
      });
    $('bs-modal-container').addClass('show-midal-element')
    this._changeDetectorRef.detectChanges();
  }

  confirmedDeleteContact(contact: Contacts) {

    this.triallineResourceService.deleteContact(contact.trialline_id, contact.id).subscribe((response: any)=> {
      let index = this.contacts.indexOf(this.contacts.find(e => e.id == contact.id));
      this.contacts.splice(index, 1);
      this.toasts.success('Successfully deleted contact: ', contact.name);
    }, (error) => {
      if (error.status == 403) {
        let errorData: any = error.error;
        if (errorData.error.message) { this.toasts.error(errorData.error.message); }
      } else {
        this.toasts.error(error);
      }
    })
  }

  modelClose() {
    $('body').removeClass('modal-open');
  }
}
