import {ChangeDetectorRef, Component, Input, OnChanges, SimpleChange} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {User} from "../../../../auth/shared/user.model";
import {ProfileResourceService} from "../../shared/profile-resoruce.service";
import {env} from "../../../../../../.env";
import {FileLibraryResourceService} from "../../../file-library/shared/file-library-resoruce.service";
import {FileModel} from "../../../file-library/shared/file.model";

@Component({
  selector: 'app-profile-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsClioComponent implements  OnChanges
{
  errors: any = {};
  loading: boolean = true;
  parseError: string = '';
  documents: any = {};
  disable = false;
  @Input() activeTab: string;
  @Input() user: User;
  foldersClio: any[] = [];
  filesClio: any[] = [];
  files: FileModel[];
  constructor(
    protected toasts: ToastrService,
    protected profileResourceService: ProfileResourceService,
    protected fileLibraryResource: FileLibraryResourceService,
    protected ref: ChangeDetectorRef,
  ) {}

  ngOnChanges() {
    if (this.activeTab === 'files') {
      if (!this.user.clio_access_token) {
        this.loading = false;
        this.parseError = 'Please connect Clio';
      } else {
        this.profileResourceService.getClioDocuments(this.user.id).toPromise()
          .then((data: any) => {
            this.parseError = 'No documents in Clio';
            this.documents = data.data;
            if (this.documents) {
              this.parseError = '';
            }
          }).catch((error) => {
            this.loading = false;
            this.parseError = error.error.error.message;
            console.log(error.error.error.message);
        });
        this.fileLibraryResource.getFiles().toPromise()
          .then((data: any) => {
            this.loading = false;
            this.files = data;
          }).catch((error) => {
          this.loading = false;
          console.log(error.message);
        });
      }
    }
  }

  selectDocuments(documentId: number) {
    const index: number = this.foldersClio.findIndex(s => s === documentId);
    if (index < 0) {
      this.foldersClio.push(documentId);
    } else {
      this.foldersClio.splice(index, 1);
    }
  }

  selectFiles(fileId: number) {
    const index: number = this.filesClio.findIndex(s => s === fileId);
    if (index < 0) {
      this.filesClio.push(fileId);
    } else {
      this.filesClio.splice(index, 1);
    }
  }
  checkLibrary(clioId) {
    return this.files.find(x => x.clio_id === clioId);
  }
  uploadFiles() {
    if (this.filesClio.length > 0) {
      this.profileResourceService.uploadFiles(this.filesClio).toPromise()
        .then((data: any) => {
          this.disable = false;
          this.toasts.success('Files successfully uploaded');
        })
        .catch((error) => {
          this.disable = false;
          console.log(error.message);
        });
    } else {
      this.toasts.error('No files selected');
    }
  }
}
