<div class="row col-md-12 ">
    <div class="portlet light col-md-7 rowsize">
    <div class="portlet-body">
        <div *ngIf="forbidden">
            <div class="alert alert-warning" *ngIf="user?.subscription_past_due">
                <strong>Warning!</strong> Your subscription is not active
            </div>
        </div>
        <ng-container *ngIf="!forbidden">
            <div class="my-dashboard-list-item __header">
                <div class="row">
                    <div class="col-md-5 col-sm-4">
                        <div class="title">Title</div>
                    </div>
                    <div class="col-md-4 col-sm-4">
                        <div class="title" (click)="reorder('created_at')" style="cursor:pointer">Creation Date
                            <span class="e2e-inner-html-bound" [style.opacity]="order == 'created_at' ? '1' : '.3'"
                            [innerHTML]="getSortIcon('created_at')"></span>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-4">
                        <div class="title">Actions</div>
                    </div>
                </div>
            </div>
            <div class="my-dashboard-list-body" *ngIf="matters.length">
                <!-- <div *ngFor="let matter of  matters | search:'display_number':SearchKey"  class="my-dashboard-list-item"> -->
                        <div *ngFor="let matter of  matters   | paginate: { id: 'foo1',
                        itemsPerPage: limit,
                        currentPage: pageNumber,
                        totalItems: total_count};let i = index;"
                       class="my-dashboard-list-item">
                    <div class="row accord">
                        <div class="col-xs-12 full">
                            <div class="col-md-5 col-sm-4 __title full"
                                 style=" margin-bottom: 0 !important;">
                                <h4 class="panel-title" style=" overflow-wrap: break-word;">
                                    {{ matter.display_number }}
                                </h4>
                            </div>
                            <div class="col-md-4 col-sm-4 __title full"
                            style=" margin-bottom: 0 !important;padding: 0px">
                               {{ matter.created_at }}
                             </div>
                            <div class="col-md-3 col-sm-4" *ngIf="!matter.trialline_id">
                                <a (click)="create(matter.id)"
                                   class="btn blue btn-sm btn-circle btn-matters" [class.disabled]="loading">
                                    Import to TrialLine
                                </a>
                            </div>
                            <div class="col-md-3 col-sm-4" *ngIf="matter.trialline_id">
                                <a (click)="update(matter.trialline_id)"
                                   class="btn blue btn-sm btn-circle" [class.disabled]="loading">
                                    Update
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="my-dashboard-list-body" *ngIf="!matters.length && !loading">
                <div class="my-dashboard-list-item">
                    <h4 class="panel-title" *ngIf="!parseError">
                        No matters
                    </h4>
                    <h4 class="panel-title" *ngIf="parseError">
                        {{parseError}}
                    </h4>
                </div>
            </div>
            <div class="my-dashboard-list-body" *ngIf="loading">
                <div class="my-dashboard-list-item">
                    <h4 class="panel-title">
                        Loading...
                    </h4>
                </div>
            </div>
        </ng-container>
    </div>
        <div style="padding-top: 10px" >
                <select (change)="changePages($event)"  style="float: right; margin-left: 10px">
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                  <div style="float: right">
                    <pagination-controls  id="foo1"
                                          (pageChange)="pageChanged($event)"
                                          maxSize="9"
                                          responsive="true"
                                          previousLabel="Previous"
                                          nextLabel="Next"
                                          screenReaderPaginationLabel="Pagination"
                                          screenReaderPageLabel="page"
                                          screenReaderCurrentLabel="You're on page">
                    </pagination-controls>
                  </div>
        </div>

</div>
<div class="rowsize portlet light col-md-7" style="left: 10px;">
    <h4>Tutorials:</h4>
    <div class="portlet-body">
        <app-feed-card  [feeds]="feeds"></app-feed-card>
    </div>
</div>
</div>

<section class="modal_popup">
  <div class="modal clio_modal"
       [ngClass]="{'show' : longPopupState}"
       (click)="$event.stopPropagation(); togglePopup(false, $event)"
       data-clicker="true"
       id="long_popup" tabindex="-1" role="dialog" aria-labelledby="popap_formLabel" aria-hidden="true">
    <div class="modal-dialog" style=""
         [ngClass]="{'slideDown' : longPopupState}">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                  data-clicker="true"
                  (click)="$event.stopPropagation(); togglePopup(false, $event)">
            <span aria-hidden="true" data-clicker="true">&times;</span>
          </button>
          <h4 class="modal-title" id="long_popup_header">Importing data from Clio</h4>
        </div>
        <div class="modal-body">
          <p>We are importing your case matter information from Clio. This process may take several minutes. Please feel free to close this popup and use TrialLine in the meantime.</p>
        </div>
      </div>
    </div>
  </div>
</section>
