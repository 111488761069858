import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RestService} from "../../../../rest/shared/rest.service";

@Injectable()
export class ViewsResourceService
{
  constructor(public http: RestService) {}

  deleteView(id, view_id) {

    return this.http.delete('trialline/' + id + '/views/' + view_id).pipe(map(
      r => r.body
    ));
  }

  updatePassword(id, view_id, password, free = null) {

    let pass;
    if (free === null) {
      pass = {password: password};
    } else {
      pass = {public_free: free, password: password};
    }

    return this.http.post('trialline/' + id + '/views/' + view_id + '/password', pass).pipe(
      map((response: any) => {
        let body = response.body;

        return body.view;
      }));
  }

  storeView(id, data) {

    return this.http.post('trialline/' + id + '/views', data).pipe( map((response: any) => {
      let body = response.body;

      return body.view;
    }));
  }

  sendView(id, data, shareId) {

      return this.http.post('trialline/' + id + '/views/' + shareId, data).pipe( map((response: any) => {
        let body = response.body;

        return body.view;
      }));
  }

  changeView(id, view_id, view) {
    let data;
    if (view === 0) {
      data = {view: 2};
    } else {
      data = {view: 0};
    }
    return this.http.post ('trialline/' + id + '/views/' + view_id+ '/view', data).pipe(
      map((response: any) => {
        let body = response.body;
        return body.view;
      }));
  }
}
