import {Component} from '@angular/core';
import { Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})

export class TermsOfUseComponent {
    constructor(private meta: Meta) {
        this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow' });
    }

}


