<div class="container">
  <div class="content">
    <br><br>
    <div class="title text-center">TrialLine Privacy Policy</div>

    Effective Date: December 20, 2016 <br><br>

     Media, LLC dba owns and operates this TrialLine.net website

    business. All references to "we", "us", this "website" or this "site" shall be

    construed to mean  Media, LLC dba. We understand that

    visitors to this website are concerned about the privacy of information. The

    following describes our privacy policy regarding information, including Personal

    Information (defined below), that we collect through this website.

    By using this website you are accepting the practices described in this Privacy

    Policy.<br> <br>

    <b> How We Modify This Privacy Policy</b> <br><br>

    We reserve the right to modify this Privacy Policy at any time, and without prior

    notice, by posting an amended Privacy Policy that is always accessible by

    clicking on the "Privacy Policy" link on this site's home page. Your continued use

    of this site indicates your acceptance of the amended Privacy Policy.

    You should check the Privacy Policy through this link periodically for

    modifications by clicking on the link provided near the top of the Privacy Policy

    for a listing of material modifications and their effective dates.
    <br><br>
    <b>The Types of Information We Collect And How We Collect It</b><br><br>

    Personal Information. We collect personal information that may be used to

    identify you ("Personal Information"). We collect Personal Information from you

    when you register on our site, place an order, subscribe to our newsletter,

    respond to a survey, fill out a form or order an affiliate product from another

    company we are working with. Personal Information that we collect may vary with

    each separate purpose for which you provide it, and it may include one or more

    of the following categories: name, physical address, an email address, phone

    number, credit card information including credit card number, expiration date,

    and billing address. <br>

    Usage Data. We reserve the right to collect information based on your usage of

    this site which is information collected automatically from this site (or third party

    services employed in this site ), which can include: the IP addresses or domain

    names of the computers utilized by the users who use this site, the URI

    addresses (Uniform Resource Identifier), the time of the request, the method

    utilized to submit the request to the server, the size of the file received in

    response, the numerical code indicating the status of the server's answer

    (successful outcome, error, etc.), the country of origin, the features of the

    browser and the operating system utilized by the user, the various time details

    per visit (e.g., the time spent on each page within the site) and the details about

    the path followed within the site with special reference to the sequence of pages

    visited, heat map data, and other parameters about the device operating system

    and/or the user's IT environment ("Usage Data"). Usage Data is essentially

    anonymous when collected, but could be used indirectly to identify a person.
    <br><br>
    <b>How We Use Your Information</b> <br><br>

    Any of the information we collect from you may be used in one of the following

    ways:

    To personalize your experience

    (your information helps us to better respond to your individual needs)

    To improve our website

    (we continually strive to improve our website offerings based on the information

    and feedback we receive from you)

    To improve customer service

    (your information helps us to more effectively respond to your customer service

    requests and support needs)

    To process transactions

    (We may share or sell information with third parties for marketing or other

    purposes without your consent)

    To administer a contest, promotion, survey or other site feature

    To send periodic emails

    The email address you provide for order processing, may be used to send you

    information and updates pertaining to your order or request, in addition to

    receiving occasional company news, updates, promotions, related product or

    service information, etc.
    <br><br>
    <b>Note: If at any time you would like to unsubscribe from receiving future

      emails, we include unsubscribe instructions at the bottom of each email.</b><br><br>

    We reserve the right to make full use of Usage Data without restriction. For

    example, we may use Usage Data to compile and analyze statistics and trends

    about the use of this site, and otherwise administer and improve this site for your

    use.
    <br><br>
    <b>How We Disclose Or Share Your Information; Your California Privacy

      Rights

      Personal Information. We may share or sell Personal Information to third

      parties for marketing or other purposes without your consent.</b> <br><br>

    California Civil Code Section 1798.83 permits California residents to request

    certain information regarding disclosure of their Personal Information to third

    parties for purposes of direct marketing. You may request further information

    about our compliance with California Civil Code Section 1798.83 at the following

    email address: info(at)trialline.net. Please note that we are only required to

    respond to one request per registrant each year, and we are not required to

    respond to requests made by means other than through this the address

    provided above.

    Disclosure to Affiliated Entities. We reserve the right to provide your Personal

    Information to any affiliated entities we may have, including our subsidiaries.

    Affiliated entities are entities that we legally control (by voting rights) or that

    control us. <br>

    Disclosure to Service Providers for Website Operations. We reserve the right to

    disclose your Personal Information to our trusted service providers that assist us

    with the operation and maintenance of this site. For example, we may use third

    parties to process payments, host our servers, provide security, and provide

    production, fulfillment, optimization, analytics, and reporting services. We will

    take commercially reasonable efforts to cause these third parties to agree to hold

    your information in confidence or to disclose information only to third parties that

    we believe in good faith have instituted and follow privacy and security policies

    that are acceptable to us. <br>

    Disclosure to Successors. If we are acquired by or merged with a third party

    entity, or if we sell this website business or a line of business from this website,

    we reserve the right to transfer your Personal Information as part of such merger,

    acquisition, sale, or other change of control. In the unlikely event of our

    bankruptcy, insolvency, reorganization, receivership, or assignment for the

    be–nefit of creditors, or the application of laws or equitable principles affecting

    creditors' rights generally, we reserve the right to transfer your Personal

    Information to protect our rights or as required by law. <br>

    Disclosure Incident to Legal Process and Enforcement. We reserve the right to

    disclose your Personal Information if we have a good faith belief that access,

    use, preservation or disclosure of such information is reasonably necessary (i) to

    satisfy any applicable law, regulation, legal process or enforceable governmental

    request (such as for example, to comply with a subpoena or court order), or (ii) to

    investigate or enforce violations of our rights or the security of this site. <br>

    When We Participate In a Joint Venture. We may participate with another

    company or individual for purposes of jointly promoting our products, services,

    promotions or contests or their products, services, promotions, or contests. We

    reserve the right to right to disclose your Personal Information to them for

    purposes of (i) compensation, transaction processing, fulfillment, and support,

    and (ii) for purposes of offering you other products, services, promotions, and

    contests. These joint venture marketing partners may also contact you regarding

    other products, services, promotions, or contests. <br>

    Marketing Partners.  We may share your Usage Data with our marketing partners

    including third party service providers, advertisers, advertising networks and

    platforms, advertising agencies, and data brokers and aggregators to serve and

    offer personalized ads.  We may share Personal Information with our marketing

    partners to correlate and match our list with our marketing partners' lists for

    purposes of creating an "audience" for serving personalized ads.
    <br><br>
    <b>How We Protect Your Information</b><br><br>

    We will implement reasonable and appropriate security procedures consistent

    with prevailing industry standards to protect data from unauthorized access by

    physical and electronic intrusion

    We utilize password protected directories and databases to safeguard your

    information.

    We offer the use of a secure server. All supplied sensitive/credit information is

    transmitted via Secure Socket Layer (SSL) technology and then encrypted into

    our Payment gateway providers database only to be accessible by those

    authorized with special access rights to such systems, and are required to keep

    the information confidential.

    After a transaction, your Personal Information (credit cards, social security

    numbers, financials, etc.) will not be stored on our servers.

    Despite all of these efforts to protect your information, no data transmission over

    the Internet can be guaranteed to be 100% secure. We cannot, therefore,

    warrant or ensure the security of any information you transmit to us or from our

    online products or services, and you do so at your own risk..
    <br><br>
    <b>Specific Information About Cookies, Web Beacons, And Behavioral Ads</b><br><br>

    1st Party Cookies. We use 1st party cookies (passed by us) to keep track of your

    current shopping session to personalize your experience and so that you may

    retrieve your shopping cart at any time. <br>

    3rd Party Cookies for Web Analytics and Reporting. We reserve the right to use

    web analytics services provided by 3rd parties. These services use 3rd party

    cookies (cookies passed by others, not by us) to collect Usage Data about your

    use of this site. These web analytics services may also transfer this information

    to third parties where required to do so by law, or where such third parties

    process the information on the service's behalf. <br>

    Google Analytics is a web analysis service provided by Google Inc. ("Google").

    Google utilizes the data collected to track and examine the use of this site, to

    prepare reports on its activities, and to share them with other Google services.

    Information collected: cookie and Usage Data. Visit Privacy Policy at

    <a href="https://www.google.com/intl/en/policies/?fg=1">https://www.google.com/intl/en/policies/?fg=1</a> You may opt-out of the Google

    Analytics service with the Google's Browser Add-on that's available at

    <a href="https://tools.google.com/dlpage/gaoptout/">https://tools.google.com/dlpage/gaoptout/</a> <br>

    3rd Party Cookies for Crediting Purchases To Affiliates; Flash Cookies. We

    reserve the right to use 3rd party cookies, including Flash cookies, for purposes

    of crediting any purchase you may make on this site to one of our affiliate

    marketing partners that may have referred you to us. These cookies will only be

    used for purposes of crediting sales to the referring affiliate and not for other

    purposes such as serving behavioral ads. Flash cookies are not the same as

    "browser cookies". Flash cookies consist of an Adobe Flash Media Player local

    shared object (LSO) that is used for tracking purposes. The Adobe Flash Media

    Player is software that enables users to view content on their computers.
    Flash

    cookies are also accompanied by a browser cookie. If you delete the browser

    cookie, the Flash cookie may automatically create (or re-spawn) a replacement

    for the browser cookie. However, recent announcements indicate that IE 8 and IE

    9 browsers have been updated to also delete Flash cookies with an their "delete

    browsing history" feature. For more information, Google "How to delete flash

    cookie". <br>

    Web Beacons - sometimes called single-pixel gifs or clear gifs - are used to

    assist in delivering cookies, and they allow us to count users who have visited

    pages of our site. We may include Web Beacons in promotional e-mail messages

    or our newsletters in order to determine whether messages have been opened

    and acted upon. Re-Marketing, Targeted Ads, Online Behavioral Ads <br>

    We reserve the right to participate with third party advertising partners in

    remarketing and behavioral targeting programs. These services allow us and our

    partners to display ads that are likely to be more relevant (such as behavioral or

    targeted ads that are based on information about your browsing history). This

    activity is performed by tracking Usage Data and by using cookies to transfer

    information to the partners that manage the remarketing and behavioral targeting

    activity. <br>

    These third party ad servers or ad networks may also use cookies and other

    tracking devices to track your activities on this site to measure advertisement

    effectiveness and other reasons that will be provided in their own privacy

    policies. Tracking may include, but not limited to, the following: first party cookies,

    third party cookies, anonymous cookies, persistent identifiers, email optin, and

    search engine keywords. We have no access or control over these cookies and

    other tracking devices that may be used by third party advertisers, and we have

    no responsibility or liability for the policies and practices of these sites. <br>

    AdWords Remarketing (Google) - We reserve the right to participate in AdWords

    Remarketing which is a Remarketing and Behavioral Targeting service provided

    by Google Inc. that connects the activity of this site with the Adwords advertising

    network and the Doubleclick cookie. Information collected: cookie and Usage

    Data. For the opt-out, visit

    <a href="https://support.google.com/ads/answer/2662922?hl=en">https://support.google.com/ads/answer/2662922?hl=en</a>

    For information in general about Google's remarketing ad campaigns, and

    specifically about information regarding DoubleClick cookies and how to control

    and manage Google's advertising cookies for these campaigns, visit

    <a href="http://www.google.com/policies/technologies/ads/">http://www.google.com/policies/technologies/ads/</a>

    For another Google resource for opting our of Google's use of cookies, visit

    <a href="http://www.google.com/settings/ads">http://www.google.com/settings/ads</a>

    For information regarding how Google uses data when you use Google's

    partners' sites or apps, visit <a href="http://www.google.com/policies/privacy/partners/">http://www.google.com/policies/privacy/partners/</a>

    For anther resource recommended by Google for opting out of a third party

    vendor's use of cookies, visit

    <a href="http://www.networkadvertising.org/managing/opt_out.asp">http://www.networkadvertising.org/managing/opt_out.asp</a>

    Remarketing through Google Analytics for Display Advertising (Google) - We

    reserve the right to participate in any and all of the following Google Analytics

    Advertising Features provided by Google Inc. –  Remarketing with Google

    Analytics, Google Display Network Impression Reporting, the DoubleClick

    Campaign Manager integration, and Google Analytics Demographics and

    Interest Reporting. These features use first party cookies (such as the Google

    Analytics cookie) for connecting the tracking activity performed by Google

    Analytics and its cookies with the Adwords advertising network and the

    DoubleClick cookie (a third party cookie). Information collected: cookie and

    Usage Data, including audience data such as age, gender, and interests. For the

    opt-out, visit <a href="https://support.google.com/ads/answer/2662922?hl=en">https://support.google.com/ads/answer/2662922?hl=en</a> You may

    optout of the Google Analytics service with the Google's Browser Add-on that's

    available at <a href="https://tools.google.com/dlpage/gaoptout/">https://tools.google.com/dlpage/gaoptout/</a>

    For information in general about Google's remarketing ad campaigns, and

    specifically about information regarding DoubleClick cookies and how to control

    and manage Google's advertising cookies for these campaigns, visit

    <a href="http://www.google.com/policies/technologies/ads/">http://www.google.com/policies/technologies/ads/</a>

    For another Google resource for opting our of Google's use of cookies, visit

    <a href="http://www.google.com/settings/ads">http://www.google.com/settings/ads</a>

    For information regarding how Google uses data when you use Google's

    partners' sites or apps, visit <a href="http://www.google.com/policies/privacy/partners/">http://www.google.com/policies/privacy/partners/</a>

    For anther resource recommended by Google for opting out of a third party

    vendor's use of cookies, visit

    <a href="http://www.networkadvertising.org/managing/opt_out.asp">http://www.networkadvertising.org/managing/opt_out.asp</a>

    Custom Audience (Facebook). We may participate in Facebook.com's Custom

    Audience program which enables us to display personalized ads to persons on

    our email lists when they visit Facebook.com.  We provide Personal Information

    such as your email address and phone number to Facebook to enable Facebook

    to determine if you are a registered account holder with Facebook. You may

    optout of participation in our Facebook Custom Audience by sending an email,

    from the email address you are opting out of, to the email address provided in

    our contact information below. For your optout to be effective, you must: (i) place

    the following text in the subject line of the email - "Opting Out of Facebook.com

    Website Custom Audience Ads", and (ii) in the body of the email, include your

    name and email address. We will forward your name and email address to

    Facebook.com with a request to delete you from all of our Facebook Custom

    Audience Ads. b <br>

    Other Remarketing Services. We reserve the right to participate in additional

    remarketing services that will be similar to the remarketing services described

    above. These services will allow us and our partners to display ads that are likely

    to be more relevant (such as behavioral or targeted ads that are based on

    information about your browsing history). You may access these services to opt

    out: <a href="http://www.networkadvertising.org/choices/">http://www.networkadvertising.org/choices/</a> and

    <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a> .

    Social Media Interactions <br>

    We invite you to socialize and share your participation with this site and

    purchases. If you choose to use social media platforms such as Facebook,

    Twitter, Pinterest, and Instagram, you will be allowing interaction with these

    platforms or other external platforms directly from this site, and in the process

    you may be sharing certain profile elements, including your comments. This

    sharing is subject to each social media program's privacy policies.

    Do Not Track Requests <br>

    Some Web browsers incorporate a "Do Not Track" feature that signals to

    websites that you visit that you do not want to have your online activity tracked.

    Each browser communicates "Do Not Track" signals to websites differently,

    making it unworkable to honor each and every request correctly. In order to

    alleviate any communication error between browsers and website, we do not

    respond to "Do Not Track" signals at this time. As the technology and

    communication between browser and website improves, we will reevaluate the

    ability to honor "Do Not Track" signals and may make changes to our policy.
    <br><br>
    <b>Updating Personal Information</b><br><br>

    Upon request, we will permit you to request or make changes or updates to your

    Personal Information for legitimate purposes. We request identification prior to

    approving such requests. We reserve the right to decline any requests that are

    unreasonably repetitive or systematic, require unreasonable time or effort of our

    technical or administrative personnel, or undermine the privacy rights of others.

    We reserve the right to permit you to access your Personal Information in any

    account you establish with this site for purposes of making your own changes or

    updates, and in such case, instructions for making such changes or updates will

    be provided where necessary.
    <br><br>
    <b>Onward Transfer Outside Your Country Of Residence</b> <br><br>

    Any Personal Information which we may collect on this site will be stored and

    processed in our servers located only in the United States. By using this site, if

    you reside outside the United States, you consent to the transfer of Personal

    Information outside your country of residence to the United States.
    <br><br>
    <b>Third party links</b> <br><br>

    Occasionally, at our discretion, we may include or offer third party products or

    services on our website. These third party sites have separate and independent

    privacy policies. We therefore have no responsibility or liability for the content

    and activities of these linked sites. Nonetheless, we seek to protect the integrity

    of our site and welcome any feedback about these sites.
    <br><br>
    <b>Children's Online Privacy Protection Act Compliance</b><br><br>

    We are committed to preserving online privacy for all of its website visitors,

    including children. This site is a general audience site. Consistent with the

    Children's Online Privacy Protection Act (COPPA), we will not knowingly collect

    any information from, or sell to, children under the age of 13. If you are a parent

    or guardian who has discovered that your child under the age of 13 has

    submitted his or her personally identifiable information without your permission or

    consent, we will remove the information from our active list, at your request. To

    request the removal of your child's information, please contact our site as

    provided below under "Contact Us", and be sure to include in your message the

    same login information that your child submitted.
    <br><br>
    <b>Contacting Us</b><br><br>

    If there are any questions regarding this privacy policy you may contact us using

    the information found at this link: <a href="http://www.trialline.net/contact-us">http://www.trialline.net/contact-us</a><br>

     Media, LLC dba<br>

    7375 E. Orchard Road #110<br>

    Greenwood Village, CO 80111<br>

    USA<br>

    info@trialline.net<br>

    (800) 325-7715<br>

    <br>

  </div>
</div>
