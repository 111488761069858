
import {of as observableOf, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";

import {TriallineResourceService} from "./trialline-resoruce.service";

@Injectable()
export class EditCanActivate implements CanActivate
{
  constructor(protected triallineResource: TriallineResourceService, protected router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.triallineResource.getTrialline(route.parent.params['trialline_id']).pipe(map(
      trialline => {

        if (! trialline.parent_trialline_id) { return true; }
        this.router.navigate(['/dashboard']);
        return false;
      }),
      catchError((error: any) => {
        this.router.navigate(['/dashboard']);
        return observableOf(false);
      }),);
  }
}
