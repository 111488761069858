import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Trialline} from "../../main/shared/trialline.model";
import {TriallineResourceService} from "../../main/trialline/shared/trialline-resoruce.service";

@Injectable()
export class TestResolver implements Resolve<Trialline>
{
  constructor(protected triallineResource: TriallineResourceService) {}

  // @ts-ignore
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Trialline>
  {
      return this.triallineResource.getTrialline(59)
        .toPromise()
        .then((trialline: Trialline) => {return trialline;});
        //.catch((error) => {});
  }
}
