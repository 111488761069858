<style>
button.close.close-icon {
  font-size: 28px !important;
}
</style>
<div id="newTrialLine" class="modal fade bs-modal-lg in" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header ">
        <button (click)="resetForm(createTriallineForm);" type="button" class="close close-icon" data-dismiss="modal" aria-label="Close"><span
          aria-hidden="true"  title="Close">&times;</span></button>
        <h4 class="modal-title">Create New TrialLine</h4>
      </div>
      <form #createTriallineForm="ngForm" (submit)="createTrialline(createTriallineForm)" role="form-horizontal">
        <div class="modal-body portlet-body form">
          <div class="form-body">
            <div class="form-group" [hubValidator]="errors?.name" transformer="inline">
              <label>Name</label>
              <input type="text" name="name" class="form-control"
                     placeholder="Ok, let`s name your new TrialLine" [ngModel]>
            </div>
            <div class="form-group" [hubValidator]="errors?.description" transformer="inline">
              <label>Description</label>
              <textarea name="description" [ngModel] class="form-control autosizeme" rows="3" placeholder="Would you like to add a description of what this TrialLine is about?"></textarea>
            </div>
            <div>
              <p class="help-block non-validate">
                <!--<span class="label label-sm label-danger">Note:&nbsp;</span>-->
                <!--In creating this TrialLine, you will be incurring a charge of $9.99/month for every month this TrialLine is active. Your credit card will be billed monthly on the date this TrialLine was created. To discontinue billing for this TrialLine, you may "Cancel" it at any time. You may also "Archive" this TrialLine for $0.99 per month. Archiving your case files here may reduce the cost of storing files in your office or through an off-site provder.-->
              </p>
              <!--<p style="color:blue"> The money will be withdrawn from the card-->
                <!--...{{ user.card_last_four }}</p>-->
            </div>
            <!-- <div class="form-group">
            <label>Which date format would you like to use?</label>

            <select [ngModel] name="trialline_date_format" class="form-control">
              <option disabled selected>- Select Date format-</option>
              <option value="MDY">MM/DD/YYYY</option>
              <option value="DMY">DD/MM/YYYY</option>
              <option value="YMD">YYYY/MM/DD</option>
            </select>
          </div> -->
            <div class="form-group">
              <label>Which date format would you like to use?</label>
              <select name="trialline_date_format" [(ngModel)]="futureTimeline.trialline_date_format" class="form-control"> 
                <option disabled>- Select Date format-</option>
                <option *ngFor="let dateFormat of dateFormatList" [value]="dateFormat.key">{{dateFormat.value}}</option> 
              </select> 
            </div>
            <div class="form-group" transformer="inline">
              <div class="mt-checkbox-inline">
                <label class="mt-checkbox mt-checkbox-outline">
                  <input class="checkbox checkbox-circle" type="checkbox" [ngModel]="futureTimeline.for_future_timelines" id="inlineCheckbox1" name="for_future_timelines">
                  <small>
                      Checked this to set default for all future timelines
                  </small>
                  <span></span>
                </label>
              </div>
            </div>
            <div class="form-group" [hubValidator]="errors?.agree" transformer="inline">
              <div class="mt-checkbox-inline">
                <label class="mt-checkbox">
                  <input type="checkbox" [(ngModel)]="agree" id="inlineCheckbox1" name="agree" checked />
                   <small > <!-- *ngIf="user?.user_type != 3" -->
                    I have read and agree to the <a href="/terms-of-use" target="_blank" rel="nofollow">Terms of Use & End User Agreement</a>.
                  </small>
                  <span></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button (click)="resetForm(createTriallineForm);" type="button" class="btn btn-default" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary" [disabled]="disable || !agree">Create</button>
        </div>
      </form>
    </div>
  </div>
</div>

