<div class="portlet light ">
    <div class="portlet-body">
        <ng-container>
            <div class="my-dashboard-list-item __header">
                <div class="row">
                    <div class="col-md-6 col-sm-6">
                        <div class="title">Title</div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                        <div class="title">Actions</div>
                    </div>
                </div>
            </div>
            <form (ngSubmit)="uploadFiles()" role="form-horizontal">
                <div class="modal-body portlet-body form">
                    <div class="form-body">
                        <div class="form-group" *ngFor="let document of documents | keys">
                            <label class="mt-checkbox-inline">
                            <!--<label class="mt-checkbox-inline" for="inlineCheckbox{{document}}">-->
                                <!--<input type="checkbox" id="inlineCheckbox{{document}}"-->
                                        <!--name="folder{{document}}" [value]="document"-->
                                       <!--(change)="selectDocuments(document)"-->
                                       <!--&gt;-->
                                <span>Folder: {{documents[document].name}}</span>
                            </label>
                            <div class="form-group ml-2" *ngFor="let file of documents[document].files">
                                <label class="mt-checkbox-inline" for="inlineCheckbox{{file.id}}">
                                    <input type="checkbox" id="inlineCheckbox{{file.id}}"
                                           name="file{{file.id}}" value="file.id" (change)="selectFiles(file.id)"
                                    [disabled]="checkLibrary(file.id)" [checked]="checkLibrary(file.id)">
                                    <small>File: {{file.filename}}</small>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary" [disabled]="disable">Upload</button>
                </div>
            </form>
            <div class="my-dashboard-list-body" *ngIf="!documents.length && !loading">
                <div class="my-dashboard-list-item">
                    <h4 class="panel-title" *ngIf="parseError">
                        {{parseError}}
                    </h4>
                </div>
            </div>
            <div class="my-dashboard-list-body" *ngIf="loading">
                <div class="my-dashboard-list-item">
                    <h4 class="panel-title">
                        Loading...
                    </h4>
                </div>
            </div>
        </ng-container>
    </div>
</div>
