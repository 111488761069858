import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from "@angular/router";
import {NgForm} from "@angular/forms";
import {User} from "../../../../auth/shared/user.model";
import {TeamResourceService} from "../../../team/shared/team-resoruce.service";
import {UserProfile} from "../../../profile/shared/user.model";

declare var jQuery: any;

@Component({
  selector: 'app-create-access-team',
  templateUrl: './create-access-team.component.html'
})
export class TriallineCreateAccessComponent {
  @Input() user: User;
  @Input() triallineId: number[];
  @Output() onCreated = new EventEmitter<UserProfile>();

  errors: any = {};
  formConfirm: boolean = false;
  submitComplete: boolean = true;

  constructor(
    protected toasts: ToastrService,
    protected route: ActivatedRoute, public router: Router,
    protected teamResourceService: TeamResourceService
  ) {
  }
  ngOnInit() {
  }

  closeSubmitAccess()
  {
    this.submitComplete = true;
    jQuery("#subteam-access").modal("hide");
  }

  confirmsubscribe()
  {
    console.log('will log for confirm suscribe');
    this.formConfirm = true;
    this.submitComplete = true;
    jQuery("#subteam-access").modal("hide");
    // jQuery(".create-access-team").click();
    jQuery("#submitAccessFormButton").click();
  }

  createAccessTeam(form: NgForm) {
    this.submitComplete = false;
    if (!this.formConfirm) {
      jQuery("#subteam-access").modal("show");
    }
    if(this.formConfirm === true) {
      console.log('submitting form');
      this.teamResourceService.createAccessTeam(form.value)
        .subscribe((user: any) => {
          this.errors = {};
            this.onCreated.emit(user);
            form.controls['first_name'].reset();
            form.controls['last_name'].reset();
            form.controls['email'].reset();
            form.controls['team_access_level'].reset();
            this.formConfirm = false;
            this.submitComplete = true;
            jQuery('#new-access-team').modal('hide');
            this.toasts.success('TrialLine access granted successfully.');
          }, (error) => {
          if (error.status == 422) {
              let errorData = error.error;
              this.errors = errorData;

              try {
                for (let key in errorData) {
                  let value = errorData[key];
                  this.toasts.error(value.join(" "), key);
                }
                this.submitComplete = true;
              } catch (e) {
                console.log(e)
              }
            } else if (error.status == 403) {

            this.toasts.error(error.error.error.message)
            this.submitComplete = true;
            } else {
              this.toasts.error(error.error);
              this.submitComplete = true;
            }
          });
    }
  }

}
