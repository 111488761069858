
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import { catchError, map, tap } from 'rxjs/operators';



import {RestService} from "../../rest/shared/rest.service";
import {View} from "../../main/shared/view.model";
import {Event} from "../../main/shared/event.model";
import {resolveAny} from "dns";

@Injectable()
export class PresentResourceService
{
  constructor(public http: RestService) {}

  getView(uuid, password?, withParam?: string): Observable<any>
  {

    return this.http.get('view/' + uuid + (withParam ? '&with=' + withParam : ''), {params: {password: password}}).pipe( map((response: any) => {
      let body = response.body;

      return body.view;
    }));

  }

  checkPassword(uuid, password)
  {
    return this.http.get('view/' + uuid + '/pwd', {params: {password: password}}).pipe( map((response: any) => {
      let body = response.body;

      return body.password;
    }));

  }

  getEvent(uuid, event_id, password): Observable<any>
  {

    return this.http.get('view/' + uuid + '/event/' + event_id, {params: {password: password}}).pipe( map((response: any) => {
      let body = response.body;

      return body.event;
    }));
  }

  storeComment(uuid, event_id, comment, password)
  {

    return this.http.post('view/' + uuid + '/event/' + event_id + '/comment', {comment: comment, password: password}).pipe( map((response: any) => {
      let body = response.body;

      return body.comment;
    }));

  }
}
