import {Observable, throwError as observableThrowError} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Feed} from './model/feed';


@Injectable()
export class FeedServiceService {

  private rssToJsonServiceBaseUrl: string = 'https://rss2json.com/api.json?rss_url=';

  constructor(
    private http: HttpClient
  ) { }

  private extractFeeds(res: HttpResponse<any>): Feed {
    return res || { };
  }
  getFeedContent(url: string): Observable<Feed>
   {
     //debugger;
    return this.http.get(this.rssToJsonServiceBaseUrl + url).pipe(
            map(this.extractFeeds),
            catchError(this.handleError),);
  }


  private handleError (error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    return observableThrowError(errMsg);
  }

}
