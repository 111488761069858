<div class="content">
  <form #accessForm="ngForm" class="login-form" (submit)="confirm(accessForm)" novalidate>
    <h3 class="form-title" style="font-family: 'Open Sans', sans-serif !important;">Confirm your access</h3>
    <div class="alert alert-danger display-hide">
      <button class="close" data-close="alert"></button>
    </div>

    <div class="form-actions">
      <button type="submit" class="btn blue pull-right">Confirm</button>
      <div class="clearfix"></div>
    </div>
    <div class="create-account">
      <p style="font-family: 'Open Sans', sans-serif !important; font-size: 14px;"> Don't have an account yet ?&nbsp;
        <a [routerLink]="['/register']" id="register-btn"> Create an account </a>
      </p>
    </div>
  </form>
</div>
